import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
import RecuperarContrasenaComponent from './../../components/login/RecuperarContrasena';
import generalCallApi from './../helpers/generalCallApi';
import { useState } from 'react';
import { useTrackedState } from '../../store';

/**
 * Container que no recibe props. Este container retorna el componente RecuperarContrasenaComponent al cual se le pasa una serie de propiedades para renderizar la pantalla de recuperar contraseña. Las función principal es recuperar contraseña.
 * @returns {JSX.Element} RecuperarContrasenaComponent
 */
const RecuperarContrasena = () => {
  const state = useTrackedState();
   const { idioma } = state;
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('olvidar_contrasena');
  const [preloader, setPreloader] = useState(false);
  const [respuestaApi, setRespuestaApi] = useState({
    isError: null,
    modal: ''
  });

  /**
   * Función que envia un correo para actualizar la contraseña. Esta función utiliza el api reestablecerContraseña
   * @param {string} correo 
   */
  const recuperarContrasena = async ({ correo }) => {
    if (!preloader) {
      setPreloader(true);
      setRespuestaApi({
        isError: null,
        modal: ''
      })

      const pathname = "/passwordRecovery/reestablecerContrasena"
      const properties = { correo: correo, idioma: idioma }
      const resultados = await generalCallApi({ pathname, properties });
      const info = resultados.info;
      const error = resultados.status === 0 && info.search('No se encontró el correo') === 1 && info.search('Se ha superado el número máximo de recuperaciones') === 1;

      let advertencia='';
      let tipoAdvertencia='';
      switch (info) {
        case 'Se ha superado el número máximo de recuperaciones de contraseña en la semana. Por favor contactar soporte si requiere recuperar la cuenta':
          advertencia = 'concurrencias';
          tipoAdvertencia = 'error';
          break;

        case 'No se encontró el correo':
          advertencia = 'correo_incorrecto';
          tipoAdvertencia = 'error';
          break;

        case 'No se pudo cambiar la contraseña. Por favor contacta soporte':
          advertencia = 'general';
          tipoAdvertencia = 'error';
          break;

        default:
          advertencia = 'exito';
          tipoAdvertencia = 'exito';
      }

      setPreloader(false);
      setRespuestaApi({
        isError: error,
        modal: advertencia,
        tipoAdvertencia: tipoAdvertencia
      })
    }
  }

  return (
    <>
      {
        isReady &&
        <RecuperarContrasenaComponent
          preloader={preloader}
          recuperarContrasena={recuperarContrasena}
          respuestaApi={respuestaApi}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}

export default RecuperarContrasena