import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { store } from 'react-notifications-component';
import MicroModal from "micromodal";

/*componentes */
import DivisionCrud from "../globales/DivisionCrud";
import Selector from "../globales/elementosFormularios/Selector";
import SelectorCheck from "../globales/elementosFormularios/SelectorCheck";
import ModalCambiarIcono from "../globales/modales/ModalCambiarIcono";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";

const EditarCurso = (props) => {
  const { actualizarIconoCurso, actualizarProfesoresAsignados, actualizarSedeAsignada, camposModificados, comprobarDatosNuevos, comprobarNombreCurso, datosCurso, datosAntiguos, editarCurso, eliminarCurso, isNombreCursoRepetido, preloaderApi, profesoresInstituto, respuestaApiEditarCurso, respuestaApiEliminarCurso, sedesInstituto, textosInterfaz, } = props
  const { miga_de_pan, editar, botones, eliminar_curso, curso_eliminado, modal_icono } = textosInterfaz;
  const { nombre, sede, profesor, icono } = editar;

  const [nombreCurso, setNombreCurso] = useState({ campo: datosCurso.nombre, valido: true, desactivado: false });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAvatar, setMostrarAvatar] = useState(false);
  const [textoInputSede, setTextoInputSede] = useState(datosCurso.nombreSede);
  const [modalEliminar, setModalEliminar] = useState(true);
  const refNombreCurso = useRef(null);

  //Variables de entorno
  const numIconos = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_ICONOS_CURSO);

  useEffect(() => {
    if (respuestaApiEliminarCurso.isError === false) {
      MicroModal.close('estas-seguro-curso', {
        awaitCloseAnimation: true
      });
      MicroModal.show('curso-eliminado', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaApiEliminarCurso.isError === true) {
      MicroModal.close('estas-seguro-curso', {
        awaitCloseAnimation: true
      });

      notificacion(textosInterfaz.notificacion.error, respuestaApiEliminarCurso.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEliminarCurso.isError])

  useEffect(() => {
    if (respuestaApiEditarCurso.isError !== null) {
      notificacion(
        respuestaApiEditarCurso.isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        respuestaApiEditarCurso.mensaje,
        respuestaApiEditarCurso.isError ? "danger" : "success"
      );
    }else{
      setNombreCurso({...nombreCurso, desactivado: false})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEditarCurso.isError])

  useEffect(() => {
    setMostrarAlerta(isNombreCursoRepetido);
    validarNombreCurso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNombreCursoRepetido])


  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  const cambiarSede = ({ opcion }) => {
    setTextoInputSede(opcion.nombre);
    actualizarSedeAsignada({ idSede: opcion.id });
  };

  const validarNombreCurso = () => {
    const valorCampo = nombreCurso.campo.length > 0 ? isNombreCursoRepetido ? false : true : false;
    setNombreCurso({ ...nombreCurso, valido: valorCampo })
  }

  const actualizarCampo = (e, estado, cambiarEstado) => {
    comprobarNombreCurso({ nombreCurso: e.target.value });
    cambiarEstado({ ...estado, campo: e.target.value });
  }

  const actualizarProfesores = ({ e, opcion }) => {
    actualizarProfesoresAsignados({ e, idSede: opcion.idSede, profesor: opcion });
  }

  const actualizarCambios = () => {
    setMostrarAlerta(true);
    if (comprobarDatosNuevos && nombreCurso.valido) {
      editarCurso();
    }
  }

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <Link to="/cursos" className="focusable-primary mr-2">{miga_de_pan.principal} </Link>  &gt;
          <span className="ml-2 font-bold ">{miga_de_pan.editar} </span>
        </p>
      </div>

      <div className="contenido">
        <h1 className="titulos-principales mb-5">{editar.titulo}</h1>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{nombre.titulo} <span className="asterisco">*</span> </h3>
          </>
          <>
            <div className="alineado-verticalmente ">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!nombreCurso.desactivado && 'input_disabled'} ${mostrarAlerta ? !nombreCurso.valido && 'input_error' : null}`}
                  onBlur={() => { validarNombreCurso() }}
                  onChange={(e) => { actualizarCampo(e, nombreCurso, setNombreCurso) }}
                  onClick={() => { setNombreCurso({ ...nombreCurso, desactivado: true }) }}
                  onKeyUp={validarNombreCurso}
                  placeholder={nombre.placeholder}
                  ref={refNombreCurso}
                  type="text"
                  value={datosCurso.nombre}
                />
              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setNombreCurso({ ...nombreCurso, desactivado: true }); refNombreCurso.current.focus() }}
              >
                {botones.editar.campo}
              </button>
            </div>
            <div className="w-full xl:w-451p">
              {mostrarAlerta &&
                <p className={`text-14 
                  ${!nombreCurso.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {isNombreCursoRepetido ?
                    nombre.error_nombre_repetido
                    :
                    nombre.error
                  }
                </p>
              }
            </div>

          </>

        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{sede.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{sede.descripcion} </p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarSede}
              desactivado={!camposModificados.idSede}
              estiloTipoInput={true}
              opciones={sedesInstituto}
              superposicion={true}
              textoDefault={sede.select}
              textoElegido={textoInputSede}
            />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{profesor.titulo} </h3>
            <p className="r4-14-gris6a">{profesor.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <SelectorCheck
              actualizarElegidos={actualizarProfesores}
              desactivado={!camposModificados.profesor}
              estiloTipoInput={true}
              hayElementos={profesoresInstituto.hayProfesores}
              opciones={profesoresInstituto.profesores}
              datosAlmacenados={datosCurso.profesores}
              textoDefault={`${datosCurso.numeroProfesores} ${datosCurso.numeroProfesores !== 1 ? profesor.profesores_asignados.plural : profesor.profesores_asignados.singular} `}
              textoNoHayDatos={profesor.no_hay_profesores}
            />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{icono.titulo}</h3>
            <p className="r4-14-gris6a">{icono.descripcion} </p>
          </>
          <div className="lg:w-32 mt-4 lg:mt-0">
            <div
              className="avatar icono border-gris-linea mx-auto text-indent cursor-pointer"
              style={{ backgroundImage: `url(/images/badges/badge-${datosCurso.icono}.png)` }}
              onClick={() => {
                if (mostrarAvatar) {
                  MicroModal.show('cambiar-icono-curso', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarAvatar(true)
                }
              }}
            >
              icono-{datosCurso.icono}
            </div>
            <button
              className="focusable-primary evento_rojo mx-auto block mt-3"
              onClick={() => {
                if (mostrarAvatar) {
                  MicroModal.show('cambiar-icono-curso', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarAvatar(true)
                }
              }}
            >
              {botones.cambiar}
            </button>
          </div>
        </DivisionCrud>

        <div className="alineado-izquierda my-5">
          <button
            className="focusable-primary boton-justo sm:center mr-5 "
            onClick={() => {
              if (modalEliminar) {
                MicroModal.show('estas-seguro-curso', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalEliminar(true)
              }
            }}
          >
            <span className="icon-basura red-inv-var2--color text-20 pr-3 hidden sm:block"></span>
            <p className=" evento_rojo">{botones.eliminar}</p>
          </button>
          <button
            disabled={isNombreCursoRepetido ? true : comprobarDatosNuevos ? false : true}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
            onClick={() => { actualizarCambios() }}
          >
            {
              preloaderApi ?
                <div className="center">
                  <span className="icon-spinner7 animate-spin text-20"></span>
                </div>
                :
                botones.actualizar
            }
          </button>
        </div>

      </div>

      {modalEliminar &&
        <>
          {/* Modal pregunta si desea eliminar curso */}
          <Modal nombreModal="estas-seguro-curso">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{eliminar_curso.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{eliminar_curso.descripcion} <span className="text-resaltado">{datosAntiguos.nombre}</span> {eliminar_curso.descripcion2} <span className="text-resaltado">{datosAntiguos.nombreSede}</span> {eliminar_curso.descripcion3}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
              aria-label="Cancelar"
                onClick={() => {
                  MicroModal.close(`estas-seguro-curso`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="focusable-primary boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5"
              >
                {botones.cancelar}
              </button>
              <button aria-label="Eliminar" className="boton-justo sm:px-10 boton-rojo boton_hover_morado" onClick={eliminarCurso} >
                {preloaderApi ?
                  <div className="center">
                    <span className="icon-spinner7 animate-spin text-20"></span>
                  </div>
                  :
                  botones.eliminar
                }
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion curso eliminado */}
          <ModalConfirmacion nombreModal="curso-eliminado">
            <Link className="focusable-primary icon-cerrar text-20 right-6 absolute" to="/cursos"></Link>
            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img "
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{curso_eliminado.titulo}</h1>
            <p className="text-center r7-16-negro26 mb-4">
              {curso_eliminado.descripcion1} <span className="text-resaltado">{datosCurso.nombre}</span> {curso_eliminado.descripcion2}
            </p>
            <div className="center my-3">
              <Link
                to="/cursos"
                className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
              >
                {botones.volver}
              </Link>
            </div>
          </ModalConfirmacion>
        </>

      }

      {mostrarAvatar &&
        <ModalCambiarIcono
          boton={botones.cambiar}
          cambiarAvatar={actualizarIconoCurso}
          datoIcono={datosCurso.icono}
          descripcion={modal_icono.descripcion}
          maxIconos={numIconos.maximo}
          minIconos={numIconos.minimo}
          nombreModal="cambiar-icono-curso"
          rutaCarpeta='badges/badge-'
          titulo={modal_icono.titulo}
        />
      }
    </>
  )
}
export default EditarCurso;