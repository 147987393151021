import { useState, useEffect } from 'react';
import MicroModal from 'micromodal';
import ModalFormEnviado from '../../../containers/ayuda/modalFormEnviado';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de props que le permiten renderizar el formulario de "Sugerencias y comentarios". Además, renderiza los componentes MicroModal y ModalFormEnviado. 
 * @returns SugerenciasYComentarios
 */
const SugerenciasYComentarios = (props) => {
  const { info, textosInterfaz, enviarASoporte, preloader, mostrarModal, ocultarModal } = props;
  const { sugerencia } = info;

  const [textArea, setTextArea] = useState('');
  const [enviar, setEnviar] = useState(false);

  useEffect(() => {
    if (mostrarModal) {
      MicroModal.show('formulario-enviado', {
        awaitCloseAnimation: true
      });
    }
  }, [mostrarModal])

  const enviarFormulario = () => {
    setEnviar(true);
    if (textArea) {
      enviarASoporte(textArea)
    }else{
      //si no es valido, envia el foco al inicio del formulario
      const startForm = document.querySelector("#cuentanos");
      if (startForm) {
        startForm.tabIndex = -1;
        startForm.focus();
        setTimeout(() => startForm.removeAttribute("tabindex"), 1000);
      }
    }
  }

  const alCerrarModal = () => {
    MicroModal.close("formulario-enviado", {
      awaitCloseAnimation: true
    });
    setTextArea('');
    setEnviar(false)

    ocultarModal();
  }

  return (
    <>
      <label htmlFor="cuentanos" className="q7-18 onbackground-var1--color  my-3">{sugerencia.descripcion} <span className="asterisco">*</span> </label>
      <textarea
        id="cuentanos"
        className={`focusable-primary onsurface-var2--color onsurface-var2--placeholder textarea mt-3 ${enviar && !textArea && 'textarea-error'}`}
        onChange={(e) => { setTextArea(e.target.value) }}
        placeholder={sugerencia.placeholder}
        value={textArea}
      ></textarea>

      <span
      role={"alert"}
      aria-live="assertive" 
      className={`text-14 ${enviar ? !textArea ? 'alert_error' : 'hidden' : 'hidden'}`}>
        {sugerencia.error}
      </span>

      <div className="flex justify-end my-5">
        <button
          className="focusable-primary boton-pequenio boton-amarillo boton_hover_morado "
          onClick={() => { enviarFormulario() }}
        >
          {
            preloader ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              textosInterfaz.boton_enviar
          }
        </button>
      </div>

      {enviar &&
        <ModalFormEnviado alCerrarModal={alCerrarModal} textosInterfaz={textosInterfaz} />
      }
    </>
  )
}

export default SugerenciasYComentarios;

SugerenciasYComentarios.propTypes = {
  /**
    * Objeto que contiene los textos de cada pregunta del formulario.
    */
  info: PropTypes.shape({
    sugerencia: PropTypes.shape({
      descripcion: PropTypes.string,
      error: PropTypes.string,
      placeholder: PropTypes.string
    })
  }).isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Función que envia la información del formulario al correo. 
   * @type{Function(sugerenciaComentario:string):void} -callback
   */
  enviarASoporte: PropTypes.func.isRequired,
  /**
 * booleano que contiene el preloader para el llamado de la API EmailAPI
 */
  preloader: PropTypes.bool.isRequired,
  /**
   * Booleano que indica si debe aparecer la ventana modal de enviado
   */
  mostrarModal: PropTypes.bool.isRequired,
  /**
    * Función que oculta la ventana modal
    */
  ocultarModal: PropTypes.func.isRequired,
}