import axios from "axios";

export class AccionesLibroAPI {

 

  // DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
  /**
   * @typedef {{
"idLibro": string,
"title": string,
"subject": "JUV039060 JUVENILE FICTION / Social Themes / Friendship",
"description": string,
"author": string,
"publisher": string,
"contributor": string,
"tag1": string,
"tag2": string,
"tag3": string,
"tag4": string,
"tag5": string,
"dba1":string,
"nivelUsuarioLector":string,
"interactivo": boolean,
"gratis": boolean,
"sonido": boolean,
"tiempoLectura": string,
"edad": string,
"curso": string,
"valores": string,
"compatibilidad": string,
"genero": string,
"portada": string,
"screenshot1": string,
"screenshot2": string
"screenshot3": string,
"recomendado1": {
  id: string,
  portada: string,
  titulo: string,
  interactivo: boolean,
  gratis: boolean,
  sonido: boolean
},
 "recomendado2": {
  id: string,
  portada: string,
  titulo: string,
  interactivo: boolean,
  gratis: boolean,
  sonido: boolean
},
 "recomendado3": {
  id: string,
  portada: string,
  titulo: string,
  interactivo: boolean,
  gratis: boolean,
  sonido: boolean
},
"linkBlog": string,
"cursos": [], //arreglo de los cursos que tienen el libro activo
"clubs": [], //arreglo de los clubs que tienen el libro activo
"favorito": boolean
}} Libro
**/

  /**
     * @typedef {{
      idLibros:Array<String>,
      idCursos:Array<String>|null,
      accion:"Mostrar"|"Ocultar",
      favorito:boolean,
      idClubs:Array<String>|null,
      codigoEstudiantes:Array<String>|null
      }} AccionesLibro
**/

/**
 * @type {AccionesLibro}
 */
static accionesPorDefecto = {
    accion:'Ocultar',
    favorito:0,
    idClubs:[],
    idCursos:[],
    codigoEstudiantes:[],
    idLibros:[]
}

  //---------------------------------------------------------------------------------
  // EMPIEZAN LOS SERVICIOS

  /**
* Agrega o elimina uno o varios libros con respecto a uno o varios clubs 
* @param {Array<String>} idLibros - libros a agregar
* @param {Array<String>} idClubs - clubs en donde se  agregaran
* @param {"Mostrar"|"Ocultar"} accion - la accion que se desea EJ:"Mostrar"
* @returns {Promise<Libro[]>} Libro[]
*/
  static async agregarRemoverAClub(idLibros, idClubs, accion) {
    const body = {...AccionesLibroAPI.accionesPorDefecto, ...{
      idLibros:idLibros || [],
      idClubs:idClubs || [],
      accion
    }}
    try {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.defaults.withCredentials = true;
      const response = await axios({
        method: 'POST',
        url: 'libros/accionesLibros',
        data: body
      })
      const mensajeRespuesta = response.data?.info;
      if (response.data?.status && response.data?.status === 1) {
        return mensajeRespuesta ? mensajeRespuesta : 'Exito';
      } else {
        return new Error(response.data?.info || 'Error');
      }

    } catch (error) {
      return Promise.reject(error);
    }

  }

 /**
* Agrega o elimina uno o varios libros con respecto a uno o varios cursos 
* @param {Array<String>} idLibros - libros a agregar
* @param {Array<String>} idCursos - cursos en donde se  agregaran
* @param {"Mostrar"|"Ocultar"} accion - la accion que se desea EJ:"Mostrar"
* @returns {Promise<Libro[]>} Libro[]
*/
static async agregarRemoverACursos(idLibros, idCursos, accion) {
  const body = {...AccionesLibroAPI.accionesPorDefecto, ...{
    idLibros:idLibros || [],
    idCursos:idCursos || [],
    accion
  }}
  try {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    const response = await axios({
      method: 'POST',
      url: 'libros/accionesLibros',
      data: body
    })
    const mensajeRespuesta = response.data?.info;
    if (response.data?.status && response.data?.status === 1) {
      return mensajeRespuesta ? mensajeRespuesta : 'Exito';
    } else {
      return new Error(response.data?.info || 'Error');
    }

  } catch (error) {
    return Promise.reject(error);
  }

}

 /**
*  Agrega o elimina uno o varios libros a favoritos del usuario activo en la sesión.
* @param {Array<String>} idLibros - libros a agregar
* @param {Array<String>} idCursos - cursos en donde se  agregaran
* @param {"Mostrar"|"Ocultar"} accion - la accion que se desea EJ:"Mostrar"
* @returns {Promise<Libro[]>} Libro[]
*/
static async agregarRemoverAFavoritos(idLibros, esFavorito) {
  const body = {...AccionesLibroAPI.accionesPorDefecto, ...{
    idLibros:idLibros || [],
    favorito:esFavorito?1:0
  }}
  try {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    const response = await axios({
      method: 'POST',
      url: 'libros/accionesLibros',
      data: body
    })
    const mensajeRespuesta = response.data?.info;
    if (response.data?.status && response.data?.status === 1) {
      return mensajeRespuesta ? mensajeRespuesta : 'Exito';
    } else {
      return new Error(response.data?.info || 'Error');
    }

  } catch (error) {
    return Promise.reject(error);
  }

}

  /**
* metodo que realiza todas las acciones posibles para los libros. 
* @param {AccionesLibro} acciones - Acciones
* @returns {Promise<string>} mensaje de respuesta
*/
  static async multiplesAcciones(acciones) {
    if(acciones?.favorito){
      acciones.favorito = acciones.favorito===true?1:0;
    }
    const body = {...AccionesLibroAPI.accionesPorDefecto, ...acciones}
    // console.log(body)
    try {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.defaults.withCredentials = true;
      const response = await axios({
        method: 'POST',
        url: 'libros/accionesLibros',
        data: body
      })
      const mensajeRespuesta = response.data?.info;
      if (response.data?.status && response.data?.status === 1) {
        return mensajeRespuesta ? mensajeRespuesta : 'Exito';
      } else {
        return new Error(response.data?.info || 'Error');
      }

    } catch (error) {
      return Promise.reject(error);
    }

  }
}
