import { useState } from 'react';
import PreguntasFrecuentes from './preguntas/PreguntasFrecuentes';
import Formularios from './formularios/Formularios';
import PropTypes from 'prop-types';
import { Miga, MigasDePan } from "../globales/MigaDePan";
import { useTrackedState } from '../../store';

/**
 *  Este componente recibe una serie de propiedades que permiten renderizar la pantalla de Ayuda. Además, renderiza los componentes PreguntasFrecuentes y Formularios
 * @returns AyudaComponent
 */
const AyudaComponent = (props) => {
  const { textosInterfaz, preguntasFrecuentes, tiposDeProblema, textosFormulariosSoporte, enviarFormulario, mostrarModal,
    ocultarModal, preloader } = props;

  //Estados del componente
  const [ayudaActiva, setAyudaActiva] = useState('Preguntas frecuentes');

  const state = useTrackedState();

  return (
    <>
      <MigasDePan>
        <Miga
          texto={textosInterfaz?.miga_de_pan || 'Ayuda'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>
      <div className="contenido">
        <h1 className="titulos-principales">{textosInterfaz.titulo}</h1>
        {
          state?.planActivo?.tipoUsuario !== 'estudiante' &&
          <p className="informacion-principal">
            {textosInterfaz.descripcion}
          </p>
        }

        < div className="flex pt-10 pb-5 ">
          <button
            aria-current={ayudaActiva === 'Preguntas frecuentes' ? 'page' : ''}
            className={`focusable-red ${ayudaActiva === 'Preguntas frecuentes' ? "boton-justo boton-morado" : "boton-justo  boton-morado_desactivado"}`}
            onClick={() => setAyudaActiva('Preguntas frecuentes')}>
            {textosInterfaz.titulo_preguntas}
          </button>
          {
            state?.planActivo?.tipoUsuario !== 'estudiante' &&
            <button
              aria-current={ayudaActiva === 'Formulario de soporte' ? 'page' : ''}
              className={`focusable-red ${ayudaActiva === 'Formulario de soporte' ? "boton-justo boton-morado ml-5" : "boton-justo  boton-morado_desactivado ml-5"}`}
              onClick={() => setAyudaActiva('Formulario de soporte')}>
              {textosInterfaz.titulo_formulario}
            </button>
          }
        </div>

        <div className="xl:grid xl:grid-cols-12 gap-4">
          {/* CONTENIDO */}
          <div className="col-span-8 border-t onsurface-var2--border-color pt-10">
            {
              state?.planActivo?.tipoUsuario !== 'estudiante' ?
                <>
                  {
                    ayudaActiva === 'Preguntas frecuentes' ?
                      <>
                        <PreguntasFrecuentes textosPreguntasFrecuentes={preguntasFrecuentes} />
                      </>
                      :
                      <>
                        <Formularios
                          textosFormulariosSoporte={textosFormulariosSoporte}
                          tiposDeProblema={tiposDeProblema}
                          textosInterfaz={textosInterfaz}
                          enviarFormulario={enviarFormulario}
                          preloader={preloader}
                          mostrarModal={mostrarModal}
                          ocultarModal={ocultarModal}
                        />
                      </>
                  }
                </>
                :
                <>
                  <PreguntasFrecuentes textosPreguntasFrecuentes={preguntasFrecuentes} />
                </>
            }

          </div>

          {/* INFO DE CONTACTO */}
          {
            state?.planActivo?.tipoUsuario !== 'estudiante' &&
            <div className="col-span-4 pt-8 xl:pt-10 xl:px-5 q7-18 onbackground-var2--color">
              <p className="raleway-700 primary-inv-var2--color text-18">{textosInterfaz.datos_contacto}</p>

              <div className="flex items-center mt-3 ">
                <span className="icon-correo text-20 mt-0.5 mr-3"></span>
                <a className="break-words focusable-primary" href="mailto:contacto@makemake.com.co"> contacto@makemake.com.co</a>
              </div>
              <div className="flex my-3">
                <span className="icon-whatsapp  text-20 mt-1 mr-3"></span>
                <div className="flex flex-col">
                  <a href="https://wa.link/vuju91" className="block focusable-primary">+57 313 4509992</a>
                  <a href="https://wa.link/aozzbk" className="block focusable-primary">+57 350 2367037</a>
                </div>
              </div>

              <p className="raleway-700 primary-inv-var2--color text-18">{textosInterfaz.datos_contacto_eeuu}</p>

              <div className="flex mt-3">
                <span className="icon-correo text-20 mt-1 mr-3"></span>
                <div className="flex flex-col">
                  <a className="break-words focusable-primary" href="mailto:acorrea@lectorum.com" > acorrea@lectorum.com</a>
                  <a className="break-words focusable-primary" href="mailto:makemake@lectorum.com" > makemake@lectorum.com</a>
                </div>
              </div>

              <div className="flex my-3">
                <span className="icon-phone text-20 mr-3"></span>
                <div className="flex flex-col">
                  <a href="tel:+1(201)5592200" className="block focusable-primary">+ 1 (201) 559-2200</a>
                  <a href="tel:+1(800)3455946" className="block focusable-primary">+ 1 (800) 345-5946</a>
                </div>
              </div>
            </div>
          }

        </div>
      </div >


    </>
  )
}

export default AyudaComponent;

AyudaComponent.propTypes = {
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * Es un arreglo de objetos que tiene la infomación de cada opción en el acordeón de preguntas frecuentes
   */
  preguntasFrecuentes: PropTypes.arrayOf(PropTypes.shape({
    descripcion: PropTypes.string,
    titulo: PropTypes.string,
    titulo_video: PropTypes.string,
  })).isRequired,

  /**
   * array que trae los títulos de los formularios de soporte a los que tiene acceso nuestro usuario loggeado. Esto se hace para que se creen las opciones del select de nuestro formulario de soporte.
   */
  tiposDeProblema: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
   * objeto que trae la información de los formularios de soporte que tiene acceso el usuario que está loggeado en el idioma activo.
   */
  textosFormulariosSoporte: PropTypes.shape({
    titulo: PropTypes.string,
    info: PropTypes.object
  }).isRequired,

  /**
   * Es un objeto que contiene las funciones de enviar formulario. 
   */
  enviarFormulario: PropTypes.shape({
    enviarProblemaLibro: PropTypes.func,
    enviarProblemaPlataforma: PropTypes.func,
    enviarDudasVentas: PropTypes.func,
    enviarSugerenciasYComentarios: PropTypes.func
  }).isRequired,

  /**
   * Booleano que indica si debe aparecer la ventana modal de enviado
   */
  mostrarModal: PropTypes.bool.isRequired,

  /**
   * Función que oculta la ventana modal
   */
  ocultarModal: PropTypes.func.isRequired,

  /**
   * booleano que contiene el preloader para el llamado de la API EmailAPI
   */
  preloader: PropTypes.bool.isRequired
}