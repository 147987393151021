import axios from "axios";

export class AutenticacionAPI{

      // DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
    /**
     * @typedef {{
      * "correo": string,
      * "contrasena": string,
  }} CredencialesProfesor
  **/

    /**
     * @typedef {{
     * "rol": string,
     * "codigo": string,
  }} CredencialesEstudiante
  **/


    /**
     * Inicia la sesión de un usuario con una credenciales de tipo CredencialesProfesor o CredencialesEstudiante, si se autentica correctammente se retorna un objeto con la información del usuario, si no se autentica se retorna un objeto con un mensaje de error.
     * @param {CredencialesEstudiante | CredencialesProfesor} credenciales 
     * @returns {Promise<Object>} retorna un objeto con el estado de la sesión.
     */

    static async login(credenciales){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'/login',
                data:JSON.stringify(credenciales)
             }) 
             return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async logout(){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'/logout',
             }) 
             return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

/**
 * Verifica si el usuario actual esta autenticado o no
 * @returns {Promise<Boolean>} Retorna verdadero si esta autenticado, de lo contrario retornará falso.
 */
  static async estaAutenticado(data){
    const {abortSignal} = data || {};
        /* const respuestaEsperada = {
            mensaje:'El usuario no está autenticado',
            estado:0
        }; */

        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:'/sesion/verificarSesion',
                signal:abortSignal
             }) 
             const sesionActualActiva = response?.data?.sesionActual?.activa;
             if(Boolean(sesionActualActiva)===false){
                    return false;
             }
             return true;
        } catch (error) {
            return Promise.reject(error);
        }
    }

}