import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DetallesSesionesAPI } from "../../../api/estadisticas/DetallesSesionesAPI";
import { useQueryParams } from "../../../containers/helpers/hooks/locationHooks";
import { useTrackedState } from "../../../store";
import { FiltroFechas } from "../../../utilities/gestion_fechas/FiltrosFechas";
import { Calendario } from "../../globales/Calendario";
//componentes
import { MigasDePan, Miga } from "../../globales/MigaDePan";
import TablaDetallePorDia from "./TablaDetallePorDia";
import moment from "moment";
import { CargandoInformacion } from "../../globales/estadisticas/CargandoInformacion";
import TituloSeccion from "../../globales/estadisticas/TituloSeccion";
import { DescargarReporte } from "../Reportes/DescargarReporte";
import { ESTADISTICAS_COLUMNAS_TABLAS } from "../../../containers/estadisticas/tablas-reportes/ColumnasTablas";
import { normalizeString } from "../../../utilities/StringUtilities";
import PropTypes from "prop-types";
/**
 * Este componente tiene la función de cargar todas las estadísticas de sesiones en plataforma que se encuentran en una fecha especifica, la cual es leída por URL en el parametro 'fecha' (revisar [Consideraciones](#consideraciones-globales) para información detallada), si no encuentra la fecha, carga las estadísticas del día actual. Por otro lado también se encarga de transformar los datos estadísticos a formato excel para que puedan ser descargados por el usuario.
 */
const DetallePorDiaComponent = (props) => {
  const { traducciones } = props;
  const { miga_de_pan, detalle_dia } = traducciones;
  const { titulo, descripcion, resumen, tabla } = detalle_dia;

  const state = useTrackedState();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [estadisticas, setEstadisticas] = useState({
    sesiones: [],
    sesionesFiltradas:[],
    totalSesiones: 0
  })
  const [filtros, setFiltros] = useState({
    fecha: ''
  })
  const [fechaUsuario, setFechaUsuario] = useState('');

  const [cargando, setCargando] = useState({
    estadisticas: false
  })




  
  const filtrarPorInput = ({ value }) => {
    if (value && value.trim().length > 0) {
      const coincidencias = estadisticas?.sesiones.filter(sesion => normalizeString( sesion?.nombreUsuario)?.includes(normalizeString(value)));
      setEstadisticas({
        ...estadisticas,
        sesionesFiltradas: coincidencias
      })
    } else {
      setEstadisticas({
        ...estadisticas,
        sesionesFiltradas: estadisticas?.sesiones
      })
    }

  }

    /**
   * transforma la información suministrada por las APIs a una tabla que se puede descargar como excel.
   * @returns tabla para reporte
   */
     const generarInformacionTabla = () => {
      const columnas = ESTADISTICAS_COLUMNAS_TABLAS.sesionesDia.map(col => {
        const traduccion = traducciones?.detalle_dia?.tabla[`${col?.traduccion}`]
        return { nombreColumna: traduccion || col.textoPorDefecto }
      })
  
      const filas = [];
      estadisticas?.sesiones.forEach(sesion => {
        let fila = [];
        ESTADISTICAS_COLUMNAS_TABLAS.sesionesDia.forEach(col => {
          let valor = sesion[`${col?.propiedad}`];
          if( col?.propiedad === 'fecha'){
            valor = moment(valor).locale(state?.idioma).format('hh:mm A');
          }
          else if( col?.propiedad === 'sede'){
            valor = valor?.nombre;
          }
          else if( col?.propiedad === 'curso'){
            if(Array.isArray(sesion[`${col?.propiedad}`])){
              valor = sesion[`${col?.propiedad}`].map(curso=>curso?.nombre).join(',')
            }else{
              valor = valor?.nombre;
            }
          }
          fila.push({ valor: valor })
        })
        filas.push(fila);
      })
  
      const tabla = {
        columnas: columnas,
        filas: filas
      }
      return tabla;
    }

  
  useEffect(() => {
    setFechaUsuario(FiltroFechas.formatoUsuario(state?.idioma, filtros?.fecha))
  }, [state?.idioma, filtros?.fecha])


  useEffect(() => {
    const abortController = new AbortController();
    const cargarEstadisticas = async () => {
      setCargando({
        ...cargando,
        estadisticas: true
      })
      await DetallesSesionesAPI.obtenerSesionesPorDia({fecha:filtros?.fecha, abortSignal:abortController.signal })
        .then(resultado => {
          setEstadisticas({
            ...estadisticas,
            sesiones: resultado,
            sesionesFiltradas:resultado,
            totalSesiones: resultado?.length
          })
          setCargando({
            ...cargando,
            estadisticas: false
          })
        })
        .catch(error => console.log(error));
    }
    if(filtros?.fecha){
      cargarEstadisticas();
    }

    return ()=>{
      abortController.abort();
      setCargando({
        ...cargando,
        estadisticas: true
      })
    }
  }, [filtros?.fecha])


  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.estadisticas || 'Estadisticas'}
          alSeleccionar={() => {
            history.push('/estadisticas')
          }} />
        <Miga
          texto={miga_de_pan?.sesiones || 'Sesiones de lectura'}
          alSeleccionar={() => {
            history.push('/estadisticas/sesiones-de-lectura')
          }} />
        <Miga
          texto={`${miga_de_pan?.detalle_de_dia || 'detalle de dia'} (${fechaUsuario})`}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{`${titulo} (${fechaUsuario})`}</h1>
        <p className="informacion-principal">{descripcion}</p>
        <div className="my-2 w-full sm:w-fit">
          <Calendario
            fechaMaxima={moment().format('YYYY-MM-DD')}
            fechaMinima={state?.datosInstitucion?.fechaInicioSuscripcion || ''}
            fechaPorDefecto={queryParams.get('fecha') || FiltroFechas.hoy(state?.idioma, 'hoy').fechaInicio}
            zIndex={9999}
            modoMultiple={false}
            modoRango={false}
            placeholder=''
            alCambiar={(fecha) => setFiltros({ ...filtros, fecha: fecha })}

          />
        </div>
        <hr className="my-8" aria-hidden={true}/>
        {Boolean(cargando?.estadisticas) === true ?
          <CargandoInformacion />

          : <>
            <div className=" mb-8 text-center  lg:text-left">
              <p className='q5-32 red-inv-var2--color '>
                <span className='icon-star-full text-30 mr-3'></span>
                {estadisticas?.totalSesiones}  {estadisticas?.totalSesiones !== 1 ? resumen.plural : resumen.singular}
              </p>
              <p className="r5-14-negro4A">{resumen.descripcion}</p>
            </div>

            
            <TituloSeccion
              titulo={tabla?.titulo || 'Sesiones'}
              fecha={fechaUsuario}
              input={{
                placeHolder: detalle_dia?.buscador?.placeholder ||  'Buscar por nombre de usuario',
              }}
              alEscribir={(value) => {
                filtrarPorInput(value)
              }}
              elementosPersonalizados={
                <div className="my-2 w-full flex  justify-center sm:justify-end">
                  <DescargarReporte
                    nombreArchivo={`${titulo|| 'Detalle de sesiones por día'} (${fechaUsuario})`}
                    tablaInformacion={generarInformacionTabla()}
                  />
                </div>
              }
            />


            <div className="fondoTabla  mt-5 xl:px-10 xl:py-5 rounded-lg">
              <TablaDetallePorDia
                datos={estadisticas?.sesionesFiltradas}
                textosTabla={{ tabla: tabla }}
              />
            </div>

          </>
        }

      </div>
    </>
  )
}

export default DetallePorDiaComponent;

DetallePorDiaComponent.propTypes = {
  /**
   * Objeto con los textos que se deben renderizar en la sección
   */
  traducciones: PropTypes.object.isRequired,
}