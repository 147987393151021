import { useEffect, useState } from 'react';
//Store
import { useTrackedState, useDispatch } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Funciones
import agregarPropiedadChecked from './../../utilities/function/agregarPropiedadChecked';
import buscarElementos from './../../utilities/function/buscarElementos';
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
import generalCallApi from './../helpers/generalCallApi';
import listarArray from './../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
import randomNumber from '../../utilities/function/randomNumber';
//Componentes
import CrearClubComponent from './../../components/clubs/CrearClubComponent';
import SkeletonClubCrear from '../../components/clubs/Skeletons/SkeletonClubCrear';
import PropTypes from 'prop-types';

/**
 * Container que recibe la función de descartar cambios, el estado isLoadingCursos y el estado isLoadingEstudiantes. Este container retorna el componente  CrearClubComponent al cual se le pasa una serie de propiedades que le permiten renderizar la pantalla de crear club. Las funciones principales de este container son actualizar los datos, buscar y eliminar estudiantes, buscar libros y guardar datos temporalmente.
 * @returns {JSX.Element}  CrearClubComponent
 */
const CrearClub = (props) => {
  const { descartarCambios, isLoadingCursos, isLoadingEstudiantes } = props;

  //Estados globales
  const state = useTrackedState();
  const { datosCursos, datosEstudiantes, datosInstitucion, temporal } = state;
  const dispatch = useDispatch();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';


  //Estados del componente    
  //Datos del club
  const [archivo, setArchivo] = useState(null);
  const [descripcion, setDescripcion] = useState('');
  const [icono, setIcono] = useState(null);
  const [nombre, setNombre] = useState('');


  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [estudiantesElegidos, setEstudiantesElegidos] = useState({
    ids: [],
    datos: []
  })
  const [filtros, setFiltros] = useState({
    sede: { id: null, nombre: '' },
    curso: { id: null, nombre: '' }
  });
  const [instanciaDatosEstudiantes, setInstanciaDatosEstudiantes] = useState(isLoadingEstudiantes);
  const [librosElegidos, setLibrosElegidos] = useState({
    ids: [],
    datos: []
  })
  const [hayLimiteLibros, setHayLimiteLibros] = useState(false);
  const [hayDatosDescartar, setHayDatosDescartar] = useState(false);
  const [resultadoEstudiantes, setResultadosEstudiantes] = useState({ hayResultados: false, resultados: [] });
  const [resultadoLibros, setResultadosLibros] = useState({ hayResultados: false, resultados: [] });
  const [sedesInstituto, setSedesInstituto] = useState([]);
  const [todosLosEstudiantes, setTodosLosEstudiantes] = useState([]);

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('clubs');


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    async function actualizarLibros() {
      await actualizarlibrosInstituto()
    };
    actualizarLibros();

    if (!!temporal?.clubs) {
      const infoClub = temporal.clubs;


      setHayDatosDescartar(true);

      setArchivo(infoClub.archivo);
      setNombre(infoClub.nombre);
      setDescripcion(infoClub.descripcion);
      setIcono(infoClub.icono);
      setLibrosElegidos(infoClub.libros);
      setEstudiantesElegidos(infoClub.estudiantes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: filtros.sede.id })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, filtros.sede.id])


  useEffect(() => {
    if (!!datosEstudiantes) {
      const datosPropiedadCheck = { ...datosEstudiantes };
      const dataActualizada = inicializarChecked({ data: datosPropiedadCheck })
      resultadosEstudiantes({ data: dataActualizada });
      setInstanciaDatosEstudiantes(dataActualizada);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosEstudiantes])


  //Actualiza la sede asignada
  const actualizarCurso = ({ idCurso, idSede, value }) => {
    let nombreSede = datosCursos[idSede].nombre;
    const nombreCurso = datosCursos[idSede].cursos.find(curso => curso.id === idCurso).nombre;

    setFiltros({
      sede: { id: idSede, nombre: nombreSede },
      curso: { id: idCurso, nombre: nombreCurso }
    })

    buscarEstudiantes({ idCurso, idSede, value })
  };


  //Actualiza el descripcion del club
  const actualizarDescripcion = ({ descripcion }) => setDescripcion(descripcion);


  //Actualiza el descripcion del club
  const actualizarIcono = ({ icono }) => setIcono(icono);


  //Actualizar lista de estudiantes seleccionados
  const actualizarEstudiantesElegidos = ({ e, estudiante }) => {
    let estudiantes = [...estudiantesElegidos.datos];
    const resultados = elegirElementosCheck({ e, elemento: estudiante, datosActuales: estudiantes })

    const { ids, datos } = resultados;
    const estudiantesSeleccionados = { ids, datos };

    let instanceDatos = [...resultadoEstudiantes.resultados];

    //Coloca todos los elementos con checked igual a false
    instanceDatos.forEach((elemento, index) => instanceDatos[index] = { ...elemento, checked: false });

    ids.forEach(id => {
      instanceDatos.forEach((instanceEstudiante, index) => {
        if (instanceEstudiante.id === id) {
          instanceDatos[index] = { ...instanceEstudiante, checked: true }
        }
      })
    })

    setEstudiantesElegidos(estudiantesSeleccionados);
    setResultadosEstudiantes({ ...resultadoEstudiantes, resultados: instanceDatos })
  }


  //Actualizar lista de libros seleccionados
  const actualizarLibrosElegidos = ({ e, libro }) => {
    let limiteLibros = false;
    let libros = [...librosElegidos.datos];
    if (libros.length < 5 || !e.target.checked) {
      const resultados = elegirElementosCheck({ e, elemento: libro, datosActuales: libros, id: 'idLibro' });


      const { ids, datos } = resultados;
      const librosSeleccionados = { ids, datos };

      let instanceDatos = [...resultadoLibros.resultados];

      //Coloca todos los elementos con checked igual a false
      instanceDatos.forEach((elemento, index) => instanceDatos[index] = { ...elemento, checked: false });

      ids.forEach(id => {
        instanceDatos.forEach((instanceLibros, index) => {
          if (instanceLibros.idLibro === id) {
            instanceDatos[index] = { ...instanceLibros, checked: true }
          }
        })
      })

      setLibrosElegidos(librosSeleccionados);
      setResultadosLibros({ ...resultadoEstudiantes, resultados: instanceDatos })
    } else {
      limiteLibros = true;
    }

    setHayLimiteLibros(limiteLibros)
  }


  //Actualiza el nombre del club
  const actualizarNombre = ({ nombre }) => setNombre(nombre);


  //Actualiza el nombre del club
  const actualizarlibrosInstituto = async () => {
    const respuesta = await generalCallApi({
      pathname: '/libros/datosLibros',
      properties: { idSede: null }
    });
    const error = respuesta.status === 0;

    if (!error) {
      buscarLibros({ value: '', datos: respuesta.data })
    }
  };


  //Actualiza la sede asignada
  const actualizarSede = ({ idSede, value }) => {
    if (idSede !== filtros.sede.id) {
      const nombreSede = datosCursos[idSede].nombre;

      setFiltros({
        sede: { id: idSede, nombre: nombreSede },
        curso: { id: null, nombre: '' }
      });

      buscarEstudiantes({ idSede, value })
    };
  };


  //Buscar estudiantes
  const borrarEstudiante = ({ idEstudiante }) => {
    let estudiantes = [...estudiantesElegidos.datos];
    let idEstudiantes = [...estudiantesElegidos.ids];

    const posicion = estudiantes.findIndex(estudiante => estudiante.id === idEstudiante);
    estudiantes.splice(posicion, 1);
    idEstudiantes.splice(posicion, 1);


    setEstudiantesElegidos({
      datos: estudiantes,
      ids: idEstudiantes
    });
  }


  //Buscar estudiantes
  const buscarEstudiantes = ({ cursoObligatorio, idCurso, idSede, value }) => {
    if (!!instanciaDatosEstudiantes) {
      const sede = !!idSede ? idSede : filtros.sede.id;
      const curso = cursoObligatorio === true ? idCurso : !!idCurso ? idCurso : filtros.curso.id;
      const datos = !!sede ? instanciaDatosEstudiantes : todosLosEstudiantes;

      let resultados = buscarElementos({ isCursos: true, sede, curso, value, datos, propiedad: 'estudiantes' })

      estudiantesElegidos.ids.map(id => {
        const indexEstudiante = resultados.resultados.findIndex(estudiante => estudiante.id === id);
        if (indexEstudiante !== -1) {
          resultados.resultados[indexEstudiante] = {
            ...resultados.resultados[indexEstudiante],
            checked: true
          }
        }
        return id;
      })


      const resultadoBusqueda = {
        hayResultados: resultados.hayResultados,
        resultados: resultados.resultados
      }

      setResultadosEstudiantes(resultadoBusqueda);
    }
  }


  //Buscar estudiantes
  const buscarLibros = ({ value, datos }) => {
    const libros = !!datos ? datos : resultadoLibros.resultados;
    let resultados = buscarElementos({ value, datos: libros });

    const resultadoBusqueda = {
      hayResultados: resultados.hayResultados,
      resultados: resultados.resultados
    }

    setResultadosLibros(resultadoBusqueda)
  }


  //Inicializar la propiedad checked de los elementos
  const inicializarChecked = ({ data }) => {
    const newData = { ...data }
    for (const key in data) {
      let sede = newData[key];
      for (const keySede in sede.cursos) {
        let cursos = sede.cursos[keySede];
        const nuevoDatoEstudiante = agregarPropiedadChecked({ data: cursos.estudiantes });

        newData[key] = {
          ...newData[key], cursos: {
            ...newData[key].cursos,
            [keySede]: { ...cursos, estudiantes: nuevoDatoEstudiante }
          }
        }
      }
    }

    return newData
  }


  //Guarda los datos del club temporlamente
  const guardarDatosTemporalmente = async () => {
    const iconoRandom = randomNumber({ minimo: 1, maximo: 5 });

    const infoClub = {
      archivo: archivo,
      descripcion: descripcion,
      estudiantes: estudiantesElegidos,
      icono: !!icono ? icono : iconoRandom,
      libros: librosElegidos,
      nombre: nombre
    }

    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "clubs",
      value: infoClub
    });
  }


  //Listar cursos de la sede
  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }


  //Almacena los datos del input file
  const obtenerDatosInput = ({ archivo, nombre }) => {
    setArchivo({
      archivo: archivo,
      nombre: nombre
    });
  }


  //Listar resultados
  const resultadosEstudiantes = ({ data }) => {
    const datos = listarObject({ object: data, idSede: filtros.sede.id, idCurso: filtros.curso.id, isCursos: true, propiedad: 'estudiantes' });
    const { cursoBuscar, errorCurso, errorSede, resultados, sedeBuscar, todosLosElementos } = datos;


    const datosSede = { id: sedeBuscar, nombre: !errorSede && !!sedeBuscar ? instanciaDatosEstudiantes[sedeBuscar].nombre : '' }
    const datosCurso = { id: cursoBuscar, nombre: !errorSede && !!sedeBuscar && !!cursoBuscar && !errorCurso ? instanciaDatosEstudiantes[sedeBuscar].cursos[cursoBuscar].nombre : '' }

    const usuario = state?.datosDeUsuario;
    if (rolUsuario === 'profesor') {
      const estudiantes = resultados.resultados || [];
      const estudiantesDelProfe = estudiantes?.reduce((acc, curr) => {
        const profes = curr?.nombreProfesor?.split(',');
        if (profes.includes(usuario?.nombre)) {
          acc.push(curr);
        }
        return acc;
      }, [])

      setResultadosEstudiantes({ hayResultados: estudiantesDelProfe?.length > 0, resultados: estudiantesDelProfe });
    } else {
      setResultadosEstudiantes(resultados);

    }
    setFiltros({ ...filtros, sede: datosSede, curso: datosCurso });
    setTodosLosEstudiantes(todosLosElementos);
  }


  return (
    <>
      {
        isReady && !isLoadingCursos && !isLoadingEstudiantes ?
          <CrearClubComponent
            actualizarCurso={actualizarCurso}
            actualizarDescripcion={actualizarDescripcion}
            actualizarEstudiantesElegidos={actualizarEstudiantesElegidos}
            actualizarLibrosElegidos={actualizarLibrosElegidos}
            actualizarNombre={actualizarNombre}
            actualizarIcono={actualizarIcono}
            actualizarSede={actualizarSede}
            archivoClub={archivo}
            borrarEstudiante={borrarEstudiante}
            buscarEstudiantes={buscarEstudiantes}
            cursosSede={cursosSede}
            descartarCambios={descartarCambios}
            descripcionClub={descripcion}
            estudiantesElegidos={estudiantesElegidos}
            filtros={filtros}
            guardarDatosTemporalmente={guardarDatosTemporalmente}
            hayDatosDescartar={hayDatosDescartar}
            hayLimiteLibros={hayLimiteLibros}
            iconoClub={icono}
            librosElegidos={librosElegidos}
            nombreClub={nombre}
            numeroLibrosElegidos={librosElegidos.datos.length}
            obtenerDatosInput={obtenerDatosInput}
            resultadoEstudiantes={resultadoEstudiantes}
            resultadoLibros={resultadoLibros}
            sedesInstituto={sedesInstituto}
            textosInterfaz={textosInterfaz}
          />
          :
          <SkeletonClubCrear />
      }
    </>
  )
}

export default CrearClub;

CrearClub.propTypes = {
  /**
   * funcion callback que borra los datos temporales.
   */
  descartarCambios: PropTypes.func.isRequired,
  /**
    * Es un booleano que indica si la información del curso ya cargo. 
    */
  isLoadingCursos: PropTypes.bool.isRequired,
  /**
  * Es un booleano que indica si la información de los estudiantes ya cargaron. 
  */
  isLoadingEstudiantes: PropTypes.bool
}