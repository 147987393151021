
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Funciones
import concatenarDatos from './../../utilities/function/concatenarDatos';
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
import generalCallApi from './../helpers/generalCallApi';
import listarArray from './../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
import transformarDatosEstudiantes from './../../utilities/function/estudiantes/transformarDatosEstudiantes';
//Componentes
import EstudianteVerificarComponente from './../../components/estudiantes/VerificarEstudiante/index';
import PropTypes from 'prop-types';

/**
 * Este container tiene toda la lógica para guardar y modificar los datos de los estudiantes que se están creando,
 * para finalmente crearlos una vez que se hayan verificado todos los datos. 
 */
const EstudiantesVerificar = (props) => {
  const { descartarCambios, isLoadingCursos } = props;

  //Estados globales
  const state = useTrackedState();
  const { datosInstitucion, datosCursos, temporal } = state;
  const dispatch = useDispatch();


  //Estados del componente
  const [cursosEstudiantes, setCursosEstudiantes] = useState({
    curso: '',
    numeroCursos: null
  });
  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [cursoSeleccionado, setCursoSeleccionado] = useState({ id: null, nombre: '' })
  const [datosEstudiantesNuevos, setDatosEstudiantesNuevos] = useState(null);
  const [estudiantesElegidos, setEstudiantesElegidos] = useState({
    keys: [],
    total: 0,
    estudiantes: [],
  })
  const [modoVista, setModoVista] = useState(null);
  const [preloader, setPreloader] = useState(false);
  const [respuestaApiCrear, setRespuestaApiCrear] = useState({
    isError: null,
    mensaje: ''
  });
  const [sedeSeleccionada, setSedeSeleccionada] = useState({ id: null, nombre: '' });
  const [sedesInstituto, setSedesInstituto] = useState([]);

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estudiantes');


  //Otras librerias
  const history = useHistory();


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes });
    setSedesInstituto(listaSedes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!temporal?.estudiantes) {
      const datosTemporales = { ...temporal.estudiantes.datos };
      const modoVista = temporal.estudiantes.modoActivoCrear;

      let cursosEstudiantesTemporales = [];
      let sedesEstudiantesTemporales = [];

      let estudiantes = [];

      for (const key in datosTemporales) {
        let estudiante = datosTemporales[key];
        if (modoVista === 'masivo') {
          cursosEstudiantesTemporales.push(estudiante.nombreCurso);
          sedesEstudiantesTemporales.push(estudiante.nombreSede);

          estudiante = {
            ...estudiante,
            id: estudiante.key,
            checked: false
          }
        }
        estudiantes.push(estudiante);
      }
      cursosEstudiantesTemporales = [...new Set(cursosEstudiantesTemporales)];
      sedesEstudiantesTemporales = [...new Set(sedesEstudiantesTemporales)];
      setCursosEstudiantes({
        cursos: cursosEstudiantesTemporales.join(", "),
        numeroCursos: cursosEstudiantesTemporales.length,
        sedes: sedesEstudiantesTemporales.join(", "),
        numeroSedes: sedesEstudiantesTemporales.length
      })

      const datosNuevos = modoVista === 'individual' ? estudiantes[0] : estudiantes;
      setDatosEstudiantesNuevos(datosNuevos);
      setModoVista(modoVista);
    } else {
      if (respuestaApiCrear.isError === null) {
        history.push('/estudiantes');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal]);



  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: sedeSeleccionada.id });
      setCursosSede(cursos.resultados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, sedeSeleccionada.id])




  useEffect(() => {
    if (respuestaApiCrear.isError === false) {
      dispatch({
        type: "SET_DATA",
        property: "temporal",
        value: {}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiCrear.isError]);


  //Actualiza la sede en la que se encuentra el estudiante
  const actualizarCurso = ({ idSede, idCurso }) => {
    const datosSede = !!datosCursos[idSede]
      ? { id: idSede, nombre: datosCursos[idSede].nombre }
      : { id: null, nombre: '' };

    const nombreCurso = datosCursos[idSede].cursos.find(curso => curso.id === idCurso).nombre;
    const datosCurso = { id: idCurso, nombre: nombreCurso };

    setSedeSeleccionada(datosSede)
    setCursoSeleccionado(datosCurso);
  };


  //Actualiza la sede en la que se encuentra el estudiante
  const actualizarSede = ({ idSede }) => {
    if (idSede !== sedeSeleccionada.id) {
      const nombreSede = datosCursos[idSede].nombre;
      setSedeSeleccionada({
        id: idSede,
        nombre: nombreSede
      });
      setCursoSeleccionado({ id: null, nombre: '' });
    };
  };


  //Cambiar estudiantes elegidos
  const cambiarEstudiantesElegidos = ({ e, estudiante }) => {
    let estudiantes = [...estudiantesElegidos.estudiantes];

    const resultados = elegirElementosCheck({ e, elemento: estudiante, datosActuales: estudiantes })
    const { ids, datos } = resultados;

    const estudiantesSeleccionadosNuevos = {
      keys: ids,
      total: datos.length,
      estudiantes: datos
    }

    let instanceDatos = [...datosEstudiantesNuevos];

    instanceDatos.forEach((elemento, index) => instanceDatos[index] = { ...elemento, checked: false })

    ids.forEach(id => {
      instanceDatos.forEach((instanciaProfesor, index) => {
        if (instanciaProfesor.id === id) {
          instanceDatos[index] = { ...instanciaProfesor, checked: true }
        };

        return instanciaProfesor;
      });
    })

    setDatosEstudiantesNuevos(instanceDatos);
    setEstudiantesElegidos(estudiantesSeleccionadosNuevos);
  }


  const cancelarAccionMultiSelect = () => {
    const datos = [...datosEstudiantesNuevos];
    datos.map(estudiante => estudiante.checked = false);

    setDatosEstudiantesNuevos(datos);

    setEstudiantesElegidos({
      keys: [],
      total: 0,
      estudiantes: [],
    });

    setCursoSeleccionado({ id: null, nombre: '' });
    setSedeSeleccionada({ id: null, nombre: '' });
  }


  //Funcionalidad crear estudiantes
  const crearEstudiantes = async () => {
    if (!preloader && !respuestaApiCrear.isError !== false) {
      setPreloader(true);
      setRespuestaApiCrear({
        isError: null,
        mensaje: ''
      });

      const datos = modoVista === 'individual' ? [datosEstudiantesNuevos] : datosEstudiantesNuevos;

      const pathname = '/estudiantes/crearEstudiantes';
      const properties = datos;

      const resultados = await generalCallApi({ pathname, properties });
      const error = resultados.status === 0;


      if (!error) {
        await obtenerDatosDB();
      }

      setPreloader(false);
      setRespuestaApiCrear({
        isError: error,
        mensaje: resultados.info
      });
    }
  }


  //Eliminar varios estudiantes temporales al tiempo
  const eliminarEstudiantes = ({ keys }) => {
    const estudiantesNuevos = { ...temporal.estudiantes.datos };
    const arrayKeys = !!keys ? keys : estudiantesElegidos.keys;
    arrayKeys.forEach(key => {
      delete estudiantesNuevos[key];
    });

    reiniciarEstadosMultiSelect({ datos: estudiantesNuevos });
  }


  //Eliminar 1 estudiante temporal
  const eliminarEstudianteIndividual = ({ key }) => eliminarEstudiantes({ keys: key })


  //Listar los cursos dependiendo de la sede activa
  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }



  //Mover estudiantes de curso y sede
  const moverEstudiantes = () => {
    let estudiantesNuevos = { ...temporal.estudiantes.datos };
    const seleccionados = [...estudiantesElegidos.keys];

    seleccionados.forEach(key => {
      estudiantesNuevos[key] = {
        ...estudiantesNuevos[key],
        idCurso: cursoSeleccionado.id,
        idSede: sedeSeleccionada.id,
        nombreCurso: cursoSeleccionado.nombre,
        nombreSede: sedeSeleccionada.nombre
      }
    })

    reiniciarEstadosMultiSelect({ datos: estudiantesNuevos });
  }


  //Llamada al api para obtener los datos de la base de datos
  const obtenerDatosDB = async () => {
    const result = await generalCallApi({ pathname: '/estudiantes/obtenerListadoEstudiantes' });
    let datos = result.data;

    const error = result.status === 0;
    if (!error) {
      const value = await transformarDatosEstudiantes({ datos: result.data, sedes: datosInstitucion.sedes })
      // Actualizar datos en el store 
      dispatch({
        property: 'datosEstudiantes',
        type: 'SET_DATA',
        value: value
      });
    }

    const resultCursos = await generalCallApi({ pathname: '/cursos/obtenerCursos' });
    const errorCursos = result.status === 0;
    if (!errorCursos) {
      // Actualizar datos en el store 
      const value = await concatenarDatos({ datos: resultCursos.data, section: 'cursos', propiedadJson: 'profesores' })
      dispatch({
        property: 'datosCursos',
        type: 'SET_DATA',
        value: value
      });
    }

    return { datos, error };
  }




  //Reinicia todos los estados relacionados con el multiselect
  const reiniciarEstadosMultiSelect = ({ datos }) => {
    const estudiantesTemporalesActualizados = {
      ...temporal.estudiantes,
      datos,
    }

    setEstudiantesElegidos({
      keys: [],
      total: 0,
      estudiantes: [],
    })

    setCursoSeleccionado({ id: null, nombre: '' });
    setSedeSeleccionada({ id: null, nombre: '' });

    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "estudiantes",
      value: estudiantesTemporalesActualizados
    })
  }


  return (
    <>
      {
        !isLoadingCursos && isReady && !!datosEstudiantesNuevos &&
        <EstudianteVerificarComponente
          actualizarCurso={actualizarCurso}
          actualizarSede={actualizarSede}
          cambiarEstudiantesElegidos={cambiarEstudiantesElegidos}
          cancelarAccionMultiSelect={cancelarAccionMultiSelect}
          crearEstudiantes={crearEstudiantes}
          cursosEstudiantes={cursosEstudiantes}
          cursosSede={cursosSede}
          cursoSeleccionado={cursoSeleccionado}
          datosEstudiantesNuevos={datosEstudiantesNuevos}
          descartarCambios={descartarCambios}
          eliminarEstudianteIndividual={eliminarEstudianteIndividual}
          eliminarEstudiantes={eliminarEstudiantes}
          estudiantesElegidos={estudiantesElegidos}
          modoVista={modoVista}
          mostrarMultiSelect={estudiantesElegidos.total !== 0}
          moverEstudiantes={moverEstudiantes}
          preloader={preloader}
          respuestaApiCrear={respuestaApiCrear}
          sedeSeleccionada={sedeSeleccionada}
          sedesInstituto={sedesInstituto}
          textosInterfaz={{
            botones: textosInterfaz.botones,
            descartar_cambios: textosInterfaz.descartar_cambios,
            estudiante_creado: textosInterfaz.estudiante_creado,
            miga_de_pan: textosInterfaz.miga_de_pan,
            modal_eliminar: textosInterfaz.modal_eliminar,
            modal_exito: textosInterfaz.modal_exito,
            modal_mover: textosInterfaz.modal_mover,
            multi_accion: textosInterfaz.multi_accion,
            no_hay_estudiantes: textosInterfaz.no_hay_estudiantes,
            verificar_datos: textosInterfaz.verificar_datos,
            notificacion: textosInterfaz.notificacion,
          }}
        />
      }
    </>
  )
}
export default EstudiantesVerificar;

EstudiantesVerificar.propTypes = {
  /**
   * Función que permite limpiar el estado de los datos del estudiante y cancela la creación de un etudiante nuevo
   */
  descartarCambios: PropTypes.func.isRequired, 
  /**
   * Indica si el componente esta cargando o no los cursos del instituto
   */
   isLoadingCursos: PropTypes.bool.isRequired,
}
