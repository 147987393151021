import FiltrosData from "./FiltrosData"

/**
 * @typedef {
{
  name:'',
  translate:'',
  analisis:[
    {
      icon:string | null,
      name:string,
      translate:string,
      porcentaje:number,
      numeroDeLibros:number
    }
  ]
}
  } Analisis
 */

  /**
  * Esta función devuelve todo el análisis estadístico correspondiente, a partir de una lista de libros que se pasará como parámetro.
 * @param {Libro[]} libros - Arreglo de los libros que se quiere analizar
 * @returns {Analisis[]} - Objeto con el análisis estadístico de la colección
   */
const AnalisisColeccion = (libros) => {
  //arreglo de  TODAS las categorias del filtro
  let dataFiltros = JSON.parse(JSON.stringify(FiltrosData));
  //arreglo de SOLO ALGUNAS categorias del filtro anterior
  let filtrosParaAnalizar = dataFiltros.filter(item => item.type !== 'checkbox' && item.name !== 'search');
 

  let analisis = [];

  filtrosParaAnalizar.forEach(filtro => {
    //por cada categoria de filtro se obtiene las siguientes propiedades
    let {options, name, translate, apiProperty} = filtro;
    
    //Por cada categoria de filtro  se crea un objeto que lleva el nombre categoria, su translate y el arreglo analisis que lleva= [nombre de opcion dentro de la categoria, su translate y el porcentaje]
    let filterOption = {name: name, translate: translate,  analisis: []};
    
    /* const totalLibros =(apiProperty==='tiempoLectura')?totalLibrosCategoriaRango(apiProperty,options, libros):totalLibrosCategoriaComun(apiProperty,options, libros); */

    //variable que recibe un valor(el total de libros que se encontro para cada opcion dentro de cada categoria) de la funcion totalLibrosCategoriaComun
    const totalLibros =totalLibrosCategoriaComun(apiProperty,options, libros);
 

    options.forEach(option=>{
      let optionBooks = [];
      optionBooks = libros.filter(libro => libro[apiProperty] === option.name);
      /* if( apiProperty==='tiempoLectura'){
        optionBooks = libros.reduce((acc,curr)=>{
          const rangoId = option.name;
          const rangos = option.name.split('-');
          const min = rangos[0] || 0;
          const max = rangos[1] || 0;
          const esNumero = Number.parseInt(curr[apiProperty]);
          const tiempoLecturaLibro = esNumero?esNumero:0;
          if(rangoId == '>20' && tiempoLecturaLibro > 20){
            acc.push(curr)
          }
          else if(rangoId !== '>20' && tiempoLecturaLibro>=min && tiempoLecturaLibro <=max){
            acc.push(curr)
          }
          return acc;
        },[])
      }else{
        optionBooks = libros.filter(libro => libro[apiProperty] === option.name);
      /* } */
      const calculo = totalLibros>0?((100/totalLibros))*optionBooks.length:0;
      let percentageObject = {
        name: option.name,
        icon: option.icon,
        translate:option.translate,
        porcentaje:Number.isInteger(calculo)?calculo:calculo.toFixed(2),
        numeroDeLibros: optionBooks.length,
      }
      filterOption.analisis.push(percentageObject);
    })
    analisis.push(filterOption);
  })

  return analisis;
}

/**
 * Devuelve el total de libros que se encontro para categorias que no tengan rango
 */
const totalLibrosCategoriaComun = (propiedad="", opciones=[], libros=[])=>{
  return opciones.reduce((acc,curr)=>{
    let optionBooks = libros.filter(libro => libro[propiedad] === curr.name);
      acc+= optionBooks.length;
      return acc;
  },0)
}

/**
 * Devuelve el total de libros que se encontro para categorias con rango
 */
 const totalLibrosCategoriaRango = (propiedad="", opciones=[], libros=[])=>{
   let optionBooks = [];
  return opciones.reduce((acc,actualOpcion)=>{
    if( propiedad==='tiempoLectura'){
      optionBooks = libros.reduce((acc,actualLibro)=>{
        const rangoId = actualOpcion.name;
        const rangos = actualOpcion.name.split('-');
        const min = rangos[0] || 0;
        const max = rangos[1] || 0;
        const esNumero = Number.parseInt(actualLibro[propiedad]);
        const tiempoLecturaLibro = esNumero?esNumero:0;
        if(rangoId == '>20' && tiempoLecturaLibro > 20){
          acc.push(actualLibro)
        }
        else if(rangoId !== '>20' && tiempoLecturaLibro>=min && tiempoLecturaLibro <=max){
          acc.push(actualLibro)
        }
        return acc;
      },[])
    }

      acc+= optionBooks.length;
      return acc;
  },0)
}

export default AnalisisColeccion;