import React, { useEffect, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import { useTrackedState } from '../../../store';
import moment from 'moment';
import useObtenerSubsecciones from '../../../containers/helpers/hooks/useObtenerSubsecciones';
import { AccesibilidadContext } from '../../../contexts/AccesibilidadContext';

Chart.register(...registerables, zoomPlugin);
Chart.defaults.elements.point.radius = 1.5;

const TIPOS_DATA = {
  fechas: 'fechas',
  numero: 'numero'
}

let idGrafica = 0;

/**
 * Componente que recibe una serie de propiedades que sirven para renderizar la grafica de estadísticas.
 * @returns Grafica
 */
const Grafica = (props) => {
  const { titulo, datos, limites, ejes } = props;
  const {textosSubSecciones:traducciones} = useObtenerSubsecciones('estadisticas');
  const [id, setId] = useState(`grafica-${idGrafica}`);
  const [grafica, setGrafica] = useState(Chart.getChart(id));
  const state = useTrackedState();
  const [datosGrafica, setDatosGrafica] = useState({
    labels: [],
    datasets: []

  })

  const accesibilidad = useContext(AccesibilidadContext);
  const [colorGrafica, setColorGrafica] = useState('rgba( 0, 0, 0, 0.1)');
  const [colorFuenteGrafica, setColorFuenteGrafica] = useState('#7c7c7c');

  const options = useMemo(() => ({
    scales: {
      x: {
        position: 'bottom',
        type: 'time',
        ticks: {
          autoSkip: true,
          autoSkipPadding: 50,
          maxRotation: 90,
          color: colorFuenteGrafica,
        },
        min: limites?.min,
        max: limites?.max,
        time: {
          minUnit: 'day',
          unit: 'day',
          stepSize: 1,
          tooltipFormat:'dddd, MMMM D, YYYY',
          displayFormats: {
            day: 'DD MMM YY'
          }
        },
        title: {
          display: true,
          text: ejes?.x?.titulo || '',
          color: colorFuenteGrafica,
          font: {
            size: 14,
            weight: 'bold',
            family: 'sans-serif',
          }
        },
        grid: {
          borderColor: colorGrafica,
          color: colorGrafica,
        },
      },
      y: {
        position: 'left',
        ticks: {
          callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
          color: colorFuenteGrafica,
        },
        grid: {
          borderColor: colorGrafica,
          color: colorGrafica,
        },
        title: {
          display: true,
          text: ejes?.y?.titulo || '',
          color: colorFuenteGrafica,
          font: {
            size: 14,
            weight: 'bold',
            family: 'sans-serif',
          }
        }
      },
    },
    plugins: {
      zoom: {
        limits: {
          x: {
            min: ejes?.x?.tipo === TIPOS_DATA.fechas ? moment(limites?.min || '').subtract(1, 'd').valueOf() : limites?.min || null,
            max: ejes?.x?.tipo === TIPOS_DATA.fechas ? moment(limites?.max || '').add(1, 'd').valueOf() : limites?.max || null,
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        }
      },
      title: {
        display: true,
        position: 'top',
        color: colorFuenteGrafica,
        text: titulo || 'N/A',
        font: {
          size: 20
        }
      },
      legend: {
        labels: {
            color: colorFuenteGrafica,
        }
      }
    },
  }), [grafica, titulo, colorGrafica])

  useEffect(()=>{
    const color =accesibilidad.temaActual === "clasico" ? 'rgba( 0, 0, 0, 0.1)': '#ffff00';
    setColorGrafica(color);
    const colorFuente = accesibilidad.temaActual === "clasico" ? '#7c7c7c': '#ffff00';
    setColorFuenteGrafica(colorFuente)
  },[accesibilidad.temaActual])

  useEffect(() => {
    try {
      setDatosGrafica({
        labels: datos?.etiquetas || [],
        datasets: datos?.datos.map(dato => {
          return {
            data: dato?.valores || [],
            label: dato?.etiquetaTitulo || 'N/A',
            fill: false,
            lineTension: 0.1,
            borderColor: dato?.color || '#000000',
            backgroundColor: dato?.color || '#000000',
            borderWidth: 2,
          }
        })
      })
    } catch (error) {
      console.log(error)
    }

  }, [datos, accesibilidad.temaActual])

  useEffect(() => {
    idGrafica += 1;
  }, [])

  useEffect(() => {
    setGrafica(Chart.getChart(id));
  }, [id])



  const restablecerGrafica = () => {
    grafica.reset()
    grafica.resetZoom();
  }



  return (
    <div >
      <Line
        id={id}
        datasetIdKey={id}
        type="line"
        data={datosGrafica}
        options={options}
        width={800}
        height={500}
        lang={state?.idioma}
        role="img"
        aria-label={`El texto alternativo para este gráfico se encuentra en la siguiente tabla de datos.`}
      />
      <div className='p-2 flex center w-full'>
        <button 
          aria-hidden={true}
          className='boton-morado boton_hover_morado_oscuro boton-justo' 
          onClick={() => restablecerGrafica()}
        >
          {traducciones?.grafica?.botones?.reiniciar_grafica || 'Restaurar grafica'}
        </button>
      </div>
    </div>
  );
};

export default Grafica;

Grafica.propTypes = {
  /**
   * String que equivale al titulo de la grafica
   */
  titulo: PropTypes.string,

  /**
   * conjunto de datos estadisticas
   */
  datos: PropTypes.shape({
    etiquetas: PropTypes.arrayOf(PropTypes.string),
    datos: PropTypes.arrayOf(PropTypes.shape({
      etiquetaTitulo: PropTypes.string,
      valores: PropTypes.array,
      color: PropTypes.string
    }))
  }),
  /**
   * limite horizontal en que puede navegar el usuario (zoom y pan)
   */
  limites: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  /**
  * Se refiere a los ejes de la grafica, en el que puede definirse el nombre del eje y el tipo de dato que este va a llevar.
  * los posibles tipos son <'fechas'|'numeros'>
  */
  ejes: PropTypes.shape({
    x: PropTypes.shape({
      tipo: PropTypes.oneOf(['fechas', 'numeros']),
      titulo: PropTypes.string,
    }),
    y: PropTypes.shape({
      tipo: PropTypes.oneOf(['fechas', 'numeros']),
      titulo: PropTypes.string,
    }),
  })
}