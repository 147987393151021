import React, { useState, useEffect, useRef, useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import useObtenerTraducciones from './containers/helpers/hooks/useObtenerTraducciones';
//Hooks y funciones personalizadas
import ValidacionesIniciales from './containers/ValidacionesIniciales';
//Store
import { useDispatch, useTrackedState } from './store';
//Componentes
import ScrollToTop from './utilities/ScrollToTop';
import Header from './containers/header';
import SideNav from './containers/sidenav';
import LoginContainer from './containers/login';
import Institutos from './containers/institutos';
import Recursos from './containers/recursos/';
import Ayuda from './containers/ayuda/index';
import MiCuenta from './containers/miCuenta/index';
import Error_404 from './containers/Error_404/index';
import Footer from './containers/footer/';
import Cursos from './containers/cursos';
import Clubs from './containers/clubs';
import Estudiantes from './containers/estudiante';
import Estadisticas from './containers/estadisticas';
import useIsMobile from './containers/helpers/hooks/useIsMobile';
import 'react-notifications-component/dist/theme.css';
import Libros from './containers/libros';
import Profesores from './containers/profesores/index';
import { RutaProtegida } from './components/globales/RutaProtegida';
import { AutenticacionAPI } from './api/AutenticacionAPI';
import { VisorAPI } from './api/VisorAPI';
import { ModalSesionCerrada } from './components/globales/modales/ModalSesionCerrada';
import { useHistory } from 'react-router-dom';
import { LocalStorage } from './services/local-storage/LocalStorage';
import { CancelRequestController } from './utilities/cancel-request-controller';
import { StudentProfileRouter } from './features/student-profile/router';
import { Classes } from './features/classes';
import { Reader } from './features/reader';
import { BookInfo } from './components/libros/ficha';

const App = () => {
  const state = useTrackedState();
  const location = useLocation();
  const { isPending } = state;
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * REDIRECCIONAR AL LOGIN DE PLAN LECTOR DE PRODUCCIÓN
   */
  // useEffect(()=>{
  //   window.location ='https://planlector.makemake.com.co/login';
  // },[])
  

  useEffect(() => {
    let abortController = new CancelRequestController();
    let isUnMounted = false;
    // /**
    //  * Función que verifica y actualiza el estado de la sesión tanto en el storage como en el state
    //  */
    const updateAuthState = async () => {
      if(isUnMounted){return}
      await AutenticacionAPI
        .estaAutenticado({abortSignal:abortController.signal})
        .then(resultado => {
          if (resultado === false) {
            if (LocalStorage.obtenerEstadoSesion() === LocalStorage.SESION_ESTADOS.activa) {
              LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.finalizadaPorInactiva);
              history.push('/login');
              window.location.reload();
            }
          } else {
            LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.activa);
          }
          dispatch({
            type: "SET_DATA_SESION",
            property: "sesionActual",
            value: { estado: LocalStorage.obtenerEstadoSesion() }
          })
        })
        .catch(error => console.log(error))
    }
    updateAuthState();

    // // verificar el estado de sesión cada 15 segundos
    let temporizadorVerificacion = setInterval(() => {
      updateAuthState();
    }, 15000);

    const updateOnVisibilityChange = ()=>{
      if (document.visibilityState === 'visible') {
        updateAuthState();
      }
    }
    // //verificar tan pronto se abra la pestaña en caso de que el usuairo este en otras paginas.
    document.addEventListener('visibilitychange', updateOnVisibilityChange)

    return () => {
      clearInterval(temporizadorVerificacion);
      isUnMounted = true;
      abortController.abort();
      document.removeEventListener('visibilitychange', updateOnVisibilityChange)
    }

  }, [location])

  useEffect(() => {
    // al cargar la app se guarda en el state el estado de la sesion que esta guardado en el local storage
    const cargarSesionStorage = () => {
      dispatch({
        type: "SET_DATA_SESION",
        property: "sesionActual",
        value: { estado: LocalStorage.obtenerEstadoSesion() }
      })
    }
    cargarSesionStorage();

    //evalua cada 10 segundos las estadisticas de los libros.
    setInterval(VisorAPI.bpc, 10000);
  }, [])



  return (
    <>
      <ReactNotification isMobile={true} breakpoint={992} id={'notificaciones'} />
      <ValidacionesIniciales />
      <ModalSesionCerrada
        visible={
          (
            location.pathname === '/login' &&
            state?.sesionActual?.estado === LocalStorage.SESION_ESTADOS.finalizadaPorInactiva
          )
            ? true : false}
      />
      {
        !isPending &&
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={MainRoutes} />
            <Route exact path="/login" component={LoginContainer} />
            <Route exact path="/login/recuperar-contrasena" component={LoginContainer} />
            <Route exact path="/visor/:idLibro"
              render={(props) =>
                <RutaProtegida rolesPermitidos={['rector', 'profesor', 'estudiante']}>
                  <Reader />
                </RutaProtegida>}
            />
            <Route exact path="/:page" component={MainRoutes} />
            <Route exact path="/:page/:section" component={MainRoutes} />
            <Route exact path="/:page/:section/:subsection" component={MainRoutes} />
            <Route exact path="/:page/:section/:subsection/:subsubsection" component={MainRoutes} />
            <Route exact path="/:page/:section/:subsection/:subsubsection/:subsubsubsection" component={MainRoutes} />
          </Switch>
        </ScrollToTop>
      }
    </>
  );
}

const MainRoutes = () => {
  const [abrirMenu, setAbrirMenu] = useState(false);
  const [alturaHeader, setAlturaHeader] = useState();
  const [alturaMenu, setAlturaMenu] = useState(0);
  const [leftMenuLateral, setLeftMenuLateral] = useState(0);

  const refFooter = useRef(null);
  const refColMenu = useRef(null);
  const isMobile = useIsMobile();
  const state = useTrackedState();

    //Hooks personalizados
    const { isReady, textosSeccion: textosInterfaz } = useObtenerTraducciones('header');

  useEffect(() => {
    const actualizarNuevaAltura = () => {
      if (!!refFooter.current) {
        const topFooter = isMobile ? 0 : refFooter.current.getBoundingClientRect().top;
        let heightContainerMenu;

        if (!isMobile) {
          heightContainerMenu = topFooter < window.innerHeight
            ? window.innerHeight - (window.innerHeight - topFooter) - alturaHeader
            : window.innerHeight - alturaHeader;
        } else {
          heightContainerMenu = window.innerHeight - alturaHeader
        }
        setAlturaMenu(heightContainerMenu);
      }
    }

    actualizarNuevaAltura();
    window.addEventListener('resize', actualizarNuevaAltura);
    window.addEventListener('scroll', actualizarNuevaAltura);
    return () => {
      window.removeEventListener('resize', actualizarNuevaAltura);
      window.removeEventListener('scroll', actualizarNuevaAltura);
    }
  }, [alturaHeader, refFooter, isMobile])


  const cerrarAbrirMenu = (value) => {
    const validacion = typeof value !== 'object' ? value : !abrirMenu;
    setAbrirMenu(validacion);
    setLeftMenuLateral(validacion ? 0 : -refColMenu.current.offsetWidth);
  }

  const actualizarAlturaHeader = (header) => {
    setAlturaHeader(header);
  }

  const validacionEscogiendoLibros = () => {
    const eligiendoLibros = Boolean(state?.planActivo?.eligiendoLibros);
    let validar = true;
    if (eligiendoLibros) {
      validar = false;
    }
    return validar;
  }

  const skipLink = (event) => {
    event.preventDefault();
    const container = document.querySelector("#main");
    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute("tabindex"), 1000);
    }
  }

  return (
    <>
    {isReady &&
      <button onClick={(e) => skipLink(e)} className='skip-link w-full'>{textosInterfaz?.skip || 'Saltar al contenido'}</button>
    }
      <ReactNotification isMobile={true} breakpoint={992} />
      <Header cerrarAbrirMenu={cerrarAbrirMenu} actualizarAlturaHeader={actualizarAlturaHeader} alturaHeader={alturaHeader} />
      <div className="container">
        <div className="grid grid-cols-12">
          <nav
            className="columna-menu transition-left duration-200 z-1000"
            ref={refColMenu}
            style={{ height: alturaMenu, left: leftMenuLateral, top: alturaHeader }}
          >
            <SideNav abrirMenu={abrirMenu} cerrarAbrirMenu={cerrarAbrirMenu} />
          </nav>
          <main id="main" className="col-span-12 lg:col-span-9 xl:col-span-10 pb-10 lg:pb-20" style={{ minHeight: window.innerHeight }}>
            <Switch>
              <Route exact path="/institutos"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Institutos />
                  </RutaProtegida>}
              />
              <Route exact path="/institutos/:section"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Institutos />
                  </RutaProtegida>}
              />
              <Route exact path="/institutos/:section/:subsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Institutos />
                  </RutaProtegida>}
              />

              <Route exact path="/profesores"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Profesores />
                  </RutaProtegida>}
              />
              <Route exact path="/profesores/:section"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Profesores />
                  </RutaProtegida>}
              />
              <Route exact path="/profesores/:section/:subsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Profesores />
                  </RutaProtegida>}
              />
              <Route exact path="/profesores/:section/:subsection/:subsubsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Profesores />
                  </RutaProtegida>}
              />

              <Route exact path="/cursos"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Classes/>
                  </RutaProtegida>}
              />
              <Route exact path="/cursos/:section"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Cursos />
                  </RutaProtegida>}
              />
              <Route exact path="/cursos/:section/:subsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Cursos />
                  </RutaProtegida>}
              />
              <Route exact path="/cursos/:section/:subsection/:subsubsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Cursos />
                  </RutaProtegida>}
              />

              <Route exact path="/clubs"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Clubs />
                  </RutaProtegida>}
              />
              <Route exact path="/clubs/:section"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Clubs />
                  </RutaProtegida>}
              />
              <Route exact path="/clubs/:section/:subsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Clubs />
                  </RutaProtegida>}
              />
              <Route exact path="/clubs/:section/:subsection/:subsubsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Clubs />
                  </RutaProtegida>}
              />

              <Route exact path="/estudiantes"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estudiantes />
                  </RutaProtegida>}
              />
              <Route exact path="/estudiantes/:section"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estudiantes />
                  </RutaProtegida>}
              />
              <Route exact path="/estudiantes/:section/:subsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estudiantes />
                  </RutaProtegida>}
              />
              <Route exact path="/estudiantes/:section/:subsection/:subsubsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estudiantes />
                  </RutaProtegida>}
              />
              <Route exact path="/estudiantes/:section/:subsection/:subsubsection/:subsubsubsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estudiantes />
                  </RutaProtegida>}
              />

              <Route exact path="/recursos"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Recursos />
                  </RutaProtegida>}
              />
              <Route exact path="/ayuda" render={(props) =>
                <RutaProtegida
                  rolesPermitidos={['rector', 'profesor', 'estudiante']}
                  validacionesAdicionales={validacionEscogiendoLibros}>
                  <Ayuda />
                </RutaProtegida>} />

              <Route exact path="/mi-cuenta"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <MiCuenta />
                  </RutaProtegida>}
              />
              <Route exact path="/mi-cuenta/:section"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <MiCuenta />
                  </RutaProtegida>}
              />

              <Route exact path="/libros"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}>
                    <Libros />
                  </RutaProtegida>}
              />
              <Route exact path="/libros/seleccion-libros"
                render={(props) =>
                  <RutaProtegida rolesPermitidos={['rector', 'profesor']}>
                    <Libros />
                  </RutaProtegida>}
              />
              <Route exact path="/fichas/:idLibro/:tituloLibro"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}>
                    <BookInfo />
                  </RutaProtegida>}
              />
              <Route exact path="/fichas/:idLibro"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}>
                    <BookInfo />
                  </RutaProtegida>}
              />

              <Route exact path="/estudiante"
                render={(props) =>
                  <RutaProtegida rolesPermitidos={['estudiante']}>
                    <StudentProfileRouter />
                  </RutaProtegida>}
              />
              <Route exact path="/estudiante/:section"
                render={(props) =>
                  <RutaProtegida rolesPermitidos={['estudiante']}>
                    <StudentProfileRouter />
                  </RutaProtegida>}
              />
              <Route exact path="/estudiante/:section/:subsection"
                render={(props) =>
                  <RutaProtegida rolesPermitidos={['estudiante']}>
                    <StudentProfileRouter />
                  </RutaProtegida>}
              />
              <Route exact path="/estadisticas"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estadisticas />
                  </RutaProtegida>}
              />

              <Route exact path="/estadisticas/:section"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estadisticas />
                  </RutaProtegida>}
              />

              <Route exact path="/estadisticas/:section/:subsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estadisticas />
                  </RutaProtegida>}
              />
              <Route exact path="/estadisticas/:section/:subsection/:subsubsection"
                render={(props) =>
                  <RutaProtegida
                    rolesPermitidos={['rector', 'profesor']}
                    validacionesAdicionales={validacionEscogiendoLibros}>
                    <Estadisticas />
                  </RutaProtegida>}
              />

              <Route component={Error_404} />
            </Switch>
          </main>
        </div>
      </div>
      <Footer ref={refFooter} />
    </>

  )
}

export default App;
