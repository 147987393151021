import axios from "axios";

export class DetallesSesionesAPI {



    // DEFINICIÓN DE LOS TIPOS
    /**
     * @typedef {  
        {
            totalSesionesEnPlataforma: number,
            tiempoPromedioPorSesion: number,
            tiempoPromedioPorSesionProfesores: number,
            totalSesionesEnPlataformaProfesores: number
            datos: 
            [
                {
                fecha: 'yyy-mm-dd',
                numeroDeSesiones: number,
                numeroDeSesionesProfesores: number,
                tiempoPromedioDeLectura: number,
                tiempoPromedioDeLecturaProfesores: number,
                tiempoPromedioEnPlataforma: number,
                tiempoPromedioEnPlataformaProfesores: number,
                librosLeidos: string,
                librosLeidosProfesores: string
               }   
            ]
        } 

  } DetallesSesion
  **/

    /**
     * @typedef{
      {
        "nombreUsuario": string,
        "fecha": string,
        "librosLeidos": number,
        "totalTiempoLectura": string,
        "rol": string,
        "curso": {
            "id": string,
            "nombre": string
        },
        "sede": {
            "id": string,
            "nombre": string
        }
    }
     } DetalleSesionDia
     */



    /**
     * @type {DetallesSesion}
     */
    static DetallesSesionPorDefecto = {
        datos: [],
        tiempoPromedioPorSesion: 0,
        totalSesionesEnPlataforma: 0,
    }




    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

    /**
  * devuelve un objeto con las estadisticas de sesiones para una sede
  * @param {String | undefined} idSede - id de la sede o undefined para obtener todo el instituto
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<DetallesSesion>} DetallesSesion
  */
    static async obtenerPorSede({idSede, fechaInicio, fechaFin, abortSignal=null}) {
        const body = {
            idSede: idSede,
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasDetalleSesiones',
                data: body,
                signal:abortSignal || null
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }
            let detalles = response.data?.data || [DetallesSesionesAPI.DetallesSesionPorDefecto];
            let sesiones = detalles?.datos.map(sesion=>{
                let aux = sesion;
                if(Array.isArray(sesion)){
                    aux = {...sesion[0]}
                }else{
                    aux = sesion;
                }
                aux = {
                    ...aux,
                    librosLeidos:parseInt(aux?.librosLeidos || '0')
                }

                return aux;
            });
            detalles.datos = sesiones;
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }

    /**
  * devuelve un objeto con las estadisticas de sesiones para un curso en especifico
  * @param {String} idCurso - id del curso
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<DetallesSesion>} DetallesSesion
  */
    static async obtenerPorCurso({idCurso, fechaInicio, fechaFin, abortSignal=null}) {
        const body = {
            idCurso: idCurso || '',
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || '',
            idSede: 0
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasDetalleSesiones',
                data: body,
                signal:abortSignal || null
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            let detalles = response.data?.data || [DetallesSesionesAPI.DetallesSesionPorDefecto];
            let sesiones = detalles?.datos.map(sesion=>{
                let aux = sesion;
                if(Array.isArray(sesion)){
                    aux = {...sesion[0]}
                }else{
                    aux = sesion;
                }
                aux = {
                    ...aux,
                    librosLeidos:parseInt(aux?.librosLeidos || '0')
                }

                return aux;
            });
            detalles.datos = sesiones;
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }

    /**
* Devuelve un arreglo con las sesiones que hubo en un día especifico.
* @param {String} fecha - la fecha del día que se quiere obtener las estadisticas
* @returns {Promise<DetalleSesionDia[]>} Arreglo de sesiones en un día
*/
    static async obtenerSesionesPorDia({fecha, abortSignal=null}) {
        const body = {
            fecha: fecha || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasSesionesDia',
                data: body,
                signal:abortSignal || null
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            let sesiones = response?.data?.data;

            if (Array.isArray(response?.data?.data)) {
                return sesiones;
            } else {
                return []
            }

        } catch (error) {
            return Promise.reject(error);
        }

    }

}

