//Hooks y funciones personalizadas
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//componentes
/* import GeneralEstadisticasComponent from "../../components/estadisticas/General/GeneralComponent"; */
import SkeletonGeneralEstadisticas from '../../components/estadisticas/SkeletonsEstadisticas/SkeletonGeneralEstadisticas';
import { EstadisticasComparativas } from '../../components/estadisticas/Comparativo';

export const Comparativo = () => {
  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
    <>
      {
        isReady ?
          <EstadisticasComparativas.Component traducciones={textosInterfaz} />
          :
          <SkeletonGeneralEstadisticas />
      }
    </>
  )
}
