import { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import CajaCreadoContenido from '../globales/cajasCrear/CajaCreadoContenido';
import PropTypes from 'prop-types';
import { AccesibilidadContext, TemasDisponibles } from '../../contexts/AccesibilidadContext';

/**
 * Este componente recibe una serie de props que permite renderizar los cuadros de la vista general de institutos/colegios. Además, usa el componente global CajaCreadoContenido.
 * @returns InstitutoCreado
 */
const InstitutoCreado = (props) => {
  const { resultados, infoSede, botones, cambiarAlturaCajaCreado, estadoAltura } = props;
  const { estadisticas, boton_ver_todo, curso, estudiante, libro, profesor } = infoSede;
  const acc = useContext(AccesibilidadContext);

  const cajaCreado = useRef(null);

  useEffect(() => {
    if (!!cajaCreado.current) {
      actualizarAltura();
      window.addEventListener('resize', actualizarAltura);
      return () => {
        window.removeEventListener('resize', actualizarAltura);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoAltura, cajaCreado]);

  const actualizarAltura = () => {
    cambiarAlturaCajaCreado(cajaCreado.current.scrollHeight);
  }

  return (
    <>
      {
        resultados.map(instituto => {
          return (
            <div key={instituto.id} className="caja-creado overflow-hidden" ref={cajaCreado}>
              <div
                className='caja-creado-titulo boton-color'
                style={{ backgroundColor: acc?.temaActual === TemasDisponibles?.clasico?.id?instituto.color:'var(--background-color)', backgroundImage: `url(/images/backBoton.png)` }}
              >
                <p className="q7-20-blanco text-center una-linea-texto">{instituto.nombre}</p>
              </div>

              <div className="px-5 xl:px-10 ">
                <CajaCreadoContenido link="./cursos" parametrosBusqueda={`?sede=${instituto.id}`} textoLink={boton_ver_todo}>
                  <span className="onsurface-var1--color icon-birrete caja-creado-icono"></span>
                  <p className="q7-16-negro26">
                    {` ${instituto.cursos.length} ${instituto.cursos.length !== 1 ? curso.plural : curso.singular}`}
                  </p>
                </CajaCreadoContenido>
                <CajaCreadoContenido link="./estudiantes" parametrosBusqueda={`?sede=${instituto.id}`} textoLink={boton_ver_todo}>
                  <span className="onsurface-var1--color icon-usuario caja-creado-icono"></span>
                  <p className="q7-16-negro26">
                    {`${instituto.numeroTotalDeEstudiantes} ${instituto.numeroTotalDeEstudiantes !== 1 ? estudiante.plural : estudiante.singular}`}
                  </p>
                </CajaCreadoContenido>
                <CajaCreadoContenido link="./libros" parametrosBusqueda={`?sede=${instituto.id}`} textoLink={boton_ver_todo}>
                  <span className="onsurface-var1--color icon-libro caja-creado-icono"></span>
                  <p className="q7-16-negro26">
                    {`${instituto.numeroDeLibrosAsignados} ${instituto.numeroDeLibrosAsignados !== 1 ? libro.plural : libro.singular}`}
                  </p>
                </CajaCreadoContenido>
                <CajaCreadoContenido link="./profesores" parametrosBusqueda={`?sede=${instituto.id}`} textoLink={boton_ver_todo}>
                  <span className="onsurface-var1--color icon-profesor caja-creado-icono"></span>
                  <p className="q7-16-negro26">
                    {`${instituto.numeroTotalDeProfesores} ${instituto.numeroDeLibrosAsignados !== 1 ? profesor.plural : profesor.singular} `}
                  </p>
                </CajaCreadoContenido>

                <div className="center flex-col mt-5 mb-7" >
                  <Link
                    to={{
                      pathname: "/estadisticas/estadisticas-globales",
                      search: `?sede=${instituto.id}`
                    }}
                    className="alineado-verticalmente"
                  >
                    <span className="icon-estadisticas-barra mr-5 red-inv-var2--color text-22 mt-1"></span>
                    <span className="evento_rojo">{estadisticas}</span>
                  </Link>
                  <Link
                    to={`./institutos/editar-instituto/${instituto.id}`}
                    className="boton-amarillo boton_hover_morado boton-justo mt-6"
                  >
                    {botones.editar.sede}
                  </Link>
                </div>
              </div>
            </div>
          )

        })
      }
    </>
  )
}

export default InstitutoCreado;


InstitutoCreado.propTypes = {
  /**
   * Es un array de objetos que contiene la información de los institutos filtrados por la búsqueda
   */
  resultados: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    cursos: PropTypes.array,
    fechaFinSuscripcion: PropTypes.string, //yyyy-mm-dd
    id: PropTypes.string.isRequired,
    nombre: PropTypes.string,
    numeroDeLibrosAsignados: PropTypes.number,
    numeroTotalDeEstudiantes: PropTypes.number,
    numeroTotalDeProfesores: PropTypes.number,
    paquete: PropTypes.shape({
      id: PropTypes.string.isRequired,
      nombre: PropTypes.string
    })
  })).isRequired,

  /**
   * Es un objeto que trae los textos de interfaz del instituto creado. Este cambia según el idioma activo.
   */
  infoSede: PropTypes.object.isRequired,

  /**
   * Es un objeto que trae los textos de los botones de interfaz. Este cambia según el idioma activo.
   */
  botones: PropTypes.object.isRequired,

  /**
   * Función que actualiza la altura de las cajas creadas
   *  @type{Function(altura:number):void} - callback
   */
   cambiarAlturaCajaCreado: PropTypes.func.isRequired,

   /**
    * Número que indica la altura de la caja del institutoCreado
    */
    estadoAltura: PropTypes.number,
}