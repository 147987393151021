import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

/**
 * Este componente hace referencia a las cajas que aparecen en las pantallas principales de institutos/colegios, cursos y clubs. Recibe una serie de props que permiten retornar dicho elemento, entre esos la propiedad children que se refiere a los elementos hijos que se pasan directamente. 
 * @returns CajaCreadoContenido
 */
const CajaCreadoContenido = (props) => {
  const { children, link, parametrosBusqueda, textoLink, ocultarElementos } = props;
  return (
    <div className="caja-creado-contenido-fila gap-4">
      <div className="alineado-verticalmente w-8/12">
        {children}
      </div>
      {
        Boolean(ocultarElementos?.link) === false &&
        <Link className="focusable-primary font-bold red-inv-var2--color w-4/12 alineado-izquierda-center" to={{
          pathname: link,
          search: parametrosBusqueda
        }}> {textoLink}</Link>
      }
    </div>
  )
}

export default CajaCreadoContenido;

CajaCreadoContenido.propTypes = {
  /**
   * array de elementos que se encuentran dentro de la caja creada
   */
  children: PropTypes.array.isRequired,
  /**
   * string que corresponde al pathmane. Esto dirige a una sección especifica.
   */
  link: PropTypes.string,
  /**
   *  string que corresponde a los parametros de búsqueda. Esto dirige a una sección con un valor de búsqueda determinado. 
   */
  parametrosBusqueda: PropTypes.string,
  /**
   * String que corresponde al texto del evento o link
   */
  textoLink: PropTypes.string,
  /**
   * objeto que condiciona si el evento o texto se debe ver
   */
  ocultarElementos: PropTypes.shape({
    link: PropTypes.bool
  })
}

