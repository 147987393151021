import { useState } from "react";
import Paginacion from "../../globales/Paginacion";
import PropTypes from "prop-types";

/**
 * Este componente recibe los props datosCursos y textosTabla que son necesarios para renderizar la tabla de cursos dentro de la pantalla de verificar instituto/colegio. Además, hace uso del componente global Paginacion.
 * @returns ListaCursos
 */
const ListaCursos = (props) => {
  const { datosCursos, textosTabla } = props;
  const { tabla } = textosTabla;
  const { curso, profesor, num_estudiantes, sin_datos, sin_profesor } = tabla;
  const [datosDivididos, setDatosDivididos] = useState([]);

  const ActualizarDatosDivididos = (value) => setDatosDivididos(value);
  return (
    <section
      role="lista"
      className={`${datosCursos.length > 10 ? "" : "mb-5"}`}
    >
      <ul className="encabezadoTabla  xl:grid grid-cols-12">
        <li className="col-span-1"></li>
        <li tabIndex={0} className="focusable-primary col-span-2">
          {curso}
        </li>
        <li tabIndex={0} className="focusable-primary col-span-4">
          {profesor}
        </li>
        <li tabIndex={0} className="focusable-primary col-span-5">
          {num_estudiantes}
        </li>
      </ul>
      {datosDivididos.length === 0 ? (
        <section className="filaTabla xl:grid-cols-12">
          <section className=" center col-span-12">
            <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
            <p className="raleway-700 red-inv-var2--color text-13">
              {" "}
              {sin_datos}
            </p>
          </section>
        </section>
      ) : (
        datosDivididos.map((dato, index) => {
          return (
            <section
              key={`campo${index}`}
              className="filaTabla xl:grid-cols-12"
            >
              <section className="fila-2-sm1-lg12-xl1 row-span-3  md:row-span-1">
                <span className="icon-star-full text-20 secondary-inv-var1--color block xl:text-center"></span>
              </section>
              <section className="fila-10-sm5-md3-lg6 xl:col-span-2">
                <span className="q7-14-negro26  xl:hidden">{curso}</span>
                <p tabIndex={0} className="focusable-primary r5-13-negro26">
                  {dato.nombre}
                </p>
              </section>
              <section className="fila-10-sm5-md4-lg6 xl:col-span-4 overflow-auto">
                <span className="q7-14-negro26  xl:hidden">{profesor}</span>
                <p
                  tabIndex={0}
                  className={`focusable-primary ${
                    !!dato.profesor ? " r5-13-negro26" : "r7-13-rojo"
                  } break-words `}
                >
                  {!!dato.profesor ? dato.profesor : sin_profesor}
                </p>
              </section>
              <section className="fila-10-sm5-md3-lg6 xl:col-span-5">
                <span className="q7-14-negro26  xl:hidden">
                  {num_estudiantes}
                </span>
                <p
                  tabIndex={0}
                  className={`focusable-primary raleway-700 ${
                    dato.numEstudiantes === 0
                      ? "red-inv-var--color"
                      : "primary-inv-var2--color"
                  }  text-13`}
                >
                  {dato.numEstudiantes}{" "}
                  {dato.numEstudiantes !== 1 ? tabla.plural : tabla.singular}
                </p>
              </section>
            </section>
          );
        })
      )}

      <section className={`alineado-izquierda xl:mt-3`}>
        <Paginacion
          datos={datosCursos}
          datosDivididos={ActualizarDatosDivididos}
        />
      </section>
    </section>
  );
};
export default ListaCursos;

ListaCursos.propTypes = {
  /**
   * Es un array de objetos que contiene la información de los cursos extraídas del excel.
   */
  datosCursos: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string,
      numEstudiantes: PropTypes.number,
      profesor: PropTypes.string, // puede ser null
    })
  ),

  /**
   * Es un objeto que trae los textos del encabezado de la tabla. Este cambia según el idioma activo.
   */
  textosTabla: PropTypes.object.isRequired,
};
