
/**
 * Función que con base a un objeto principal, busca una propiedad en especifico y devuelve los resultados que coincidan con la propiedad
 * @param {{ object:object, idSede:string, idCurso:string, isCursos:bool, propiedad:string }} param de tipo objeto con la información necesaria para la función
  @returns {{
    cursoBuscar: string | null;
    errorCurso: boolean;
    errorSede: boolean;
    sedeBuscar: string | null;
    resultados: {
        hayResultados: boolean;
        resultados: any[];
    };
    todosLosElementos: any[];
  }} - Retorna un objeto con información de los elementos de una colección.
 */
const listarObject = ({ object, idSede, idCurso, isCursos, propiedad }) => {
  const sedeBuscar = !!idSede ? idSede : null;
  const cursoBuscar = !!idCurso ? idCurso : null;
  let errorSede = false;
  let errorCurso = false;

  let todosLosElementos = [];
  for (const sede in object) {
    //Obtener estudiantes de un cursos
    if (isCursos) {
      const cursosSede = object[sede].cursos;
      for (const keyCursos in cursosSede) {
        let estudiantesCurso = cursosSede[keyCursos][propiedad];
        todosLosElementos = todosLosElementos.concat(estudiantesCurso);
      }

    } else {
      const cursosSede = object[sede][propiedad];
      todosLosElementos = todosLosElementos.concat(cursosSede);
    }
  }

  let resultados = {
    hayResultados: true,
    resultados: todosLosElementos
  };


  if (!!sedeBuscar) {
    errorSede = !!object[idSede] ? false : true;

    if (isCursos && !errorSede) {
      if (!!cursoBuscar) {
        errorCurso = !!object[idSede].cursos[idCurso] ? false : true;

        resultados = {
          hayResultados: !!object[idSede].cursos[idCurso],
          resultados: !!object[idSede].cursos[idCurso]
            ? object[idSede].cursos[idCurso][propiedad]
            : []
        };
      } else {
        let estudiantesSede = [];
        const cursosSede = object[idSede].cursos;

        for (const key in cursosSede) {
          const element = cursosSede[key];
          estudiantesSede = estudiantesSede.concat(element[propiedad]);
        }

        resultados = {
          hayResultados: estudiantesSede.length !== 0,
          resultados: estudiantesSede
        };
      }
    } else {
      resultados = {
        hayResultados: !!object[idSede],
        resultados: !!object[idSede]
          ? object[idSede][propiedad]
          : []
      };
    };
  }

  resultados = {
    ...resultados,
    hayResultados: resultados.resultados.length !== 0 ? true : false
  }

  return { cursoBuscar, errorCurso, errorSede, sedeBuscar, resultados, todosLosElementos }
}

export default listarObject;
