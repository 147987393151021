import { memo, useCallback, useEffect,  useMemo,  useState } from "react";
import PropTypes from 'prop-types';
import SelectorDeBusqueda from "../elementosFormularios/SelectorDeBusqueda";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Calendario } from '../Calendario';
import { SedesAPI } from '../../../api/SedesAPI';
import { CursosAPI } from '../../../api/CursosAPI';
import { useDispatch, useTrackedState } from "../../../store";
/**
 * Este componente es un contenedor de los select de filtro de fecha, sede y curso
 * @param {*} props 
 */
const Filtro = memo((props) => {
  const { sedePorDefecto, cursoPorDefecto, calendarioProps, alActualizarFiltros } = props;
  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('estadisticas');
  const [sedes, setSedes] = useState([]);
  const [cursosSede, setCursosSede] = useState([]);
  const [cursos, setCursos] = useState([]);
  const sedesTodas = { id: 'todo', nombre: traducciones?.global?.select?.sedes_todas || 'Todas las sedes' }
  const cursosTodos = { id: 'todo', nombre:  traducciones?.global?.select?.cursos_todos || 'Todos los cursos' }
  const [cargando, setCargando] = useState(true);


  const [sede, setSede] = useState(undefined);
  const [curso, setCurso] = useState(undefined);
  const [fechas, setFechas] = useState([]);
  const [filtros, setFiltros] = useState({
    fechas:[],
    sede:undefined,
    curso:undefined
  })

  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';
  const dispatch = useDispatch();


  const cambiarCurso = ({ opcion }) => {
    setCurso(opcion);
    dispatch({
      type: "SECCION_ESTADISTICAS_FILTROS",
      property: "curso",
      value: opcion?.id
    })
  }
  const cambiarSede = ({ opcion }) => {
    setSede(opcion);
    dispatch({
      type: "SECCION_ESTADISTICAS_FILTROS",
      property: "sede",
      value: opcion?.id
    })
  }

  useEffect(() => {

    const cargarDatos = async () => {
      setCargando(true);
      await SedesAPI.obtenerTodas()
        .then(resultado => {
          setSedes(resultado)
        })
        .catch(error => console.log(error))

        await CursosAPI.obtenerTodos()
        .then(resultado => {
         setCursos(resultado)
        })
        .catch(error => console.log(error))
        setCargando(false);
    }
    cargarDatos();
  }, [])


  useEffect(()=>{
    const sedeEncontrada = sedes.find(sede=>sede?.id === sedePorDefecto);
    const cursoEncontrado = cursos.find(curso=>curso?.id === cursoPorDefecto);
    sedeEncontrada?setSede(sedeEncontrada):setSede(sedesTodas);
    cursoEncontrado?setCurso(cursoEncontrado):setCurso(cursosTodos);
  },[sedes, cursos, sedePorDefecto, cursoPorDefecto])


  useEffect(() => {
    const cargarCursos = async () => {
      if (rolUsuario === 'rector') {
        const cursosDeSede = cursos.filter(curso => curso?.idSede === sede?.id);
        if (cargando == false) {
          setCurso(cursosTodos);
        }
        if (sede?.id !== sedesTodas.id) {
          setCursosSede(cursosDeSede)
        } else {
          setCursosSede([]);
        }
      }
      else {
        if (cargando == false) {
          setCurso(cursosTodos);
        }
        setCursosSede(cursos);
      }

    }
    cargarCursos();
  }, [cursos, sede?.id])


  useEffect(() => {
    setFiltros({
      fechas:fechas,
      sede:rolUsuario ==='rector'?sede:null,
      curso:curso
    })
  }, [ JSON.stringify(fechas), sede?.id, curso?.id])



  useEffect(()=>{
    if(rolUsuario === 'rector'){
      if(filtros?.fechas && filtros?.sede && filtros?.curso){
        alActualizarFiltros&&alActualizarFiltros(filtros)
      }
    }else{
      if(filtros?.fechas && filtros?.curso){
        alActualizarFiltros&&alActualizarFiltros(filtros)
      }
    }
   
  },[JSON.stringify(filtros)])


  return (
    <>
      <div className="md:alineado-verticalmente gap-4 my-3">
        <div className="max-w-full md:max-w-full">
          <Calendario
            fechaMaxima={calendarioProps?.fechaMaxima}
            fechaMinima={calendarioProps?.fechaMinima}
            fechaPorDefecto={calendarioProps?.fechaPorDefecto}
            formato={calendarioProps?.formato}
            modoMultiple={calendarioProps?.modoMultiple}
            modoRango={calendarioProps?.modoRango}
            placeholder={calendarioProps?.placeholder}
            zIndex={calendarioProps?.zIndex}
            alCambiar={(fechas) => {
              setFechas(fechas);
              dispatch({
                type: "SECCION_ESTADISTICAS_FILTROS",
                property: "fechas",
                value: {inicio:fechas[0], fin:fechas[1]}
              })
              calendarioProps?.alCambiar&&calendarioProps?.alCambiar(fechas)
            }}
          />
        </div>
      { rolUsuario === 'rector'&& 
      <>
        <span className="primary-inv-var2--color icon-atras transform rotate-180 hidden md:block"></span>
        <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0">
          <SelectorDeBusqueda
            cambiarElegido={cambiarSede}
            opciones={[sedesTodas, ...sedes]}
            textoNoHayOpciones={traducciones?.comparativa?.select?.sede.no_existe || 'No hay sedes asignadas'}
            textoDefault={traducciones?.comparativa?.select?.sede.default || 'Selecciona una sede'}
            textoElegido={sede?.id === sedesTodas?.id? sedesTodas?.nombre: sede?.nombre}
            zIndex={60}
          />
        </div>
      </>
    }
        <span className="primary-inv-var2--color icon-atras transform rotate-180 hidden md:block"></span>
        <div className="md:w-4/12  xl:w-3/12 mt-5 md:mt-0">
          <SelectorDeBusqueda
            cambiarElegido={cambiarCurso}
            opciones={[cursosTodos, ...cursosSede]}
            textoNoHayOpciones={traducciones?.comparativa?.select?.curso?.no_existe || 'No hay cursos asignados'}
            textoDefault={traducciones?.comparativa?.select?.curso?.default || 'Selecciona un cursos'}
            textoElegido={curso?.id === cursosTodos?.id? cursosTodos?.nombre:curso?.nombre}
            zIndex={59}
          />
        </div>
      </div>

      <hr className="my-8" />
    </>
  )
})
export default Filtro;

Filtro.propTypes = {
  /**
   * todas las propiedades que posee el componente de calendario
   */
  calendarioProps: PropTypes.shape({
    fechaMaxima: PropTypes.string,
    fechaMinima: PropTypes.string,
    fechaPorDefecto: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    formato: PropTypes.string,
    modoMultiple: PropTypes.bool,
    modoRango: PropTypes.bool,
    placeholder: PropTypes.string,
    zIndex: PropTypes.number,
    /**
 * funcion callback que devuelve un arreglo con fechas seleccionadas en el calendario.
 * EJEMPLO
 * @example
 <Calendario
 alActualizarFiltros={(fechas)=>console.log(fechas)}/>
 */
 alCambiar: PropTypes.func
  })
}