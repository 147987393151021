import { useState } from 'react';
/*Componentes */
import DivisionCrud from "../../globales/DivisionCrud";
import Selector from "../../globales/elementosFormularios/Selector";
import InputFile from "../../globales/elementosFormularios/InputFile";
import PropTypes from 'prop-types';

/**
 * Componente que recibe una serie de props necesarios apra renderizar el formulario de crear masivo. Este hace uso de los componentes globales DivisionCrud, Selector e InputFile.
 * @returns CrearProfesorMasivo
 */
const CrearProfesorMasivo = (props) => {
  const { datosValidosModoMasivo, actualizarSedeAsignada, datosTemporalesExcel, excelValido, hayErrorExcel, mostrarAlertaArchivo, obtenerDatosExcel, sedesInstituto, sedeSeleccionada, setExcelValido, textosInterfaz } = props;

  const { sede, subir_excel } = textosInterfaz;

  const [nombreDocumento, setNombreDocumento] = useState('');

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarSede = ({ opcion }) => actualizarSedeAsignada({ idSede: opcion.id });

  return (
    <>
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{sede.titulo}<span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{sede.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarSede}
            desactivado={false}
            estiloTipoInput={true}
            opciones={sedesInstituto}
            textoDefault={textosInterfaz?.sede?.placeholder || 'Selecciona una sede'}
            textoElegido={sedeSeleccionada.nombre}
            zIndex={50}
          />
          {
            Boolean(sedeSeleccionada?.id) === false && datosValidosModoMasivo === false &&
            <p role="alert" aria-live="assertive" className="text-14 animate-fadeIn alert_error">
              {sede?.masivo_error || 'Por favor selecciona una sede'}
            </p>
          }
        </div>
      </DivisionCrud>

      {/* Subir excel */}
      <DivisionCrud>
        <>      
          <h3 className="raleway-700 onbackground-var1--color">{subir_excel.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{subir_excel.descripcion}</p>
          <a href='/documents/plantilla_profesor.xlsx' download="plantilla_profesor.xlsx" className="focusable-primary evento_rojo block mt-3">{subir_excel.descargar_plantilla}</a>
        </>
        <>
          <InputFile
            datoGuardado={datosTemporalesExcel}
            descripcion={
              !!datosTemporalesExcel?.datos?.nombreArchivo ? datosTemporalesExcel.datos.nombreArchivo : subir_excel.descripcion_input
            }
            error={subir_excel.input.excel_no_valido}
            excelValido={excelValido}
            hayErrorExcel={hayErrorExcel}
            instruccion={subir_excel.input.titulo}
            mostrarAlerta={mostrarAlertaArchivo}
            nombreDocumento={nombreDocumento}
            obtenerDatosExcel={obtenerDatosExcel}
            setExcelValido={setExcelValido}
            setNombreDocumento={setNombreDocumento}
            textoError={subir_excel.input.excel_no_valido}
          />
        </>
      </DivisionCrud>
    </>
  )
}
export default CrearProfesorMasivo;

CrearProfesorMasivo.propTypes = {
  /**
   * Booleano que indica si los datos son validos
   */
  datosValidosModoMasivo: PropTypes.bool.isRequired,
  /**
   * función que se encarga de actualizar el id de la sede en la que se desea crear el profesor(es)
   * @type{Function({ idSede : string }):void} - callback
   */
  actualizarSedeAsignada: PropTypes.func.isRequired,
  /**
  * Es un objeto que almacena los datos temporales del excel 
  */
  datosTemporalesExcel: PropTypes.shape({
    datos: PropTypes.object,
    hayDatos: PropTypes.bool
  }).isRequired,
  /**
   * Booleano que indica si el excel es valido
   */
  excelValido: PropTypes.bool.isRequired,
  /**
   * Booleano que indica si el archivo subido es erróneo. Puede tener un valor de null 
   */
  hayErrorExcel: PropTypes.bool,

  /**
   * Booleano que muestra una alerta si el archivo subido es incorrecto
   */
  mostrarAlertaArchivo: PropTypes.bool.isRequired,

  /**
   * función que debe ejecutarse en un evento onChange del input en el que se carga el excel. +
   * @type{Function({ archivo: shape, e: shape, nombre: string }):void }- callback
   */
  obtenerDatosExcel: PropTypes.func.isRequired,

  /**
  * array de objetos que contiene el listado de sedes que el instituto tiene. 
  */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
   *  objeto que cuenta con los datos de la sede seleccionada para crear el o los profesores.
   */
  sedeSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
   * Función que recibe un valor booleano para indicar si el excel es valido. 
   */
  setExcelValido: PropTypes.func.isRequired,

  /**
  * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
  */
  textosInterfaz: PropTypes.object.isRequired
}