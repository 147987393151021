import SkeletonProfesoresCrearMasivo from "./SkeletonProfesoresCrearMasivo";
import SkeletonProfesoresCrearIndividual from "./SkeletonProfesoresCrearIndividual";

const SkeletonProfesoresCrear = (props) => {
  const { modoActivoCrear } = props;
  return (
    <>
      {
        modoActivoCrear === 'individual'
          ? <SkeletonProfesoresCrearIndividual />
          : <SkeletonProfesoresCrearMasivo />
      }
    </>
  )
}
export default SkeletonProfesoresCrear;