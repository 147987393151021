import { Children } from "react";
import PropTypes from 'prop-types';

/**
 * Componente que genera una estructura de columnas para los formularios de crear y editar. Este componente recibe la propiedad children, la cual pasa elementos hijos directamente. 
 */
const DivisionCrud = (props) => {
    const { children } = props;
    const _children = Children.toArray(children)
    
    return (
        <>
            <div className="crud-seccion onsurface-var2--border-color justify-between">
                <div className="lg:w-3/12">
                    {_children[0]}
                </div>
                <div className="lg:w-8/12 crud-contenido">
                    {_children[1]}
                </div>
            </div>
        </>
    )
}
export default DivisionCrud;

DivisionCrud.propTypes = {
    /**
     * array de elementos 
     */
    children: PropTypes.array.isRequired,
}
