import concatenarDatos from './concatenarDatos';

/**
 * Agrega una propiedad que contiene el total de profesores de cada curso, retorna el objeto 'datos' añadiendo la propiedad mencionada en cada curso.
 */
const agregarDatosCurso = async ({ datos, section, propiedadJson }) => {
  let resultadosConcatenados = await concatenarDatos({ datos, section, propiedadJson });
  let resultadosModificados = {}

  for (const key in resultadosConcatenados) {
    const arreglo = [];
    const datosSeccion = resultadosConcatenados[key];

    datosSeccion[section].forEach(elemento => {
      arreglo.push({
        ...elemento,
        numeroTotalDeProfesores: elemento.profesores.length
      })
    })
    resultadosModificados[key] = {
      ...datos[key],
      [section]: arreglo
    }
  }
  
  return resultadosModificados;
}

export default agregarDatosCurso;