import { useEffect, useState } from 'react';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Funciones
import listarArray from './../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Componentes
import EstudiantesCrearComponent from "../../components/estudiantes/EstudiantesCrearComponent";
import SkeletonEstudiantesCrear from '../../components/estudiantes/SkeletonsEstudiantes/Crear';
import normalizeString from './../../utilities/function/normalizeString';
import useObtenerDatosExcel from '../helpers/hooks/obtenerDatosExcel/useObtenerDatosExcel';
import PropTypes from 'prop-types';

/**
 * Componente de tipo container que tiene la lógica interna para gestionar la creación de estudiantes tanto de manera individual como masiva
 */
const EstudiantesCrear = (props) => {
  const { descartarCambios, isLoadingCursos } = props;

  //Estados globales
  const state = useTrackedState();
  const { datosInstitucion, datosCursos, temporal } = state;
  const dispatch = useDispatch();

  //Estados del componente
  //Datos del estudiante
  const [codigo, setCodigo] = useState('');
  const [correo, setCorreo] = useState('');
  const [cursoSeleccionado, setCursoSeleccionado] = useState({
    individual: { id: null, nombre: '' },
    masivo: { id: null, nombre: '' }
  })
  const [nombre, setNombre] = useState('');
  const [sedeSeleccionada, setSedeSeleccionada] = useState({
    individual: { id: null, nombre: '' },
    masivo: { id: null, nombre: '' }
  })


  const [archivoExcel, setArchivoExcel] = useState(null);
  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [datosTemporalesExcel, setDatosTemporalesExcel] = useState({
    individual: { hayDatos: false },
    masivo: { hayDatos: false }
  });
  const [modoActivoCrear, setModoActivoCrear] = useState(!!temporal?.estudiantes ? temporal.estudiantes.modoActivoCrear : 'individual');
  const [sedesInstituto, setSedesInstituto] = useState([])


  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estudiantes');

  const hookDatosExcel = useObtenerDatosExcel({
    archivo: archivoExcel,
    crearMasivamente: modoActivoCrear !== 'individual',
    nombreHoja: 'archivo_excel',
    nombreSeccionACrear: 'estudiantes',
    seccionACrear: 'estudiantes'
  })

  const { advertenciasExcel, datosExcel, errorSubirExcel, isLoadingExcel, isReadyTextosHoja, isReadyTextosTabla } = hookDatosExcel;



  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes);

    if (!!temporal?.estudiantes) {
      const datosTemporales = temporal.estudiantes;
      const modoCrear = datosTemporales.modoActivoCrear;

      if (modoCrear === 'individual') {
        const estudianteTemporal = datosTemporales.datos;
        const datoIndividual = datosTemporales.datos[Object.keys(estudianteTemporal)[0]];

        setCodigo(datoIndividual.codigo);
        setCorreo(datoIndividual.correo);
        setNombre(datoIndividual.nombre);
      }

      setModoActivoCrear(modoCrear);
      setCursoSeleccionado({ ...cursoSeleccionado, [modoCrear]: datosTemporales.curso });
      setSedeSeleccionada({ ...sedeSeleccionada, [modoCrear]: datosTemporales.sede });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!temporal?.estudiantes) {
      const hayEstudiantes = Object.keys(temporal.estudiantes.datos).length !== 0;
      setDatosTemporalesExcel({
        ...datosTemporalesExcel,
        [temporal.estudiantes.modoActivoCrear]: {
          hayDatos: hayEstudiantes,
          datos: {
            nombreArchivo: hayEstudiantes ? temporal.estudiantes.nombreArchivo : null
          }
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal])



  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: sedeSeleccionada[modoActivoCrear].id })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, sedeSeleccionada[modoActivoCrear].id])


  //Actualiza el nombre del profesor que se desea crear
  const actualizarCodigo = ({ codigo }) => setCodigo(codigo);


  //Actualiza el nombre del profesor que se desea crear
  const actualizarCorreo = ({ correo }) => setCorreo(correo);


  //Actualiza el nombre del estudiante
  const actualizarNombre = ({ nombre }) => setNombre(nombre);


  //Habilita o deshabilita que se pueda subir un archivo
  const actualizarModoCrear = ({ modo }) => {
    setArchivoExcel(null);
    setModoActivoCrear(modo);
  };


  //Actualiza la sede asignada
  const actualizarCursoAsignado = ({ idCurso, idSede }) => {
    if (idSede !== sedeSeleccionada[modoActivoCrear].id) {
      const nombreSede = datosCursos[idSede].nombre;
      setSedeSeleccionada({
        ...sedeSeleccionada,
        [modoActivoCrear]: {
          id: idSede,
          nombre: nombreSede,
        }
      });
    };
    const nombreCurso = datosCursos[idSede].cursos.find(curso => curso.id === idCurso).nombre;
    setCursoSeleccionado({
      ...cursoSeleccionado,
      [modoActivoCrear]: { id: idCurso, nombre: nombreCurso }
    })
  };


  //Actualiza la sede asignada
  const actualizarSedeAsignada = ({ idSede }) => {
    if (idSede !== sedeSeleccionada[modoActivoCrear].id) {
      const nombreSede = datosCursos[idSede].nombre;
      setSedeSeleccionada({
        ...sedeSeleccionada,
        [modoActivoCrear]: {
          id: idSede,
          nombre: nombreSede,
        }
      });

      setCursoSeleccionado({
        ...cursoSeleccionado,
        [modoActivoCrear]: { id: null, nombre: '' }
      });
    };
  };


  //Guardar datos temporalmente
  const guardarDatosTemporales = async () => {
    const sede = sedeSeleccionada[modoActivoCrear];
    const curso = cursoSeleccionado[modoActivoCrear];
    let datosEstudiantesNuevos = {}

    let hayArchivo = false;
    let nombreArchivo;


    if (modoActivoCrear === 'individual') {
      const key = normalizeString(nombre);

      datosEstudiantesNuevos[key] = {
        codigo: codigo !== '' ? codigo : null,
        correo: correo !== '' ? correo : null,
        idCurso: curso.id,
        idSede: sede.id,
        nombre: nombre,
        nombreCurso: curso.nombre,
        nombreSede: sede.nombre,
      }
    } else {
      hayArchivo = true;
      nombreArchivo = datosExcel.nombreArchivo;

      let estudiantes = {};
      datosEstudiantesNuevos = datosExcel.datos;

      for (const key in datosEstudiantesNuevos) {
        let estudiante = datosEstudiantesNuevos[key];
        estudiante = {
          ...estudiante,
          idCurso: curso.id,
          idSede: sede.id,
          key: key,
          nombreCurso: curso.nombre,
          nombreSede: sede.nombre,
        }

        estudiantes[key] = estudiante;
      }

      datosEstudiantesNuevos = estudiantes;
    }


    const informacionTemporal = {
      datos: datosEstudiantesNuevos,
      curso: curso,
      hayArchivo: hayArchivo,
      modoActivoCrear: modoActivoCrear,
      nombreArchivo: nombreArchivo,
      sede: sede
    }

    dispatch({
      type: 'SET_DATA_TEMPORAL',
      property: 'estudiantes',
      value: informacionTemporal
    });
  }


  //Actualiza el estado que almacena al archivo
  const obtenerDatosExcel = ({ archivo, e, nombre }) => {
    setArchivoExcel({ archivo, e, nombre });
  }



  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }

  return (
    <>
      {
        !isLoadingCursos && isReady && isReadyTextosHoja && isReadyTextosTabla ?
          <EstudiantesCrearComponent
            actualizarCodigo={actualizarCodigo}
            actualizarCorreo={actualizarCorreo}
            actualizarCursoAsignado={actualizarCursoAsignado}
            actualizarNombre={actualizarNombre}
            actualizarModoCrear={actualizarModoCrear}
            actualizarSedeAsignada={actualizarSedeAsignada}
            advertenciasExcel={{
              hayAdvertencias: advertenciasExcel.hayAdvertencias,
              href: advertenciasExcel.href,
              nombreArchivo: advertenciasExcel.nombreArchivo
            }}
            codigoEstudiante={codigo}
            correoEstudiante={correo}
            cursosSede={cursosSede}
            cursoSeleccionado={cursoSeleccionado[modoActivoCrear]}
            datosTemporalesExcel={datosTemporalesExcel[modoActivoCrear]}
            descartarCambios={descartarCambios}
            errorSubirExcel={errorSubirExcel}
            guardarDatosTemporales={guardarDatosTemporales}
            datosExcel={datosExcel}
            hayArchivo={!!datosExcel ? datosExcel.hayArchivo : false}
            isLoadingExcel={isLoadingExcel}
            modoActivoCrear={modoActivoCrear}
            nombreEstudiante={nombre}
            obtenerDatosExcel={obtenerDatosExcel}
            sedeSeleccionada={sedeSeleccionada[modoActivoCrear]}
            sedesInstituto={sedesInstituto}
            textosInterfaz={{
              advertencias: textosInterfaz.advertencias,
              botones: textosInterfaz.botones,
              crear: textosInterfaz.crear,
              descartar_cambios: textosInterfaz.descartar_cambios,
              error_excel: textosInterfaz.error_excel,
              miga_de_pan: textosInterfaz.miga_de_pan,
            }}
          />
          :
          <SkeletonEstudiantesCrear
            modoActivoCrear={modoActivoCrear}
          />
      }
    </>
  )
}
export default EstudiantesCrear;

EstudiantesCrear.propTypes = {
  /**
   * Función que controla la lógica para borrar los datos ingresados al momento de crear un estudiante
   */
  descartarCambios: PropTypes.func.isRequired, 
  /**
   * Propiedad booleana que indica si los cursos se están cargando o ya están listo para renderizarse.
   */
  isLoadingCursos:PropTypes.bool.isRequired
}