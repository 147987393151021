import { useState, useEffect } from 'react';
//Store
import { useTrackedState } from "../../../store";
//Data
import DataTextos from "../../../modules/dataTextos.json";

/**
 * Devuelve un objeto con la información de todos los paises que tienen disponible los servicios de MakeMake
 * @param {*} seccion - Weccion de la pagina, ej: 'footer_paises'
 * @returns {{
 * listaPaises:object[],
 * textoPaises:object[]
 * }} Paises disponibles - Objeto con la información de todos los paises que tienen disponible los servicios de MakeMake
 */
const useObtenerPaises = (seccion) => {
  //Estados globales
  const state = useTrackedState();
  const { idioma } = state;
  //Estados del componente
  const [textoPaises, setTextoPaises] = useState(undefined);
  const [listaPaises, setListaPaises] = useState(undefined);


  useEffect(() => {
    const obetenerTraducciones = () => {
      const seccionEspecifica = DataTextos[seccion];
      let infoSeccion = {};
      let listaPaises = [];

      seccionEspecifica.forEach(item => {
        const { id, item: pais, value } = item;

        if (infoSeccion[pais] === undefined) {
          infoSeccion[pais] = { ...infoSeccion[seccion] };
          infoSeccion[pais][id] = value;
          return
        }

        //Llenar nombre y descripción
        if (id === "nombre" || id === "descripcion") return infoSeccion[pais][id] = item[idioma]

        //Llenar telefono
        if (id === "telefono") return infoSeccion[pais][id] = value.trim().split('; ')

        //Llenar telefono
        if (id === "correo") return infoSeccion[pais][id] = value.trim().split('; ')

        //Otros
        infoSeccion[pais][id] = value;
      })

      for (const key in infoSeccion) {
        //Lista paises
        listaPaises.push({
          cod: infoSeccion[key].cod,
          nombre: infoSeccion[key].nombre,
        })

        //Info paises
        const cod = infoSeccion[key].cod
        infoSeccion[cod] = infoSeccion[key];
        delete infoSeccion[key];
        delete infoSeccion[cod].cod;
        delete infoSeccion[cod].nombre;
      }

      setListaPaises(listaPaises);
      setTextoPaises(infoSeccion);
    }
    obetenerTraducciones();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idioma, seccion])

  return { listaPaises, textoPaises }
}

export default useObtenerPaises;