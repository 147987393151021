import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Libro } from "../../../../components/globales/libro/LibroComponent";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Componente que recibe el texto de interfaz y la información de los libros leidos recientemente por el estudiante, para renderizar la sección en el Home.
 * @returns LeidosRecientemente
 */
export const RecentlyReadBooks = ({ leidosRecientemente }) => {
    const [librosAMostrar, setLibrosAMostrar] = useState([]);
    const [sobreBoton, setSobreBoton] = useState(false);
    const history = useHistory();
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const noResults = textos?.home?.seguir_leyendo?.no_hay || 'Nada por aquí aún...';

    useEffect(() => {
        if (Array.isArray(leidosRecientemente)) {
            setLibrosAMostrar(leidosRecientemente.slice(0, 3))
        }
    }, [leidosRecientemente])

    const mostrarLibro = (idLibro) => {
        history.push({ pathname: `/visor/${idLibro}`, state: { idLibro: idLibro, pathAnterior: window.location.pathname } });
    }

    return (
        <>
            {
                Array.isArray(leidosRecientemente) && leidosRecientemente.length !== 0
                    ?
                    <>
                        <div className=" grid grid-cols-2 sm:grid-cols-3  gap-8 mt-5">
                            {
                                librosAMostrar.map((libro, i) => {
                                    return (
                                        <div className="my-5" key={`libroReciente-${i}`}>
                                            <Libro

                                                libro={libro}
                                                clasesPersonalizadas={{
                                                    contenedor: 'min-w-full',
                                                    portada: 'min-w-full',
                                                    titulo: ''
                                                }}
                                                ocultarOpciones={{
                                                    seleccionado: true,
                                                    botonMultiSeleccion: true,
                                                    botonFavoritos: true
                                                }}
                                                estadoInicial={{
                                                    esFavorito: Boolean(libro?.favorito)
                                                }}
                                                alSeleccionar={(libro) => mostrarLibro(libro?.idLibro)}
                                                esVisible
                                            />

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                        <div
                            className="fondo-verde-imagen mt-5 h-250 xl:h-350 center img-contain-center  xl:img-50-center"
                            style={{ backgroundImage: `${sobreBoton ? 'url(/images/perfil-estudiante/seguir-leyendo2.png)' : 'url(/images/perfil-estudiante/seguir-leyendo.png)'}` }}
                        >
                            <button
                                onMouseOver={() => setSobreBoton(true)}
                                onMouseLeave={() => setSobreBoton(false)}
                                onClick={() => { history.push('/estudiante/libros') }}
                                className="px-10 focusable-red primary-var2--bordered boton-justo boton-morado boton_hover_morado_oscuro mt-32">
                                {noResults}
                            </button>
                        </div>
                    </>
            }
        </>
    )
}

RecentlyReadBooks.propTypes = {
    /**
   * array de objeto que almacena los datos de los libros leídos recientemente por el estudiante.
   */
    leidosRecientemente: PropTypes.array.isRequired,
    /**
     *  Es un string que trae los textos de la interfaz dependiendo del idioma activo.
     */
    textoHome: PropTypes.string.isRequired
}