import { useContext } from "react";
import { COMPARACION_COLORES_DISPONIBLES } from "../../../containers/estadisticas/Constantes";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { useTrackedState } from "../../../store";
import TituloInstituto from "../../globales/estadisticas/TituloInstituto";
import PropTypes from "prop-types";
import { AccesibilidadContext } from '../../../contexts/AccesibilidadContext';

/**
 * Como su nombre lo dice, es una tabla que compara  valores estadísticos de 2 sedes o cursos
 */
export const TablaComparativa = (props) => {
  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('estadisticas');
  const { estadisticas, comparaciones } = props;
  const { comparativa, global } = traducciones || {};
  const { grafica } = comparativa || {};
  const { categoria, sesiones, libros, unidad } = grafica || {};

  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';

  const accesibilidad = useContext(AccesibilidadContext);
  return (
    <>
      <div className={`background-var2--bg ${accesibilidad.temaActual !== "clasico" && "onprimary-var1--bordered"}`}>
        <div className="hidden md:grid grid-cols-12">
          <div className="col-span-4 px-2"></div>
          {estadisticas?.map((estadistica, i) => {
            return <div key={i} className={`${estadisticas.length === 1 ? 'col-span-full' : 'col-span-4'} mr-4`}>
              <TituloInstituto
                colorFondo={COMPARACION_COLORES_DISPONIBLES[i]?.fondo}
                colorTexto={COMPARACION_COLORES_DISPONIBLES[i]?.texto}
                nombreColegio={
                  rolUsuario === 'rector' ?
                    (comparaciones.length > 0 ? comparaciones[i]?.sede?.nombre
                      :
                      estadistica?.nombreSede
                    )
                    :
                    (comparaciones.length > 0 ? comparaciones[i]?.curso?.nombre || 'N/A'
                      :
                      comparativa?.rol_profesor?.todo || 'Todos los cursos'
                    )

                }
                nombreCurso={rolUsuario === 'rector' ? ((comparaciones.length > 0 && comparaciones[i]?.curso?.id !== 'todo') ? comparaciones[i]?.curso?.nombre : '') : null}
                cuadroColor={true}
              />
            </div>
          })}
        </div>

        <div role="table" aria-rowcount="4">
          <div role="rowgroup">
            {/* row 1 */}
            <div role="row" aria-rowindex="1" className="sm:grid grid-cols-12 sm:border-t onsurface-var2--border-color">
              <div role="cell" className="col-span-4 p-3 sm:border-r onsurface-var2--border-color ">
                <p className="raleway-700 onbackground-var1--color">{categoria?.sesiones}</p>
              </div>
              {estadisticas?.map((estadistica, i) => {
                return (
                  <div key={i} role="cell" className={`${comparaciones?.length > 1 ? 'col-span-4' : 'col-span-8'} px-3 py-1 sm:py-3 sm:border-r onsurface-var2--border-color`}>
                    <p
                      className="q7-18-morado sm:text-center"
                      style={{
                        color: accesibilidad.temaActual === "clasico" ? COMPARACION_COLORES_DISPONIBLES[i]?.fondo : COMPARACION_COLORES_DISPONIBLES[i]?.contraste
                      }}
                    >
                      {estadistica?.totalSesionesPlataforma || 0} {estadistica?.totalSesionesPlataforma !== 1 ? sesiones?.plural : sesiones?.singular}
                    </p>
                  </div>
                )
              })}
            </div>

            {/* row 2 */}
            <div role="row" aria-rowindex="2" className="sm:grid grid-cols-12 sm:border-t onsurface-var2--border-color">
              <div role="cell" className="col-span-4 p-3 sm:border-r onsurface-var2--border-color ">
                <p className="raleway-700 onbackground-var1--color">{categoria?.promedio_sesion} </p>
              </div>
              {estadisticas?.map((estadistica, i) => {
                return (
                  <div key={i} role="cell" className={`${comparaciones?.length > 1 ? 'col-span-4' : 'col-span-8'} px-3 py-1 sm:py-3 sm:border-r onsurface-var2--border-color`}>
                    <p
                      className="q7-18-morado sm:text-center"
                      style={{
                        color: accesibilidad.temaActual === "clasico" ? COMPARACION_COLORES_DISPONIBLES[i]?.fondo : COMPARACION_COLORES_DISPONIBLES[i]?.contraste
                      }}
                    >
                      {estadistica?.tiempoPromedioPorSesion || 0} {estadistica?.tiempoPromedioPorSesion !== 1 ? `${global?.unidad_minuto?.plural || 'minutos'}` : `${global?.unidad_minuto?.singular || 'minuto'}`}
                    </p>
                  </div>
                )
              })}
            </div>

            {/* row 3 */}
            <div role="row" aria-rowindex="3" className="sm:grid grid-cols-12 sm:border-t onsurface-var2--border-color">
              <div role="cell" className="col-span-4 p-3 sm:border-r onsurface-var2--border-color ">
                <p className="raleway-700 onbackground-var1--color">{categoria?.libros}</p>
              </div>
              {estadisticas?.map((estadistica, i) => {
                return (
                  <div key={i} role="cell" className={`${comparaciones?.length > 1 ? 'col-span-4' : 'col-span-8'} px-3 py-1 sm:py-3 sm:border-r onsurface-var2--border-color`}>
                    <p
                      className="q7-18-morado sm:text-center"
                      style={{
                        color: accesibilidad.temaActual === "clasico" ? COMPARACION_COLORES_DISPONIBLES[i]?.fondo : COMPARACION_COLORES_DISPONIBLES[i]?.contraste
                      }}
                    >
                      {estadistica?.totalLibrosLeidos || 0} {estadistica?.totalLibrosLeidos !== 1 ? libros?.plural : libros?.singular}
                    </p>
                  </div>
                )

              })}
            </div>

            {/* row 4 */}
            <div role="row" aria-rowindex="4" className="sm:grid grid-cols-12 sm:border-t onsurface-var2--border-color">
              <div role="cell" className="col-span-4 p-3 sm:border-r onsurface-var2--border-color ">
                <p className="raleway-700 onbackground-var1--color">{categoria?.promedio_lectura}</p>
              </div>
              {estadisticas?.map((estadistica, i) => {
                return (
                  <div key={i} role="cell" className={`${comparaciones?.length > 1 ? 'col-span-4' : 'col-span-8'} px-3 py-1 sm:py-3 sm:border-r onsurface-var2--border-color`}>
                    <p
                      className="q7-18-morado sm:text-center"
                      style={{
                        color: accesibilidad.temaActual === "clasico" ? COMPARACION_COLORES_DISPONIBLES[i]?.fondo : COMPARACION_COLORES_DISPONIBLES[i]?.contraste
                      }}
                    >
                      {estadistica?.tiempoPromedioDeLectura || 0} {estadistica?.tiempoPromedioDeLectura !== 1 ? `${global?.unidad_minuto?.plural || 'minutos'}` : `${global?.unidad_minuto?.singular || 'minuto'}`}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

TablaComparativa.propTypes = {
  /**
   * Arreglo con las estadísticas correspondientes a cada comparación, en el mismo orden que las comparaciones
   */
  estadisticas: PropTypes.arrayOf(PropTypes.object),
  /**
 * Arreglo de objetos con los datos de los cursos o sedes que se están comparando.
 */
  comparaciones: PropTypes.arrayOf(PropTypes.shape({
    sede: PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    }),
    curso: PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })
  })),
}