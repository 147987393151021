import PropTypes from 'prop-types';

/**
 * Componente que recibe los props necesarios para renderizar las pestañas de la gráfica de la pantalla "Reportes gráficos de estudiantes" en estadísticas.
 * @returns TooltipGrafica
 */
const TooltipGrafica = (props) => {
  const { descripcionTool, alSeleccionar, opcionActiva } = props;
  return (
    <button
      className={`w-full tooltipGrafica focusable-red boton_hover_escalar ${opcionActiva && 'secondary-inv-var1--bg pestana'}`}
      onClick={() => alSeleccionar && alSeleccionar()}
    >
      <p className={`text-center lg:text-left raleway-700 onsurface-var1--color ${opcionActiva && 'onprimary-inv-var2--color'}`}>{descripcionTool}</p>
    </button>
  )
}
export default TooltipGrafica;

TooltipGrafica.propTypes = {
  /**
   * texto descriptivo del tooltip
   */
  descripcionTool: PropTypes.string.isRequired,
  /**
   * Callback usada para cuando se selecciona una opcion de las graficas
   * @type{Function(grafica:grafica):void} - callback
   */
  alSeleccionar: PropTypes.func.isRequired,
  /**
   * Bool que indica si la opción es la que se encuentra activa
   */
  opcionActiva: PropTypes.bool.isRequired,
}