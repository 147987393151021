import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const SkeletonReporteAlumno = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>

      <Skeleton className="ruta" width={188} height={40} />
      <div className="contenido">

        <h1 className="titulos-principales  ">
          <div className="md:w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <div className="py-3 md:w-451p"  >
          <Skeleton width={'100%'} height={30} />
        </div>

        <div className="md:w-4/12 xl:w-3/12 mt-3 mb-5 ">
          <Skeleton width={'100%'} height={36} />
        </div>

        <hr />

        <div className="center md:justify-start my-3">
          <div className="w-36 mt-2">
            <Skeleton width={'100%'} height={24} />
          </div>
          <p className="mx-2">/</p>
          <div className="w-40 mt-2">
            <Skeleton width={'100%'} height={24} />
          </div>
          <p className="mx-2">/</p>
          <div className="w-40 mt-2">
            <Skeleton width={'100%'} height={24} />
          </div>
        </div>

        <div className="md:flex my-5 ">
          <div className="md:w-6/12">
            <div className="w-4/12">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="w-8/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="md:w-6/12 alineado-izquierda-center">
            <div className="boton-pequenio">
              <Skeleton width={'100%'} height={36} />
            </div>
          </div>
        </div>

        <div className="md:flex mt-5 mb-10">
        <div className="md:w-6/12">
          <div className="w-4/12">
            <Skeleton width={'100%'} height={24} />
          </div>
          <div className="w-8/12">
            <Skeleton width={'100%'} height={24} />
          </div>
        </div>
        <div className="md:w-6/12 alineado-izquierda-center">
          <div className="boton-pequenio">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>
      </div>

        <hr className="hidden xl:block" />

        <div className="encabezadoTabla xl:grid grid-cols-12 mt-5">
          <div className="col-span-4">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
        </div>

        <div className="filaTabla xl:grid-cols-12 mb-40">

          <div className="fila-10-sm5-lg6 xl:col-span-4">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-8/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-5/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-5/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-5/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-5/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
        </div>

        <hr className="hidden xl:block" />

        <div className="encabezadoTabla xl:grid grid-cols-12 mt-5">
          <div className="col-span-3">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-3">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-3">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-3">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
        </div>

        <div className="filaTabla xl:grid-cols-12 mb-24">

          <div className="fila-10-sm5-lg6 xl:col-span-3">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-8/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-3">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-3">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-3">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-5/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

        </div>


      </div>
    </SkeletonTheme>
  )
}
export default SkeletonReporteAlumno;