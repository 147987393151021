import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

/**Componentes */
import Paginacion from "../../globales/Paginacion";

/**
 * Este componente recibe una serie de props necesarios para renderizar la tabla de lista profesores. Esta tabla se ubica en la pantalla de verificar de manera masiva. 
 * @returns ListaProfesoresNuevos
 */
const ListaProfesoresNuevos = (props) => {
  const { botones, cambiarProfesoresElegidos, datosProfesores, eliminarProfesorIndividual, textosTabla } = props;
  const { nombre, correo, sede, cursos } = textosTabla;
  const [datosDivididos, setDatosDivididos] = useState([]);


  const ActualizarDatosDivididos = (value) => setDatosDivididos(value);

  const onKeyEnter = (e, dato) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
      e.target.checked = !e.target.checked;
      cambiarProfesoresElegidos({ e, profesor: dato });
    }
  }

  return (
    <>
      <div role="table" aria-rowcount={datosProfesores.length} aria-label="Lista de profesores" className='my-5'>
        <div role="rowgroup">
          <div role="row" className="encabezadoTabla  xl:grid grid-cols-12">
            <p role="columnheader" className="col-span-1"></p>
            <p role="columnheader" className="col-span-3">{nombre.titulo}</p>
            <p role="columnheader" className="col-span-2">{correo.titulo}</p>
            <p role="columnheader" className="col-span-2">{sede.titulo}</p>
            <p role="columnheader" className="col-span-3">{cursos.plural}</p>
            <p role="columnheader" className="col-span-1"></p>
          </div>
        </div>

        <div role="rowgroup">
          {datosProfesores.length === 0 ?
            <div role="row" className="filaTabla xl:grid-cols-12">
              <div className=" center col-span-12">
                <span className="icon-alerta text-18 red-inv-var2--color  mr-3"></span>
                <p className="raleway-700 red-inv-var2--color  text-13">No hay profesores</p>
              </div>
            </div>
            :
            <>
              {
                datosDivididos.length === 0 ?
                  <div role="row" className="filaTabla xl:grid-cols-12">
                    <div className=" center col-span-12">
                      <span className="icon-alerta text-18 red-inv-var2--color  mr-3"></span>
                      <p className="raleway-700 red-inv-var2--color  text-13">No hay profesores</p>
                    </div>
                  </div>

                  :

                  datosDivididos.map((dato, index) => {
                    return (
                      <div role="row" key={`campo${dato.key}`} className="filaTabla xl:grid-cols-12">

                        <label role="cell" className="fila-2-sm1-lg12-xl1 row-span-5  sm:row-span-3 lg:row-span-1  h-7 relative mr-3 cursor-pointer focusable-by-children-primary">
                          <input 
                            checked={dato.checked} 
                            type="checkbox" 
                            tabIndex={0}
                            onChange={(e) => { cambiarProfesoresElegidos({ e, profesor: dato }); }}
                            onKeyUp={(e) => { onKeyEnter(e, dato) }}
                          />
                          <i aria-hidden={true} className="check" ></i>
                        </label>
                        <div role="cell" className="fila-10-sm5-lg6 xl:col-span-3">
                          <span className="q7-14-negro26  xl:hidden">{nombre.titulo}</span>
                          <p className="r5-13-negro26 una-linea-texto">{dato.nombre}</p>
                        </div>
                        <div role="cell" className="fila-10-sm5-lg6 xl:col-span-2 overflow-auto">
                          <span className="q7-14-negro26  xl:hidden">{correo.titulo}</span>
                          <p className={`${!!dato.correo ? 'r5-13-negro26' : 'r7-13-rojo'} una-linea-texto`} >{!dato.correo ? correo.sin_corrreo : dato.correo}</p>
                        </div>
                        <div role="cell" className="fila-10-sm5-lg6 xl:col-span-2">
                          <span className="q7-14-negro26  xl:hidden">{sede.titulo}</span>
                          <p className={`${!!dato.nombreSede ? 'r5-13-negro26' : 'r7-13-rojo'} una-linea-texto`} >{!dato.nombreSede ? sede.sin_sede : dato.nombreSede}</p>
                        </div>
                        <div role="cell" className="fila-10-sm5-lg6 xl:col-span-3">
                          <span className="q7-14-negro26  xl:hidden">{cursos.plural}</span>
                          <p className={`${!!dato.nombreCursos ? 'r5-13-negro26' : 'r7-13-rojo'} una-linea-texto`} >{!!dato.nombreCursos ? dato.nombreCursos : cursos.sin_cursos}</p>
                        </div>
                        <div role="cell" className="fila-10-sm11-lg12 xl:col-span-1">
                          <div className="md:alineado-izquierda-center">
                            <Link aria-label={botones.editar} to={`/profesores/crear-profesores/verificar-profesores/${dato.key}`} className="boton-justo boton-amarillo boton_hover_morado center w-full md:w-auto md:px-10 xl:p-2 mr-5 mb-3 md:mb-0 focusable-primary">
                              <p className="xl:hidden">{botones.editar} </p><span className="pl-3 xl:pl-0 icon-lapiz"></span>
                            </Link>
                            <button
                              aria-label={botones.eliminar.singular}
                              className="boton-justo boton-rojo boton_hover_morado center w-full md:w-auto md:px-10 xl:p-2 mb-3 md:mb-0 focusable-primary"
                              onClick={() => {
                                eliminarProfesorIndividual({ key: [dato.key] })
                              }}
                            >
                              <p className="xl:hidden">{botones.eliminar.singular} </p><span className="pl-3 xl:pl-0 icon-basura"></span>
                            </button>
                          </div>
                        </div>

                      </div>
                    )
                  })
              }
            </>
          }
        </div>
        <div className={`alineado-izquierda xl:mt-3`}>
          <Paginacion datos={datosProfesores} datosDivididos={ActualizarDatosDivididos} />
        </div>

      </div>
    </>
  )
}
export default ListaProfesoresNuevos;

ListaProfesoresNuevos.propTypes = {
  /**
   * Objeto que contiene los textos de interfaz correspondientes a los botones.
   */
  botones: PropTypes.object.isRequired,

  /**
  * función que se encarga de almacenar los profesores seleccionados con el check.
  * @type{Function ({ e: event, idSede: string, profesor: shape }):void } -callback
  */
  cambiarProfesoresElegidos: PropTypes.func.isRequired,

  /**
   * Objeto que contiene la información de los profesores que se desean crear
   */
  datosProfesores: PropTypes.array.isRequired, //Esto puede ser un objeto

  /**
*  función que se encarga de eliminar un profesor de forma individual.
* @type{Function({ key: string }):void}-callback
*/
  eliminarProfesorIndividual: PropTypes.func.isRequired,

  /**
   * Objeto que contiene los textos del encabezado de la tabla. Estos pueden cambiar según el idioma seleccionado.
   */
  textosTabla: PropTypes.object.isRequired
}