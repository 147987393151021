import React, { useState, useEffect } from 'react';

let timeout;

/**
 * Este hook permite paginar un arreglo, 
 * recibe como parametro un objeto en donde se puede definir el arreglo que se quiere paginar, 
 * la cantidad de items que van por página,
 * y el delay en milisegundos para que se cargue una nueva página.
 * Es útil para el scroll infinito de arreglos sincronos, para el paginado asincrono en donde se debe hacer fetch se debe usar useAsyncPagination
 * 
 * @param {Object} options - Objeto que define las opciones de paginación.
 * @param {number} options.itemsPerPage - Cantidad de elementos por página.
 * @param {Array<any>} options.data - Arreglo que se quiere paginar.
 * @param {number} options.nextPageDelay - Retraso en milisegundos para cargar la siguiente página.
 * @returns {{hasMore:boolean, nextPage:Promise<boolean>, paginatedData:Array<any>}} - Objeto con propiedades relacionadas a la paginación.
 * @property {boolean} hasMore - Indica si hay más elementos por cargar.
 * @property {Promise} nextPage - Promesa que se resuelve cuando se carga la siguiente página.
 * @property {Array<any>} paginatedData - Arreglo con los datos paginados.
 */
export const useSyncPagination = ({ itemsPerPage, data, nextPageDelay }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    data = Array.isArray(data) ? data : [];
    itemsPerPage = itemsPerPage >= 1 ? itemsPerPage : 1;


    useEffect(() => {
        const newPaginatedData = data.slice(0, currentPage * itemsPerPage);
        setPaginatedData(newPaginatedData);
        setHasMore(newPaginatedData.length < data.length);
    }, [currentPage, data, itemsPerPage]);

 
    useEffect(() => {
        setCurrentPage(1);
        setPaginatedData(data.slice(0, itemsPerPage));
        setHasMore(itemsPerPage < data.length);
    }, [data, itemsPerPage]);

    const nextPage = async () => {
        clearTimeout(timeout);
        return new Promise((resolve) => {
            if (nextPageDelay > 0) {
                timeout = setTimeout(() => {
                    setCurrentPage(prevPage => prevPage + 1);
                    resolve(true);
                }, nextPageDelay);
            } else {
                setCurrentPage(prevPage => prevPage + 1);
                resolve(true);
            }

        });
    };

    return { paginatedData, nextPage, hasMore };
};
