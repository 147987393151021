import { useEffect, useRef, useState } from 'react';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerDatosExcel from './../helpers/hooks/obtenerDatosExcel/useObtenerDatosExcel';
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Funciones
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
import listarArray from '../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
import normalizeString from './../../utilities/function/normalizeString';
//Componentes
import ProfesoresCrearComponent from '../../components/profesores/ProfesoresCrearComponent';
import SkeletonProfesoresCrear from '../../components/profesores/SkeletonsProfesores/Crear/index';
import { CursosAPI } from '../../api/CursosAPI';
import PropTypes from 'prop-types';

/**
 * Container que recibe la función de descartar cambios y el estado isLoadingCursos. Este container retorna el componente  ProfesoresCrearComponent al cual se le pasa una serie de propiedades que le permiten renderizar la pantalla de crear profesor/es. Las funciones principales de este container son cambiar el modo de crear, actualiza datos y guardar los datos temporalmente.
 * @returns {JSX.Element} ProfesoresCrearComponent
 */
const ProfesoresCrear = (props) => {
  const { descartarCambios, isLoadingCursos } = props

  //Estados globales
  const state = useTrackedState();
  const { datosCursos, datosInstitucion, temporal } = state;
  const dispatch = useDispatch();

  //Estados del componente
  const [archivoExcel, setArchivoExcel] = useState(null);
  const [correo, setCorreo] = useState('');
  const [cursosAsignados, setCursosAsignados] = useState({
    ids: [],
    datos: [],
  });
  const [cursosSede, setCursosSede] = useState(null);
  const [datosTemporalesExcel, setDatosTemporalesExcel] = useState({ hayDatos: false });
  const [modoActivoCrear, setModoActivoCrear] = useState(!!temporal?.profesores ? temporal.profesores.modoActivoCrear : 'individual');
  const [nombre, setNombre] = useState('');
  const [sedes, setSedes] = useState([]);
  const [sedeSeleccionada, setSedeSeleccionada] = useState({
    individual: { id: null, nombre: '' },
    masivo: { id: null, nombre: '' }
  })

  //Referencias
  const refInputNombre = useRef(null);

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('profesores');


  const hookDatosExcel = useObtenerDatosExcel({
    archivo: archivoExcel,
    crearMasivamente: modoActivoCrear !== 'individual',
    nombreHoja: 'archivo_excel',
    nombreSeccionACrear: !!refInputNombre.current ? refInputNombre.current.value : '',
    seccionACrear: 'profesores'
  });

  const { advertenciasExcel, datosExcel, errorSubirExcel, isLoadingExcel, isReadyTextosHoja, isReadyTextosTabla } = hookDatosExcel;


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedes(listaSedes);

    if (!!temporal?.profesores) {
      const datosTemporales = temporal.profesores;
      const modoCrear = datosTemporales.modoActivoCrear;

      if (modoCrear === 'individual') {
        const profesorTemporal = datosTemporales.datos;
        const dataIndividual = datosTemporales.datos[Object.keys(profesorTemporal)[0]];
        const cursos = {
          ids: dataIndividual.cursos,
          datos: dataIndividual.datosCursos
        }
        setCorreo(dataIndividual.correo);
        setCursosAsignados(cursos);
        setNombre(dataIndividual.nombre);
      }

      setModoActivoCrear(modoCrear);
      setSedeSeleccionada({ ...sedeSeleccionada, [modoCrear]: datosTemporales.sede });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    listarCursosSede();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sedeSeleccionada[modoActivoCrear].id, datosCursos])


  useEffect(() => {
    if (Boolean(temporal?.profesores)) {
      const hayProfesores = temporal?.profesores?.datos && Object.keys(temporal?.profesores?.datos).length !== 0;
      setDatosTemporalesExcel({
        hayArchivo: temporal.profesores.hayArchivo,
        hayDatos: hayProfesores,
        datos: {
          nombreArchivo: hayProfesores ? temporal.profesores.nombreArchivo : null
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal]);

  /**
   * Actualiza el correo del profesor que se desea crear
   * @param {string} correo 
   */
  const actualizarCorreo = ({ correo }) => setCorreo(correo);

  /**
   * Habilita o deshabilita que se pueda subir un archivo
   *  @param {string} modo
   */
  const actualizarModoCrear = ({ modo }) => {
    setArchivoExcel(null);
    setModoActivoCrear(modo);
  };

  /**
   * Actualiza el nombre del profesor que se desea crear
   * @param {string} nombre
   */
  const actualizarNombre = ({ nombre }) => setNombre(nombre);

  /**
  * Actualiza la sede asignada
  * @param {string} idSede
  */
  const actualizarSedeAsignada = ({ idSede }) => {
    if (idSede !== sedeSeleccionada[modoActivoCrear].id) {
      const nombreSede = datosCursos[idSede].nombre;
      setSedeSeleccionada({
        ...sedeSeleccionada,
        [modoActivoCrear]: {
          id: idSede,
          nombre: nombreSede,
        }
      });

      setCursosAsignados({ ids: [], datos: [] });
    };
  };

  /**
   * Actualizar cursos asignados
   * @param {shape} e
   * @param {shape} curso
   * @param {string} idSede
   */
  const actualizarCursosAsignados = ({ e, curso, idSede }) => {
    const datosCursosEstado = [...cursosAsignados.datos];

    const resultados = elegirElementosCheck({ e, elemento: curso, datosActuales: datosCursosEstado })
    const { ids, datos } = resultados

    const nombreSede = datosCursos[idSede].nombre;

    setCursosAsignados({ ids, datos });

    setSedeSeleccionada({
      ...sedeSeleccionada,
      [modoActivoCrear]: {
        id: idSede,
        nombre: nombreSede
      }
    });
  }

  /**
   * Guardar datos temporalmente
   */
  const guardarDatosTemporales = async () => {
    const sede = sedeSeleccionada[modoActivoCrear];
    let datosProfesoresNuevos = {}

    let hayArchivo = false;
    let nombreArchivo;

    if (modoActivoCrear === 'individual') {
      const key = normalizeString(nombre);
      datosProfesoresNuevos[key] = {
        correo: correo !== '' ? correo : null,
        cursos: cursosAsignados.ids,
        datosCursos: cursosAsignados.datos,
        idSede: sede.id,
        nombre: nombre,
        nombreSede: sede.nombre,
      }
      const informacionTemporal = {
        datos: datosProfesoresNuevos,
        hayArchivo: hayArchivo,
        modoActivoCrear: modoActivoCrear,
        nombreArchivo: nombreArchivo,
        sede: sede,
      }

      dispatch({
        type: 'SET_DATA_TEMPORAL',
        property: 'profesores',
        value: informacionTemporal
      });
    } else {
      hayArchivo = true;
      nombreArchivo = datosExcel.nombreArchivo;

      datosProfesoresNuevos = {}
      /*
      lee los nombres de los cursos que estan en el excel, 
      se busca los datos de los cursos desde las APIS  en base a esos nombres 
      y se setean en el estado 'temporal' 
      en las propiedades 'cursos' donde se almacenan los IDs
      y en 'datosCursos' se setea la información completa de los cursos
      es muy importante estas 2 propiedades debido a que son muy utilizadas
      */
      const cargarDatos = async () => {
        await CursosAPI
          .obtenerCursosPorSede(sede?.id || '')
          .then(resultadoCursos => {
            let profesores = datosExcel?.datos;
            if (profesores)
              for (const profesorKey in profesores) {
                const cursos = [];
                const nombres = profesores[profesorKey]?.cursosNombres;
                resultadoCursos?.forEach(curso => {
                  if (nombres.includes(curso?.nombre))
                    cursos.push(curso);
                })
                profesores[profesorKey] = {
                  ...profesores[profesorKey],
                  cursos: cursos?.map(curso => curso?.id),
                  datosCursos: cursos,
                  idSede: Boolean(profesores[profesorKey]?.idSede) ? profesores[profesorKey]?.idSede : sede?.id,
                  nombreSede: sedes.find(s => s?.id === sede?.id)?.nombre || ''
                }
              }

            datosProfesoresNuevos = profesores;
            const informacionTemporal = {
              datos: datosProfesoresNuevos,
              hayArchivo: hayArchivo,
              modoActivoCrear: modoActivoCrear,
              nombreArchivo: nombreArchivo,
              sede: sede,
            }

            dispatch({
              type: 'SET_DATA_TEMPORAL',
              property: 'profesores',
              value: informacionTemporal
            });
          })
      }
      await cargarDatos();
    }


  }

  //Genera el listado de cursos que el usurio puede seleccionar para asignar al profesor
  const listarCursosSede = () => {
    const cursosFiltrados = listarObject({
      object: datosCursos,
      idSede: sedeSeleccionada[modoActivoCrear].id,
      propiedad: 'cursos'
    });

    const { resultados } = cursosFiltrados;

    const resultadosFiltro = {
      hayCursos: resultados.hayResultados,
      cursos: resultados.resultados
    }

    setCursosSede(resultadosFiltro);
  }

  /**
   * Actualiza el estado que almacena al archivo
   * @param {shape} archivo 
   * @param {shape} e 
   * @param {string} nombre 
   */
  const obtenerDatosExcel = ({ archivo, e, nombre }) => {
    setArchivoExcel({ archivo, e, nombre });
  }

  return (
    <>
      {isReady && !!datosInstitucion && !isLoadingCursos && isReadyTextosHoja && isReadyTextosTabla ?
        <>
          <ProfesoresCrearComponent
            actualizarCorreo={actualizarCorreo}
            actualizarCursosAsignados={actualizarCursosAsignados}
            actualizarNombre={actualizarNombre}
            actualizarModoCrear={actualizarModoCrear}
            actualizarSedeAsignada={actualizarSedeAsignada}
            advertenciasExcel={{
              hayAdvertencias: advertenciasExcel.hayAdvertencias,
              href: advertenciasExcel.href,
              nombreArchivo: advertenciasExcel.nombreArchivo
            }}
            correo={correo}
            cursosAsignados={cursosAsignados}
            cursosSede={cursosSede}
            datosTemporalesExcel={datosTemporalesExcel}
            descartarCambios={descartarCambios}
            errorSubirExcel={errorSubirExcel}
            guardarDatosTemporales={guardarDatosTemporales}
            isLoadingExcel={isLoadingExcel}
            modoActivoCrear={modoActivoCrear}
            nombre={nombre}
            obtenerDatosExcel={obtenerDatosExcel}
            sedeSeleccionada={sedeSeleccionada[modoActivoCrear]}
            sedesInstituto={sedes}
            textosInterfaz={{
              miga_de_pan: textosInterfaz.miga_de_pan,
              crear: textosInterfaz.crear,
              botones: textosInterfaz.botones,
              advertencias: textosInterfaz.advertencias,
              error_excel: textosInterfaz.error_excel,
              descartar_cambios: textosInterfaz.descartar_cambios,
            }}
          />
        </>
        :
        <SkeletonProfesoresCrear
          modoActivoCrear={modoActivoCrear}
        />
      }
    </>
  )
}
export default ProfesoresCrear;

ProfesoresCrear.propTypes = {
  /**
   * funcion callback que borra los datos temporales.
   */
   descartarCambios: PropTypes.func.isRequired,

   /**
    * Es un booleano que indica si la información del curso ya cargo. 
    */
    isLoadingCursos: PropTypes.bool.isRequired
}