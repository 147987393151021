import { useContext, useEffect, useState } from "react";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { AccesibilidadContext } from "../../../../contexts/AccesibilidadContext";
import { Icon } from "@iconify/react";
import { Bar } from "react-chartjs-2";


export const BooksReadChart = ({ stats, loading }) => {
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const accesibilidad = useContext(AccesibilidadContext);
	const [colorGrafica, setColorGrafica] = useState("rgba( 0, 0, 0, 0.1)");
	const [colorFuenteGrafica, setColorFuenteGrafica] = useState("#7c7c7c");
	const [colorDato, setColorDato] = useState("#6666CC");

	useEffect(() => {
		const color = accesibilidad.temaActual === "clasico" ? "rgba( 0, 0, 0, 0.1)" : "#ffff00";
		setColorGrafica(color);
		const colorFuente = accesibilidad.temaActual === "clasico" ? "#7c7c7c" : "#ffff00";
		setColorFuenteGrafica(colorFuente);
		const colorValor = accesibilidad.temaActual === "clasico" ? "#6666CC" : "#ffff00";
		setColorDato(colorValor);
	}, [accesibilidad?.temaActual]);

	const options = {
		responsive: true,
		scales: {
			x: {
				ticks: {
					color: colorFuenteGrafica,
				},
				grid: {
					borderColor: colorGrafica,
					color: colorGrafica,
				},
			},
			y: {
				ticks: {
					color: colorFuenteGrafica,
				},
				grid: {
					borderColor: colorGrafica,
					color: colorGrafica,
				},
			},
		},
		plugins: {
			legend: {
				position: "top",
				labels: {
					color: colorFuenteGrafica,
				},
			},
		},
	};

	const chartData = {
		labels: stats?.rangoX,
		datasets: [
			{
				label: textos?.logros?.estadisticas?.libros_leidos,
				data: stats?.rangoY,
				backgroundColor: colorDato,
				borderRadius: Number.MAX_VALUE,
				borderSkipped: false,
			},
		],
	};

	return (
		<div className="relative mt-5 md:mt-0 md:p-5 w-full col-span-12">
			{loading ? (
				<div className="absolute w-full h-full background-var2--bg flex justify-center items-center">
					<div
						className={`absolute top-0 left-0 w-full h-full flex-grow flex items-center justify-center`}
					>
						<Icon
							icon="fa:spinner"
							width={30}
							className={`primary-inv-var2--color animate-spin`}
						/>
					</div>
				</div>
			) : (
				<></>
			)}
			<>
				<p className="q7-20 blue-inv-var1--color">{stats?.rangoFechas}</p>
				<Bar
					options={options}
					data={chartData}
					aria-label={chartData?.labels?.map((item, index) => {
						const textocompuesto =
							item +
							" " +
							chartData?.datasets[0]?.data[index] +
							" " +
							textos?.logros?.estadisticas?.libros_leidos;
						return textocompuesto;
					})}
				/>
			</>
		</div>
	);
};
