import { useState } from "react";
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import { useTrackedState } from "../../../../store";
import { DetalleEstudianteAPI } from "../../../../api/estadisticas/DetalleEstudianteAPI";

export const useBooksReadStats = ({ abortController, setAbortController }) => {
	const appState = useTrackedState();
	const language = appState?.idioma || "es";
	const studentProfileId = appState?.perfilEstudiante?.id;

	const fetchStats = async (startDate, endDate, abortSignal) => {
		return await DetalleEstudianteAPI.obtenerDetalles(
			studentProfileId,
			startDate,
			endDate,
			abortSignal
		);
	};

	const getThisWeek = async ({ abortSignal }) => {
		const rangos = FiltroFechas.estaSemana(language, "esta-semana", false);
		const fechaInicio = rangos.rangoFecha.fechaInicio;
		const fechaFin = rangos.rangoFecha.fechaFin;
		const nombresDias = rangos.fechaDias.map((item) => item.nombreDia);
		const fechas = rangos.fechaDias.map((item) => item.fecha);

		return Promise.all(
			fechas.map(async (fecha) => {
				const estadisticas = await fetchStats(fecha, fecha, abortSignal);
				return estadisticas;
			})
		).then((resultado) => {
			const librosConsultados = resultado.map(
				(estadisticas) => estadisticas.numeroLibrosConsultados
			);
			const data = {
				rangoFechas: `${fechaInicio} - ${fechaFin}`,
				rangoX: nombresDias,
				rangoY: librosConsultados,
			};
			return data;
		});
	};

	const getLastWeek = async ({ abortSignal }) => {
		const rangos = FiltroFechas.semanaPasada(language, "semana-pasada");
		const fechaInicio = rangos.rangoFecha.fechaInicio;
		const fechaFin = rangos.rangoFecha.fechaFin;
		const nombresDias = rangos.fechaDias.map((item) => item.nombreDia);
		const fechas = rangos.fechaDias.map((item) => item.fecha);

		return Promise.all(
			fechas.map(async (fecha) => {
				const estadisticas = await fetchStats(fecha, fecha, abortSignal);
				return estadisticas;
			})
		).then((resultado) => {
			const librosConsultados = resultado.map(
				(estadisticas) => estadisticas.numeroLibrosConsultados
			);
			const data = {
				rangoFechas: `${fechaInicio} - ${fechaFin}`,
				rangoX: nombresDias,
				rangoY: librosConsultados,
			};
			return data;
		});
	};

	const getThisMonth = async ({ abortSignal }) => {
		const rangos = FiltroFechas.ultimoMes(language, "este-mes");
		const fechaInicio = rangos.rangoFecha.fechaInicio;
		const fechaFin = rangos.rangoFecha.fechaFin;
		const numeroDias = rangos.fechaDias.map((item) => item.dia);
		const fechas = rangos.fechaDias.map((item) => item.fecha);

		return Promise.all(
			fechas.map(async (fecha) => {
				const estadisticas = await fetchStats(fecha, fecha, abortSignal);
				return estadisticas;
			})
		).then((resultado) => {
			const librosConsultados = resultado.map(
				(estadisticas) => estadisticas.numeroLibrosConsultados
			);
			const data = {
				rangoFechas: `${fechaInicio} - ${fechaFin}`,
				rangoX: numeroDias,
				rangoY: librosConsultados,
			};
			return data;
		});
	};

	const getThisYear = async ({ abortSignal }) => {
		const rangos = FiltroFechas.esteAnio(language, "este-mes", false);
		const fechaInicio = rangos.rangoFecha.fechaInicio;
		const fechaFin = rangos.rangoFecha.fechaFin;
		const nombreMeses = rangos.fechaMeses.map((item) => item.nombreMes);
		const fechas = rangos.fechaMeses.map((item) => {
			let inicio = item.fechaInicio;
			let fin = item.fechaFin;
			return { inicio, fin };
		});

		return Promise.all(
			fechas.map(async (fecha) => {
				const estadisticas = await fetchStats(fecha.inicio, fecha.fin, abortSignal);
				return estadisticas;
			})
		).then((resultado) => {
			const librosConsultados = resultado.map(
				(estadisticas) => estadisticas.numeroLibrosConsultados
			);
			const data = {
				rangoFechas: `${fechaInicio} - ${fechaFin}`,
				rangoX: nombreMeses,
				rangoY: librosConsultados,
			};
			return data;
		});
	};

	return {
		getLastWeek,
		getThisWeek,
		getThisMonth,
		getThisYear,
	};
};
