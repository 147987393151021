import React, {useEffect} from 'react';
import  {useHistory,useParams, useLocation} from 'react-router-dom';
import { VerificarSeleccionComponent } from '../../components/libros/VerificarSeleccion';


export const  VerificarSeleccionContainer = (props)=>{
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    return (
       <>
       <VerificarSeleccionComponent/>
       </>
    )
}