import { useState } from 'react';

//Componentes
import DivisionCrud from "../../globales/DivisionCrud";
import Selector from '../../globales/elementosFormularios/Selector';
import InputFile from '../../globales/elementosFormularios/InputFile';

const CrearMasivo = (props) => {
  const { actualizarSedeAsignada, crear, desactivar, obtenerDatosExcel, opcionesSelector, excelValido, mostrarAlertaArchivo, mostrarAlerta, sedeSeleccionada, setExcelValido, datosTemporalesExcel, hayErrorExcel } = props;

  const [textoInputSede, setTextoInputSede] = useState('');
  const [nombreDocumento, setNombreDocumento] = useState('');

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarSede = ({ opcion }) => {
    actualizarSedeAsignada({ idSede: opcion.id })
    setTextoInputSede(opcion.nombre);
  };

  return (
    <>
      {/* sede */}
      <DivisionCrud>
        <>
          <h3 className="onbackground-var1--color raleway-700">{crear.sede.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{crear.sede.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarSede}
            desactivado={false}
            estiloTipoInput={true}
            opciones={opcionesSelector}
            textoDefault={crear.sede.select}
            textoElegido={!!sedeSeleccionada.nombre ? sedeSeleccionada.nombre : textoInputSede}
            estadoEnviar={mostrarAlerta}
            valido={!!sedeSeleccionada.id ? true : false}
            textoError={crear.sede.error}
            zIndex={50}
          />
        </div>
      </DivisionCrud>

      {/* Subir excel */}
      <DivisionCrud>
        <>
          <h3 className="onbackground-var1--color raleway-700">{crear.subir_excel.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{crear.subir_excel.descripcion}</p>
          <a href='documents/plantilla_curso_masivo.xlsx'  download="plantilla_curso.xlsx" className="focusable-primary evento_rojo block mt-3">{crear.subir_excel.descargar_plantilla}</a>
        </>
        <>
          <InputFile
            datoGuardado={datosTemporalesExcel}
            desactivar={desactivar}
            descripcion={
              !!datosTemporalesExcel?.datos?.nombreArchivo ? datosTemporalesExcel.datos.nombreArchivo: crear.subir_excel.descripcion_input
            }
            excelValido={excelValido}
            hayErrorExcel={hayErrorExcel}
            instruccion={crear.subir_excel.titulo_input}
            mostrarAlerta={mostrarAlertaArchivo}
            nombreDocumento={nombreDocumento}
            obtenerDatosExcel={obtenerDatosExcel}
            setExcelValido={setExcelValido}
            setNombreDocumento={setNombreDocumento}
            textoError={crear.subir_excel.excel_no_valido}
          />
        </>
      </DivisionCrud>
    </>
  )
}

export default CrearMasivo;