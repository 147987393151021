import { useHistory, useParams } from "react-router-dom";
//componentes
import { MigasDePan, Miga } from "../../../globales/MigaDePan";
import TituloSeccion from "../../../globales/estadisticas/TituloSeccion";
import TablaDetalleLibro from "./TablaDetalleLibro";
import { useDispatch, useTrackedState } from "../../../../store";
import { useQueryParams } from "../../../../containers/helpers/hooks/locationHooks";
import { useEffect, useState } from "react";
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import { ESTADISTICAS_COLUMNAS_TABLAS } from "../../../../containers/estadisticas/tablas-reportes/ColumnasTablas";
import moment from "moment";
import { CargandoInformacion } from "../../../globales/estadisticas/CargandoInformacion";
import { DescargarReporte } from "../../Reportes/DescargarReporte";
import { LibrosAPI } from "../../../../api/LibrosAPI";
import { useFechaPorDefecto } from "../../hooks/Fechas";
import { Calendario } from "../../../globales/Calendario";
import { DetalleLibroProfesorAPI } from "../../../../api/estadisticas/DetalleLibroProfesorAPI";
import { normalizeString } from "../../../../utilities/StringUtilities";
import PropTypes from "prop-types";
import { CancelRequestController } from "../../../../utilities/cancel-request-controller";

/**
 * Este componente tiene la función de cargar todas las estadísticas de los profesores que leyeron un libro en particular con base a un rango de fechas.Por otro lado, también se encarga de transformar los datos estadísticos a formato excel para que puedan ser descargados por el usuario.
 */
const DetalleLibroProfesorComponent = (props) => {
  const { traducciones } = props;
  const { miga_de_pan, detalle_libro_profesor } = traducciones;
  const { tabla, descripcion, detalle_de, resultados } = detalle_libro_profesor;


  const history = useHistory();
  const parametros = useParams();
  const state = useTrackedState();
  const queryParams = useQueryParams();
  const fechaPorDefecto = useFechaPorDefecto();
  const dispatch = useDispatch();


  const [filtros, setFiltros] = useState({
    fechas: {
      inicio: fechaPorDefecto.fechaInicio,
      fin: fechaPorDefecto.fechaFin
    }
  })
  const [rangoFechas, setRangoFechas] = useState({
    inicio: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaInicio),
    fin: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaFin),
  })
  const [informacionLibro, setInformacionLibro] = useState({ nombre: '' })
  const [estadisticas, setEstadisticas] = useState({
    lecturas: [],
    lecturasFiltradas: []
  });
  const [cargando, setCargando] = useState({
    estadisticas: false
  })



  const actualizarFiltros = (fechas) => {
    dispatch({
      type: "SECCION_ESTADISTICAS_FILTROS",
      property: "fechas",
      value: {inicio:fechas[0], fin:fechas[1]}
    })
    setFiltros({
      ...filtros,
      fechas: {
        inicio: fechas[0],
        fin: fechas[1]
      }
    })
  }


  const filtrarPorInput = ({ value }) => {
    if (value && value.trim().length > 0) {
      const coincidencias = estadisticas?.lecturas.filter(lectura => normalizeString(lectura?.nombreCompleto)?.includes(normalizeString(value)));
      setEstadisticas({
        ...estadisticas,
        lecturasFiltradas: coincidencias
      })
    } else {
      setEstadisticas({
        ...estadisticas,
        lecturasFiltradas: estadisticas?.lecturas
      })
    }

  }

  /**
   * transforma la información suministrada por las APIs a una tabla que se puede descargar como excel.
   * @returns tabla para reporte
   */
  const generarInformacionTabla = () => {
    const columnas = ESTADISTICAS_COLUMNAS_TABLAS.detalleLibroProfesor.map(col => {
      const traduccion = traducciones?.detalle_libro_profesor?.tabla[`${col?.traduccion}`]
      return { nombreColumna: traduccion || col.textoPorDefecto }
    })

    const filas = [];
    estadisticas?.lecturas.forEach(lectura => {
      let fila = [];
      ESTADISTICAS_COLUMNAS_TABLAS.detalleLibroProfesor.forEach(col => {
        let valor = lectura[`${col?.propiedad}`];
        if (Array.isArray(lectura[`${col?.propiedad}`])) {
          valor = lectura[`${col?.propiedad}`].join(',')
        }
        fila.push({ valor: valor })
      })
      filas.push(fila);
    })

    const tabla = {
      columnas: columnas,
      filas: filas
    }
    return tabla;
  }


  useEffect(() => {
    setRangoFechas({
      inicio: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.inicio),
      fin: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.fin),
    })
  }, [filtros, state?.idioma])


  useEffect(() => {
    const abortController = new CancelRequestController();  
    setCargando({
      ...cargando,
      estadisticas: true
    })
    const actualizarEstadisticas = async () => {
      const idLibro = parametros?.idLibro;
      const rangoFechas = {
        inicio: filtros?.fechas.inicio || FiltroFechas.formatoAPI(state?.idioma, moment()),
        fin: filtros?.fechas.fin || FiltroFechas.formatoAPI(state?.idioma, moment())
      };

 
        await DetalleLibroProfesorAPI
          .obtenerDetalle({idLibro, fechaInicio:rangoFechas?.inicio, fechaFin:rangoFechas?.fin, abortSignal: abortController.signal})
          .then(resultado => {
            setEstadisticas({
              ...estadisticas,
              lecturas: resultado?.lecturasLibro,
              lecturasFiltradas: resultado?.lecturasLibro
            })
            setCargando({
              ...cargando,
              estadisticas: false
            })
          })
          .catch(error => console.log(error))
     
    }
    if (filtros?.fechas?.inicio && filtros?.fechas?.fin ) {
      actualizarEstadisticas();
    }

    return () => {
      abortController.abort();
      setCargando({
        ...cargando,
        estadisticas: true
      })
    }

  }, [parametros?.idLibro, JSON.stringify(filtros)])

  useEffect(() => {
    const abortController = new CancelRequestController();
    const cargarInformacionLibro = async () => {
      await LibrosAPI.multiplesFiltros({ idLibro: parametros?.idLibro, abortSignal: abortController.signal })
        .then(resultado => {
          if (Boolean(resultado) && resultado.length > 0) {
            setInformacionLibro({
              ...informacionLibro,
              nombre: resultado[0]?.title || 'N/A'
            })
          } else {
            history.push('/estadisticas/libros-leidos');
          }
        })
    }
    cargarInformacionLibro();

    return () => {  
      abortController.abort();
    }
  }, [parametros?.idLibro])


  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.estadisticas || 'Estadisticas'}
          alSeleccionar={() => {
            history.push('/estadisticas')
          }} />
        <Miga
          texto={detalle_libro_profesor?.miga_pan?.libros_leidos || 'Libros leidos(Profesores)'}
          alSeleccionar={() => {
            history.push({
              pathname:'/estadisticas/profesores/libros-leidos',
              search:`?fechaInicio=${filtros?.fechas?.inicio}&fechaFin=${filtros?.fechas?.fin}`
            })
          }} />
        <Miga
          texto={`${miga_de_pan?.detalle_de || 'Detalle de'}  ${informacionLibro?.nombre || ''}`}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales"> {informacionLibro?.nombre || ''}</h1>
        <p className="informacion-principal">{descripcion}</p>

        <div className="mt-4 md:w-4/12 xl:w-3/12">
        <Calendario
               fechaMaxima={moment().format('YYYY-MM-DD')}
               fechaMinima={state?.datosInstitucion?.fechaInicioSuscripcion || ''}
               modoRango={true}
               fechaPorDefecto={ [
                state?.estadisticas?.filtros?.fechas?.inicio || queryParams.get('fechaInicio') || fechaPorDefecto?.inicio, 
                state?.estadisticas?.filtros?.fechas?.fin || queryParams.get('fechaFin') || fechaPorDefecto.fin
              ]}
               zIndex={9999}
               modoMultiple={false}
               placeholder={''}
               alCambiar={(fechas)=>actualizarFiltros(fechas)}
        />
        </div>
        <hr className="my-8" aria-hidden={true}/>

        {Boolean(cargando?.estadisticas) === true ?
          <CargandoInformacion />

          :
          <>
            <TituloSeccion
              titulo={detalle_de}
              fecha={`${rangoFechas.inicio || 'N/A'} - ${rangoFechas.fin || 'N/A'}`}
              input={{
                placeHolder: detalle_libro_profesor?.input_busqueda?.placeholder || 'Buscar profesor por nombre de usuario',
              }}
              alEscribir={(value) => {
                filtrarPorInput(value)
              }}
              elementosPersonalizados={
                <div className="my-2 w-full flex  justify-center sm:justify-end">
                  <DescargarReporte
                    nombreArchivo={`${miga_de_pan?.detalle_de || 'Detalle de'} ${informacionLibro?.nombre || 'N/A'} (${state?.idioma === 'es'?'Profesores':'Teachers'}) (${rangoFechas?.inicio} - ${rangoFechas?.fin})`}
                    tablaInformacion={generarInformacionTabla()}
                  />
                </div>
              }
            />

            <div className="fondoTabla  mt-5 xl:px-10 xl:py-5 rounded-lg">
              <TablaDetalleLibro
                datos={estadisticas?.lecturasFiltradas}
                textosTabla={{ tabla: tabla, resultados: resultados }}
              />
            </div>
          </>
        }

      </div>
    </>
  )
}
export default DetalleLibroProfesorComponent;

DetalleLibroProfesorComponent.propTypes = {
  /**
  * Objeto con los textos que se deben renderizar en la sección
  */
  traducciones: PropTypes.object.isRequired,
  }