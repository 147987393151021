import { useState } from 'react';
//Funciones
import generalCallApi from '../helpers/generalCallApi';
//Hooks y funciones personalizadas
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Componentes
import CambiarContrasenaComponent from '../../components/miCuenta/CambiarContrasena';

/**
 * Container que no recibe props. Este container retorna el componente  CambiarContrasenaComponent al cual se le pasa una serie de propiedades que le permiten renderizar la pantalla de cambiar contraseña. La función principal de este container son cambiar la contraseña del usuario logueado.
 * @returns {JSX.Element} CambiarContrasenaComponent
 */
const CambiarContrasena = () => {
  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('mi_cuenta');
  //Estados del componente

  const [respuestaCambioContrasena, setRespuestaCambioContrasena] = useState({
    isError: false,
    mensaje: ''
  })

  const [preloader, setPreloader] = useState({
    contrasena: false
  });

  /**
   * función que se encarga de ejecutar el API de cambiar contraseña.
   * @param {string} anterior 
   * @param {string} nueva 
   */
  const cambiar_contrasena = async (anterior, nueva) => {
    if (!preloader.contrasena) {
      setRespuestaCambioContrasena({
        isError: null,
        mensaje: ""
      });


      setPreloader({ ...preloader, contrasena: true });
      const pathname = '/datosUsuario/cambiarContrasena';
      const properties = { contrasenaActual: anterior, nuevaContrasena: nueva };
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      setRespuestaCambioContrasena({
        isError: error,
        mensaje: result.info
      });
      setPreloader({ ...preloader, contrasena: false });
    }
  }

  return (
    <>
      {isReady &&
        <CambiarContrasenaComponent
          cambiarContrasena={cambiar_contrasena}
          preloader={preloader}
          respuestaCambioContrasena={respuestaCambioContrasena}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}

export default CambiarContrasena;
