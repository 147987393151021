import PropTypes from 'prop-types';

/**
 * Componente que recibe un titulo y descripción  para renderizar la pantalla de no hay resultados
 */
const NoHayResultados = (props) => {
  const { descripcion, titulo } = props;
  return (
    <>
      <div className="center min m-2" >
        <div className="lg:center">
          <img className="w-80 mx-auto lg:mx-0 lg:order-2  my-10 lg:my-0" src='/images/matilda-busca.png' alt={descripcion} />
          <div className=" md:w-3/5 lg:w-6/12 lg:order-1  mx-auto lg:mx-0 lg:mr-8">
            <h1 className="font-bold text-28 font-primary onbackground-var1--color text-center lg:text-left ">{titulo}</h1>
            <p className="text-20 font-primary onbackground-var1--color text-center lg:text-left  ">{descripcion} </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default NoHayResultados;

NoHayResultados.propTypes = {
  /**
   * string que corresponde al texto de descripción 
   */
  descripcion: PropTypes.string, 
  /**
   * string que corresponde al titulo
   */
  titulo: PropTypes.string
}