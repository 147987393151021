import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icon } from "@iconify/react";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Libro } from "../../../../components/globales/libro/LibroComponent";
import { BotonVolverArriba } from "../../../../components/globales/BotonVolverArriba";
import { BookListSkeleton } from "../../components/skeletons/book-list-skeleton";

/**
 * Función que recibe una serie de props que son necesarios para renderizar los libros dentro de la sección de libros. Además, hace uso del componente global Libro.
 * @returns SeccionLibros
 */
export const BooksList = ({
	cargando,
	libros,
	alSeleccionarLibro,
	alAgregarLibroAFav,
	alRemoverLibroDeFav,
	seccionVaciaElementoPersonalizado,
	alSeleccionarVerTodos,
	habilitarBotonVerTodos,
	clasesPersonalizadas,
	clasesPersonalizadasTitulo,
	ocultarElementos,
	ocultarOpciones,
	nombre,
	descripcion,
}) => {
	const { isReady, textosSubSecciones } = useObtenerSubsecciones("perfil_estudiante");
	const traducciones = textosSubSecciones;
	const [librosAMostrar, setLibrosAMostrar] = useState([]);
	const [librosCargados, setLibrosCargados] = useState(false);
	const cantidadLibrosAMostrar = 21;
	const defaultHiddenOptions = {
		botonMultiSeleccion: true,
		seleccionado: true,
	};

	const SinCoincidenciasImg = "/images/perfil-estudiante/clubs.png";

	// Funcion que agrega 10 libros a los libros que se estan mostrando en pantalla
	const cargaProgresiva = async () => {
		let timer = null;
		return await new Promise((resolve, reject) => {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				let contador = 0;
				const nuevosLibros = [];
				for (let i = librosAMostrar.length; i < libros.length; i++) {
					const libro = libros[i];
					if (contador < cantidadLibrosAMostrar) {
						nuevosLibros.push(libro);
					}
					contador++;
				}
				setLibrosAMostrar([...librosAMostrar, ...nuevosLibros]);
				if (librosAMostrar.length == libros.length) {
					setLibrosCargados(true);
				}
				resolve(true);
			}, 1000);
		});
	};

	useEffect(() => {
		// Carga los 10 primeros libros
		const cargaInicial = () => {
			if (Array.isArray(libros) && libros.length > 0) {
				if (
					librosAMostrar.length <= cantidadLibrosAMostrar ||
					libros.length <= cantidadLibrosAMostrar
				) {
					setLibrosAMostrar(libros.slice(0, cantidadLibrosAMostrar));
				} else {
					setLibrosAMostrar(libros.slice(0, librosAMostrar.length));
				}
				libros.length <= cantidadLibrosAMostrar && setLibrosCargados(true);
			} else {
				setLibrosAMostrar([]);
			}
		};

		setLibrosCargados(false);
		cargaInicial();
	}, [libros]);

	useEffect(() => {
		if (librosAMostrar.length >= libros.length) {
			setLibrosCargados(true);
		}
	}, [librosAMostrar]);

	return (
		<>
			{isReady && (
				<>
					{cargando ? (
						<BookListSkeleton />
					) : (
						<div className="my-2">
							<div>
								<div className="sm:flex flex-wrap justify-between items-center">
									{Boolean(
										ocultarElementos?.titulo === false || cargando === false
									) && (
										<h2
											className={`${
												Boolean(clasesPersonalizadasTitulo)
													? clasesPersonalizadasTitulo
													: "q7-24 onsecondary-var1--color border-b-2 onsurface-var2--borderedb-color"
											} w-full`}
										>
											{nombre}
										</h2>
									)}
									{librosAMostrar &&
										librosAMostrar.length > 0 &&
										habilitarBotonVerTodos && (
											<button
												className="focusable-primary boton-amarillo boton_hover_filtros  boton-pequenio  alineado-verticalmente center my-2"
												onClick={() =>
													alSeleccionarVerTodos &&
													alSeleccionarVerTodos(libros)
												}
											>
												<p className="quicksand-700">
													{traducciones.botones.seccion_libros
														.ver_todos || "Ver todos"}
												</p>
												<span className="icon-atras transform rotate-180 ml-3"></span>
											</button>
										)}
								</div>
								{Boolean(ocultarElementos?.descripcion) === false && (
									<p className={`q7-18 onsecondary-var1--color font-normal`}>
										{descripcion || ""}
									</p>
								)}
							</div>
							<div
								className={`${
									clasesPersonalizadas
										? clasesPersonalizadas
										: " flex justify-center flex-wrap "
								}`}
							></div>
							{librosAMostrar.length > 0 && Boolean(cargando) === false && (
								<InfiniteScroll
									style={{ overflow: "visible" }}
									className="w-full min-h-full  grid grid-cols-2  md:grid-cols-3 xl:grid-cols-4 gap-8 mt-5"
									dataLength={librosAMostrar.length}
									next={cargaProgresiva}
									hasMore={librosCargados ? false : true}
									scrollThreshold={"50%"}
									loader={
										<div className="w-full center pt-5 col-span-full">
											<span className="ml-10 icon-spinner9 animate-spin text-40 primary-inv-var2--color"></span>
										</div>
									}
									// endMessage={<h1>Ya has visto todo</h1>}
								>
									{librosAMostrar.map((libro, index) => {
										return (
											<div key={`libro-${libro?.idLibro}`} className="my-5">
												<Libro
													libro={libro}
													alAgregarFav={(libro) =>
														alAgregarLibroAFav &&
														alAgregarLibroAFav(libro)
													}
													alRemoverFav={(libro) =>
														alRemoverLibroDeFav &&
														alRemoverLibroDeFav(libro)
													}
													alSeleccionar={(libro) =>
														alSeleccionarLibro &&
														alSeleccionarLibro(libro)
													}
													clasesPersonalizadas={{
														contenedor: "min-w-full",
														portada: "min-w-full",
														titulo: "",
													}}
													estadoInicial={{
														esFavorito: Boolean(libro?.favorito),
													}}
													esVisible={true}
													ocultarOpciones={{
														...defaultHiddenOptions,
														...ocultarOpciones,
													}}
												/>
											</div>
										);
									})}
								</InfiniteScroll>
							)}

							{librosCargados == false && cargando === false && (
								<div className="flex flex-col flex-wrap center my-2">
									<h1 className="primary-inv-var2--color font-bold font-raleway">
										{`${
											traducciones?.libros?.scroll?.informacion_carga ||
											"Desplazate hacia abajo para cargar más libros."
										}`}
									</h1>
									<Icon
										icon="mdi:mouse-move-down"
										className="primary-inv-var1--color text-28 mx-2"
									/>
								</div>
							)}

							{librosAMostrar.length <= 0 && Boolean(cargando) === false && (
								<div className="flex w-full center">
									{seccionVaciaElementoPersonalizado ? (
										<>{seccionVaciaElementoPersonalizado}</>
									) : (
										<div className="center">
											<div>
												<img
													className="w-40 h-auto bg-img"
													src={SinCoincidenciasImg}
													alt="Sin coincidencias."
												/>
												<h2 className="quicksand-700 onsecondary-var1--color ">
													{traducciones.home.clubs.no_hay ||
														"Nada por aquí todavía..."}
												</h2>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</>
			)}
			{librosAMostrar.length >= cantidadLibrosAMostrar && <BotonVolverArriba />}
		</>
	);
};

BooksList.propTypes = {
	/**
	 * nombre de la sección
	 */
	nombre: PropTypes.string,
	/**
	 * descripción de la sección
	 */
	descripcion: PropTypes.string,
	/**
	 * callback usado para realizar alguna acción al momento en que se seleccione el boton de ver todos los libros
	 * callback = Function(libros:Libro[]):void;
	 */
	alSeleccionarVerTodos: PropTypes.func,
	/**
	 * callback usado para realizar alguna acción al momento en que se seleccione un libro
	 * callback = Function(libro:Libro):void;
	 */
	alSeleccionarLibro: PropTypes.func,
	/**
	 * callback utilizada para cuando se desmarque la opción de favoritos
	 * @type{Function(libro:Libro):void} - callback
	 */
	alRemoverLibroDeFav: PropTypes.func,
	/**
	 * callback utilizada para cuando se marque la opción de favoritos
	 * @type{Function(libro:Libro):void} - callback
	 */
	alAgregarLibroAFav: PropTypes.func,
	/**
	 * Arreglo de libros, Es obligatorio pasar un arreglo asi sea vacio.
	 * Libro[]
	 */
	libros: PropTypes.array,
	/**
	 * Permite pasar un elemento personalizado que se mostrara cuando la sección este vacia, de no pasar esta propiedad se cargara una por defecto.
	 */
	seccionVaciaElementoPersonalizado: PropTypes.element,
	/**
	 * Permite mostrar o ocultar el botón de ver todos los libros
	 */
	habilitarBotonVerTodos: PropTypes.bool,
	/**
	 * Permite agregar clases personalizadas para estructurar como se deben mostrar los libros, posiciones, grid, flex, margenes, etc.
	 */
	clasesPersonalizadas: PropTypes.string,
	/**
	 * Permite agregar clases personalizadas para el titulo
	 */
	clasesPersonalizadasTitulo: PropTypes.string,
	/**
	 * Limita la cantidad de libros que se mostraran aqui, al darle click a ver todos mostrara todos jaja
	 */
	limiteLibros: PropTypes.number,
	/**
	 * propiedad para saber si debe mostrar o no el skeleton de carga de libros.
	 */
	cargando: PropTypes.bool,
	/**
	 * objeto de booleanos que permite ocultar elementos del componente
	 */
	ocultarElementos: PropTypes.shape({
		titulo: PropTypes.bool,
		descripcion: PropTypes.bool,
	}),
};
