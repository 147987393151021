import { useState } from "react";
import PropTypes from 'prop-types';
import { useTrackedState } from "../../../../store";
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import Paginacion from "../../../globales/Paginacion";

/**
 * Renderizara una tabla con las estadísticas de todos los libros leídos por un estudiante, estas estadísticas son pasadas de padre a hijo por props. 
 */
const TablaLibros = (props) => {
  const { datos, textosTabla } = props;
  const { tabla, resultados } = textosTabla;
  const { no_hay, titulo_libro, autor, editorial, tiempo_promedio, fecha, tiempo_total } = tabla;
  const { hay, resultado } = resultados;
  const state = useTrackedState();

  const [datosDivididos, setDatosDivididos] = useState([]);
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  return (
    <>
      <div role="table" aria-rowcount={datos.length} className={`${datos.length > 10 ? null : 'mb-5'}`}>
        <div role="rowgroup">
          <div role="row" className="encabezadoTabla  xl:grid grid-cols-12 ">
            <p role="columnheader" className="col-span-2 alineado-verticalmente">{fecha}</p>
            <p role="columnheader" className="col-span-2 alineado-verticalmente">{titulo_libro}</p>
            <p role="columnheader" className="col-span-2 center text-center">{autor}</p>
            <p role="columnheader" className="col-span-2 center text-center">{editorial}</p>
            <p role="columnheader" className="col-span-2 center text-center">{tiempo_promedio}</p>
            <p role="columnheader" className="col-span-2 center text-center">{tiempo_total}</p>
          </div>
        </div>

        <div role="rowgroup">
          {datosDivididos.length === 0 ?
            <div role="row" className="filaTabla xl:grid-cols-12">
              <div role="cell" className=" center col-span-12">
                <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                <p className="raleway-700 red-inv-var2--color text-13">{no_hay}</p>
              </div>
            </div>
            :
            datosDivididos.map((dato, i) => {
              return (
                <div role="row" key={`campo-${i}`} className="filaTabla xl:grid-cols-12">
                  <div role="cell" className="fila-12-sm4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{fecha}</span>
                    <p className="raleway-700 primary-inv-var2--color text-13  una-linea-texto">{FiltroFechas.formatoUsuario(state?.idioma, dato.fechaLectura) || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{titulo_libro}</span>
                    <p className="raleway-700 primary-inv-var2--color text-13  una-linea-texto">{dato?.titulo || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm4-lg6 xl:col-span-2 overflow-auto">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{autor}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center `} >{dato?.autor || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{editorial}</span>
                    <p className={`r5-13-negro26 una-linea-texto xl:text-center `} >{dato?.editorial || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden una-linea-texto ">{tiempo_promedio}</span>
                    <p className={`r5-13-negro26 una-linea-texto xl:text-center`} >{dato?.tiempoPromedioDeLectura || 0} min</p>
                  </div>
                  <div role="cell" className="fila-12-sm4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{tiempo_total}</span>
                    <p className={`r5-13-negro26 una-linea-texto xl:text-center`} >{dato?.tiempoTotalLectura || 0} min</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="md:alineado-verticalmente xl:mt-3">
          <div className={`w-full md:w-6/12 `}>
            <p className="r4-16-negro4A">{hay} {datos.length} {datos.length !== 1 ? resultado.plural : resultado.singular} </p>
          </div>

          <div className={`w-full md:w-6/12 alineado-izquierda mt-5 md:mt-0`}>
            <Paginacion datos={datos} datosDivididos={ActualizarDatosDivididos} />
          </div>
        </div>

      </div>
    </>
  )
}
export default TablaLibros;


TablaLibros.propTypes = {
  /**
   * Arreglo de objetos con los datos de la tabla
   */
  datos: PropTypes.arrayOf(PropTypes.shape({
    titulo: PropTypes.string,
    autor: PropTypes.string,
    editorial: PropTypes.string,
    fechaLectura: PropTypes.string,
    tiempoPromedioDeLectura: PropTypes.number,
    tiempoTotalLectura: PropTypes.number,
  })).isRequired,
  /**
   * Objeto con las traducciones de encabezado y boton
   * @type{
  * tabla: object
  * resultados: object
   * }
   */
  textosTabla: PropTypes.object.isRequired,
}