import { useState } from "react";
import PropTypes from 'prop-types';
import Paginacion from "../../../globales/Paginacion";

/**
 * Renderizara una tabla con las estadísticas de todos los libros leídos por los profesores, estas estadísticas son pasadas de padre a hijo por props. 
 */
const TablaLibrosLeidos = (props) => {
  const { alSeleccionarVerDetalle, datos, textosTabla } = props;
  const { tabla, boton, resultados } = textosTabla;
  const { titulo, autor, editorial, numero_veces, tiempo_promedio, tiempo_acumulado, no_hay } = tabla;
  const { hay, resultado} = resultados;

  const [datosDivididos, setDatosDivididos] = useState([]);
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)


  return (
    <>
      <div className={`${datos.length > 10 ? null : 'mb-5'}`}>
        <div className="encabezadoTabla  xl:grid grid-cols-12 ">
          <p className="col-span-2 alineado-verticalmente">{titulo || 'Título'}</p>
          <p className="col-span-1 alineado-verticalmente">{autor || 'Autor'}</p>
          <p className="col-span-1 alineado-verticalmente">{editorial || 'Editorial'}</p>
          <p className="col-span-2 center text-center">{numero_veces || 'N° veces leído'}</p>
          <p className="col-span-2 center text-center">{tiempo_promedio || 'Tiempo promedio de lectura'}</p>
          <p className="col-span-2 center text-center">{tiempo_acumulado || 'Tiempo acumulado de lectura'}</p>
          <p className="col-span-2 center"></p>
        </div>
        <>
          {datosDivididos.length === 0 ?
            <div className="filaTabla xl:grid-cols-12">
              <div className=" center col-span-12">
                <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                <p className="raleway-700 red-inv-var2--color text-13">{no_hay}</p>
              </div>
            </div>
            :
            datosDivididos.map((dato) => {
              return (
                <div key={`campo-${dato.idLibro}`} className="filaTabla xl:grid-cols-12">
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2">
                    <span className="q7-14-negro26  xl:hidden">{titulo || 'Título'}</span>
                    <p className="raleway-700 primary-inv-var2--color text-13  una-linea-texto" title={dato.tituloLibro || 'N/A'}>{dato.tituloLibro || 'N/A'}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-1 overflow-auto">
                    <span className="q7-14-negro26  xl:hidden">{autor || 'Autor'}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} title={dato.autor || 'N/A'} >{dato.autor || 'N/A'}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-1">
                    <span className="q7-14-negro26  xl:hidden">{editorial || 'Editorial'}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} title={dato.editorial || 'N/A'} >{dato.editorial || 'N/A'}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2">
                    <span className="q7-14-negro26  xl:hidden">{numero_veces || 'N° veces leído'}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center`} title={dato.cantidadLecturas || 0} >{dato.cantidadLecturas || 0}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2">
                    <span className="q7-14-negro26  xl:hidden">{tiempo_promedio || 'Tiempo promedio de lectura'}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center`} title={dato.tiempoPromedioDeLectura || 0} >{dato.tiempoPromedioDeLectura || 0} min</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2">
                    <span className="q7-14-negro26  xl:hidden">{tiempo_acumulado || 'Tiempo acumulado de lectura'}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center`} title={dato.totalTiempoLectura || 0}>{dato.totalTiempoLectura || 0} min</p>
                  </div>
                  <div className="fila-12 xl:col-span-2">
                    <div className="alineado-izquierda-center">
                      <button
                        onClick={() => alSeleccionarVerDetalle&&alSeleccionarVerDetalle(dato)}
                        className="focusable-red boton-justo boton-amarillo boton_hover_morado center w-full lg:w-auto lg:px-5 py-2">
                        <p>{boton} </p>
                      </button>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </>
        <div className="md:alineado-verticalmente xl:mt-3">
          <div className={`w-full md:w-6/12 `}>
            <p className="r4-16-negro4A">{hay} {datos.length} {datos.length !== 1 ? resultado.plural : resultado.singular } </p>
          </div>

          <div className={`w-full md:w-6/12 alineado-izquierda mt-5 md:mt-0`}>
            <Paginacion datos={datos} datosDivididos={ActualizarDatosDivididos} />
          </div>
        </div>

      </div>
    </>
  )
}
export default TablaLibrosLeidos;

TablaLibrosLeidos.propTypes = {
 /**
   * Arreglo de objetos con los datos de la tabla
   */
  datos: PropTypes.arrayOf(PropTypes.shape({
    idLibro: PropTypes.string,
    tituloLibro: PropTypes.string,
    autor : PropTypes.string,
    editorial: PropTypes.string,
    tiempoPromedioDeLectura: PropTypes.number,
    totalTiempoLectura: PropTypes.number,
    cantidadLecturas: PropTypes.number,
})).isRequired,
  /**
   * Objeto con las traducciones de encabezado y boton
   * @type{
   * tabla: object
   * boton: string
   * resultados: object
   * }
   */
  textosTabla: PropTypes.shape({
    tabla: PropTypes.object,
    boton: PropTypes.string,
    resultados: PropTypes.object
  }).isRequired,
  /**
   * Evento que se dispara cuando el usuario da click en el botón de ver detalle, tiene como parametro el libro.
   @example
   alSeleccionarVerDetalle={(libro)=>console.log(libro)}
   */
  alSeleccionarVerDetalle:PropTypes.func
}