//Componentes
import { useEffect, useState } from 'react';
//Store
import { useDispatch, useTrackedState } from "../../store";
//Funciones
import listarArray from '../../utilities/function/listarElementos/listarArray';
import listarObject from '../../utilities/function/listarElementos/listarObject';
//Hooks personalizados
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Componentes
import EstudiantesEditarNuevoComponent from './../../components/estudiantes/EstudiantesEditarNuevoComponent';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 *  Componente de tipo Container que gestiona toda la lógica para la renderización, actualización de los datos de un estudiante cuando se está creando, este componente es funcional al momento de la verificación al crear estudiantes, es decir, permite la actualización de datos antes de crear oficialmente uno o varios estudiantes.

 */
const EstudiantesEditarNuevo = (props) => {
  const { descartarCambios, isLoadingCursos, isLoadingEstudiantes } = props;

  //Estados globales 
  const state = useTrackedState()
  const { datosInstitucion, datosCursos, datosEstudiantes, temporal } = state;
  const dispatch = useDispatch();

  //Estados del componente  
  //Datos del estudiante
  const [codigo, setCodigo] = useState('');
  const [correo, setCorreo] = useState('');
  const [cursoSeleccionado, setCursoSeleccionado] = useState({ id: null, nombre: '' })
  const [nombre, setNombre] = useState('');
  const [sedeSeleccionada, setSedeSeleccionada] = useState({ id: null, nombre: '' });

  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [datosEstudianteNuevo, setDatosEstudianteNuevo] = useState([]);
  const [hayCambios, setHayCambios] = useState({ cambios: false, datos: {} });
  const [isEstudianteEliminado, setIsEstudianteEliminado] = useState(false);
  const [sedesInstituto, setSedesInstituto] = useState([])

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estudiantes');


  //otras librerias
  const history = useHistory();
  const params = useParams();
  const { estudiante_nuevo } = params;


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!temporal?.estudiantes) {
      if (!isEstudianteEliminado) {
        const datosTemporales = { ...temporal.estudiantes.datos };
        let datosEstudiante = datosTemporales[estudiante_nuevo];


        setCodigo(datosEstudiante.codigo);
        setCorreo(datosEstudiante.correo);
        setCursoSeleccionado({
          id: datosEstudiante.idCurso,
          nombre: datosEstudiante.nombreCurso,
        });
        setDatosEstudianteNuevo(datosEstudiante);
        setNombre(datosEstudiante.nombre);
        setSedeSeleccionada({
          id: datosEstudiante.idSede,
          nombre: datosEstudiante.nombreSede,
        });

        setHayCambios({
          cambios: false,
          datos: {
            codigo: false,
            correo: false,
            curso: false,
            nombre: false,
            sede: false,
          }
        })
      }
    } else {
      history.push('/estudiantes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal])



  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: sedeSeleccionada.id })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, sedeSeleccionada.id])



  // //Actualiza el nombre del profesor
  const actualizarCodigo = ({ codigo }) => {
    const propiedades = { codigo: codigo !== datosEstudianteNuevo.codigo }
    verificarCambios({ propiedades });
    setCodigo(codigo);
  };


  // //Actualiza el nombre del profesor
  const actualizarCorreo = ({ correo }) => {
    const propiedades = { correo: correo !== datosEstudianteNuevo.correo }
    verificarCambios({ propiedades });
    setCorreo(correo);
  };


  //Actualiza la sede en la que se encuentra el estudiante
  const actualizarCurso = ({ idSede, idCurso }) => {
    const datosSede = !!datosCursos[idSede]
      ? { id: idSede, nombre: datosCursos[idSede].nombre }
      : { id: null, nombre: '' };

    const datosCurso = !!datosEstudiantes[idSede].cursos[idCurso]
      ? { id: idCurso, nombre: datosEstudiantes[idSede].cursos[idCurso].nombre }
      : { id: null, nombre: '' };

    setSedeSeleccionada(datosSede)
    setCursoSeleccionado(datosCurso);

    const propiedades = {
      sede: datosEstudianteNuevo.idSede !== idSede,
      curso: datosEstudianteNuevo.idCurso !== idCurso
    }
    verificarCambios({ propiedades });
  };


  // //Actualiza el nombre del profesor
  const actualizarNombre = ({ nombre }) => {
    const propiedades = { nombre: nombre !== datosEstudianteNuevo.nombre }
    verificarCambios({ propiedades });
    setNombre(nombre)
  };


  //Actualiza la sede en la que se encuentra el estudiante
  const actualizarSede = ({ idSede }) => {
    if (idSede !== sedeSeleccionada.id) {
      const nombreSede = datosEstudiantes[idSede].nombre;
      setSedeSeleccionada({
        id: idSede,
        nombre: nombreSede
      });
      setCursoSeleccionado({ id: null, nombre: '' });

      const propiedades = { sede: datosEstudianteNuevo.idSede !== idSede }
      verificarCambios({ propiedades });
    };
  };


  //Editar curso  
  const editarEstudiante = () => {
    let estudiantesNuevos = { ...temporal.estudiantes.datos };

    //Se actualizan los datos del curso nuevo
    estudiantesNuevos[estudiante_nuevo] = {
      ...estudiantesNuevos[estudiante_nuevo],
      codigo, correo, nombre,
      idCurso: cursoSeleccionado.id,
      idSede: sedeSeleccionada.id,
      nombreCurso: cursoSeleccionado.nombre,
      nombreSede: sedeSeleccionada.nombre
    }

    //Se crean los nuevos datos de los cursos nuevos
    const nuevosDatosTemporales = {
      ...temporal.estudiantes,
      datos: estudiantesNuevos
    }


    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "estudiantes",
      value: nuevosDatosTemporales
    });
  }


  //Eliminar profesor
  const eliminarEstudiante = () => {
    const estudiantesTemporales = { ...temporal.estudiantes.datos };
    let estudiantesNuevos = estudiantesTemporales;
    delete estudiantesNuevos[estudiante_nuevo];


    const nuevosDatosTemporales = {
      ...temporal.estudiantes,
      datos: estudiantesNuevos
    }

    setIsEstudianteEliminado(true)
    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "estudiantes",
      value: nuevosDatosTemporales
    });
  };



  //Lista los cursos disponibles
  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }



  //Verificar cambios
  const verificarCambios = ({ propiedades }) => {
    const datos = { ...hayCambios.datos, ...propiedades };
    let cambios = false;

    for (const key in datos) {
      const element = datos[key];
      if (element) cambios = true;
    }

    setHayCambios({
      hayCambios: cambios,
      datos: { ...hayCambios.datos, ...propiedades }
    });
  }


  return (
    <>
      {
        !isLoadingCursos && !isLoadingEstudiantes && isReady &&
        <EstudiantesEditarNuevoComponent
          actualizarCodigo={actualizarCodigo}
          actualizarCorreo={actualizarCorreo}
          actualizarCurso={actualizarCurso}
          actualizarNombre={actualizarNombre}
          actualizarSede={actualizarSede}
          codigoEstudiante={codigo}
          correoEstudiante={correo}
          cursosSede={cursosSede}
          cursoSeleccionado={cursoSeleccionado}
          datosEstudianteNuevo={datosEstudianteNuevo}
          descartarCambios={descartarCambios}
          editarEstudiante={editarEstudiante}
          eliminarEstudiante={eliminarEstudiante}
          hayCambios={hayCambios.hayCambios}
          nombreActual={datosEstudianteNuevo.nombre}
          nombreEstudiante={nombre}
          sedeSeleccionada={sedeSeleccionada}
          sedesInstituto={sedesInstituto}
          textosInterfaz={{
            botones: textosInterfaz.botones,
            descartar_cambios: textosInterfaz.descartar_cambios,
            editar: textosInterfaz.editar,
            eliminar_estudiante: textosInterfaz.eliminar_estudiante,
            estudiante_eliminado: textosInterfaz.estudiante_eliminado,
            miga_de_pan: textosInterfaz.miga_de_pan,
            notificacion:textosInterfaz.notificacion,
          }}
        />
      }
    </>
  )
}
export default EstudiantesEditarNuevo;

EstudiantesEditarNuevo.propTypes = {
  /**
   * Función que permite limpiar el estado de los datos del estudiante y cancela la creación de un etudiante nuevo
   */
  descartarCambios: PropTypes.func.isRequired, 
  /**
   * Indica si el componente esta cargando o no los cursos del instituto
   */
   isLoadingCursos: PropTypes.bool.isRequired,
   /**
    * Indicia si el componente esta cargando los datos de los estudiantes
    */
   isLoadingEstudiantes: PropTypes.bool,
}
