import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useIsMobile from "../../../containers/helpers/hooks/useIsMobile";

const SkeletonLibros = () => {

  //Hooks personalizados
  const isMobile = useIsMobile();


  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
        <Skeleton className="ruta" width={170} height={40} />

        <div className="contenido">
          <h1 className="titulos-principales  ">
            <div className="w-24 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>
          <div className="w-7/12 md:w-5/12">
            <Skeleton width={'100%'} height={36} />
          </div>

          <div className="md:flex mt-3 gap-4">
            <div className="md:w-4/12 xl:w-3/12">
              <Skeleton width={'100%'} height={36} />
            </div>
            <div className="w-10">
              <Skeleton width={'100%'} height={36} />
            </div>
            <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0">
              <Skeleton width={'100%'} height={36} />
            </div>
          </div>

          <div className="lg:flex gap-4 border-t border-gris-linea mt-5 py-5 ">
            <div className="columna-filtro">
              <div className="w-full lg:hidden">
                <Skeleton width={'100%'} height={49} />
              </div>
              <div className={`${isMobile ? 'hidden' : ' w-64 h-644'}`}>
                <Skeleton width={'100%'} height={'100%'} />
              </div>
            </div>

            <div className="w-full mt-5 lg:mt-0">
              <div className="lg:w-96">
                <Skeleton width={'100%'} height={36} />
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 mt-5">
                <Skeleton width={'100%'} height={271} />
                <Skeleton width={'100%'} height={271} />
                <Skeleton width={'100%'} height={271} />
                <Skeleton width={'100%'} height={271} />
                <Skeleton width={'100%'} height={271} />
                <Skeleton width={'100%'} height={271} />
                <Skeleton width={'100%'} height={271} />
                <Skeleton width={'100%'} height={271} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  )
}
export default SkeletonLibros;