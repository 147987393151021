import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ClubsSkeleton = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={170} height={40} />
      <div className="contenido">
        <div className="md:flex gap-4">
          <div className='md:w-3/12 xl:w-2/12'>
            <Skeleton className="mx-auto" width={128} height={128} style={{ borderRadius: '100%', display: 'block' }} />
            <Skeleton className="mx-auto mt-3" width={130} height={24} style={{ display: 'block' }} />
          </div>

          <div className='md:w-9/12 xl:w-10/12'>
            <div className='my-5 md:my-0'>
              <div className="sm:w-8/12 mx-auto md:mx-0 md:w-5/12">
                <Skeleton width={'100%'} height={36} />
              </div>

              <div className="center md:justify-start">
                <div className="w-36 mt-2">
                  <Skeleton width={'100%'} height={24} />
                </div>
                <p className="mx-2">/</p>
                <div className="w-40 mt-2">
                  <Skeleton width={'100%'} height={24} />
                </div>
                <p className="mx-2">/</p>
                <div className="w-40 mt-2">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>

              <div className="sm:center md:justify-start mt-2">
                <div className="center sm:block mt-2">
                  <Skeleton width={40} height={50} />
                  <Skeleton className="ml-2" width={100} height={50} />
                </div>
                <div className="center sm:block mt-2 mx-8">
                  <Skeleton width={40} height={50} />
                  <Skeleton className="ml-2" width={100} height={50} />
                </div>
                <div className="center sm:block mt-2">
                  <Skeleton width={40} height={50} />
                  <Skeleton className="ml-2" width={100} height={50} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Skeleton className="mt-5" width={'100%'} height={400} />
      </div>
    </SkeletonTheme>
  )
}
