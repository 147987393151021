import { useState, useEffect } from 'react';
//Componentes
import HeaderComponent from '../../components/header';
//Hooks y funciones personalizadas
import useObtenerTraducciones from '../helpers/hooks/useObtenerTraducciones';
//Store
import { useTrackedState } from '../../store';

/**
 * Esta función recibe unos parametros que se pasan al componente HeaderComponent. Su función principal es establecer los valores por default para el header.
 * @param {Function} cerrarAbrirMenu 
 * @param {Function} actualizarAlturaHeader 
 * @param {number} alturaHeader 
 * @returns {JSX.Element} HeaderComponent
 */
const Header = ({ cerrarAbrirMenu, actualizarAlturaHeader, alturaHeader }) => {
  //Estados globales
  const state = useTrackedState();
  const { datosInstitucion } = state;
  //Estados del componente
  const [datosInstituto, setDatosInstituto] = useState({})
  //Hooks personalizados
  const { isReady, textosSeccion: textosInterfaz } = useObtenerTraducciones('header');

  useEffect(() => {
    let datos = {
      color: {
        header: '#f1f1f1',
        letras: '#262626'
      },
      logo: '/images/logo-makemake.svg',
      nombre: 'Plan lector',
    }
    if (!!datosInstitucion) {
      datos = {
        color: datosInstitucion.color,
        logo: !!datosInstitucion.logo
          ?  `${process.env.REACT_APP_ASSETS_URL_DOCUMENTOS}/${datosInstitucion.logo}`
          : datos.logo,
        nombre: datosInstitucion.nombre
      }
    }

    setDatosInstituto(datos)
  }, [datosInstitucion])

  return (
    <>
      {isReady &&
        <HeaderComponent
          actualizarAlturaHeader={actualizarAlturaHeader}
          alturaHeader={alturaHeader}          
          cerrarAbrirMenu={cerrarAbrirMenu}
          color={datosInstituto.color}
          logoInstitucion={datosInstituto.logo}
          nombreInstitucion={datosInstituto.nombre}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}

export default Header;