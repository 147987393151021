import PropTypes from "prop-types";

/**
 * Componente que recibe una serie de propiedades para renderizar el titulo de cada sección del Home del perfil del estudiante.
 * @returns TituloCaja
 */
export const SectionTitle = ({
	icono,
	titulo,
	cajaClubs,
	alHacerClick,
	mostrarBoton,
	textoBoton,
}) => {
	return (
		<>
			<div className="alineado-verticalmente justify-between">
				<h2
					className={`onprimary-var2--color q7-24 ${
						!!cajaClubs && "onprimary-var1--color"
					}`}
				>
					<span className={icono}></span> {titulo}
				</h2>
				{mostrarBoton && (
					<button
						className="focusable-red boton-justo boton-morado boton_hover_amarillo"
						onClick={() => alHacerClick && alHacerClick()}
					>
						{textoBoton}
					</button>
				)}
			</div>
		</>
	);
};

SectionTitle.propTypes = {
	/**
	 * String que correponde a la clase del icono.
	 */
	icono: PropTypes.string.isRequired,
	/**
	 * String que corresponde al texto del titulo. Este cambia según el idioma activo.
	 */
	titulo: PropTypes.string.isRequired,
	/**
	 * Booleano que cambia el color de la fuente solo si es la cajaClubs
	 */
	cajaClubs: PropTypes.bool,
	/**
	 * Función que redirige a la pantalla correspondiente
	 */
	alHacerClick: PropTypes.func.isRequired,
	/**
	 * Booleano que indica si hay libros dentro de la caja. Si el número de libros es mayor a 0, muestra el botón d
	 */
	mostrarBoton: PropTypes.bool.isRequired,
	/**
	 * String que equivale al texto del botón.
	 */
	textoBoton: PropTypes.string.isRequired,
};
