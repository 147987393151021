import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import SelectorDeBusqueda from "../../globales/elementosFormularios/SelectorDeBusqueda";
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';
import { AccesibilidadContext } from '../../../contexts/AccesibilidadContext';

//componentes
import ListaProfesoresNuevos from "./ListaProfesoresNuevos";
import Modal from "../../globales/modales/Modal";
import ModalConfirmacion from "../../globales/modales/ModalConfirmacion";
import VentanaAlerta from "../../globales/VentanaAlerta";
import { Miga, MigasDePan } from "../../globales/MigaDePan";

/**
 * Componente que recibe una serie de props necesarios para renderizar la pantalla de verificar los datos de modo masivo. Este hace uso de los componentes globales ListaProfesoresNuevos, Modal, ModalConfirmacion y VentanaAlerta.
 * @returns  VerificarProfesorMasivo 
 */
const VerificarProfesorMasivo = (props) => {
  const { actualizarSedeAsignada, cambiarCurso, cambiarProfesoresElegidos, cancelarAccionMultiSelect, crearProfesores, cursosSede, cursoSeleccionada, descartarCambios, eliminarProfesorIndividual, eliminarProfesores, mostrarMultiSelect, moverProfesores, numeroProfesoresSeleccionados, datosProfesoresNuevos, preloader, respuestaApiCrear, sedeSeleccionada, sedesInstituto, textosInterfaz, sedesProfesores } = props;
  const { miga_de_pan, verificar_datos, botones, multi_accion, modal_mover, modal_exito, modal_eliminar, no_hay_profesores, descartar_cambios, profesor_creado } = textosInterfaz;

  const { isError, mensaje } = respuestaApiCrear;
  const history = useHistory();

  /**estados */
  const [modalMover, setModalMover] = useState(false);
  const [modalConfirmarMover, setModalConfirmarMover] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalDescartar, setModalDescartar] = useState(false);
  const accesibilidad = useContext(AccesibilidadContext);

  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar]);

  /**Una vez que cambia el valor isError, al dar click en crear profesor, la ventana modal de creado aparece   */
  useEffect(() => {
    if (isError !== null) {
      if (!isError) {
        MicroModal.show('profesor-creado', {
          awaitCloseAnimation: true
        });
      } else {
        notificacion(textosInterfaz.notificacion.error, mensaje, "danger")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    if (modalMover) {
      MicroModal.show('estas-seguro-mover-profesores', {
        awaitCloseAnimation: true
      });
    }
  }, [modalMover]);

  useEffect(() => {
    if (modalEliminar) {
      MicroModal.show('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
    }
  }, [modalEliminar]);


  useEffect(() => {
    if (modalConfirmarMover === true) {
      MicroModal.close('estas-seguro-mover-profesores', {
        awaitCloseAnimation: true
      });
      MicroModal.show('profesor-movido', {
        awaitCloseAnimation: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfirmarMover]);

  /**funciones */
  const cambiarOpcionMultiSelect = ({ opcion }) => { actualizarSedeAsignada({ idSede: opcion.id }) };

  const cambiarOpcionCurso = ({ opcion }) => { cambiarCurso({ idCurso: opcion.id, idSede: opcion.idSede }) };


  const eliminarProfesoresNuevos = () => {
    eliminarProfesores({});
    MicroModal.close('estas-seguro-eliminar', {
      awaitCloseAnimation: true
    });
    MicroModal.show('profesor-eliminado', {
      awaitCloseAnimation: true
    });
  }

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Profesores'}
          alSeleccionar={() => {
            if (modalDescartar) {
              MicroModal.show('cancelar-crear', {
                awaitCloseAnimation: true
              });
            } else {
              setModalDescartar(true)
            }
          }}>
        </Miga>
        <Miga
          texto={miga_de_pan?.crear || 'Crear profesores'}
          alSeleccionar={() => {
            history.push('/profesores/crear-profesores')
          }}>
        </Miga>
        <Miga
          texto={miga_de_pan?.verificar || 'Verificar profesores'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{verificar_datos.titulo}</h1>

        {datosProfesoresNuevos.length !== 0 ?
          <>
            <ListaProfesoresNuevos
              datosProfesores={datosProfesoresNuevos}
              eliminarProfesorIndividual={eliminarProfesorIndividual}
              textosTabla={verificar_datos}
              botones={botones}
              cambiarProfesoresElegidos={cambiarProfesoresElegidos}
            />

            {/* barra de seleccionados */}
            {mostrarMultiSelect !== false &&
              <div className={`${accesibilidad.temaActual !== "clasico" && 'border-b-2 onbackground-var1--border-color'}  multiselector fixed left-0 primary-var2--bg w-full py-4 transicion-200  ${mostrarMultiSelect !== false ? `animate-slideIn -top-0` : ` animate-slideOut -top-72`}`}>
                <div className='lg:flex'>
                  <div className="lg:w-4/12  alineado-verticalmente pl-5 xl:pl-10">
                    <button aria-label="desactivar seleccionados"  onClick={cancelarAccionMultiSelect} className='onprimary-var1--color text-20 mr-3 hover:text-amarillo  focusable-red'>
                      <span className='icon-cerrar'></span>
                    </button>
                    <p className='q7-20-blanco'>
                      {numeroProfesoresSeleccionados} {numeroProfesoresSeleccionados !== 1 ? multi_accion.profesores.singular : multi_accion.profesores.plural}
                    </p>
                  </div>
                  <div className="lg:w-8/12  md:alineado-verticalmente lg:alineado-izquierda-center gap-4 px-5 xl:px-0 mt-5 lg:mt-0">
                    <div className='md:w-4/12 lg:w-60'>
                      <SelectorDeBusqueda
                        cambiarElegido={cambiarOpcionMultiSelect}
                        estiloMultiAction={true}
                        opciones={sedesInstituto}
                        textoDefault={multi_accion.cambiar_sede}
                        textoElegido={sedeSeleccionada.nombre}
                        superposicion={true}
                      />
                    </div>
                    <div className='md:w-4/12 lg:w-60 mt-3 md:mt-0'>
                      <SelectorDeBusqueda
                        cambiarElegido={cambiarOpcionCurso}
                        estiloMultiAction={true}
                        opciones={cursosSede.resultados}
                        textoDefault={multi_accion.curso}
                        textoElegido={cursoSeleccionada.nombre}
                        textoNoHayOpciones={multi_accion.sin_curso}
                      />
                    </div>

                    <button
                      className={`focusable-red md:w-4/12 lg:w-auto quicksand-700 text-17.6 center pt-3 md:pt-0 ${cursoSeleccionada.id !== null ? 'onprimary-var1--color hover:text-amarillo' : `bg-transparent cursor-default ${accesibilidad.temaActual === "clasico" ? 'text-gris-alfa' : 'ondisabled-var1--color'}`}`}
                      onClick={() => {
                        if (sedeSeleccionada.id !== null) {
                          if (modalMover) {
                            MicroModal.show('estas-seguro-mover-profesores', {
                              awaitCloseAnimation: true
                            });
                          } else {
                            setModalMover(true)
                          }
                        }

                      }}
                    >
                      <span className='icon-mover mr-3 lg:hidden xl:block'></span>
                      {botones.mover}
                    </button>
                    <button
                      className="focusable-red md:w-4/12 lg:w-auto mr-10 onprimary-var1--color hover:text-amarillo quicksand-700 text-17.6 center pt-3 md:pt-0"
                      onClick={() => {
                        if (modalEliminar) {
                          MicroModal.show('estas-seguro-eliminar', {
                            awaitCloseAnimation: true
                          });
                        } else {
                          setModalEliminar(true)
                        }
                      }}
                    >
                      <span className="icon-basura mr-3 lg:hidden xl:block"></span>
                      {numeroProfesoresSeleccionados !== 1 ? botones.eliminar.plural : botones.eliminar.singular}
                    </button>
                  </div>
                </div>

              </div>
            }


            <div className="alineado-izquierda-center my-5">
              <Link
                to="/profesores/crear-profesores"
                className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center mr-5 focusable-primary"
              >
                <span className="icon-volver pr-3"></span>
                {botones.regresar}
              </Link>
              <button
                onClick={crearProfesores}
                className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center focusable-primary"
              >
                {
                  preloader ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    : botones.crear.masivo
                }
              </button>
            </div>
          </>
          :
          <VentanaAlerta
            textosInterfaz={{
              titulo: no_hay_profesores.titulo,
              descripcion: no_hay_profesores.descripcion,
            }}
            imagen={{
              alt: 'blop triste porque no hay cursos',
              img: 'alertas/blop-alerta-cursos.png',
            }}
          >
            <Link
              className="boton-justo mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado focusable-primary"
              to='/profesores/crear-profesores'
            >
              {botones.volver.crear_profesores}
            </Link>
          </VentanaAlerta>
        }
      </div>

      {/* Modal pregunta si desea mover profesores */}
      {modalMover &&
        <>
          <Modal nombreModal="estas-seguro-mover-profesores">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-pregunta.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_mover.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{modal_mover.descripcion_1} <span className="text-resaltado"> {numeroProfesoresSeleccionados} {numeroProfesoresSeleccionados !== 1 ? multi_accion.profesores.singular : multi_accion.profesores.plural} </span> {modal_mover.descripcion_2} <span className="text-resaltado">{sedeSeleccionada.nombre} </span> {modal_mover.descripcion_3}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-mover-profesores`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" onClick={() => {
                moverProfesores();
                setModalConfirmarMover(true)
              }}>
                <p>{botones.mover}</p>
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion de profesores movidos */}
          <ModalConfirmacion nombreModal="profesor-movido">
            <button
              aria-label="Cerrar ventana"
              className="icon-cerrar text-20 right-6 absolute focusable-primary onbackground-var1--color  "
              onClick={() => {
                setModalConfirmarMover(false);
                MicroModal.close(`profesor-movido`, {
                  awaitCloseAnimation: true
                });
              }}>
            </button>

            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center "> {modal_exito.mover.titulo}</h1>

            <p className="text-center r7-16-negro26 mb-4">
              {numeroProfesoresSeleccionados !== 1 ? modal_exito.mover.descripcion.masivo : modal_exito.mover.descripcion.individual}
            </p>
          </ModalConfirmacion>
        </>
      }

      {/* Modal pregunta si desea eliminar profesores */}
      {modalEliminar &&
        <>
          <Modal nombreModal="estas-seguro-eliminar">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_eliminar.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{modal_eliminar.descripcion_1} <span className="text-resaltado">{numeroProfesoresSeleccionados} {numeroProfesoresSeleccionados !== 1 ? multi_accion.profesores.singular : multi_accion.profesores.plural} </span>{modal_eliminar.descripcion_2} </p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-eliminar`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" onClick={eliminarProfesoresNuevos}>
                <p>{numeroProfesoresSeleccionados !== 1 ? botones.eliminar.plural : botones.eliminar.singular}</p>
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion de profesores movidos */}
          <ModalConfirmacion nombreModal="profesor-eliminado">
            <button
              aria-label="Cerrar ventana"
              className="icon-cerrar text-20  right-6 absolute focusable-primary onbackground-var1--color"
              onClick={() => {
                MicroModal.close(`profesor-eliminado`, {
                  awaitCloseAnimation: true
                });

              }}>
            </button>

            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_exito.eliminar.titulo} </h1>

            <p className="text-center r7-16-negro26 mb-4">
              {numeroProfesoresSeleccionados !== 1 ? modal_exito.eliminar.descripcion.masivo : modal_exito.eliminar.descripcion.individual}
            </p>
          </ModalConfirmacion>
        </>
      }
      {/* Modal descartar*/}
      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal profesor-creado */}
      {!isError &&
        <ModalConfirmacion nombreModal="profesor-creado">
          <Link className="icon-cerrar text-20  right-6 absolute focusable-primary onbackground-var1--color" to="/profesores"></Link>
          <img
            alt="eyex feliz"
            className="w-5/12 mx-auto bg-img"
            src='/images/eyex-creado.png'
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{profesor_creado.titulo}</h1>
          <p className="text-center r7-16-negro26 mb-4">
            {profesor_creado.masivo.descripcion} <span className="text-resaltado">{datosProfesoresNuevos.length}</span> {datosProfesoresNuevos.length !== 1 ? profesor_creado.masivo.profesores.plural : profesor_creado.masivo.profesores.singular} {profesor_creado.masivo.en} {sedesProfesores.numeroSedes === 1 && profesor_creado.masivo.sede.singular} <span className="text-resaltado">
              {sedesProfesores.numeroSedes !== 1 ? sedesProfesores.numeroSedes : sedesProfesores.sede}</span> {sedesProfesores.numeroSedes !== 1 && profesor_creado.masivo.sede.plural}
          </p>
          <div className="center my-3">
            <Link
              to="/profesores"
              className="boton-justo boton-amarillo boton_hover_morado py-2 focusable-primary"
            >
              {botones.volver.principal}
            </Link>
          </div>
        </ModalConfirmacion>
      }
    </>
  )
}
export default VerificarProfesorMasivo;

VerificarProfesorMasivo.propTypes = {
  /**
   * función que se encarga de actualizar el id de la sede en la que se desea crear el profesor(es). 
   * @type{Function({ idSede:string }):void}- callback
  */
  actualizarSedeAsignada: PropTypes.func.isRequired,

  /**
 * funcion que cambia el curso por el que se desea filtrar
 * @type{Function({ idSede: string, idCurso: string }):void} -callback
 */
  cambiarCurso: PropTypes.func.isRequired,

  /**
   * función que se encarga de almacenar los profesores seleccionados con el check.
   * @type{Function ({ e: event, idSede: string, profesor: shape }):void } -callback
   */
  cambiarProfesoresElegidos: PropTypes.func.isRequired,

  /**
  * función que se encarga de cancelar la acción del multi action. 
  */
  cancelarAccionMultiSelect: PropTypes.func.isRequired,

  /**
   * función que se encarga de crear los profesores creados temporalmente ya sea de forma masiva o individual. Para esto esta función se encarga de llamar el api de crear profesores.
   */
  crearProfesores: PropTypes.func.isRequired,

  /**
 * Objeto que contiene la información del curso
 */
  cursosSede: PropTypes.shape({
    hayCursos: PropTypes.bool,
    cursos: PropTypes.array
  }).isRequired,

  /**
   * Objeto que contiene la información del curso seleccionado
   */
  cursoSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
   * función que se encarga de borrar los datos temporales que se guarden en el store.
   */
  descartarCambios: PropTypes.func.isRequired,

  /**
 *  función que se encarga de eliminar un profesor de forma individual.
 * @type{Function({ key: string }):void}-callback
 */
  eliminarProfesorIndividual: PropTypes.func.isRequired,

  /**
   * función que se encarga de eliminar varios profesores al tiempo. 
   *  @type{Function({ keys: string }):void} -callback
   */
  eliminarProfesores: PropTypes.func.isRequired,

  /**
  *  booleano que se encarga de controlar si el multi action debe aparecer o no en pantalla.
  */
  mostrarMultiSelect: PropTypes.bool.isRequired,

  /**
   * función que se encarga de cambiar la sede en la que se desean crear los profesores que se encuentran almacenados en el prop datosProfesoresNuevos. 
   */
  moverProfesores: PropTypes.func.isRequired,

  /**
  * número almacena el número de profesores seleccionados con el multiaction.
  */
  numeroProfesoresSeleccionados: PropTypes.number.isRequired,

  /**
   * Objeto que contiene la información de los profesores que se desean crear
   */
  datosProfesoresNuevos: PropTypes.array.isRequired, //Esto puede ser un objeto

  /**
  *  Es un booleano que contiene el preloader para el llamado del API de crear profesores.
  */
  preloader: PropTypes.bool.isRequired,


  /**
   * objeto que contiene dos propiedades que va a devolver cuando el API crear profesores termine de responder.
   */
  respuestaApiCrear: PropTypes.shape({
    isError: PropTypes.string,
    mensaje: PropTypes.string
  }).isRequired,

  /**
   *  objeto que cuenta con los datos de la sede seleccionada para mover al o los profesores de sede en la que se desea crear. 
   */
  sedeSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
  *  array de objetos que contiene el listado de sedes que el instituto tiene.
  */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
     *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
     */
  textosInterfaz: PropTypes.object.isRequired,

  /**
  * objeto donde se almacenan los datos en los que se desean crear los profesores
  */
  sedesProfesores: PropTypes.shape({
    numeroSedes: PropTypes.number,
    sede: PropTypes.string
  }).isRequired
}