import randomNumber from './../randomNumber';

  /**
   * @typedef {{
      id:string,
      nombre:string,
      descripcion:string,
      icono:string,
      archivo:{path:string,name:string},
      estudiantes:Array<object>,
      libros:Array<object>,
      numeroTotalDeEstudiantes:number,
      numeroTotalDeLibros:number

}} Club
**/

/**
 * Obtiene los datos RAW de clubs traidos desde el API, y los transforma en un arreglo de objetos que permite facilitar mejor el acceso a los datos.
 * @param {{datos:object}} { datos }
 * @returns {Array<Club>} - Retorna arreglo de clubs.
 */
const transformarClubs = async ({ datos }) => {
  const nuevosClubs = Array.isArray(datos)?[...datos]:[];
  let infoClubs = [];

  nuevosClubs.length !== 0 && nuevosClubs.forEach(club => {
    infoClubs.push({
      archivo: club.archivo,
      descripcion: club.descripcion,
      estudiantes: club.estudiantes,
      icono: !!club.icono
        ? club.icono
        : randomNumber({ minimo: 1, maximo: 5 }),
      id: club.id,
      libros: club.libros,
      nombre: club.nombre,
      numeroTotalDeEstudiantes: club.estudiantes.length,
      numeroTotalDeLibros: club.libros.length,
    })
  })

  return infoClubs;
}

export default transformarClubs