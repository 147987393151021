import { Icon } from '@iconify/react';
import { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../store';
//Funciones
import generalCallApi from './helpers/generalCallApi';
import { AccesibilidadContext } from '../contexts/AccesibilidadContext';

const ValidacionesIniciales = () => {
  const dispatch = useDispatch();
  const state = useTrackedState();
  const { isPending } = state;
  const history = useHistory();
  const location = useLocation();
  const accesibilidad = useContext(AccesibilidadContext);
  const [logoImagen, setLogoImagen] = useState('/images/makemake-logo-principal.png');

  useEffect(() => {
    const logo =  accesibilidad.temaActual === "clasico" ? '/images/makemake-logo-principal.png' : '/images/logo-makemake-contraste.svg'
    setLogoImagen(logo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accesibilidad.temaActual])

  useEffect(() => {
    /*
      Este componente se ejecuta siempre al iniciar la aplicación o abrir una página por primera vez, esto se hace para evitar que se pueda consultar secciones internas sin iniciar sesión.
      Verifica la sesión activa, si está activa redirige al api de planActivo si no está activo redirige al login.
      Si se está en el home el api de planActivo redirige a la sección correspondiente:
      Profesor: cursos
      Rector: institutos
      Estudiantes:estudiante
    */
    const quitarPreloaderGeneral = () => {
      dispatch({
        type: "SET_DATA",
        property: "isPending",
        value: false
      })
    }

    //AE: En las secciones individuales es necesario añadir una validación en el API para el caso de que el usuario ingrese a una sección a la que no está autorizado, por ejemplo si un profesor entra a sedes el api debería devolver que no está autorizado y redirigir a la sección por defecto de profesores que es cursos.

    const validarSesion = async () => {
      const pathname = '/login';
      const result = await generalCallApi({ pathname });
      const error = result.status === 0;

      if (!error) {
        const data = result.data;
        const instituto = data.instituto;
        switch (data.rol) {
          case 'rector':
            if (location.pathname === '/' && Boolean(data.eligiendoLibros) === false) 
            {
              history.push('/institutos')
            }
            else if (location.pathname === '/' && Boolean(data.eligiendoLibros)) history.push('/libros')
            break;
          case 'profesor':
            if (location.pathname === '/') history.push('/cursos')
            break;
          case 'estudiante':
            if (location.pathname === '/') history.push('/estudiante/home')
            break;
          default:
            history.push('/404')
            break;
        }
        if (data.rol !== 'estudiante') {
          //Datos de usuario
          const contrasena = Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2);

          const avatarRuta = data.avatar.replace("/img/", "/images/avatares/");
          const datosUsurio = {
            avatar: avatarRuta,
            contrasena: contrasena,
            correo: data.email,
            instituto: instituto.nombre,
            nombre: data.nombreCompleto,
          }

          dispatch({
            type: "SET_DATA",
            property: "datosDeUsuario",
            value: datosUsurio
          })


          //Informacion plan activo
          const planActivo = {
            eligiendoLibros: data.eligiendoLibros === 1 ? true : false,
            // eligiendoLibros: false,
            fechaFinSuscripcion: data.fechaFinSuscripcion,
            numeroMaximoDeSedes: instituto.numeroMaximoDeSedes,
            crearCursos: data.crearCursos === 1 ? true : false,
            crearClubs: data.crearClubs === 1 ? true : false,
            crearEstudiantes: data.crearEstudiantes === 1 ? true : false,
            crearProfesores: data.crearProfesores === 1 ? true : false,
            crearSedes: data.crearSedes === 1 ? true : false,
            escogerLibros: data.escogerLibros === 1 ? true : false,
            tipoUsuario: data.tipoUsuario,
            verClubs: data.verClubs === 1 ? true : false,
            verEstadisticas: data.verEstadisticas === 1 ? true : false,
            verEstudiantes: data.verEstudiantes === 1 ? true : false,
            verProfesores: data.verProfesores === 1 ? true : false,
            verSedes: data.verSedes === 1 ? true : false,
            color: { ...instituto.color },
          }


          dispatch({
            type: "SET_DATA",
            property: "planActivo",
            value: planActivo
          })

          const resultPaquete = await generalCallApi({
            pathname: '/paquetes/paquetesDisponibles'
          });

          const paquetes = [];

          resultPaquete.data.length !== 0 && resultPaquete.data.map(paquete => paquetes.push(paquete))

          dispatch({
            type: "SET_DATA",
            property: "paquetes",
            value: paquetes
          })

          const resultDatosInstituto = await generalCallApi({
            pathname: '/sedes/obtenerInstituto'
          });
          let paquetesSede = [];
          resultDatosInstituto.data.paquetes.length !== 0 && resultDatosInstituto.data.paquetes.map(paquete => paquetesSede.push({ nombre: paquete }))

          let sedes = []
          resultDatosInstituto.data.sedes.length !== 0 && resultDatosInstituto.data.sedes.map(sede => {
            return sedes.push(
              {
                color: sede.color,
                id: sede.id,
                correo: sede.correo,
                cursos: sede.cursos,
                fechaFinSuscripcion: sede.fechaFinSuscripcion,
                fechaInicioSuscripcion: sede.fechaInicioSuscripcion,
                nombre: sede.nombre,
                numeroDeLibrosAsignados: sede.numeroDeLibrosAsignados,
                numeroTotalDeEstudiantes: sede.numeroTotalDeEstudiantes,
                numeroTotalDeProfesores: !!sede.numeroTotalDeProfesores ? sede.numeroTotalDeProfesores : 0,
                paquete: sede.paquetes,
                telefono: sede.telefono,
              }
            )
          })

          resultDatosInstituto.data = {
            ...resultDatosInstituto.data,
            id: resultDatosInstituto.data.idInstituto,
            pais: 'CO',
            paquetes: paquetesSede,
            sedes: sedes
          }

          dispatch({
            type: "SET_DATA",
            property: "datosInstitucion",
            value: resultDatosInstituto.data
          })

          const { eligiendoLibros, escogerLibros, tipoUsuario } = planActivo;

          quitarPreloaderGeneral();

          // if (eligiendoLibros) {
          //   if (escogerLibros) {
          //     return location.pathname === "/"&&history.push('/libros')
          //   } else {
          //     return history.push('/no-disponible')
          //   }
          // } else {
          //   switch (tipoUsuario) {
          //     case 'rector':
          //       return history.push('/institutos')
          //     case 'profesor':
          //       return history.push('/cursos')
          //     default:
          //       return false
          //   }
          // }

        } else {

          const avatarRuta = data.avatar.replace("/img/", "/images/avatares/");
          const infoEstudiante = {
				...data,
				avatar: avatarRuta,
				codigo: data.codigo,
				correo: data.correo,
				id: data.idEstudiantePlanLector,
				nombre: data.nombre,
				codigoColegio: data.codigoColegio,
				idCursoPlanLector: data.idCursoPlanLector,
				totalHorasLeidas: data?.totalHorasLeidas,
				totalLibrosLeidos: data?.totalLibrosLeidos,
				totalLogros:data?.totalLogros,
			};

          dispatch({
            type: "SET_DATA",
            property: "planActivo",
            value: { tipoUsuario: 'estudiante' }
          })

          dispatch({
            type: "SET_DATA",
            property: "perfilEstudiante",
            value: infoEstudiante
          })

          dispatch({
            type: "SET_DATA",
            property: "datosInstitucion",
            value: data.instituto
          })
        }


        // history.push('/estudiante/home');
        quitarPreloaderGeneral();
      } else {
        const url = location.pathname.search('/login/recuperar-contrasena');
        history.push(url !== -1 ? url : '/login');
        quitarPreloaderGeneral();
      }
    }
    validarSesion()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`splash-screen ${isPending === false && 'ocultar'}`}>
        <img
          className='logo'
          src={logoImagen}
          alt='MakeMake splash screen'
        />
        <Icon icon={'icomoon-free:spinner9'} className={'spinner'} />
      </div>

    </>
  )
}



export default ValidacionesIniciales;