import { Switch, Route } from 'react-router-dom';
//Componentes
import Error_404 from '../Error_404';
import Login from './Login';
import RecuperarContrasena from './RecuperarContrasena';
import Footer from './../footer/index';

/**
 * Es el Container principal de la sección de login. Su función es renderizar el container correspondiente. Los containers que pueden ser renderizados son Login y RecuperarContrasena.
 */
const LoginContainer = () => {
  return (
    <>
      <Switch>
        <Route exact path='/login'>
          <Login />
        </Route>
        <Route exact path='/login/recuperar-contrasena'>
          <RecuperarContrasena />
        </Route>
        <Route component={Error_404} />
      </Switch>
      <Footer />
    </>
  )
}

export default LoginContainer