import axios from "axios";

/**
 * Realiza  peticiones a un API y retorna la información solicitada, permite agregar parametros a la petición.
 * @param {{
 * pathname: string,
 * properties: object,
 * formdata: object,
 * method: string,
 * }} parametros - Objeto con los parámetros de la llamada a la API. 
 * @returns {Promise<any>} - Promesa que se resuelve con el resultado de la llamada a la API.
 */
const generalCallApi = async ({ pathname, properties, config, formdata, method = 'post' }) => {
  try {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    const headers = {
      ...config?.headers
    };
    if(formdata === true && !headers?.["Content-Type"]){
      headers["Content-Type"] = 'multipart/form-data';
    }
    const result = await axios[method](pathname, properties, {
		...config,
		headers
	});
    const info = result.info;

    if (info !== 'El usuario no está autenticado') {
      return result.data;      
    }



  } catch (err) {
    console.error(err)
    return err;
  }

}
export default generalCallApi;