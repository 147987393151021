//Hooks y funciones personalizadas
import useObtenerTraducciones from './../helpers/hooks/useObtenerTraducciones';
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Componentes
import RecursosComponent from './../../components/recursos/index';

/**
 * Este es un componente de tipo container. Su principal función es obtener la información correspondiente de los recursos de interfaz y contenido. Además, retorna el componente  RecursosComponent al cual se le pasa una serie de propiedades que le permiten renderizar la pantalla de recursos.  
 * @returns {JSX.Element} RecursosComponent
 */
const Recursos = () => {
  //Hooks personalizados
  const { isReady: isReadyInterfaz, textosSeccion: textosInterfaz } = useObtenerTraducciones('recursos_interfaz');
  const { isReady: isReadyRecursos, textosSubSecciones: recursosContenido } = useObtenerSubsecciones('recursos_contenido', true);

  return (
    <>
      {
        isReadyInterfaz && isReadyRecursos &&
        <RecursosComponent
          textosInterfaz={textosInterfaz}
          textosRecursos={recursosContenido}
        />
      }
    </>
  )
}

export default Recursos;