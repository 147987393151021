import axios from "axios";

export class DetalleEstudiantesAPI {

 

  // DEFINICIÓN DE LOS TIPOS
  /**
   * @typedef {{
    idEstudiante: string,
    codigo:string,
    codigoAsignadoInstitucion:string,
    fechaLectura:string,
    nombreCompleto: string,
    tiempoTotalLectura:number,
    sesionesDeLectura: number,
    tiempoPromedioDeLectura: number,//mins
    tiempoPromedioDeSesion: number //mins
    sede:{
      idSede:string,
      nombreSede:string
    },
    curso:{
      idCurso:string,
      nombreCurso:string
    },
    profesor:string[]
  }
} DetalleEstudiantes
**/

/**
 * @type {DetalleEstudiantes}
 */
static detalleEstudiantesPorDefecto = {
  idEstudiante:'N/A',
  nombreCompleto:'N/A',
  sesionesDeLectura:0,
  tiempoPromedioDeLectura:0,
  tiempoPromedioDeSesion:0,
  codigo:'N/A',
  codigoAsignadoInstitucion:'N/A',
  curso:{idCurso:'',nombreCurso:''},
  sede:{idSede:'',nombreSede:''},
  fechaLectura:'',
  profesor:[],
  tiempoTotalLectura:0

}



  //---------------------------------------------------------------------------------
  // EMPIEZAN LOS SERVICIOS

  /**
* devuelve un arreglo con las estadisticas de los estudiantes de un curso.
* @param {String} idCurso - el id del curso 
* @param {String} fechaInicio - la fecha de inicio de busqueda de información
* @param {String} fechaFin - la fecha de fin  de busqueda de información
* @returns {Promise<DetalleEstudiantes[]>} DetalleEstudiantes[]
*/
  static async obtenerDetallesPorCurso({idCurso, fechaInicio, fechaFin, abortSignal=null}) {
    const body = {
        idCurso:idCurso || '',
        fechaInicio:fechaInicio || '', 
        fechaFin:fechaFin || ''
    }
    try {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.defaults.withCredentials = true;
      const response = await axios({
        method: 'POST',
        url: 'estadisticas/estadisticasDetalleEstudiantes',
        data:body,
        signal:abortSignal || null
      })
      if (response.data.status != 1) {
        return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
    }

    const estadisticas = response.data?.data || [DetalleEstudiantesAPI.EstadisticaGeneralPorDefecto];
    if(estadisticas && Array.isArray(estadisticas)){
        return estadisticas.map((estadisticaCurso,i)=>{
            const objeto=estadisticaCurso[Object.keys(estadisticaCurso)[0]];
            return {...objeto, idSede:Object.keys(estadisticaCurso)[0]}
        })
    }
    return [];
      
    } catch (error) {
      return Promise.reject(error);
    }

  }

    /**
* devuelve un arreglo con las estadisticas de los estudiantes de una sede.
* @param {String} idSede - el id de la sede 
* @param {String} fechaInicio - la fecha de inicio de busqueda de información
* @param {String} fechaFin - la fecha de fin  de busqueda de información
* @returns {Promise<DetalleEstudiantes[]>} DetalleEstudiantes[]
*/
static async obtenerDetallesPorSede({idSede, fechaInicio, fechaFin, abortSignal=null}) {
  const body = {
      idSede:idSede,
      fechaInicio:fechaInicio || '', 
      fechaFin:fechaFin || '',
  }
  try {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    const response = await axios({
      method: 'POST',
      url: 'estadisticas/estadisticasDetalleEstudiantes',
      data:body,
      signal:abortSignal || null
    })
    if (response.data.status != 1) {
      return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
  }

  const estadisticas = response.data?.data || [DetalleEstudiantesAPI.EstadisticaGeneralPorDefecto];
  if(estadisticas && Array.isArray(estadisticas)){
      return estadisticas.map((estadisticaSede,i)=>{
          const objeto=estadisticaSede[Object.keys(estadisticaSede)[0]];
          return {...objeto, idSede:Object.keys(estadisticaSede)[0]}
      })
  }
  return [];
    
  } catch (error) {
    return Promise.reject(error);
  }

}

}

