import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DivisionCrud from '../globales/DivisionCrud';
const SkeletonInstitutoCrear = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={306} height={40} />
      <div className="contenido">
        <h1 className="titulos-principales mb-5">
          <Skeleton className="mb-2" width={400} height={36} />
        </h1>

        <DivisionCrud>
          <>
            <Skeleton width={97} height={24} />
          </>
          <div className="xl:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={97} height={24} />
            <div className="xl:w-64">
              <Skeleton width={'100%'} height={63} />
            </div>
          </>
          <div className="xl:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={97} height={24} />
            <div className="xl:w-64">
              <Skeleton width={'100%'} height={63} />
            </div>
          </>
          <div className="xl:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={150} height={24} />
            <div className="xl:w-64">
              <Skeleton width={'100%'} height={63} />
            </div>

          </>
          <div className="xl:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={170} height={24} />
            <div className="xl:w-64">
              <Skeleton width={'100%'} height={63} />
            </div>
          </>
          <div className="xl:w-451p grid grid-cols-3 md:grid-cols-6 lg:grid-cols-3 gap-4 ">
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={170} height={24} />

            <div className="xl:w-64">
              <Skeleton width={'100%'} height={63} />
              <Skeleton className=" mt-3" width={'100%'} height={24} />
              <Skeleton width={'100%'} height={24} />
            </div>
          </>
          <div className="xl:w-451p">
            <Skeleton width={'100%'} height={144} />
          </div>
        </DivisionCrud>

        <div className="flex justify-end my-5">
          <button className=" w-1/2 sm:w-40 mr-5">
            <Skeleton width={'100%'} height={36} />
          </button>
          <button className=" w-1/2 sm:w-40">
            <Skeleton width={'100%'} height={36} />
          </button>
        </div>
      </div>
    </SkeletonTheme>
  )
}
export default SkeletonInstitutoCrear;