import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DivisionCrud from "../../../globales/DivisionCrud";

const SkeletonEstudiantesCrearMasivo = () => {
  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <div className="w-40 sm:w-96 ">
          <Skeleton className="ruta" width={'100%'} height={40} />
        </div>

        <div className="contenido">
          <h1 className="titulos-principales ">
            <div className="md:w-64 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>

          <div className="flex pb-5">
            <div className='w-60'>
              <Skeleton width={'100%'} height={36} />
            </div>
            <div className='w-72 ml-5'>
              <Skeleton width={'100%'} height={36} />
            </div>
          </div>

          <DivisionCrud>
            <>
              <Skeleton width={150} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={150} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={120} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={120} />
              <Skeleton className="mt-3" width={180} height={24} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={144} />
            </div>
          </DivisionCrud>


          <div className="flex justify-end my-5">
            <button className=" w-1/2 sm:w-40 mr-5">
              <Skeleton width={'100%'} height={36} />
            </button>
            <button className=" w-1/2 sm:w-40">
              <Skeleton width={'100%'} height={36} />
            </button>
          </div>
        </div>
      </SkeletonTheme>

    </>
  )
}
export default SkeletonEstudiantesCrearMasivo;