import { useRef } from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { useTrackedState } from '../../store';
import CajaCreadoContenido from "../globales/cajasCrear/CajaCreadoContenido";

const CursoCreado = (props) => {
  const { resultados, textos, textoBotonEditar, tooltips } = props;
  const { estadisticas, boton_ver_editar, boton_ver_todo, estudiante, libro, profesor } = textos;
  const cajaCursoCreado = useRef(null);
  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';


  return (
    <>
      {
        resultados.map((curso, index) => {
          return (
            <div key={`curso-${index}`} className="p-3">
              <div className="caja-creado h-full" ref={cajaCursoCreado}>
                <div className="caja-creado-titulo surface-var1--bg alineado-verticalmente relative overflow-hidden ">
                  <img className="caja-creado-titulo-img img-80-center" src={`/images/badges/badge-${curso.icono}.png`} alt="icono del curso" />
                  <p className="q7-20-blanco ml-20 una-linea-texto">{curso.nombre}</p>
                </div>

                <div className=" px-5 xl:px-10 ">
                  <CajaCreadoContenido
                    link="./profesores"
                    parametrosBusqueda={`?sede=${curso.idSede}&curso=${curso.id}`}
                    textoLink={boton_ver_editar}
                    ocultarElementos={{
                      link: rolUsuario === 'profesor' ? true : false
                    }}
                  >
                    <span className="onsurface-var1--color icon-profesor caja-creado-icono"></span>

                    <div>
                      {curso.profesores.length === 0 ?
                        <>
                          <p className="q7-16-negro26">
                            {profesor.sin_profesor}
                            <span
                              className="red-inv-var2--color icon-alerta text-18 cursor-pointer ml-3"
                              data-tip={true}
                              data-scroll-hide={true}
                              data-for='tool-profesores'
                            ></span>
                          </p>

                        </>
                        :
                        Object.keys(curso.profesores).map(item => {
                          return <p key={item} className="q4-16-negro26">{curso.profesores[item].nombre}</p>
                        })
                      }
                      {!curso.profesor &&
                        <ReactTooltip
                          backgroundColor="#FF3548"
                          className="quicksand-400"
                          id="tool-profesores"
                          place="top"
                          textColor="#FFFFFF"
                          event="mouseover mouseenter click"
                          eventOff="mouseleave mouseout scroll mousewheel blur"
                          effect="solid"
                        >
                          <p>{tooltips.profesor}</p>
                        </ReactTooltip>
                      }
                    </div>
                  </CajaCreadoContenido>

                  <CajaCreadoContenido
                    link="./estudiantes"
                    parametrosBusqueda={`?sede=${curso.idSede}&curso=${curso.id}`}
                    textoLink={boton_ver_todo}
                  >
                    <span className="onsurface-var1--color icon-usuario caja-creado-icono"></span>

                    <div className="w-full alineado-verticalmente">
                      <p className={!curso.numeroTotalDeEstudiantes ? "q7-16-negro26" : "q4-16-negro26"}>
                        {curso.numeroTotalDeEstudiantes} {curso.numeroTotalDeEstudiantes !== 1 ? estudiante.plural : estudiante.singular}

                        {!curso.numeroTotalDeEstudiantes &&
                          <span
                            className="icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
                            data-tip={true}
                            data-scroll-hide={true}
                            data-for='tool-estudiantes'
                          ></span>
                        }
                      </p>
                      {!curso.numeroTotalDeEstudiantes &&
                        <ReactTooltip
                          backgroundColor="#FF3548"
                          className="quicksand-400"
                          id="tool-estudiantes"
                          place="top"
                          textColor="#FFFFFF"
                          event="mouseover mouseenter click"
                          eventOff="mouseleave mouseout scroll mousewheel blur"
                          effect="solid"
                        >
                          <p>{tooltips.estudiantes}</p>
                        </ReactTooltip>
                      }
                    </div>
                  </CajaCreadoContenido>

                  <CajaCreadoContenido
                    link="./libros"
                    parametrosBusqueda={`?sede=${curso.idSede}&curso=${curso.id}`}
                    textoLink={boton_ver_todo}
                  >
                    <span className="onsurface-var1--color icon-libro caja-creado-icono"></span>

                    <div className="w-full alineado-verticalmente">
                      <p className={!curso.numeroDeLibrosAsignados ? "q7-16-negro26" : "q4-16-negro26"} >
                        {curso.numeroDeLibrosAsignados} {curso.numeroDeLibrosAsignados !== 1 ? libro.plural : libro.singular}

                        {!curso.numeroDeLibrosAsignados &&
                          <span
                            className=" icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
                            data-tip={true}
                            data-scroll-hide={true}
                            data-for='tool-libros'
                          ></span>
                        }
                      </p>

                      {!curso.librosAsignados &&
                        <ReactTooltip
                          backgroundColor="#FF3548"
                          className="quicksand-400"
                          id="tool-libros"
                          place="top"
                          textColor="#FFFFFF"
                          event="mouseover mouseenter click"
                          eventOff="mouseleave mouseout scroll mousewheel blur"
                          effect="solid"
                        >
                          <p>{tooltips.libros}</p>
                        </ReactTooltip>
                      }

                    </div>


                  </CajaCreadoContenido>

                  <div className="center flex-col mt-5 mb-7" >
                    <Link
                    to={{
                      pathname: "/estadisticas/estadisticas-globales",
                      search: `?sede=${curso.idSede}&curso=${curso.id}`
                    }}
                    className="focusable-primary alineado-verticalmente"
                  >
                    <span className="icon-estadisticas-barra mr-5 red-inv-var2--color text-22 mt-1"></span>
                    <span className="evento_rojo">{estadisticas}</span>
                  </Link>

                    {
                      rolUsuario === 'rector' &&
                      <Link
                        to={`./cursos/editar-curso/${curso.idSede}/${curso.id}`}
                        className="focusable-primary boton-amarillo boton_hover_morado boton-justo mt-6"
                      >
                        {textoBotonEditar.curso}
                      </Link>
                    }
                  </div>
                </div>

              </div>
            </div>

          )
        })
      }

    </>
  )

}
export default CursoCreado;