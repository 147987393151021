import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Apis
import generalCallApi from '../helpers/generalCallApi';
//Componentes
import VerificarClubComponent from './../../components/clubs/VerificarClubComponent';
import SkeletonClubVerificar from '../../components/clubs/Skeletons/SkeletonClubVerificar';
import transformarClubs from './../../utilities/function/clubs/transformarClubs';
import PropTypes from 'prop-types';

/**
 * Container que recibe como prop la función de descartarCambios e isLoadingCursos. Este container renderiza el componente VerificarClubComponent al cual se le pasan una serie de propiedades que muestran la pantalla de verificar campos. La función principal de este componente es crear club.
 * @returns {JSX.Element} VerificarClubComponent
 */
const VerificarClub = (props) => {
  const { descartarCambios, isLoadingCursos } = props;
  //Estados globales
  const state = useTrackedState();
  const { temporal } = state;
  const dispatch = useDispatch();

  //Estados del componente
  const [datosClub, setDatosClub] = useState(null)
  const [preloader, setPreloader] = useState(false)
  const [respuestaApiCrear, setRespuestaApiCrear] = useState({
    isError: null,
    mensaje: ''
  })

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('clubs');


  //Librerias adicionales
  const history = useHistory();


  useEffect(() => {
    if (!!temporal?.clubs) {
      const infoClub = temporal.clubs;

      setDatosClub({
        archivo: infoClub.archivo,
        descripcion: infoClub.descripcion,
        estudiantes: infoClub.estudiantes,
        icono: infoClub.icono,
        libros: infoClub.libros,
        nombre: infoClub.nombre,
        sede: infoClub.sede,
      });
      return
    }
    history.push('/clubs');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal])


  const crearClub = async () => {
    if (!preloader) {
      setPreloader(true);
      setRespuestaApiCrear({
        isError: null,
        mensaje: ''
      })

      const pathname = '/clubs/crearClub';
      const properties = {
        archivo: !!datosClub.archivo ? datosClub.archivo.archivo : null,
        descripcion: datosClub.descripcion,
        icono: datosClub.icono,
        idEstudiantes: datosClub.estudiantes.ids,
        idLibros: datosClub.libros.ids,
        nombre: datosClub.nombre,
      }

      let formData = new FormData();
      for (const key in properties) {
        let value = properties[key];
        if (key !== 'descripcion' && key !== 'nombre' && key !== 'archivo') {
          value = JSON.stringify(value)
        }
        if (key === 'archivo' && datosClub?.archivo && datosClub?.archivo?.nombre) {
          formData.append(key, value, !!datosClub.archivo ? datosClub.archivo.nombre : 'guiaClub.pdf');
        }
        else {
          formData.append(key, value);
        }
      }
      const respuesta = await generalCallApi({ pathname, properties: formData, formdata: true });
      const error = respuesta.status === 0;


      if (!error) {
        const resultClubs = await generalCallApi({ pathname: '/clubs/obtenerClubs' });
        const errorClubs = respuesta.status === 0;

        if (!errorClubs) {
          const value = await transformarClubs({ datos: resultClubs.data })
          // Actualizar datos en el store 
          dispatch({
            property: 'datosClubs',
            type: 'SET_DATA',
            value: value
          });
        }
      }

      setRespuestaApiCrear({
        isError: error,
        mensaje: respuesta.info
      })
      setPreloader(false);
    }
  }


  return (
    <>
      {
        isReady && !isLoadingCursos && !!datosClub ? (
          <>
            <VerificarClubComponent
              crearClub={crearClub}
              datosClub={datosClub}
              descartarCambios={descartarCambios}
              preloader={preloader}
              respuestaApiCrear={respuestaApiCrear}
              textosInterfaz={textosInterfaz}
            />
          </>
        )
          : <SkeletonClubVerificar />
      }
    </>
  )
}

export default VerificarClub;

VerificarClub.propTypes = {
  /**
    * funcion callback que borra los datos temporales.
    */
  descartarCambios: PropTypes.func.isRequired,
  /**
   *  Booleano que valida si ha cargado la información.
   */
   isLoadingCursos: PropTypes.bool
}