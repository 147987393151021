import React from "react";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Link } from "react-router-dom";

export const ClubCard = ({ club } ) => {
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const totalBooks = Array.isArray(club?.libros) ? club?.libros?.length : 0;
	const totalStudents = Array.isArray(club?.estudiantes) ? club?.estudiantes?.length : 0;

	return (
		<article className="md:p-8 xl:p-10 mt-5">
			<div className="background-var3--bg rounded-lg  border-4 onsurface-var2--border-color relative xl:w-10/12 mx-auto py-8 px-5 md:pl-28 md:pr-8">
				<div
					className="avatar icono onsurface-var2--border-color background-var3--bg text-indent mx-auto md:absolute -top-10 -left-12"
					style={{ backgroundImage: `url(/images/badges/badge-${club?.icono}.png)` }}
				>
					icono-{club?.icono}
				</div>

				<p className="q7-16-negro28 text-center md:text-left mt-5 md:mt-0 ">
					{club?.nombre}
				</p>

				<div className="md:flex  gap-4 mt-5">
					<div className="md:w-4/12 xl:w-3/12">
						<p className="r7-18-negro ">
							{totalBooks}{" "}
							{totalBooks !== 1
								? textos?.clubs?.libros?.plural
								: textos?.clubs?.libros?.singular}
						</p>
						<p className="r7-18-negro ">
							{totalStudents}{" "}
							{totalStudents !== 1
								? textos?.clubs?.estudiantes?.plural
								: textos?.clubs?.estudiantes?.singular}
						</p>
					</div>

					<div className="md:w-8/12 xl:w-9/12 mt-5 md:mt-0">
						<p className="q4-16-negro26">
							{club?.descripcion !== "null" ? club?.descripcion : "Sin descripción"}{" "}
						</p>
					</div>
				</div>

				<div className="md:alineado-izquierda  mt-8">
					{club?.archivo.name !== "" && (
						<a
							href={`${process.env.REACT_APP_ASSETS_URL_DOCUMENTOS}${club?.archivo?.path}`}
							download={club?.archivo?.name}
							target="_blank"
							rel="noreferrer"
							className="block w-full text-center focusable-red md:w-auto md:center boton-justo boton-morado boton_hover_escalar mr-3 "
						>
							<span className="icon-descargar mr-5 hidden md:block lg:hidden xl:block"></span>
							{textos?.botones?.clubs?.descargar}
						</a>
					)}

					<Link
						to={`/estudiante/clubs/${club?.id}`}
						className="block w-full md:w-40 md:center  boton-justo text-center boton-amarillo boton_hover_morado shadow-1 mt-5 md:mt-0 py-1 focusable-primary"
					>
						{textos?.botones?.clubs?.libros}
					</Link>
				</div>
			</div>
		</article>
	);
};
