import { useState, useRef, useEffect } from "react";
import useElementoExterior from "../../../containers/helpers/hooks/useElementoExterior";
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';

/**
 * Este componente se usa en la pantalla de crear o editar club. Es el select que contiene una lista de opciones con check y permite mostrar la lista de libros seleccionados. 
 */
const InputSelectCheckbox = (props) => {
  const { datosAlmacenados, searchEstudiantes, placeholder, listaOpciones, noHaylistaOpciones, noEstaEnLista, actualizarElegidos, activadorExterno, activadorExternoLibros, propiedadNombre = 'nombre', propiedadId = 'id', notificacionTitulo, notificacionDescripcion, numeroLibros } = props;

  /**estados */
  const [activadorSelect, setActivadorSelect] = useState(false);
  const [campoInput, setCampoInput] = useState('');

  /**REF */
  const contenedorOpciones = useRef(null);
  const inputSelect = useRef(null);

  useEffect(() => {
    if (!!activadorExterno) {
      setActivadorSelect(true);
    }
  }, [activadorExterno])


  /**Funciones */
  /**esta función sirve para cerrar las opciones cuando se da click fuera del inputSelectCheckbox */
  const cerrarOpciones = () => {
    setActivadorSelect(false);

    if (!!activadorExternoLibros) {
      activadorExternoLibros(false)
    }
  }
  useElementoExterior(cerrarOpciones, inputSelect);

  /**Se pasa el texto o valor del input
   * x equivale a cada opcion de la listaOpciones
   * se filtran  y se retornan las OPCIONES que tengan relación o tengan los mismos caracteres que el VALOR del input
   */
  const filtro = (palabra) => {
    return function (x) {
      const palabras = x[propiedadNombre].toLowerCase().includes(palabra.toLowerCase());
      return palabras;
    }
  }

  /**Se actualiza el valor del input text  cuando se escribe y se abren las opciones*/
  const actualizarCampo = (valorInput) => {
    setCampoInput(valorInput)
    setActivadorSelect(true);
  }


  /**opciones del select */
  const opcionesSelect = () => {
    if (listaOpciones) {
      let opciones = listaOpciones.filter(filtro(campoInput));
      opciones = opciones.length !== 0 ?
        opciones.map((opcion, index) => {
          const checked = !!datosAlmacenados.find(dato => dato === opcion[propiedadId]) ? true : false;
          return (
            <li
              key={`opcion${index}`}
              className="item alineado-verticalmente px-3 py-2"
            >
              <label  className="focusable-by-children-red w-full h-7 relative mr-3 cursor-pointer">
                <input
                  checked={checked}
                  onChange={(e) => {
                    actualizarElegidos({ e, opcion })
                    !!searchEstudiantes && searchEstudiantes({ e });

                    if (!!numeroLibros) {
                      if (e.target.checked === true && numeroLibros >= 5) {
                        notificacion(notificacionTitulo, notificacionDescripcion, "danger");
                      }
                    };
                  }}
                  type="checkbox"
                />
                <i className="check "></i>
                <p className="raleway-400 onbackground-var1--color absolute left-10 top-0.5">{opcion[propiedadNombre]}</p>
              </label>
            </li>
          )
        })
        :
        <li className="item px-3 py-2 cursor-pointer">
          <p className="text-16 raleway-400 onbackground-var1--color">{noEstaEnLista}</p>
        </li>

      return opciones
    }

  }

  /**crea la notificaion */
  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  return (
    <>
      <div ref={inputSelect} className="input-select-checkbox">
        <div className="relative">
          <input
            className={`w-full`}
            type="text"
            value={campoInput}
            placeholder={placeholder}
            onChange={(e) => {
              actualizarCampo(e.target.value);
            }}
            onFocus={() => { setActivadorSelect(true) }}
          />
          <div
            className="absolute right-0 top-0 w-10 h-full center cursor-pointer"
            onClick={() => { setActivadorSelect(!activadorSelect) }}
          >
            <span className={`icon-select ${activadorSelect ? "" : "transform rotate-180"} transicion-500 text-20 text-gris-textos`}></span>
          </div>
        </div>
        <div
          ref={contenedorOpciones}
          style={{ height: activadorSelect ? 'auto' : '0px' }}
          className="inputTextSelect-opciones background-var3--bg shadow-1 rounded-b-md scroll max-h-56 transicion-300"
        >
          <ul className="list">

            {listaOpciones && listaOpciones.length !== 0 ?
              opcionesSelect()
              :
              <li className="px-3 py-2 cursor-pointer  ">
                <p className="text-16 raleway-400 onbackground-var2--color">{noHaylistaOpciones}</p>
              </li>
            }

          </ul>
        </div>
      </div>
    </>
  )
}
export default InputSelectCheckbox;

InputSelectCheckbox.propTypes = {
  /**
   * array con la información de los libros guardados
   */
  datosAlmacenados: PropTypes.array,
  /**
   * función que busca los estudiantes por nombre
   * @type{Function({ e: event }):void}
   */
  searchEstudiantes: PropTypes.func,
  /**
   * string que corresponde a el placeholder el input
   */
  placeholder: PropTypes.string,
  /**
   * arreglo de objetos que contienen la información de cada libro.
   */
  listaOpciones: PropTypes.array,
  /**
   * string que corresponde al mensaje que aparece cuando no hay libros
   */
  noHaylistaOpciones: PropTypes.string,
  /**
   * string que corresponde al mensaje que aparece cuando el libro que se escribe no esta en la lista
   */
  noEstaEnLista: PropTypes.string,
  /**
   * función que actualiza la lista de libros elegidos.
   * @type{Fucntion({ e: event, opcion: array}):void}
   */
  actualizarElegidos: PropTypes.func,
  /**
   * booleano que indica el estado de select
   */
  activadorExterno: PropTypes.bool,
  /**
   * Actualiza el valor del estado activador externo que en este caso es el botón de agregar libros
   */
  activadorExternoLibros: PropTypes.func,
  /**
   * string que corresponde al valor del input
   */
  propiedadNombre: PropTypes.string,
  /**
   * string que sirve para identificar las opciones seleccionadas
   */
  propiedadId: PropTypes.string,
  /**
   * string que corresponde al titulo de la notificación
   */
  notificacionTitulo: PropTypes.string,
  /**
   * string que corresponde a la descripción de la notificación
   */
  notificacionDescripcion: PropTypes.string,
  /**
   * número maximo de libros a elegir. 
   */
  numeroLibros: PropTypes.number
}