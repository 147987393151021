import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useElementoExterior from '../../../containers/helpers/hooks/useElementoExterior';

/**
 * Componente que recibe una serie de propiedades que sirven para renderizar el slector de búsqueda. En su mayoría, se usa en las pantallas iniciales de cada sección. 
 * @returns SelectorDeBusqueda
 */
const SelectorDeBusqueda = (props) => {
  const { zIndex, cambiarElegido, opciones, textoDefault, verTodasLasOpciones, textoElegido, filtro, filtroMensajeNoexite, estiloMultiAction, textoNoHayOpciones, superposicion, subposicion, alturaLista } = props;
  /*   const [alturaOpciones, setAlturaOpciones] = useState("0px"); */
  const [activadorSelect, setActivadorSelect] = useState(false);
  const [filtroSelect, setFiltroSelect] = useState(null);
  const comboboxRef = useRef(null);


  const contenedorOpciones = useRef(null);
  const selector = useRef(null);

  // useEffect(() => {
  //   if (!!contenedorOpciones.current) {
  //     setAlturaOpciones(contenedorOpciones.current.scrollHeight);
  //   }
  // }, [textoElegido, opciones])

  useEffect(() => {
    if (!!filtro) {
      for (let i = 0; i < opciones.length; i++) {
        if (filtro === opciones[i].id) {
          setFiltroSelect(opciones[i].nombre);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cerrarOpcionesSelect = () => setActivadorSelect(false);

  useElementoExterior(cerrarOpcionesSelect, selector);

  return (
    <>
      <div className={`selector ${(!!superposicion && !!zIndex === false) && 'z-60'} ${(!!subposicion && !!zIndex === false) && 'z-50'}`} ref={selector} style={{zIndex:zIndex&&zIndex}}>

        <button
          role={"combobox"}
          aria-label={textoDefault ||'Selector'}
          aria-haspopup="listbox"
          aria-expanded={activadorSelect}
          ref={comboboxRef}
          aria-controls='selector-list-busqueda'
          className={`selector-elegido focusable-primary
          ${estiloMultiAction && 'rounded onprimary-inv-var1--bg primary-inv-var1--color border surface-var3--border-color'}
          ${activadorSelect ? 'rounded-t-md' : 'rounded-md'} `}
          onClick={() => { setActivadorSelect(!activadorSelect) }}
        >
          <p className="una-linea-texto">
            {!textoElegido ?
              !!filtro ?
                filtroSelect ?
                  filtroSelect
                  :
                  filtroMensajeNoexite
                :
                textoDefault
              :
              textoElegido
            }
          </p>

          <span className={`icon-select  ${activadorSelect ? "" : "transform rotate-180"} transicion-500 text-20`}></span>
        </button>

        <div
          className={`selector-opciones scroll ${Boolean(alturaLista?.max) === false && 'max-h-60'}`}
          ref={contenedorOpciones}
          style={{
            opacity:activadorSelect?1:0,
            height: activadorSelect ? `${contenedorOpciones.current.scrollHeight}px` : '0px',
            maxHeight: Boolean(alturaLista?.max) && alturaLista?.max,
            minHeight: Boolean(alturaLista?.min) && alturaLista?.min,
          }}
        >
          <ul
            id={"selector-list-busqueda"} 
            role={"listbox"} aria-expanded={activadorSelect} 
            aria-multiselectable={false}
            className={`list ${!activadorSelect&&'invisible'}`}>
            {textoDefault && textoElegido !== textoDefault &&
              <li role={"presentation"} className="px-3 py-2 border-b animate-fadeIn primary-inv-var2--color  q7-18 ">
                {textoDefault}
              </li>
            }

            {/* {rangoFecha && textoElegido !== rangoFecha &&
              <li className="px-3 py-2 border-b animate-fadeIn border-negro-claro r5-16 ">
                <p>{rangoFecha?.fechaInicio} - {rangoFecha?.fechaFin}</p>
              </li>
            } */}

            {opciones.length !== 0 && verTodasLasOpciones &&
              <li
                className="item px-3 py-2 cursor-pointer"
              >
                  <button
                      role="option"
                      aria-selected={textoElegido===verTodasLasOpciones?.textos}
                      onClick={() => {
                        if(comboboxRef.current){comboboxRef.current.focus()}
                        if (verTodasLasOpciones) {
                          verTodasLasOpciones.alVertodasLasOpciones();
                          setActivadorSelect(false);
                        }
                      }}
                      className="w-full text-left focusable-primary text-16 raleway-400">{verTodasLasOpciones?.textos}</button>
              </li>
            }

            {opciones.length !== 0 ?
              opciones.map((opcion, index) => {
                return (
                  <li
                    key={`opcion-${index}`}
                    className="item px-3 py-2 cursor-pointer"
                  >
                     <button
                      role="option"
                      aria-selected={textoElegido===opcion?.nombre}
                      onClick={() => {
                        if(comboboxRef.current){comboboxRef.current.focus()}
                        cambiarElegido({ opcion, index });
                        setActivadorSelect(false);
                      }} 
                      className="w-full text-left focusable-primary text-16 raleway-400">{opcion.nombre}</button>
                  </li>
                )
              })
              :
              <>
                <li role={"presentation"} className="px-3 py-2 cursor-pointer hover:bg-morado-claro">
                  <p className="text-16 raleway-400 onbackground-var1--color">{textoNoHayOpciones}</p>
                </li>
              </>
            }
          </ul>
        </div>
      </div>

    </>
  )
}
export default SelectorDeBusqueda;

SelectorDeBusqueda.propTypes = {
  /**
 * callback utilizada para cuando se seleccione una opcion dentro de las diferentes opciones. Esta actualiza la lista de opciones
 * @type{Function({ opcion, index }):void} - callback
 */
  cambiarElegido: PropTypes.func.isRequired,
  /**
   * arreglo de objetos, debe incluir las opciones del select  
   * @type[{
   * id: string
   * nombre: string},]
   */
  opciones: PropTypes.array.isRequired,

  /**
   * String que indica la acción del select. Ej: elija un colegio
   */
  textoDefault: PropTypes.string,

  /**
   * String que indica el texto seleccionado 
   */
  textoElegido: PropTypes.string,
  /**
   * string que indica si existe un filtro. Debera ser una palabra que se encuentre dentro de las opciones para que se muestre unicamente la palabra o opciones especificas
   */
  filtro: PropTypes.string,
  /**
   * String. En el caso que la opcion filtro no se encuentre dentro de las opciones. Se mostrara el mensaje no exite 
   */
  filtroMensajeNoexite: PropTypes.string,

  /**
   * Bool, en caso de que el selector se encuentre dentro de un multiselect se utiliza este boooleano para cambiar los estilos del selector
   */
  estiloMultiAction: PropTypes.bool,

  /**
   * String que indica el mensaje de no hay opciones en caso de que el arreglo de opciones este vacio
   */
  textoNoHayOpciones: PropTypes.string,
  /**
   * Bool que indica cambio en estilos. En el caso que se encuentren más de 2 selectores seguidos, se debe poner esta indicación en el primer selector para que las opciones se sobrepongas al segundo. 
   */
  superposicion: PropTypes.bool,
  /**
   * Bool que indica cambio en estilos. En el caso que se encuentren más de 3 selectores seguidos, se debe poner esta indicación en el segundo selector para que las opciones se sobrepongas al segundo. 
   */
  subPosicion: PropTypes.bool,
  /**
   *Objeto que contiene el valor de fecha inicio y fecha fin. Esto se usa para el select de fecha 
   *@type{{
   * fechaInicio: string,
   * fechaFin: string
   *}}
   */
  rangoFecha: PropTypes.object,
  /**Objeto que indica el texto de ver todas las opciones del selector y la funcion al dar clic en esta opcion
   * verTodasLasOpciones={{
      textos: string
      alVertodasLasOpciones: func
    }}
   */
  verTodasLasOpciones: PropTypes.object,
  /**
   * Permite poner un z-index especifico al elemento, si esta opción se pasa las propiedades 'superposicion' y 'subposicion' serán ignoradas
   */
  zIndex:PropTypes.number

}