import { useState, useEffect } from 'react';
import MicroModal from "micromodal";

//Componentes
import DivisionCrud from "../../globales/DivisionCrud";
import Selector from '../../globales/elementosFormularios/Selector';
import SelectorCheck from '../../globales/elementosFormularios/SelectorCheck';
import InputFile from '../../globales/elementosFormularios/InputFile';
import ModalCambiarIcono from '../../globales/modales/ModalCambiarIcono';

const CrearIndividual = (props) => {
  const { actualizarIconoCurso, actualizarNombreCursos, actualizarProfesores, actualizarSedeAsignada, autorizar, botones, crear, datosTemporalesExcel, desactivar, excelValido, iconoSeleccionado, modal_icono, mostrarAlerta, nombreCurso, obtenerDatosExcel, opcionesSelector, permisoCrearEstudiantes, profesoresAsignados, profesoresInstituto, setExcelValido, setNombreCurso, isNombreCursoRepetido, sedeSeleccionada, setMostrarAlerta, mostrarAlertaArchivo, hayErrorExcel } = props;

  const [textoInputSede, setTextoInputSede] = useState(!nombreCurso ? nombreCurso : '');
  const [mostrarIcono, setMostrarIcono] = useState(false);
  const [nombreDocumento, setNombreDocumento] = useState('');  
  //Variables de entorno
  const numIconos = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_ICONOS_CURSO);

  useEffect(() => {
    setMostrarAlerta(isNombreCursoRepetido);
    validarNombreCurso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNombreCursoRepetido])

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarSede = ({ opcion }) => {
    actualizarSedeAsignada({ idSede: opcion.id })
    setTextoInputSede(opcion.nombre);
  };

  const validarNombreCurso = () => {
    const valorCampo = nombreCurso.campo.length > 0 ? isNombreCursoRepetido ? false : true : false;
    setNombreCurso({ ...nombreCurso, valido: valorCampo })
  }

  const actualizarCampo = (e, estado, cambiarEstado) => {
    actualizarNombreCursos({ nombreCurso: e.target.value });
    cambiarEstado({ ...estado, campo: e.target.value });
  }

  return (
    <>
      {/* nombre */}
      <DivisionCrud>
        <>
          <h3 className="onbackground-var1--color raleway-700">{crear.nombre.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{crear.nombre.descripcion}</p>
        </>
        <div className="xl:w-451p">

          <input
            className={`w-full ${mostrarAlerta ? !nombreCurso.valido && 'input_error' : null}`}
            onBlur={() => { validarNombreCurso() }}
            onChange={(e) => { actualizarCampo(e, nombreCurso, setNombreCurso) }}
            onKeyUp={validarNombreCurso}
            value={nombreCurso.campo}
            placeholder={crear.nombre.placeholder_nombre}
            type="text"
          />
          {mostrarAlerta &&
            <p className={`text-14 
                  ${!nombreCurso.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
            }>
              {isNombreCursoRepetido ?
                crear.nombre.error_nombre
                :
                crear.nombre.error
              }
            </p>
          }
        </div>
      </DivisionCrud>

      {/* sede */}
      <DivisionCrud>
        <>
          <h3 className="onbackground-var1--color raleway-700">{crear.sede.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{crear.sede.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarSede}
            desactivado={false}
            estiloTipoInput={true}
            opciones={opcionesSelector}
            textoDefault={crear.sede.select}
            textoElegido={!!sedeSeleccionada.nombre ? sedeSeleccionada.nombre : textoInputSede}
            estadoEnviar={mostrarAlerta}
            superposicion={true}
            valido={!!sedeSeleccionada.id ? true : false}
            textoError={crear.sede.error}
          />
        </div>
      </DivisionCrud>

      {/* profesor */}
      <DivisionCrud>
        <>
          <h3 className="onbackground-var1--color raleway-700">{crear.profesor.titulo}</h3>
          <p className="r4-14-gris6a">{crear.profesor.descripcion} </p>
        </>
        <div className="xl:w-451p">
          <SelectorCheck
            actualizarElegidos={actualizarProfesores}
            desactivado={false}
            datosAlmacenados={profesoresAsignados?.ids}
            estiloTipoInput={true}
            hayElementos={profesoresInstituto?.hayProfesores}
            opciones={profesoresInstituto?.profesores}
            textoDefault={`${profesoresAsignados?.ids?.length} ${profesoresAsignados?.ids?.length !== 1 ? crear.profesor.asignados.plural : crear.profesor.asignados.singular} `}
            textoNoHayDatos={crear.profesor.no_hay}
            subposicion={true}
          />
        </div>
      </DivisionCrud>

      {/* icono */}
      <DivisionCrud>
        <>
          <h3 className="onbackground-var1--color raleway-700">{crear.icono.titulo}</h3>
          <p className="r4-14-gris6a">{crear.icono.descripcion} </p>
        </>
        <div className="lg:w-32 mt-4 lg:mt-0">
          <div
            className={`avatar mx-auto cursor-pointer border-gris-linea ${iconoSeleccionado === null ? 'icono_null ' : 'icono text-indent'} `}
            style={{ backgroundImage: `url(/images/badges/badge-${iconoSeleccionado === null ? 'sombrero' : iconoSeleccionado}.png)` }}
            onClick={() => {
              if (mostrarIcono) {
                MicroModal.show('cambiar-icono-curso', {
                  awaitCloseAnimation: true
                });
              } else {
                setMostrarIcono(true)
              }
            }}
          >
            <p className="px-5 text-center r7-13-negro26">{iconoSeleccionado === null ? crear.icono.no_hay : ''}</p>
          </div>
          <button
            className="focusable-primary evento_rojo mx-auto block mt-3"
            onClick={() => {
              if (mostrarIcono) {
                MicroModal.show('cambiar-icono-curso', {
                  awaitCloseAnimation: true
                });
              } else {
                setMostrarIcono(true)
              }
            }}
          >
            {botones.cambiar}
          </button>
        </div>
      </DivisionCrud>

      {/* Subir excel */}
      <DivisionCrud>
        <>
          <h3 className="onbackground-var1--color raleway-700">{crear.subir_excel.titulo}</h3>
          <p className="r4-14-gris6a">{crear.subir_excel.descripcion}</p>
          <a href='/documents/plantilla_curso.xlsx' download="plantilla_curso.xlsx" className="focusable-primary evento_rojo block mt-3">{crear.subir_excel.descargar_plantilla}</a>
        </>
        <>
          <InputFile
            datoGuardado={datosTemporalesExcel}
            desactivar={desactivar}
            descripcion={
              datosTemporalesExcel.hayArchivo
                ? datosTemporalesExcel.datos.nombreArchivo === null && crear.subir_excel.descripcion_input
                : crear.subir_excel.descripcion_input
              }
            excelValido={excelValido}
            hayErrorExcel={hayErrorExcel}
            instruccion={crear.subir_excel.titulo_input}
            mostrarAlerta={mostrarAlertaArchivo}
            nombreDocumento={nombreDocumento}
            obtenerDatosExcel={obtenerDatosExcel}
            setExcelValido={setExcelValido}
            setNombreDocumento={setNombreDocumento}
            textoError={crear.subir_excel.excel_no_valido}
          />

          <div className=" flex md:items-center -h-8 my-6">
            <label className="focusable-by-children-primary relative w-16 h-7" role="checkbox" aria-checked={!autorizar}>
              <input type="checkbox" className=" h-0" onChange={(e) => { permisoCrearEstudiantes({ value: e.target.checked }) }} checked={!autorizar} />
              <i className="checkbox"><span className="no-check">{crear.opcion.no}</span></i>
            </label>
            <p className="r5-14-negro4A  ml-5 alineado-verticalmente">{crear.checkbox.descripcion}</p>
          </div>
        </>
      </DivisionCrud>

      {/* modal de cambiar icono */}
      {mostrarIcono &&
        <ModalCambiarIcono
          boton={botones.cambiar}
          cambiarAvatar={actualizarIconoCurso}
          datoIcono={iconoSeleccionado === null ? 1 : iconoSeleccionado}
          descripcion={modal_icono.descripcion}
          nombreModal="cambiar-icono-curso"
          minIconos={numIconos.minimo}
          maxIconos={numIconos.maximo}
          titulo={modal_icono.titulo}
          rutaCarpeta='badges/badge-'
        />
      }
    </>
  )
}

export default CrearIndividual;