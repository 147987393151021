//Hooks y funciones personalizadas
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';

import ReporteAlumnoComponent from '../../components/estadisticas/ReporteIndividuo/ReporteAlumno/ReporteAlumnoComponent';
import SkeletonReporteAlumno from '../../components/estadisticas/SkeletonsEstadisticas/SkeletonReporteAlumno';
import { Route, Switch } from 'react-router-dom';
import ReporteProfesorComponent from '../../components/estadisticas/ReporteIndividuo/ReporteProfesor/ReporteProfesorComponent';

const ReporteIndividuo = () => {

  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
      <>
        <Switch>
          <Route exact path="/estadisticas/ingresos-individuales/profesores/:idProfesor">
            {
              isReady ?
                <ReporteProfesorComponent traducciones={textosInterfaz}/>
                :
                <SkeletonReporteAlumno />
            }
          </Route>
          <Route exact path="/estadisticas/ingresos-individuales/estudiantes/:idEstudiante">
            {
              isReady ?
                <ReporteAlumnoComponent traducciones={textosInterfaz}/>
                :
                <SkeletonReporteAlumno />
            }
          </Route>
        </Switch>
      </>
  )
}
export default ReporteIndividuo;