/**
 * Devuelve un objeto con los datos de los objetos  que tienen la propiedad 'checked'
 * @param {object} - objeto con los datos a analizar
 */
const elegirElementosCheck = ({ e, elemento, datosActuales, id }) => {
  const idElement = !!id ? id : 'id';
  const datosAntiguos = [...datosActuales];

  const checked = e.target.checked;
  let datosActualizados = [];

  if (checked) {
    datosActualizados = [...datosAntiguos, { ...elemento }]
  } else {
    datosAntiguos.forEach(elementoAsignado => {
      if (elementoAsignado[idElement].toString() !== elemento[idElement].toString()) {
        datosActualizados.push(elementoAsignado);
      }
    })
  }

  let idElementos = [];
  if (datosActualizados.length !== 0) {
    datosActualizados.map(elemento => idElementos.push(elemento[idElement].toString()));
  }
  idElementos = [...new Set(idElementos)];

  return { ids: idElementos, datos: datosActualizados }
};

export default elegirElementosCheck;