import { useState, useRef, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { store } from 'react-notifications-component';
import MicroModal from "micromodal";
import { MigasDePan, Miga } from "../globales/MigaDePan";

/**componentes */
import DivisionCrud from "../globales/DivisionCrud";
import Selector from "../globales/elementosFormularios/Selector";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";

const EstudiantesEditarEliminarComponent = (props) => {
  const { 
    codigoAsignadoInstitucion, 
    actualizarCodigoPorInstituto,
    actualizarCorreo, 
    actualizarNombre, 
    actualizarCurso, 
    actualizarSede, 
    codigoEstudiante, 
    correoEstudiante, 
    cursoSeleccionado, 
    cursosSede, 
    datosEstudiante, 
    editarEstudiante, 
    eliminarEstudiante, 
    hayCambios, 
    nombreEstudiante, 
    preloader, 
    respuestaApiEditar, 
    respuestaApiEliminar, 
    sedeSeleccionada, 
    sedesInstituto, 
    textosInterfaz } = props;

  const { editar, botones, miga_de_pan, modal_eliminar, modal_exito } = textosInterfaz;
  const { codigo, codigo_acceso, correo, curso, cursos, nombre, sede, titulo } = editar;
  const history = useHistory();

  /**estados */
  const [inputCodigo, setInputCodigo] = useState({ campo: codigoAsignadoInstitucion, valido: true, desactivado: false });
  const [inputNombre, setInputNombre] = useState({ campo: nombreEstudiante, valido: true, desactivado: false });
  const [inputCorreo, setInputCorreo] = useState({ campo: correoEstudiante, valido: true, desactivado: false });
  const [inputSede, setInputSede] = useState({ campo: sedeSeleccionada, desactivado: true });
  const [inputCurso, setInputCurso] = useState({ campo: cursoSeleccionado.nombre, desactivado: true });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  /*ref*/
  const refNombre = useRef(null);
  const refCorreo = useRef(null);
  const refCodigo = useRef(null);

  /*expresion */
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  /**useEffect */
  /**estos useEffect sirven para que cuando cambie el estado de cada ventana modal se abra de una vez sin necesidad de dar 2 veces click sobre sus correspondientes botones */
  useEffect(() => {
    if (modalEliminar) {
      MicroModal.show('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
    }
  }, [modalEliminar]);

  useEffect(() => {
    if (respuestaApiEliminar.isError === false) {
      MicroModal.close('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
      MicroModal.show('estudiante-eliminado', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaApiEliminar.isError === true) {
      MicroModal.close('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
      notificacion(textosInterfaz.notificacion.error, respuestaApiEliminar.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEliminar.isError])

  useEffect(() => {
    if (respuestaApiEditar.isError !== null) {
      notificacion(
        respuestaApiEditar.isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        respuestaApiEditar.mensaje,
        respuestaApiEditar.isError ? "danger" : "success"
      );

      setInputNombre({ ...inputNombre, desactivado: false });
      setInputSede({ ...inputSede, desactivado: true });
      setInputCurso({ ...inputCurso, desactivado: true });
      setInputCorreo({ ...inputCorreo, desactivado: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEditar.isError]);


  /**Sirve para desactivar el campo de curso en caso que se actualice el campo de sede */
  useEffect(() => {
    if (inputSede.desactivado === false) {
      setInputCurso({ ...inputCurso, desactivado: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSede.desactivado])


  /**funciones */
  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  /**actualiza el valor del campo */
  const actualizarCampo = (e, estado, cambiarEstado) => cambiarEstado({ ...estado, campo: e.target.value });

  /**valida si el campo esta vacio */
  const validarNombre = () => {
    const valorCampo = inputNombre.campo.length > 0 ? true : false;
    setInputNombre({ ...inputNombre, valido: valorCampo })
  }

  /**Esta función comprueba que el valor del campo correo cumple con la expresión y cambia su estado */
  const validarCorreo = (expresion, estado, cambiarEstado) => {
    const valor = estado.campo !== '' ? expresion.test(estado.campo) : false;
    cambiarEstado({ ...estado, valido: valor });
  }

  /*Cambia la sede en el select */
  const cambiarSede = ({ opcion }) => {
    setInputSede({ ...inputSede, campo: opcion.nombre, desactivado: false });
    actualizarSede({ idSede: opcion.id });
  }

  const cambiarCurso = ({ opcion }) => {
    setInputCurso({ ...inputCurso, campo: opcion.nombre, desactivado: false });
    actualizarCurso({ idSede: opcion.idSede, idCurso: opcion.id });
  }

  /*actualia los cambios de todos los campos */
  const actualizarCambios = () => {
    setMostrarAlerta(true);
    if (inputNombre.valido && !!cursoSeleccionado.id && !!sedeSeleccionada.id && inputCorreo.valido) {
      editarEstudiante();
    }
  }

  useEffect(()=>{
    if(refCodigo && inputCodigo.desactivado === true){
      refCodigo.current.focus()
    }
  },[inputCodigo.desactivado])

  useEffect(()=>{
    if(refNombre && inputNombre.desactivado === true){
      refNombre.current.focus()
    }
  },[inputNombre.desactivado])

  useEffect(()=>{
    if(refCorreo && inputCorreo.desactivado === true){
      refCorreo.current.focus()
    }
  },[inputCorreo.desactivado])

  return (
    <>
        <MigasDePan>
      <Miga
          texto={miga_de_pan?.principal || 'Estudiantes'}
          alSeleccionar={() => {
            history.push('/estudiantes')
          }} />
        <Miga
          texto={miga_de_pan?.editar || 'Editar estudiante'}
          esRutaActual={true}
        />
      </MigasDePan>
      <div className="contenido">
        <h1 className="titulos-principales mb-5">{titulo}</h1>

        {/* Codigo autogenerado */}
        <DivisionCrud>
          <div>
            <h3 className="raleway-700 onbackground-var1--color">{`${codigo_acceso?.titulo || 'Codigo de acceso'}`}</h3>
            <p className="r4-14-gris6a">{`${codigo_acceso?.descripcion || 'Codigo  auto-generado por Make Make'}`}</p>
          </div>
          <div className="xl:w-451p">
            <input
              defaultValue={!!codigoEstudiante ? codigoEstudiante : codigo.no_hay}
              placeholder={codigo.placeholder}
              className="w-full xl:w-451p input_disabled  hover:cursor-not-allowed "
              tabIndex={-1}
              readOnly
            />
          </div>
        </DivisionCrud>
        {/* Codigo dado por el instituto */}
    <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{codigo.titulo}</h3>
            <p className="r4-14-gris6a">{codigo.descripcion}</p>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputCodigo.desactivado && 'input_disabled'}`}
                  onChange={(e) => {
                    actualizarCampo(e, inputCodigo, setInputCodigo);
                    actualizarCodigoPorInstituto({ codigo: e.target.value });
                  }}
                  //onClick={() => { setInputCodigo({ ...inputCodigo, desactivado: true }) }}
                  disabled={!inputCodigo.desactivado}
                  placeholder={codigo?.titulo || 'Codigo'}
                  ref={refCodigo}
                  type="text"
                  value={inputCodigo?.campo || ''}
                />
              </div>
              <div className="h-9 alineado-verticalmente">
                <button
                  className="focusable-primary evento_rojo ml-5"
                  onClick={() => { setInputCodigo({ ...inputCodigo, desactivado: true }); refCodigo.current.focus() }}
                >
                  {botones.editar}
                </button>
              </div>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p role="alert" aria-live="assertive" className={`text-14 
                  ${!inputNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {nombre.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/* nombre */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{nombre.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{nombre.descripcion}</p>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputNombre.desactivado && 'input_disabled'} ${mostrarAlerta ? !inputNombre.valido && 'input_error' : null}`}
                  onBlur={validarNombre}
                  onChange={(e) => {
                    actualizarCampo(e, inputNombre, setInputNombre);
                    actualizarNombre({ nombre: e.target.value });
                  }}
                  disabled={!inputNombre.desactivado}
                  //onClick={() => { setInputNombre({ ...inputNombre, desactivado: true }) }}
                  onKeyUp={validarNombre}
                  placeholder={nombre.placeholder}
                  ref={refNombre}
                  type="text"
                  value={inputNombre.campo}
                />
              </div>
              <div className="h-9 alineado-verticalmente">
                <button
                  className="focusable-primary evento_rojo ml-5"
                  onClick={() => { setInputNombre({ ...inputNombre, desactivado: true }); }}
                >
                  {botones.editar}
                </button>
              </div>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p role="alert" aria-live="assertive" className={`text-14 
                  ${!inputNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {nombre.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/* correo */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{correo.titulo} <span className="asterisco">*</span> </h3>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputCorreo.desactivado && 'input_disabled'} ${mostrarAlerta ? !inputCorreo.valido && 'input_error' : null}`}
                  onChange={(e) => {
                    actualizarCampo(e, inputCorreo, setInputCorreo);
                    actualizarCorreo({ correo: e.target.value })
                  }}
                  onBlur={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                  disabled={!inputCorreo.desactivado}
                  //onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true }) }}
                  onKeyUp={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                  placeholder={correo.placeholder}
                  ref={refCorreo}
                  type="email"
                  value={inputCorreo.campo}
                />
              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true }); refCorreo.current.focus() }}
              >
                {botones.editar}
              </button>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p role="alert" aria-live="assertive" className={`text-14 
                  ${!inputCorreo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {correo.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/* sede */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{sede.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{sede.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarSede}
              desactivado={inputSede.desactivado}
              estiloTipoInput={true}
              opciones={sedesInstituto}
              superposicion={true}
              textoDefault={sede.placeholder}
              textoElegido={!!sedeSeleccionada.nombre ? sedeSeleccionada.nombre : sede.placeholder /**null */}
            />
          </div>
        </DivisionCrud>

        {/* curso */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{curso.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{curso.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarCurso}
              desactivado={inputCurso.desactivado}
              estiloTipoInput={true}
              estadoEnviar={mostrarAlerta}
              opciones={cursosSede.resultados}
              textoDefault={curso.placeholder}
              textoElegido={!!cursoSeleccionado.nombre ? cursoSeleccionado.nombre : curso.placeholder}
              textoError={curso.error}
              textoNoHayOpciones={cursos.no_hay}
              valido={!cursoSeleccionado.id ? false : true}
            />
          </div>
        </DivisionCrud>

        {/* botones */}
        <div className="alineado-izquierda my-5">
          <button
            className="focusable-primary boton-justo sm:center mr-5 "
            onClick={() => {
              if (modalEliminar) {
                MicroModal.show('estas-seguro-eliminar', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalEliminar(true)
              }
            }}
          >
            <span className="icon-basura red-inv-var2--color text-20 pr-3 hidden sm:block"></span>
            <p className=" evento_rojo">{botones.eliminar.singular}</p>
          </button>
          <button
            disabled={!hayCambios}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
            onClick={actualizarCambios}
          >
            {
              preloader ?
                <div className="center">
                  <span className="icon-spinner7 animate-spin text-20"></span>
                </div>
                :
                botones.actualizar
            }
          </button>
        </div>
      </div>


      {modalEliminar &&
        <>
          {/* Modal pregunta si desea eliminar estudiante*/}
          <Modal nombreModal="estas-seguro-eliminar">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_eliminar.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{modal_eliminar.descripcion_1} <span className="text-resaltado">{datosEstudiante.nombre}</span> {modal_eliminar.descripcion_2}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-eliminar`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="focusable-primary boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5"
              >
                {botones.cancelar}
              </button>
              <button className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" onClick={eliminarEstudiante}>
                {
                  preloader ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    : botones.eliminar.singular
                }
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion estudiante eliminado */}
          <ModalConfirmacion nombreModal="estudiante-eliminado">
            <Link className="icon-cerrar text-20  right-6 absolute focusable-primary" to="/estudiantes"></Link>
            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img "
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_exito.eliminar.titulo}</h1>
            <p className="text-center r7-16-negro26 mb-4">
              {modal_exito.eliminar.descripcion.individual}
            </p>
            <div className="center my-3">
              <Link
                to="/estudiantes"
                className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
              >
                {botones.volver.volver}
              </Link>
            </div>
          </ModalConfirmacion>
        </>
      }
    </>
  )
}
export default EstudiantesEditarEliminarComponent;