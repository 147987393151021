import { useState } from "react";
import PropTypes from 'prop-types';
import Paginacion from "../../globales/Paginacion";
import moment from "moment";

/**
 * Renderizara una tabla con los detalles de las sesiones reaizadas en una fecha determinada.
 */
const TablaDetallePorDia = (props) => {
  const { datos, textosTabla } = props;
  const { tabla } = textosTabla;
  const { hora, rol, nombres_apellidos, colegio, curso, no_hay, resultado } = tabla;

  const [datosDivididos, setDatosDivididos] = useState([]);
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  return (
    <>
      <div role="table" aria-rowcount={datos.length} className={`${datos.length > 10 ? null : 'mb-5'}`}>
        <div role="rowgroup">
          <div role="row" className="encabezadoTabla  xl:grid grid-cols-12 ">
            <p role="columnheader" className="col-span-2 alineado-verticalmente">{hora || 'Hora'}</p>
            <p role="columnheader" className="col-span-3 alineado-verticalmente">{nombres_apellidos || 'Nombres y apellidos'}</p>
            <p role="columnheader" className="col-span-3 alineado-verticalmente">{rol || 'Rol'}</p>
            <p role="columnheader" className="col-span-2 alineado-verticalmente">{colegio || 'Sede'}</p>
            <p role="columnheader" className="col-span-2 alineado-verticalmente">{curso || 'Curso'}</p>
          </div>
        </div>

        <div role="rowgroup">
          {datosDivididos.length === 0 ?
            <div role="row" className="filaTabla xl:grid-cols-12">
              <div role="cell" className=" center col-span-12">
                <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                <p className="raleway-700 red-inv-var2--color text-13">{no_hay}</p>
              </div>
            </div>
            :
            datosDivididos.map((dato, i) => {
              return (
                <div key={`campo-${i}`} role="row" className="filaTabla xl:grid-cols-12">
                  <div role="cell" className="fila-12-sm2-lg-6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{hora || 'Hora'}</span>
                    <p className="raleway-700 primary-inv-var2--color text-13  una-linea-texto">{moment(dato?.fecha || 'N/A').format(`hh:mm A`)}</p>
                  </div>
                  <div role="cell" className="fila-12-sm4-lg6 xl:col-span-3 overflow-auto">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{nombres_apellidos || 'Nombres y apellidos'}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} >{dato?.nombreUsuario || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm2-lg-6 xl:col-span-3">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{rol || 'Rol'}</span>
                    <p className="raleway-700 primary-inv-var2--color text-13  una-linea-texto">{dato?.rol || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm3-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{colegio}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} >{dato?.sede?.nombre || 'N/A'} </p>
                  </div>
                  <div role="cell" className="fila-12-sm3-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{curso}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} >{(Array.isArray(dato?.curso)) ? dato?.curso?.map(c => c?.nombre).join(',') || 'N/A' : dato?.curso?.nombre || 'N/A'} </p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="md:alineado-verticalmente xl:mt-3">
          <div className={`w-full md:w-6/12 `}>
            <p className="r4-16-negro4A">{resultado.hay} {datos.length} {datos.length !== 1 ? resultado.plural : resultado.singular} </p>
          </div>

          <div className={`w-full md:w-6/12 alineado-izquierda mt-5 md:mt-0`}>
            <Paginacion datos={datos} datosDivididos={ActualizarDatosDivididos} />
          </div>
        </div>

      </div>
    </>
  )
}
export default TablaDetallePorDia;

TablaDetallePorDia.propTypes = {
  /**
   * Arreglo de objetos con los datos de las estadisticas
   */
  datos: PropTypes.arrayOf(PropTypes.shape({
    nombreUsuario: PropTypes.string,
    fecha: PropTypes.string,
    librosLeidos: PropTypes.number,
    totalTiempoLectura: PropTypes.string,
    rol: PropTypes.string,
    curso: {
      id: PropTypes.string,
      nombre: PropTypes.string,
    },
    sede: {
      id: PropTypes.string,
      nombre: PropTypes.string,
    }
  })).isRequired,
  /**
  * Objeto con las traducciones de encabezado y boton
  */
  textosTabla: PropTypes.object.isRequired,
}