import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Libro } from "../../../../components/globales/libro/LibroComponent";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Componente que recibe el texto de interfaz y la información de los libros escogidos por el profesor, para renderizar la sección en el Home.
 * @returns EscogidosPorElProfesor
 */
export const RecommendedBooks = ({ escogidosPorElProfesor }) => {
	const [librosAMostrar, setLibrosAMostrar] = useState([]);
	const history = useHistory();
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const noResults = textos?.home?.profesor?.no_hay || 'Nada por aquí aún...';


	useEffect(() => {
		if (Array.isArray(escogidosPorElProfesor)) {
			if (escogidosPorElProfesor.length > 3) {
				setLibrosAMostrar(escogidosPorElProfesor.slice(0, 3));
			} else {
				setLibrosAMostrar(escogidosPorElProfesor);
			}
		} else {
			setLibrosAMostrar([]);
		}
	}, [escogidosPorElProfesor]);

	const mostrarLibro = (idLibro) => {
		history.push({
			pathname: `/visor/${idLibro}`,
			state: { idLibro: idLibro, pathAnterior: window.location.pathname },
		});
	};

	return (
		<>
			{Array.isArray(escogidosPorElProfesor) && escogidosPorElProfesor.length !== 0 ? (
				<>
					<div className=" grid grid-cols-2 sm:grid-cols-3 gap-8 mt-5">
						{librosAMostrar.map((libro, i) => {
							return (
								<div className="my-5" key={`libroEscogido-${i}`}>
									<Libro
										key={`libro-${i}`}
										libro={libro}
										clasesPersonalizadas={{
											contenedor: "min-w-full",
											portada: "min-w-full",
											titulo: "",
										}}
										ocultarOpciones={{
											seleccionado: true,
											botonMultiSeleccion: true,
											botonFavoritos: true,
										}}
										estadoInicial={{
											esFavorito: Boolean(libro?.favorito),
										}}
										alSeleccionar={(libro) => mostrarLibro(libro?.idLibro)}
										esVisible
									/>
								</div>
							);
						})}
					</div>
				</>
			) : (
				<>
					<div className="h-64 mt-5 center">
						<div className="lg:center">
							<img
								aria-hidden={true}
								alt="libro abierto"
								className="primary-inv-var5--bg mx-auto max-w-32"
								src="/images/perfil-estudiante/profe-escogio.png"
							/>
							<p className="q7-20 onsurface-var1--color text-center mt-3 break-words ml-3">
								{noResults}
							</p>
						</div>
					</div>
				</>
			)}
		</>
	);
};

RecommendedBooks.propTypes = {
	/**
	 * array de objeto que almacena los datos de los libros elegidos por el profesor para el estudiante.
	 */
	escogidosPorElProfesor: PropTypes.array.isRequired,
	/**
	 *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
	 */
	textoHome: PropTypes.object.isRequired,
};
