import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';

/**
 * Componente que renderiza el btón de volver arriba en la sección de libros
 */
export const BotonVolverArriba = (props)=>{
   /*  const {alHacerClick} = props; */
    return (
    <button 
    className='focusable-secondary cursor-pointer rounded-full shadow-2 p-1 m-1 primary-inv-var1--bg onprimary-inv-var1--color fixed right-0 bottom-0 transicion-150  transform hover:-translate-y-1 z-50'
    onClick={()=>window.scrollTo({top:0})}>
        <Icon icon="iconoir:fast-arrow-top" width={30} />
    </button>)
}

BotonVolverArriba.propTypes = {
    alHacerClick:PropTypes.func
}



