import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';

/*componentes */
import DivisionCrud from "../globales/DivisionCrud";
import Selector from "../globales/elementosFormularios/Selector";
import SelectorCheck from "../globales/elementosFormularios/SelectorCheck";
import ModalCambiarIcono from "../globales/modales/ModalCambiarIcono";
import VerificarListaEstudiantes from "./VerificarCurso/ListaEstudiantes";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";

const EditarCursoNuevoComponent = (props) => {
  const { actualizarIconoCurso, actualizarNombreCursos, actualizarProfesoresAsignados, actualizarSedeAsignada, datosViejos, editarCurso, eliminarCurso, estudiantes, iconoSeleccionado, isNombreCursoRepetido, nombreCursoActual, nombreCursoNuevo, profesoresAsignados, profesoresInstituto, sedeSeleccionada, sedesInstituto, textosInterfaz, descartarCambios } = props;

  const { miga_de_pan, editar, botones, curso_eliminado, eliminar_curso_temporal, descartar_cambios } = textosInterfaz;
  const { nombre, sede, profesor, icono, titulo } = editar;

  const [nombreCurso, setNombreCurso] = useState({ campo: nombreCursoNuevo, valido: true, desactivado: false });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [textoInputSede, setTextoInputSede] = useState(nombreCursoNuevo);
  const [mostrarAvatar, setMostrarAvatar] = useState(false);
  const [comprobarDatosNuevos, setComprobarDatosNuevos] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(true);
  const [modalDescartar, setModalDescartar] = useState(false);

  const refNombreCurso = useRef(null);

  //Variables de entorno
  const numIconos = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_ICONOS_CURSO);

  useEffect(() => {
    setMostrarAlerta(isNombreCursoRepetido);
    validarNombreCurso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNombreCursoRepetido])

  /**Cuando alguno de los campos es modificado se comprueba que hay un cambio */
  useEffect(() => {
    if (nombreCurso.desactivado || sedeSeleccionada.nombre !== datosViejos.nombreSede || profesoresAsignados.ids.length !== 0 || iconoSeleccionado !== datosViejos.icono) {
      setComprobarDatosNuevos(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nombreCurso.desactivado, textoInputSede, profesoresAsignados, iconoSeleccionado])

  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar])

  /*NOMBRE CURSO */
  /*Valida si el campo de nombre curso es valido */
  const validarNombreCurso = () => {
    const valorCampo = nombreCurso.campo.length > 0 ? isNombreCursoRepetido ? false : true : false;
    setNombreCurso({ ...nombreCurso, valido: valorCampo })
  }

  /*cambia el valor del input de nombre curso */
  const actualizarCampo = (e, estado, cambiarEstado) => {
    actualizarNombreCursos({ nombreCurso: e.target.value });
    cambiarEstado({ ...estado, campo: e.target.value });
  }


  /*SEDE */
  /*Cambia la opcion de sede en el componente Selector */
  const cambiarSede = ({ opcion }) => {
    setTextoInputSede(opcion.nombre);
    actualizarSedeAsignada({ idSede: opcion.id });
  };


  /*PROFESORES */
  const actualizarProfesores = ({ e, opcion }) => {
    actualizarProfesoresAsignados({ e, idSede: opcion.idSede, profesor: opcion });
  }

  /**Crea la notificacion */
  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  /**actualiaz los campos */
  const validarCampos = () => {
    setMostrarAlerta(true);
    if (comprobarDatosNuevos && nombreCurso.valido) {
      editarCurso();
      notificacion(textosInterfaz.notificacion.exito, textosInterfaz.notificacion.actualizacion, "success");
    }
  }

  /**elimina el curso */
  const eliminarCursoNuevo = () => {
    eliminarCurso();
    MicroModal.close('estas-seguro-curso', {
      awaitCloseAnimation: true
    });
    MicroModal.show('curso-eliminado', {
      awaitCloseAnimation: true
    });
  }

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <button
            className="focusable-primary mr-2"
            onClick={() => {
              if (modalDescartar) {
                MicroModal.show('cancelar-crear', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalDescartar(true)
              }
            }}
          >
            {miga_de_pan.principal}
          </button>  &gt;
          <Link to="/cursos/crear-curso" className="focusable-primary mx-2">{miga_de_pan.crear}</Link>  &gt;
          <Link to="/cursos/crear-curso/verificar-cursos" className="focusable-primary mx-2">{miga_de_pan.verificar}</Link>  &gt;
          <span className="ml-2 font-bold">{nombreCursoActual}</span>
        </p>
      </div>


      <div className="contenido">
        <h1 className="titulos-principales mb-5">{titulo} </h1>

        {/* nombre de curso */}
        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{nombre.titulo}</h3>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full  ${!nombreCurso.desactivado && 'input_disabled'} ${mostrarAlerta ? !nombreCurso.valido && 'input_error' : null}`}
                  onBlur={() => { validarNombreCurso() }}
                  onChange={(e) => { actualizarCampo(e, nombreCurso, setNombreCurso) }}
                  onClick={() => { setNombreCurso({ ...nombreCurso, desactivado: true }) }}
                  onKeyUp={validarNombreCurso}
                  value={nombreCurso.campo}
                  placeholder={nombre.placeholder}
                  type="text"
                />
              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setNombreCurso({ ...nombreCurso, desactivado: true }); refNombreCurso.current.focus() }}
              >
                {botones.editar.campo}
              </button>
            </div>
            <div className="w-full xl:w-451p">
              {mostrarAlerta &&
                <p className={`text-14 
                  ${!nombreCurso.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {isNombreCursoRepetido ?
                    nombre.error_nombre_repetido
                    :
                    nombre.error
                  }
                </p>
              }
            </div>
          </>
        </DivisionCrud>


        {/* SEDE */}
        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{sede.titulo}</h3>
            <p className="r4-14-gris6a">{sede.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarSede}
              desactivado={textoInputSede !== sedeSeleccionada.nombre ? true : false}
              estiloTipoInput={true}
              opciones={sedesInstituto}
              textoDefault={sede.select}
              textoElegido={!!sedeSeleccionada.nombre ? sedeSeleccionada.nombre : textoInputSede}
              superposicion={true}
            />
          </div>
        </DivisionCrud>

        {/* PROFESOR */}
        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{profesor.titulo}</h3>
            <p className="r4-14-gris6a">{profesor.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <SelectorCheck
              actualizarElegidos={actualizarProfesores}
              datosAlmacenados={profesoresAsignados.ids}
              desactivado={profesoresAsignados.ids.length !== 0 ? false : true}
              estiloTipoInput={true}
              hayElementos={profesoresInstituto.hayProfesores}
              opciones={profesoresInstituto.profesores}
              textoDefault={`${profesoresAsignados.datos.length} ${profesoresAsignados.datos.length !== 1 ? profesor.profesores_asignados.plural : profesor.profesores_asignados.singular} `}
              textoNoHayDatos={profesor.no_hay_profesores}
            />
          </div>
        </DivisionCrud>


        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{icono.titulo}</h3>
            <p className="r4-14-gris6a">{icono.descripcion} </p>
          </>
          <div className="lg:w-32">
            <div
              className="avatar border-3 border-gris-linea mx-auto img-80-center text-indent cursor-pointer"
              style={{ backgroundImage: `url(/images/badges/badge-${iconoSeleccionado}.png)`}}
              onClick={() => {
                if (mostrarAvatar) {
                  MicroModal.show('cambiar-icono-curso', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarAvatar(true)
                }
              }}
            >
              icono-{iconoSeleccionado}
            </div>
            <button
              className="focusable-primary evento_rojo mx-auto block mt-3"
              onClick={() => {
                if (mostrarAvatar) {
                  MicroModal.show('cambiar-icono-curso', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarAvatar(true)
                }
              }}
            >
              {icono.titulo_popup}
            </button>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{estudiantes.length} {estudiantes.length !== 0 ? editar.estudiantes.titulo.plural : editar.estudiantes.titulo.singular}</h3>
            <p className="r4-14-gris6a">{editar.estudiantes.descripcion} </p>
          </>
          <VerificarListaEstudiantes datosEstudiantes={estudiantes} textosTabla={editar.estudiantes.tabla} />
        </DivisionCrud>

        <div className="sm:alineado-izquierda-center my-5">
          <button
            className="focusable-primary mx-auto sm:boton-justo sm:mr-5 center"
            onClick={() => {
              if (modalEliminar) {
                MicroModal.show('estas-seguro-curso', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalEliminar(true)
              }
            }}
          >
            <span className="icon-basura red-inv-var2--color  text-20 pr-3 "></span>
            <p className=" evento_rojo">{botones.eliminar}</p>
          </button>

          <Link
            className="focusable-primary w-full sm:w-40 boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 sm:mt-0 text-center mr-5 block"
            to='/cursos/crear-curso/verificar-cursos/'
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </Link>
          <button
            disabled={isNombreCursoRepetido ? true : comprobarDatosNuevos ? false : true}
            onClick={() => validarCampos()}
            className="focusable-primary w-full sm:w-40 boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 sm:mt-0 text-center "
          >
            {botones.actualizar}
          </button>
        </div>
      </div>

      {mostrarAvatar &&
        <ModalCambiarIcono
          boton={botones.cambiar}
          cambiarAvatar={actualizarIconoCurso}
          datoIcono={iconoSeleccionado}
          descripcion={icono.descripcion_popup}
          nombreModal="cambiar-icono-curso"
          minIconos={numIconos.minimo}
          maxIconos={numIconos.maximo}
          titulo={icono.titulo_popup}
          rutaCarpeta='badges/badge-'
        />
      }

      {modalEliminar &&
        <>
          {/* Modal pregunta si desea eliminar curso */}
          <Modal nombreModal="estas-seguro-curso">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{eliminar_curso_temporal.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{eliminar_curso_temporal.descripcion} <span className="text-resaltado">{nombreCursoNuevo}</span> {eliminar_curso_temporal.descripcion2} </p>
            </div>
            <div className="flex justify-center mb-4">
              <button
              aria-label="Cancelar"
                onClick={() => {
                  MicroModal.close(`estas-seguro-curso`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="focusable-primary boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5"
              >
                {botones.cancelar}
              </button>
              <button aria-label="Eliminar" className="focusable-primary boton-justo sm:px-10 boton-rojo boton_hover_morado" onClick={() => eliminarCursoNuevo()} >
                {botones.eliminar}
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion curso eliminado */}
          <ModalConfirmacion nombreModal="curso-eliminado">
            <Link className="focusable-primary icon-cerrar text-20 right-6 absolute" to="/cursos/crear-curso/verificar-cursos"></Link>
            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img "
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{curso_eliminado.titulo}</h1>
            <p className="text-center r7-16-negro26 mb-4">
              {curso_eliminado.descripcion1} <span className="text-resaltado">{nombreCursoNuevo}</span> {curso_eliminado.descripcion2}
            </p>
            <div className="center my-3">
              <Link
                to="/cursos/crear-curso/verificar-cursos"
                className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
              >
                {botones.volver}
              </Link>
            </div>
          </ModalConfirmacion>
        </>
      }

      {/* Modal descartar cambios */}

      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
            aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

    </>
  )
}
export default EditarCursoNuevoComponent;