
/**
 * Esta función lee una url y devuelve el valor de un query param si existe.
 * @param {string} parameter  - El nombre del query param
 * @param {*} defaultvalue  - El valor por defecto que se devuelve si no existe el query param
 * @returns {boolean | string}  - Retorna el valor del query param, o retorna falso si no existe el parametro.
 */
export default function getUrlParam(parameter, defaultvalue) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === parameter) {
          return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
  }
  return false;
};
