import { useEffect, useState } from "react";
import { normalizeString } from "../../../utilities/StringUtilities";

export const useSearchClasses = ({classes, buildingId, query})=>{
    const [matches, setMatches] = useState([]);




    const search = ()=>{
        if(Array.isArray(classes)){
          const filtered = classes.filter(_class=>{
            if(buildingId){
                return normalizeString(_class?.nombre)?.includes(normalizeString(query)) && buildingId === _class?.idSede
            }else{
                return normalizeString(_class?.nombre)?.includes(normalizeString(query))
            }
          })
          setMatches(filtered);
        }
    }

    useEffect(()=>{
        search();
    },[classes, buildingId, query])

    return matches;
}