//https://medium.com/swlh/demystifying-the-folder-structure-of-a-react-app-c60b29d90836
import { useReducer } from 'react';
import { createContainer } from 'react-tracked';
import reducer from './actions';


const initialState = {
  idioma: 'es',
  isLoading: false,
  isPending: true,
  planActivo: {},
};


const useValue = () => useReducer(reducer, initialState);
export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
