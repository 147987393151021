//Hooks y funciones personalizadas
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';

//componentes
import EstadisticasComponent from '../../components/estadisticas';

const GeneralEstadisticas = () => {

  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
    <>
      {
        isReady &&
          <EstadisticasComponent traducciones={textosInterfaz} /> 
      }
    </>
  )
}
export default GeneralEstadisticas;