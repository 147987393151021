import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const HomeSkeleton = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={188} height={40} />
      <div className="contenido">

        <h1 className="titulos-principales  ">
          <div className="md:w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <div className="md:flex justify-between items-center flex-wrap  py-3">
          <div className="w-7/12 md:w-5/12 md:order-1">
            <Skeleton width={'100%'} height={36} />
          </div>

          <div className="w-full md:flex justify-between flex-wrap mt-3 gap-4 md:order-3">
            <div className="md:w-5/12 xl:w-3/12">
              <Skeleton width={'100%'} height={36} />
            </div>

            <div className="md:w-6/12 xl:w-451p mt-6 md:mt-0">
              <div className="relative w-full h-full">
                <Skeleton width={'100%'} height={36} />
              </div>
            </div>

            <div className="w-full alineado-izquierda my-6 md:mt-0 ">
              <button className="boton-pequenio">
                <Skeleton width={'100%'} height={36} />
              </button>
            </div>
          </div>

          <div className=" md:w-5/12 my-6 lg:my-0 md:order-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-6 xl:gap-11">
          <Skeleton className="h-72" width={'100%'} />
          <Skeleton className="h-72" width={'100%'} />

        </div>

      </div>
    </SkeletonTheme>
  )
}
