import React, { useEffect, useState } from "react";
import { LibrosAPI } from "../../../../api/LibrosAPI";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { adaptarPortadaLibros } from "../../../../utilities/adaptadores";
import { BooksPage } from "../components/books-page";
import { CancelRequestController } from "../../../../utilities/cancel-request-controller";

/**
 * Este componente renderiza la sección especifica de libros recomendados o escogidos por el profesor. Este retorna el componente LibrosSeccionEstudianteComponent al cual se le pasan los libros, nombre y descripción correspondiente.
 * @returns {JSX.Element} LibrosSeccionEstudianteComponent
 */
export const RecommendedBooks = () => {
	const [loading, setLoading] = useState(true);
	const [books, setBooks] = useState([]);
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");

	useEffect(() => {
		let isUnMounted = false;
		let abortController = new CancelRequestController();

		const loadBooks = async () => {
			if (isUnMounted) {
				return;
			}
			setLoading(true);
			await LibrosAPI.obtenerLibrosEstudiante({ abortSignal: abortController.signal })
				.then((res) => {
					let favoritos = Array.isArray(res?.favoritos)?res?.favoritos:[];
					if (Array.isArray(res?.escogidosPorElProfesor)) {
						const idsFavoritos = favoritos.map((fav) => fav?.idLibro);
						const libros = res?.escogidosPorElProfesor.map((libro) => {
							return {
								...libro,
								favorito: idsFavoritos.includes(libro?.idLibro),
							};
						});
						setBooks(adaptarPortadaLibros(libros));
					}
				})
				.catch((err) => console.log(err));
			setLoading(false);
		};
		loadBooks();

		return () => {
			isUnMounted = true;
			abortController.abort();
		};
	}, []);

	return (
		<>
			<BooksPage
				loading={loading}
				title={
					textos?.libros?.seccion_libros?.escogidos_profesor?.titulo ||
					"Libros escogidos por tu profe"
				}
				description={
					textos?.libros?.seccion_libros?.escogidos_profesor?.descripcion || "descripcion"
				}
				books={books}
				breadcrumb={{
					source: {
						path: "/estudiante/libros",
						name: textos?.miga_de_pan?.libros || "Libros",
					},
				}}
				noResults={{
					title: textos?.libros?.seccion_libros?.sin_resultados?.titulo || "Sin libros",
					description:
						textos?.libros?.seccion_libros?.sin_resultados?.descripcion ||
						"No hay nada por aquí aún.",
				}}
			/>
		</>
	);
};
