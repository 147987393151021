import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonTotalSesiones = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>

      <Skeleton className="ruta" width={188} height={40} />
      <div className="contenido">

        <h1 className="titulos-principales  ">
          <div className="md:w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <div style={{ width: 500 }} className="py-5"  >
          <Skeleton width={'100%'} height={30} />
        </div>

        <hr />

        <div className="alineado-verticalmente my-5">
          <div className="w-6/12 lg:w-3/12">
            <div className="alineado-verticalmente">
              <div className="w-10 h-10">
                <Skeleton width={'100%'} height={'100%'} />
              </div>
              <div className="w-full ml-3">
                <Skeleton width={'100%'} height={50} />
              </div>
            </div>
            <Skeleton width={'100%'} height={24} />
          </div>

          <div className="w-6/12 lg:w-3/12 ml-5">
            <div className="alineado-verticalmente">
              <div className="w-10 h-10">
                <Skeleton width={'100%'} height={'100%'} />
              </div>
              <div className="w-full ml-3">
                <Skeleton width={'100%'} height={50} />
              </div>
            </div>
            <Skeleton width={'100%'} height={24} />
          </div>
        </div>

        <hr />

        <div className="md:flex mt-5 mb-10">
          <div className="md:w-6/12">
            <div className="w-4/12">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="w-8/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="md:w-6/12 alineado-izquierda-center">
            <div className="boton-pequenio">
              <Skeleton width={'100%'} height={36} />
            </div>
          </div>
        </div>

        <hr className="hidden xl:block" />

        <div className="encabezadoTabla xl:grid grid-cols-12 mt-5">
          <div className="col-span-4">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
        </div>

        <div className="filaTabla xl:grid-cols-12">

          <div className="fila-10-sm5-lg6 xl:col-span-4">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-8/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
        </div>

      </div>
    </SkeletonTheme>
  )
}
export default SkeletonTotalSesiones;