import {useHistory} from 'react-router-dom';
import { Miga, MigasDePan } from '../globales/MigaDePan';


export const  VerificarSeleccionComponent = (props)=>{
    const history = useHistory();

    return (
        <>
         <MigasDePan>
      <Miga texto={'Libros'}/>
      <Miga texto={'Escogiendo libros'} alSeleccionar={()=>history.push({pathname:'/libros/seleccion-libros'})} />
      <Miga texto={'Verificar'} esRutaActual={true}/>
    </MigasDePan>
        <div>
            Verificar seleccion
        </div>
        </>
    )
}