import { useState } from "react";
import { Link } from "react-router-dom";

/**Componentes */
import Paginacion from "../../globales/Paginacion";

const ListaEstudiantesNuevos = (props) => {
  const { datosEstudiantes, eliminarEstudianteIndividual, textosTabla, botones, cambiarEstudiantesElegidos } = props;

  const { codigo, correo, curso, nombre, sede } = textosTabla;

  const [datosDivididos, setDatosDivididos] = useState([]);

  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  const onKeyEnter = (e, dato) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
      e.target.checked = !e.target.checked;
      cambiarEstudiantesElegidos({ e, estudiante: dato });
    }
  }

  return (
    <div role="table" aria-rowcount={datosDivididos.length} aria-label="Lista de estudiantes">
      <div className='my-5' role="rowgroup">
        <div role="row" className="encabezadoTabla  xl:grid grid-cols-12">
          <p role="columnheader" className="col-span-1"></p>
          <p role="columnheader" className="col-span-1">{codigo.titulo} </p>
          <p role="columnheader" className="col-span-2">{nombre.titulo}</p>
          <p role="columnheader" className="col-span-3">{correo.titulo}</p>
          <p role="columnheader" className="col-span-2">{sede.titulo}</p>
          <p role="columnheader" className="col-span-2">{curso.titulo}</p>
          <p role="columnheader" className="col-span-1"></p>
        </div>

        <div role="rowgroup">
          {datosDivididos.map((dato) => {
            return (
              <div role="row" key={`campo${dato.key}`} className="filaTabla xl:grid-cols-12">

                <label role="cell" className="fila-2-sm1-lg12-xl1 row-span-5  sm:row-span-3 lg:row-span-1  h-7 relative mr-3 cursor-pointer focusable-by-children-primary">
                  <input 
                    checked={dato.checked} 
                    type="checkbox"
                    tabIndex={0} 
                    onChange={(e) => { cambiarEstudiantesElegidos({ e, estudiante: dato }); }} 
                    onKeyUp={(e) => { onKeyEnter(e, dato) }}
                  />
                  <i aria-hidden={true} className="check" ></i>
                </label>
                <div role="cell" className="fila-10-sm5-lg6 xl:col-span-1">
                  <span className="q7-14-negro26  xl:hidden">{codigo.titulo}</span>
                  <p className="r5-13-negro26 una-linea-texto">{dato.codigo}</p>
                </div>
                <div role="cell" className="fila-10-sm5-lg6 xl:col-span-2">
                  <span className="q7-14-negro26  xl:hidden">{nombre.titulo}</span>
                  <p className="r5-13-negro26 una-linea-texto">{dato.nombre}</p>
                </div>
                <div role="cell" className="fila-10-sm5-lg6 xl:col-span-3 overflow-auto">
                  <span className="q7-14-negro26  xl:hidden">{correo.titulo}</span>
                  <p className={`${!!dato.correo ? 'r5-13-negro26' : 'r7-13-rojo'} una-linea-texto`} >{!dato.correo ? correo.sin_corrreo : dato.correo}</p>
                </div>
                <div role="cell" className="fila-10-sm5-lg6 xl:col-span-2">
                  <span className="q7-14-negro26  xl:hidden">{sede.titulo}</span>
                  <p className='r5-13-negro26 una-linea-texto' >{dato.nombreSede}</p>
                </div>
                <div role="cell" className="fila-10-sm5-lg6 xl:col-span-2">
                  <span className="q7-14-negro26  xl:hidden">{curso.titulo}</span>
                  <p className={`r5-13-negro26 una-linea-texto`} >{dato.nombreCurso}</p>
                </div>
                <div role="cell" className="col-span-12 xl:col-span-1">
                  <div className="md:alineado-izquierda-center">
                    <Link aria-label={botones.editar} to={`/estudiantes/crear-estudiantes/verificar-estudiantes/${dato.key}`} className="boton-justo boton-amarillo boton_hover_morado center w-full md:w-auto md:px-10 xl:p-2 mr-5 mb-3 md:mb-0 focusable-primary">
                      <p className="xl:hidden">{botones.editar} </p><span className="pl-3 xl:pl-0 icon-lapiz"></span>
                    </Link>
                    <button
                      aria-label={botones.eliminar.singular}
                      className="boton-justo boton-rojo boton_hover_morado center w-full md:w-auto md:px-10 xl:p-2 mb-3 md:mb-0 focusable-primary"
                      onClick={() => {
                        eliminarEstudianteIndividual({ key: [dato.key] })
                      }}
                    >
                      <p className="xl:hidden">{botones.eliminar.singular} </p><span className="pl-3 xl:pl-0 icon-basura"></span>
                    </button>
                  </div>
                </div>

              </div>
            )
          })
          }
        </div>

        <div className={`alineado-izquierda xl:mt-3`}>
          <Paginacion datos={datosEstudiantes} datosDivididos={ActualizarDatosDivididos} />
        </div>

      </div>

    </div>
  )
}
export default ListaEstudiantesNuevos;