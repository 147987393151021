
import React from "react";

export const StatsTab = ({ data, current, onClick, disabled }) => {
	return (
		<button
			aria-current={current === "esta-semana" ? "page" : ""}
			disabled={disabled}
			className={`focusable-red boton-justo m-2 ${
				current ? "boton-morado" : "boton-morado_desactivado"
			}`}
			onClick={() => onClick && onClick(data)}
		>
			{data?.text?.translation || data?.text?.default || "N/A"}
		</button>
	);
};