import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PropTypes from "prop-types";
import { useTrackedState } from "../../../store";

/**
 * Componente que muestra la información principal de un libro en la ficha.
 */
const InformacionBasica = (props) => {
	let {
		title,
		author,
		publisher,
		nivelUsuarioLector,
		subject,
		genero,
		description,
		descriptionENG,
		contributor,
		actividad,
		textosInterfaz,
		idLibro
	} = props;
	const { ficha, filtros } = textosInterfaz;
	const { info_basica } = ficha;

	const [nivel, setNivel] = useState({ valor: 1, translate: "" });
	const [materia, setMateria] = useState({ valor: "ciencias", translate: "" });
	const [botonGenero, setBotonGenero] = useState({ valor: "icon-comic", translate: "" });
	const state = useTrackedState();
	const lang = state?.idioma;
	const history = useHistory();


	useEffect(() => {
		if (!!nivelUsuarioLector) {
			let valorNivel;
			let valorTranslate;
			switch (nivelUsuarioLector) {
				case "Primeros lectores":
					valorNivel = 1;
					valorTranslate = "primeros";
					break;
				case "Lectores intermedios":
					valorNivel = 2;
					valorTranslate = "intermedios";
					break;
				case "Lectores avanzados":
					valorNivel = 3;
					valorTranslate = "avanzados";
					break;
				default:
					valorNivel = 1;
					valorTranslate = "";
					break;
			}
			setNivel({ ...nivel, valor: valorNivel, translate: valorTranslate });
		}
	}, [nivelUsuarioLector]);

	useEffect(() => {
		if (!!subject) {
			let valorMateria;
			let valorTranslate;
			switch (subject) {
				case "Ciencias":
					valorMateria = "ciencias";
					valorTranslate = "ciencias";
					break;
				case "Historia y ensayo":
					valorMateria = "historia";
					valorTranslate = "historia_ensayo";
					break;
				case "Letras y números":
					valorMateria = "matematicas";
					valorTranslate = "letras_numeros";
					break;
				case "Artes y manualidades":
					valorMateria = "artes";
					valorTranslate = "artes_manualidades";
					break;
				case "Canción y poesía":
					valorMateria = "no-ficcion";
					valorTranslate = "cancion_poesia";
					break;
				case "Narrativa":
					valorMateria = "literatura";
					valorTranslate = "narrativa";
					break;
				default:
					valorMateria = "";
					valorTranslate = "";
					break;
			}
			setMateria({ ...materia, valor: valorMateria, translate: valorTranslate });
		}
	}, [subject]);

	useEffect(() => {
		if (!!genero) {
			let valorGenero;
			let valorTranslate;
			switch (genero) {
				case "Cómic":
					valorGenero = "icon-comic";
					valorTranslate = "comic";
					break;
				case "Libro álbum":
					valorGenero = "icon-libro-album";
					valorTranslate = "libro_album";
					break;
				case "Cuento ilustrado":
					valorGenero = "icon-cuento-ilustrado";
					valorTranslate = "cuento_ilustrado";
					break;
				case "Poesía":
					valorGenero = "icon-poesia";
					valorTranslate = "poesia";
					break;
				case "Crónica y ensayo":
					valorGenero = "icon-cronica-y-ensayo";
					valorTranslate = "cronica";
					break;
				case "Interactivo":
					valorGenero = "icon-interactivo";
					valorTranslate = "interactivo";
					break;
				case "Didáctico":
					valorGenero = "icon-didactivo";
					valorTranslate = "didactico";
					break;
				case "Teatro":
					valorGenero = "icon-teatro";
					valorTranslate = "teatro";
					break;
				case "Cuento":
					valorGenero = "icon-cuento";
					valorTranslate = "cuento";
					break;
				case "Novela":
					valorGenero = "icon-novela";
					valorTranslate = "novela";
					break;
				case "Informativo":
					valorGenero = "icon-informativo";
					valorTranslate = "informativo";
					break;
				default:
					valorGenero = "icon-comic";
					valorTranslate = "";
					break;
			}
			setBotonGenero({ ...botonGenero, valor: valorGenero, translate: valorTranslate });
		}
	}, [genero]);


	const mostrarLibro = (idLibro) => {
		history.push({ pathname: `/visor/${idLibro}`, state: { idLibro: idLibro, pathAnterior: window.location.pathname } });
	}

	return (
		<>
			<div className="flex flex-col-reverse sm:flex-row sm:flex-nowrap">
				<h1 className={`q7-32-negro4d w-full pt-10 pl-10 pr-2`}>{title || "N/A"}</h1>
				{actividad && (
					<button
						className="self-end sm:self-start w-72 py-5 px-2 rounded-bl-lg  onsurface-var2--border-color  center"
						style={{ height: "fit-content", borderLeft: "1px solid", borderBottom: "1px solid" }}
						onClick={() => { mostrarLibro(idLibro) }}
					>
						<div className="center r7-morado">
							<span className="icon icon-libreta text-40"></span>
							<p className="r7-morado text-center ml-3">
								{info_basica.incluye_actividad}
							</p>
						</div>
					</button>
				)}
			</div>

			<div className="pl-10 pr-10 pb-10">
				<div className="my-5">
					<h2
						className={`q7-18-negro4d border-b-3 leading-6 secondary-inv-var1--borderb-color inline-block`}
					>
						{author}
					</h2>
					<p className="r5-14-negro4D">{info_basica.autor}</p>
				</div>

				{!!contributor && (
					<div className="my-5">
						<h2
							className={`q7-18-negro4d border-b-3 leading-6 secondary-inv-var1--borderb-color inline-block`}
						>
							{contributor}
						</h2>
						<p className="r5-14-negro4D">{info_basica.colaboradores} </p>
					</div>
				)}

				<div className="my-5">
					<h2 className="q7-18-negro4d border-b-3 leading-6 secondary-inv-var1--borderb-color inline-block ">
						{publisher}
					</h2>
					<p className="r5-14-negro4D">{info_basica.editorial}</p>
				</div>

				<div className="grid grid-cols-12 gap-4">
					<div
						style={{ backgroundImage: `url(/images/niveles/nivel${nivel.valor}.png)` }}
						className={`nivel-materia-genero boton-ficha nivel-materia`}
					>
						<p className="w-8/12 text-center leading-5">
							{" "}
							{filtros.nivel[nivel.translate]}
						</p>
					</div>

					<div
						className={`nivel-materia-genero  boton-ficha nivel-materia`}
						style={{ backgroundImage: `url(/images/materias/${materia.valor}.png)` }}
					>
						<div className="w-8/12 text-center leading-5">
							<p>{info_basica.materia}</p>
							<p className="r7-morado">{filtros.materia[materia.translate]}</p>
						</div>
					</div>

					<div
						className={`primary-inv-var2--bg  onprimary-inv-var1--color nivel-materia-genero rounded-xl p-3 raleway-700 center`}
					>
						<span className={`${botonGenero.valor} text-30 mr-3 `}></span>
						<p className="text-center">{filtros.generos[botonGenero.translate]}</p>
					</div>
				</div>

				<p
					className="raleway-500 onsurface-var1--color mt-5"
					dangerouslySetInnerHTML={{ __html: lang === 'en'? descriptionENG || description: description  }}
				></p>
			</div>
		</>
	);
};
export default InformacionBasica;

InformacionBasica.propTypes = {
	/**
	 * Titulo del libro
	 */
	title: PropTypes.string,
	/**
	 * Descripción del libro
	 */
	description: PropTypes.string,
	/**
	 * Autor del libro
	 */
	author: PropTypes.string,
	/**
	 * Editorial del libro
	 */
	publisher: PropTypes.string,
	/**
	 * Nivel de lectura del libro
	 */
	nivelUsuarioLector: PropTypes.string,
	/**
	 * Tema del libro
	 */
	subject: PropTypes.string,
	/**
	 * Genero del libro
	 */
	genero: PropTypes.string,
	/**
	 * contribuyentes del libro
	 */
	contributor: PropTypes.string,
	/**
	 * Propiedad booleana que indica si el libro incluye actividad o no
	 */
	actividad: PropTypes.bool,
	/**
	 * Objeto con los textos de traducción de la interfaz.
	 */
	textosInterfaz: PropTypes.object,
};
