import { useEffect, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
//Store
import { useTrackedState } from '../../../../store';
//Hooks personalizados
import useObtenerSubseccionesIdiomas from '../useObtenerSubseccionesIdiomas';
import useObtenerSubsecciones from '../useObtenerSubsecciones';
//Funciones
import advertenciasCamposIncompletos from './advertenciasCamposIncompletos';
import comprobacionCamposExcel from './comprobacionDatosExcel';
import nuevoHeader from './nuevoHeader';
import obtenerHeaderTabla from './obtenerHeaderTabla';
import randomNumber from '../../../../utilities/function/randomNumber';
import validacionCamposLlenos from './validarCamposLlenos';
//Componentes
import ReportePDF from '../../../../components/globales/reportes/ReportePDF';
import removeAccents from '../../../../utilities/function/removeAccents';
import normalizeString from './../../../../utilities/function/normalizeString';
import PropTypes from 'prop-types';
/**
 * Hook utilizado para obtener los datos que están dentro de un archivo excel, el cual es cargado y pasado a este componente
 * @returns 
 */
const useObtenerDatosExcel = (props) => {
  const { archivo, crearMasivamente, nombreHoja, nombreSeccionACrear, seccionACrear } = props;
  //Estados globales
  const state = useTrackedState();
  const { temporal } = state;

  //Hooks personalizados
  const { isReady: isReadyTextosHoja, textosSubSecciones: textosHoja } = useObtenerSubseccionesIdiomas(nombreHoja);
  const { isReady: isReadyTextosTabla, textosSubSecciones: textosTabla } = useObtenerSubsecciones(nombreHoja);

  const datosTemporal = !!temporal
    ? !!temporal[seccionACrear] ? temporal[seccionACrear] : null
    : null;
  

  //Estados del hook
  const [advertenciasExcel, setAdvertenciasExcel] = useState({
    datos: {},
    hayAdvertencias: null,
  });
  const [datosExcel, setDatosExcel] = useState(datosTemporal);
  const [errorSubirExcel, setErrorSubirExcel] = useState({ hayError: null });
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  //Archivo de reporte 
  const [instance, updateInstance] = usePDF({ document: <ReportePDF advertencias={advertenciasExcel} /> });

  const rangoAvatares = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_AVATARES);
  const rangoIconosCurso = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_ICONOS_CURSO);

  useEffect(() => {
    if (!!archivo) {
      const archivoSubido = !!archivo.archivo
        ? archivo.archivo
        : archivo.e.target.files[0];
      obtenerDatosExcel({ archivo: archivoSubido, nombre: archivo.nombre });
    } else {
      setErrorSubirExcel({
        hayError: null,
        tipoError: ''
      })
      setAdvertenciasExcel({
        datos: {},
        hayAdvertencias: null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivo, crearMasivamente]);


  useEffect(() => {
    setAdvertenciasExcel({ ...advertenciasExcel, href: instance.url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);


  useEffect(() => {
    if (advertenciasExcel.hayAdvertencias) {
      updateInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertenciasExcel.hayAdvertencias]);


  const obtenerDatosExcel = ({ archivo, e, nombre }) => {
    setIsLoadingExcel(true);
    setErrorSubirExcel({
      hayError: false
    });

    setAdvertenciasExcel({
      hayAdvertencias: null,
      datos: {},
    });

    setDatosExcel(null);

    const file = archivo;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary' });
      //Nombre de la hoja de la primera hoja
      const worksheetName = workbook.SheetNames;

      //Archivo correcto
      let archivoCorrecto = false;

      //Cursos y profesores de la sede
      let cursos = {};
      let estudiantes = [];
      let profesores = [];
      let datosExcel = {}

      //Advertencias
      let advertencias = {};

      //Variable para comprobar si existe la hoja de profesor
      let existeProfesor = {
        nombreHoja: '',
        existe: false
      };

      //Comprueba si en las hojas que tiene el excel alguna de coincide con el nombre de profesores, ya sea en inglés o en español
      const idiomasDisponibles = process.env.REACT_APP_IDIOMAS_DISPONIBLES.split(',');
      idiomasDisponibles.forEach(idioma => {
        worksheetName.forEach(sheet => {
          const nombreHoja = sheet.toLowerCase();
          if (!!textosHoja[idioma]) {
            const nombreHojaBuscar = textosHoja[idioma].hoja.profesor.campo.toLowerCase();
            const existeHoja = nombreHoja.search(nombreHojaBuscar);

            if (existeHoja !== -1) return existeProfesor = { nombreHoja: sheet, existe: true };
          };
        });
      });


      if (seccionACrear === 'sede') {
        //Si existe la hoja crea la información de profesores y los asigna a los cursos correspondientes.
        if (existeProfesor.existe) {
          const nameSheet = existeProfesor.nombreHoja;
          const dataSheet = workbook.Sheets[nameSheet];
          const infoProfesor = crearProfesores({ advertencias, archivoCorrecto, cursos, dataSheet, profesores });

          //Crea los cursos con base a los que tenga asiganados los profesores
          const profesoresCursos = asignarInformacionCurso({ cursos, profesores: infoProfesor.profesores });
          cursos = { ...cursos, ...profesoresCursos.cursos };
          archivoCorrecto = infoProfesor.archivoCorrecto;

        } else {
          advertencias.profesor = {
            ...advertencias.profesor, hoja: {
              mensaje: textosTabla.advertencias.profesor.sin_hoja.descripcion,
              titulo: textosTabla.advertencias.profesor.sin_hoja.titulo
            }
          };
        };

        //Crear cursos y estudiantes
        const infoEstudiantes = crearEstudiantes({ advertencias, archivoCorrecto, existeProfesor, workbook, worksheetName });
        const estudiantesCurso = asignarInformacionCurso({ cursos, estudiantes: infoEstudiantes.estudiantes });
        cursos = { ...cursos, ...estudiantesCurso.cursos };
        archivoCorrecto = infoEstudiantes.archivoCorrecto;

        validacionCursosSinEstudiantes({ advertencias, cursos });

        datosExcel = {
          cursos: cursos,
          nombreArchivo: nombre,
          profesores: profesores,
        };

      } else if (seccionACrear === 'cursos') {
        //Crear cursos y estudiantes
        const infoEstudiantes = crearEstudiantes({ advertencias, archivoCorrecto, existeProfesor, workbook, worksheetName });
        const estudiantesCurso = asignarInformacionCurso({ cursos, estudiantes: infoEstudiantes.estudiantes });

        cursos = { ...cursos, ...estudiantesCurso.cursos };
        archivoCorrecto = infoEstudiantes.archivoCorrecto;

        validacionCursosSinEstudiantes({ advertencias, cursos });

        datosExcel = {
          datos: cursos,
          nombreArchivo: nombre,
          estudiantes: estudiantes
        };
      } else if (seccionACrear === 'profesores') {
        if (existeProfesor.existe) {
          const nameSheet = existeProfesor.nombreHoja;
          const dataSheet = workbook.Sheets[nameSheet];
          const infoProfesor = crearProfesores({ advertencias, archivoCorrecto, cursos, dataSheet, profesores });

          const infoProfesores = [...profesores]
          const datosProfesores = {}
          infoProfesores.map((profesor, index) => {
            const key = `${normalizeString(profesor.nombre)}-${index}`
            // delete profesor.cursos;
            datosProfesores[key] = { ...profesor, key }
            return profesor
          });

          profesores = datosProfesores;
          archivoCorrecto = infoProfesor.archivoCorrecto;
        }

        if (Object.keys(profesores).length !== 0) {
          datosExcel = {
            nombreArchivo: nombre,
            datos: profesores,
          };
        } else {
          setErrorSubirExcel({
            hayError: true,
            tipoError: 'No hay profesores'
          });
        }
      } else if (seccionACrear === 'estudiantes') {
        const infoEstudiantes = crearEstudiantes({ advertencias, archivoCorrecto, existeProfesor, workbook, worksheetName });

        const buscarHoja = worksheetName.find(hoja => removeAccents(hoja) === removeAccents(nombreSeccionACrear));

        const hoja = !!buscarHoja
          ? buscarHoja
          : worksheetName.find(hoja => removeAccents(hoja) !== removeAccents(existeProfesor.nombreHoja));

        const nombreHoja = !!nombreSeccionACrear ? nombreSeccionACrear : 'estudiantes';

        const datos = !!infoEstudiantes.estudiantes[nombreHoja]
          ? infoEstudiantes.estudiantes[nombreHoja]
          : infoEstudiantes.estudiantes[hoja]

        archivoCorrecto = infoEstudiantes.archivoCorrecto;

        if (!!datos) {
          if (datos.length !== 0) {
            const estudiantesObject = {};
            datos.forEach((estudiante) => {
              const key = normalizeString(estudiante.nombre);
              estudiantesObject[`${key}`] = estudiante;
            })


            datosExcel = {
              datos: estudiantesObject,
              nombreArchivo: nombre,
            };
          } else {
            setErrorSubirExcel({
              hayError: true,
              tipoError: 'No hay estudiantes'
            });
          }
        }
      }


      const hayAdvertencias = Object.keys(advertencias).length !== 0 ? true : false;

      if (hayAdvertencias) {
        for (const key in advertencias) {
          advertencias[key] = { ...advertencias[key], titulo: textosTabla.advertencias[key].titulo };
          if (advertencias[key].incompletos) {
            advertencias[key].incompletos = {
              ...advertencias[key].incompletos,
              mensaje: textosTabla.advertencias[key].sin_datos.descripcion,
              titulo: textosTabla.advertencias[key].sin_datos.titulo,
              header: textosTabla.advertencias.header
            };
          };
        };
        advertencias.titulo = textosTabla.advertencias.titulo_archivo_crear[seccionACrear];
      };

      setAdvertenciasExcel({
        datos: hayAdvertencias ? { ...advertencias } : {},
        href: hayAdvertencias ? instance.url : '',
        hayAdvertencias: hayAdvertencias,
        nombreArchivo: hayAdvertencias ? `${textosTabla.archivo.nombre_archivo}` : null
      });


      if (!archivoCorrecto) {
        setErrorSubirExcel({
          hayError: true,
          tipoError: 'Archivo incorrecto'
        });
      };

      setDatosExcel(datosExcel);
      setIsLoadingExcel(false);
    };
    reader.readAsBinaryString(file);
  };


  //Crear profesores
  const crearProfesores = ({ advertencias, archivoCorrecto, dataSheet, profesores }) => {
    const headerProfesor = XLSX.utils.sheet_to_json(dataSheet, { header: 1 })[0];
    const headerCorrecto = obtenerHeaderTabla({ seccion: 'profesor', textosTabla: textosHoja }).es;
    const camposObligatorios = comprobacionCamposExcel({ headerTabla: headerProfesor, headerEsperado: headerCorrecto });

    //La hoja de profesores no cuenta con las columnas correspondientes
    if (!camposObligatorios.comprobacion) {
      advertencias = advertenciasCamposIncompletos({ camposObligatorios, advertencias, seccion: 'profesor', textosTabla });
    } else {
      const data = XLSX.utils.sheet_to_json(dataSheet);
      archivoCorrecto = true;

      data.forEach((profesor, index) => {
        const comprobacionProfesor = validacionCamposLlenos({ camposObligatorios: camposObligatorios.campos, fila: profesor, index });
        const header = nuevoHeader({ headerTabla: headerProfesor, headerCorrecto });

        //Advertencias cuando los datos se encuentran incompletos      
        if (comprobacionProfesor.hayError) {
          advertencias.profesor = { ...advertencias.profesor };
          advertencias.profesor.incompletos = { ...advertencias.profesor.incompletos };
          advertencias.profesor.incompletos.data = { ...advertencias.profesor.incompletos.data, ...comprobacionProfesor.campos };
        };

        if (!comprobacionProfesor.hayError) {
          //Cursos profesor
          let cursosProfesor = !!profesor[header.curso] && profesor[header.curso].split(',');
          cursosProfesor = cursosProfesor !== false ? cursosProfesor.map((curso, index) => cursosProfesor[index] = curso.trim()) : [];
          const nombreProfesor = `${profesor[header.nombres]?.trim()} ${profesor[header.apellidos]?.trim()}`;

          if (cursosProfesor.length === 0) {
            advertencias.profesor = { ...advertencias.profesor };
            advertencias.profesor.sin_curso = {
              ...advertencias.profesor.sin_curso,
              mensaje: textosTabla.advertencias.profesor.sin_curso.descripcion,
              titulo: textosTabla.advertencias.profesor.sin_curso.titulo,
            };
            advertencias.profesor.sin_curso.data = !!advertencias.profesor.sin_curso.data ? [...advertencias.profesor.sin_curso.data, nombreProfesor] : [nombreProfesor];
          };


          //Datos del profesor
          let profesorSede = {
            avatar: randomNumber({ minimo: rangoAvatares.minimo, maximo: rangoAvatares.maximo }),
            correo: !!profesor[header.correo] ? profesor[header.correo] : null,
            cursos:cursosProfesor,
            cursosNombres:cursosProfesor,
            nombre: nombreProfesor,
          };

          profesores.push(profesorSede);
        };
      });
    };
    return { advertencias, archivoCorrecto, profesores };
  };

  //Crear estudiantes
  const crearEstudiantes = ({ advertencias, archivoCorrecto, existeProfesor, workbook, worksheetName }) => {
    let estudiantes = {};
    let datosEstudiantesCurso = { advertencias: {}, archivoCorrecto: false, estudiantes: {} };

    if (crearMasivamente === false) {
      const buscarHoja = worksheetName.find(hoja => removeAccents(hoja) === removeAccents(nombreSeccionACrear));
      const hoja = !!buscarHoja
        ? buscarHoja
        : worksheetName.find(hoja => removeAccents(hoja) !== removeAccents(existeProfesor.nombreHoja));

      const nombreHoja = !!nombreSeccionACrear ? nombreSeccionACrear : 'curso';

      const dataSheet = workbook.Sheets[hoja];
      const camposEstudiantes = camposObligatoriosEstudiantes({ dataSheet });
      const { camposObligatorios, header } = camposEstudiantes;

      if (camposObligatorios.comprobacion) {
        datosEstudiantesCurso = datosEstudiantes({ advertencias, archivoCorrecto, camposObligatorios, dataSheet, estudiantes, header, nombreHoja: nombreHoja });

        if (datosEstudiantesCurso?.estudiantes[nombreHoja]?.length === 0) {
          setErrorSubirExcel({
            hayError: true,
            tipoError: 'No hay estudiantes'
          });
        };
      } else {
        worksheetName.every(curso => {
          if (curso === existeProfesor.nombreHoja) return curso

          const dataSheet = workbook.Sheets[curso];
          const camposEstudiantes = camposObligatoriosEstudiantes({ dataSheet });
          const { camposObligatorios, header } = camposEstudiantes;

          if (camposObligatorios.comprobacion) {
            datosEstudiantesCurso = datosEstudiantes({ advertencias, archivoCorrecto, camposObligatorios, dataSheet, estudiantes, header, nombreHoja: curso });

            if (datosEstudiantesCurso.estudiantes[curso].length === 0) {
              setErrorSubirExcel({
                hayError: true,
                tipoError: 'No hay estudiantes'
              });
            };
            return false
          }
          return curso
        })
      }

    } else {
      worksheetName.forEach(nombreHoja => {
        if (nombreHoja === existeProfesor.nombreHoja) return;

        const dataSheet = workbook.Sheets[nombreHoja];
        const camposEstudiantes = camposObligatoriosEstudiantes({ dataSheet });
        const { camposObligatorios, header } = camposEstudiantes;

        datosEstudiantesCurso = datosEstudiantes({ advertencias, archivoCorrecto, camposObligatorios, dataSheet, estudiantes, header, nombreHoja });
      });
    };

    archivoCorrecto = datosEstudiantesCurso.archivoCorrecto;

    return { advertencias: datosEstudiantesCurso.advertencias, archivoCorrecto, estudiantes };
  };


  //Se encarga de señalar que campos son obligatorios y generar el header para que sea mucho mas facil acceder a la información señalando que valor tienen en el Excel y cual es el esperado
  const camposObligatoriosEstudiantes = ({ dataSheet }) => {
    const headerEstudiantes = XLSX.utils.sheet_to_json(dataSheet, { header: 1 })[0];
    const headerCorrecto = obtenerHeaderTabla({ seccion: 'estudiantes', textosTabla: textosHoja }).es;
    const camposObligatorios = comprobacionCamposExcel({ headerTabla: headerEstudiantes, headerEsperado: headerCorrecto });
    const header = nuevoHeader({ headerTabla: headerEstudiantes, headerCorrecto });
    return { camposObligatorios, header };
  };


  const datosEstudiantes = ({ advertencias, archivoCorrecto, camposObligatorios, dataSheet, estudiantes, header, nombreHoja }) => {
    if (!camposObligatorios.comprobacion) {
      advertencias = advertenciasCamposIncompletos({ camposObligatorios, advertencias, seccion: 'cursos', nombreHoja, textosTabla });
    } else {
      const data = XLSX.utils.sheet_to_json(dataSheet);
      archivoCorrecto = true;

      if (data.length !== 0) {
        data.forEach((estudiante, index) => {
          const comprobacionEstudiantes = validacionCamposLlenos({ camposObligatorios: camposObligatorios.campos, fila: estudiante, index });
          //Advertencias cuando los datos se encuentran incompletos
          if (comprobacionEstudiantes.hayError) {
            advertencias.cursos = { ...advertencias.cursos };
            advertencias.cursos.incompletos = { ...advertencias.cursos.incompletos };
            advertencias.cursos.incompletos.data = { ...advertencias.cursos.incompletos.data };
            advertencias.cursos.incompletos.data[nombreHoja] = { ...advertencias.cursos.incompletos.data[nombreHoja], ...comprobacionEstudiantes.campos };
          }

          if (!comprobacionEstudiantes.hayError) {
            let estudianteCurso = {
              avatar: randomNumber({ minimo: rangoAvatares.minimo, maximo: rangoAvatares.maximo }),
              codigo: !!estudiante[header.codigo] ? estudiante[header.codigo] : null,
              correo: !!estudiante[header.correo] ? estudiante[header.correo] : null,
              nombre: `${estudiante[header.nombres]?.trim()} ${estudiante[header.apellidos]?.trim()}`,
            };
            estudiantes[nombreHoja] = !!estudiantes[nombreHoja] ? [...estudiantes[nombreHoja], estudianteCurso] : [estudianteCurso];
          };
        });
      } else {
        estudiantes[nombreHoja] = [];
      };
    };

    return { advertencias, archivoCorrecto, estudiantes };
  };

  //Valida que cursos no tiene estudiantes
  const validacionCursosSinEstudiantes = ({ advertencias, cursos }) => {
    const cursoSinEstudiantes = [];
    for (const key in cursos) {
      if (cursos[key].estudiantes === undefined || cursos[key].estudiantes.length === 0) {
        cursoSinEstudiantes.push(key);
        advertencias.cursos = {
          ...advertencias.cursos,
          sin_estudiantes: {
            data: cursoSinEstudiantes,
            mensaje: textosTabla.advertencias.cursos.sin_estudiantes.descripcion,
            titulo: textosTabla.advertencias.cursos.sin_estudiantes.titulo
          }
        };
      };
    };
    return { advertencias, cursos };
  };


  const asignarInformacionCurso = ({ cursos, estudiantes, profesores }) => {
    if (!!estudiantes) {
      for (const key in estudiantes) {
        if (!!cursos[key]) {
          cursos[key].estudiantes = estudiantes[key];
        } else {
          cursos = {
            ...cursos, [key]: {
              ...cursos[key],
              icono: randomNumber({ minimo: rangoIconosCurso.minimo, maximo: rangoIconosCurso.maximo }),
              nombre: key,
              estudiantes: estudiantes[key]
            }
          };
        };
      };
    };

    if (!!profesores) {
      profesores.forEach(profesor => {
        profesor.cursos.forEach(curso => {
          if (!!cursos[curso]) {
            cursos[curso].profesores.push(profesor);
          } else {
            cursos = {
              ...cursos, [curso]: {
                ...cursos[curso],
                icono: randomNumber({ minimo: rangoIconosCurso.minimo, maximo: rangoIconosCurso.maximo }),
                nombre: curso,
                profesores: [profesor],
              }
            };
          };
        });
      });
    };

    return { cursos };
  };

  return {
    advertenciasExcel: advertenciasExcel,
    datosExcel: datosExcel,
    errorSubirExcel: errorSubirExcel,
    isLoadingExcel: isLoadingExcel,
    isReadyTextosHoja: isReadyTextosHoja,
    isReadyTextosTabla: isReadyTextosTabla,
  };
};


useObtenerDatosExcel.propTypes = {
  /**
   * Archivo excel con la información
   */
  archivo:PropTypes.shape({
    archivo:PropTypes.object,
    nombre:PropTypes.string,
  }),
  /**
   * Valida si se esta en modo de carga masiva
   */
  crearMasivamente:PropTypes.bool,
  /**
   * El nombre de la hoja de excel
   */
  nombreHoja:PropTypes.string,
  /**
   * Nombre de la sección que se va a crear
   */
  nombreSeccionACrear:PropTypes.string,
  /**
   * Nombre de la sección que se va a crear
   */
  seccionACrear:PropTypes.string,
}

export default useObtenerDatosExcel;