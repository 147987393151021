import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import { AccesibilidadContext } from "../../../../contexts/AccesibilidadContext";

export const Badges = ({badges}) => {
    const accesibilidad = useContext(AccesibilidadContext);
	return (
		<>
			<div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6  lg:grid-cols-5 xl:grid-cols-7 gap-x-8 gap-y-4 mt-10">
				{!!badges &&
					badges.length !== 0 &&
					badges.map((medalla) => (
						<div key={`${medalla.Categoria}_${medalla.Nivel}`}>
							<img
								alt={`${medalla.activado ? "activo" : "inactivo"} ${
									medalla.Categoria
								} ${medalla.nivel}`}
								className="mx-auto cursor-pointer"
								data-tip={true}
								data-scroll-hide={true}
								data-for={`${medalla.Categoria}_${medalla.Nivel}`}
								src={`/images/badges/badges_${medalla.subCategoria}${
									medalla.activado ? "_" : "_off_"
								}${medalla.nivel}.png`}
							/>
							<ReactTooltip
								backgroundColor={
									medalla.activado
										? accesibilidad.temaActual === "clasico"
											? "#6666CC"
											: "#000000"
										: accesibilidad.temaActual === "clasico"
										? "#262626"
										: "#000000"
								}
								className={`w-40 sm:w-60 ${
									accesibilidad.temaActual !== "clasico" &&
									"onprimary-var1--bordered"
								}`}
								id={`${medalla.Categoria}_${medalla.Nivel}`}
								place="top"
								textColor={
									accesibilidad.temaActual === "clasico" ? "#FFFFFF" : "#ffff00"
								}
								event="mouseover mouseenter click"
								eventOff="mouseleave mouseout scroll mousewheel blur"
								effect="solid"
							>
								<p className="quicksand-700 text-16 text-center">
									{medalla.Trigger}
								</p>
							</ReactTooltip>
						</div>
					))}
			</div>
		</>
	);
};
