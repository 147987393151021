import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DetalleEstudianteAPI } from "../../../../api/estadisticas/DetalleEstudianteAPI";
import { EstudiantesAPI } from "../../../../api/EstudiantesAPI";
import { ESTADISTICAS_COLUMNAS_TABLAS } from "../../../../containers/estadisticas/tablas-reportes/ColumnasTablas";
import { useQueryParams } from "../../../../containers/helpers/hooks/locationHooks";
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import { Calendario } from "../../../globales/Calendario";
import { CargandoInformacion } from "../../../globales/estadisticas/CargandoInformacion";
import TituloSeccion from "../../../globales/estadisticas/TituloSeccion";
import { Miga, MigasDePan } from "../../../globales/MigaDePan";
import { useFechaPorDefecto } from "../../hooks/Fechas";
import { DescargarReporte } from "../../Reportes/DescargarReporte";
import TablaSesiones from "./TablaSesiones";
import TablaLibros from "./TablaLibros";
import moment from "moment";
import { useDispatch, useTrackedState } from "../../../../store";
import PropTypes from "prop-types";
import { CancelRequestController } from "../../../../utilities/cancel-request-controller";

/**
 * Este componente tiene la función de cargar todas las estadísticas relacionadas a libros leídos y sesiones de lectura de un estudiante con base a un rango de fechas.Por otro lado, también se encarga de transformar los datos estadísticos a formato excel para que puedan ser descargados por el usuario.
 */
const ReporteAlumnoComponent = (props) => {
  const { traducciones } = props;
  const { miga_de_pan, detalle_estudiante } = traducciones;
  const { codigo, codigo_acceso, tabla, resultados, resumen } = detalle_estudiante;

  const history = useHistory();
  const state = useTrackedState();
  const queryParams = useQueryParams();
  const parametros = useParams();
  const fechaPorDefecto = useFechaPorDefecto();
  const dispatch = useDispatch();
  const [filtros, setFiltros] = useState({
    sede: undefined,
    curso: undefined,
    fechas: {
      inicio: fechaPorDefecto.fechaInicio,
      fin: fechaPorDefecto.fechaFin
    }
  })

  const [informacionEstudiante, setInformacionEstudiante] = useState({
    nombre:' ',
    codigoAcceso:'',
    codigo:'',
    nombreSede:'',
    nombreCurso:''
  })

  const [rangoFechas, setRangoFechas] = useState({
    inicio: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaInicio),
    fin: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaFin),
  })

  const [estadisticas, setEstadisticas] = useState({
    totalSesionesLectura:0,
    sesionesDeLectura:[],
    librosLeidos:[],
  });

  const [cargando, setCargando] = useState({
    estadisticas: false
  })


  const actualizarFiltros = (fechasActualizadas) => {
    dispatch({
      type: "SECCION_ESTADISTICAS_FILTROS",
      property: "fechas",
      value: {inicio:fechasActualizadas[0], fin:fechasActualizadas[1]}
    })
    setFiltros({
      ...filtros,
      fechas: {
        inicio: fechasActualizadas[0],
        fin: fechasActualizadas[1]
      }
    })
  }



  /**
   * transforma la información suministrada por las APIs a una tabla que se puede descargar como excel.
   * @returns tabla para reporte
   */
  const generarInformacionTablaLibros = () => {
    const columnas = ESTADISTICAS_COLUMNAS_TABLAS.ingresoIndividual.librosLeidos.map(col => {
      const traduccion = traducciones?.detalle_estudiante?.tabla?.detalle_libros[`${col?.traduccion}`]
      return { nombreColumna: traduccion || col.textoPorDefecto }
    })

    const filas = [];
    estadisticas?.librosLeidos.forEach(ingreso => {
      let fila = [];
      ESTADISTICAS_COLUMNAS_TABLAS.ingresoIndividual.librosLeidos.forEach(col => {
        let valor = ingreso[`${col?.propiedad}`];
        fila.push({ valor: valor })
      })
      filas.push(fila);
    })

    const tabla = {
      columnas: columnas,
      filas: filas
    }
    return tabla;
  }

  /**
   * transforma la información suministrada por las APIs a una tabla que se puede descargar como excel.
   * @returns tabla para reporte
   */
   const generarInformacionTablaSesiones = () => {
    const columnas = ESTADISTICAS_COLUMNAS_TABLAS.ingresoIndividual.sesiones.map(col => {
      const traduccion = traducciones?.detalle_estudiante?.tabla?.historial_sesiones[`${col?.traduccion}`]
      return { nombreColumna: traduccion || col.textoPorDefecto }
    })

    const filas = [];
    estadisticas?.sesionesDeLectura.forEach(ingreso => {
      let fila = [];
      ESTADISTICAS_COLUMNAS_TABLAS.ingresoIndividual.sesiones.forEach(col => {
        let valor = ingreso[`${col?.propiedad}`];
        fila.push({ valor: valor })
      })
      filas.push(fila);
    })

    const tabla = {
      columnas: columnas,
      filas: filas
    }
    return tabla;
  }


  useEffect(() => {
    setRangoFechas({
      inicio: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.inicio),
      fin: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.fin),
    })
  }, [filtros, state?.idioma])


  useEffect(() => {
    const abortController = new CancelRequestController();
    setCargando({
      ...cargando,
      estadisticas: true
    })
    const actualizarEstadisticas = async () => {
      const idEstudiante = parametros?.idEstudiante;
      await DetalleEstudianteAPI
      .obtenerDetalles(idEstudiante, filtros?.fechas?.inicio, filtros?.fechas?.fin, abortController.signal)
      .then(resultados=>{
        const sesionesLecura = resultados?.sesionesDeLectura.reduce((acc,curr)=>{
          return acc = acc + curr?.numeroSesiones
        },0)
        setEstadisticas({
          ...estadisticas,
          totalSesionesLectura:sesionesLecura,
          sesionesDeLectura:resultados?.sesionesDeLectura,
          librosLeidos:resultados?.librosLeidos
        })
        setCargando({
          ...cargando,
          estadisticas: false
        })
      })
      .catch(error=>console.log(error))
      
    }
    if (filtros?.fechas?.inicio && filtros?.fechas?.fin ) {
      actualizarEstadisticas();
    }
    return () => {
      abortController.abort();
      setCargando({
        ...cargando,
        estadisticas: true
      })
    }
  }, [parametros?.idEstudiante,JSON.stringify(filtros)])



  useEffect(()=>{
    const cargarinformacionDelEstudiante = async ()=>{
      await EstudiantesAPI.obtenerPorId(parametros?.idEstudiante)
      .then(resultado=>{
        setInformacionEstudiante({
          codigo:resultado?.codigoAsignadoInstitucion,
          codigoAcceso:resultado?.codigo,
          nombre:resultado?.nombre,
          nombreCurso:resultado?.nombreCurso,
          nombreSede:resultado?.nombreSede
        })
      })
      .catch(error=>console.log(error))
    }
    cargarinformacionDelEstudiante();

  },[parametros?.idEstudiante])


  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.estadisticas || 'Estadisticas'}
          alSeleccionar={() => {
            history.push('/estadisticas')
          }} />
        <Miga
          texto={detalle_estudiante?.miga_pan?.ingresos_individuales || 'Ingresos individuales (estudiantes)'}
          alSeleccionar={() => {
            history.push('/estadisticas/ingresos-individuales/estudiantes')
          }} />
        <Miga
          texto={`${miga_de_pan?.detalle_de || 'Ingresos individuales'} ${informacionEstudiante?.nombre || 'N/A'}`}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{`${detalle_estudiante.titulo} ${informacionEstudiante?.nombre || 'N/A'}`}</h1>
        <p className="informacion-principal">{detalle_estudiante.descripcion}</p>

        <div className="border-b onsurface-var2--border-color  md:alineado-verticalmente py-5 mb-8 gap-4">
          <div className="md:w-4/12 xl:w-3/12">
            <Calendario
              fechaPorDefecto={[
                state?.estadisticas?.filtros?.fechas?.inicio || queryParams.get('fechaInicio') || fechaPorDefecto?.inicio,
                state?.estadisticas?.filtros?.fechas?.fin || queryParams.get('fechaFin') || fechaPorDefecto.fin
              ]}
              placeholder={'YYYY-MM-DD ~ YYYY-MM-DD'}
              zIndex={999999}
              fechaMinima={state?.datosInstitucion?.fechaInicioSuscripcion || ''}
              fechaMaxima={moment().format('YYYY-MM-DD')}
              alCambiar={(fechas) => actualizarFiltros(fechas)}
              modoRango
            />
          </div>
        </div>

        {Boolean(cargando?.estadisticas) === true ?
          <CargandoInformacion />

          : <>
            <p className="font-quicksand font-bold text-18 onsurface-var1--color">{informacionEstudiante?.nombreSede} 
            <span> - { informacionEstudiante?.nombreCurso || 'N/A'}</span>
            </p>
            <p className="r7-18-negro">{codigo_acceso || 'Código de acceso'} 
            <span className="primary-inv-var2--color"> {informacionEstudiante?.codigoAcceso || 'N/A'}</span> 
            </p>
            <p className="r7-18-negro">{codigo || 'Código'} 
            <span className="primary-inv-var2--color"> {informacionEstudiante?.codigo || 'N/A'}</span> 
            </p>
            <hr class="my-8"/>
            <div className="mb-8">
              <div className="text-center lg:text-left  mt-5 sm:mt-0 ">
                <p className='q5-32 red-inv-var2--color '>
                  <span className='icon-star-full text-30 mr-3'></span>
                  {estadisticas?.totalSesionesLectura}  {estadisticas?.totalSesionesLectura !== 1 ? resumen.plural : resumen.singular}
                </p>
                <p className="r5-14-negro4A">{resumen.descripcion}</p>
              </div>
            </div>

            <TituloSeccion
              titulo={tabla.historial_sesiones.titulo}
              fecha={`${rangoFechas.inicio || 'N/A'} - ${rangoFechas.fin || 'N/A'}`}
              elementosPersonalizados={
                <div className="my-2 w-full flex  justify-center sm:justify-end">
                  <DescargarReporte
                    nombreArchivo={`${traducciones?.detalle_estudiante?.historial_sesiones?.nombre_archivo_reporte || 'Libros leidos por'} ${informacionEstudiante?.nombre}  (${rangoFechas?.inicio} - ${rangoFechas?.fin})`}
                    tablaInformacion={generarInformacionTablaSesiones()}
                  />
                </div>
              }
            />


            <div className="fondoTabla mt-5 xl:px-10 xl:py-5 rounded-lg">
              <TablaSesiones
                datos={estadisticas?.sesionesDeLectura}
                textosTabla={{ tabla: tabla.historial_sesiones, resultados: resultados }}
              />
            </div>

            <TituloSeccion
              titulo={`${tabla.detalle_libros.titulo} ${informacionEstudiante?.nombre || 'N/A'}`}
              fecha={`${rangoFechas.inicio || 'N/A'} - ${rangoFechas.fin || 'N/A'}`}
              elementosPersonalizados={
                <div className="my-2 w-full flex  justify-center sm:justify-end">
                  <DescargarReporte
                    nombreArchivo={`${traducciones?.detalle_estudiante?.libros_leidos?.nombre_archivo_reporte || 'Libros leidos por'} ${informacionEstudiante?.nombre} (${rangoFechas?.inicio} - ${rangoFechas?.fin})`}
                    tablaInformacion={generarInformacionTablaLibros()}
                  />
                </div>
              }
            />

            <div className="fondoTabla  mt-5 xl:px-10 xl:py-5 rounded-lg">
              <TablaLibros
                datos={estadisticas?.librosLeidos}
                textosTabla={{ tabla: tabla.detalle_libros, resultados: resultados }}
              />
            </div>
          </>
        }


      </div>
    </>
  )
}
export default ReporteAlumnoComponent;

ReporteAlumnoComponent.propTypes = {
  /**
   * Objeto con los textos que se deben renderizar en la sección
   */
  traducciones: PropTypes.object.isRequired,
}