import { useState, useEffect } from "react";
import MicroModal from "micromodal";

/**componente */
import Modal from "../../globales/modales/Modal";
import { ClubsAPI } from "../../../api/ClubsAPI";
import { AccionesLibroAPI } from "../../../api/AccionesLibroAPI";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Icon } from "@iconify/react";
import { store } from "react-notifications-component";
import PropTypes from "prop-types";
import { CancelRequestController } from "../../../utilities/cancel-request-controller";

/**
 Componente que despliega un modal con los clubs existentes dentro del instituto y muestra los clubs que tienen asignados un libro, también permite modificar el estado de asignación del libro a cada club
 */
const AsignarAClub = (props) => {

  const { idLibro } = props;
  const [clubsAsignados, setClubsAsignados] = useState([]);
  const [clubsSeleccionados, setClubsSeleccionados] = useState([]);
  const [clubs, setClubs] = useState([]);
  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('libros');
  const [modalVisible, setModalVisible] = useState(false);
  const [guardandoCambios, setGuardandoCambios] = useState(false);


  const cerrarModal = () => {
    MicroModal.close('modal-asignarAClubs')
    setModalVisible(false)
  }

  const abrirModal = () => {
    setModalVisible(true)
    MicroModal.show('modal-asignarAClubs', {
      awaitCloseAnimation: true
    });
  }

  const removerClub = async (clubId) => {
    await AccionesLibroAPI
      .agregarRemoverAClub([idLibro || ''], [clubId || ''], "Ocultar")
      .then(resultado => {
        setClubsAsignados([...clubsAsignados.filter(club => club?.id !== clubId)])
      })
      .catch(error => console.log(error));
  }

  const gestionarSeleccionados = (club, esSeleccionado) => {
    const aux = clubsSeleccionados.filter(c => c?.id !== club?.id);
    if (esSeleccionado) {
      setClubsSeleccionados([...aux, { ...club, asignado: true }])
    } else {
      setClubsSeleccionados(aux)
    }

  }

  /**
  * Esta función agrega y quita clubs en la base de datos
  * @param {'Mostrar'|'Ocultar'} accion 
  */
  const gestionarClubs = async () => {
    setGuardandoCambios(true);
    const idClubsSeleccionados = clubsSeleccionados.map(club => club?.id);

    //verificar que los clubs no asignen el libro si ya han llegado a su limite de libros.
    const clubsNoAsignables = clubsSeleccionados.reduce((acc,curr)=>{
      const libros= Array.isArray(curr?.libros)?curr?.libros : [];
      if(libros?.length >= 5){
        acc.push(curr);
      }
      return acc;
    },[])

    if (clubsNoAsignables.length <= 0){
    await Promise.all([
      AccionesLibroAPI.agregarRemoverAClub(
        [idLibro],
        clubs.map(club=>club?.id),
        'Ocultar'
      ),
      AccionesLibroAPI.agregarRemoverAClub(
        [idLibro],
        idClubsSeleccionados,
        'Mostrar'
      ),
    ])
      .then(resultado => {
        setClubsAsignados(clubs.reduce((acc, curr) => {
          if (idClubsSeleccionados.includes(curr?.id)) {
            acc.push(curr);
          }
          return acc;
        }, []))
        cerrarModal();
      })
      .catch(error => console.log(error))
    }else{
      const nombreClubs = clubsNoAsignables.map(club=> club?.nombre).join(',');
     const titulo = traducciones?.ficha?.clubs?.notificacion_limite?.titulo ||  'Limite de libros alcanzado';
      const mensaje =`${traducciones?.ficha?.clubs?.notificacion_limite?.descripcion || `Los siguientes clubs han alcanzado su limite maximo de 5 libros asignados:`}  \n ${nombreClubs}.`
  
      store.addNotification({
        title: titulo,
        message: mensaje,
        type: "danger",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 10000,
          click:true,
          touch:true,
          showIcon:true
        },
      })
    }

    setGuardandoCambios(false);
  }



  const loadBookClubs = (clubs)=>{
    let clubsFiltrados = [];
    if (clubs && Array.isArray(clubs)) {
      clubs.forEach((club) => {
        const librosId = club?.libros.map((libro) => libro.idLibro);
        if (librosId.includes(idLibro)) {
          clubsFiltrados.push(club);
        }
      });
    }
    setClubsAsignados(clubsFiltrados);
  }
  useEffect(() => {
    const abortController = new CancelRequestController();
    const cargarClubsCompletos = async () => {
      await ClubsAPI
        .obtenerTodos({abortSignal:abortController.signal})
        .then(resultado => {
          setClubs(resultado);
          loadBookClubs(resultado);
        })
        .catch(error => console.log(error))
    }
    cargarClubsCompletos();
    return ()=>{
      abortController.abort();
    }
  }, [])


  useEffect(() => {
    if (modalVisible)
      setClubsSeleccionados(clubsAsignados.map(club => {
        return { ...club, asignado: true }
      }))
  }, [clubsAsignados, modalVisible])




  useEffect(() => {
    const ids = clubsSeleccionados.map(club => club?.id);
    setClubs(clubs.map(club => {
      let esAsignado = false;
      if (ids.includes(club?.id)) {
        esAsignado = true;
      }
      return { ...club, asignado: esAsignado }
    }))
  }, [clubsSeleccionados])


  return (
    <>
      <div className="2xl:border-b border-gris-linea py-5">
        <h3 className="alineado-verticalmente pb-3 r7-16-negro26">
          <span className="icon-marcador text-20 mr-3"></span>
          {traducciones?.ficha?.clubs?.titulo || 'Clubs'}
        </h3>

        <div className="max-h-40 scroll">
          {clubsAsignados.map((club, i) => {
            const { nombre, id } = club;
            return (
              <>
                <p className="w-full orange-var2--bg orange-inv-var2--bordered onbackground-var2--color flex justify-between py-1 px-2 my-3  rounded-sm q7-18 una-linea-texto" key={`club-${i}`}>
                  {nombre}
                  <button aria-label="Quitar/Remove" onClick={() => removerClub(id)} className="focusable-red">
                    <span className="icon-cerrar text-14 hover:text-rojo"></span>
                  </button>
                </p>
              </>
            )
          })}

        </div>

        <button
          className="focusable-red w-full md:w-60 xl:w-auto boton-justo mt-5 boton-blanco boton_hover_amarillo"
          onClick={() => abrirModal()}
        >
          {traducciones?.ficha?.clubs?.boton || 'Asignar'}
          <span className="icon-mas ml-3"></span>
        </button>
      </div>


      <>
        <Modal nombreModal="modal-asignarAClubs" alCerrarModal={() => setModalVisible(false)}>
          <div className="pb-3">
            <h1 className="quicksand-700 onbackground-var2--color text-20 text-center ">{traducciones?.ficha?.clubs?.modal?.titulo || 'Asignar este libro a'}</h1>
            {clubs?.length > 0 ?
              <div className="primary-var5--bg max-h-60 scroll mt-5">
                <ul>
                  {clubs.map(club => {
                    const { nombre, id } = club;
                    return (
                      <li
                        key={id}
                        className="px-3 py-3 alineado-verticalmente cursor-pointer hover:primary-inv-var5--color"

                      >
                        <label className=" w-full h-7 relative mr-3 cursor-pointer" role="checkbox">
                          <input
                            type="checkbox"
                            checked={Boolean(club?.asignado)}
                            onChange={(e) => gestionarSeleccionados(club, e.target.checked)}
                          />
                          <i className="check" ></i>
                          <p className="raleway-400 onsurface-var1--color absolute left-10 top-0.5">{nombre}</p>
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </div>
              : <h2 className="primary-inv-var2--color raleway-400 font-bold text-22 w-full text-center p-2 m-2">{traducciones?.ficha?.clubs?.modal?.sin_clubs || 'Aún no se han creado clubs.'}</h2>
            }
          </div>
          <div className="flex center w-full">
            <button
              aria-label="Actualizar"
              className="focusable-red boton-justo text-16 boton-amarillo boton_hover_morado alineado-verticalmente  flex  justify-between text-center"
              onClick={() => gestionarClubs()}
            >
              {guardandoCambios &&
                <Icon icon={`fa:spinner`} className={'text-22 animate-spin mx-1'} />
              }
              {traducciones?.ficha?.clubs?.modal?.actualizar || 'Actualizar'}
            </button>

          </div>
        </Modal>
      </>

    </>
  )
}
export default AsignarAClub;

PropTypes.AsignarAClub = {
  /**
   * Id del libro que se desea asignar a los clubs
   */
  idLibro: PropTypes.string.isRequired,
}