/**
 * Esta función se encarga de extraer los nombres del header que se esperan obtener en las hojas del Excel. Esta función extrae los textos según los idiomas que se encuentren en la variable de entorno REACT_APP_IDIOMAS_DISPONIBLES, pero si este idioma no existe en el excel donde se encuentran todos los textos, no la utiliza para evitar errores
 * 
 * @param seccion: string
 * @param textosTabla: object
 * 
 * seccion: Corresponde a la sección que se desea consultar (profesores, cursos, estudiantes)
 * textosTabla: Corresponde a los textos relacionados de la hoja que hace referencia a los datos del Excel según la sección que se está consultando.
 * 
 * @returns columnas: object
 * 
 * columnas: Es un objeto que obtine el hombre del header esperado y si dicho campo es obligatorio. Estas propiedades se almacenan en 2 propiedades:
 *  - campo: Es un string que corresponde al nombre del campo esperado dentro del Excel
 *  - obligatorio: Es un booleano que indica si es obligatorio. Si es true es obligatorio, si es false es opcional
**/

const obtenerHeaderTabla = ({ seccion, textosTabla }) => {
  const idiomasDisponibles = process.env.REACT_APP_IDIOMAS_DISPONIBLES.split(',');
  let columnas = {}
  idiomasDisponibles.forEach(idioma => {
    if (!!textosTabla[idioma]) {
      const columnasIdioma = textosTabla[idioma].headerTabla[seccion];
      let colHoja = []
      for (const key in columnasIdioma) {
        const element = columnasIdioma[key];
        element.obligatorio = true;
        colHoja.push(element)
      }
      columnas[idioma] = colHoja;
    }
  })

  return columnas;
};

export default obtenerHeaderTabla;