import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import PropTypes from 'prop-types';
//Hooks personalizados
import useIsMobile from '../../containers/helpers/hooks/useIsMobile';
import useElementoExterior from '../../containers/helpers/hooks/useElementoExterior';
//Compononentes
import OpcionesHeader from "../../containers/header/OpcionesHeader";
import { AccesibilidadContext } from '../../contexts/AccesibilidadContext';

/**
 * Componente que recibe una serie de props necesarios para rendizar el menú lateral. Este componente hace uso del container OpcionesHeader.
 * @returns SideNavComponent 
 */
const SideNavComponent = (props) => {
  const { abrirMenu, cerrarAbrirMenu, isEstudiante, itemsMenu } = props;
  //Hooks
  const param = useParams();
  const { page, section } = param;
  const isMobile = useIsMobile();
  //Referencias
  const refMenu = useRef(null);
  //Estados del compornente
  const [isLoadLogo, setIsLoadLogo] = useState(false);
  const [alturaMenuContenido, setAlturaMenuContenido] = useState('auto');
  const accesibilidad = useContext(AccesibilidadContext);
  const [logoImagen, setLogoImagen] = useState('/images/logo-makemake.svg');

  useEffect(() => {
    const logo =  accesibilidad.temaActual === "clasico" ? '/images/logo-makemake.svg' : '/images/logo-makemake-contraste.svg'
    setLogoImagen(logo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accesibilidad.temaActual])


  //Cierra el menú en la versión móvil cuando se cambia de página
  useEffect(() => {
    isMobile && cerrarAbrirMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, page])


  //Calcula el alto de las opciones del menú lateral
  const refOpcionesMenu = useCallback((node) => {
    if (!!node && isLoadLogo && !!refMenu.current && !!refMenu) {
      const menuContenido = () => {
        const alturaMenu = !!refMenu.current ? refMenu.current.getBoundingClientRect().height : 100;
        const paddingMenu = 5;
        const topOpciones = node.offsetTop;

        const resultadoMenuContenido = alturaMenu - topOpciones - paddingMenu;
        setAlturaMenuContenido(resultadoMenuContenido);
      }

      menuContenido();

      window.addEventListener('resize', menuContenido);
      window.addEventListener('scroll', menuContenido);
      return () => {
        window.removeEventListener('resize', menuContenido);
        window.removeEventListener('scroll', menuContenido);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadLogo, refMenu])


  //Hook para cerrar el menú cuando se haga clic en un elemento externo
  const callback_cerrarMenu = (e) => {
    if (e.id !== 'boton-menu') return isMobile && abrirMenu && cerrarAbrirMenu(false)
  }
  useElementoExterior(callback_cerrarMenu, refMenu);

  return (
    <div
      aria-label="Menu"
      className="menu-lateral-estilo"
      ref={refMenu}
    >
      <div className="menu-contenido box-content" >
        <img
          aria-hidden={true}
          alt="logo makemake"
          className=" w-20 lg:mb-6"
          onLoad={() => setIsLoadLogo(true)}
          src={logoImagen}
        />

        <ul
          className="scroll overflow-x-hidden mt-5"
          style={{ height: alturaMenuContenido }}
          ref={refOpcionesMenu}
        >
          <li className="lg:hidden flex flex-col"><OpcionesHeader /></li>
          {itemsMenu.map((item, index) => {
            const activo = isEstudiante
              ? `/${page}/${section}` === item.ruta
              : `/${page}` === item.ruta

            return (
                <li
                  key={`navLink-${index}`}
                  className={itemsMenu[index] !== itemsMenu[itemsMenu.length - 1] ? "mb-5" : ""}
                >
                  <Link
                    to={item.ruta}
                    aria-current={activo ? 'page': ''}
                    className={`focusable-primary ${activo ? "menu-option_activado" : item.disabled ? "menu-option_candado" : "menu-option_desactivado"}`}
                    onClick={(e) => {
                      if (item.disabled) {
                        e.preventDefault();
                      } else {
                        const container = document.querySelector("#main");
                        if (container) {
                          container.tabIndex = -1;
                          container.focus();
                          setTimeout(() => container.removeAttribute("tabindex"), 1000);
                        }
                      }
                    }}
                  >
                    {item.nombre} {item.disabled && <span className="icon-candado-close text-14"></span>}
                  </Link>
                </li>

            )
          }
          )}
        </ul>
      </div>
    </div>
  )
}

export default SideNavComponent;

SideNavComponent.propTypes = {
  /**
   *  booleano que indica si el menú está oculto o no en la versión móvil. 
   */
  abrirMenu: PropTypes.bool.isRequired,
  /**
   * función que se encarga de cerrar o abrir el menú en la versión móvil de la aplicación.
   * @type{Function(value : number ):void} -callback
   */
  cerrarAbrirMenu: PropTypes.func.isRequired,
  /**
   * Booleano que indica si el usuario activo es un estudiante
   */
  isEstudiante: PropTypes.bool.isRequired,
  /**
   * array de objetos que tiene los items que se deben renderizar. 
   */
  itemsMenu: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    nombre: PropTypes.string,
    ruta: PropTypes.string
  }))
}