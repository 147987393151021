import { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { MigasDePan, Miga } from "../globales/MigaDePan";
import MicroModal from "micromodal";
import PropTypes from 'prop-types';

/*Componentes */
import CrearProfesorMasivo from "./CrearProfesor/Masivo";
import CrearProfesorIndividual from "./CrearProfesor/Individual";
import Modal from "../globales/modales/Modal";

/**
 * Este componente recibe una serie de props que nos necesarios para renderizar la pantalla de crear profesores. Además, hace uso de los componentes CrearProfesorMasivo y CrearProfesorIndividual que hacen referencia a los modos de crear. 
 * @returns ProfesoresCrearComponent
 */
const ProfesoresCrearComponent = (props) => {
  const { actualizarCorreo, actualizarCursosAsignados, actualizarModoCrear, actualizarNombre, actualizarSedeAsignada, advertenciasExcel, correo, cursosAsignados, cursosSede, datosTemporalesExcel, descartarCambios, errorSubirExcel, guardarDatosTemporales, isLoadingExcel, modoActivoCrear, nombre, obtenerDatosExcel, sedesInstituto, sedeSeleccionada, textosInterfaz } = props;

  const { miga_de_pan, crear, botones, advertencias, error_excel, descartar_cambios } = textosInterfaz;


  /*estados */
  const [excelValidoProfesores, setExcelValidoProfesores] = useState(true);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaArchivo, setMostrarAlertaArchivo] = useState(false);
  const [continuarConAdvertencias, setContinuarConAdvertencias] = useState(false);
  const [nombreProfesor, setNombreProfesor] = useState({ campo: nombre, valido: nombre !== '' });
  const [inputCorreo, setInputCorreo] = useState({ campo: correo, valido: false });
  const [datosValidosModoMasivo, setDatosValidosModoMasivo] = useState(true);

  const history = useHistory()

  /**useEffect */
  useEffect(() => {
    if (errorSubirExcel.hayError) {
      MicroModal.show('error-archivo', {
        awaitCloseAnimation: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSubirExcel])

  useEffect(() => {
    setMostrarAlertaArchivo(false);
    setMostrarAlerta(false);

    if (!datosTemporalesExcel.hayArchivo) {
      setExcelValidoProfesores(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modoActivoCrear])


  useEffect(() => {
    if (modoActivoCrear === 'masivo') {
      if (mostrarAlerta) {
        setMostrarAlertaArchivo(true);
      }
    } else {
      setMostrarAlertaArchivo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostrarAlerta])


  /**Funciones */
  /**Esta función sirve para verificar los campos antes de que pase a la pantalla de verificar */
  const verificarDatos = async () => {
    setMostrarAlerta(true);
    if (modoActivoCrear === 'masivo') {
      if (excelValidoProfesores === true && Boolean(sedeSeleccionada?.id)) {
        if (advertenciasExcel.hayAdvertencias) {
          MicroModal.show('advertencias-excel', {
            awaitCloseAnimation: true
          });
          setDatosValidosModoMasivo(false)
        } else {
          await guardarDatos();
          setDatosValidosModoMasivo(true)
        }
      } else {
        setDatosValidosModoMasivo(false)
      }

    } else {
      if (nombreProfesor.valido && inputCorreo.valido) {
        await guardarDatos();
      }
    }
  }

  /**Esta función es del botón de la ventana modal de advertencias.  */
  const continuar = async () => {
    if (continuarConAdvertencias) {
      await guardarDatos()
    }
  }

  const guardarDatos = async () => {
    await guardarDatosTemporales();
    history.push('/profesores/crear-profesores/verificar-profesores');
  }

  /**función del botón descartar */
  const descartar = () => {
    if (datosTemporalesExcel.hayDatos === false) {
      history.push('/profesores');
    } else {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }

  const onKeyEnter = (e) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
       e.target.checked = !e.target.checked;
      setContinuarConAdvertencias(e.target.checked)
    }
  }

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Crear un club de lectura'}
          alSeleccionar={() => {
            descartar()
          }} />
        <Miga
          texto={miga_de_pan?.crear || 'Verificar Club'}
          esRutaActual={true}
        />
      </MigasDePan>
      <div className="contenido">
        <h1 className="titulos-principales mb-5">{crear.titulo}</h1>
        <h3 className={'italic mt-1 mb-5 font-bold onsurface-var1--color'}>{crear?.nota?.password_nuevos_profesores || `Nota: Cada profesor nuevo por defecto tendrá asignado una contraseña que será el correo del profesor anticipado por 'temp-' (ej. temp-example@hotmail.com)`} </h3>

        <div className="flex pb-5">
          <button
            aria-current={modoActivoCrear === 'individual' ? 'page' : ''}
            className={`focusable-red boton-justo  ${modoActivoCrear === 'individual' ? "boton-morado" : "boton-morado_desactivado"}`}
            onClick={() => actualizarModoCrear({ modo: 'individual' })}
          >
            {botones.modo_crear.individual}
          </button>
          <button
            aria-current={modoActivoCrear === 'masivo' ? 'page' : ''}
            className={`focusable-red boton-justo ml-5 ${modoActivoCrear === 'masivo' ? "boton-morado" : "boton-morado_desactivado"}`}
            onClick={() => actualizarModoCrear({ modo: 'masivo' })}
          >
            {botones.modo_crear.masivo}
          </button>
        </div>

        {modoActivoCrear === 'masivo' ?
          <CrearProfesorMasivo
            actualizarSedeAsignada={actualizarSedeAsignada}
            datosTemporalesExcel={datosTemporalesExcel}
            descartarCambios={descartarCambios}
            excelValido={excelValidoProfesores}
            hayArchivo={datosTemporalesExcel.hayArchivo}
            hayErrorExcel={errorSubirExcel.hayError}
            isLoadingExcel={isLoadingExcel}
            mostrarAlerta={mostrarAlerta}
            mostrarAlertaArchivo={mostrarAlertaArchivo}
            obtenerDatosExcel={obtenerDatosExcel}
            sedeSeleccionada={sedeSeleccionada}
            sedesInstituto={sedesInstituto}
            setExcelValido={setExcelValidoProfesores}
            textosInterfaz={crear}
            datosValidosModoMasivo={datosValidosModoMasivo}
          />
          :
          <CrearProfesorIndividual
            actualizarNombre={actualizarNombre}
            actualizarCorreo={actualizarCorreo}
            actualizarSedeAsignada={actualizarSedeAsignada}
            actualizarCursosAsignados={actualizarCursosAsignados}
            setInputCorreo={setInputCorreo}
            inputCorreo={inputCorreo}
            cursosAsignados={cursosAsignados}
            cursosSede={cursosSede}
            descartarCambios={descartarCambios}
            mostrarAlerta={mostrarAlerta}
            nombreProfesor={nombreProfesor}
            sedeSeleccionada={sedeSeleccionada}
            sedesInstituto={sedesInstituto}
            setNombreProfesor={setNombreProfesor}
            textosInterfaz={crear}
          />
        }

        {/* Botones */}
        <div className="flex justify-end my-5">
          <Link
            to="/profesores"
            className="focusable-primary boton-pequenio boton-rojo boton_hover_morado text-16 text-center mr-5 "
          >
            {botones.cancelar}
          </Link>
          <button
            className="focusable-primary boton-justo boton-amarillo boton_hover_naranja text-16 center"
            onClick={() => { verificarDatos() }}
          >
            {botones.verificar}
          </button>
        </div>
      </div>

      {/* Modal error archivo*/}
      {errorSubirExcel.hayError &&
        <Modal nombreModal="error-archivo">
          <div className="border-b onbackground-var2--color pb-3">
            <span aria-label="Icono alerta" className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="q7-28 onbackground-var2--color text-center">
              {errorSubirExcel.tipoError === "No hay profesores" ? error_excel.sin_profesores.titulo : error_excel.archivo_incorrecto.titulo}
            </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {errorSubirExcel.tipoError === "No hay profesores" ? error_excel.sin_profesores.descripcion : error_excel.archivo_incorrecto.descripcion}
          </p>

          <button
            onClick={() => {
              MicroModal.close(`error-archivo`, {
                awaitCloseAnimation: true
              })
            }}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_morado block mx-auto mb-6"
          >
            {botones.regresar}
          </button>
        </Modal>
      }

      {/* Modal advertencias Modal */}
      {advertenciasExcel.hayAdvertencias &&
        <Modal nombreModal="advertencias-excel">
          <div className="border-b onsurface-var1--color focusable-primary pb-3">
            <span aria-label="Icono alerta" className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="q7-28 onbackground-var2--colo text-center">{advertencias.titulo} </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {advertencias.descripcion}
          </p>

          <a href={advertenciasExcel.href} download={advertenciasExcel.nombreArchivo} className="evento_rojo text-center r7-16-negro26 block focusable-primary"> {advertencias.archivo.archivo} </a>

          <div className="flex md:items-center md:w-3/4 justify-between  mx-auto my-6">
            <p className="r5-14-negro4A w-9/12  ">{advertencias.archivo.checkbox} </p>
            <label className="relative w-20 focusable-by-children-primary" role="checkbox">
              <input
                tabIndex={0}
                type="checkbox"
                className="focusable-red h-0  m-3"
                onChange={(e) => { setContinuarConAdvertencias(e.target.checked) }}
                onKeyUp={(e) => { onKeyEnter(e) }}
                checked={continuarConAdvertencias}
              />
              <i aria-hidden={true} className="checkbox"><span className="focusable-red no-check">No </span></i>
            </label>
          </div>

          <div className="center my-5">
            <button
              aria-label="Cancelar"
              className="focusable-primary boton-pequenio boton-rojo boton_hover_morado sm:flex justify-center items-center mr-5 "
              onClick={() => {
                MicroModal.close('advertencias-excel', {
                  awaitCloseAnimation: true
                });
              }}
            >
              <p className="onprimary-inv-var1--color">{botones.cancelar}</p>
            </button>

            <button
              className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
              onClick={continuar}
              disabled={continuarConAdvertencias ? false : true}
            >
              {botones.continuar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal descartar cambios */}
      {datosTemporalesExcel.hayDatos &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              onClick={descartarCambios}
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }
    </>
  )
}
export default ProfesoresCrearComponent;

ProfesoresCrearComponent.propTypes = {
  /**
   * función que se encarga de actualizar el correo del profesor cuando se crea de forma individual.
   * @type{Function({ correo: string}):void} - callback
   */
  actualizarCorreo: PropTypes.func.isRequired,
  /**
   * función que se encarga de actualizar los cursos asignados al profesor. 
   * @type{Function({ e : shape, curso: shape, idSede: string }):void} - callback
   */
  actualizarCursosAsignados: PropTypes.func.isRequired,
  /**
   * función que se encarga de cambiar el modo en el que se van a crear los profesores
   * @type{Function({ modo : string }):void} -callback
   */
  actualizarModoCrear: PropTypes.func.isRequired,
  /**
   * función de encarga de actualizar el estado de nombre cuando se está creando el profesor de manera individual.
   * @type{Function({ nombre: string }):void} - callback
   */
  actualizarNombre: PropTypes.func.isRequired,
  /**
   * función que se encarga de actualizar el id de la sede en la que se desea crear el profesor(es)
   * @type{Function({ idSede : string }):void} - callback
   */
  actualizarSedeAsignada: PropTypes.func.isRequired,
  /**
   * objeto de objetos que contienen ciertas propiedades relacionadas con las advertencias que se encontraron en el archivo subido por el usuario.
   */
  advertenciasExcel: PropTypes.shape({
    hayAdvertencias: PropTypes.bool, //puede ser null
    href: PropTypes.string,
    nombreArchivo: PropTypes.string
  }).isRequired,
  /**
   *  string que corresponde al campo de correo cuando se crea el profesor de manera individual.
   */
  correo: PropTypes.string.isRequired,
  /**
   * Objeto que contiene la información de los cursos asignados al profesor.
   */
  cursosAsignados: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }).isRequired,
  /**
   * Objeto que contiene el estado y los cursos que el instituto tiene.
   */
  cursosSede: PropTypes.shape({
    cursos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    hayCursos: PropTypes.bool
  }).isRequired,

  /**
   * Es un objeto que almacena los datos temporales del excel 
   */
  datosTemporalesExcel: PropTypes.shape({
    datos: PropTypes.object,
    hayDatos: PropTypes.bool
  }).isRequired,

  /**
   *  función que se encarga de borrar los datos temporales que se guarden en el store
   */
  descartarCambios: PropTypes.func.isRequired,

  /**
   * objeto que indica si el archivo subido es erróneo y que tipo de error es.
   */
  errorSubirExcel: PropTypes.shape({
    hayError: PropTypes.bool, // puede ser null
    tipoError: PropTypes.string
  }).isRequired,

  /**
   *  función que debe ejecutarse cuando el usuario haga clic en el botón de Verificar Datos
   */
  guardarDatosTemporales: PropTypes.func.isRequired,

  /**
   * booleano que indica si el archivo subido termino de ser validado.
   */
  isLoadingExcel: PropTypes.bool.isRequired,

  /**
   * string que indica cual es el modo en el que se están creando los profesores. Puede contar con un valor de "individual" o "masivo".
   */
  modoActivoCrear: PropTypes.string.isRequired,

  /**
   *  string que corresponde al campo de nombre cuando se crea el profesor de manera individual.
   */
  nombre: PropTypes.string.isRequired,

  /**
   * función que debe ejecutarse en un evento onChange del input en el que se carga el excel. +
   * @type{Function({ archivo: shape, e: shape, nombre: string }):void }- callback
   */
  obtenerDatosExcel: PropTypes.func.isRequired,

  /**
   * array de objetos que contiene el listado de sedes que el instituto tiene. 
   */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
   *  objeto que cuenta con los datos de la sede seleccionada para crear el o los profesores.
   */
  sedeSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired
}