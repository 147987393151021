import axios from "axios";

export class DetalleLibroAPI {



    // DEFINICIÓN DE LOS TIPOS
    /**
     * @typedef {  
       {
            idLibro: string,
            tituloLibro: string,
            autor: string,
            editorial: string, //mins
            lecturasLibro:[
                {
                nombreCompleto: string,
                numeroDeVecesLeido: number,
                codigo: string,
                codigoAsignadoInstitucion: string | null,
                tiempoPromedioDeLectura: number,
                tiempoTotalLectura: number,
                fechaLectura: string,
                sede: {
                    idSede: string,
                    nombre: string
                },
                curso: {
                    idCurso: string,
                    nombre: string
                }
                }
            ]
        }

  } DetalleLibro
  **/

    /**
     * @type {DetalleLibro}
     */
    static DetalleLibroPorDefecto = {
      autor:'N/A',
      editorial:'N/A',
      idLibro:'N/A',
      lecturasLibro:[],
      tituloLibro:'N/A' 
    }



    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

    /**
  * devuelve un objeto con las estadisticas de los estudiantes de una sede en realación a un libro 
  * @param {String} idSede - id de la sede
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<DetalleLibro>} DetalleLibro
  */
    static async obtenerPorSede({idLibro, idSede, fechaInicio, fechaFin, abortSignal=null}) {
        const body = {
            idLibro:idLibro || '',
            idSede: idSede,
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasDetalleLibro',
                data: body,
                signal:abortSignal || null
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            const detalleLibro = response.data?.data.find(libro=>libro?.idLibro === idLibro);
            const detalles = detalleLibro || DetalleLibroAPI.DetalleLibroPorDefecto;
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }

    /**
  * devuelve un objeto con las estadisticas de los estudiantes de un curso en realación a un libro 
  * @param {String} idLibro - id del libro
  * @param {String} idCurso - id del curso
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<DetalleLibro>} DetalleLibro
  */
     static async obtenerPorCurso({idLibro, idCurso, fechaInicio, fechaFin, abortSignal=null}) {
        const body = {
            idLibro:idLibro || '',
            idCurso: idCurso || '',
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasDetalleLibro',
                data: body,
                signal:abortSignal || null
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }
            const detalleLibro = response.data?.data.find(libro=>libro?.idLibro === idLibro);
            const detalles = detalleLibro|| DetalleLibroAPI.DetalleLibroPorDefecto;
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }

}

