
/**
 * Servicios relacionados a local storage
 */
export class LocalStorage{

    /**
     * Estados de la sesión
     */
    static SESION_ESTADOS = {
        activa:'activa',
        finalizadaPorInactiva:'finalizada-por-inactividad',
        finalizadaPorUsuario:'finalizada-por-usuario',
        noAutenticado:'no-autenticado'
    }

    /**
     * guarda el estado de la sesión en el local storage
     * @param {string} estado - estado que se desea guardar
     */
    static guardarEstadoSesion(estado){
        localStorage.setItem('estado-sesion', estado);
    }

     /**
     * obtiene el estado de la sesión desde el local storage
     * @returns {string} estado actual de la sesión
     */
    static obtenerEstadoSesion(){
        const estado = localStorage.getItem('estado-sesion');
        return estado?estado:undefined;
    }

    /**
     * Elimina el estado de la sesión del local storage
     */
    static eliminarEstadoSesion(){
        localStorage.removeItem('estado-sesion')
    }
}