import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

/**
 * Componente que recibe una serie de props necesarios para las portadas de los libros.
 * @returns Libro
 */
export const Libro = (props) => {
	const {
		libro,
		alAgregarFav,
		alRemoverFav,
		alSeleccionar,
		alCambiarEstadoCheckbox,
		clasesPersonalizadas,
		ocultarOpciones,
		estadoInicial,
		esVisible,
		elementosAdicionales,
	} = props;

	const SinImagen = "/images/no-imagen.png";

	const [esFavorito, setEsFavorito] = useState(false);
	const [esCheckboxActivado, setEsCheckboxActivado] = useState(false);
	const [imagenCargada, setImagenCargada] = useState(false);
	const [imagen, setImagen] = useState(libro?.portada || SinImagen);
	const [lazyVisible, setLazyVisible] = useState(false);

	const agregarAFavorito = (favorito) => {
		if (favorito) {
			if (alAgregarFav) alAgregarFav(libro);
		} else {
			if (alRemoverFav) alRemoverFav(libro);
		}
		setEsFavorito(favorito);
	};

	const marcarCheckbox = (activar) => {
		setEsCheckboxActivado(activar);
		alCambiarEstadoCheckbox && alCambiarEstadoCheckbox(libro, activar);
	};

	useEffect(() => {
		if (Boolean(libro.portada) === false) {
			setImagen(SinImagen);
		} else {
			setImagen(libro?.portada);
		}
	}, [libro]);

	useEffect(() => {
		setImagenCargada(false);
	}, [imagen]);

	useEffect(() => {
		setEsCheckboxActivado(estadoInicial?.esCheckboxMarcado);
	}, [estadoInicial?.esCheckboxMarcado]);

	useEffect(() => {
		setEsFavorito(estadoInicial?.esFavorito);
	}, [estadoInicial?.esFavorito]);

	return (
		<div
			className={`w-full h-full relative ${lazyVisible ? "" : "portada"}`}
			style={{
				background: lazyVisible ? "transparent" : "var(--background-var3)",
				aspectRatio: "29/45",
			}}
		>
			{lazyVisible === false && (
				<div
					className={`absolute top-0 left-0 w-full h-full flex-grow flex items-center justify-center`}
				>
					<Icon
						icon="fa:spinner"
						width={30}
						className={`primary-inv-var2--color animate-spin`}
					/>
				</div>
			)}
			<LazyLoad
				width={"100%"}
				height={"100%"}
				threshold={0.2}
				onContentVisible={() => {
					setLazyVisible(true);
				}}
			>
				<>
					<div
						role="region"
						aria-label={`Libro/Book ${libro?.title || libro?.nombre}`}
						tabIndex={0}
						className={`focusable-primary libro-tarjeta ${clasesPersonalizadas?.contenedor}`}
					>
						<div
							className={`portada relative overflow-hidden  ${clasesPersonalizadas?.portada} `}
							style={{
								aspectRatio: "29/45",
							}}
						>
							<img
								loading="lazy"
								className={`${Boolean(esVisible) === false && "filter grayscale"} ${
									imagenCargada === false && "opacity-0 w-full"
								} `}
								src={imagen}
								alt={libro?.title || libro?.nombre}
								onLoad={(e) => {
									setImagenCargada(true);
								}}
								onError={(e) => {
									setImagen(SinImagen);
								}}
							/>
							<div className="ver-mas z-10">
								<button
									aria-label={`Ir a/Go to ${libro?.title || libro?.nombre}`}
									className="focusable-secondary rounded-full p-2 primary-inv-var2--bg onprimary-inv-var1--color cursor-pointer  transicion-150"
									onClick={() => alSeleccionar && alSeleccionar(libro)}
								>
									<Icon icon="ion:open" className="text-30" />
								</button>
							</div>
							{Boolean(ocultarOpciones?.botonMultiSeleccion) == false && (
								<button
									role="checkbox"
									aria-label={`Seleccionar libro/Select book`}
									aria-checked={esCheckboxActivado}
									className={`focusable-secondary ${
										esCheckboxActivado
											? "primary-inv-var2--bg primary-inv-var2--bordered"
											: "  background-var3--bg primary-inv-var1--bordered"
									} transicion-150 cursor-pointer flex center border-2 rounded-md  z-10 absolute top-0 left-0 w-6 h-6 m-2`}
									onClick={() => marcarCheckbox(!esCheckboxActivado)}
								>
									{esCheckboxActivado && (
										<Icon
											icon={"akar-icons:check"}
											className={"onprimary-inv-var1--color w-full h-full"}
										/>
									)}
								</button>
							)}
							{Boolean(ocultarOpciones?.botonFavoritos) === false && (
								<button
									role="checkbox"
									aria-label={`Agregar a favorito/Add to favorites`}
									aria-checked={esFavorito}
									className={`focusable-secondary z-10 fav ${
										esFavorito && "added"
									}  absolute top-0 right-0 rounded-full m-1 p-1 flex center transform transicion-150`}
									onClick={(e) => agregarAFavorito(!esFavorito)}
								>
									<span className="icon-corazon pointer-events-none"></span>
								</button>
							)}

							{imagenCargada === false && (
								<div
									className={`absolute top-0 left-0 w-full h-full flex-grow flex items-center justify-center`}
								>
									<Icon
										icon="fa:spinner"
										width={30}
										className={`primary-inv-var2--color animate-spin`}
									/>
								</div>
							)}
							{elementosAdicionales?.portada && (
								<div className="wrapper-elementos-adicionales-portada absolute top-0 left-0 w-full h-full">
									{elementosAdicionales?.portada}
								</div>
							)}
						</div>
						<Link
							aria-label={`Ir a/Go to ${libro?.title}`}
							className={`focusable-primary titulo ${clasesPersonalizadas?.titulo}`}
							onClick={(e) => alSeleccionar && alSeleccionar(libro)}
						>
							{libro?.title || libro?.nombre || "N/A"}
						</Link>
					</div>
				</>
			</LazyLoad>
		</div>
	);
};

Libro.propTypes = {
	/**
	 * el objeto libro que se cargara, es requerido
	 */
	libro: PropTypes.object.isRequired,
	/**
* callback utilizada para cuando el libro sea seleccionado para realizar acciones de ver, abrir, mostrar libro.
Este evento se dispara al hacer click en el titulo y en el icono que aparece en la mitad al hacer hover
* @type{Function(libro:Libro):void} - callback
*/
	alSeleccionar: PropTypes.func,
	/**
	 * callback utilizada para verificar si el checkbox esta seleccionado o no, devuelve el libro y el si esta seleccionado el checkbox o no
	 * @type{Function(libro:Libro, estaSeleccionado:boolean):void} - callback
	 */
	alCambiarEstadoCheckbox: PropTypes.func,
	/**
	 * callback utilizada para cuando se desmarque la opción de favoritos
	 * @type{Function(libro:Libro):void} - callback
	 */
	alRemoverFav: PropTypes.func,
	/**
	 * callback utilizada para cuando se marque la opción de favoritos
	 * @type{Function(libro:Libro):void} - callback
	 */
	alAgregarFav: PropTypes.func,
	/**
  *objeto para personalizar clases de portada, titulo y container principal
  * @type{{
  contenedor:string,
  portada:string,
  titulo:string}} - objeto de clases personalizadas
  */
	clasesPersonalizadas: PropTypes.object,
	/**
  *objeto en el cual se define si una opción ( por ejemplo favoritos) se debe mostrar o no
  * @type{{
  n  botonFavoritos:boolean,
    botonMultiSeleccion:boolean,
    seleccionado:boolea
  */
	ocultarOpciones: PropTypes.object,
	/**
 *objeto en donde se pasa propiedades iniciales por defecto al componente, se puede pasar si el boton de favorito debe estar marcado, al igual que otras caracteristicas. 
 * @type{{
   esFavorito:boolean,
   esCheckboxMarcado:boolean
   seleccionado:boolean
 * }}
 */
	estadoInicial: PropTypes.object,
	/**
	 *propiedad booleana en donde se define si es visible o no el libro, si es visible se vera a color, si no es as[i, se vera en escala de grises]
	 * @type{boolean}
	 */
	esVisible: PropTypes.bool,
	/**
   * Objeto el cual permite agregar elementos html o componentes react adicionales al libro.
   * @example 
      {
        "portada":<button>Botón personalizado que ira dentro de la portada</button>
      }
 
   */
	elementosAdicionales: PropTypes.shape({
		portada: PropTypes.element,
	}),
};
