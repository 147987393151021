import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
//Store
import { useDispatch } from '../../store';
//Hooks personalizados
import useInitialDataOfSection from './../helpers/useInitiailDataOfSection';
//Apis//Componentes
import ProfesoresGeneral from './ProfesoresGeneral';
import ProfesoresCrear from './ProfesoresCrear';
import ProfesoresVerificar from './ProfesoresVerificar';
import ProfesoresEditarNuevo from './ProfesoresEditarNuevo';
import ProfesoresEditarEliminar from './ProfesoresEditarEliminar';
import Error_404 from './../Error_404/index';

/**
 * Es el Container principal del módulo de Profesores. Su función es renderizar el container  correspondiente y asignarle la información requerida. Los containers que pueden ser renderizados son ProfesoresGeneral, ProfesoresCrear, ProfesoresVerificar, ProfesoresEditarNuevo y ProfesoresEditarEliminar.
 */
const Profesores = () => {
  //Estados globales
  const dispatch = useDispatch();

  const { isLoading: isLoadingProfesores } = useInitialDataOfSection({ pathname: '/profesores/obtenerListadoProfesores', property: 'datosProfesores', section: 'profesores', propiedadJson: 'cursos' })
  const { isLoading: isLoadingCursos } = useInitialDataOfSection({ pathname: '/cursos/obtenerCursos', property: 'datosCursos', section: 'cursos', propiedadJson: 'profesores' })


  const history = useHistory()

  useEffect(() => {
    return () => borrarDatosTemporales()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Descartar cambios
  const descartarCambios = async () => {
    borrarDatosTemporales()

    history.push('/profesores')
  }


  const borrarDatosTemporales = async () => {
    let dataTemporal = {};
    await dispatch({
      type: "SET_DATA",
      property: "temporal",
      value: dataTemporal
    });
  }


  return (
    <>
      <Switch>
        <Route exact path="/profesores">
          <ProfesoresGeneral
            isLoadingCursos={isLoadingCursos}
            isLoadingProfesores={isLoadingProfesores}
          />
        </Route>

        <Route exact path="/profesores/crear-profesores">
          <ProfesoresCrear
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
          />
        </Route>
        <Route exact path="/profesores/crear-profesores/verificar-profesores">
          <ProfesoresVerificar
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
          />
        </Route>
        <Route exact path="/profesores/crear-profesores/verificar-profesores/:profesor_nuevo">
          <ProfesoresEditarNuevo
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
          />
        </Route>

        <Route exact path="/profesores/editar-profesor">
          <ProfesoresEditarEliminar
            isLoadingCursos={isLoadingCursos}
          />
        </Route>
        <Route exact path="/profesores/editar-profesor/:sede">
          <ProfesoresEditarEliminar
            isLoadingCursos={isLoadingCursos}
          />
        </Route>
        <Route exact path="/profesores/editar-profesor/:sede/:profesor">
          <ProfesoresEditarEliminar
            isLoadingCursos={isLoadingCursos}
          />
        </Route>

        <Route component={Error_404} />
      </Switch>
    </>
  )
}

export default Profesores;
