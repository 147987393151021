import { useEffect, useState } from "react";
import { CursosAPI } from "../../../api/CursosAPI";
import { CancelRequestController } from "../../../utilities/cancel-request-controller";

export const useClasses = ()=>{
    const [loading, setLoading] = useState(true);
    const [classes, setClasses] = useState([]);
    const [buildings, setBuildings] = useState([]);


    const getBuildings = ({classes})=>{
        if(!Array.isArray(classes)){return []};
        const _buildings = classes.map(curso => {
            return {
                idSede: curso.idSede,
                sedeNombre: curso.sedeNombre
            };
        });

        const filtered = _buildings.filter((sede, index, self) => 
            index === self.findIndex((s) => (
                s.idSede === sede.idSede && s.sedeNombre === sede.sedeNombre
            ))
        );
    
        return filtered;
    }

    const fetchClasses = async ({abortSignal, isUnmounted})=>{
        if(isUnmounted){
            return;
        }
        await CursosAPI.obtenerTodos({abortSignal}).then(res=>{
            const _classes = res;
            if(Array.isArray(_classes)){
            setClasses(_classes);
            setBuildings(getBuildings({classes:_classes}))
           }
           else{
            setClasses([]);
            setBuildings([]);
           }
        })
        .catch(error=>{
            console.log(error)
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        const abortController = new CancelRequestController();
        let isUnmounted = false;
        fetchClasses({abortSignal:abortController.signal, isUnmounted});
        return ()=>{
            abortController.abort();
            isUnmounted = true;
        }
    },[])


    return {
        loading,
        classes,
        buildings
    }
}