import AbortController from "abort-controller";
import axios from "axios";

export class ClubsAPI {
	// DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
	/**
     * @typedef { {
            id: string,
            nombre: string,
            descripcion: string,
            icono: string,
            archivo: {
                path: string,
                name: string
            } | null,
            estudiantes: [
                {
                    codigo: string,
                    curso: string,
                    email: string
                    id: string,
                    idCurso: string,
                    nombre: string,
                    sede: string,
                }
            ],
            libros: [
                {
                    idLibro: string,
                    nombre: string,
                    portada:string
                }
            ]
        }} Club
  */
	//---------------------------------------------------------------------------------
	// EMPIEZAN LOS SERVICIOS
	/**
	 * Devuelve todos los clubs asociados a un instituto
	 * @returns {Promise<Club[]>} Club[]
	 */
	static async obtenerTodos(data) {
		const { abortSignal } = data || {};
		try {
			axios.defaults.baseURL = process.env.REACT_APP_API_URL;
			axios.defaults.withCredentials = true;
			const response = await axios({
				method: "POST",
				url: "clubs/obtenerClubs",
				data: {},
				signal: abortSignal,
			});
			const clubs = response.data?.data;
			return clubs ? clubs : [];
		} catch (error) {
			return Promise.reject(error);
		}
	}

	/**
	 * Devuelve todos los clubs que tienen asignado un libro en particular
	 * @param {string} idLibro
	 * @returns {Promise<Club[]>} Club[]
	 */
	static async obtenerPorAsignacionLibro(idLibro) {
		try {
			axios.defaults.baseURL = process.env.REACT_APP_API_URL;
			axios.defaults.withCredentials = true;
			const response = await axios({
				method: "POST",
				url: "clubs/obtenerClubs",
				data: {},
			});
			const clubs = response.data?.data;
			let clubsFiltrados = [];
			if (clubs && Array.isArray(clubs)) {
				clubs.forEach((club) => {
					const librosId = club?.libros.map((libro) => libro.idLibro);
					if (librosId.includes(idLibro)) {
						clubsFiltrados.push(club);
					}
				});
			}
			return clubsFiltrados;
		} catch (error) {
			return Promise.reject(error);
		}
	}
}
