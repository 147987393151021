import PropTypes from 'prop-types';
import { useContext } from 'react';
import { AccesibilidadContext, TemasDisponibles } from '../../../contexts/AccesibilidadContext';

/**
 * Componente que recibe una serie de propiedades necesarias para renderizar los nombres de la selección del filtro en "Reportes gráficos de estudiantes" de estadísticas.
 * @returns TituloInstituto
 */
const TituloInstituto = (props) => {
  const { elementosVisibles, colorFondo, colorTexto, nombreColegio, nombreCurso, cuadroColor, alCerrar } = props;
  const accesibilidad = useContext(AccesibilidadContext)

  const background = accesibilidad.temaActual ===TemasDisponibles.altoContraste.id? "var(--primary-inv-var1)":colorFondo
  const color = accesibilidad.temaActual ===TemasDisponibles.altoContraste.id? "var(--onprimary-inv-var1)":colorTexto


  return (
    <section 
    aria-label={nombreColegio} 
    role="region"
    tabIndex={0} className=' focusable-primary w-full xl:w-72 h-16 flex items-center my-3 cursor-default' >
      {
        cuadroColor &&
        <section style={{ background: colorFondo }} className={`w-8 h-8 mr-3 rounded-md`}></section>
      }
      <section className='grid grid-cols-12 w-full h-full rounded-md' style={{ background: background, color:color }}>
        <section className={`flex flex-col center p-2 w-full ${elementosVisibles?.botonCerrar === true?'col-span-11':'col-span-12'}`}>
          {
            nombreColegio &&
            <h3 className='font-bold w-full truncate text-center' style={{maxWidth:'97%'}}>{ nombreColegio || 'N/A'}</h3>
          }
          {
            nombreCurso &&
            <p className='max-w-full truncate text-center'>{nombreCurso || 'N/A'}</p>
          }
        </section>
        {
          elementosVisibles?.botonCerrar === true &&
          <button aria-label={'cerrar'+(nombreColegio)} className=' focusable-primary flex items-start justify-center m-2' onClick={() => alCerrar && alCerrar()}>
            <span className='icon-cerrar  text-13 cursor-pointer' ></span>
          </button>
        }


      </section>
    </section>
  )
}
export default TituloInstituto;

TituloInstituto.propTypes = {
  /**
   * String del color del fondo de la tarjeta
   */
  colorFondo: PropTypes.string.isRequired,
  /**
   * String del color del texto
   */
  colorTexto: PropTypes.string.isRequired,
  /**
   * String equivale al nombre del  colegio, sede o institucion
   */
  nombreColegio: PropTypes.string.isRequired,
  /**
   * String del nombre del curso
   */
  nombreCurso: PropTypes.string,
  /**
   * Booleano que indica si el titulo debe llevar una muestra de color
   */
  cuadroColor: PropTypes.bool,
  /**
   * Callback que funciona para quitar el titulo
   */
  alCerrar: PropTypes.func,
}