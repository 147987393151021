
import { ComparativoComponent } from "./ComparativoComponent";
import Filtro from "./Filtro";
import { Graficas } from "./Grafica";
import { TablaComparativa } from "./TablaComparaciones";

export const EstadisticasComparativas = {
    Component: ComparativoComponent,
    Filtro: Filtro,
    TablaComparativa: TablaComparativa,
    Grafica: Graficas
}