//Hooks y funciones personalizadas
import useObtenerTraducciones from '../helpers/hooks/useObtenerTraducciones';
import { useTrackedState } from '../../store';
//Componentes
import ERROR_404_COMPONENT from './../../components/Error_404/index';

/**
 * Componente de tipo container quue no recibe props. Este componente tiene como función pasar los textos de interfaz y el rol activo al componente ERROR_404_COMPONENT para ser renderizado.
 * @returns  {JSX.Element} ERROR_404_COMPONENT
 */
const Error_404 = () => {
  const state = useTrackedState();
  //Hooks personalizados
  const { isReady, textosSeccion: textosInterfaz } = useObtenerTraducciones('error_404');

  return (
    <>
      {isReady &&
        <ERROR_404_COMPONENT
          textosInterfaz={textosInterfaz}
          rol={state?.planActivo?.tipoUsuario}
        />
      }
    </>
  )
}

export default Error_404;