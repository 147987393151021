import axios from "axios";

export class CursosAPI {

    // DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
    /**
     * @typedef {{
                    "id": string
                    "nombre": string
                    "idSede": string,
                    "numeroTotalDeEstudiantes": number,
                    "numeroDeLibrosAsignados": number,
                    "icono": number,
                    "profesores": [
                        {
                            "id": number,
                            "nombre": string
                        }
                    ]
                }} Curso
  */
//---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS
       /**
     * Devuelve todos los cursos asociados a un instituto
     * @returns {Promise<Curso[]>} Curso[]
     */
    static async obtenerTodos({abortSignal}={}) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'cursos/obtenerCursos',
                data: {},
                signal:abortSignal || null
            })
            let cursos = [];
            const sedes = response?.data?.data;
            if (sedes) {
                Object.values(sedes).forEach((sede) => {
                    if (sede?.cursos && Array.isArray(sede?.cursos)) {
                        const cursosSede = sede?.cursos.map(curso => {
                            return { ...curso, sedeNombre: sede?.nombre || 'N/A' }
                        })
                        cursos = [...cursos, ...cursosSede]
                    }
                })
            }
            return cursos ? cursos : []
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
* Devuelve todos los cursos que tienen asignado un libro en particular
* @param {string} idLibro   Id del libro
* @returns {Promise<Curso[]>} Curso[]
*/
    static async obtenerPorAsignacionLibro(idLibro) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const librosResponse = await axios({
                method: 'POST',
                url: 'libros/datosLibros',
                data: { idLibros: [idLibro] }
            })
            const cursosDisponibles = await CursosAPI.obtenerTodos();
            const libros = librosResponse.data?.data || [];
            const libro = libros.find(libro => libro?.idLibro == idLibro);
            const cursosAsignadosLibro = (libro && libro?.cursos) ? libro.cursos : [];
            let cursosAsignados = cursosDisponibles.reduce((acc, curr) => {
                if (cursosAsignadosLibro.includes(curr.id)) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            return cursosAsignados ? cursosAsignados : [];
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Devuelve un objeto con las o la sede y dentro de ella un arreglo de cursos
     * @param {string} idSede 
     * @returns {Promise<
       {
        "idSede": {
                "idSede": string,
                "nombre": string,
                "cursos": Curso[]
            }
        }
        >} 
     */
    static async obtenerCursos(idSede) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'cursos/obtenerCursos'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: { idSede: idSede }
            })
            const data = response.data.data;
            if (data) {
                return data;
            } else {
                return []
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
           * Devuelve un arreglo con los cursos asignados a una sede en especifico.
           * @param {string} idSede 
           * @returns {Promise<Curso[]>} 
           */
    static async obtenerCursosPorSede(idSede) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'cursos/obtenerCursos'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: { idSede: idSede }
            })
            const sedes = response.data.data;
            if (response.data?.status == 1) {
                const sede = sedes[idSede];
                if (sede) {
                    const cursos = sede?.cursos || [];
                    return cursos && Array.isArray(cursos) ? cursos : []
                } else {
                    return []
                }
            } else {
                throw new Error(response.data?.info || 'Hubo un problema, intente de nuevo más tarde.')
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
