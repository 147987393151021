import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from "../../store";
//Funciones
import concatenarDatos from './../../utilities/function/concatenarDatos';
import generalCallApi from './../helpers/generalCallApi';
import listarArray from './../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
import transformarDatosEstudiantes from './../../utilities/function/estudiantes/transformarDatosEstudiantes';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Componentes
import EstudiantesEditarEliminarComponent from "../../components/estudiantes/EstudiantesEditarEliminarComponent";
import SkeletonEstudiantesEditar from "../../components/estudiantes/SkeletonsEstudiantes/SkeletonEstudiantesEditar";
import VentanaAlerta from './../../components/globales/VentanaAlerta';
import PropTypes from 'prop-types';

/**
 *  Componente de tipo Container que gestiona toda la lógica para la renderización, actualización de los datos de un estudiante y también la eliminación.
 * Finalmente se renderiza el componente EstudiantesEditarEliminarComponent. al cual se le pasan los datos del estudiante a editar o eliminar. 
 */
const EstudiantesEditarEliminar = (props) => {
  const { isLoadingCursos, isLoadingEstudiantes } = props;

  //Estados globales 
  const state = useTrackedState()
  const { datosInstitucion, datosCursos, datosEstudiantes } = state;
  const dispatch = useDispatch();

  //Estados del componente  
  //Datos del estudiante
  const [codigo, setCodigo] = useState('');
  const [correo, setCorreo] = useState('');
  const [cursoSeleccionado, setCursoSeleccionado] = useState({ id: null, nombre: '' })
  const [nombre, setNombre] = useState('');
  const [codigoPorInstituto, setCodigoPorInstituto] = useState('');
  const [sedeSeleccionada, setSedeSeleccionada] = useState({ id: null, nombre: '' });


  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [datosEstudiante, setDatosEstudiante] = useState(null);
  const [existeEstudiante, setExisteEstudiante] = useState(null);
  const [hayCambios, setHayCambios] = useState({ cambios: false, datos: {} });
  const [preloader, setPreloader] = useState(false);
  const [respuestaApiEditar, setRespuestaApiEditar] = useState({
    isError: null,
    mensaje: ''
  });
  const [respuestaApiEliminar, setRespuestaApiEliminar] = useState({
    isError: null,
    mensaje: ''
  });
  const [sedesInstituto, setSedesInstituto] = useState([])

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estudiantes');


  //Otras librerias
  const history = useHistory();
  const params = useParams();
  const { curso, sede, estudiante } = params;


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    console.log(curso, datosCursos, datosEstudiantes, estudiante, sede);
    if (!!curso && !!estudiante && !!sede) {
      if (!!datosCursos && !!datosEstudiantes) {
        //Verifica si la sede existe
        const sedeActual = { ...datosEstudiantes[sede] };
        let existeParam = !!sedeActual;
        if (!!sedeActual) {
          const cursoActual = { ...sedeActual.cursos[curso] }
          existeParam = !!cursoActual;

          if (!!cursoActual) {
    console.log('hay curso', cursoActual);
            const estudianteActual = cursoActual?.estudiantes?.find(estudianteCurso => estudianteCurso.id === estudiante);
            existeParam = !!estudianteActual;


            //Si el api de eliminar ya se llamo y esta respondio de forma afirmativa, no se ejecuta lo que se encuentra en el siguiente condicional
            if (respuestaApiEliminar.isError !== false) {
              //Si exite el curso se guardan los datos en el estado del componente
              if (existeParam) {
                const infoEstudiante = {
                  codigoAsignadoInstitucion:estudianteActual.codigoAsignadoInstitucion,
                  codigo: estudianteActual.codigo,
                  correo: estudianteActual.correo,
                  id: estudianteActual.id,
                  idCurso: estudianteActual.idCurso,
                  idSede: estudianteActual.idSede,
                  nombre: estudianteActual.nombre,
                  nombreCurso: estudianteActual.nombreCurso,
                  nombreSede: estudianteActual.nombreSede
                }

                setCodigo(infoEstudiante.codigo);
                setCodigoPorInstituto(infoEstudiante.codigoAsignadoInstitucion);
                setCorreo(infoEstudiante.correo);
                setCursoSeleccionado({
                  id: infoEstudiante.idCurso,
                  nombre: infoEstudiante.nombreCurso,
                });
                setDatosEstudiante(infoEstudiante);
                setNombre(infoEstudiante.nombre);
                setSedeSeleccionada({
                  id: infoEstudiante.idSede,
                  nombre: infoEstudiante.nombreSede,
                });
              }
            }
          }
        }
        setExisteEstudiante(existeParam);
      }
      return
    }
    history.push('/estudiantes');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curso, datosCursos, datosEstudiantes, estudiante, sede])


  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: sedeSeleccionada.id })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, sedeSeleccionada.id])


  //Actualiza el nombre del estudiante
  const actualizarCorreo = ({ correo }) => {
    const propiedades = { correo: correo !== datosEstudiante.correo }
    verificarCambios({ propiedades });

    setCorreo(correo);
  }


  //Actualiza la sede en la que se encuentra el estudiante
  const actualizarCurso = ({ idSede, idCurso }) => {
    const datosSede = !!datosCursos[idSede]
      ? { id: idSede, nombre: datosCursos[idSede].nombre }
      : { id: null, nombre: '' };

    const datosCurso = !!datosEstudiantes[idSede].cursos[idCurso]
      ? { id: idCurso, nombre: datosEstudiantes[idSede].cursos[idCurso].nombre }
      : { id: null, nombre: '' };

    setSedeSeleccionada(datosSede)
    setCursoSeleccionado(datosCurso);


    const propiedades = {
      sede: datosEstudiante.idSede !== idSede,
      curso: datosEstudiante.idCurso !== idCurso
    }
    verificarCambios({ propiedades });
  };


  //Actualiza el nombre del estudiante
  const actualizarNombre = ({ nombre }) => {
    const propiedades = { nombre: nombre !== datosEstudiante.nombre }
    verificarCambios({ propiedades });
    setNombre(nombre)
  };
  
  //Actualiza el codigo asignado desde el instituto
  const actualizarCodigoPorInstituto = ({ codigo }) => {
    const propiedades = { codigoPorInstituto: codigo !== datosEstudiante?.codigoAsignadoInstitucion }
    verificarCambios({ propiedades });
    setCodigoPorInstituto(codigo)
  };


  //Actualiza la sede en la que se encuentra el estudiante
  const actualizarSede = ({ idSede }) => {
    if (idSede !== sedeSeleccionada.id) {
      const nombreSede = datosEstudiantes[idSede].nombre;
      setSedeSeleccionada({
        id: idSede,
        nombre: nombreSede
      });
      setCursoSeleccionado({ id: null, nombre: '' });

      const propiedades = { sede: datosEstudiante.idSede !== idSede }
      verificarCambios({ propiedades });
    };
  };


  //Editar estudiante de la base de datos
  const editarEstudiante = async () => {
    if (!preloader) {
      setRespuestaApiEditar({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);

      const pathname = '/estudiantes/editarEstudiante';
      const properties = {
        correo: correo,
        idCurso: cursoSeleccionado.id,
        idEstudiante: estudiante,
        idSede: sedeSeleccionada.id,
        nombre: nombre,
        codigoColegio:codigoPorInstituto
      }
  
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0

      if (!error) {
        await obtenerDatosDB();

        if (datosEstudiante.idSede !== sede && datosEstudiante.idCurso !== curso) {
          history.push(`/estudiantes/editar-estudiante/${sedeSeleccionada.id}/${cursoSeleccionado.id}/${estudiante}`)
        }
      }
      	setHayCambios(false)
      setRespuestaApiEditar({
        isError: error,
        mensaje: result.info
      });
 
      setPreloader(false);
    }
  }


  //Eliminar estudiante de la base de datos
  const eliminarEstudiante = async () => {
    if (!preloader && respuestaApiEliminar.isError !== false) {
      setRespuestaApiEliminar({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);

      //Párametros para el llamdo del api
      const pathname = '/estudiantes/borrarEstudiante';
      const properties = { idEstudiantes: [datosEstudiante.id]}

      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        setDatosEstudiante({ ...datosEstudiante, ...properties });
        await obtenerDatosDB();
      }

      setRespuestaApiEliminar({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    }
  }


  //Listar los cursos dependiendo de la sede activa
  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }


  //Verificar cambios
  const verificarCambios = ({ propiedades }) => {
    const datos = { ...hayCambios.datos, ...propiedades };
    let cambios;

    for (const key in datos) {
      const element = datos[key];
      if (element) cambios = true;
    }


    setHayCambios({
      hayCambios: cambios,
      datos: { ...hayCambios.datos, ...propiedades }
    });
  }



  //Llamada al api para obtener los datos de la base de datos
  const obtenerDatosDB = async () => {
    const result = await generalCallApi({ pathname: '/estudiantes/obtenerListadoEstudiantes' });
    let datos = result.data;

    const error = result.status === 0;
    if (!error) {
      const value = await transformarDatosEstudiantes({ datos: result.data, sedes: datosInstitucion.sedes })
      // Actualizar datos en el store 
      dispatch({
        property: 'datosEstudiantes',
        type: 'SET_DATA',
        value: value
      });
    }

    const resultCursos = await generalCallApi({ pathname: '/cursos/obtenerCursos' });
    const errorCursos = result.status === 0;
    if (!errorCursos) {
      // Actualizar datos en el store 
      const value = await concatenarDatos({ datos: resultCursos.data, section: 'cursos', propiedadJson: 'profesores' })
      dispatch({
        property: 'datosCursos',
        type: 'SET_DATA',
        value: value
      });
    }

    return { datos, error };
  }


  //Componente que se va a renderizar 
  const componenteRender = () => {
    if (!isLoadingCursos && !isLoadingEstudiantes && isReady && !!datosCursos && !!datosEstudiante) {
      return (
        <EstudiantesEditarEliminarComponent
        actualizarCodigoPorInstituto={actualizarCodigoPorInstituto}
          actualizarCorreo={actualizarCorreo}
          actualizarCurso={actualizarCurso}
          actualizarNombre={actualizarNombre}
          actualizarSede={actualizarSede}
          codigoEstudiante={codigo}
          codigoAsignadoInstitucion={codigoPorInstituto}
          correoEstudiante={correo}
          cursosSede={cursosSede}
          cursoSeleccionado={cursoSeleccionado}
          datosEstudiante={datosEstudiante}
          hayCambios={hayCambios.hayCambios}
          editarEstudiante={editarEstudiante}
          eliminarEstudiante={eliminarEstudiante}
          nombreEstudiante={nombre}
          preloader={preloader}
          respuestaApiEditar={respuestaApiEditar}
          respuestaApiEliminar={respuestaApiEliminar}
          sedeSeleccionada={sedeSeleccionada}
          sedesInstituto={sedesInstituto}
          textosInterfaz={{
            botones: textosInterfaz.botones,
            editar: textosInterfaz.editar,
            miga_de_pan: textosInterfaz.miga_de_pan,
            modal_eliminar: textosInterfaz.modal_eliminar,
            modal_exito: textosInterfaz.modal_exito,
            notificacion: textosInterfaz.notificacion,
          }}
        />
      )
    }
    if (isReady && existeEstudiante === false && respuestaApiEliminar.isError !== false) {
      return (
        <VentanaAlerta
          imagen={{
            alt: 'blop con un icono de alerta',
            img: 'alertas/blop-alerta.png',
          }}
          link='/estudiantes'
          textosInterfaz={{
            descripcion: textosInterfaz.no_existe_estudiante.descripcion,
            textoBoton: textosInterfaz.botones.volver,
            titulo: textosInterfaz.no_existe_estudiante.titulo,
          }}
        >
          <Link
            className="boton-justo mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado"
            to='/estudiantes'
          >
            {textosInterfaz.botones.volver.principal}
          </Link>
        </VentanaAlerta>
      )
    } else {
      return <SkeletonEstudiantesEditar />
    }
  }


  return (
    <>
      {componenteRender()}
    </>
  )
}
export default EstudiantesEditarEliminar;

EstudiantesEditarEliminar.propTypes = {
  /**
   * Indica si el componente esta cargando o no los cursos del instituto
   */
  isLoadingCursos: PropTypes.bool.isRequired,
  /**
   * Indicia si el componente esta cargando los datos de los estudiantes
   */
  isLoadingEstudiantes: PropTypes.bool.isRequired,
}