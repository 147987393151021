import OpcionesHeader from "../../containers/header/OpcionesHeader";
import React, { useEffect, useRef, useContext } from "react";
import PropTypes from 'prop-types';
import { Icon } from "@iconify/react";
import { useState } from "react";
import { MenuAccesibilidad } from "../globales/MenuAccesibilidad";
import { AccesibilidadContext } from "../../contexts/AccesibilidadContext";

/**
 * Componente que recibe una serie de props necesarios para renderizar el encabezado o Header. Este componente hace uso del container OpcionesHeader
 * @returns HeaderComponent
 */
const HeaderComponent = (props) => {
  const { actualizarAlturaHeader, alturaHeader, color, cerrarAbrirMenu, nombreInstitucion, logoInstitucion } = props;
  //Referencias
  const refHeader = useRef(null);
  const [mostrarMenuAccesibilidad, setMostrarMenuAccesibilidad] = useState(false);
  const accebilidadState = useContext(AccesibilidadContext);
  const [esTemaClasico, setEsTemaClasico] = useState(true);

  useEffect(() => {
    let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
    setEsTemaClasico(tema);
  }, [accebilidadState])

  useEffect(() => {
    if (refHeader !== null) {
      alturaEspacioVacio();
      window.addEventListener('resize', alturaEspacioVacio)

      return () => {
        window.removeEventListener('resize', alturaEspacioVacio)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refHeader]);

  const alturaEspacioVacio = () => {
    const alturaHeader = refHeader.current.getBoundingClientRect().height;
    actualizarAlturaHeader(alturaHeader);
  }

  return (
    <>
      <header
        ref={refHeader}
        className="p-0 header-estilo"
        style={{
          backgroundColor: esTemaClasico ?  color?.header || '#f1f1f1' : 'var(--background-var2)'  ,
          color: esTemaClasico ?  color?.letras || '#262626' : 'var(--onbackground-var2)',
          zIndex: 1001
        }}
      >
        <div className="relative w-full flex justify-between p-2 h-16">
          <div className="flex justify-between">
            <div className="flex items-center">
              <div
                role="img"
                className=" onsurface-var2--border-color onprimary-var1--bg border-3 w-12 h-12 rounded-full text-indent img-80-center flex-shrink-0"
                style={{ backgroundImage: `url(${logoInstitucion})` }}
                aria-labelledby="image-logo"
              >
                  <p id="image-logo">logotipo institución</p>
                </div>
              <p
                className="ml-5 text-16 font-bold text-negro-claro max-h-14" style={{ color: esTemaClasico ?  color?.letras || '#262626' : 'var(--onbackground-var2)' }}
              // className="ml-5 text-20 font-bold text-negro-claro  w-36  overflow-hidden xsm:w-52 sm:w-auto whitespace-nowrap overflow-ellipsis" style={{color: !!color ? color.letras : '#262626'}}
              >{nombreInstitucion}</p>
            </div>
          </div>
          <div className="menu-accesibilidad-flotante">
            <MenuAccesibilidad
              setVisible={setMostrarMenuAccesibilidad}
              visible={mostrarMenuAccesibilidad}
            />
          </div>
          <div className="flex justify-end relative">
            <button 
              className="mx-4 focusable-red px-2" 
              onClick={() => setMostrarMenuAccesibilidad(!mostrarMenuAccesibilidad)}
              aria-label="panel de accesibilidad"
            >
              <Icon icon="fontisto:universal-acces" width={30} />
            </button>
            <div className="hidden lg:flex justify-end items-center">
              <OpcionesHeader />
            </div>
            <button
              className="boton-menu focusable-red"
              ria-label="Menu"
              onClick={cerrarAbrirMenu}
            >
              <span className="icon-menu boton-menu-estilo" id='boton-menu'></span>
            </button>
          </div>
        </div>
      </header>
      <div style={{ height: alturaHeader }}></div>
    </>
  )
}

export default HeaderComponent;

HeaderComponent.propTypes = {
  /**
   * Función que obtiene la altura del header
   * @type{Function(header : number ):void} -callback
   */
  actualizarAlturaHeader: PropTypes.func.isRequired,

  /**
   * Número que hace referencia a la altura del header
   */
  alturaHeader: PropTypes.number,

  /**
   * Objeto que contiene el color de fondo y letras pertenecente a la institutción.
   */
  color: PropTypes.shape({
    header: PropTypes.string,
    letras: PropTypes.string
  }).isRequired,

  /**
   *  función que se encarga de cerrar o abrir el menú en la versión móvil de la aplicación. Este prop viene del componente APP
   * @type{Function(value : number ):void} -callback
   */
  cerrarAbrirMenu: PropTypes.func.isRequired,

  /**
   *  Es un string con el nombre de la institución.
   */
  nombreInstitucion: PropTypes.string.isRequired,

  /**
   * Es un string que tiene el link del logo de la institución.
   */
  logoInstitucion: PropTypes.string.isRequired
}