import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DivisionCrud from "../../../globales/DivisionCrud";

const SkeletonCursosCrearIndividual = () => {
  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
        <Skeleton className="ruta" width={280} height={40} />
        <div className="contenido" >

          <h1 className="titulos-principales  ">
            <div className="md:w-44 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>

          <div className="flex py-5">
            <div className='w-6/12 sm:w-4/12 lg:w-52'>
              <Skeleton width={'100%'} height={36} />
            </div>
            <div className='w-6/12 sm:w-4/12 lg:w-52 ml-5'>
              <Skeleton width={'100%'} height={36} />
            </div>
          </div>

          <DivisionCrud>
            <>
              <Skeleton width={160} height={24} />
            </>
            <div className="alineado-verticalmente h-full">
              <div className="w-full xl:w-451p">
                <Skeleton width={'100%'} height={36} />
              </div>
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={80} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={120} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={'90%'} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div>
              <Skeleton className="mx-auto lg:mx-0" width={128} height={128} style={{ borderRadius: '100%', display: 'block' }} />
              <Skeleton className="mx-auto lg:mx-0 mt-3" width={130} height={24} style={{ display: 'block' }} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={120} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={120} />
              <Skeleton className="mt-3" width={180} height={24} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={144} />
              <div className="mt-3 alineado-verticalmente">
                <div className="w-2/12 md:w-14">
                  <Skeleton style={{ borderRadius: '25px', display: 'block' }} width={'100%'} height={28} />
                </div>
                <div className="w-10/12 md:w-72 ml-5">
                  <Skeleton width={'100%'} height={28} />
                </div>

              </div>
            </div>
          </DivisionCrud>

          <div className="flex justify-end my-5">
            <button className=" w-1/2 sm:w-40 mr-5">
              <Skeleton width={'100%'} height={36} />
            </button>
            <button className=" w-1/2 sm:w-40">
              <Skeleton width={'100%'} height={36} />
            </button>
          </div>
        </div>
      </SkeletonTheme>
    </>
  )
}
export default SkeletonCursosCrearIndividual;