import { Icon } from "@iconify/react";
import react from "react";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Componente que retorna los elementos de carga en la sección de estadísticas.
 * @returns CargandoInformacion
 */
export const CargandoInformacion = (props) => {
  const { textosSubSecciones: traducciones } =
    useObtenerSubsecciones("estadisticas");
  return (
    <section className="w-full flex-col center" alt="cargando información">
      <Icon
        aria-hidden="true"
        role="img"
        icon="icomoon-free:spinner9"
        width={50}
        className={"primary-inv-var2--color    animate-spin"}
      />
      <p
        className="raleway-500 text-20 m-3 primary-inv-var1--color "
        alt="cargando información"
      >
        {traducciones?.global?.resultados?.cargando ||
          "Cargando información, por favor espere..."}
      </p>
    </section>
  );
};
