import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * Cuando se cambia de URL en el navbar, el scroll vuelve a la parte superior de la página.
 * @param {object} history de react-router-dom, para poder acceder a la url actual y children de react-router-dom para poder acceder al componente hijo.
 */
const ScrollToTop =({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return <>{children}</>;
}

export default withRouter(ScrollToTop);