import { useEffect } from 'react';
//https://css-tricks.com/dangers-stopping-event-propagation/
//https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
//
/**
 * Hook que detecta si se ha hecho click fuera de un elemento HTML.
 * @param {Function} callback 
 * @param {Element} ref 
 */
const useElementoExterior = (callback, ref) => {
  useEffect(() => {
    // Alert if clicked on outside of element
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback(e.target)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default useElementoExterior;
