import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Icon } from '@iconify/react';

/**
 * Componente que recibe una serie de propiedades necesarias
 *  para renderizar el titulo de cada sección en estadísticas.
 * @returns TituloSeccion
 */
const TituloSeccion = (props) => {
  const { titulo, fecha, elementosPersonalizados, input, alEscribir } = props;

  const inputBuscador = useRef(null);
  const [buscadorActivo, setBuscadorActivo] = useState(false);

  const limpiarBuscador = () => {
    inputBuscador.current.value = '';
    alEscribir && alEscribir({ value: '' })
    setBuscadorActivo(false)
  }

  return (
    <section className="z-10 md:alineado-verticalmente flex-wrap my-3">
      <section className={input ? 'md:w-5/12' : 'md:w-6/12'}>
        <h2 className=" q7-20 primary-inv-var1--color">{titulo}</h2>
        <p className="r4-16 onsurface-var1--color">{fecha}</p>
      </section>
      {
        input &&
        <section aria-label="buscar un estudiante por su nombre" className="md:w-7/12  mt-6 md:mt-0 md:alineado-verticalmente ">
          <section className="relative w-full">
            <input
              role='img'
              className="w-full"
              placeholder={input.placeHolder}
              onChange={(e) => {
                if (e.target.value.trim().length > 0) {
                  setBuscadorActivo(true)
                } else {
                  setBuscadorActivo(false)
                }
                alEscribir && alEscribir({ value: !!inputBuscador.current ? inputBuscador.current.value : '' })
              }}
              ref={inputBuscador}
              type="text"
            />
            <section alt ="buscar"className={`absolute right-0 top-0 ${buscadorActivo ? 'w-14' : 'w-10'} h-full flex flex-row center`}>
              {
                buscadorActivo &&
                <Icon icon="ep:close-bold" className='primary-inv-var1--color mx-1 cursor-pointer red-inv-var1--hover' onClick={() => limpiarBuscador()} />
              }
              <span alt="lupa"className="icon-lupa primary-inv-var1--color cursor-default"></span>
            </section>
          </section>
        </section>
      }
      {

        elementosPersonalizados &&
        <>
          {elementosPersonalizados}
        </>
      }
    </section>
  )
}
export default TituloSeccion;

TituloSeccion.propTypes = {
  /**
   * String que equivale al titulo de la seccion
   */
  titulo: PropTypes.string.isRequired,
  /**
   * String que equivale a la fecha en la/s que se esta filtrando.
   */
  fecha: PropTypes.string,
  /**
   * objeto que contiene la informacion del input
   * @type{
   *  placeHolder: string 
   *  textoBorrarFiltro: string
   * }
   */
  input: PropTypes.object,
  /**
   * callback utilizada para hacer la busqueda segun el texto del input
   * @type{Function({value: string}):void} - callback
   */
  alEscribir: PropTypes.func,
  /**
   * Cualquier elemento html o de react que quiera ser adicionado al componente actual.
   */
  elementosPersonalizados:PropTypes.element
}