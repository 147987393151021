import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Componente que recibe el texto de interfaz y la información de los badges para renderizar la sección de medallas en el Home.
 * @returns BadgesEstudiantes
 */
export const Badges = ({ badgesEstudiantes }) => {
    const [numeroBadges, setNumeroBadges] = useState(0);
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
    const textoHome = textos?.home || {};

    const badges = Array.isArray(badgesEstudiantes)?badgesEstudiantes:[];


    useEffect(() => {
        const setMobile = () => {
            let nBadges;
            if (window.innerWidth <= 338) {
                nBadges = 3;
            } if (window.innerWidth >= 339 && window.innerWidth <= 575) {
                nBadges = 2;
            } if (window.innerWidth >= 576 && window.innerWidth <= 767) {
                nBadges = 4;
            } if (window.innerWidth >= 768 && window.innerWidth <= 991) {
                nBadges = 5;
            } if (window.innerWidth >= 992 && window.innerWidth <= 1199) {
                nBadges = 4;
            } if (window.innerWidth >= 1200) {
                nBadges = 6;
            }
            setNumeroBadges(nBadges)
        }
        setMobile();
        window.addEventListener('resize', setMobile)
        return () => {
            window.removeEventListener('resize', setMobile)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                badges?.length !== 0
                    ?
                    <>
                        <div className="grid grid-cols-2 xsm:grid-cols-3 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-5 xl:grid-cols-7 mt-5 overflow-hidden">
                            {
                                badges?.map((badge, index) => {
                                    const soloEstosBadges = [];
                                    if (index <= numeroBadges) {
                                        soloEstosBadges.push(
                                            <div key={`${badge.Categoria}_${badge.Nivel}`} className="mx-3">
                                                <img
                                                    alt={`${badge.Categoria}_${badge.Nivel}`}
                                                    src={`/images/badges/badges_${badge.Categoria}${badge.Trigger ? '_' : '_off_'}${badge.Nivel}.png`}
                                                />
                                            </div>
                                        )
                                    }
                                    return soloEstosBadges;
                                })
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className="sm:center">
                            <div className="w-full xl:w-8/12 sm:center sm:overflow-hidden h-180 relative">
                                <img
                                    aria-hidden={true}
                                    alt="libro abierto"
                                    className="onprimary-var1--bg w-32 md:w-auto mx-auto "
                                    src={`/images/perfil-estudiante/logros.png`}
                                />
                                <p className="md:w-200p q7-20 onsurface-var1--color text-center break-words md:mr-20 ">{textoHome?.logros?.no_hay}</p>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

Badges.propTypes = {
    /**
   * array de objetos que almacena los badges obtenidos por el estudiante.
   */
    badgesEstudiantes: PropTypes.arrayOf(PropTypes.shape({
        Categoria: PropTypes.string,
        Nivel: PropTypes.string,
        Trigger: PropTypes.string
    })).isRequired,
    /**
     * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
     */
    textoHome: PropTypes.object.isRequired
}