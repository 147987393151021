//Hooks y funciones personalizadas
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';

//componentes
import DetalleLibroEstudianteComponent from '../../components/estadisticas/DetalleLibro/DetalleLibroEstudiante/DetalleLibroEstudianteComponent';
import SkeletonDetalleLibro from '../../components/estadisticas/SkeletonsEstadisticas/SkeletonDetalleLibro';
import { Route, Switch } from 'react-router-dom';
import DetalleLibroProfesorComponent from '../../components/estadisticas/DetalleLibro/DetalleLibroProfesor/DetalleLibroProfesorComponent';

const DetalleLibro = () => {

  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
    <>
      <Switch>
        <Route exact path="/estadisticas/profesores/libros-leidos/:idLibro">
          {
            isReady ?
              <DetalleLibroProfesorComponent traducciones={textosInterfaz} />
              :
              <SkeletonDetalleLibro />
          }
        </Route>
        <Route exact path="/estadisticas/estudiantes/libros-leidos/:idLibro">
          {
            isReady ?
              <DetalleLibroEstudianteComponent traducciones={textosInterfaz} />
              :
              <SkeletonDetalleLibro />
          }
        </Route>
      </Switch>

    </>
  )
}
export default DetalleLibro;