import { useState, useEffect } from 'react';
import { useTrackedState } from "../../../store";
//Data
import DataTextos from "../../../modules/dataTextos.json";
/**
 * Retorna un objeto con los textos de la sección de preguntas frecuentes.
 * @param {string} seccion - Seccion de preguntas frecuentes.
 * @returns {object} Objeto con los textos de la sección de preguntas frecuentes.
 */
const useObtenerPreguntasFrecuentes = (seccion) => {
  //Estados globales
  const state = useTrackedState();
  const { idioma } = state;
  //Estados del componente
  const [textosSubSecciones, setTextosSubSecciones] = useState(undefined);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const obtenerTraducciones = () => {
      const seccionEspecifica = DataTextos[seccion];
      let infoSeccion = {};

      seccionEspecifica.forEach(item => {
        const { item: grupo, id, rector, profesor, estudiante } = item;
        infoSeccion[grupo] = { ...infoSeccion[grupo] };

        if (infoSeccion[item.item]) {
          if (id !== 'usuarios') {
            let info = item[idioma];
            infoSeccion[grupo] = { ...infoSeccion[grupo], [id]: info }
            return
          } else {
            infoSeccion[grupo] = {...infoSeccion[grupo], [id] :
              {estudiante : convert_string_bolean(estudiante),
              profesor : convert_string_bolean(profesor),
              rector : convert_string_bolean(rector)}
            }
            return
          }
        }

      })

      setIsReady(true);
      setTextosSubSecciones(infoSeccion);
    }
    obtenerTraducciones()
  }, [idioma, seccion])

  const convert_string_bolean = (value) => value === true ? true : false;

  return { isReady, textosSubSecciones }
}
export default useObtenerPreguntasFrecuentes;