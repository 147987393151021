import React, { useRef } from "react";
import { useTrackedState } from "../../../store";
import CajaCreadoContenido from "../../../components/globales/cajasCrear/CajaCreadoContenido";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";

export const ClassCard = ({ data }) => {
	const { textosSubSecciones: texts } = useObtenerSubsecciones("cursos");
	const {info_curso, vista_general} = texts || {};
	const {boton_ver_editar, boton_ver_todo, profesor, estudiante, libro, estadisticas} = info_curso || {};
	const {tool_tips} = vista_general || {};
	const cajaCursoCreado = useRef(null);
	const state = useTrackedState();
	const rolUsuario = state?.planActivo?.tipoUsuario || "null";


	return (
		<div className="p-3">
			<div className="caja-creado h-full" ref={cajaCursoCreado}>
				<div className="caja-creado-titulo surface-var1--bg alineado-verticalmente relative overflow-hidden ">
					<img
						className="caja-creado-titulo-img img-80-center"
						src={`/images/badges/badge-${data?.icono}.png`}
						alt="icono del curso"
					/>
					<p className="q7-20-blanco ml-20 una-linea-texto">{data?.nombre}</p>
				</div>

				<div className=" px-5 xl:px-10 ">
					<CajaCreadoContenido
						link="./profesores"
						parametrosBusqueda={`?sede=${data?.idSede}&curso=${data?.id}`}
						textoLink={boton_ver_editar}
						ocultarElementos={{
							link: rolUsuario === "profesor" ? true : false,
						}}
					>
						<span className="onsurface-var1--color icon-profesor caja-creado-icono"></span>

						<div>
							{data?.profesores?.length === 0 ? (
								<>
									<p className="q7-16-negro26">
										{profesor?.sin_profesor}
										<span
											className="red-inv-var2--color icon-alerta text-18 cursor-pointer ml-3"
											data-tip={true}
											data-scroll-hide={true}
											data-for="tool-profesores"
										></span>
									</p>
								</>
							) : (
								Object.keys(data?.profesores).map((item) => {
									return (
										<p key={item} className="q4-16-negro26">
											{data?.profesores[item].nombre}
										</p>
									);
								})
							)}
							{!data?.profesor && (
								<ReactTooltip
									backgroundColor="#FF3548"
									className="quicksand-400"
									id="tool-profesores"
									place="top"
									textColor="#FFFFFF"
									event="mouseover mouseenter click"
									eventOff="mouseleave mouseout scroll mousewheel blur"
									effect="solid"
								>
									<p>
										{tool_tips?.profesor || 'sin profesores'}
									</p>
								</ReactTooltip>
							)}
						</div>
					</CajaCreadoContenido>

					<CajaCreadoContenido
						link="./estudiantes"
						parametrosBusqueda={`?sede=${data?.idSede}&curso=${data?.id}`}
						textoLink={boton_ver_todo}
					>
						<span className="onsurface-var1--color icon-usuario caja-creado-icono"></span>

						<div className="w-full alineado-verticalmente">
							<p
								className={
									!data?.numeroTotalDeEstudiantes
										? "q7-16-negro26"
										: "q4-16-negro26"
								}
							>
								{data?.numeroTotalDeEstudiantes}{" "}
								{data?.numeroTotalDeEstudiantes !== 1
									? estudiante?.plural
									: estudiante?.singular}
								{!data?.numeroTotalDeEstudiantes && (
									<span
										className="icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
										data-tip={true}
										data-scroll-hide={true}
										data-for="tool-estudiantes"
									></span>
								)}
							</p>
							{!data?.numeroTotalDeEstudiantes && (
								<ReactTooltip
									backgroundColor="#FF3548"
									className="quicksand-400"
									id="tool-estudiantes"
									place="top"
									textColor="#FFFFFF"
									event="mouseover mouseenter click"
									eventOff="mouseleave mouseout scroll mousewheel blur"
									effect="solid"
								>
									<p>{tool_tips?.estudiantes || 'sin estudiantes'}</p>
								</ReactTooltip>
							)}
						</div>
					</CajaCreadoContenido>

					<CajaCreadoContenido
						link="./libros"
						parametrosBusqueda={`?sede=${data?.idSede}&curso=${data?.id}`}
						textoLink={boton_ver_todo}
					>
						<span className="onsurface-var1--color icon-libro caja-creado-icono"></span>

						<div className="w-full alineado-verticalmente">
							<p
								className={
									!data?.numeroDeLibrosAsignados
										? "q7-16-negro26"
										: "q4-16-negro26"
								}
							>
								{data?.numeroDeLibrosAsignados}{" "}
								{data?.numeroDeLibrosAsignados !== 1
									? libro?.plural
									: libro?.singular}
								{!data?.numeroDeLibrosAsignados && (
									<span
										className=" icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
										data-tip={true}
										data-scroll-hide={true}
										data-for="tool-libros"
									></span>
								)}
							</p>

							{!data?.librosAsignados && (
								<ReactTooltip
									backgroundColor="#FF3548"
									className="quicksand-400"
									id="tool-libros"
									place="top"
									textColor="#FFFFFF"
									event="mouseover mouseenter click"
									eventOff="mouseleave mouseout scroll mousewheel blur"
									effect="solid"
								>
									<p>{tool_tips?.libros || 'sin libros'}</p>
								</ReactTooltip>
							)}
						</div>
					</CajaCreadoContenido>

					<div className="center flex-col mt-5 mb-7">
						<Link
							to={{
								pathname: "/estadisticas/estadisticas-globales",
								search: `?sede=${data?.idSede}&curso=${data?.id}`,
							}}
							className="focusable-primary alineado-verticalmente"
						>
							<span className="icon-estadisticas-barra mr-5 red-inv-var2--color text-22 mt-1"></span>
							<span className="evento_rojo">
                                {estadisticas}
                                </span>
						</Link>

						{rolUsuario === "rector" && (
							<Link
								to={`./cursos/editar-curso/${data?.idSede}/${data?.id}`}
								className="focusable-primary boton-amarillo boton_hover_morado boton-justo mt-6"
							>
								{texts?.editar?.titulo || 'Editar'}
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
