import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Funciones
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
import generalCallApi from './../helpers/generalCallApi';
import listarArray from '../../utilities/function/listarElementos/listarArray';
import listarObject from '../../utilities/function/listarElementos/listarObject';
import transformarDatosProfesores from '../../utilities/function/profesores/transformarDatosProfesores';
//Componentes
import ProfesoresEditarEliminarComponent from './../../components/profesores/ProfesoresEditarEliminarComponent';
import VentanaAlerta from '../../components/globales/VentanaAlerta';
import SkeletonProfesoresEditar from '../../components/profesores/SkeletonsProfesores/SkeletonProfesoresEditar';
import PropTypes from 'prop-types';

/**
 * Este Container recibe una propiedad llamada isLoadingCursos que  permite renderizar la pantalla de editar y eliminar profesor. Las funciones principales de este Container son editar la información, obtener datos de la base de datos, listar cursos y eliminar profesor seleccionado.
 * @returns {JSX.Element} ProfesoresEditarEliminarComponent o VentanaAlerta
 */
const ProfesoresEditarEliminar = (props) => {
  const { isLoading, isLoadingProfesores } = props;

  //Estados globales
  const state = useTrackedState();
  const { datosCursos, datosProfesores, datosInstitucion } = state;
  const dispatch = useDispatch();

  //Estados del componente
  const [correo, setCorreo] = useState('');
  const [cursosAsignados, setCursosAsignados] = useState({
    ids: [],
    datos: [],
  });
  const [cursosSede, setCursosSede] = useState(null);
  const [datosProfesor, setDatosProfesor] = useState(null);
  const [existeProfesor, setExisteProfesor] = useState(null);
  const [hayCambios, setHayCambios] = useState({ cambios: false, datos: {} });
  const [nombre, setNombre] = useState('');
  const [preloader, setPreloader] = useState(false);
  const [respuestaApiEditar, setRespuestaApiEditar] = useState({
    isError: null,
    mensaje: ''
  });
  const [respuestaApiEliminar, setRespuestaApiEliminar] = useState({
    isError: null,
    mensaje: ''
  });
  const [sedes, setSedes] = useState([]);
  const [sedeSeleccionada, setSedeSeleccionada] = useState({ id: null, nombre: '' })


  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('profesores');


  //Parámetros de url
  const history = useHistory();
  const params = useParams();
  const { sede, profesor } = params;


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedes(listaSedes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    listarCursosSede();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sedeSeleccionada.id])


  //Obtener datos del profesor
  useEffect(() => {
    //Verificar si el parámetro de profesor y sede existen
    if (!!profesor && !!sede) {
      if (!!datosCursos && !!datosProfesores?.resultados) {
        //Verificar si la sede existe
        const sedeActual = { ...datosProfesores?.resultados[sede] };
        let existeParam = !!sedeActual ? true : false;

        if (!!sedeActual) {
          const profesorActual = sedeActual?.profesores?.find(profesorSede => profesorSede.id === profesor);
          existeParam = !!profesorActual ? true : false;

          //Si el api de eliminar ya se llamo y esta respondio de forma afirmativa, no se ejecuta lo que se encuentra en el siguiente condicional
          if (respuestaApiEliminar.isError !== false) {
            //Si exite el curso se guardan los datos en el estado del componente
            if (existeParam) {
              //Se el estado datosInstitucion existe en el store se obtienen los datos de ese estado
              let cursos = [];
              profesorActual.cursos.map(curso => cursos.push(curso.id));

              const infoProfesor = {
                correo: profesorActual.correo,
                cursos: cursos,
                datosCursos: profesorActual.cursos,
                id: profesorActual.id,
                idSede: profesorActual.idSede,
                nombre: profesorActual.nombre,
                nombreSede: datosProfesores.resultados[profesorActual.idSede].nombre,
                numeroCursos: cursos.length,
              }


              setCorreo(profesorActual.correo);
              setCursosAsignados({
                ids: cursos,
                datos: profesorActual.cursos,
              });
              setDatosProfesor(infoProfesor);
              setHayCambios({
                cambios: false,
                datos: {
                  correo: false,
                  cursos: false,
                  nombre: false,
                  sede: false,
                }
              })

              setNombre(profesorActual.nombre);
              setSedeSeleccionada({
                id: profesorActual.idSede !== 'sin-asignar' ? profesorActual.idSede : null,
                nombre: profesorActual.nombreSede,
              });
            }
          }
        }
        setExisteProfesor(existeParam);
      }
      return;
    }
    //Si el parámetro no existe se redirecciona a la vista en general
    history.push('/profesores');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profesor, sede, datosCursos, datosProfesores])

  /**
   * Actualiza el nombre del profesor
   * @param {string} nombre 
   */
  const actualizarNombre = ({ nombre }) => {
    const propiedades = { nombre: nombre !== datosProfesor.nombre }
    verificarCambios({ propiedades });
    setNombre(nombre)
  };

  /**
   * Actualiza el correo del profesor
   * @param {string} correo 
   */
  const actualizarCorreo = ({ correo }) => {
    const propiedades = { correo: correo !== datosProfesor.correo }
    verificarCambios({ propiedades });

    setCorreo(correo);
  }

  /**
   * Actualizar cursos asignados
   * @param {Event} e
   * @param {shape} curso
   */
  const actualizarCursosAsignados = ({ e, curso }) => {
    const datosCursos = [...cursosAsignados.datos];
    const resultados = elegirElementosCheck({ e, elemento: curso, datosActuales: datosCursos });
    const { ids, datos } = resultados;

    const propiedades = { cursos: cursosAsignados.ids.length !== datos.length }
    verificarCambios({ propiedades });
    setCursosAsignados({ ids, datos });

    setSedeSeleccionada({
      id: curso.idSede,
      nombre: !!curso.idSede ? datosProfesores.resultados[curso.idSede].nombre : ''
    });
  }

  /**
   * Actualiza la sede asignada
   * @param {string} idSede 
   */
  const actualizarSedeAsignada = ({ idSede }) => {
    if (idSede !== sedeSeleccionada.id) {
      const nombreSede = datosProfesores.resultados[idSede].nombre;
      setSedeSeleccionada({
        id: idSede,
        nombre: nombreSede
      });

      const propiedades = { sede: datosProfesor.idSede !== idSede }
      verificarCambios({ propiedades });
      setCursosAsignados({ ids: [], datos: [] });
    };
  };


  //Editar profesores
  const editarProfesor = async () => {
    if (!preloader) {
      setRespuestaApiEditar({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);

      //Parámetros para el llamado del api
      const pathname = '/profesores/editarProfesor';
      const properties = {
        correo: correo,
        cursos: cursosAsignados.ids,
        idProfesor: profesor,
        idSede: sedeSeleccionada.id,
        nombre: nombre,
      };

      //Llamar api de editar profesor
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        await obtenerDatosDB();

        if (sede !== sedeSeleccionada.id) {
          history.push(`/profesores/editar-profesor/${sedeSeleccionada.id}/${profesor}`)
        }
      }

      setRespuestaApiEditar({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    }
  }


  //Editar profesores
  const eliminarProfesor = async () => {
    if (!preloader && respuestaApiEliminar.isError !== false) {
      setRespuestaApiEliminar({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);

      //Parámetros para el llamado del api
      const pathname = "/profesores/borrarProfesor";
      const properties = { idProfesores: [profesor] }
      // const properties = { idProfesores: ['P0'] }

      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;
      if (!error) {
        setDatosProfesor({ ...datosProfesor, ...properties });
        await obtenerDatosDB();
      }

      setRespuestaApiEliminar({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    }
  }


  //Genera el listado de cursos que el usurio puede seleccionar para asignar al profesor
  const listarCursosSede = () => {
    const cursosFiltrados = listarObject({
      object: datosCursos,
      idSede: sedeSeleccionada.id,
      propiedad: 'cursos'
    })

    const resultados = {
      hayCursos: cursosFiltrados.resultados.hayResultados,
      cursos: cursosFiltrados.resultados.resultados,
    }

    setCursosSede(resultados)
  }


  //Llamada al api para obtener los datos de la base de datos
  const obtenerDatosDB = async () => {
    const result = await generalCallApi({ pathname: '/profesores/obtenerListadoProfesores' });
    let datos;

    const error = result.status === 0;
    if (!error) {
      datos = await transformarDatosProfesores({ datos: result.data, sedes: datosInstitucion.sedes });
      datos = {
        resultados: datos.resultadosModificados,
        'sin-asignar': datos.profesoresSinAsignar
      }

      // Actualizar datos en el store 
      dispatch({
        property: 'datosProfesores',
        type: 'SET_DATA',
        value: datos
      });
    }

    return { datos, error };
  }


  //Verificar cambios
  const verificarCambios = ({ propiedades }) => {
    const datos = { ...hayCambios.datos, ...propiedades };
    let cambios;

    for (const key in datos) {
      const element = datos[key];
      if (element) cambios = true;
    }


    setHayCambios({
      hayCambios: cambios,
      datos: { ...hayCambios.datos, ...propiedades }
    });
  }

  //Componente que se va a renderizar 
  const componenteRender = () => {
    if (isReady && !!datosProfesor && !isLoading && !isLoadingProfesores && cursosAsignados.hayCursos !== null) {
      return (
        <ProfesoresEditarEliminarComponent
          actualizarCorreo={actualizarCorreo}
          actualizarCursosAsignados={actualizarCursosAsignados}
          actualizarNombre={actualizarNombre}
          actualizarSedeAsignada={actualizarSedeAsignada}
          correoProfesor={correo}
          cursosAsignados={cursosAsignados}
          cursosSede={cursosSede}
          datosProfesor={datosProfesor}
          editarProfesor={editarProfesor}
          eliminarProfesor={eliminarProfesor}
          hayCambios={hayCambios.hayCambios}
          nombreProfesor={nombre}
          preloader={preloader}
          respuestaApiEditar={respuestaApiEditar}
          respuestaApiEliminar={respuestaApiEliminar}
          sedeSeleccionada={sedeSeleccionada}
          sedes={sedes}
          textosInterfaz={textosInterfaz}
        />
      )
    }
    if (isReady && existeProfesor === false && respuestaApiEliminar.isError !== false) {
      return (
        <VentanaAlerta
          imagen={{
            alt: 'blop con un icono de alerta',
            img: 'alertas/blop-alerta.png',
          }}
          link='/profesores'
          textosInterfaz={{
            descripcion: textosInterfaz.no_existe_profesor.descripcion,
            textoBoton: textosInterfaz.botones.volver,
            titulo: textosInterfaz.no_existe_profesor.titulo,
          }}
        >
          <Link
            className="boton-justo mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado"
            to='/profesores'
          >
            {textosInterfaz.botones.volver.principal}
          </Link>
        </VentanaAlerta>
      )
    } else {
      return (
        <SkeletonProfesoresEditar />
      )
    }
  }

  return (
    <>
      {componenteRender()}
    </>
  )
}
export default ProfesoresEditarEliminar;

ProfesoresEditarEliminar.propTypes = {
  /**
   *  Booleano que valida si ha cargado la información.
   */
  isLoading: PropTypes.bool,
  /**
  *  Booleano que valida si ha cargado la información de profesores.
  */
  isLoadingProfesores: PropTypes.bool,
}