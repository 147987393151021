import { useEffect, useState } from "react";
import MicroModal from "micromodal";

import ModalRecomendar from "./ModalRecomendar";
import { Icon } from '@iconify/react';
import { useTrackedState } from "../../../store";
import PropTypes from 'prop-types';

/**
 * Componente que permite realizar las acciones de: mostrar/ocultar, recomendar y agregar/quitar favorito a uno o varios libros.
 */
const MultiselectBar = (props) => {
  const { multiselectState, selectFunctions, itemsActivos, textosInterfaz, cursosData, modoEscogerProps } = props;

  const { deactivateMultiselect, selectItems, returnLibroActionForMultiselect } = selectFunctions;

  const { multi_select, modal_asignar, botones } = textosInterfaz;

  const { active, idLibros, librosToSelect } = multiselectState;
  const state = useTrackedState();
  const [eligiendoLibros, setEligiendoLibros] = useState(false);
  const [marcadoFavoritoMultiSelecccion, setMarcadoFavoritoMultiSeleccicon] = useState(false);

  useEffect(() => {
    setEligiendoLibros(state?.planActivo?.eligiendoLibros || false);
  }, [state])

  useEffect(() => {
    /**
     * Muestra en el multiselectbar el estado actual de la selección de favoritos, si debe marcar o desmarcar.
     */
    const actualizarEstadoFavoritosMultiSeleccion = () => {
      let favoritoMarcado = false;
      for (let i = 0; i < librosToSelect.length; i++) {
        const libro = librosToSelect[i];
        if (Boolean(libro?.favorito)) {
          favoritoMarcado = true;
          break;
        }
      }
      /*   console.log(favoritoMarcado, librosToSelect) */
      setMarcadoFavoritoMultiSeleccicon(favoritoMarcado);
    }
    actualizarEstadoFavoritosMultiSeleccion();
  }, [librosToSelect])

  const libroAccion = () => {
    let libros = librosToSelect;
    const ocultarMostrar = returnLibroActionForMultiselect({ libros }) === 'Ocultar' ? multi_select.ocultar : multi_select.mostrar;
    return ocultarMostrar
  }

  const iconMostrar = () => {
    let libros = librosToSelect;
    const iconoMostrarOcultar = returnLibroActionForMultiselect({ libros }) === 'Ocultar' ? 'icon-no-ver' : 'icon-ver';
    return iconoMostrarOcultar
  }

  return (
    <>
      <div className={`fixed left-0 primary-var2--bg w-full py-4 transicion-200  ${active ? `animate-slideIn -top-0` : ` animate-slideOut -top-72`}`} style={{zIndex:'var(--zindex-multiSelector)'}}>
        <div className='lg:flex'>
          <div className="lg:w-4/12  alineado-verticalmente pl-5 xl:pl-10">
            <button onClick={() => { deactivateMultiselect() }} className='focusable-red onprimary-var1--color text-20 mr-3 hover:text-amarillo'>
              <span className='icon-cerrar'></span>
            </button>
            <p className='q7-20-blanco'>
              {idLibros.length} {idLibros.length !== 1 ? multi_select.seleccionados.plural : multi_select.seleccionados.singular}
            </p>
          </div>

          <div className="lg:w-8/12  md:alineado-verticalmente lg:alineado-izquierda-center gap-10 px-5 mt-5 lg:mt-0">

            {
              eligiendoLibros &&
              <>
                <button
                  className={`focusable-red quicksand-700 text-17.6 center pt-3 md:pt-0  onprimary-var1--color hover:text-amarillo`}
                  onClick={() => modoEscogerProps?.alAgregarASeleccion && modoEscogerProps?.alAgregarASeleccion(multiselectState?.librosToSelect || [])}>
                  <Icon icon="carbon:add-filled" className="text-26 mx-2" />
                  {multi_select?.libros?.seleccion?.agregar || 'Agregar'}
                </button>
                <button
                  className={`focusable-red quicksand-700 text-17.6 center pt-3 md:pt-0  onprimary-var1--color hover:text-amarillo`}
                  onClick={() => modoEscogerProps?.alRemoverSeleccion && modoEscogerProps?.alRemoverSeleccion(multiselectState?.librosToSelect || [])}>
                  <Icon icon="ep:remove-filled" className="text-26 mx-2" />
                  {multi_select?.libros?.seleccion?.quitar || 'Quitar'}
                </button>
              </>
            }

            {
              !eligiendoLibros &&
              <>
                {/* MOSTRAR / OCULTAR LIBRO */}
                <button
                  className={`focusable-red md:w-4/12 lg:w-auto quicksand-700 text-17.6 center pt-3 md:pt-0 onprimary-var1--color hover:text-amarillo`}
                  onClick={() => {
                    let libros = librosToSelect;
                    let property = 'idCursos';
                    let newValue = [itemsActivos.curso];
                    let multiselect = true;
                    selectItems({ libros, property, newValue, multiselect })
                    deactivateMultiselect();
                  }}
                >
                  <span className={`${iconMostrar()} text-28 mr-1 lg:hidden xl:block`}></span>
                  {libroAccion()}
                </button>

                {/* RECOMENDAR LIBRO */}
                <button
                  className={`focusable-red md:w-4/12 lg:w-auto quicksand-700 text-17.6 center pt-3 md:pt-0 onprimary-var1--color hover:text-amarillo border-blanco hover:border-amarillo`}
                  onClick={() => {
                    MicroModal.show('modal-recomendar', {
                      awaitCloseAnimation: true
                    });
                  }}
                >
                  <div className={`w-6 h-6 rounded-full border-2 center mr-1 bg-transparent`}>
                    <span className={`onprimary-var1--color icon-check text-14`}></span>
                  </div>
                  {multi_select?.recomendar.agregar || 'recomendar'}
                </button>

                {/* MARCAR COMO FAVORITO / REMOVER FAVORITO */}
                <button
                  className={`focusable-red md:w-4/12 lg:w-auto quicksand-700 text-17.6 center pt-3 md:pt-0 onprimary-var1--color hover:text-amarillo`}
                  onClick={() => {
                    let libros = librosToSelect;
                    let newValue = !marcadoFavoritoMultiSelecccion ? true: false;
                    let property = 'favorito';
                    selectItems({ newValue, libros, property })
                    deactivateMultiselect();
                  }}
                >
                  <Icon icon={`${marcadoFavoritoMultiSelecccion === false ? 'ic:outline-favorite-border' : 'ic:outline-favorite'}`} className={'text-24 inline-flex mr-1'} />
                  {marcadoFavoritoMultiSelecccion === false ? `${multi_select?.favorito.agregar || 'Marcar favorito'}` : `${multi_select?.favorito.remover || 'Remover favorito'}`}
                  {/* {marcadoFavoritoMultiSelecccion?'':''multi_select.favorito} */}
                </button>
              </>
            }
          </div>
        </div>

      </div>

      {/* VENTANAS MODALES */}

      {
        eligiendoLibros === false &&
        <>
          <ModalRecomendar
            {...props}

            libros={librosToSelect}
            sede={itemsActivos?.sede || ''}
            curso={itemsActivos?.curso || ''}
            alActualizarRecomendaciones={() => deactivateMultiselect()}

            estadoAsignacion={returnLibroActionForMultiselect({ libros: librosToSelect })}
            modal_asignar={modal_asignar}
            botones={botones}
            cursosData={cursosData}
          />

        </>
      }


    </>
  )
}

export default MultiselectBar;

MultiselectBar.propTypes = {
  /**
   * Objeto con los libros seleccionados y el estado 'active' que defiene si el multiselector está activo o no. 
   */
  multiselectState:PropTypes.shape({
    librosToSelect: PropTypes.arrayOf(PropTypes.object),
    idLibros: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.bool,
  }), 
  selectFunctions:PropTypes.object, 
    /**
     * Objeto con el id del curso y sede actualmente seleccionados en la sección de libros.
     */
     itemsActivos: PropTypes.shape({
      curso:PropTypes.string,
      sede:PropTypes.string
  }),
  /**
   * Objeto con los textos traducidos del componente.
   */
  textosInterfaz:PropTypes.object,

  /**
   * Objeto con las sedes disponibles del instituto y en su interior un arreglo de cursos.
   */
  cursosData:PropTypes.object, 
  /**
   * Objeto con funciones que se ejecutan cuando se selecciona o deselecciona libros.
   Esta propiedad solo se llama si se esta en modo de selección de libros.
  */
  modoEscogerProps:PropTypes.shape({
    alAgregarASeleccion: PropTypes.func,
    alRemoverSeleccion: PropTypes.func,
  }) 
}