import { forwardRef, useState } from 'react';
//Hooks y funciones personalizadas
import useObtenerTraducciones from '../helpers/hooks/useObtenerTraducciones';
import useObtenerPaises from './../helpers/hooks/useObtenerPaises';
//Componentes
import FooterComponent from './../../components/footer/index';

/**
 * Componente de tipo container que pasa su ref a APP.js y no recibe props. También renderiza el componente FooterComponent, al cual se le pasan una serie de props para visualizar el pie de pagina. Su principal función es cambiar el país y obtener textos de interfaz. 
 *  @returns  {JSX.Element} FooterComponent
 */
const Footer = forwardRef((props, ref) => {
  //Variables
  const makemakeData = JSON.parse(window.localStorage.getItem('makemakeData'));
  const pais = (makemakeData) ? makemakeData.country : null;
  //Hooks personalizados
  const { isReady, textosSeccion: textosInterfaz } = useObtenerTraducciones('footer_interfaz');
  const { listaPaises, textoPaises } = useObtenerPaises('footer_paises')
  //Estados del componente
  const [paisActivo, setPaisActivo] = useState(pais || 'CO')

  /**
   * cambia el pais seleccionado
   * @param {string} cod 
   */
  const cambiarPais = (cod) => setPaisActivo(cod);

  return (
    <>
      {isReady &&
        <footer ref={ref}>
          <FooterComponent
            cambiarPais={cambiarPais}
            infoPais={textoPaises}
            listaPaises={listaPaises}
            paisActivo={paisActivo}
            textosInterfaz={textosInterfaz}
          />
        </footer>
      }
    </>
  )
})

export default Footer;