import { useContext, useEffect, useState } from "react";
import ReactTooltip from 'react-tooltip';
import PropTypes from "prop-types";
import {AccesibilidadContext, TemasDisponibles} from '../../../contexts/AccesibilidadContext'

/**
 * Componente que renderiza la información perteneciente a la sección 'Más detalles' en la ficha de un libro.
 */
const Detalles = (props) => {
  const { book, textosInterfaz } = props;
  const { codigoBISAC, title, author, isbn, idioma, idioma2, valores, descripcionDba } = book;

  const { ficha } = textosInterfaz;
  const { info_basica, mas_detalles } = ficha;

  const [tags, setTags] = useState([]);
  const [dba, setDba] = useState([]);
  const acc = useContext(AccesibilidadContext);

  useEffect(() => {
    let arregloDba = [];
    Object.keys(book).map((item) => { return item.includes('dba') && arregloDba.push(item) })
    setTags(["tag1","tag2","tag3"]);
    setDba(arregloDba);
  }, [book])


  return (
    <>
      <h1 className="q7-28-negro4d">{title}</h1>


      <div className="md:flex gap-4 mt-5">
        <div className="md:w-4/12">
          <p className="r7-18-gris97 uppercase ">{info_basica.autor}</p>
        </div>
        <div className="md:w-8/12">
          <p className="q7-18-negro4d border-b-3 leading-6 secondary-inv-var1--borderb-color inline-block ">{author}</p>
        </div>
      </div>

      <div className="md:flex gap-4 mt-5">
        <div className="md:w-4/12">
          <p className="r7-18-gris97 uppercase ">{mas_detalles.tema}</p>
        </div>
        <div className="md:w-8/12">
          {
            tags.map((item) => {
              return <p key={item} className="q7-18-negro4d">{book[item]}</p>
            })
          }
        </div>
      </div>

      <div className="md:flex gap-4  mt-5">
        <div className="md:w-4/12">
          <p className="r7-18-gris97 uppercase ">
            {mas_detalles.codigo}
            <span
            className="text-24 ml-3 inline-block"
            data-tip={true}
            data-scroll-hide={true}
            data-for='que-es-codigo-bisac'
          >
            <div className="w-7 h-7 surface-var2--bg onprimary-var1--color cursor-pointer hover:bg-morado center rounded-full">?</div>
          </span>
            </p>
        </div>
        <ReactTooltip
            backgroundColor={acc?.temaActual === TemasDisponibles.clasico?.id?"#000":"var(--background-var1)"}
            textColor={acc?.temaActual === TemasDisponibles.clasico?.id?"#fff":"var(--onbackground-var1)"}
            className="quicksand-400"
            id='que-es-codigo-bisac'
            place="top"
            event="mouseover mouseenter click"
            eventOff="mouseleave mouseout scroll mousewheel blur"
            effect="solid"
          >
            <p className="w-72">{mas_detalles?.bisac?.tooltip || "BISAC (Book Industry Standards and Communications) es un código estandarizado para la transferencia electrónica de información de materias. Los títulos de asignaturas de BISAC describen el contenido temático de un libro diferenciado por materia, rango de edad y género"}</p>
          </ReactTooltip>
        <div className="md:w-8/12">
          <p className="q7-18-negro4d  ">{codigoBISAC || 'N/A'}</p>
        </div>
      </div>

      {/* <div className="md:flex gap-4 mt-5">
        <div className="md:w-4/12">
          <p className="r7-18-gris97 uppercase ">{mas_detalles.isbn}</p>
        </div>
        <div className="md:w-8/12">
          <p className="q7-18-negro4d">{isbn}</p>
        </div>
      </div> */}

      <div className="md:flex gap-4 mt-5">
        <div className="md:w-4/12">
          <p className="r7-18-gris97 uppercase ">{mas_detalles.idioma}</p>
        </div>
        <div className="md:w-8/12">
          <p className="q7-18-negro4d ">{idioma} {idioma2 !== null && `/ ${idioma2}`}</p>
        </div>
      </div>

      <div className="md:flex gap-4 mt-5">
        <div className="md:w-4/12 ">
          <p className="r7-18-gris97 uppercase ">{mas_detalles.valores}</p>
        </div>
        <div className="md:w-8/12">
          <p className="q7-18-negro4d ">{valores}</p>
        </div>
      </div>

      <div className=" mt-8 py-1 border-b-2 onsurface-var2--borderb-color">
        <div className="w-100 flex items-center">
        <h2 className="q7-28-negro4d">
          {mas_detalles.competencias}
        </h2>
        <span
            className="text-24 ml-3 inline-block r7-18-gris97 uppercase"
            data-tip={true}
            data-scroll-hide={true}
            data-for='tool-competecias'
          >
            <div className="w-7 h-7 surface-var2--bg onprimary-var1--color cursor-pointer hover:bg-morado center rounded-full">?</div>
          </span>
          <ReactTooltip
            backgroundColor={acc?.temaActual === TemasDisponibles.clasico?.id?"#000":"var(--background-var1)"}
            textColor={acc?.temaActual === TemasDisponibles.clasico?.id?"#fff":"var(--onbackground-var1)"}
            className="quicksand-400"
            id="tool-competecias"
            place="top"
            event="mouseover mouseenter click"
            eventOff="mouseleave mouseout scroll mousewheel blur"
            effect="solid"
          >
            <p className="w-72">{mas_detalles.tooltip}</p>
          </ReactTooltip>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-4 lg:center lg:p-3">

          <div>
            <img src='/images/educacion.png' alt="libro y lapiz" className="lg:mx-auto my-3" />
            {
              dba.map((item,i) => {
                if(item !== 'dba1'){
                  return <p key={item} className="r7-16-negro26">{book[item]}{i< dba.length-1?',':''}</p>
                }
              })
            }
          </div>

        </div>
        {
          descripcionDba &&
          <p className="col-span-12 lg:col-span-8 lg:alineado-verticalmente r7-16-negro26">{descripcionDba}</p>
        }

        {/* <div className="col-span-12 lg:col-span-8  lg:center lg:p-5  mb-5 lg:mb-0">
          <p  className="r7-16-negro26">
            {
              textLink.map((item) => {
                return <span key={item}>{book[item]}, </span>
              })
            }
          </p>

        </div> */}
      </div>

    </>
  )
}
export default Detalles;

Detalles.propTypes = {
  /**
   * Objeto con la información del libro 
   */
  book: PropTypes.object.isRequired,
  /**
   * Objeto con los textos de traducciones que se mostraran en el UI.
   */
  textosInterfaz: PropTypes.object.isRequired,
}