import { useState, useEffect } from "react";
import MicroModal from "micromodal";

/**componente */
import Modal from "../../../globales/modales/Modal";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";

/**
Componente modal que se despliega, muestra los estudiantes de un curso y si tienen o no un libro recomendado, también permite editar el estado de la recomendación de todos y cada uno de los estudiantes.
 */
export const ModalCursoEspecifico = (props) => {
	// const idModal = 'modal-recomendar-a-curso=especifico-ficha-libro';
	const { setVisible, idModal, currentClass, recommendedManager } = props;
	const { textosSubSecciones: traducciones } = useObtenerSubsecciones("libros");
	const classId = currentClass?.id;
	const [students, setStudents] = useState([]);
	const opcionTodos = {
		id: "todos",
		nombre: traducciones?.ficha?.recomendar?.opciones?.recomendar_todos || "Recomendar a todos",
	};
	const [cargando, setCargando] = useState({
		estudiantes: false,
		guardando: false,
	});

	const cerrarModal = () => {
		MicroModal.close(idModal);
	};

	const update = async () => {
		setCargando({ ...cargando, guardando: true });
		await recommendedManager.removeAllStudents({ classId });
		const recommendedStudentsIds = students
			.filter((student) => student?.recommended)
			.map((student) => student?.codigo);
		await recommendedManager
			.addStudents({
				classId,
				ids: recommendedStudentsIds,
			})
			.then((resultado) => {
				setVisible(false);
				cerrarModal();
				setCargando({ ...cargando, guardando: false });
			})
			.catch((error) => console.log(error));
	};

	const updateStudent = ({ studentId, recommended }) => {
		setStudents((students) =>
			students.map((student) => {
				if (studentId === "todos") {
					return {
						...student,
						recommended,
					};
				}
				if (studentId === student.id) {
					return {
						...student,
						recommended,
					};
				}
				return student;
			})
		);
	};

	useEffect(() => {
		setStudents(recommendedManager.getClassStudents({ classId }));
	}, [classId, recommendedManager.allStudents]);

	return (
		<>
			<Modal
				nombreModal={idModal}
				alCerrarModal={() => {
					setVisible(false);
					cerrarModal();
				}}
			>
				<div className="pb-3">
					<h1 className="quicksand-700 onbackground-var2--color text-20 text-center ">{`${
						traducciones?.ficha?.recomendar?.modal?.titulo || "Recomendar este libro a"
					}`}</h1>
					<h1 className="w-full p-2 blue-inv-var1--bg m-1 text-center background-var3--color font-raleway font-bold">
						{currentClass?.nombre || "N/A"}
					</h1>
					<div
						className="background-var3--bg scroll mt-5 h-52"
						style={{ minHeight: 110 }}
					>
						{cargando.estudiantes == false ? (
							<>
								{students?.length > 0 ? (
									<ul>
										{[opcionTodos, ...students].map((student) => {
											const { nombre, id } = student;
											return (
												<li
													key={id}
													className="px-3 py-3 alineado-verticalmente cursor-pointer hover:primary-inv-var5--bg"
												>
													<label
														className=" w-full h-7 relative mr-3 cursor-pointer"
														role="checkbox"
													>
														<input
															type="checkbox"
															checked={student.recommended}
															onChange={(e) =>
																updateStudent({
																	studentId: id,
																	recommended: e.target.checked,
																})
															}
														/>
														<i className="check"></i>
														<p className="raleway-400 onbackground-var2--color absolute left-10 top-0.5">
															{nombre}
														</p>
													</label>
												</li>
											);
										})}
									</ul>
								) : (
									<div className="min-w-full w-full h-full min-h-full flex center">
										<h1 className="onbackground-var2--color font-bold font-quicksand text-center">
											{`${
												traducciones?.ficha?.recomendar?.modal
													?.sin_estudiantes ||
												"No hay estudiantes en este curso."
											}`}
										</h1>
									</div>
								)}
							</>
						) : (
							<div className="flex center min-w-full min-h-full">
								<Icon
									icon="fa:spinner"
									className="primary-inv-var2--color text-28 animate-spin"
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex center w-full">
					<button
						aria-label="Actualizar"
						disabled={students.length <= 0 ? true : false}
						className="focusable-red boton-justo text-16 boton-amarillo boton_hover_morado alineado-verticalmente  flex  justify-between text-center"
						onClick={() => update()}
					>
						{cargando.guardando == true && (
							<Icon icon={`fa:spinner`} className={"text-22 animate-spin mx-1"} />
						)}
						{traducciones?.ficha?.recomendar.modal?.actualizar || "Actualizar"}
					</button>
				</div>
			</Modal>
		</>
	);
};

ModalCursoEspecifico.propTypes = {
	/**
	 * propiedad booleana que defiene si el modal debe ser visible o no
	 */
	visible: PropTypes.bool,
	/**
	 * Función que modifica el estado de visibilidad del modal, recibe un parametro booleano.
	 */
	setVisible: PropTypes.func,
	/**
	 * instancia del hook del manejador de libros recomendados
	 */
	recommendedManager: PropTypes.object,
	/**
	 * id del modal
	 */
	idModal: PropTypes.string,
};
