import { useState, useRef, useEffect } from 'react';
import useElementoExterior from '../../../containers/helpers/hooks/useElementoExterior';
import PropTypes from 'prop-types';

/**
 * Componente que recibe una serie de props necesarios para renderizar el selector que se encuentra en formularios o en la pantalla inicial de las secciones de plan lector. 
 * @returns Selector
 */
const Selector = (props) => {
  const { zIndex, asincrona, cambiarElegido, opciones, textoDefault, textoElegido, estiloTipoInput, desactivado, estadoEnviar, valido, textoError, superposicion, textoNoHayOpciones, subposicion } = props
  const [alturaOpciones, setAlturaOpciones] = useState("0px");
  const [activadorSelect, setActivadorSelect] = useState(false);
  const contenedorOpciones = useRef(null);
  const selector = useRef(null);
  const comboboxRef = useRef(null);

  useEffect(() => {
    if (!!contenedorOpciones.current) {
      setAlturaOpciones(contenedorOpciones.current.scrollHeight);
    }
  }, [textoElegido, opciones, textoDefault])

  const cerrarOpcionesSelect = () => setActivadorSelect(false);

  useElementoExterior(cerrarOpcionesSelect, selector);

  return (
    <>
      <div className={`selector ${(!!superposicion && !!zIndex === false) && 'z-50'} ${(!!subposicion && !!zIndex === false) && 'z-40'}`} ref={selector} style={{ zIndex: zIndex && zIndex }}>
        <button
          role={"combobox"}
          aria-label={textoDefault ||'Selector'}
          aria-haspopup="listbox"
          aria-expanded={activadorSelect}
          ref={comboboxRef}
          aria-controls='selector-list'
          className={`focusable-primary ${desactivado && 'input_disabled'}
            ${estiloTipoInput ? estadoEnviar ? valido ? 'selector-elegido-input' : 'selector-elegido-input-error' : 'selector-elegido-input' : 'selector-elegido'} 
            ${activadorSelect ? 'rounded-t-md' : 'rounded-md'} 
          `}
          onClick={() => { setActivadorSelect(!activadorSelect) }}
        >
          <p className="una-linea-texto"> {textoElegido === '' ? textoDefault : textoElegido}</p>
          <span className={`icon-select  ${activadorSelect ? "" : "transform rotate-180"} transicion-500 text-20`}></span>
        </button>

        <div
          className={`selector-opciones  max-h-80 scroll`}
          ref={contenedorOpciones}
          style={{ height: activadorSelect ? `${alturaOpciones}px ` : '0px', opacity:activadorSelect?1:0 }}
        >
          <ul 
            id={"selector-list"} 
            role={"listbox"} aria-expanded={activadorSelect} 
            aria-multiselectable={false}
            className={`list ${!activadorSelect&&'invisible'}`} >
            {textoElegido !== textoDefault &&
              <li role={"presentation"} className="px-3 py-2 border-b animate-fadeIn primary-inv-var2--color q7-18">{textoDefault}</li>
            }
            {opciones.length !== 0 ?
              opciones.map((opcion, index) => {
                return (
                  <li
                    key={`select-${index}`}
                    className="item focusable-primary px-3 py-2"
                  >
                    <button
                      role="option"
                      aria-selected={textoElegido===opcion?.nombre}
                      onClick={async () => {
                        if(comboboxRef.current){comboboxRef.current.focus()}
                        asincrona === true
                          ? await cambiarElegido({ opcion, index })
                          : cambiarElegido({ opcion, index })
                        setActivadorSelect(false);
                      }} className="w-full text-left focusable-primary text-16 raleway-400">{opcion.nombre}</button>
                  </li>
                )
              })
              :
              <>
                <li role={"presentation"}  className="px-3 py-2 cursor-pointer">
                  <p className="text-16 raleway-400 onbackground-var1--color">{textoNoHayOpciones}</p>
                </li>
              </>
            }
          </ul>
        </div>
      </div>


      {estiloTipoInput &&
        <p role="alert" aria-live="assertive" className={`text-14 ${estadoEnviar ? valido ? 'hidden' : 'alert_error' : 'hidden'}`}>{textoError} </p>
      }

    </>
  )
}
export default Selector;

Selector.propTypes = {
  /**
   * número que indica la posición de z-index que este campo debe tener
   */
  zIndex: PropTypes.number,
  /***
   * booleano que condiciona la función de cambiar elegido.
   */
  asincrona: PropTypes.bool,
  /**
   * Función que cambia la opción elegida
   */
  cambiarElegido: PropTypes.func,
  /**
   * array de objetos que contiene la información de cada opción
   */
  opciones: PropTypes.array,
  /** 
   * string que corresponde al texto inicial
  */
  textoDefault: PropTypes.string,
  /**
   * string de la opción elegido
   */
  textoElegido: PropTypes.string,
  /**
   * booleano que indica si tiene el estilo morado o blanco
   */
  estiloTipoInput: PropTypes.bool,
  /**
   * Booleano que activa o desactiva el select
   */
  desactivado: PropTypes.bool,
  /**
   * booleano que indica si se deben mostrar el error o una advertencia
   */
  estadoEnviar: PropTypes.bool,
  /**
   * booleano que valida si el campo fue diligenciado
   */
  valido: PropTypes.bool,
  /**
   * string que corresponde al texto de error
   */
  textoError: PropTypes.string,
  /**
   * booleano que indica si el select debe sobreponerse a otro select
   */
  superposicion: PropTypes.bool,
  /**
   * texto que indica que no existen opciones dentro del select
   */
  textoNoHayOpciones: PropTypes.string,
  /**
   * booleano que indica que el select se ubicará debajo de otro select
   */
  subposicion: PropTypes.bool
}