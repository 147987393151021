import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const ROLES = {
  estudiante: "estudiante",
  profesor: "profesor",
};

/**
 * Componente que recibe una serie de propiedades que permiten renderizar los botones de elegir rol dentro de las pantallas de estadísticas.
 * @returns SelectorRol
 */
export const SelectorRol = (props) => {
  const { alSeleccionarRol, rolPorDefecto, textos } = props;
  const [rolSeleccionado, setRolSeleccionado] = useState(ROLES.estudiante);

  const cambiarRol = (rol) => {
    setRolSeleccionado(rol);
    alSeleccionarRol && alSeleccionarRol(rol);
  };

  useEffect(() => {
    const roles = Object.values(ROLES);
    roles?.includes(rolPorDefecto)
      ? setRolSeleccionado(rolPorDefecto)
      : setRolSeleccionado(ROLES.estudiante);
  }, [rolPorDefecto]);

  return (
    <section>
      {Object.values(ROLES).map((rol) => {
        return (
          <button
            role="tab"
            aria-pressed={rolSeleccionado===rol}
            className={`focusable-primary selector-rol font-raleway p-2 mr-1 rounded-md font-bold ${
              rol === rolSeleccionado
                ? "active"
                : ""
            }`}
            onClick={() => cambiarRol(rol)}
          >
            {`${textos?.[rol] || rol}`}
          </button>
        ); 
      })}
    </section>
  );
};

SelectorRol.propTypes = {
  alSeleccionarRol: PropTypes.func,
  rolPorDefecto: PropTypes.oneOf(["profesor", "estudiante"]),
  textos: PropTypes.shape({
    profesor: PropTypes.string,
    estudiante: PropTypes.string,
  }),
};
