import PropTypes from 'prop-types';
/**
 * Este componente suele usarse para las restricciones que tiene el usuario, por ejemplo: Cuando no puede crear más sedes, cuando la sede que busca no existe, entre otros casos.
 */
const VentanaAlerta = (props) => {
  const { children, textosInterfaz, imagen } = props;
  const { titulo, descripcion } = textosInterfaz;
  const { alt, img } = imagen;
  return (
    <section className="px-5 h-full center">
      <div className="text-center flex flex-col ">
        <div className="mt-5 lg:mt-0 lg:order-2 flex flex-col">
          <h1 className="raleway-700 onbackground-var2--color text-28">{titulo}</h1>
          <p className="r5-20-negro26 lg:w-8/12 mx-auto mb-5">{descripcion}</p>
        </div>

        <img
          alt={alt}
          className="sm:w-1/2 mx-auto mb-5 lg:order-1"
          src={`/images/${img}`}
        />

        {children}
      </div>
    </section>
  )
}
export default VentanaAlerta;

VentanaAlerta.propTypes = {
  /**
  * array de elementos 
  */
  children: PropTypes.array.isRequired,
  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object,
  /**
   * objeto que contiene la información de la imagen
   */
   imagen: PropTypes.shape({
    alt: PropTypes.string,
    img: PropTypes.string
   })
}