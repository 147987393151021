
//Hooks y funciones personalizadas
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';

//componente
import DetallePorDiaComponent from '../../components/estadisticas/DetalleDia/DetallePorDia';

const DetallePorDia = (props) => {
  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
    <>
      {isReady &&
        <DetallePorDiaComponent traducciones={textosInterfaz} />
      }
    </>
  )
}

export default DetallePorDia;