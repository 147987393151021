import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Funciones
import generalCallApi from './../helpers/generalCallApi';
import listarArray from './../../utilities/function/listarElementos/listarArray';
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Componentes
import ProfesoresVerificarComponent from '../../components/profesores/ProfesoresVerificarComponent';
import listarObject from './../../utilities/function/listarElementos/listarObject';
import transformarDatosProfesores from './../../utilities/function/profesores/transformarDatosProfesores';
import PropTypes from 'prop-types';

/**
 * Container que recibe como prop la función de descartarCambios e isLoading. Este container renderiza el componente ProfesoresVerificarComponent al cual se le pasan una serie de propiedades que muestran la pantalla de verificar campos. Las funciones principales de este container son acciones del multiselect (asignar colegio, curso y eliminar profesor), reiniciar los estados del MultiSelect y descargar datos.
 * @returns {JSX.Element} ProfesoresVerificarComponent
 */
const ProfesoresVerificar = (props) => {
  const { descartarCambios, isLoading } = props;

  //Estados globales 
  const state = useTrackedState();
  const { datosCursos, datosInstitucion, datosProfesores, temporal } = state;
  const dispatch = useDispatch();


  //Estados del componente
  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [cursoSeleccionada, setCursoSeleccionada] = useState({
    id: null,
    nombre: ''
  })
  const [datosProfesoresNuevos, setDatosProfesoresNuevos] = useState(null);
  const [modoVista, setModoVista] = useState(null);
  const [preloader, setPreloader] = useState(false);
  const [profesoresSeleccionados, setProferosSeleccionados] = useState({
    keys: [],
    numerosProfesores: 0,
    profesores: []
  });
  const [primerMultiselect, setPrimerMultiSelect] = useState(false);
  const [respuestaApiCrear, setRespuestaApiCrear] = useState({
    isError: null,
    mensaje: ''
  });
  const [sedes, setSedes] = useState(false);
  const [sedeSeleccionada, setSedeSeleccionada] = useState({
    id: null,
    nombre: ''
  })
  const [sedesProfesores, setSedesProfesores] = useState({
    sede: '',
    numeroSedes: null
  })

  const history = useHistory();

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('profesores');


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes });
    setSedes(listaSedes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: sedeSeleccionada.id })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, sedeSeleccionada.id])

  useEffect(() => {
    if (!!temporal?.profesores) {
      const datosTemporales = { ...temporal.profesores.datos };
      const modoCrear = temporal.profesores.modoActivoCrear;
      let sedesProfesoresTemporales = []

      let profesores = []

      for (const key in datosTemporales) {
        let profesor = datosTemporales[key];
        if (modoCrear === 'masivo') {
          let cursos = []
          profesor.datosCursos.length !== 0 && profesor.datosCursos.map(curso => cursos.push(curso.nombre))
          sedesProfesoresTemporales.push(profesor.nombreSede)
          profesor = {
            ...profesor,
            id: profesor.key,
            checked: false,
            nombreCursos: cursos.join(', ')
          }
        }
        profesores.push(profesor);
      }


      sedesProfesoresTemporales = [...new Set(sedesProfesoresTemporales)]
      setSedesProfesores({
        numeroSedes: sedesProfesoresTemporales.length,
        sede: sedesProfesoresTemporales.join(", ")
      })

      const datosNuevos = modoCrear === 'individual' ? profesores[0] : profesores
      setDatosProfesoresNuevos(datosNuevos)
      setModoVista(modoCrear);
    } else {
      if (respuestaApiCrear.isError === null) {
        history.push('/profesores');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal])

  useEffect(() => {
    if (respuestaApiCrear.isError === false) {
      dispatch({
        type: "SET_DATA",
        property: "temporal",
        value: {}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiCrear.isError]);


  /**
   * Se cambia el curso por el que se desea filtrar
   * @param {string} idSede 
   * @param {string} idCurso 
   */
  const cambiarCurso = ({ idSede, idCurso }) => {
    const sede = !!idSede ? idSede : null;
    const curso = !!idCurso ? idCurso : null;

    const sedeDB = datosInstitucion.sedes.find(sede => sede.id === idSede);
    const cursoDB = datosCursos[sede].cursos.find(curso => curso.id === idCurso);
    setCursoSeleccionada({
      id: curso,
      nombre: cursoDB.nombre
    })

    setSedeSeleccionada({
      id: idSede,
      nombre: sedeDB.nombre
    })
    setCursosSede(listarCursos({ idSede: sede }).resultados)
  }

  //Actualizar sede seleccioanda por el multiselect
  const actualizarSedeAsignada = ({ idSede }) => {
    if (idSede !== sedeSeleccionada.id) {
      const nombreSede = datosProfesores.resultados[idSede].nombre;
      setSedeSeleccionada({
        id: idSede,
        nombre: nombreSede
      });
      setCursoSeleccionada({ id: null, nombre: '' })
    };
  };

  /**
   *  función que se encarga de almacenar los profesores seleccionados con el check.
   * @param {Event} e -  evento para rectificar el evento checked del profesor.
   * @param {string} idSede - id de la sede a la que pertenece el profesor
   * @param {shape} profesor -  objeto con la información del profesor (id y nombre).
   */
  const cambiarProfesoresElegidos = ({ e, idSede, profesor }) => {
    let profesores = [...profesoresSeleccionados.profesores];

    const resultados = elegirElementosCheck({ e, elemento: profesor, datosActuales: profesores })
    const { ids, datos } = resultados;


    const profesoresSeleccionadosNuevos = {
      keys: ids,
      numerosProfesores: datos.length,
      profesores: datos
    }

    let multiSelect = datos.length !== 0;
    let instanceDatos = [...datosProfesoresNuevos];

    instanceDatos.forEach((elemento, index) => instanceDatos[index] = { ...elemento, checked: false })

    ids.forEach(id => {
      instanceDatos.forEach((instanciaProfesor, index) => {
        if (instanciaProfesor.id === id) {
          instanceDatos[index] = { ...instanciaProfesor, checked: true }
        };

        return instanciaProfesor;
      });
    })

    setDatosProfesoresNuevos(instanceDatos);
    setProferosSeleccionados(profesoresSeleccionadosNuevos)
    setPrimerMultiSelect(multiSelect)
  }

  const cancelarAccionMultiSelect = () => {
    const datos = [...datosProfesoresNuevos];

    datos.map(profesor => profesor.checked = false);

    setDatosProfesoresNuevos(datos);

    setProferosSeleccionados({
      keys: [],
      numerosProfesores: 0,
      profesores: []
    })

    setPrimerMultiSelect(false);

    setSedeSeleccionada({
      id: null,
      nombre: ''
    })
  }

  //Funcionalidad crear profesores
  const crearProfesores = async () => {
    if (!preloader && !respuestaApiCrear.isError !== false) {
      setPreloader(true);
      setRespuestaApiCrear({
        isError: null,
        mensaje: ''
      })

      const datosEnviar = [];

      const datos = modoVista === 'individual' ? [datosProfesoresNuevos] : datosProfesoresNuevos;
      datos.map(profesor => {
        return datosEnviar.push({
          correo: profesor.correo,
          cursos: profesor.cursos,
          idSede: profesor.idSede,
          nombre: profesor.nombre,
        })
      })

      const pathname = '/profesores/crearProfesores';
      const properties = datosEnviar;

      const resultados = await generalCallApi({ pathname, properties });
      const info = resultados.info.search('Algo salió mal');
      const error = resultados.status === 0 && info === -1;

      if (!error) {
        await obtenerDatosDB();
      }

      setPreloader(false);
      setRespuestaApiCrear({
        isError: error,
        mensaje: resultados.info
      });

    }

  }

  /**
   *  Eliminar varios profesores temporales al tiempo
   * @param {string} keys 
   */
  const eliminarProfesores = ({ keys }) => {
    const profesoresNuevos = { ...temporal.profesores.datos };
    const arrayKeys = !!keys ? keys : profesoresSeleccionados.keys;
    arrayKeys.forEach(key => {
      delete profesoresNuevos[key];
    });

    reiniciarEstadosMultiSelect({ datos: profesoresNuevos });
  }
  /**
   *  Eliminar 1 profesor temporal
   * @param {string} key 
   */
  const eliminarProfesorIndividual = ({ key }) => eliminarProfesores({ keys: key })

  //Lista los cursos por sede
  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }

  const moverProfesores = () => {
    let profesoresNuevos = { ...temporal.profesores.datos };

    const seleccionados = [...profesoresSeleccionados.keys];

    seleccionados.forEach(key => {
      profesoresNuevos[key] = {
        ...profesoresNuevos[key],
        cursos: [cursoSeleccionada.id],
        datosCursos: [cursoSeleccionada],
        idSede: sedeSeleccionada.id,
        nombreSede: sedeSeleccionada.nombre,
        nombreCursos: cursoSeleccionada.nombre
      }
    })
    reiniciarEstadosMultiSelect({ datos: profesoresNuevos });
  }

  //Llamada al api para obtener los datos de la base de datos
  const obtenerDatosDB = async () => {
    const result = await generalCallApi({ pathname: '/profesores/obtenerListadoProfesores' });
    let datos;

    const error = result.status === 0;
    if (!error) {
      datos = await transformarDatosProfesores({ datos: result.data, sedes: datosInstitucion.sedes });
      datos = {
        resultados: datos.resultadosModificados,
        'sin-asignar': datos.profesoresSinAsignar
      }
      // Actualizar datos en el store 
      dispatch({
        property: 'datosProfesores',
        type: 'SET_DATA',
        value: datos
      });
    }

    return { datos, error };
  }

  const reiniciarEstadosMultiSelect = ({ datos }) => {
    const hayProfesores = Object.keys(datos);
    
    const profesoresTemporalesActualizados = {
      ...temporal.profesores,
      hayArchivo: hayProfesores.length!==0,
      datos,
    }

    setProferosSeleccionados({
      keys: [],
      numerosProfesores: 0,
      profesores: []
    })

    setPrimerMultiSelect(false);

    setCursoSeleccionada({
      id: null,
      nombre: ''
    })

    setSedeSeleccionada({
      id: null,
      nombre: ''
    })

    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "profesores",
      value: profesoresTemporalesActualizados
    })
  }

  return (
    <>
      {isReady && !isLoading && !!datosProfesoresNuevos &&
        <>
          <ProfesoresVerificarComponent
            actualizarSedeAsignada={actualizarSedeAsignada}
            cambiarProfesoresElegidos={cambiarProfesoresElegidos}
            cambiarCurso={cambiarCurso}
            cancelarAccionMultiSelect={cancelarAccionMultiSelect}
            crearProfesores={crearProfesores}
            cursosSede={cursosSede}
            cursoSeleccionada={cursoSeleccionada}
            datosProfesoresNuevos={datosProfesoresNuevos}
            descartarCambios={descartarCambios}
            eliminarProfesorIndividual={eliminarProfesorIndividual}
            eliminarProfesores={eliminarProfesores}
            modoVista={modoVista}
            moverProfesores={moverProfesores}
            numeroProfesoresSeleccionados={profesoresSeleccionados.numerosProfesores}
            preloader={preloader}
            primerMultiselect={primerMultiselect}
            respuestaApiCrear={respuestaApiCrear}
            sedeSeleccionada={sedeSeleccionada}
            sedesInstituto={sedes}
            sedesProfesores={sedesProfesores}
            textosInterfaz={{
              botones: textosInterfaz.botones,
              descartar_cambios: textosInterfaz.descartar_cambios,
              miga_de_pan: textosInterfaz.miga_de_pan,
              modal_eliminar: textosInterfaz.modal_eliminar,
              modal_mover: textosInterfaz.modal_mover,
              modal_exito: textosInterfaz.modal_exito,
              multi_accion: textosInterfaz.multi_accion,
              no_hay_profesores: textosInterfaz.no_hay_profesores,
              profesor_creado: textosInterfaz.profesor_creado,
              verificar_datos: textosInterfaz.verificar_datos,
              notificacion: textosInterfaz.notificacion,
            }}
          />
        </>
      }
    </>
  )
}
export default ProfesoresVerificar;

ProfesoresVerificar.propTypes = {
 /**
   * funcion callback que borra los datos temporales.
   */
  descartarCambios: PropTypes.func.isRequired,
  /**
   *  Booleano que valida si ha cargado la información.
   */
   isLoading: PropTypes.bool
}