import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { store } from 'react-notifications-component';
import MicroModal from "micromodal";
import PropTypes from 'prop-types';
import { MigasDePan, Miga } from "../globales/MigaDePan";


/*Componentes */
import DivisionCrud from "../globales/DivisionCrud";
import Selector from "../globales/elementosFormularios/Selector";
import SelectorCheck from "../globales/elementosFormularios/SelectorCheck";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";

/**
 *  Este componente recibe una serie de props que le permiten renderizar la pantalla editar profesor. Además, hace uso de los componentes globales DivisionCrud, Selector, SelectorCheck, Modal y ModalConfirmacion.
 * @returns ProfesoresEditarEliminarComponent
 */
const ProfesoresEditarEliminarComponent = (props) => {
  const history = useHistory();
  const { actualizarCorreo, actualizarNombre, actualizarCursosAsignados, actualizarSedeAsignada, correoProfesor, cursosAsignados, cursosSede, datosProfesor, editarProfesor, eliminarProfesor, hayCambios, nombreProfesor, preloader, respuestaApiEditar, respuestaApiEliminar, sedeSeleccionada, sedes, textosInterfaz } = props;

  const { miga_de_pan, editar, botones, eliminar_profesor, profesor_eliminado } = textosInterfaz;
  const { nombre, sede, cursos, correo } = editar;

  /*estados */
  const [campoNombre, setCampoNombre] = useState({ campo: nombreProfesor, valido: true, desactivado: false });
  const [inputSede, setInputSede] = useState({ campo: sedeSeleccionada.id, desactivado: true });
  const [inputCorreo, setInputCorreo] = useState({ campo: correoProfesor, valido: true, desactivado: false });
  const [modalEliminar, setModalEliminar] = useState(true);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [valorInicialCursos, setValorIniciarlCursos] = useState(false);

  /*expresion */
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  /*REF */
  const refNombreProfesor = useRef(null);
  const refCorreo = useRef(null);


  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  /**Este useEffect Guarda el valor inicial de cursos asignados en un estado para activar y desactivar el SelectorCheck de cursos  */
  useEffect(() => {
    setValorIniciarlCursos(cursosAsignados.ids.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorInicialCursos])

  useEffect(() => {
    if (respuestaApiEliminar.isError === false) {
      MicroModal.close('estas-seguro-profesor', {
        awaitCloseAnimation: true
      });
      MicroModal.show('profesor-eliminado', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaApiEliminar.isError === true) {
      MicroModal.close('estas-seguro-profesor', {
        awaitCloseAnimation: true
      });
      notificacion(textosInterfaz.notificacion.error, respuestaApiEliminar.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEliminar.isError])



  useEffect(() => {
    if (respuestaApiEditar.isError !== null) {
      notificacion(
        respuestaApiEditar.isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        respuestaApiEditar.mensaje,
        respuestaApiEditar.isError ? "danger" : "success"
      );

      setCampoNombre({ ...campoNombre, desactivado: false });
      setInputSede({ ...inputSede, desactivado: true });
      setValorIniciarlCursos(cursosAsignados.ids.length);
      setInputCorreo({ ...inputCorreo, desactivado: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEditar.isError])


  /*Funciones */
  /*Valida si el nombre del profesor es valido o no. Esto se califica según el número de caracteres */
  const validarNombreProfesor = () => {
    const valorCampo = campoNombre.campo.length > 0 ? true : false;
    setCampoNombre({ ...campoNombre, valido: valorCampo })
  }

  /*esta función actualiza el valor del input y lo agrega en el estado */
  const actualizarCampo = (e, estado, cambiarEstado) => cambiarEstado({ ...estado, campo: e.target.value });

  /*Cambia la sede en el select */
  const cambiarSede = ({ opcion }) => {
    setInputSede({ ...inputSede, campo: opcion.nombre, desactivado: false });
    actualizarSedeAsignada({ idSede: opcion.id });
  }

  /*Cambia el valor de la opcion a check  */
  const actualizarCursos = ({ e, opcion }) => {
    actualizarCursosAsignados({ e, idSede: opcion.idSede, curso: opcion })
  }

  /*actualia los cambios de todos los campos */
  const actualizarCambios = () => {
    setMostrarAlerta(true);
    if (campoNombre.valido && inputCorreo.valido) {
      editarProfesor();
    }
  }

  /**Esta función comprueba que el valor del campo correo cumple con la expresión y cambia su estado */
  const validarCorreo = (expresion, estado, cambiarEstado) => {
    const valor = estado.campo !== '' ? expresion.test(estado.campo) : false;
    cambiarEstado({ ...estado, valido: valor });
  }

  useEffect(()=>{
    if(refNombreProfesor && campoNombre.desactivado === true){
      refNombreProfesor.current.focus()
    }
  },[campoNombre.desactivado])

  useEffect(()=>{
    if(refCorreo && inputCorreo.desactivado === true){
      refCorreo.current.focus()
    }
  },[inputCorreo.desactivado])

  return (
    <>
    <MigasDePan>
      <Miga
          texto={miga_de_pan?.principal || 'Profesores'}
          alSeleccionar={() => {
            history.push('/profesores')
          }} />
        <Miga
          texto={miga_de_pan?.editar || 'Editar profesor'}
          esRutaActual={true}
        />
      </MigasDePan>
      <div className="contenido">
        <h1 className="titulos-principales mb-5">{editar.titulo}</h1>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{nombre.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{nombre.descripcion} </p>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!campoNombre.desactivado && 'input_disabled'} ${mostrarAlerta ? !campoNombre.valido && 'input_error' : null}`}
                  onBlur={() => { validarNombreProfesor() }}
                  onChange={(e) => {
                    actualizarCampo(e, campoNombre, setCampoNombre);
                    actualizarNombre({ nombre: e.target.value });
                  }}
                  //onClick={() => {setCampoNombre({ ...campoNombre, desactivado: true })}}
                  disabled={!campoNombre.desactivado}
                  onKeyUp={validarNombreProfesor}
                  placeholder={nombre.placeholder}
                  ref={refNombreProfesor}
                  type="text"
                  value={campoNombre.campo}
                />
              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setCampoNombre({ ...campoNombre, desactivado: true }) }}
              >
                {botones.editar}
              </button>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p role="alert" aria-live="assertive" className={`text-14 ${!campoNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {nombre.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>


        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{correo.titulo} <span className="asterisco">*</span> </h3>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputCorreo.desactivado && 'input_disabled'} ${mostrarAlerta ? !inputCorreo.valido && 'input_error' : null}`}
                  onChange={(e) => {
                    actualizarCampo(e, inputCorreo, setInputCorreo);
                    actualizarCorreo({ correo: e.target.value })
                  }}
                  onBlur={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                  disabled={!inputCorreo.desactivado}
                  //onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true }) }}
                  onKeyUp={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                  placeholder={correo.placeholder}
                  ref={refCorreo}
                  type="email"
                  value={inputCorreo.campo}
                />
              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true })}}
              >
                {botones.editar}
              </button>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p role="alert" aria-live="assertive" className={`text-14 ${!inputCorreo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {nombre.error}
                </p>
              </div>
            }
          </>

        </DivisionCrud>


        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{sede.titulo}</h3>
            <p className="r4-14-gris6a">{sede.descripcion} </p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarSede}
              desactivado={inputSede.desactivado}
              estiloTipoInput={true}
              opciones={sedes}
              textoDefault={sede.placeholder}
              textoElegido={sedeSeleccionada.nombre}
              superposicion={true}
            />
          </div>
        </DivisionCrud>


        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{cursos.titulo} </h3>
            <p className="r4-14-gris6a">{cursos.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <SelectorCheck
              actualizarElegidos={actualizarCursos}
              datosAlmacenados={cursosAsignados.ids}
              desactivado={cursosAsignados.ids.length !== valorInicialCursos ? false : true}
              estiloTipoInput={true}
              hayElementos={cursosSede.hayCursos}
              opciones={cursosSede.cursos}
              textoDefault={`${cursosAsignados.datos.length} ${cursosAsignados.datos.length !== 1 ? cursos.asignados.plural : cursos.asignados.singular} `}
              textoNoHayDatos={cursos.no_hay}
            />
          </div>
        </DivisionCrud>


        <div className="alineado-izquierda my-5">
          <button
            className="focusable-primary boton-justo sm:center mr-5 "
            onClick={() => {
              if (modalEliminar) {
                MicroModal.show('estas-seguro-profesor', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalEliminar(true)
              }
            }}
          >
            <span className="icon-basura red-inv-var2--color  text-20 pr-3 hidden sm:block"></span>
            <p className=" evento_rojo">{botones.eliminar.singular}</p>
          </button>
          <button
            disabled={!hayCambios}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
            onClick={actualizarCambios}
          >
            {
              preloader ?
                <div className="center">
                  <span className="icon-spinner7 animate-spin text-20"></span>
                </div>
                :
                botones.actualizar
            }
          </button>
        </div>
      </div>

      {modalEliminar &&
        <>
          {/* Modal pregunta si desea eliminar profesor */}
          <Modal nombreModal="estas-seguro-profesor">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{eliminar_profesor.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{eliminar_profesor.descripcion1} <span className="text-resaltado">{datosProfesor.nombre}</span> {eliminar_profesor.descripcion2} <span className="text-resaltado">{datosProfesor.nombreSede}</span> {eliminar_profesor.descripcion3}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-profesor`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="focusable-primary boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5"
              >
                {botones.cancelar}
              </button>
              <button className="focusable-primary boton-justo sm:px-10 boton-rojo boton_hover_morado" onClick={eliminarProfesor}>
                {
                  preloader ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    : botones.eliminar.singular
                }
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion profesor eliminado */}
          <ModalConfirmacion nombreModal="profesor-eliminado">
            <Link className="icon-cerrar style-icon-crerrar text-20 right-6 absolute" to="/profesores"></Link>
            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{profesor_eliminado.titulo}</h1>
            <p className="text-center r7-16-negro26 mb-4">
              {profesor_eliminado.descripcion1} <span className="text-resaltado">{datosProfesor.nombre}</span> {profesor_eliminado.descripcion2} <span className="text-resaltado">{datosProfesor.nombreSede}</span>
            </p>
            <div className="center my-3">
              <Link
                to="/profesores"
                className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
              >
                {botones.volver.volver}
              </Link>
            </div>
          </ModalConfirmacion>
        </>
      }
    </>
  )
}
export default ProfesoresEditarEliminarComponent;

ProfesoresEditarEliminarComponent.propTypes = {
  /**
   * función que actualiza el correo del profesor
   * @type{Function({ correo: string }):void} - callback
   */
  actualizarCorreo: PropTypes.func.isRequired,

  /**
   * función que actualiza el nombre del profesor
   * @type{Function({ nombre: string }):void} - callback
   */
  actualizarNombre: PropTypes.func.isRequired,

  /**
   *  función que se encarga de actualizar los cursos asignados al profesor.
   * @type{Function({ e: event, curso: shape }):void} -callback
   */
  actualizarCursosAsignados: PropTypes.func.isRequired,

  /**
   *  función que se encarga de actualizar el id de la sede en la que se desea crear el profesor temporal.
   * @type{Function({ idSede:string }):void}- callback
   */
  actualizarSedeAsignada: PropTypes.func.isRequired,

  /**
   *string que corresponde al campo de correo del profesor.
   */
  correoProfesor: PropTypes.string.isRequired,

  /**
  * Objeto que contiene la información de los cursos asignados al profesor.
  */
  cursosAsignados: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }).isRequired,
  /**
   * Objeto que contiene el estado y los cursos que el instituto tiene.
   */
  cursosSede: PropTypes.shape({
    cursos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    hayCursos: PropTypes.bool
  }).isRequired,

  /**
   *  objeto que almacena los datos del profesor antes de ser editados.  
   */
  datosProfesor: PropTypes.shape({
    correo: PropTypes.string,
    cursos: PropTypes.array,
    datosCursos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nomber: PropTypes.string
    })),
    idSede: PropTypes.string,
    id: PropTypes.string,
    nombre: PropTypes.string,
    nombreSede: PropTypes.string,
    numeroCursos: PropTypes.number
  }).isRequired,

  /**
   * función que se encarga de editar los datos del profesor. Esta función se encarga de llamar el api de editar profesores.
   */
  editarProfesor: PropTypes.func.isRequired,

  /**
   * función que se encarga de eliminar el profesor. Esta función se encarga de llamar el api de borrar profesores.
   */
  eliminarProfesor: PropTypes.func.isRequired,

  /**
   * Objeto que guarda temporalmente los cambios del formulario
   */
  hayCambios: PropTypes.shape({
    cambios: PropTypes.bool,
    datos: PropTypes.object
  }).isRequired,

  /**
   * string que almacena el nuevo nombre del profesor.
   */
  nombreProfesor: PropTypes.string.isRequired,

  /**
   *  un booleano que contiene el preloader para el llamado de las API de eliminar y editar profesor.
   */
  preloader: PropTypes.bool.isRequired,

  /**
   * objeto que contiene dos propiedades que va a devolver cuando el API editar profesores. termine de responder.
   */
  respuestaApiEditar: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }).isRequired,

  /**
   * Es un objeto que contiene dos propiedades que va a devolver cuando el API eliminar profesores. termine de responder.
   */
  respuestaApiEliminar: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }).isRequired,

  /**
   * objeto que cuenta con los datos de la sede seleccionada para mover al o los profesores de sede en la que se desea crear. 
   */
  sedeSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,
  /**
   * array de objetos que contiene el listado de sedes que el instituto tiene.
   */
  sedes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired
}