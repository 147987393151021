import PropTypes from 'prop-types';

/**
 * Componente que recibe una serie de props necesarios para renderizar el pie de página o Footer.
 * @returns FooterComponent
 */
const FooterComponent = (props) => {
  const { cambiarPais, textosInterfaz, listaPaises, paisActivo, infoPais } = props;
  const { boletin_boton, boletin_descripcion, boletin_placeholder, derechos_consulta, derechos_politica, derechos_terminos, derechos_y, informacion_comercial, producto_de, cosulta_soporte, version_navegador } = textosInterfaz;

  //imagenes
  const makinaImagen = '/images/footer/logo-makina.png';
  const colProducImagen = '/images/footer/logo-col-productiva.svg';
  const innpulsaImagen = '/images/footer/logo-innpulsa.svg';
  const ecoNaranjaImagen = '/images/footer/logo-economia-naranja.png';
  return (
    <footer className="p-5 lg:px-5 2xl:px-10 footer-estilo ">

      <div className="focusable-primary md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">

        <div className="flex flex-wrap items-center justify-center md:justify-start">
          <div>
            <div className="grid grid-cols-12 gap-4 justify-between">
              <div className="col-span-12 md:col-span-6">
                <div className="logo-makemake text-indent" style={{ backgroundImage: `url(/images/footer/logo-makemake.png)` }}>Logo makemake</div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex lex-wrap flex-col justify-center ">
                  <p className="text-center onprimary-var1--color">{producto_de}: </p>
                  <img className="logo-makina mt-3" alt="logo de Makina Editorial" src={makinaImagen} />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <ul tabIndex={0} className="focusable-primary flex flex-wrap justify-center " aria-label="Botones de información de contacto para (Colombia, Chile, Ecuador, Estadous unidos, México y Costa Rica).">
                {listaPaises.map((pais, index) => {
                  return (
                    <li key={pais.cod}>
                      <button 
                      key={pais.cod}
                      aria-current= {paisActivo === pais.cod ? true : false}
                      className={`focusable-primary onsurface-var2--color  ${listaPaises[index] === listaPaises[0] ? 'mr-2 ' : 'mx-2'} ${paisActivo === pais.cod ? 'paises_active' : 'paises'}`}
                      onClick={() => cambiarPais(pais.cod)}>
                      {pais.nombre}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="py-5 lg:py-0 text-center lg:text-left flex justify-center md:justify-end xl:justify-start">
          <div>
            <p  className='onsurface-var2--color'>{informacion_comercial}</p>
            <div>
              {infoPais[paisActivo].descripcion !== 'null' &&
                <p className='onsurface-var2--color'>{infoPais[paisActivo].descripcion} </p>
              }
              {infoPais[paisActivo].correo.map((correo, index) => {
                return (
                  <p className='onsurface-var2--color focusable-primary' key={`${correo}${index}`}>
                    <a className='onsurface-var2--color focusable-primary' href={`email:${correo}`} >{correo}</a>
                  </p>
                )
              })
              }
              <div className="mx-auto lg:mx-0 lg:grid lg:grid-cols-2 w-80">
                {infoPais[paisActivo].telefono.map((tel, index) => {
                  return (
                    <a  className='onsurface-var2--color focusable-primary' href={`tel:${tel}`} key={index}>{tel}</a>
                  )
                })
                }
              </div>
              <p  className='onsurface-var2--color'>{infoPais[paisActivo].direccion}</p>
            </div>
            <div className="flex flex-wrap mt-3 justify-center lg:justify-start">
              <a href="https://www.facebook.com/Bookmakemake/" aria-label="Facebook link" rel="noreferrer" target="_blank" className="focusable-primary text-30  onsurface-var2--color icon-redes-contraste">
                <span className="icon-facebook2 block py-1"></span>
              </a>
              <a href="https://www.instagram.com/makemakelibros" aria-label="Instagram link" rel="noreferrer" target="_blank" className="focusable-primary text-30 mx-5 onsurface-var2--color icon-redes-contraste">
                <span className="icon-instagram block py-1"></span>
              </a>
              <a href="https://www.youtube.com/channel/UCAGRlTJB3fUDRfkVRPVNdzQ"aria-label="Youtube link"  rel="noreferrer" target="_blank" className="focusable-primary text-38 py-0 onsurface-var2--color icon-redes-contraste">
                <span className="icon-youtube block pb-1"></span>
              </a>
            </div>
          </div>

        </div>

        <div className="md:col-span-3 lg:col-span-1 lg:flex lg:flex-wrap  xl:justify-end xl:items-center">
          <form className="xl:w-11/12">
            <label className="onsurface-var2--color text-center block mx-auto lg:mx-0">{boletin_descripcion}</label>
            <div className="grid grid-cols-12 gap-3 mt-2 max-w-screen-md mx-auto">
              <input type="email" className="footer-email col-span-12 xl:col-span-7" placeholder={boletin_placeholder} required />
              <input type="submit" value={boletin_boton} className="focusable-secondary col-span-12  xl:col-span-5  boton-morado boton_hover_morado_oscuro boton-morado-footer" />
            </div>
          </form>
          <div className="mt-3 xl:w-11/12">
            <p className="onsurface-var2--color text-center">{version_navegador} </p>
            <div  className="alineado-verticalmente justify-between w-8/12 sm:w-4/12 lg:w-8/12 mx-auto">
              <p className='onsurface-var2--color'>96+ <span role="contentinfo" aria-label="en Chrome" className="icon-chrome"></span></p>
              <p className='onsurface-var2--color'>97+<span role="contentinfo" aria-label="en Edge" className="icon-edge"></span> </p>
              <p className='onsurface-var2--color'>13.1+  <span role="contentinfo" aria-label="en Safari" className="icon-safari"></span></p>
            </div>



          </div>
        </div>
      </div>

      <div className="py-5 xl:py-0 xl:grid xl:grid-cols-12 xl:mt-5">
        <div className="lg:col-span-4 md:w-9/12 xl:w-full md:mx-auto xl:mx-0 md:flex md:flex-row md:justify-around xl:justify-start md:items-center">
          <p className="onsurface-var2--color text-14 text-center lg:text-left lg:truncate">Con el apoyo:</p>
          <a rel="noreferrer" href="https://www.innpulsacolombia.com/" target="_blank" aria-label="Inpulsa, link." className="focusable-primary logo-innpulsa mx-auto lg:mx-2 block" >
            <img src={innpulsaImagen} alt="Logo de Inpulsa" />
          </a>
          <a rel="noreferrer" href="https://www.colombiaproductiva.com/" target="_blank" aria-label="Colombia productiva, link." className="focusable-primary logo-col-prod mx-auto lg:mx-2 block">
            <img src={colProducImagen} alt="Logo de Colombia productiva" />
          </a>
          <a rel="noreferrer" href="https://economianaranja.gov.co/sello-colombia-crea/" target="_blank" aria-label="Economia naranja, link." className="focusable-primary logo-eco-naranja mx-auto lg:mx-2 block">
            <img src={ecoNaranjaImagen} alt="Logo de Economia naranja" />
          </a>
        </div>
        <div className=" lg:col-span-8 lg:col-end-13 lg:flex lg:items-center lg:justify-center xl:justify-end">
          <p className="onsurface-var2--color terminos-y-condiciones lg:truncate">
            {derechos_consulta}
            <a href="./" target="_blank" rel="noreferrer" className="focusable-primary underline"> {derechos_terminos} </a>
            {derechos_y}
            <a href="./" target="_blank" rel="noreferrer" className="focusable-primary underline"> {derechos_politica} </a>
            Consulta nuestra
            <a href="./" target="_blank" rel="noreferrer" className="focusable-primary underline"> {cosulta_soporte}</a></p>
        </div>
      </div>

    </footer>
  )
}

export default FooterComponent;

FooterComponent.propTypes = {
  /**
   * Es una función que recibe como parámetro un string que corresponde al código del país. Al final de esta sección se encuentra la tabla de conversiones.
   * @type{Function(cod: string):void} -callback
   */
  cambiarPais: PropTypes.func.isRequired,
  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   *  array de objetos que corresponde al listado de países disponibles por el momento. 
   */
  listaPaises: PropTypes.arrayOf(PropTypes.shape({
    cod: PropTypes.string, // país que se encuentra en el localStorage.
    nombre: PropTypes.string // país según el idioma activo.
  })).isRequired,
  /**
   * string que hace referencia al país activo.
   */
  paisActivo: PropTypes.string.isRequired,
  /**
   *  objeto de objetos que trae la información de contacto que corresponde a cada país.
   */
  infoPais: PropTypes.shape({
    correo: PropTypes.string,
    descripcion: PropTypes.string, //puede ser null
    direccion: PropTypes.string,
    telefono: PropTypes.array // tiene los números de contacto.
  })
}