import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonProfesoresGeneral = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={188} height={40} />
      <div className="contenido">
        <h1 className="titulos-principales flex ">
          <div className="md:w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>

          <div className="md:w-40 mb-2 ml-5">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <div style={{ width: 500 }} className="py-5"  >
          <Skeleton width={'100%'} height={30} />
        </div>

        <div className="md:flex justify-between mt-3 gap-4">
          <div className="md:w-6/12 xl:w-3/12">
            <Skeleton width={'100%'} height={36} />
          </div>
          <div className="md:w-6/12 xl:w-451p mt-6 md:mt-0">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>

        <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 py-5 mb-5 border-b-2 border-gris-linea">
          <div className="justify-self-end xl:order-3 md:col-span-2 xl:w-40">
            <Skeleton width={'100%'} height={36} />
          </div>

          <div className="xl:order-1">
            <Skeleton width={'100%'} height={36} />
          </div>

          <div className=" xl:order-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>


        <div className="encabezadoTabla  xl:grid grid-cols-12">
          <p className="col-span-1"></p>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-3">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-3">
            <div className="md:w-5/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-2">
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>
          <div className="col-span-1"></div>
        </div>

        <div className="filaTabla xl:grid-cols-12">
          <div className="fila-2-sm1-lg12-xl1 row-span-5 sm:row-span-3 lg:row-span-1 h-7 relative  ">
            <Skeleton width={30} height={'100%'} />
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-3 ">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-3">
            <div className="w-6/12 xl:w-10/12 xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm5-lg6 xl:col-span-2">
            <div className="w-6/12 xl:w-10/12  xl:hidden">
              <Skeleton width={'100%'} height={24} />
            </div>
            <div className="md:w-10/12">
              <Skeleton width={'100%'} height={24} />
            </div>
          </div>

          <div className="fila-10-sm11-lg12 xl:col-span-1">
            <div className="alineado-izquierda-center">
              <div className="w-full md:w-36 xl:w-8 ">
                <Skeleton width={'100%'} height={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  )
}
export default SkeletonProfesoresGeneral;