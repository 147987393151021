import moment from 'moment';
import 'moment/locale/es';

export const FORMATO_FECHA_API = 'YYYY-MM-DD';
export const FORMATO_FECHA_USUARIO = 'DD MMM YYYY';

export const FECHAS_FILTROS = {
    hoy: 'HOY',
    ayer: 'AYER',
    estaSemana: 'ESTA_SEMANA',
    semanaPasada: 'SEMANA_PASADA',
    ultimoMes: 'ULTIMO_MES',
    esteAnio:'ESTE_AÑO'
}


// definición de tipos

/**
 * @typedef {
    {
        id: string,
        nombre:string,
        fechaInicio: string,
        fechaFin: string,
        rangoFecha: {
            fechaInicio: string,
            fechaFin: string,
        },
        fechaDias:[{dia:'DD', diaNombre:'dddd', fecha:'YYYY-MM-DD', fechaUsuario:'DD MM YYYY'}]
    }
} InformacionFecha
 */

/**
 * @typedef {
    {
        id: string,
        nombre:string,
        fechaInicio: string,
        fechaFin: string,
        rangoFecha: {
            fechaInicio: string,
            fechaFin: string,
        },
        fechaMeses:[
           {
            mes:'MM',
            nombreMes:'MMM', 
            fechaInicio:'YYYY-MM-DD', 
            fechaFin:'YYYY-MM-DD', 
            rangoFecha:{
                fechaInicio:'DD MM YYYY', 
                fechaFin:'DD MM YYYY', 
            }
           }
        ]
    }
} InformacionFechaAnio
 */


/**
 * @class FiltroFechas
 */

export class FiltroFechas {


    /**
     * Devuelve un objeto con información detalla de la fecha actual
     * @param {'en'|'es'} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} nombre  - texto  adicional 
     * @returns {InformacionFecha} objeto con la información de la fecha actual
     */
    static hoy(idioma, nombre) {
        moment.locale(idioma || 'en');
        const hoy = moment().format(FORMATO_FECHA_API);
        const fechaInicialAMostrar = moment(hoy).format(FORMATO_FECHA_USUARIO);
        const fechaFinalAmostrar = moment(hoy).format(FORMATO_FECHA_USUARIO);

        const informacionFiltro = {
            id: FECHAS_FILTROS.hoy,
            nombre: nombre,
            fechaInicio: hoy,
            fechaFin: hoy,
            rangoFecha: {
                fechaInicio: fechaInicialAMostrar,
                fechaFin: fechaFinalAmostrar,
            },
            fechaDias:FiltroFechas.fechasEntreRango(idioma,hoy,hoy)
        }
        return informacionFiltro;
    }

        /**
     * Devuelve un objeto con información detalla de ayer.
     * @param {'en'|'es'} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} nombre  - texto  adicional 
     * @returns {InformacionFecha} objeto con la información de ayer
     */
    static ayer(idioma, nombre) {
        moment.locale(idioma || 'en');
        const hoy = moment().format(FORMATO_FECHA_API);
        const fechaInicio = moment(hoy).subtract(1, 'd').format(FORMATO_FECHA_API);
        const fechaFin = fechaInicio;
        const fechaInicialAMostrar = moment(fechaInicio).format(FORMATO_FECHA_USUARIO);
        const fechaFinalAmostrar = fechaInicialAMostrar;
        const informacionFiltro = {
            id: FECHAS_FILTROS.ayer,
            nombre: nombre,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            rangoFecha: {
                fechaInicio: fechaInicialAMostrar,
                fechaFin: fechaFinalAmostrar,
            },
            fechaDias:FiltroFechas.fechasEntreRango(idioma,fechaInicio,fechaFin)
        }
        return informacionFiltro;
    }


        /**
     * Devuelve un objeto con información detalla de la semana actual comenzando con el lunes como día 1 y finalizando el domingo o el día actual dependiendo de el parametro 'hastaHoy'.
     * @param {'en'|'es'} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} nombre  - texto  adicional 
     * @param {boolean} hastaHoy  - si se pasa en falso devuelve toda la semana, si es verdadero cuenta desde el dia 1 (lunes) hasta el día actual
     * @returns {InformacionFecha} objeto con la información de la semana actual.
     */
    static estaSemana(idioma, nombre, hastaHoy) {
        moment.locale(idioma || 'en');
        const hoy = moment().format(FORMATO_FECHA_API);
        const fechaInicio = moment().startOf('isoWeek').format(FORMATO_FECHA_API);
        const fechaFin = hastaHoy==false?moment().endOf('isoWeek').format(FORMATO_FECHA_API):moment().format(FORMATO_FECHA_API);
        const fechaInicialAMostrar = moment(fechaInicio).format(FORMATO_FECHA_USUARIO);
        const fechaFinalAmostrar = moment(fechaFin).format(FORMATO_FECHA_USUARIO);
        const informacionFiltro = {
            id: FECHAS_FILTROS.estaSemana,
            nombre: nombre,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            rangoFecha: {
                fechaInicio: fechaInicialAMostrar,
                fechaFin: fechaFinalAmostrar,
            },
            fechaDias:FiltroFechas.fechasEntreRango(idioma,fechaInicio,fechaFin)
        }
        return informacionFiltro;
    }

     /**
     * Devuelve un objeto con información detalla de la semana pasada comenzando con el lunes como día 1 y finalizando el domingo.
     * @param {'en'|'es'} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} nombre  - texto  adicional 
     * @returns {InformacionFecha} objeto con la información de la semana pasada.
     */
    static semanaPasada(idioma, nombre) {
        moment.locale(idioma || 'en');
        const fechaInicio = moment().subtract(1, 'isoWeek').startOf('isoWeek').format(FORMATO_FECHA_API);
        const fechaFin = moment().subtract(1, 'isoWeek').endOf('isoWeek').format(FORMATO_FECHA_API);
        const fechaInicialAMostrar = moment(fechaInicio).format(FORMATO_FECHA_USUARIO);
        const fechaFinalAmostrar = moment(fechaFin).format(FORMATO_FECHA_USUARIO);
        const informacionFiltro = {
            id: FECHAS_FILTROS.semanaPasada,
            nombre: nombre,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            rangoFecha: {
                fechaInicio: fechaInicialAMostrar,
                fechaFin: fechaFinalAmostrar,
            },
            fechaDias:FiltroFechas.fechasEntreRango(idioma,fechaInicio,fechaFin)
        }
        return informacionFiltro;
    }

            /**
     * Devuelve un objeto con información detalla del mes actual
     * @param {'en'|'es'} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} nombre  - texto  adicional 
     * @returns {InformacionFecha} objeto con la información del mes actual
     */
    static ultimoMes(idioma, nombre) {
        moment.locale(idioma || 'en');
        const fechaInicio = moment().startOf('month').format(FORMATO_FECHA_API);
        const fechaFin = moment().endOf('month').format(FORMATO_FECHA_API);
        const fechaInicialAMostrar = moment(fechaInicio).format(FORMATO_FECHA_USUARIO);
        const fechaFinalAmostrar = moment(fechaFin).format(FORMATO_FECHA_USUARIO);
        const informacionFiltro = {
            id: FECHAS_FILTROS.ultimoMes,
            nombre: nombre,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            rangoFecha: {
                fechaInicio: fechaInicialAMostrar,
                fechaFin: fechaFinalAmostrar,
            },
            fechaDias:FiltroFechas.fechasEntreRango(idioma,fechaInicio,fechaFin)
        }
        return informacionFiltro;
    }


             /**
     * Devuelve un objeto con información detallada del año actual, desde enero hasta diciembre o hasta el mes actual según el valor que se pase al paramatro 'hastaMesActual'
     * @param {'en'|'es'} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} nombre  - texto  adicional 
     * @param {boolean} hastaMesActual  - si se pasa en falso devuelve desde enero hasta diciembre, si es verdadero cuenta desde enero hasta el mes actual.
     * @returns {InformacionFechaAnio} objeto con la información del año actual.
     */
              static esteAnio(idioma, nombre, hastaMesActual) {
                moment.locale(idioma || 'en');
                const fechaInicio = moment().startOf('year').format(FORMATO_FECHA_API);
                const fechaFin = hastaMesActual==false?moment().endOf('year').format(FORMATO_FECHA_API):moment().endOf('M').format(FORMATO_FECHA_API);
                const fechaInicialAMostrar = moment(fechaInicio).format(FORMATO_FECHA_USUARIO);
                const fechaFinalAmostrar = moment(fechaFin).format(FORMATO_FECHA_USUARIO);
                const informacionFiltro = {
                    id: FECHAS_FILTROS.esteAnio,
                    nombre: nombre,
                    fechaInicio: fechaInicio,
                    fechaFin: fechaFin,
                    rangoFecha: {
                        fechaInicio: fechaInicialAMostrar,
                        fechaFin: fechaFinalAmostrar,
                    },
                    fechaMeses:FiltroFechas.mesesEntreRango(idioma,fechaInicio,fechaFin)
                }
                return informacionFiltro;
            }
    

   /**
    * Devuelve un arreglo de objetos de fechas (en días) que estan dentro de un rango de tiempo
    * @param {string} fechaInicio 
    * @param {string} fechaFin 
    * @return {
        Array<{dia:'DD',nombreDia:'dddd', fecha:'YYYY-MM-DD', fechaUsuario:'DD MMM YYYY'}>
    * } cada objeto del arreglo devuelve información del día como:  el numero del dia, el nombre del día, la fecha en formato requerido por la API y la fecha para el usuario.
    */
    static fechasEntreRango(idioma, fechaInicio, fechaFin){
        moment.locale(idioma || 'en');
        var inicio = moment(fechaInicio).clone()
        const fechas = [];

        while (inicio.isSameOrBefore(fechaFin)) {
            const dia = inicio.format();
            const nombreDia = moment(dia).format('dddd');
            fechas.push({
                dia:moment(dia).format('DD'),
                nombreDia:nombreDia,
                fecha:moment(dia).format(FORMATO_FECHA_API),
                fechaUsuario:moment(dia).format(FORMATO_FECHA_USUARIO)
            });
            inicio.add(1, 'days');
        }
        return fechas;
    }

       /**
    * Devuelve un arreglo de objetos de fechas (en meses) que estan dentro de un rango de tiempo
    * @param {string} fechaInicio 
    * @param {string} fechaFin 
    * @return {
        Array<{mes:'M',nombreMes:'MMM', fechaInicio:'YYYY-MM-DD', fechaFin:'YYYY-MM-DD',rangoFecha:{fechaInicio:'DD MMM YYYY', fechaFin:'DD MMM YYYY'}}>
    * } 
    */
        static mesesEntreRango(idioma, fechaInicio, fechaFin){
            moment.locale(idioma || 'en');
            var inicio = moment(fechaInicio).clone()
            const fechas = [];
    
            while (inicio.isSameOrBefore(fechaFin)) {
                const dia = inicio.format();
                const nombreDia = moment(dia).format('MMM');
                fechas.push({
                    mes:moment(dia).format('MM'),
                    nombreMes:nombreDia,
                    fechaInicio: moment(dia).startOf('M').format(FORMATO_FECHA_API),
                    fechaFin: moment(dia).endOf('M').format(FORMATO_FECHA_API),
                    rangoFecha: {
                        fechaInicio: moment(dia).startOf('M').format(FORMATO_FECHA_USUARIO),
                        fechaFin: moment(dia).endOf('M').format(FORMATO_FECHA_USUARIO),
                    }
                });
                inicio.add(1, 'month');
            }
            return fechas;
        }

    /**
     * Devuelve una fecha en  formato  'DD MMM YYYY'
     * @param {string} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} fecha - fecha en formato valido.
     * @returns {string} fecha en formato 'DD MMM YYYY'
     */
    static formatoUsuario(idioma, fecha){
        moment.locale(idioma || 'en');
        return moment(fecha).format(FORMATO_FECHA_USUARIO)
    }

        /**
     * Devuelve una fecha en  formato  'YYYY-MM-DD'
     * @param {string} idioma - idioma en el que debe mostrarse la fecha al usuario.
     * @param {string} fecha - fecha en formato valido.
     * @returns {string} fecha en formato 'YYYY-MM-DD'
     */
    static formatoAPI(idioma, fecha){
        moment.locale(idioma || 'en');
        return moment(fecha).format(FORMATO_FECHA_API)
    }

}
