import { useState, useEffect } from 'react';
//Hooks personalizados y funciones
import { useTrackedState } from "../../../store";
//Data
import DataTextos from "../../../modules/dataTextos.json";

/**
 * Retorna un objeto con los textos de la interfaz gráfica de una sección específica
 * @param {string} seccion - Sección (pestaña) del archivo de excel  que se desea obtener los textos.
 * @param {*} isArray - Si la sección es un array.
 * @returns {object} - Objeto con los textos de la sección especificada.
 */
const useObtenerSubsecciones = (seccion, isArray) => {
  //Estados globales
  const state = useTrackedState();
  const { idioma } = state;
  //Estados del componente
  const [textosSubSecciones, setTextosSubSecciones] = useState(undefined);
  const [isReady, setIsReady] = useState(false);


  useEffect(() => {
    const obetenerTraducciones = () => {
      const seccionEspecifica = DataTextos[seccion];
      let subseccion = {
        seccion: false,
        subseccion: false
      };
      let infoSeccion = {};
      let result = [];

      seccionEspecifica.forEach((item, index) => {
        //Para recursos que tienen secciones
        if (!!item.seccion) {
          infoSeccion[item.seccion] = { ...infoSeccion[item.seccion] };
          subseccion[seccion] = true;
          //Para recursos con subsecciones
          if (item.subseccion !== 'null' && !!item.subseccion) {
            infoSeccion[item.seccion][item.subseccion] = { ...infoSeccion[item.seccion][item.subseccion] };
            item.item !== 'null'
              ? infoSeccion[item.seccion][item.subseccion][item.item] = { ...infoSeccion[item.seccion][item.subseccion][item.item], [item.id]: item[idioma] }
              : infoSeccion[item.seccion][item.subseccion] = { ...infoSeccion[item.seccion][item.subseccion], [item.id]: item[idioma] };
            
              subseccion[subseccion] = true;
          } else {
            item.item !== 'null'
              ? infoSeccion[item.seccion][item.item] = { ...infoSeccion[item.seccion][item.item], [item.id]: item[idioma] }
              : infoSeccion[item.seccion] = { ...infoSeccion[item.seccion], [item.id]: item[idioma] };
          }
        }
        //Para recursos que no tienen subsecciones
        else itemsRecurso(seccionEspecifica, infoSeccion)
      })


      //Array para las subsecciones
      if (isArray) {
        if (subseccion[seccion]) {
          result = {}
          for (let property in infoSeccion) {
            result[property] = {}
            let item = [];

            for (let property2 in infoSeccion[property]) {
              item.push(infoSeccion[property][property2])
              result[property] = item
            }
          }
        }

        //Array para los recursos que no tienen subsecciones
        else {
          for (let property in infoSeccion) result.push(infoSeccion[property])
        }
      }
      setIsReady(true);
      setTextosSubSecciones(isArray ? result : infoSeccion);
    }

    obetenerTraducciones()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idioma, seccion])


  //Recursos que no tienen subsecciones internas
  const itemsRecurso = (array, infoSeccion) => {
    array.forEach(item => {
      if (infoSeccion[item.item]) return infoSeccion[item.item] = { ...infoSeccion[item.item], [item.id]: item[idioma] }
      infoSeccion[item.item] = { [item.id]: item[idioma] }
    })
  }

  return { isReady, textosSubSecciones }
}

export default useObtenerSubsecciones;
