import VerificarEstudianteIndividual from "./Individual";
import VerificarEstudianteMasivo from "./Masivo";

const EstudianteVerificarComponente = (props) => {
  const { actualizarCurso, actualizarSede, cambiarEstudiantesElegidos, cancelarAccionMultiSelect, crearEstudiantes, cursosEstudiantes, cursosSede, cursoSeleccionado, datosEstudiantesNuevos, descartarCambios, eliminarEstudianteIndividual, eliminarEstudiantes, estudiantesElegidos, modoVista, mostrarMultiSelect, moverEstudiantes, preloader, respuestaApiCrear, sedeSeleccionada, sedesInstituto, textosInterfaz } = props;


  return <>
    {modoVista === 'individual' ?
      <VerificarEstudianteIndividual
        crearEstudiantes={crearEstudiantes}
        datosEstudiantesNuevos={datosEstudiantesNuevos}
        descartarCambios={descartarCambios}
        preloader={preloader}
        respuestaApiCrear={respuestaApiCrear}
        textosInterfaz={textosInterfaz}
      />
      :
      <VerificarEstudianteMasivo
        actualizarCurso={actualizarCurso}
        actualizarSede={actualizarSede}
        cambiarEstudiantesElegidos={cambiarEstudiantesElegidos}
        cancelarAccionMultiSelect={cancelarAccionMultiSelect}
        crearEstudiantes={crearEstudiantes}
        cursosEstudiantes={cursosEstudiantes}
        cursosSede={cursosSede}
        cursoSeleccionado={cursoSeleccionado}
        datosEstudiantesNuevos={datosEstudiantesNuevos}
        descartarCambios={descartarCambios}
        eliminarEstudiantes={eliminarEstudiantes}
        eliminarEstudianteIndividual={eliminarEstudianteIndividual}
        estudiantesElegidos={estudiantesElegidos}
        mostrarMultiSelect={mostrarMultiSelect}
        moverEstudiantes={moverEstudiantes}
        preloader={preloader}
        respuestaApiCrear={respuestaApiCrear}
        sedeSeleccionada={sedeSeleccionada}
        sedesInstituto={sedesInstituto}
        textosInterfaz={textosInterfaz}
      />
    }
  </>
}

export default EstudianteVerificarComponente