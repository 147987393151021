import { useEffect, useState } from 'react';
//Hooks y funciones personalizadas
import useObtenerTraducciones from './../helpers/hooks/useObtenerTraducciones';
import useObtenerFormulariosSoporte from '../helpers/hooks/useObtenerFormulariosSoporte';
import useObtenerPreguntasFrecuentes from '../helpers/hooks/useObtenerPreguntasFrecuentes';
import { EmailAPI } from '../../api/EmailAPI';
//Store
import { useTrackedState } from "../../store";
//Componentes
import AyudaComponent from '../../components/ayuda';

/**
 * Componente de tipo container que no recibe props.  Este container retorna el componente AyudaComponent al cual se le pasa una serie de propiedades que le permiten renderizar la pantalla de ayuda. Las funciones principales de este container son obtener las opciones de correspondiente de la sección y enviar al correo los valores de los formularios.
 * @returns {JSX.Element} AyudaComponent
 */
const Ayuda = () => {
  //Estados globales
  const state = useTrackedState();
  const { planActivo } = state;
  const { tipoUsuario } = planActivo;

  //Hooks personalizados
  const { isReady, textosSeccion: textosInterfaz } = useObtenerTraducciones('ayuda_interfaz');

  const { isReady: isReadySoporte, textosSubSecciones: textoFormulariosSoporte } = useObtenerFormulariosSoporte('ayuda_formularios_soporte');

  const { isReady: isReadyPreguntas, textosSubSecciones: textoPreguntasFrecuentes } = useObtenerPreguntasFrecuentes('ayuda_preguntas_frecuentes');

  //Estados del componente
  const [info_FormulariosSoporte, setInfo_FormulariosSoporte] = useState(null);
  const [tiposDeProblema, setTiposDeProblema] = useState([]);
  const [tiposDePreguntas, setTiposDePreguntas] = useState([]);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [preloader, setPreloader] = useState(false);

  const CORREODESOPORTE = "contacto@makemake.com.co";

  useEffect(() => {

    if (!!tipoUsuario && isReadySoporte && isReadyPreguntas) {
      const get_formularios_usuario = () => {
        let formularioUsuario = {}
        for (const key in textoFormulariosSoporte) {
          if (textoFormulariosSoporte[key].usuarios[tipoUsuario]) formularioUsuario = { ...formularioUsuario, [key]: textoFormulariosSoporte[key] }
          delete textoFormulariosSoporte[key].usuarios;
        }

        get_opciones_problema(formularioUsuario);
        setInfo_FormulariosSoporte(formularioUsuario);
      }

      const get_preguntas_frecuentes = () => {
        let preguntas = {}
        for (const key in textoPreguntasFrecuentes) {
          if (textoPreguntasFrecuentes[key]?.usuarios[tipoUsuario]) preguntas = { ...preguntas, [key]: textoPreguntasFrecuentes[key] }
          delete textoPreguntasFrecuentes[key].usuarios;
        }
        get_opciones_preguntas(preguntas);
      }

      get_preguntas_frecuentes();
      get_formularios_usuario();
    }
  }, [tipoUsuario, textoFormulariosSoporte, textoPreguntasFrecuentes])


  const get_opciones_problema = (value) => {
    let opciones = []
    for (const key in value) opciones.push({ id: key, nombre: value[key].titulo })
    setTiposDeProblema(opciones)
  }

  const get_opciones_preguntas = (value) => {
    let opciones = []
    for (const key in value) opciones.push({ descripcion: value[key].descripcion, titulo: value[key].titulo, titulo_video: value[key].titulo_video })
    setTiposDePreguntas(opciones)
  }

  /**
   * esta funcion obtiene los valores del formulario de "problema libro"
   * @param {string} nombreLibro - string del nombre del libro 
   * @param {string} nombreDispositivo - string del nombre del dispositivo
   * @param {object} tipoProblema - objeto con los valores de los tipos de problemas
   * @param {string} otrosDescripcion - string con otra descripcion del problema
   */
  const submitProblemaLibro = async (nombreLibro, nombreDispositivo, tipoProblema, otrosDescripcion) => {
    setPreloader(true)

    await EmailAPI.enviarProblemaLibro(
      CORREODESOPORTE,
      tipoProblema.sonido,
      tipoProblema.imagen,
      tipoProblema.libro,
      tipoProblema.tipografia,
      tipoProblema.carga,
      tipoProblema.clasificacion,
      nombreLibro,
      nombreDispositivo,
      otrosDescripcion)
      .then(respuesta => {
        const valorRespuesta = respuesta ? true : false;
        setMostrarModal(valorRespuesta)
      })
      .catch(error => { console.log(error) })

    setPreloader(false)
  }

  /**
  * esta funcion obtiene los valores del formulario de "problema en la plataforma"
  * @param {string} dispositivo - string, nombre del dispositivo
  * @param {string} problema - string, texto descriptivo del problema
  */
  const submitProblemaPlataforma = async (dispositivo, problema) => {
    setPreloader(true)

    await EmailAPI.enviarProblemaPlataforma(CORREODESOPORTE, dispositivo, problema)
      .then(respuesta => {
        const valorRespuesta = respuesta ? true : false;
        setMostrarModal(valorRespuesta)
      })
      .catch(error => { console.log(error) })

    setPreloader(false)
  }

  /**
  * esta funcion obtiene los valores del formulario de "dudas sobre ventas"
  * @param {string} dispositivo - string, nombre del dispositivo
  * @param {string} problema - string, texto descriptivo del problema
  */
  const submitDudasVentas = async (tipoDuda, descripcion) => {
    setPreloader(true)

    await EmailAPI.enviarDudasSobreVentas(
      CORREODESOPORTE,
      tipoDuda.suscripcion,
      tipoDuda.renovar,
      tipoDuda.otrosPlanes,
      descripcion
    )
      .then(respuesta => {
        const valorRespuesta = respuesta ? true : false;
        setMostrarModal(valorRespuesta)
      })
      .catch(error => { console.log(error) })

    setPreloader(false)
  }

  /**
   * esta funcion obtiene los valores del formulario de "sugerencias y comentarios"
   * @param {string} sugerenciaComentario 
   */
  const submitSugerenciasYComentarios = async (sugerenciaComentario) => {
    setPreloader(true)

    await EmailAPI.enviarSugerenciasYComentarios(CORREODESOPORTE, sugerenciaComentario)
      .then(respuesta => {
        const valorRespuesta = respuesta ? true : false;
        setMostrarModal(valorRespuesta)
      })
      .catch(error => { console.log(error) })

    setPreloader(false)
  }

  const ocultarModal = () => { setMostrarModal(false) }
  return (
    <>
      {
        isReady && isReadyPreguntas && isReadySoporte && !!info_FormulariosSoporte && (
          <AyudaComponent
            textosInterfaz={textosInterfaz}
            textosFormulariosSoporte={info_FormulariosSoporte}
            preguntasFrecuentes={tiposDePreguntas}
            tiposDeProblema={tiposDeProblema}
            enviarFormulario={{
              enviarProblemaLibro: submitProblemaLibro,
              enviarProblemaPlataforma: submitProblemaPlataforma,
              enviarDudasVentas: submitDudasVentas,
              enviarSugerenciasYComentarios: submitSugerenciasYComentarios,
            }}
            preloader={preloader}
            mostrarModal={mostrarModal}
            ocultarModal={ocultarModal}
          />
        )

      }
    </>
  )
}

export default Ayuda;