import React, { useContext } from "react";
import PropTypes from 'prop-types';
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { AccesibilidadContext, TemasDisponibles } from "../../../contexts/AccesibilidadContext";

/**
 * Tarjeta que renderizará información sobre el total de sesiones en plataforma, se utiliza en la sección de sesiones de plataforma para mostrar la información de estudiantes y profesores por separado.
 */
export const TarjetaInfoSesion = (props) => {
    const { titulo, totalSesionesLectura, tiempoPromedioSesion, estiloPersonalizado } = props;
    const {textosSubSecciones:traducciones} = useObtenerSubsecciones('estadisticas');
    const acc = useContext(AccesibilidadContext);
    const titleClassName = acc.temaActual===TemasDisponibles.altoContraste.id?'background-var1--bg onbackground-var1--color border-b':estiloPersonalizado?.titulo?.class;

    return (
        <div className="flex flex-col rounded-lg overflow-hidden shadow-1 m-1">
            <p 
            className={`w-full p-2 font-bold font-raleway ${estiloPersonalizado?.titulo?.class ? `${titleClassName}` : 'primary-var2--bg onprimary-var1--color'}`}
            style={estiloPersonalizado?.titulo?.style?{...estiloPersonalizado?.titulo?.style}:{}}>{titulo || 'N/A'}
            </p>
            <div className={`flex flex-col p-2 background-var3--bg`}>
                <div>
                    <p className='q5-32 red-inv-var2--color text-center sm:text-justify'>
                        <span className='icon-star-full text-30 mr-3'></span>
                        {totalSesionesLectura || 0}  {totalSesionesLectura !== 1 ? 
                        traducciones?.total_sesiones?.resumen?.sesiones?.plural|| 'Sesiones' 
                        : 
                        traducciones?.total_sesiones?.resumen?.sesiones?.singular || 'Sesion'}
                    </p>
                    <p className="r5-14-negro4A  text-center sm:text-justify">{traducciones?.total_sesiones?.resumen?.sesiones?.descripcion || 'Total de sesiones de lectura'}</p>
                </div>
                <hr className="my-3"/>
                <div>
                    <p className='q5-32 primary-inv-var2--color text-center sm:text-justify'>
                    <span className='icon-tiempo text-30 mr-3'></span>
                        {tiempoPromedioSesion || 0} min
                    </p>
                    <p className="r5-14-negro4A  text-center sm:text-justify">{traducciones?.total_sesiones?.resumen?.tiempo_promedio?.descripcion || 'Tiempo promedio por sesión'}</p>
                </div>
            </div>
        </div>
    )
}

TarjetaInfoSesion.propTypes = {

    /**
     * Titulo de la tarjeta
     */
    titulo: PropTypes.string,
    /**
     * Total de sesiones de lectura
     */
    totalSesionesLectura: PropTypes.number,
    /**
     * Tiempo promedio por sesión
     */
    tiempoPromedioSesion: PropTypes.number,
    /**
     * Estilo personalizado del titulo, puede ser mediante clases o propiedades CSS, solo se tomará una de las 2 opciones
     */
    estiloPersonalizado: PropTypes.shape({
        titulo: PropTypes.shape({
            style:PropTypes.object,
            class:PropTypes.string
        }),
    })
}

