/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import MicroModal from "micromodal";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { store } from "react-notifications-component";
import PropTypes from "prop-types";

//Componentes
import ListaEstudiantes from "./ListaEstudiantes";
import ListaCursos from "./ListaCursos";
import ListaProfesores from "./ListaProfesores";
import DivisionCrud from "../../globales/DivisionCrud";
import Modal from "../../globales/modales/Modal";
import ModalConfirmacion from "../../globales/modales/ModalConfirmacion";

/**
 * Este componente recibe una serie de props que le permiten renderizar la pantalla de verificar datos de institutos/colegios. Además, usa los componentes ListaEstudiantes, ListaCursos, ListaProfesores, DivisionCrud, Modal y ModalConfirmacion
 * @returns VerificarInstitutoComponent
 */
const VerificarInstitutoComponent = (props) => {
  const {
    crearInstituto,
    respuestaApiCrearSede,
    textosInterfaz,
    preloaderInstituto,
    datosSede,
    descartarCambios,
  } = props;
  const { isError } = respuestaApiCrearSede;
  const {
    miga_de_pan,
    verificar_datos,
    botones,
    sede_creada,
    descartar_cambios,
  } = textosInterfaz;
  const {
    color_instituto,
    correo,
    cursos,
    estudiantes,
    nombre,
    paquete,
    profesores,
    telefono,
    titulo,
  } = verificar_datos;

  const [descartarModal, setDescartarModal] = useState(false);

  const history = useHistory();

  const numEstudiantes = datosSede.estudiantes.length;
  const numCurso = datosSede.cursos.length;
  const numProfesores = datosSede.profesores.length;

  useEffect(() => {
    if (isError === false) {
      MicroModal.show(`sede-creada`, {
        awaitCloseAnimation: true,
      });
    } else {
      if (!!respuestaApiCrearSede.mensaje) {
        notificacion(
          textosInterfaz.notificacion.error,
          respuestaApiCrearSede.mensaje,
          "danger"
        );
      }
    }
  }, [isError]);

  useEffect(() => {
    if (descartarModal) {
      MicroModal.show("cancelar-crear", {
        awaitCloseAnimation: true,
      });
    }
  }, [descartarModal]);

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    });
  };

  return (
    <>
      <section className="ruta">
        <p className="font-medium">
          <button
            role="button"
            onClick={() => {
              setDescartarModal(true);
            }}
            className="focusable-primary mr-2"
          >
            {miga_de_pan.principal}
          </button>{" "}
          &gt;
          <Link
            tabIndex={0}
            to="/institutos/crear-instituto"
            className="focusable-primary mx-2"
          >
            {miga_de_pan.crear}
          </Link>{" "}
          &gt;
          <span tabIndex={0} className="focusable-primary ml-2 font-bold">
            {miga_de_pan.verificar}
          </span>
        </p>
      </section>
      <section className="contenido">
        <h1 tabIndex={0} className="focusable-primary titulos-principales">
          {titulo}
        </h1>
        <DivisionCrud>
          <h3
            tabIndex={0}
            className=" focusable-primary raleway-700 onprimary-var2--color"
          >
            {nombre}
          </h3>
          <section
            aria-label={datosSede.nombre}
            tabIndex={0}
            className=" focusable-primary  alineado-verticalmente input-lectura"
          >
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">
              {datosSede.nombre}{" "}
            </p>
          </section>
        </DivisionCrud>

        <DivisionCrud>
          <h3
            tabIndex={0}
            className=" focusable-primary raleway-700 onprimary-var2--color"
          >
            {telefono.titulo}
          </h3>
          <>
            <section
              tabIndex={0}
              aria-label={datosSede.telefono}
              className="focusable-primary input-lectura"
            >
              {!!datosSede.telefono ? (
                <section className="alineado-verticalmente">
                  <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                  <p className="raleway-700 primary-inv-var2--color text-13">
                    {datosSede.telefono}{" "}
                  </p>
                </section>
              ) : (
                <p className={`r7-13-rojo break-words alineado-verticalmente`}>
                  <span className="icon-alerta text-18  mr-3"></span>{" "}
                  {telefono.error}
                </p>
              )}
            </section>
          </>
        </DivisionCrud>

        <DivisionCrud>
          <h3
            tabIndex={0}
            className="focusable-primary raleway-700 onprimary-var2--color"
          >
            {correo.titulo}
          </h3>
          <>
            <section
              tabIndex={0}
              aria-label={datosSede.correo}
              className="focusable-primary input-lectura"
            >
              {!!datosSede.correo ? (
                <section className=" alineado-verticalmente">
                  <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                  <p className="raleway-700 primary-inv-var2--color text-13">
                    {datosSede.correo}{" "}
                  </p>
                </section>
              ) : (
                <p className={`r7-13-rojo break-words alineado-verticalmente`}>
                  <span className="icon-alerta text-18  mr-3"></span>{" "}
                  {correo.error}
                </p>
              )}
            </section>
          </>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3
              tabIndex={0}
              className="focusable-primary raleway-700 onprimary-var2--color"
            >
              {paquete.titulo}
            </h3>
          </>
          <section
            tabIndex={0}
            aria-label={datosSede.paquete.nombre}
            className="focusable-primary alineado-verticalmente input-lectura"
          >
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">
              {datosSede.paquete.nombre}
            </p>
          </section>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3
              tabIndex={0}
              alt="color del colegio"
              className="focusable-primary raleway-700 onprimary-var2--color"
            >
              {color_instituto.titulo}
            </h3>
            <p tabIndex={0} className="focusable-primary  r4-14-gris6a">
              {color_instituto.descripcion}
            </p>
          </>
          <section
            tabIndex={0}
            aria-label={datosSede.color}
            className="focusable-primary h-full alineado-verticalmente"
          >
            <section
              className="boton-color rounded-md h-20 w-36"
              style={{
                backgroundColor: datosSede.color,
                backgroundImage: `url(/images/backBoton.png)`,
              }}
            />
          </section>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3
              tabIndex={0}
              className="focusable-primary raleway-700 onprimary-var2--color"
            >
              {numEstudiantes}{" "}
              {numEstudiantes === 1
                ? estudiantes.titulo.singular
                : estudiantes.titulo.plural}
            </h3>
            <p tabIndex={0} className="focusable-primary  r4-14-gris6a">
              {estudiantes.descripcion}
            </p>
          </>
          <ListaEstudiantes
            datosEstudiantes={datosSede.estudiantes}
            textosTabla={estudiantes.tabla}
          />
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3
              tabIndex={0}
              className="focusable-primary raleway-700 onprimary-var2--color"
            >
              {numCurso}{" "}
              {numCurso === 1 ? cursos.titulo.singular : cursos.titulo.plural}
            </h3>
            <p tabIndex={0} className="focusable-primary r4-14-gris6a">
              {cursos.descripcion}
            </p>
          </>
          <ListaCursos datosCursos={datosSede.cursos} textosTabla={cursos} />
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3
              tabIndex={0}
              className="focusable-primary raleway-700 onprimary-var2--color"
            >
              {numProfesores}{" "}
              {numProfesores === 1
                ? profesores.titulo.singular
                : profesores.titulo.plural}
            </h3>
            <p tabIndex={0} className="focusable-primary  r4-14-gris6a">
              {profesores.descripcion}
            </p>
          </>
          <ListaProfesores
            datosProfesores={datosSede.profesores}
            textosTabla={profesores.tabla}
          />
        </DivisionCrud>

        <section className="flex justify-end my-5">
          <button
            role="button"
            disabled={preloaderInstituto ? true : false}
            onClick={() => {
              history.push("/institutos/crear-instituto");
            }}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 center  mr-5"
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </button>
          <button
            role="button"
            className="focusable-primary boton-justo boton-amarillo boton_hover_naranja text-16 px-5 mt-5 md:mt-0 text-center"
            onClick={crearInstituto}
          >
            {preloaderInstituto ? (
              <section className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </section>
            ) : (
              botones.crear
            )}
          </button>
          {respuestaApiCrearSede.isError === false && (
            /* modal sede creada */
            <ModalConfirmacion nombreModal="sede-creada">
              <Link
                className="icon-cerrar text-20 right-6 absolute"
                to="/institutos"
              ></Link>
              <img
                alt="eyex feliz"
                className="w-5/12 mx-auto bg-img"
                src="/images/eyex-creado.png"
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">
                {sede_creada.titulo}
              </h1>
              <p className="text-center r7-16-negro26 mb-4">
                {sede_creada.descripcion1}{" "}
                <span className="text-resaltado">{datosSede.nombre} </span>{" "}
                {sede_creada.descripcion2}
              </p>
              <section className="center my-3">
                <Link
                  to="/institutos"
                  className="boton-justo boton-amarillo boton_hover_morado py-2"
                >
                  {botones.volver}
                </Link>
              </section>
            </ModalConfirmacion>
          )}
        </section>
      </section>

      {/* modal descartar cambios */}
      {descartarModal && (
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src="/images/eyex-pregunta.png"
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">
            {descartar_cambios.titulo}
          </h1>
          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>
          <section className="center my-5">
            <button
              aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </section>
        </Modal>
      )}
    </>
  );
};

export default VerificarInstitutoComponent;

VerificarInstitutoComponent.propTypes = {
  /**
   * Función que se encarga de llamar el api para crear la institución en la base de datos y actualizar los datos del store.
   */
  crearInstituto: PropTypes.func.isRequired,

  /**
   * objeto que contiene dos propiedades que va a devolver cuando el API crear instituto termine de responder.
   */
  respuestaApiCrearSede: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string,
  }).isRequired,

  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * Booleano que contiene el preloader para el llamado de las API de crear instituto.
   */
  preloaderInstituto: PropTypes.bool.isRequired,

  /**
   * Objeto que cuenta con los datos que se deben visualizar en la verificación de los datos.
   */
  datosSede: PropTypes.shape({
    color: PropTypes.number,
    correo: PropTypes.string, //puede ser null
    cursos: PropTypes.arrayOf(
      PropTypes.shape({
        nombre: PropTypes.string,
        numEstudiantes: PropTypes.number,
        profesor: PropTypes.string, // puede ser null
      })
    ),
    estudiantes: PropTypes.arrayOf(
      PropTypes.shape({
        avatar: PropTypes.number,
        correo: PropTypes.string,
        curso: PropTypes.string,
        nombres: PropTypes.string,
      })
    ),
    nombre: PropTypes.string,
    paquete: PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string,
    }),
    profesores: PropTypes.arrayOf(
      PropTypes.shape({
        avatar: PropTypes.number,
        correo: PropTypes.string,
        curso: PropTypes.array,
        nombres_apellidos: PropTypes.string,
      })
    ),
    telefono: PropTypes.string, // puede ser null
  }).isRequired,

  /**
   *  Es una función que se encarga de borrar los datos temporales que se guardan en el store.
   */
  descartarCambios: PropTypes.func.isRequired,
};
