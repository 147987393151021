/**
 * Obtiene los datos RAW de los profesores traidos desde el API, y los transforma en un objeto en donde se define si existe profesores sin asignar cursos y organiza a los profesores que si estan asignados a cursos y también a los que no.
 * @param {{datos:object, sedes:object}} { datos, sedes}
 * @returns {{profesoresSinAsignar:boolean, resultadosModificados:object}} - Retorna objeto con las sedes y los profesores asignados, también retorna los profesores que no estan asignados.
 */
const transformarDatosProfesores = async ({ datos, sedes }) => {
  let resultadosModificados = {};
  let profesoresSinAsignar = false;
  let sedesProfesores = {};
  let idSedesInstituto = [];
  !!sedes && sedes.length !== 0 && sedes.map(sede => idSedesInstituto.push(sede.id));
  datos.forEach(dato => {
    let cursos = dato.cursos;
    let keyCursos = Object.keys(dato.cursos);
    if (keyCursos.length !== 0) {
      let nombreCursosProfesor = [];
      let key;

      idSedesInstituto.every(idSede => {
        const existeSede = keyCursos.find(sedeProfesor => sedeProfesor === idSede)
        key = !!existeSede ? existeSede : key;

        return !!existeSede ? false : true
      })

      const sede = cursos[key];

      if (!!sede) {
        const cursosProfesor = dato.cursos[Object.keys(dato.cursos)[0]].cursos;
        cursosProfesor.length !== 0 && cursosProfesor.map(curso => nombreCursosProfesor.push(curso.nombre));

        dato = {
          ...dato,
          cursos: cursosProfesor,
          idSede: key,
          nombreCursos: nombreCursosProfesor.join(", "),
          nombreSede: sede.nombre
        }

        sedesProfesores[key] = !!sedesProfesores[key]
          ? sedesProfesores[key] = { ...sedesProfesores[key], profesores: [...sedesProfesores[key].profesores, dato] }
          : sedesProfesores[key] = { nombre: sede.nombre, profesores: [dato] }
      }
    } else {
      profesoresSinAsignar = true;
      dato = {
        ...dato,
        cursos: [],
        idSede: 'sin-asignar',
        nombreCursos: "",
        nombreSede: ""
      }
      sedesProfesores['sin-asignar'] = !!sedesProfesores['sin-asignar']
        ? sedesProfesores['sin-asignar'] = { ...sedesProfesores['sin-asignar'], profesores: [...sedesProfesores['sin-asignar'].profesores, dato] }
        : sedesProfesores['sin-asignar'] = { nombre: 'Sin asignar', profesores: [dato] }
    }
  })


  !!sedes && sedes.length !== 0 && sedes.map(sede => {
    if (!!sedesProfesores[sede.id]) {

    } else {
      sedesProfesores[sede.id] = { nombre: sede.nombre, profesores: [] }
    }
    return sede;
  })

  resultadosModificados = sedesProfesores;

  return { profesoresSinAsignar, resultadosModificados };
}

export default transformarDatosProfesores;