import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Paginacion from "../../../globales/Paginacion";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import moment from 'moment';
import 'moment/locale/es';
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import { useTrackedState } from "../../../../store";

/**
 * Renderizara una tabla con las estadísticas de todos los ingresos y lecturas realizadas por los profesores, estas estadísticas son pasadas de padre a hijo por props. 
 */
const TablaIngresos = (props) => {
  const state = useTrackedState();
  const { datos, filtros } = props;
  //TRADUCCIONES--------
  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('estadisticas');
  const { ingresos_individuales_profesores, botones } = traducciones || {};
  const { tabla, resultados } = ingresos_individuales_profesores || {};
  const { no_hay, nombres_apellidos, fecha, colegio, tiempo_promedio, correo, tiempo_promedio_lectura } = tabla || {};
  const { hay, resultado } = resultados || {};
  // FIN TRADUCCIONES--------
  const [datosDivididos, setDatosDivididos] = useState([]);
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  const history = useHistory();

  return (
    <>
      <div className={`${datos.length > 10 ? null : 'mb-5'}`}>
        <div className="encabezadoTabla  xl:grid grid-cols-12 ">
          <p className="col-span-2 alineado-verticalmente">{correo || 'Correo'}</p>
          <p className="col-span-2 alineado-verticalmente">{nombres_apellidos}</p>
          <p className="col-span-1 alineado-verticalmente">{fecha}</p>
          <p className="col-span-2 alineado-verticalmente">{colegio}</p>
          <p className="col-span-1 alineado-verticalmente text-center">{tiempo_promedio}</p>
          <p className="col-span-2 alineado-verticalmente text-center">{tiempo_promedio_lectura || 'Tiempo promedio de lectura'}</p>
          <p className="col-span-1"></p>
        </div>
        <>
          {datosDivididos.length === 0 ?
            <div className="filaTabla xl:grid-cols-12">
              <div className=" center col-span-12">
                <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                <p className="raleway-700 red-inv-var2--color text-13">{no_hay}</p>
              </div>
            </div>
            :
            datosDivididos.map((dato,i) => {
              return (
                <div key={`campo-${i}`} className="filaTabla xl:grid-cols-12">
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2 overflow-auto">
                    <span className="q7-14-negro26  xl:hidden">{correo || 'Correo'}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} title={dato?.username || 'N/A'}>{dato.username || 'N/A'}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2 overflow-auto">
                    <span className="q7-14-negro26  xl:hidden">{nombres_apellidos}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} title={dato.nombreCompleto}>{dato.nombreCompleto}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-1">
                    <span className="q7-14-negro26  xl:hidden">{fecha}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} title={FiltroFechas.formatoUsuario(state?.idioma, dato.fechaLectura)}>{FiltroFechas.formatoUsuario(state?.idioma, dato.fechaLectura) || 'N/A'}</p>
                    <p className={`r5-13-negro26 una-linea-texto `} title={moment(dato.fechaLectura).format(`hh:mm A`)}>{moment(dato.fechaLectura).format(`hh:mm A`) || 'N/A'}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2">
                    <span className="q7-14-negro26  xl:hidden">{colegio}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} title={dato?.instituto || 'N/A'} >{dato?.instituto || 'N/A'}</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-1">
                    <span className="q7-14-negro26  xl:hidden">{tiempo_promedio}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center`} title={dato?.tiempoPromedioDeSesion}>{dato.tiempoPromedioDeSesion} min</p>
                  </div>
                  <div tabIndex={0} className="focusable-red fila-12-sm4-lg6 xl:col-span-2">
                    <span className="q7-14-negro26  xl:hidden">{tiempo_promedio_lectura || 'Tiempo promedio de lectura'}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center`} title={dato?.tiempoPromedioDeLectura}>{dato?.tiempoPromedioDeLectura} min</p>
                  </div>
                  <div className="fila-12 xl:col-span-2">
                    <div className="alineado-izquierda-center">
                      <button
                        onClick={() => history.push({
                          pathname: `/estadisticas/ingresos-individuales/profesores${Boolean(dato?.username) ? `/${dato?.username}` : ''}`,
                          search: `?fechaInicio=${filtros?.fechas?.inicio}&fechaFin=${filtros?.fechas?.fin}`
                        })}
                        className="focusable-red boton-justo boton-amarillo boton_hover_morado center w-full lg:w-auto lg:px-5 py-2">
                        <p>{botones?.ver_detalle || 'Ver detalle'} </p>
                      </button>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </>
        <div className="md:alineado-verticalmente xl:mt-3">
          <div className={`w-full md:w-6/12 `}>
            <p className="r4-16-negro4A">{hay} {datos.length} {datos.length !== 1 ? resultado?.plural : resultado?.singular} </p>
          </div>

          <div className={`w-full md:w-6/12 alineado-izquierda mt-5 md:mt-0`}>
            <Paginacion datos={datos} datosDivididos={ActualizarDatosDivididos} />
          </div>
        </div>

      </div>
    </>
  )
}
export default TablaIngresos;

TablaIngresos.propTypes = {
  /**
   * Arreglo de objetos con los datos de la tabla
   */
   datos: PropTypes.arrayOf(PropTypes.shape({
    username:PropTypes.string,
    instituto: PropTypes.string,
    nombreCompleto: PropTypes.string,
    fechaLectura: PropTypes.string,
    tiempoTotalLectura: PropTypes.number,
    sesionesDeLectura: PropTypes.number,
    tiempoPromedioDeLectura: PropTypes.number,
    tiempoPromedioDeSesion: PropTypes.number,
    idSede: PropTypes.string
})).isRequired,
  /**
 * filtros que serán usados para ver los detalles del estudiante, por el momento solo se rquieren las fechas (inicio, fin)
 @example 
 {
   fechas:{
     inicio:'',
     fin:''
   }
 }
 * 
 */
  filtros: PropTypes.shape({
    fechas: PropTypes.shape({
      inicio: PropTypes.string,
      fin: PropTypes.string,
    })
  })
}