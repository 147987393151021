import removeAccents from "./removeAccents";
import listarObject from './listarElementos/listarObject';

/**
 * Permite realizar busqueda de elementos o propiedades dentro de un objeto en particular,  retorna un objeto con los resultados obtenidos y con una propiedad que indica si se encontro o no resultados.
 * @param {{ datos, isCursos, curso, propiedad, sede, value }} objeto con las propiedades necesarias para realizar la busqueda 
  @returns {{
    hayResultados:boolean, 
    resultados:object | array | null | undefined
 }} Retorna los resultados obtenidos en un objeto
 */
const buscarElementos = ({ datos, isCursos, curso, propiedad, sede, value }) => {
  let resultados = datos;
  let hayResultados = true;

  if (!!sede) {
    if (isCursos) {
      if (!!resultados[sede]) {
        const estudiantes = listarObject({ object: datos, idSede: sede, idCurso: curso, isCursos, propiedad: 'estudiantes' })
        const { resultados: resultadoEstudiantes } = estudiantes;
        resultados = resultadoEstudiantes.resultados;
      }
    } else {
      resultados = !!resultados[sede][propiedad] ? resultados[sede][propiedad] : []
    }
  }


  if (value !== '') {
    //Valor del input 
    const input = removeAccents(value);

    resultados = resultados.filter(dato => {
      //Nombre de las sedes del instituto 
      const nameInstituto = removeAccents(dato.nombre);
      return nameInstituto.search(input) !== -1;
    })
    hayResultados = resultados.length !== 0 ? true : false;
  }

  return ({ hayResultados, resultados });
}

export default buscarElementos;