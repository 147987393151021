//Hooks y funciones personalizadas
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';

//componentes
import TotalSesionesComponent from '../../components/estadisticas/TotalSesiones/TotalSesionesComponent';
import SkeletonTotalSesiones from '../../components/estadisticas/SkeletonsEstadisticas/SkeletonTotalSesiones';

const TotalSesiones = () => {

  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
    <>
      {isReady ?
        <TotalSesionesComponent traducciones={textosInterfaz} />
        :
        <SkeletonTotalSesiones />
      }

    </>
  )
}
export default TotalSesiones;