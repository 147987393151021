import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Componente que recibe los textos de interfaz y el rol activo para renderizar la pantalla error 404. Esta pantalla se visualiza cuando no se encuentra la página que se busca a través de la url.
 * @returns ERROR_404_COMPONENT
 */
const ERROR_404_COMPONENT = (props) => {
  const { textosInterfaz, rol } = props;
  const { miga_de_pan, titulo, descrpcion, boton } = textosInterfaz;

  const [link, setLink] = useState("");

  useEffect(() => {
    const rutaIndicada =
      rol !== "estudiante" ? "/institutos" : "/estudiante/home";
    setLink(rutaIndicada);
  }, [rol]);

  return (
    <>
      <section className="ruta">
        <p>{miga_de_pan}</p>
      </section>
      <section className="contenido">
        <section className="center">
          <section
            tabIndex={0}
            aria-label="Error 404"
            className=" focusable-primary  relative w-451p h-448 grid content-end"
          >
            <section className="relative surface-var2--bg rounded-lg w-full sm:h-auto text-center p-5 pb-7">
              <section
                className=" rounded-lg dibujo-error onprimary-var1--bg no-repeat"
                style={{ backgroundImage: `url(/images/error-404.png)` }}
              ></section>
              <span className="icon-alerta red-inv-var1--color text-80"></span>
              <h1 alt="oh no" className="q7-40 onprimary-var1--color">{titulo}</h1>
              <p alt="No encontramos la pagina que estabas buscando" className="r5-24 lg:w-10/12 mx-auto onprimary-var1--color">
                {descrpcion}
              </p>
            </section>
          </section>
        </section>

        <div className="center mt-8">
          <Link
            role="button"
            to={link}
            className="boton-justo boton-amarillo boton_hover_morado px-10 py-2 focusable-primary onprimary-inv-var2--color"
          >
            {boton}
          </Link>
        </div>
      </section>
    </>
  );
};

export default ERROR_404_COMPONENT;

ERROR_404_COMPONENT.propTypes = {
  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * String que indica el tipo de usuario activo 'rector', 'estudiante' o 'profesor'
   */
  rol: PropTypes.string.isRequired,
};
