import { useEffect, useState } from "react";
//Store
import { useDispatch, useTrackedState } from "../../store";
import generalCallApi from "./generalCallApi";
//Funciones
import agregarDatosCurso from "./../../utilities/function/agregarDatosCurso";
import transformarDatosEstudiantes from "./../../utilities/function/estudiantes/transformarDatosEstudiantes";
import transformarDatosProfesores from "./../../utilities/function/profesores/transformarDatosProfesores";
import transformarClubs from "./../../utilities/function/clubs/transformarClubs";
import { CancelRequestController } from "../../utilities/cancel-request-controller";

/**
 * Dependiendo de la sección que se quiera cargar, se realiza una llamada a la API, se trae la información y se organiza para luego ser desplegada en el store.
 * @param {*} props
 * @returns {{isLoading:boolean}} - Objeto con el estado de carga de la información.
 */
const useInitialDataOfSection = (props) => {
	const { pathname, properties, propiedadJson, property, section } = props;

	const state = useTrackedState();
	const { datosInstitucion } = state;
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let abortController = new CancelRequestController();
		let isUnMounted = false;

		const callApi = async () => {
			if (isUnMounted) {
				return;
			}
			try {
				let results = await generalCallApi({
					pathname,
					properties,
					config: { signal: abortController.signal },
				});
				let value;

				switch (section) {
					case "profesores":
						const datosProfesores = await transformarDatosProfesores({
							datos: results.data,
							sedes: datosInstitucion.sedes,
						});
						value = {
							resultados: datosProfesores.resultadosModificados,
							"sin-asignar": datosProfesores.profesoresSinAsignar,
						};
						break;
					case "cursos":
						value = await agregarDatosCurso({
							datos: results.data,
							section,
							propiedadJson,
						});
						break;
					case "estudiantes":
						value = await transformarDatosEstudiantes({
							datos: results.data,
							sedes: datosInstitucion.sedes,
						});
						break;
					case "clubs":
						value = await transformarClubs({ datos: results.data });
						break;
					default:
						value = results.data;
				}

				//Se debe elimiinar cuando la estructura del api se la correcta
				if (pathname === "/sedes/obtenerInstituto") {
					const resultDatosInstituto = results;
					let paquetesSede = [];
					resultDatosInstituto.data.paquetes.length !== 0 &&
						resultDatosInstituto.data.paquetes.map((paquete) =>
							paquetesSede.push({ nombre: paquete })
						);

					let sedes = [];
					resultDatosInstituto.data.sedes.length !== 0 &&
						resultDatosInstituto.data.sedes.map((sede) => {
							return sedes.push({
								...sede,
								numeroTotalDeProfesores: !!sede.numeroTotalDeProfesores
									? sede.numeroTotalDeProfesores
									: 0,
							});
						});

					resultDatosInstituto.data = {
						...resultDatosInstituto.data,
						id: resultDatosInstituto.data.idInstituto,
						pais: "CO",
						paquetes: paquetesSede,
						sedes: sedes,
					};

					value = resultDatosInstituto.data;
				}

				dispatch({
					property: property,
					type: "SET_DATA",
					value: value,
				});

				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		callApi();
		return () => {
			isUnMounted = true;
			abortController.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { isLoading };
};

export default useInitialDataOfSection;
