import Modal from "../../globales/modales/Modal";
import PropTypes from "prop-types";


/**
 * Modal generico para mostrar una advertencia en la sección de libros.
 */
const ModalAdvertencia = (props) => {
  const { nombreDelModal, titulo, descripcion } = props;
  return (
    <Modal nombreModal={nombreDelModal}>
      <div className="border-b onbackground-var2--color pb-3">
        <span className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
        <h2 className="q7-28 onbackground-var2--color text-center">{titulo || 'N/A'} </h2>
      </div>

      <p className="lg:w-9/12 mx-auto text-center r7-16-negro26 mt-5">
       {descripcion || 'N/A'}
      </p>
    </Modal>
  )
}
export default ModalAdvertencia;

ModalAdvertencia.propTypes = {
  /**
   * el nombre requerido para abrir la ventana modal
   */
  nombreDelModal: PropTypes.string.isRequired,

  /**
   * texto o elemento personalizado
   */
  titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * descripcion de la advertencia, puede ser text o un elemento  personalizado
   */
   descripcion:  PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}