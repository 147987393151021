const DataTextos = require('../../modules/dataTextos.json');

/**
 * Retorna un objeto con los textos de la interfaz gráfica de una sección específica
 * @param {string} seccion - Sección (pestaña) del archivo de excel  que se desea obtener los textos.
 * @param {string} idioma - Idioma en que se quiere los textos
 * @param {string} subseccion - sección especifica dentro de la sección pasada por parámetro.
 * @returns {object} - Objeto con los textos de la sección especificada.
 */
const ObtenerTraducciones = (seccion, idioma, subseccion) => {
  const seccionEspecifica = DataTextos[seccion];
  if(seccionEspecifica){
    let textos = {};
    let textosFinales = seccionEspecifica
    if(subseccion){
      textosFinales = seccionEspecifica.filter(item=>item.seccion === subseccion);
    }
    textosFinales.forEach((item)=>{
      textos[item.id] = item[idioma];
    })
    return textos;
  }
  return false
}

export default ObtenerTraducciones;