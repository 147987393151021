import { Icon } from '@iconify/react';
import MicroModal from 'micromodal';
import { useEffect } from 'react';
import useObtenerSubsecciones from '../../../containers/helpers/hooks/useObtenerSubsecciones';
import PropTypes from "prop-types";
import { FocusScope } from "react-aria";

/**
 * Este componente renderiza una ventana modal la cual indica que la suscripción del cliente ha caducado, dependiendo del rol de usuario mostrara un mensaje en especifico.
 * @returns ModalSuscripcionCaducada
 */
export const ModalSuscripcionCaducada = (props) => {
    const { visible, setVisible, userType } = props;
    const idModal = 'modal-suscripcion-caducada';
    const { textosSubSecciones: traducciones } = useObtenerSubsecciones('login');

    const messages = {
        teacher: {
            title: traducciones?.modal?.suscripcion_caducada?.profesor?.titulo
                || 'Tu suscripción ha caducado',
            description: traducciones?.modal?.suscripcion_caducada?.profesor?.descripcion
                || 'Comunicate con ventas para renovar el período de la suscripción o escribenos a contacto@makemake.com.co'
        },
        student: {
            description: traducciones?.modal?.suscripcion_caducada?.estudiante?.descripcion
                || 'Tu usuario no está disponible,comunícate con tu institución para más información.'
        }
    }


    const close = () => {
        try {
            setVisible && setVisible(false);
        } catch (error) {

        }
    }

    useEffect(() => {
        if (visible === true) {
            MicroModal.show(idModal, {
                awaitCloseAnimation: true
            });
        } else {
            try {
                MicroModal.close(idModal);
            } catch (error) {

            }
        }
    }, [visible])



    return (
        <FocusScope contain>
            <div className="micromodal-slide" id={idModal} aria-hidden="true">
                <div className="modal__overlay">
                    <div
                        className="modal__container_error scroll max-h-screen"
                        role="alertdialog"
                        aria-modal="true"
                    >
                        <button
                            className="icon-cerrar text-20 style-icon-crerrar right-6 absolute focusable-red"
                            onClick={close}
                            aria-label="cerrar ventana"
                        ></button>
                        <div className='flex flex-col center text-center'>
                            <Icon icon="dashicons:warning" className='red-inv-var2--color' width={80} />
                            <h1 className='text-28 font-bold onsecondary-var1--color'>
                                {userType !== "estudiante" ? messages.teacher.title : ''}
                            </h1>
                            <p className='onsecondary-var1--color text-18 font-raleway w-full text-center'>
                                {userType !== "estudiante" ? messages.teacher.description : messages.student.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </FocusScope>
    )
}

ModalSuscripcionCaducada.propTypes = {
    /**
     * String donde se indica que tipo de usuario se está intentando loguear, para que el modal sepa que mensaje debe mostrar.
     */
    userType: PropTypes.oneOf(['estudiante', 'profesor', 'rector']),
    /**
     * Booleano que indica si es visible o no la ventana modal
     */
    visible: PropTypes.bool.isRequired,
    /**
     * función que tiene como parametro un valor booleano, el cual permite modificar el estado de visibilidad.
     */
    setVisible: PropTypes.func.isRequired,
}