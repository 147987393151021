import React from "react";
import { BooksSkeleton } from "../../components/skeletons/books-skeleton";
import { Miga, MigasDePan } from "../../../../components/globales/MigaDePan";
import { useHistory } from "react-router-dom";
import { BooksList } from "./book-list";
import NoHayResultados from "../../../../components/globales/NoHayResultados";
import { AccionesLibroAPI } from "../../../../api/AccionesLibroAPI";

export const BooksPage = ({
	loading,
	breadcrumb,
	title,
	description,
	books,
	noResults,
	favoritesFeature = true,
}) => {
	const history = useHistory();

	const addRemoveFavorites = async (libro, favorito) => {
		await AccionesLibroAPI.agregarRemoverAFavoritos([libro?.idLibro], favorito).catch((error) =>
			console.log(error)
		);
	};

	const openBookReader = (libro) => {
		history.push({
			pathname: `/visor/${libro.idLibro}`,
			state: { idLibro: libro.idLibro, pathAnterior: "/estudiante/libros" },
		});
	};

	return (
		<>
			{loading ? (
				<BooksSkeleton />
			) : (
				<>
					<div className="relative">
						<div className="primary-var2--bg primary-inv-var2--bordered py-0.5 rounded-br-3xl">
							<MigasDePan>
								<Miga
									texto={breadcrumb?.source?.name}
									alSeleccionar={() => {
										history.push(breadcrumb?.source?.path);
									}}
								/>
								<Miga texto={title || "N/A"} esRutaActual={true} />
							</MigasDePan>
							<div className="p-5">
								<h1 className="w-full q7-28 onprimary-var1--color border-b-2 onprimary-var1-borderedb--color">
									{title}
								</h1>
								<p className="font-quicksand font-bold text-18  onprimary-var1--color">
									{description}
								</p>
							</div>
						</div>
						<div
							className="sticky block  left-0 w-full  z-20 rounded-br-2xl"
							style={{ top: 63 }}
						></div>

						<section className="contenido">
							<BooksList
								libros={books || []}
								ocultarElementos={{
									titulo: true,
									descripcion: true,
									favoritos: true,
								}}
								ocultarOpciones={{
									seleccionado: true,
									botonMultiSeleccion: true,
									botonFavoritos: favoritesFeature?false:true,
								}}
								seccionVaciaElementoPersonalizado={
									<NoHayResultados
										titulo={noResults?.title || "No hay nada por aquí aún..."}
										descripcion={
											noResults?.description ||
											"No se encontraron libros en esta sección todavia."
										}
									/>
								}
								alAgregarLibroAFav={(libro) => addRemoveFavorites(libro, true)}
								alRemoverLibroDeFav={(libro) => addRemoveFavorites(libro, false)}
								alSeleccionarLibro={(libro) => openBookReader(libro)}
							/>
						</section>
					</div>
				</>
			)}
		</>
	);
};
