import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonEstudiantesGeneral = () => {
  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
        <Skeleton className="ruta" width={188} height={40} />
        <div className="contenido">
          <h1 className="titulos-principales flex ">
            <div className="w-40 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>

          <div className="py-5 xl:w-96"  >
            <Skeleton width={'100%'} height={30} />
          </div>

          <div className="md:flex justify-between mt-3 gap-4">
            <div className="md:w-6/12 xl:w-3/12">
              <Skeleton width={'100%'} height={36} />
            </div>
            <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0">
              <Skeleton width={'100%'} height={36} />
            </div>
            <div className="md:w-4/12 xl:w-451p mt-6 md:mt-0">
              <Skeleton width={'100%'} height={36} />
            </div>
          </div>

          <div className="md:flex justify-between  gap-4 py-5 mb-5 border-b-2 border-gris-linea">
            <div className="xl:alineado-izquierda xl:order-3  md:w-4/12  xl:w-451p ">
              <div className=" w-40">
                <Skeleton width={'100%'} height={36} />
              </div>
            </div>

            <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0 md:order-1">
              <Skeleton width={'100%'} height={36} />
            </div>

            <div className=" md:w-4/12 xl:w-3/12 mt-6 md:mt-0 md:order-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </div>


          <div className="encabezadoTabla  xl:grid grid-cols-12">
            <p className="col-span-1"></p>
            <p className="col-span-1 ">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-2">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1">
              <Skeleton width={'100%'} height={40} />
            </p>
            <p className="col-span-1"></p>
          </div>

          <div className="filaTabla xl:grid-cols-12">
            <div className="fila-2-sm1-lg12-xl1  sm:row-span-6 md:row-span-4 lg:row-span-6 xl:row-span-1  h-7 relative  ">
              <Skeleton width={30} height={'100%'} />
            </div>

            <div className="fila-12-sm5-md3-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md4-lg5 xl:col-span-2 ">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md4-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12 xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md3-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md4-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md4-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md3-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md4-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm5-md4-lg5 xl:col-span-1">
              <div className="w-6/12 xl:w-10/12  xl:hidden">
                <Skeleton width={'100%'} height={24} />
              </div>
              <div className="md:w-10/12">
                <Skeleton width={'100%'} height={24} />
              </div>
            </div>

            <div className="fila-12-sm11-lg12 xl:col-span-1">
              <div className="alineado-izquierda-center">
                <div className="w-full md:w-36 xl:w-8 ">
                  <Skeleton width={'100%'} height={30} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  )
}
export default SkeletonEstudiantesGeneral;