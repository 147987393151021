import { useEffect, useState, useContext } from "react";
import { CursosAPI } from "../../../api/CursosAPI";
import { SedesAPI } from "../../../api/SedesAPI";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Calendario } from "../../globales/Calendario";
import SelectorDeBusqueda from "../../globales/elementosFormularios/SelectorDeBusqueda";
import PropTypes from 'prop-types';
import { useDispatch, useTrackedState } from "../../../store";
import { AccesibilidadContext } from '../../../contexts/AccesibilidadContext';

/**
 * Este componente es el filtro que se usa dentro del componente 'ComparativoComponent', se encarga de mostrar las sedes, cursos, fechas y de avisar los cambios que se realicen en los filtros o los reportes comparativos mediante funciones callbacks pasadas en las props, permitiendo al componente padre leer los filtros y usarlos con base a sus necesidades.
 */
const Filtro = (props) => {
  const { comparaciones,
    limiteComparaciones,
    sedePorDefecto,
    cursoPorDefecto,
    calendarioProps,
    alActualizarFiltros,
    alAgregarReporte } = props;
  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('estadisticas');
  const [sedes, setSedes] = useState([]);
  const [cursosSede, setCursosSede] = useState([]);
  const [cursos, setCursos] = useState([]);
  const sedesTodas = { id: 'todo', nombre: traducciones?.global?.select?.sedes_todas || 'Todas las sedes' }
  const cursosTodos = { id: 'todo', nombre: traducciones?.global?.select?.cursos_todos || 'Todos los cursos' }
  const [cargando, setCargando] = useState(true);
  const accesibilidad = useContext(AccesibilidadContext);

  const [sede, setSede] = useState(undefined);
  const [curso, setCurso] = useState(undefined);
  const [fechas, setFechas] = useState([]);
  const [filtros, setFiltros] = useState({
    fechas: [],
    sede: undefined,
    curso: undefined
  })

  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';
  const dispatch = useDispatch();




  const [reporteValido, setReporteValido] = useState({ valido: true, idTraduccion: '', textoAlternativo: 'Error' });

  const cambiarCurso = ({ opcion }) => {
    setCurso(opcion);
    dispatch({
      type: "SECCION_ESTADISTICAS_FILTROS",
      property: "curso",
      value: opcion?.id
    })
  }
  const cambiarSede = ({ opcion }) => {
    setSede(opcion);
    dispatch({
      type: "SECCION_ESTADISTICAS_FILTROS",
      property: "sede",
      value: opcion?.id
    })
    cambiarCurso({opcion:cursosTodos})
  }

  useEffect(() => {

    const cargarDatos = async () => {
      setCargando(true);
      await SedesAPI.obtenerTodas()
        .then(resultado => {
          setSedes(resultado)
        })
        .catch(error => console.log(error))

      await CursosAPI.obtenerTodos()
        .then(resultado => {
          setCursos(resultado)
        })
        .catch(error => console.log(error))
      setCargando(false);
    }
    cargarDatos();
  }, [])


  useEffect(() => {
    const sedeEncontrada = sedes.find(sede => sede?.id === sedePorDefecto);
    const cursoEncontrado = cursos.find(curso => curso?.id === cursoPorDefecto);
    sedeEncontrada ? setSede(sedeEncontrada) : setSede(sedesTodas);
    cursoEncontrado ? setCurso(cursoEncontrado) : setCurso(cursosTodos);
  }, [sedes, cursos, sedePorDefecto, cursoPorDefecto])



  useEffect(() => {
    const cargarCursos = async () => {
      if (rolUsuario === 'rector') {
        const cursosDeSede = cursos.filter(curso => curso?.idSede === sede?.id);
        if (cargando == false) {
          setCurso(cursosTodos);
        }
        if (sede?.id !== sedesTodas.id) {
          setCursosSede(cursosDeSede)
        } else {
          setCursosSede([]);
        }
      }
      else {
        setCursosSede(cursos);
      }

    }
    cargarCursos();
  }, [cursos, sede?.id])

  useEffect(() => {
    setFiltros({
      fechas:fechas,
      sede: rolUsuario === 'rector'?sede:null,
      curso: curso
    })
  }, [ JSON.stringify(fechas), sede?.id, curso?.id])


  useEffect(()=>{
    if(rolUsuario === 'rector'){
      if(filtros?.fechas && filtros?.sede && filtros?.curso){
        alActualizarFiltros&&alActualizarFiltros(filtros)
      }
    }else{
      if(filtros?.fechas && filtros?.curso){
        alActualizarFiltros&&alActualizarFiltros(filtros)
      }
    }
  },[JSON.stringify(filtros)])


  const agregarReporte = () => {
    const existeComparacion = comparaciones.reduce((acc, curr) => {
      if (curr?.sede?.id === filtros?.sede?.id && curr?.curso?.id === filtros?.curso?.id) {
        acc = true;
      }
      return acc;
    }, false)

    if (existeComparacion) {
      setReporteValido({
        valido: false,
        idTraduccion: 'ya_existe',
        textoAlternativo: 'Esta sede y curso ya están siendo comparados.'
      })
      return;
    }
    alAgregarReporte && alAgregarReporte(filtros)

    setReporteValido({
      valido: true,
      idTraduccion: '',
      textoAlternativo: ''
    })

  }

  useEffect(()=>{
    if(comparaciones?.length <= 0){
      setFiltros({
        ...filtros,
        curso:cursosTodos,
        sede:sedesTodas
      })
      setSede(sedesTodas)
      setCurso(cursosTodos)
      setCursosSede([])
      setReporteValido({
        valido: true,
        idTraduccion: '',
        textoAlternativo: ''
      })
    }
  },[comparaciones])



  return (
    <>
      <div className="lg:grid grid-cols-12 mt-3">
        {/* calerendario */}
        <div className="lg:col-span-6 xl:col-span-3 mt-3 lg:mt-0 lg:p-3">
          <div className="alineado-verticalmente  mb-3">
            <span className={`w-8 h-8 rounded-full center quicksand-700 text-18 ${accesibilidad.temaActual === "clasico" ? 'bg-morado-alfa' : 'ondisabled-var1--bg'}`}>1</span>
            <h2 className=" quicksand-700 una-linea-texto ml-1 onbackground-var1--color">{traducciones?.filtro?.fecha || 'Filtrar por fecha'}</h2>
            <span className="icon-atras transform rotate-180  ml-2 hidden xl:block onbackground-var1--color"></span>
          </div>
          <Calendario
            fechaMaxima={calendarioProps?.fechaMaxima}
            fechaMinima={calendarioProps?.fechaMinima}
            fechaPorDefecto={calendarioProps?.fechaPorDefecto}
            formato={calendarioProps?.formato}
            modoMultiple={calendarioProps?.modoMultiple}
            modoRango={calendarioProps?.modoRango}
            placeholder={calendarioProps?.placeholder}
            zIndex={calendarioProps?.zIndex}
            alCambiar={(fechas) => {
              setFechas(fechas);
              dispatch({
                type: "SECCION_ESTADISTICAS_FILTROS",
                property: "fechas",
                value: {inicio:fechas[0], fin:fechas[1]}
              })
              calendarioProps?.alCambiar&&calendarioProps?.alCambiar(fechas)
            }}
          />
        </div>

        {
          rolUsuario == 'rector' &&
          <>
            {/* sede */}
            <div className=" lg:col-span-6 xl:col-span-4 mt-3 lg:mt-0 lg:p-3">
              <div className="alineado-verticalmente mb-3">
                <span className={`w-8 h-8 rounded-full center quicksand-700 text-18 ${accesibilidad.temaActual === "clasico" ? 'bg-morado-alfa' : 'ondisabled-var1--bg'}`}>2</span>
                <h2 className="quicksand-700 una-linea-texto ml-1 onbackground-var1--color">{traducciones?.filtro?.sede || 'Filtrar por sede'} <span className="primary-inv-var2--color">{traducciones?.filtro?.opcional || '(opcional)'}</span></h2>
                <span className="icon-atras transform rotate-180  ml-2 hidden xl:block onbackground-var1--color"></span>
              </div>
              <SelectorDeBusqueda
                cambiarElegido={cambiarSede}
                opciones={comparaciones?.length == 0 ? [sedesTodas, ...sedes] : sedes}
                textoNoHayOpciones={traducciones?.select?.sede?.no_existe}
                textoDefault={traducciones?.comparativa?.select?.sede?.default || 'Escoger sede'}
                textoElegido={filtros?.sede?.nombre}
                superposicion={true}
              />
            </div>
          </>
        }

     

        {/* curso */}
        <div className="lg:col-span-6 xl:col-span-3  mt-3 lg:mt-0 lg:p-3 ">
          <div className="alineado-verticalmente mb-3">
            <span className={`w-8 h-8 rounded-full center quicksand-700 text-18 ${accesibilidad.temaActual === "clasico" ? 'bg-morado-alfa' : 'ondisabled-var1--bg'}`}>{rolUsuario==='rector'?'3':'2'}</span>
            <h2 className="quicksand-700 una-linea-texto ml-1 onbackground-var1--color">{traducciones?.filtro?.curso || 'Escoger curso'} <span className="primary-inv-var2--color">{traducciones?.filtro?.opcional || '(opcional)'}</span></h2>
          </div>
          <SelectorDeBusqueda
            cambiarElegido={cambiarCurso}
            opciones={[cursosTodos, ...cursosSede]}
            textoNoHayOpciones={traducciones?.comparativa?.select?.curso?.no_existe || 'No hay cursos asignados'}
            textoDefault={traducciones?.comparativa?.select?.curso?.default || 'Selecciona un curso'}
            textoElegido={filtros?.curso?.id === cursosTodos?.id ? cursosTodos?.nombre : filtros?.curso?.nombre}
            zIndex={59}
          />

        </div>

        {
          filtros?.sede?.id !== sedesTodas.id &&
          <div className="lg:col-span-6 xl:col-span-2 mt-5 lg:mt-0 lg:p-3 flex items-end ">
            <button
              disabled={comparaciones?.length <= limiteComparaciones-1 ? false : true}
              onClick={() => agregarReporte()}
              className="boton-justo boton-azul boton_hover_morado py-1 una-linea-texto focusable-red"
            >
              {comparaciones?.length !== 0 ? traducciones?.botones?.filtro?.anadir_otro : traducciones?.botones?.filtro?.anadir_reporte} {comparaciones.length > 0 ? `(${comparaciones.length})` : ''}
            </button>
          </div>

        }

      </div>
        {Boolean(reporteValido?.valido) === false &&
          <h1 className="red-inv-var1--color quicksand-700 mt-2">{`${traducciones?.comparativa?.filtros?.reportes?.[`${reporteValido?.idTraduccion}`] || reporteValido?.textoAlternativo}`}</h1>
        }

      <hr className="my-8" />
    </>
  )
}
export default Filtro;

Filtro.propTypes = {
  /**
* el limite de comparaciones que se puede realizar.
*/
  limiteComparaciones: PropTypes.number,
  /**
* arreglo de las comparaciones que se estan llevando a cabo
*/
  comparaciones: PropTypes.array,
  /**
   * todas las propiedades que posee el componente de calendario
   */
  calendarioProps: PropTypes.shape({
    fechaMaxima: PropTypes.string,
    fechaMinima: PropTypes.string,
    fechaPorDefecto: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    formato: PropTypes.string,
    modoMultiple: PropTypes.bool,
    modoRango: PropTypes.bool,
    placeholder: PropTypes.string,
    zIndex: PropTypes.number,
    /**
 * funcion callback que devuelve un arreglo con fechas seleccionadas en el calendario.
 * EJEMPLO
 * @example
 <Calendario
 alActualizarFiltros={(fechas)=>console.log(fechas)}/>
 */
    alActualizarFiltros: PropTypes.func
  }),
  /**
   * Id de la sede que cargara por defecto, si no se pasa se carga todas las sedes
   */
  sedePorDefecto:PropTypes.string,
  /**
   * id del curso que cargara por defecto, si no se pasa se carga todos los cursos
   */
  cursoPorDefecto:PropTypes.string,
  /**
   * funcion que se ejecuta cuando se modifican los filtros, tiene como parametro un objeto con la sede, curso y fechas actualizadas.
   */
  alActualizarFiltros:PropTypes.func,
    /**
   * funcion que se ejecuta cuando se agregan reportes, tiene como parametro un objeto con la sede, curso y fechas actualizadas.
   */
  alAgregarReporte:PropTypes.func
}