import React, { useState, useEffect, useRef } from "react";

/*componentes */
import InicioEstudiante from "./InicioEstudiante";
import InicioDocente from "./InicioDocente";
import PropTypes from "prop-types";

/**
 *  Este componente recibe una serie de props que le permiten renderizar la pantalla de login. Además, renderiza  los componentes InicioEstudiante y InicioDocente.
 * @returns LoginComponent
 */
const LoginComponent = (props) => {
  const {
    fn_iniciarSesionEstudiante,
    isError,
    isLoading,
    fn_iniciarSesion,
    textosInterfaz,
  } = props;
  const { informacion, otros_sesion, tipo_usuario, boton } = textosInterfaz;
  const { docente, estudiante } = tipo_usuario;

  /*ref*/
  const tipoUsuarioCaja = useRef(null);

  const [posicion, setPosicion] = useState(0);
  const [tipoUsuarioElegido, setTipoUsuarioElegido] = useState(null);
  const [anchoCaja, setAnchoCaja] = useState();

  /*estados estudiante */
  const [codigoEstudiante, setCodigoEstudiante] = useState({
    campo: "",
    valido: null,
  });
  const [mostrarAlertaEstudiante, setMostrarAlertaEstudiante] = useState(false);

  /*estados docente */
  const [usuario, setUsuario] = useState({ campo: "", valido: null });
  const [contrasenaValido, setContrasenaValido] = useState(false);
  const [mostrarAlertaDocente, setMostrarAlertaDocente] = useState(false);
  const [mostrarError, setMostrarError] = useState(false);

  /**UseEffect  */
  /**Actualiza el ancho de las cajas que tienen el contenido según el tipo de usuario*/
  useEffect(() => {
    if (!!tipoUsuarioCaja.current) {
      actualizarAncho();
      window.addEventListener("resize", actualizarAncho);
      return () => {
        window.removeEventListener("resize", actualizarAncho);
      };
    }
  }, [anchoCaja]);

  /**Restablece los valores de los campos cuando se cambia de tipo de usuario */
  useEffect(() => {
    setCodigoEstudiante({ ...codigoEstudiante, campo: "", valido: null });
    setUsuario({ ...usuario, campo: "", valido: null });
    setMostrarAlertaDocente(false);
    setMostrarAlertaEstudiante(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoUsuarioElegido]);

  /**FUNCIONES */
  /**Calcula el ancho de la caja blanca */
  const actualizarAncho = () => {
    const nuevaAncho = tipoUsuarioCaja.current.offsetWidth;
    setAnchoCaja(nuevaAncho);
  };

  /**Cambia el tipo de usuario y posiciona la caja del contenido */
  const elegirTipoUsuario = (usuario) => {
    setPosicion(-anchoCaja);
    setTipoUsuarioElegido(usuario);
  };

  /**
   * Valida e inicia la sesión del estudiante
   * @param {event} e
   * @param {string} codigo
   */
  const iniciarSesionEstudiante = ({ e, codigo }) => {
    setMostrarAlertaEstudiante(true);
    if (codigoEstudiante.valido && isError) {
      fn_iniciarSesionEstudiante({ e, codigo });
    }
  };

  /**Actualiza la posicion del contenido a 0 para reubicarlos (esto es lo del botón Volver) */
  const actualizarPosicion = () => setPosicion(0);

  /**
   * Segun la validacion del campo contraseña esta funcion actualiza el estado contrasenaValido
   * @param {bool} valor
   */
  const actualizarValidez = (valor) => setContrasenaValido(valor);

  return (
    <>
      <section
        className="py-12 w-full  pl-home "
        style={{ backgroundImage: `url(/images/background.png)` }}
      >
        <section className="container-login">
          <section className="lg:grid lg:grid-cols-2">
            <section className="lg:order-2 px-10 sm:w-10/12 md:w-8/12 mx-auto lg:w-full">
              <h2
                tabIndex={0}
                alt="iniciar sesión"
                className="focusable-primary quicksand-600 onprimary-var1--color text-40 mb-7"
              >
                {textosInterfaz.titulo.titulo}
              </h2>

              <section
                aria-label="selecciona el tipo de usuario que eres"
                ref={tipoUsuarioCaja}
                tabIndex={0}
                role="tab"
                className="onred-var1--bordered focusable-primary background-var2--bg rounded-md min-h-730 sm:min-h-600 lg:min-h-650 relative overflow-hidden"
              >
                <section
                  className="absolute flex transicion-200"
                  style={{ left: posicion }}
                >
                  <section
                    className="px-5 lg:px-12 pt-8 lg:pt-14"
                    style={{ width: anchoCaja }}
                  >
                    <p className="onprimary-var2--color quicksand-600 text-20">
                      {tipo_usuario.titulo}
                    </p>

                    <button
                      role="button"
                      aria-label="estudiante"
                      className="focusable-secondary w-full flex my-5 p-3 hover:border-2 hover:transicion-150 surface-var4--border rounded"
                      onClick={() => {
                        elegirTipoUsuario("estudiante");
                      }}
                    >
                      <p
                        className=" btn-estudiante onprimary-var2--color h-20 pl-28 alineado-verticalmente quicksand-600 text-24"
                        style={{
                          backgroundImage: `url(/images/avatares/avatar11.png)`,
                        }}
                      >
                        {estudiante.titulo}
                      </p>
                    </button>

                    <button
                      role="button"
                      aria-label="docente"
                      className="focusable-secondary w-full flex my-5 p-3 hover:border-2 hover:transicion-150 surface-var4--border-color rounded"
                      onClick={() => {
                        elegirTipoUsuario("docente");
                      }}
                    >
                      <p
                        className="btn-docente onprimary-var2--color h-20 pl-28 alineado-verticalmente quicksand-600 text-24"
                        style={{
                          backgroundImage: `url(/images/avatares/avatar1.png)`,
                        }}
                      >
                        {docente.titulo}{" "}
                      </p>
                    </button>

                    <section
                      tabIndex={0}
                      role="tab"
                      aria-label="otros incios de sesión"
                      className=" focusable-secondary border-t-2 onprimary-var2--border-color pt-5"
                    >
                      <p className="onprimary-var2--color quicksand-600 text-20">
                        {otros_sesion.titulo}
                      </p>

                      <section className=" grid sm:grid-cols-2 gap-4 pt-5 pb-8">
                        <a
                          className="focusable-secondary red-inv-var2--bg bg-no-repeat boton-otros-inicios-sesion  img-95-center-bottom mb-8 lg:mb-0"
                          href="https://makemake.com.co/login"
                          style={{
                            backgroundImage: `url(/images/logins/login-bibliotecas.png)`,
                          }}
                        >
                          <p className="absolute -bottom-3 center rounded-md secondary-var1--bg onred-var1--bordered onsecondary-var1--color quicksand-600 text-18 w-fit  py-1 px-3 left-1/2 transform -translate-x-1/2">
                            {otros_sesion.biblioteca}
                          </p>
                        </a>
                        <a
                          className="focusable-secondary boton-otros-inicios-sesion img-95-center-bottom  green-inv-var1--bg bg-no-repeat"
                          style={{
                            backgroundImage: `url(/images/logins/login-academia.png)`,
                          }}
                          href="https://academia.makemake.com.co/login"
                        >
                          <p className="absolute -bottom-3 center rounded-md  onred-var1--bordered secondary-var1--bg onsecondary-var1--color quicksand-600 text-18 w-fit  py-1 px-3 left-1/2 transform -translate-x-1/2">
                            {otros_sesion.academia}
                          </p>
                        </a>
                      </section>
                    </section>
                  </section>

                  <section
                    className="px-8 md:px-12 pt-14 focusable-secondary"
                    style={{ width: anchoCaja }}
                  >
                    {tipoUsuarioElegido === "estudiante" ? (
                      <InicioEstudiante
                        actualizarPosicion={actualizarPosicion}
                        iniciarSesionEstudiante={iniciarSesionEstudiante}
                        isLoading={isLoading}
                        isError={isError}
                        mostrarAlertaEstudiante={mostrarAlertaEstudiante}
                        setCodigo={setCodigoEstudiante}
                        codigo={codigoEstudiante}
                        textosDeInterfaz={textosInterfaz}
                      />
                    ) : (
                      <InicioDocente
                        actualizarPosicion={actualizarPosicion}
                        contrasenaValido={contrasenaValido}
                        fnIniciarSesion={fn_iniciarSesion}
                        isError={isError}
                        isLoading={isLoading}
                        setMostrarAlertaDocente={setMostrarAlertaDocente}
                        mostrarAlertaDocente={mostrarAlertaDocente}
                        setMostrarError={setMostrarError}
                        mostrarError={mostrarError}
                        actualizarValidez={actualizarValidez}
                        setUsuario={setUsuario}
                        textosDeInterfaz={textosInterfaz}
                        usuario={usuario}
                      />
                    )}
                  </section>
                </section>
              </section>
            </section>

            <section className="lg:order-1 px-5 lg:px-0">
              <section className="max-w-450 mx-auto">
                <section
                  className="focusable-secondary border-b-2 pt-10 onred-var1--border-color"
                  tabIndex={0}
                  role="image"
                  aria-label="colegios"
                >
                  <section
                   
                    className=" avatar-colegios  onprimary-var1--bg img-95-center-bottom bg-no-repeat "
                    style={{
                      backgroundImage: `url(/images/logins/login-colegios.png)`,
                    }}
                  >
                    <p className="absolute -bottom-3 center rounded-md onred-var1--bordered secondary-var1--bg onsecondary-var1--color quicksand-600 text-20 w-full  py-1 px-3">
                      {informacion.titulo}
                    </p>
                  </section>
                </section>

                <section
                  tabIndex={0}
                  className="focusable-secondary list-disc onred-var1--color quicksand-400 text-20 p-6"
                  dangerouslySetInnerHTML={{ __html: informacion.informacion }}
                ></section>

                <a
                  href="https://makemake.com.co/que-es-colegios"
                  target="_blank"
                  className="focusable-secondary boton-conoce boton-amarillo-quicksand boton_hover_morado onsecondary-inv-var1 text-24 mx-auto my-5 center"
                >
                  {boton.conoce}
                </a>
              </section>
            </section>
          </section>
        </section>
      </section>
    </>
  );
};

export default LoginComponent;

LoginComponent.propTypes = {
  /**
   * función que se encarga de llamar el API de login y actualizar los datos del estudiante en el storage.
   * @type{Function({ e: event, codigo: string }):void} - callback
   */
  fn_iniciarSesionEstudiante: PropTypes.func.isRequired,
  /**
   * object que indica si el API de login respondio con un error.
   */
  isError: PropTypes.object.isRequired,
  /**
   * booleano que indica que el api no ha terminado de responder.
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Es una función que se encarga de llamar el API de login y actualizar los datos del profesor o rector en el storage.
   * @type{Function({ e : event, correo:string, contrasena:string }) : void} - callback
   */
  fn_iniciarSesion: PropTypes.func.isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
};
