export const COMPARACION_COLORES_DISPONIBLES = [
    {
        fondo:'#6666CC',
        contraste:'#ffff00',
        texto:'#fff'
    },// MORADO
    {
        fondo:'#99D438',
        contraste:'#FAF096',
        texto:'#000'
    } //VERDE
]

