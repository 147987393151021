import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
//Store
import { useDispatch } from '../../store';
//Hooks personalizados
import useInitialDataOfSection from './../helpers/useInitiailDataOfSection';
//Componentes
import GeneralClubs from './GeneralClubs';
import CrearClub from './CrearClub';
import VerificarClub from './VerificarClub';
import EditarEliminarClub from './EditarEliminarClub';
import Error_404 from './../Error_404/index';

/**
 * Es el Container principal del módulo de Clubs. Su función es renderizar el container  correspondiente y asignarle la información requerida. Los containers que pueden ser renderizados son GeneralClubs, CrearClub, VerificarClub EditarEliminarClub o Error_404.
 */
const Clubs = () => {
  //Estados globales
  const dispatch = useDispatch();

  const { isLoading: isLoadingCursos } = useInitialDataOfSection({ pathname: '/cursos/obtenerCursos', property: 'datosCursos', section: 'cursos', propiedadJson: 'profesores' });
  const { isLoading: isLoadingEstudiantes } = useInitialDataOfSection({ pathname: '/estudiantes/obtenerListadoEstudiantes', property: 'datosEstudiantes', section: 'estudiantes' })
  const { isLoading: isLoadingClubs } = useInitialDataOfSection({ pathname: '/clubs/obtenerClubs', property: 'datosClubs', section: 'clubs'})


  const history = useHistory();


  useEffect(() => {
    return () => {
      borrarDatosTemporales()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //Descartar cambios
  const descartarCambios = async () => {
    borrarDatosTemporales();
    history.push('/clubs');
  }



  const borrarDatosTemporales = async () => {
    let dataTemporal = {};
    await dispatch({
      type: "SET_DATA",
      property: "temporal",
      value: dataTemporal
    });
  }


  return (
    <>
      <Switch>
        <Route exact path="/clubs">
          <GeneralClubs
            isLoadingClubs={isLoadingClubs}
          />
        </Route>

        <Route exact path='/clubs/crear-club'>
          <CrearClub
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
          />
        </Route>

        <Route exact path='/clubs/crear-club/verificar-club'>
          <VerificarClub
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
          />
        </Route>

        <Route exact path='/clubs/editar-club'>
          <EditarEliminarClub
            isLoadingCursos={isLoadingCursos}
            isLoadingEstudiantes={isLoadingEstudiantes}
          />
        </Route>

        <Route exact path='/clubs/editar-club/:club'>
          <EditarEliminarClub
            isLoadingCursos={isLoadingCursos}
            isLoadingEstudiantes={isLoadingEstudiantes}
          />
        </Route>

        <Route component={Error_404} />
      </Switch>
    </>
  )
}

export default Clubs;
