import { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';
import MicroModal from "micromodal";
import PropTypes from 'prop-types';

/**componentes */
import DivisionCrud from "../globales/DivisionCrud";
import InputSelectCheckbox from "../globales/elementosFormularios/InputSelectCheckbox";
import ModalCambiarIcono from "../globales/modales/ModalCambiarIcono";
import Selector from "../globales/elementosFormularios/Selector";
import EditarListaEstudiantes from "./Listas/ListaEstudiantes";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";
import { FileSelector } from "../globales/elementosFormularios/FileSelector";
import { useTrackedState } from "../../store";
import { Miga, MigasDePan } from "../globales/MigaDePan";

/**
 * Este componente recibe una serie de props que son necesarios para renderizar la pantalla de editar club. Además, hace uso de los componentes Modal, ModalConfirmacion, DivisionCrud, InputSelectCheckbox, ModalCambiarIcono, EditarListaEstudiantes y Selector.
 * @returns EditarEliminarClubComponent
 */
const EditarEliminarClubComponent = (props) => {
  const { actualizarCurso, actualizarDescripcion, actualizarLibrosElegidos, actualizarEstudiantesElegidos, actualizarNombre, actualizarIcono, actualizarSede, borrarEstudiante, buscarEstudiantes, cursosSede, datosClub, editarClub, eliminarClub, estudiantesElegidos, filtros, iconoClub, librosElegidos, numeroLibrosElegidos, obtenerDatosInput, preloader, respuestaApiEditarClub, respuestaApiEliminarClub, resultadoEstudiantes, resultadoLibros, sedesInstituto, textosInterfaz } = props;

  const { miga_de_pan, editar, botones, modal_icono, eliminar_club, club_eliminado } = textosInterfaz;
  const { archivo, descripcion, estudiantes, icono, libros, nombre, titulo } = editar;
  const [existenCambios, setExistenCambios] = useState(props.hayCambios);
  /**Estados del componente */
  const [inputNombre, setInputNombre] = useState({ campo: datosClub.nombre, valido: true, desactivado: false });
  const [textareaDescripcion, setTextareaDescripcion] = useState({ campo: datosClub.descripcion, valido: true, desactivado: false });
  const [inputEstudiantes, setInputEstudiantes] = useState('');
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [nombreDocumento, setNombreDocumento] = useState('');
  const [mostrarAvatar, setMostrarAvatar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(true);
  const [activadorExterno, setActivadorExterno] = useState(false);
  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';
  const history = useHistory();

  //Variables de entorno
  const numIconos = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_ICONOS_CURSO);

  /**REF */
  const refInputNombre = useRef(null);
  const refTextareaDescripcion = useRef(null);

  /**useEffect */
  /**Según la respuesta del api eliminar muestra la modal o notificacion correspondiente */
  useEffect(() => {
    if (respuestaApiEliminarClub.isError === false) {
      MicroModal.close('estas-seguro', {
        awaitCloseAnimation: true
      });
      MicroModal.show('club-eliminado', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaApiEliminarClub.isError === true) {
      MicroModal.close('estas-seguro', {
        awaitCloseAnimation: true
      });

      notificacion(textosInterfaz.notificacion.error, respuestaApiEliminarClub.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEliminarClub.isError])


  /**Según la respuesta del api editar muestra la  notificacion correspondiente  */
  useEffect(() => {
    if (respuestaApiEditarClub.isError !== null) {
      notificacion(
        respuestaApiEditarClub.isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        respuestaApiEditarClub.mensaje,
        respuestaApiEditarClub.isError ? "danger" : "success"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEditarClub.isError])

  /*
    cuando la prop 'hayCambios' se modifique actualizara el hook 'existenCambios'
   evitar usar la prop 'hayCambios' directamente debido a que la prop no se puede modificar
    dentro de este componente. 
    se recomienda usar el hook existenCambios para obtener y modificar
    si hay cambios en la edición del club.
   */
  useEffect(() => {
    setExistenCambios(props.hayCambios);
  }, [props.hayCambios])

  useEffect(() => {
    if (refInputNombre && inputNombre.desactivado === true) {
      refInputNombre.current.focus()
    }
  }, [inputNombre.desactivado])

  useEffect(() => {
    if (refTextareaDescripcion && textareaDescripcion.desactivado === true) {
      refTextareaDescripcion.current.focus()
    }
  }, [textareaDescripcion.desactivado])

  /**Funciones */
  /**Valida que el campo no este vacio y actualiza su estado a valido */
  const validarCampo = (estado, actualizarCampo) => {
    actualizarCampo({ ...estado, valido: (estado?.campo && estado?.campo?.trim()?.length > 0) ? true : false })
  }

  /**Actualiza el estado una vez se escriba en campo */
  const actualizarCampo = (e, estado, cambiarEstado) => cambiarEstado({ ...estado, campo: e.target.value });

  /**Envia los datos de los libros seleccionados */
  const actualizarLibros = ({ e, opcion }) => {
    // console.log(e,opcion);
    actualizarLibrosElegidos({ e, libro: opcion });
  }

  /** Actualiza el valor del estado activador externo que en este caso es el botón de agregar libros*/
  const activadorExternoLibros = (valor) => setActivadorExterno(valor);

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarSede = ({ opcion }) => {
    actualizarSede({ idSede: opcion.id, value: inputEstudiantes });
  };

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarCurso = ({ opcion }) => {
    actualizarCurso({ idSede: opcion.idSede, idCurso: opcion.id, value: inputEstudiantes });
  };

  /** Valida que los campos obligatorios esten diligenciados y ejecuta la función de editar*/
  const actualizarCambios = () => {
    setMostrarAlerta(true);
    if (existenCambios && inputNombre.valido && textareaDescripcion.valido) {
      editarClub();
    }
  }

  /**Buscas los estudiantes por nombre */
  const searchEstudiantes = ({ e }) => {
    setInputEstudiantes(e.target.value);
    buscarEstudiantes({ value: e.target.value });
  }


  /**Actualiza los estudiantes elegidos */
  const actualizarEstudiantes = ({ e, opcion }) => {
    actualizarEstudiantesElegidos({ e, estudiante: opcion });
  }


  /**crea la notificaion */
  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  return (
    <>
      <MigasDePan>
        <Miga
          alSeleccionar={() => {
            history.push({
              pathname: '/clubs'
            })
          }}
          texto={miga_de_pan?.principal || 'Clubs'}>
        </Miga>
        <Miga
          texto={miga_de_pan.editar || 'Editar club'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales mb-5">{titulo}</h1>

        {/*Nombre  */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{nombre.titulo} <span className="asterisco">*</span> </h3>
          </>
          <>
            <div className="alineado-verticalmente ">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputNombre.desactivado && 'input_disabled'} ${mostrarAlerta ? !inputNombre.valido && 'input_error' : null}`}
                  onBlur={() => { validarCampo(inputNombre, setInputNombre) }}
                  onChange={(e) => {
                    actualizarCampo(e, inputNombre, setInputNombre);
                    actualizarNombre({ nombre: e.target.value });
                  }}
                  /* onClick={() => { setInputNombre({ ...inputNombre, desactivado: true }) }} */
                  onKeyUp={() => { validarCampo(inputNombre, setInputNombre) }}
                  placeholder={nombre.placeholder}
                  ref={refInputNombre}
                  type="text"
                  value={inputNombre.campo}
                  disabled={!inputNombre.desactivado}
                />
              </div>
              <button
                className="evento_rojo ml-5 focusable-primary"
                onClick={() => { setInputNombre({ ...inputNombre, desactivado: true }); refInputNombre.current.focus() }}
              >
                {botones.editar.campo}
              </button>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p className={`text-14 ${!inputNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {nombre.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/* Descripcion */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{descripcion.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{descripcion.descripcion} </p>
          </>
          <>
            <div className="alineado-verticalmente ">
              <div className="w-full xl:w-451p">
                <textarea
                  id="descripcion"
                  className={`textarea focusable-primary ${!textareaDescripcion.desactivado && 'input_disabled'} ${mostrarAlerta ? !textareaDescripcion.valido && 'textarea-error' : null}`}
                  onBlur={() => { validarCampo(textareaDescripcion, setTextareaDescripcion) }}
                  onChange={(e) => {
                    actualizarCampo(e, textareaDescripcion, setTextareaDescripcion);
                    actualizarDescripcion({ descripcion: e.target.value })
                  }}
                  /* onClick={() => { setTextareaDescripcion({ ...textareaDescripcion, desactivado: true }) }} */
                  onKeyUp={() => { validarCampo(textareaDescripcion, setTextareaDescripcion) }}
                  placeholder={descripcion.placeholder}
                  ref={refTextareaDescripcion}
                  value={textareaDescripcion.campo || ''}
                  disabled={!textareaDescripcion.desactivado}
                ></textarea>
              </div>
              <button
                className="evento_rojo ml-5 focusable-primary"
                onClick={() => { setTextareaDescripcion({ ...textareaDescripcion, desactivado: true }); refTextareaDescripcion.current.focus() }}
              >
                {botones.editar.campo}
              </button>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p className={`text-14 ${!textareaDescripcion.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {descripcion.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/*archivo adjunto */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{archivo.titulo}</h3>
            <p className="r4-14-gris6a">{archivo.descripcion} </p>
          </>
          <>
            <FileSelector
              cantidadMaximaArchivos={1}
              extensionesPermitidas={'.pdf, application/pdf'}
              alCargarArchivo={(archivoCargado) => {
                if (archivoCargado)
                  obtenerDatosInput({ archivo: archivoCargado, nombre: archivoCargado.name });
                setNombreDocumento(archivoCargado.name);
                setExistenCambios(true);
              }}
              alFallar={(error) => console.log(error)}
              informacion={!!datosClub.archivo ? datosClub.archivo.name : archivo.descripcion}
              tituloBotonAgregar={archivo.titulo}
              mensajeError={archivo.no_valido}
            />
          </>
        </DivisionCrud>


        {/*libros asignados */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{libros.titulo}</h3>
            <p className="r4-14-gris6a">{libros.descripcion} </p>
          </>
          <>
            <div className="w-full xl:w-451p alineado-izquierda-center">
              <p className="r7-morado">({numeroLibrosElegidos}/5 {numeroLibrosElegidos !== 1 ? libros.seleccionados.plural : libros.seleccionados.singular})</p>
            </div>
            <div className="w-full xl:w-451p">
              <InputSelectCheckbox
                actualizarElegidos={actualizarLibros}
                activadorExterno={activadorExterno}
                activadorExternoLibros={activadorExternoLibros}
                datosAlmacenados={librosElegidos.ids}
                listaOpciones={resultadoLibros.resultados}
                noEstaEnLista={libros.input.no_hay}
                noHaylistaOpciones={libros.input.no_hay}
                notificacionTitulo={libros.alerta.titulo}
                notificacionDescripcion={libros.alerta.descripcion}
                numeroLibros={numeroLibrosElegidos}
                placeholder={libros.input.placeholder}
                propiedadId='idLibro'
                propiedadNombre='title'
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-3 2xl:grid-cols-5  gap-4 mt-5 ">
              {
                librosElegidos.datos.length !== 0 && librosElegidos.datos.map((libro, index) => {
                  /**
                    Las APIs no retornar la propiedad del nombre del libro de la misma forma,
                    en este caso hay que verificar que la propiedad sea 'nombre' o 'title'
                   */
                  const tituloLibro = libro?.nombre || libro?.title || 'N/A'
                  return (
                    <div key={`libro-${index}`}>
                      <div className="w-32 h-44 mx-auto">
                        <img
                          alt={tituloLibro}
                          className="w-32 h-44"
                          src={`${process.env.REACT_APP_ASSETS_URL}${libro.portada}`}
                        />
                      </div>
                      <div className="w-10/12 xl:w-11/12 mx-auto">
                        <p className="r7-morado text-center mt-1" aria-hidden={true}>{tituloLibro}</p>
                      </div>
                    </div>
                  )
                })
              }
              {librosElegidos.datos.length < 5 &&
                <>
                  <div
                    className="w-32 h-44 caja-agregar mx-auto"
                    onClick={() => { setActivadorExterno(true) }}
                  >
                    <div className="z-10">
                      <span className="icon-mas text-20 block text-center onbackground-var2--color"></span>
                      <p className="r7-16-negro26 mt-3 text-center"> {libros.agregar}</p>
                    </div>
                  </div>
                </>
              }
            </div>
          </>
        </DivisionCrud>

        {/* icono */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{icono.titulo}</h3>
            <p className="r4-14-gris6a">{icono.descripcion} </p>
          </>
          <div className="lg:w-32 mt-4 lg:mt-0">
            <div
              className="avatar icono onsurface-var2--border-color mx-auto text-indent cursor-pointer"
              style={{ backgroundImage: `url(/images/badges/badge-${iconoClub}.png)` }}
              onClick={() => {
                if (mostrarAvatar) {
                  MicroModal.show('cambiar-icono', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarAvatar(true)
                }
              }}
            >
              icono-{iconoClub}
            </div>
            <button
              className="evento_rojo mx-auto block mt-3 focusable-primary"
              onClick={() => {
                if (mostrarAvatar) {
                  MicroModal.show('cambiar-icono', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarAvatar(true)
                }
              }}
            >
              {botones.cambiar}
            </button>
          </div>
        </DivisionCrud>


        {/* estudiantes */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{estudiantes.titulo}</h3>
            <p className="r4-14-gris6a">{estudiantes.descripcion} </p>
          </>
          <>
            <div className="sm:flex gap-4">
              {
                rolUsuario === 'rector' &&
                <div className="sm:w-6/12">
                  <Selector
                    cambiarElegido={cambiarSede}
                    desactivado={false}
                    estadoEnviar={mostrarAlerta}
                    opciones={sedesInstituto}
                    superposicion={true}
                    textoDefault={estudiantes.sede.default}
                    textoElegido={filtros.sede.nombre}
                    textoError="error"
                    textoNoHayOpciones={estudiantes.sede.no_hay}
                    valido={!!filtros.sede.id ? true : false}
                  />
                </div>
              }
              <div className="sm:w-6/12 mt-5 sm:mt-0">
                <Selector
                  cambiarElegido={cambiarCurso}
                  desactivado={false}
                  opciones={cursosSede.resultados}
                  textoDefault={estudiantes.cursos.default}
                  textoElegido={filtros.curso.nombre}
                  estadoEnviar={mostrarAlerta}
                  textoError="error"
                  textoNoHayOpciones={estudiantes.cursos.no_hay}
                  valido={!!filtros.curso.id}
                  subposicion={true}
                />
              </div>
            </div>
            <div className="w-full mt-3">
              <InputSelectCheckbox
                inputChange={searchEstudiantes}
                actualizarElegidos={actualizarEstudiantes}
                datosAlmacenados={estudiantesElegidos.ids}
                listaOpciones={resultadoEstudiantes.resultados}
                noEstaEnLista={estudiantes.input.no_resultados}
                noHaylistaOpciones={estudiantes.input.no_hay}
                placeholder={estudiantes.input.placeholder}
              />
            </div>
            <div className="mt-10">
              <EditarListaEstudiantes
                botonEliminar={botones.eliminar}
                datosEstudiantes={estudiantesElegidos.datos}
                eliminarEstudianteIndividual={borrarEstudiante}
                textosTabla={estudiantes.tabla}
              />
            </div>
          </>
        </DivisionCrud>


        <div className="alineado-izquierda my-5">
          <button
            className="boton-justo sm:center mr-5 focusable-primary"
            onClick={() => {
              if (modalEliminar) {
                MicroModal.show('estas-seguro', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalEliminar(true)
              }
            }}
          >
            <span className="icon-basura red-inv-var2--color  text-20 pr-3 hidden sm:block"></span>
            <p className=" evento_rojo">{botones.eliminar}</p>
          </button>
          <button
            disabled={!existenCambios}
            className="boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center focusable-primary"
            onClick={() => { actualizarCambios() }}
          >
            {
              preloader ?
                <div className="center">
                  <span className="icon-spinner7 animate-spin text-20"></span>
                </div>
                :
                botones.actualizar
            }
          </button>
        </div>
      </div>

      {/* modal avatar */}
      {mostrarAvatar &&
        <ModalCambiarIcono
          boton={botones.cambiar}
          cambiarAvatar={actualizarIcono}
          datoIcono={iconoClub}
          descripcion={modal_icono.descripcion}
          maxIconos={numIconos.maximo}
          minIconos={numIconos.minimo}
          nombreModal="cambiar-icono"
          rutaCarpeta='badges/badge-'
          titulo={modal_icono.titulo}
        />
      }

      {modalEliminar &&
        <>
          {/* Modal pregunta si desea eliminar */}
          <Modal nombreModal="estas-seguro">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{eliminar_club.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{eliminar_club.descripcion} <span className="primary-var2--color text-resaltado">{datosClub.nombre}</span> {eliminar_club.descripcion2} </p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                aria-label="Cancelar"
                onClick={() => {
                  MicroModal.close(`estas-seguro`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button aria-label="Eliminar" className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" onClick={eliminarClub} >
                {preloader ?
                  <div className="center">
                    <span className="icon-spinner7 animate-spin text-20"></span>
                  </div>
                  :
                  botones.eliminar
                }
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion  eliminado */}
          <ModalConfirmacion nombreModal="club-eliminado">
            <Link className="icon-cerrar text-20 right-6 absolute" to="/clubs"></Link>
            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{club_eliminado.titulo}</h1>
            <p className="text-center r7-16-negro26 mb-4">
              {club_eliminado.descripcion1} <span className="text-resaltado">{datosClub.nombre}</span> {club_eliminado.descripcion2}
            </p>
            <div className="center my-3">
              <Link
                to="/clubs"
                className="boton-justo boton-amarillo boton_hover_morado py-1 px-10"
              >
                {botones.volver}
              </Link>
            </div>
          </ModalConfirmacion>
        </>

      }
    </>
  )
}

export default EditarEliminarClubComponent;

EditarEliminarClubComponent.propTypes = {
  /**
   * Es una función que se encarga de cambiar el curso por el que se desean filtrar los etudiantes. 
   */
  actualizarCurso: PropTypes.func,
  /**
 * Es una función que se recibe como parámetro llamado descripcion. Esta función de encarga de actualizar la propiedad descripcion del club a crear.
 */
  actualizarDescripcion: PropTypes.func,
  /**
   * Es una función que se encarga de actualizar el array de los libros asignados al club. 
   *@type{Function({ e, libro: object }):void}
   */
  actualizarLibrosElegidos: PropTypes.func,
  /**
  * Es una función que se encarga de actualizar el array de los estudiantes asignados al club.
  * @type{Function({ e,  estudiante: object }):void}
  */
  actualizarEstudiantesElegidos: PropTypes.func,
  /**
   * Esta función de encarga de actualizar la propiedad nombre del club.
   * @type{Function({ nombre: string }):void}
   */
  actualizarNombre: PropTypes.func,
  /**
   * Es una función que se encarga de cambiar el icono del club que se desea crear
   *  @type{Function({ icono: number }):void}
   */
  actualizarIcono: PropTypes.func,
  /**
   * Es una función que se encarga de cambiar la sede por la que se desean filtrar los estudiantes. 
   * @type{Function({ idSede: string, value: string }):void}
   */
  actualizarSede: PropTypes.func,
  /**
  * Es una función que se encarga de remover los estudiantes que se desean asignar al Club.
  * @type{Function({ idEstudiante: string }):void}
  */
  borrarEstudiante: PropTypes.func,
  /**
   *  Es una función que se encarga de buscar los estudiantes que se desean asignar al club.
   * @type{Function({ cursoObligatorio: bool, idCurso:string, idSede: string, value: string }):void}
   */
  buscarEstudiantes: PropTypes.func,
  /**
    * Es un objeto que almacena que cuenta con dos propiedades
    */
  cursosSede: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.array
  }),

  /**
   *  Es un objeto que almacena las propiedades de clubs
   */
  datosClub: PropTypes.shape({
    archivo: PropTypes.object, //puede ser null
    descripcion: PropTypes.string,
    estudiantes: PropTypes.array,
    icono: PropTypes.number,
    libros: PropTypes.array,
    nombre: PropTypes.string
  }),
  /**
   * Es una función que se encarga de editar los datos del club en la base de datos. Esta función se encarga de ejecutar el api editar club.
   */
  editarClub: PropTypes.func,
  /**
   *  Es una función que se encarga de editar los datos del club en la base de datos. Esta función se encarga de ejecutar el api eliminar club.
   */
  eliminarClub: PropTypes.func,
  /**
  * Es un objeto que almacena las propiedades de los estudiantes
  */
  estudiantesElegidos: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }),
  /**
* Es un objeto que controla los filtros aplicados dentro de la sección de estudiantes. 
*/
  filtros: PropTypes.shape({
    curso: PropTypes.shape({
      id: PropTypes.string, //puede ser null
      nombre: PropTypes.string
    }),
    sede: PropTypes.shape({
      id: PropTypes.string, //puede ser null
      nombre: PropTypes.string
    })
  }),
  /**
   * Esta propiedad puede contar con un valor de null o puede ser un number. Estos valores corresponden al icono selecciondo para el club.
   */
  iconoClub: PropTypes.number,
  /**
   * Es un objeto que almacena las propiedades del libro elegido
   */
  librosElegidos: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }),
  /**
  * Es un numero que corresponde al los libros asignados al club.
  */
  numeroLibrosElegidos: PropTypes.number,
  /**
    *  Es una función que se encarga de guardar el archivo que se desea subir al club, para que posteriormente sea guardado y pueda ser descargado por los estudiantes.
    *  @type{Function({ archivo: object, nombre: string }):void}
    */
  obtenerDatosInput: PropTypes.func,
  /**
   *  Es un booleano que contiene el preloader para el llamado de las API de editar club.
   */
  preloader: PropTypes.bool,
  /**
   * Es un objeto que contiene dos propiedades que va a devolver cuando el API editar club termine de responder.
   */
  respuestaApiEditarClub: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }),
  /**
   *  Es un objeto que contiene dos propiedades que va a devolver cuando el API borrar club termine de responder.
   */
  respuestaApiEliminarClub: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }),
  /**
    *  Es un objeto que almacena que cuenta con dos propiedades
    */
  resultadoEstudiantes: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.array
  }),
  /**
  * Es un objeto que almacena que cuenta con dos propiedades
  */
  resultadoLibros: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.array
  }),
  /**
   *  Es un array de objetos que contiene el listado de sedes que el instituto tiene.
   */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })),
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object
}