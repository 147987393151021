import { useEffect, useRef, useState } from 'react';
//Store
import { useTrackedState, useDispatch } from '../../store';
//Hooks personalizados
import useObtenerDatosExcel from './../helpers/hooks/obtenerDatosExcel/useObtenerDatosExcel';
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Funciones
import comprobarNombreRepetido from '../../utilities/function/comprobarNombreRepetido';
import normalizeString from './../../utilities/function/normalizeString';
import randomNumber from './../../utilities/function/randomNumber';
//Componentes
import CrearCursoComponent from './../../components/cursos/CrearCurso';
import SkeletonCursosCrear from '../../components/cursos/SkeletonsCursos/Crear/index';
import listarObject from './../../utilities/function/listarElementos/listarObject';
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';


const CrearCursos = (props) => {
  const { descartarCambios, isLoadingCursos } = props;

  //Estados globales
  const state = useTrackedState();
  const { datosCursos, datosInstitucion, datosProfesores, temporal } = state;
  const dispatch = useDispatch()

  //Estado del componente
  const [archivoExcel, setArchivoExcel] = useState(null);
  const [datosParaDescartar, setDatosParaDescartar] = useState({ archivo: false, nombre: false, sede: false });
  const [datosTemporalesExcel, setDatosTemporalesExcel] = useState({
    individual: { hayDatos: false },
    masivo: { hayDatos: false, },
  })
  const [iconoSeleccionado, setIconoSeleccionado] = useState(null);
  const [isDisableInput, setIsDisableInput] = useState(true);
  const [isHayDatosParaDescartar, setIsHayDatosParaDescartar] = useState(false);
  const [isCrearEstudiantes, setIsCrearEstudiantes] = useState(!!temporal?.cursos ? temporal.cursos.isCrearEstudiantes : true);
  const [isNombreCursoRepetido, setIsNombreCursoRepetido] = useState(false);
  const [modoActivoCrear, setModoActivoCrear] = useState(!!temporal?.cursos ? temporal.cursos.modoActivoCrear : 'individual');
  const [nombreCurso, setNombreCurso] = useState('');
  const [profesoresAsignados, setProfesoresAsignados] = useState({
    ids: [],
    datos: [],
  });
  const [profesoresInstituto, setProfesoresInstituto] = useState(null);
  const [sedeSeleccionada, setSedeSeleccionada] = useState({
    individual: { id: null, nombre: '' },
    masivo: { id: null, nombre: '' }
  })
  const [sedesInstituto, setSedesInstituto] = useState(null);

  //Ref
  const refInputNombre = useRef(null)

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('cursos')

  const hookDatosExcel = useObtenerDatosExcel(
    {
      archivo: archivoExcel,
      crearMasivamente: modoActivoCrear !== 'individual',
      nombreHoja: 'archivo_excel',
      nombreSeccionACrear: !!refInputNombre.current ? refInputNombre.current.value : '',
      seccionACrear: 'cursos'
    }
  );
  const { advertenciasExcel, datosExcel, errorSubirExcel, isLoadingExcel, isReadyTextosHoja, isReadyTextosTabla } = hookDatosExcel;


  useEffect(() => {
    listarSedesInstituto();

    if (!!temporal && !!temporal.cursos) {
      let datosTemporales = datosTemporalesExcel;
      datosTemporales[modoActivoCrear] = {
        hayDatos: temporal.cursos.hayArchivo,
        datos: {
          nombreArchivo: temporal.cursos.nombreArchivo,
        }
      }

      let sede = temporal.cursos.sede;

      if (temporal.cursos.modoActivoCrear === "individual") {
        const cursoTemporal = temporal.cursos.datos;
        const datosCursoIndividual = cursoTemporal[Object.keys(cursoTemporal)[0]];

        sede = {
          id: datosCursoIndividual.idSede,
          nombre: datosCursoIndividual.nombreSede
        }
        setNombreCurso(datosCursoIndividual.nombre)
        setIconoSeleccionado(datosCursoIndividual.icono);
        setProfesoresAsignados({
          ids: datosCursoIndividual.profesores,
          datos: datosCursoIndividual.datosProfesores
        })
      }

      setSedeSeleccionada({
        ...sedeSeleccionada,
        [modoActivoCrear]: { ...sede }
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (modoActivoCrear === 'individual') {
      comprobarNombreCurso({ nombreCurso: nombreCurso })

      listarProfesoresInstituto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sedeSeleccionada[modoActivoCrear].id])


  useEffect(() => {
    if (!!temporal?.cursos) {

      const hayCursos = Object.keys(temporal.cursos.datos).length !== 0;

      setDatosTemporalesExcel({
        ...datosTemporalesExcel,
        [modoActivoCrear]: {
          hayDatos: hayCursos,
          datos: {
            nombreArchivo: hayCursos ? temporal.cursos.nombreArchivo : null
          }
        }
      })
      setIsHayDatosParaDescartar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal])



  useEffect(() => {
    listarProfesoresInstituto()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosProfesores])


  useEffect(() => {
    let disable = false;
    if (modoActivoCrear === 'individual') {
      disable = isCrearEstudiantes;
    }

    setIsDisableInput(disable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modoActivoCrear, isCrearEstudiantes])


  //Actualiza icono del curso
  const actualizarIconoCurso = ({ icono }) => setIconoSeleccionado(icono);


  //Habilita o deshabilita que se pueda subir un archivo
  const actualizarModoCrearCursos = ({ modo }) => {
    setArchivoExcel(null);
    setModoActivoCrear(modo);
  };


  //Habilita o deshabilita que se pueda subir un archivo
  const actualizarNombreCursos = ({ nombreCurso }) => {
    let validacionCampoNombre = false;
    if (nombreCurso !== '') validacionCampoNombre = true;

    setDatosParaDescartar({
      ...datosParaDescartar,
      nombre: validacionCampoNombre
    });

    setNombreCurso(nombreCurso);
    comprobarNombreCurso({ nombreCurso });
  };


  //Actualiza el profesor asignado
  const actualizarProfesoresAsignados = ({ e, idSede, profesor }) => {
    const datosEstadoProfesores = [...profesoresAsignados.datos];

    const resultados = elegirElementosCheck({ e, elemento: profesor, datosActuales: datosEstadoProfesores });
    const { ids, datos } = resultados;

    const nombreSede = datosProfesores.resultados[idSede].nombre;

    setProfesoresAsignados({ ids, datos });

    setSedeSeleccionada({
      ...sedeSeleccionada,
      [modoActivoCrear]: {
        id: idSede,
        nombre: nombreSede
      }
    });
  };


  //Actualiza la sede asignada
  const actualizarSedeAsignada = ({ idSede }) => {
    if (!!idSede) {
      setDatosParaDescartar({
        ...datosParaDescartar,
        sede: true
      });
    };

    if (idSede !== sedeSeleccionada[modoActivoCrear].id) {
      const nombreSede = datosInstitucion.sedes.find(sede => sede.id === idSede).nombre;

      setSedeSeleccionada({
        ...sedeSeleccionada,
        [modoActivoCrear]: {
          id: idSede,
          nombre: nombreSede,
        }
      });

      setProfesoresAsignados({ ids: [], datos: [] });
    };
  };


  //Comprueba si el nombre ingresado corresponde a algun curso ya creado dentro de la sede
  const comprobarNombreCurso = ({ nombreCurso }) => {
    const sede = sedeSeleccionada[modoActivoCrear].id;

    if (!!sede) {
      const array = datosCursos[sede]?.cursos;
      const comprobacion = comprobarNombreRepetido({ array, nombreCurso, propiedad: sede });
      setIsNombreCursoRepetido(comprobacion.estaRepetida);
    };
  };


  //Guarda temporalmente los datos del usuario en el estado del store
  const guardarDatosTemporalmente = async () => {
    const iconoRandom = randomNumber({ minimo: 1, maximo: 5 });
    let datosCursosNuevos = !!datosExcel
      ? datosExcel.datos
      : { [normalizeString(nombreCurso)]: { estudiantes: [] } };
    let nombreCursosNuevos = {};
    const sede = sedeSeleccionada[modoActivoCrear];

    let hayArchivo;
    let nombreArchivo;
    const nombreCursoNormalizado = normalizeString(nombreCurso);

    if (modoActivoCrear === 'individual') {
      if (!isCrearEstudiantes) {
        hayArchivo = true;
        nombreArchivo = !!datosExcel ? datosExcel.nombreArchivo : null;

        datosCursosNuevos = {
          [nombreCursoNormalizado]: {
            ...datosCursosNuevos[Object.keys(datosCursosNuevos)[0]],
            datosProfesores: profesoresAsignados.datos,
            idSede: sede.id,
            icono: !!iconoSeleccionado ? iconoSeleccionado : iconoRandom,
            nombre: nombreCurso,
            nombreSede: sede.nombre,
            profesores: profesoresAsignados.ids,
          }
        };
      } else {
        hayArchivo = false;
        nombreArchivo = null;

        datosCursosNuevos = {
          [nombreCursoNormalizado]: {
            ...datosCursosNuevos[Object.keys(datosCursosNuevos)[0]],
            datosProfesores: profesoresAsignados.datos,
            idSede: sede.id,
            icono: !!iconoSeleccionado ? iconoSeleccionado : iconoRandom,
            nombre: nombreCurso,
            nombreSede: sede.nombre,
            profesores: profesoresAsignados.ids,
            estudiantes: [],
          }
        };
      }

    } else {
      let cursos = {};
      let numCurso = 0;

      hayArchivo = datosExcel.hayArchivo;
      nombreArchivo = datosExcel.nombreArchivo;

      for (const key in datosCursosNuevos) {
        const curso = datosCursosNuevos[key];
        const iconoRandom = randomNumber({ minimo: 1, maximo: 5 });

        numCurso = numCurso + 1;

        const keyCurso = normalizeString(`curso-${numCurso}`);

        // //Se guardan los nombres de los cursos en una de las propiedades del estado      
        nombreCursosNuevos[sede.id] = !!nombreCursosNuevos[sede.id]
          ? nombreCursosNuevos[sede.id] = [...nombreCursosNuevos[sede.id], { key: keyCurso, nombre: curso.nombre }]
          : [{ key: keyCurso, nombre: curso.nombre }];

        cursos[keyCurso] = {
          ...curso,
          datosProfesores: !!datosCursosNuevos.datosProfesores ? datosCursosNuevos.datosProfesores : [],
          icono: !!datosCursosNuevos.icono ? datosCursosNuevos.icono : iconoRandom,
          idSede: sede.id,
          key: keyCurso,
          nombre: curso.nombre,
          nombreSede: sede.nombre,
          profesores: !!datosCursosNuevos.profesores ? datosCursosNuevos.profesores : [],
        };
      };
      datosCursosNuevos = cursos;
    }

    let informacionCursosNuevos = {
      datos: datosCursosNuevos,
      hayArchivo: hayArchivo,
      sede: { id: sede.id, nombre: sede.nombre },
      isCrearEstudiantes: isCrearEstudiantes,
      nombreArchivo: nombreArchivo,
      nombreCursos: nombreCursosNuevos,
      modoActivoCrear: modoActivoCrear,
    }
    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "cursos",
      value: informacionCursosNuevos
    });
  }


  //Genera el listado de profesores que el profesor puede seleccionar para asignar al curso
  const listarProfesoresInstituto = () => {
    if (!!datosProfesores?.resultados) {
      const profesoresFiltrados = listarObject({
        object: datosProfesores.resultados,
        idSede: sedeSeleccionada[modoActivoCrear].id,
        propiedad: 'profesores'
      })

      const resultados = {
        hayProfesores: profesoresFiltrados.resultados.hayResultados,
        profesores: profesoresFiltrados.resultados.resultados,
      }

      setProfesoresInstituto(resultados);
    }
  }


  //Guarda los datos del id y el nombre de las sedes de la sede en un estado
  const listarSedesInstituto = () => {
    const sedes = []
    datosInstitucion.sedes.map(sede => {
      return sedes.push({ id: sede.id, nombre: sede.nombre })
    })
    setSedesInstituto(sedes)
  }


  //Actualiza el estado que almacena al archivo
  const obtenerDatosExcel = ({ archivo, e, nombre }) => {
    setArchivoExcel({ archivo, e, nombre });
  }


  //Habilita o deshabilita que se pueda subir un archivo
  const permisoCrearEstudiantes = ({ value }) => {
    if (!value) setArchivoExcel(null)
    setIsCrearEstudiantes(value)
  };



  return (
    <>
      {
        isReady && !isLoadingCursos && isReadyTextosHoja && isReadyTextosTabla ?
          <CrearCursoComponent
            actualizarIconoCurso={actualizarIconoCurso}
            actualizarModoCrearCursos={actualizarModoCrearCursos}
            actualizarNombreCursos={actualizarNombreCursos}
            actualizarProfesoresAsignados={actualizarProfesoresAsignados}
            actualizarSedeAsignada={actualizarSedeAsignada}
            advertenciasExcel={{
              hayAdvertencias: advertenciasExcel.hayAdvertencias,
              href: advertenciasExcel.href,
              nombreArchivo: advertenciasExcel.nombreArchivo
            }}
            comprobarNombreCurso={comprobarNombreCurso}
            datosTemporalesExcel={datosTemporalesExcel[modoActivoCrear]}
            descartarCambios={descartarCambios}
            errorSubirExcel={errorSubirExcel}
            guardarDatosTemporalmente={guardarDatosTemporalmente}
            iconoSeleccionado={iconoSeleccionado}
            isCrearEstudiantes={!isCrearEstudiantes}
            isDisableInput={isDisableInput}
            isHayDatosParaDescartar={isHayDatosParaDescartar}
            isLoadingExcel={isLoadingExcel}
            isNombreCursoRepetido={isNombreCursoRepetido}
            nombreCursoNuevo={nombreCurso}
            modoActivoCrear={modoActivoCrear}
            obtenerDatosExcel={obtenerDatosExcel}
            permisoCrearEstudiantes={permisoCrearEstudiantes}
            profesoresAsignados={profesoresAsignados}
            profesoresInstituto={profesoresInstituto}
            sedesInstituto={sedesInstituto}
            sedeSeleccionada={sedeSeleccionada[modoActivoCrear]}
            textosInterfaz={{
              advertencias: textosInterfaz.advertencias,
              botones: textosInterfaz.botones,
              crear: textosInterfaz.crear,
              descartar_cambios: textosInterfaz.descartar_cambios,
              error_excel: textosInterfaz.error_excel,
              miga_de_pan: textosInterfaz.miga_de_pan,
              modal_icono: textosInterfaz.modal_icono
            }}
          />
          :
          <SkeletonCursosCrear
            modoActivoCrear={modoActivoCrear}
          />
      }
    </>
  )
}

export default CrearCursos;