import { useState, useEffect } from 'react';
import RadioButton from '../../globales/elementosFormularios/RadioButton';
import MicroModal from 'micromodal';
import ModalFormEnviado from '../../../containers/ayuda/modalFormEnviado';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de props que le permiten renderizar el formulario de "duda sobre ventas". Además, renderiza los componentes RadioButton, MicroModal y ModalFormEnviado. 
 * @returns DudaVentas
 */
const DudaVentas = (props) => {
  const { info, textosInterfaz, enviarASoporte, preloader, mostrarModal, ocultarModal } = props;
  const { descripcion_duda, tipo_duda } = info;
  const [enviar, setEnviar] = useState(false);
  const [radioActivo, setRadioActivo] = useState(null);
  const [textArea, setTextArea] = useState('');

  useEffect(() => {
    if (mostrarModal) {
      MicroModal.show('formulario-enviado', {
        awaitCloseAnimation: true
      });
    }
  }, [mostrarModal])

  const enviarFormulario = () => {
    setEnviar(true);
    if (textArea && radioActivo !== null) {
      const tipoDuda = { suscripcion: 0, renovar: 0, otrosPlanes: 0 };

      Object.keys(tipoDuda).map((item, index) => {
        if (radioActivo === index) {
          tipoDuda[item] = 1;
        }
      })

      enviarASoporte(tipoDuda, textArea)
    }else{
      //si no son validos envia el foco al inicio del formulario
      const startForm = document.querySelector("#tipoDuda");
      if (startForm) {
        startForm.tabIndex = -1;
        startForm.focus();
        setTimeout(() => startForm.removeAttribute("tabindex"), 1000);
      }
    }
  }

  const alCerrarModal = () => {
    MicroModal.close("formulario-enviado", {
      awaitCloseAnimation: true
    });
    setRadioActivo(null)
    setTextArea('');
    setEnviar(false)

    ocultarModal();
  }


  return (
    <>
      < RadioButton
        identificador={'tipoDuda'}
        arreglo={tipo_duda}
        estadoEnviar={enviar}
        mensajeError={tipo_duda.error}
        radioActivo={radioActivo}
        setRadioActivo={setRadioActivo}
        titulo={tipo_duda.titulo}
      />

      <label htmlFor="cuentanos" className="q7-18 onbackground-var1--color my-3">{descripcion_duda.titulo} <span className="asterisco">*</span> </label>
      <textarea
        id="cuentanos"
        className={`textarea focusable-primary onsurface-var2--color onsurface-var2--placeholder ${enviar && !textArea && 'textarea-error'}`}
        onChange={(e) => { setTextArea(e.target.value) }}
        placeholder={descripcion_duda.placeholder}
        value={textArea}
      ></textarea>
      <span 
      role={"alert"}
      aria-live="assertive"
      className={`text-14 ${enviar ? !textArea ? 'alert_error' : 'hidden' : 'hidden'}`}>
        {descripcion_duda.error}
      </span>

      <div className="flex justify-end my-5">
        <button
          className="focusable-primary boton-pequenio boton-amarillo boton_hover_morado"
          onClick={() => { enviarFormulario() }}
        >
          {
            preloader ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              textosInterfaz.boton_enviar
          }
        </button>
      </div>
      {enviar &&
        <ModalFormEnviado alCerrarModal={alCerrarModal} textosInterfaz={textosInterfaz} />
      }
    </>
  )
}

export default DudaVentas;

DudaVentas.propTypes = {
  /**
   * Objeto que contiene los textos de cada pregunta del formulario.
   */
  info: PropTypes.shape({
    descripcion_duda: PropTypes.shape({
      error: PropTypes.string,
      placeholder: PropTypes.string,
      titulo: PropTypes.string
    }),
    tipo_duda: PropTypes.shape({
      error: PropTypes.string,
      opciones: PropTypes.array,
      titulo: PropTypes.string
    })
  }).isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Función que envia la información del formulario al correo. 
   * @type{Function(tipoDuda:string, descripcion:string):void} -callback
   */
  enviarASoporte: PropTypes.func.isRequired,
  /**
 * booleano que contiene el preloader para el llamado de la API EmailAPI
 */
  preloader: PropTypes.bool.isRequired,
  /**
   * Booleano que indica si debe aparecer la ventana modal de enviado
   */
  mostrarModal: PropTypes.bool.isRequired,
  /**
    * Función que oculta la ventana modal
    */
  ocultarModal: PropTypes.func.isRequired,
}