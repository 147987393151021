import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';

/*Componentes */
import DivisionCrud from "../../globales/DivisionCrud";
import Modal from "../../globales/modales/Modal";
import ModalConfirmacion from "../../globales/modales/ModalConfirmacion";
import VerificarListaEstudiantes from "./ListaEstudiantes";

const VerificarIndividual = (props) => {
  const { crearCursos, descartarCambios, icono, listaEstudiantes, listaProfesoresAsignados, nombre, nombreSede, numEstudiantes, preloaderCrearCursos, respuestaApiCrearCursos, textosInterfaz } = props;
  const { isError, mensaje } = respuestaApiCrearCursos;
  const { verificar_datos, botones, miga_de_pan, descartar_cambios, curso_creado } = textosInterfaz;

  const [modalDescartar, setModalDescartar] = useState(false);

  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar])

  /**Una vez que cambia el valor isError, la ventana modal de curso creado aparece   */
  useEffect(() => {
    if (isError !== null) {
      if (!isError) {
        MicroModal.show('curso-creado', {
          awaitCloseAnimation: true
        });
      } else {
        notificacion(textosInterfaz.notificacion.error, mensaje, "danger");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <button
            className="focusable-primary mr-2"
            onClick={() => {
              if (modalDescartar) {
                MicroModal.show('cancelar-crear', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalDescartar(true)
              }
            }}
          >{miga_de_pan.principal}</button>  &gt;
          <Link to="/cursos/crear-curso" className="focusable-primary mx-2">{miga_de_pan.crear}</Link>  &gt;
          <span className="ml-2 font-bold">{miga_de_pan.verificar}</span>
        </p>
      </div>

      <div className="contenido">

        <h1 className="titulos-principales">{verificar_datos.titulo}</h1>
        <DivisionCrud>
          <h3 className="onbackground-var1--color raleway-700">{verificar_datos.nombre.titulo} </h3>
          <div className=" alineado-verticalmente input-lectura">
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">{nombre}</p>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <h3 className="onbackground-var1--color raleway-700">{verificar_datos.sede}</h3>
          <div className=" alineado-verticalmente input-lectura">
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">{nombreSede}</p>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <h3 className="onbackground-var1--color raleway-700">{verificar_datos.profesor.titulo} </h3>
          <div className="input-lectura">

            <div>
              {listaProfesoresAsignados.length !== 0 ?
                listaProfesoresAsignados.map((profesor, index) => {
                  return (
                    <p
                      key={`profesor-${index}`}
                      className={`raleway-700 primary-inv-var2--color alineado-verticalmente text-13 ${index + 1 !== listaProfesoresAsignados.length && 'mb-2'}`}>

                      <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span> {profesor.nombre}
                    </p>
                  )
                })
                :
                <p className={`raleway-700 red-inv-var2--color alineado-verticalmente text-13`}>
                  <span className="icon-alerta text-18  mr-3"></span> {verificar_datos.profesor.sin_profesor}
                </p>
              }
            </div>

          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{verificar_datos.icono.titulo}</h3>
            <p className="r4-14-gris6a">{verificar_datos.icono.descripcion}</p>
          </>
          <div className="lg:w-32">
            <div
              className="avatar icono border-gris-linea mx-auto  text-indent"
              style={{ backgroundImage: `url(/images/badges/badge-${icono}.png)` }}
            >
              {verificar_datos.icono.titulo}
            </div>
          </div>
        </DivisionCrud>


        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{numEstudiantes} {numEstudiantes === 1 ? verificar_datos.estudiantes.titulo.singular : verificar_datos.estudiantes.titulo.plural}</h3>
            <p className="r4-14-gris6a">{verificar_datos.estudiantes.descripcion}</p>
          </>
          <VerificarListaEstudiantes datosEstudiantes={listaEstudiantes} textosTabla={verificar_datos.estudiantes.tabla} />
        </DivisionCrud>

        <div className="flex justify-center md:justify-end my-5">
          <Link
            to="/cursos/crear-curso"
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center mr-5"
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </Link>
          <button
            onClick={crearCursos}
            /* onClick={() => crearCursoNuevo()} */
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center"
          >
            {preloaderCrearCursos ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              botones.crear.crear
            }
          </button>
        </div>
      </div>

      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img "
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
            aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal curso-creado */}
      {!isError &&
        <ModalConfirmacion nombreModal="curso-creado">
          <Link className="focusable-primary icon-cerrar text-20  right-6 absolute" to="/cursos"></Link>
          <img
            alt="eyex feliz"
            className="w-5/12 mx-auto bg-img"
            src='/images/eyex-creado.png'
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{curso_creado.titulo}</h1>
          <p className="text-center r7-16-negro26 mb-4">
            {curso_creado.individual.descripcion1} <span className="text-resaltado">{nombre} </span>, <span className="text-resaltado">{nombreSede} </span> {curso_creado.individual.descripcion2}
          </p>
          <div className="center my-3">
            <Link
              to="/cursos"
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-2"
            >
              {botones.volver}
            </Link>
          </div>
        </ModalConfirmacion>

      }
    </>
  )
}
export default VerificarIndividual;