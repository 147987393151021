import { useEffect, useState } from "react";
import AnalisisColeccion from "./AnalisisColeccion";
import useObtenerSubsecciones from "../helpers/hooks/useObtenerSubsecciones";
import EscogiendoLibrosComponent from "../../components/libros/EscogiendoLibrosComponent";
import PropTypes  from 'prop-types';

/**
 * Recibe una colección de libros y retorna el componente 'EscogiendoLibrosComponent'
 a quien se le pasará una serie de propiedades para que renderice la colección de libros, la función principal 
 de este contenedor es obtener, refrescar, actualizar la colección de libros y pasarla al componente hijo para que renderice la información correspondiente.
 * @param {
    {
      librosData: {
        libros: Libro[],
      }
    }
 * } props 
 * @returns {JSX.Element} EscogiendoLibrosComponent
 */
const EligiendoLibros = (props) => {
  const { librosData } = props;
  const [schFilter, setSchFilter] = useState('');
  const [librosFiltrados, setLibrosFiltrados] = useState([]);
  const [modoFiltro, setModoFiltro] = useState({ activado: false });

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('libros');

  useEffect(() => {
    /**
  * 
  Si el modo filtro esta activado, solo carga y actualiza los 
  cambios hechos en los libros filtrados (si se hizo alguno) 
  y no carga de nuevo todos los libros, si no esta activado, carga,
   actualiza y renderiza de nuevo la totalidad de libros.
  */

const refrescarLibros = ()=>{
if (Array.isArray(librosData?.libros)) {
 if (modoFiltro.activado) {
   const librosIds= librosFiltrados.map(libro=> libro?.idLibro);
   const filtrados = [...librosData?.libros.reduce((acc,curr)=>{
     if(librosIds.includes(curr?.idLibro)){
       acc.push(curr)
     }
     return acc;
   },[])]
   setLibrosFiltrados(filtrados)

 } else {
   setLibrosFiltrados(JSON.parse(JSON.stringify(librosData.libros)))
 }
}
}

refrescarLibros();

}, [librosData, modoFiltro])

  return (
    <>
      {isReady &&
        <EscogiendoLibrosComponent
          {...props}
          modoFiltro={modoFiltro}
          setModoFiltro={setModoFiltro}
          setLibrosFiltrados={setLibrosFiltrados}
          librosFiltrados={librosFiltrados}
          setSchFilter={setSchFilter}
          schFilter={schFilter}
          AnalisisColeccion={AnalisisColeccion}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}

export default EligiendoLibros;

EligiendoLibros.propTypes = { 
  /**
   * Objeto cuya propiedad libros contiene todos los libros disponibles para la selección.
   */
  librosData: PropTypes.shape({
    libros:PropTypes.arrayOf(PropTypes.object.isRequired)
  }).isRequired,
}