import removeAccents from './removeAccents'
  ;
  /**
   * Comprueba si el nombre ingresado ya existe en el array de cursos
   * @param {{array:object[], nombreCurso:string}} Objeto con los datos necesarios para realizar la comprobación de nombres.
   * @returns {{estaRepetida:boolean, nombreCurso:string}} Objeto con el resultado de la comprobación y el nombre del curso.
   */

 const comprobarNombreRepetido = ({ array, nombreCurso }) => {
  let estaRepetida = false;
  if (nombreCurso !== '') {
    array.every(elemento => {
      const nombreCursoCreado = removeAccents(elemento.nombre);
      const nombreCursoNuevo = removeAccents(nombreCurso);

      if (nombreCursoCreado === nombreCursoNuevo) {
        estaRepetida = true;
        return false;
      }
      return elemento;
    });
  }

  return {estaRepetida, nombreCurso}
}

export default comprobarNombreRepetido;