import React from "react"
import { Switch, Route } from "react-router-dom"
import { Home } from "./home"


export const Classes = ()=>{
    return <>
    <Switch>
      <Route exact path="/cursos">
        <Home/>
      </Route>
    </Switch>
  </>
}