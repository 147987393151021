import { useState, useEffect } from 'react';
import InputText from '../../globales/elementosFormularios/InputText';
import MicroModal from 'micromodal';
import ModalFormEnviado from '../../../containers/ayuda/modalFormEnviado';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de props que le permiten renderizar el formulario de "Tuve problemas con la plataforma". Además, renderiza los componentes InputText, MicroModal y ModalFormEnviado. 
 * @returns ProblemaPlataforma
 */
const ProblemaPlataforma = (props) => {
  const { info, textosInterfaz, enviarASoporte, preloader, mostrarModal, ocultarModal } = props;
  const { descripcion_problema, dispositivo } = info;

  const [campo, setCampo] = useState({ campo: '', valido: null });
  const [enviar, setEnviar] = useState(false);
  const [textArea, setTextArea] = useState();

  const expresion = /^[a-zA-ZÀ-ÿ0-9\s]{1,40}$/;

  useEffect(() => {
    if (mostrarModal) {
      MicroModal.show('formulario-enviado', {
        awaitCloseAnimation: true
      });
    }
  }, [mostrarModal])

  const enviarFormulario = () => {
    setEnviar(true);
    if (campo.valido && textArea) {
      enviarASoporte(campo.campo, textArea)
    }else{
      //si no son validos envia el foco al inicio del formulario
      const startForm = document.querySelector("#plataforma");
      if (startForm) {
        startForm.tabIndex = -1;
        startForm.focus();
        setTimeout(() => startForm.removeAttribute("tabindex"), 1000);
      }
    }
  }

  const alCerrarModal = () => {
    MicroModal.close("formulario-enviado", {
      awaitCloseAnimation: true
    });
    setCampo({ ...campo, campo: '', valido: false })
    setTextArea('');
    setEnviar(false)

    ocultarModal();
  }

  return (
    <>
      <div className="mb-3">
        <label htmlFor="plataforma" className="q7-18 onprimary-var2--color inline-block pb-3">{dispositivo.titulo} <span className="asterisco">*</span></label>
        <InputText
          cambiarEstado={setCampo}
          estado={campo}
          estadoEnviar={enviar}
          identidicador="plataforma"
          mensajeError={!campo.valido && dispositivo.error}
          placeholder={dispositivo.placeholder}
          validarExpresion={expresion}
        />
      </div>

      <label htmlFor="cuentanos" className="q7-18 onprimary-var2--color my-3">{descripcion_problema.problema} <span className="asterisco">*</span></label>
      <textarea
        id="cuentanos"
        className={`textarea focusable-primary mt-3 onsurface-var2--color onsurface-var2--placeholder ${enviar && !textArea && 'textarea-error'}`}
        onChange={(e) => { setTextArea(e.target.value) }}
        placeholder={descripcion_problema.placeholder}
        value={textArea}

      ></textarea>
      <span
      role={"alert"}
      aria-live="assertive" 
      className={`text-14 ${enviar ? !textArea ? 'alert_error' : 'hidden' : 'hidden'}`}>
        {descripcion_problema.error}
      </span>


      <div className="flex justify-end my-5">
        <button
          className="focusable-primary boton-pequenio boton-amarillo boton_hover_morado"
          onClick={() => { enviarFormulario() }}
        >
          {
            preloader ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              textosInterfaz.boton_enviar
          }
        </button>
      </div>
      {enviar &&
        <ModalFormEnviado alCerrarModal={alCerrarModal} textosInterfaz={textosInterfaz} />
      }
    </>
  )
}

export default ProblemaPlataforma;


ProblemaPlataforma.propTypes = {
  /**
   * Objeto que contiene los textos de cada pregunta del formulario.
   */
  info: PropTypes.shape({
    descripcion_problema: PropTypes.shape({
      error: PropTypes.string,
      placeholder: PropTypes.string,
      problema: PropTypes.string
    }),
    dispositivo: PropTypes.shape({
      error: PropTypes.string,
      placeholder: PropTypes.string,
      titulo: PropTypes.string
    })
  }).isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Función que envia la información del formulario al correo. 
   * @type{Function(dispositivo:string, problema:string):void} -callback
   */
  enviarASoporte: PropTypes.func.isRequired,
  /**
 * booleano que contiene el preloader para el llamado de la API EmailAPI
 */
  preloader: PropTypes.bool.isRequired,
  /**
   * Booleano que indica si debe aparecer la ventana modal de enviado
   */
  mostrarModal: PropTypes.bool.isRequired,
  /**
    * Función que oculta la ventana modal
    */
  ocultarModal: PropTypes.func.isRequired,
}