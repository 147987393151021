import { useState } from "react";
import Paginacion from "../../globales/Paginacion";
import PropTypes from "prop-types";

/**
 * Este componente recibe los props datosEstudiantes y textosTabla que son necesarios para renderizar la tabla de estudiantes dentro de la pantalla de verificar instituto/colegio. Además, hace uso del componente global Paginacion.
 * @returns ListaEstudiantes
 */
const ListaEstudiantes = (props) => {
  const { datosEstudiantes, textosTabla } = props;
  const { correo, curso, nombres_apellidos, sin_datos, sin_correo, codigo } =
    textosTabla;
  const [datosDivididos, setDatosDivididos] = useState([]);

  const ActualizarDatosDivididos = (value) => setDatosDivididos(value);

  return (
    <>
      <ul className="encabezadoTabla">
        <li className="col-span-1"></li>
        <li tabIndex={0} className="focusable-primary col-span-2">
          {codigo}
        </li>
        <li tabIndex={0} className="focusable-primary xl:col-span-3">
          {nombres_apellidos}
        </li>
        <li tabIndex={0} className="focusable-primary xl:col-span-4">
          {correo}
        </li>
        <li tabIndex={0} className="focusable-primary xl:col-span-2">
          {curso}
        </li>
      </ul>
      {datosDivididos.length === 0 ? (
        <section className="filaTabla">
          <section className=" center col-span-12">
            <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
            <p className="raleway-700 red-inv-var2--color text-13">
              {" "}
              {sin_datos}
            </p>
          </section>
        </section>
      ) : (
        datosDivididos.map((dato, index) => {
          return (
            <section key={`campo${index}`} className="filaTabla">
              <section className="fila-2-sm1-lg12-xl1  row-span-4 sm:row-span-2 lg:row-span-1">
                <section
                  className=" w-10 h-10 xl:mx-auto text-indent rounded-full img-cover-center"
                  style={{
                    backgroundImage: `url(/images/avatares/avatar${dato.avatar}.png) `,
                  }}
                >
                  <p>{dato.avatar}</p>
                </section>
              </section>
              <section className=" fila-10-sm5-lg6 xl:col-span-2 overflow-auto ">
                <span className=" q7-14-negro26 xl:hidden">{codigo}</span>
                <p
                  tabIndex={0}
                  className=" focusable-primary raleway-700 primary-inv-var1--color text-13 una-linea-texto"
                >
                  {dato.codigo}
                </p>
              </section>
              <section className="fila-10-sm5-lg6 xl:col-span-3">
                <span className="q7-14-negro26  xl:hidden">
                  {nombres_apellidos}
                </span>
                <p
                  tabIndex={0}
                  className="focusable-primary r5-13-negro26 break-words"
                >
                  {dato.nombre}
                </p>
              </section>
              <section className="fila-10-sm5-lg6  xl:col-span-4 overflow-auto">
                <span className="q7-14-negro26  xl:hidden">{correo}</span>
                <p
                  tabIndex={0}
                  className={`${
                    !!dato.correo
                      ? "focusable-primary r5-13-negro26"
                      : "r7-13-rojo"
                  } break-words`}
                >
                  {!!dato.correo ? dato.correo : sin_correo}
                </p>
              </section>
              <section className="fila-10-sm5-lg6 xl:col-span-2 overflow-auto ">
                <span className="q7-14-negro26 xl:hidden">{curso}</span>
                <p
                  tabIndex={0}
                  className="focusable-primary raleway-700 primary-inv-var1--color text-13 una-linea-texto"
                >
                  {dato.curso}
                </p>
              </section>
            </section>
          );
        })
      )}

      <section className={`alineado-izquierda xl:mt-3`}>
        <Paginacion
          datos={datosEstudiantes}
          datosDivididos={ActualizarDatosDivididos}
        />
      </section>
    </>
  );
};
export default ListaEstudiantes;

ListaEstudiantes.propTypes = {
  /**
   * Es un array de objetos que contiene la información de los estudiantes extraídos del excel.
   */
  datosEstudiantes: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.number,
      correo: PropTypes.string,
      curso: PropTypes.string,
      nombres: PropTypes.string,
    })
  ),

  /**
   * Es un objeto que trae los textos del encabezado de la tabla. Este cambia según el idioma activo.
   */
  textosTabla: PropTypes.object.isRequired,
};
