import { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Paginacion from "../../globales/Paginacion";
import { useTrackedState } from "../../../store";
import { FiltroFechas } from "../../../utilities/gestion_fechas/FiltrosFechas";

/**
 * Renderizara una tabla con las estadísticas de todas las sesiones en plataforma realizadas por los estudiantes y profesores, estas estadísticas son pasadas de padre a hijo por props. 
 */
const TablaSesiones = (props) => {
  const { datos, textosTabla } = props;
  const { tabla, resultados, boton } = textosTabla;
  const { fecha, sesiones, promedio_lectura, promedio_plataforma, libros_leidos, sesiones_profesores, promedio_lectura_profesores, promedio_plataforma_profesores, libros_leidos_profesores, no_hay } = tabla;
  const { hay, resultado } = resultados;

  const state = useTrackedState();
  const [datosDivididos, setDatosDivididos] = useState([]);
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)
  const history = useHistory();

  return (
    <>
      <div tabIndex={0} aria-label="Tabla de sesiones/Sessions table" className={`focusable-red ${datos.length > 10 ? null : 'mb-5'}`}>
     
        <>
          {datosDivididos.length === 0 ?
            <div className="filaTabla">
              <div className=" center col-span-12">
                <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                <p className="raleway-700 red-inv-var2--color text-13">{no_hay}</p>
              </div>
            </div>
            :
            datosDivididos.map((dato,i) => {
              return (
                <div key={`campo-${i}`} className="filaTabla">
                  
                  <div tabIndex={0} aria-label={sesiones}  className="focusable-red fila-12-sm4-lg6  overflow-auto">
                    <span className="q7-14-negro26  ">{sesiones}</span>
                    <p className={`r7-13-rojo una-linea-texto `} >{dato.numeroDeSesiones || 0}</p>
                  </div>
                  <div tabIndex={0} aria-label={sesiones_profesores}  className="focusable-red fila-12-sm4-lg6  overflow-auto">
                    <span className="q7-14-negro26  ">{sesiones_profesores || 'Sesiones (profesores)'}</span>
                    <p className={`r7-13-rojo una-linea-texto `} >{dato.numeroDeSesionesProfesores || 0}</p>
                  </div>
                  <div tabIndex={0} aria-label={promedio_lectura}  className="focusable-red fila-12-sm4-lg6 ">
                    <span className="q7-14-negro26  ">{promedio_lectura}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} >{dato.tiempoPromedioDeLectura || 0} min</p>
                  </div>
                  <div tabIndex={0} aria-label={promedio_lectura_profesores}  className="focusable-red fila-12-sm4-lg6 ">
                    <span className="q7-14-negro26  ">{promedio_lectura_profesores || 'Promedio de lecturas (profesores)'}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} >{dato.tiempoPromedioDeLecturaProfesores || 0} min</p>
                  </div>
                  <div tabIndex={0} aria-label={promedio_plataforma}  className="focusable-red fila-12-sm4-lg6 ">
                    <span className="q7-14-negro26  ">{promedio_plataforma}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} >{dato.tiempoPromedioEnPlataforma || 0} min</p>
                  </div>
                  <div tabIndex={0} aria-label={promedio_plataforma_profesores}  className="focusable-red fila-12-sm4-lg6 ">
                    <span className="q7-14-negro26  ">{promedio_plataforma_profesores || 'Promedio de plataforma (profesores)'}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} >{dato.tiempoPromedioEnPlataformaProfesores || 0} min</p>
                  </div>
                  <div tabIndex={0} aria-label={libros_leidos}  className="focusable-red fila-12-sm4-lg6 ">
                    <span className="q7-14-negro26  ">{libros_leidos}</span>
                    <p className={`r7-13-rojo una-linea-texto `} >{dato.librosLeidos || 0}</p>
                  </div>
                  <div tabIndex={0} aria-label={libros_leidos_profesores}  className="focusable-red fila-12-sm4-lg6 ">
                    <span className="q7-14-negro26  ">{libros_leidos_profesores || 'Libros leídos (profesores)'}</span>
                    <p className={`r7-13-rojo una-linea-texto `} >{dato.librosLeidosProfesores || 0}</p>
                  </div>
                  <div tabIndex={0} aria-label={fecha}  className="focusable-red fila-12-sm4-lg6 ">
                    <span className="q7-14-negro26  ">{fecha}</span>
                    <p className="raleway-700 primary-inv-var2--color text-13  una-linea-texto">{FiltroFechas.formatoUsuario(state?.idioma, dato.fecha) || 'N/A' }</p>
                  </div>
                  <div className="fila-12 ">
                    <div className="alineado-izquierda-center">
                      <button
                        onClick={() => history.push({
                          pathname: `/estadisticas/sesiones-de-lectura/detalle-dia`,
                          search:`?fecha=${dato?.fecha}`
                        })}
                        className="focusable-red boton-justo boton-amarillo boton_hover_morado center w-full lg:w-auto lg:px-5 py-2">
                        <p>{boton} </p>
                      </button>
                    </div>
                  </div>

                </div>
              )
            })
          }
        </>
        <div className="md:alineado-verticalmente">
          <div className={`w-full md:w-6/12 `}>
            <p className="r4-16-negro4A">{hay} {datos.length} {datos.length !== 1 ? resultado.plural : resultado.singular} </p>
          </div>

          <div className={`w-full md:w-6/12 alineado-izquierda mt-5 md:mt-0`}>
            <Paginacion datos={datos} datosDivididos={ActualizarDatosDivididos} />
          </div>
        </div>

      </div>
    </>
  )
}
export default TablaSesiones;

TablaSesiones.propTypes = {
  /**
   * Arreglo de objetos con los datos de la tabla
   */
  datos: PropTypes.arrayOf(PropTypes.shape({
    fecha: PropTypes.string,
    numeroDeSesiones: PropTypes.number,
    tiempoPromedioEnPlataforma: PropTypes.number,
    tiempoPromedioDeLectura: PropTypes.number,
    librosLeidos: PropTypes.number,
    numeroDeSesionesProfesores: PropTypes.number,
    tiempoPromedioEnPlataformaProfesores: PropTypes.number,
    tiempoPromedioDeLecturaProfesores: PropTypes.number,
    librosLeidosProfesores: PropTypes.string,
})).isRequired,
  /**
  * Objeto con las traducciones de encabezado y boton
  * @type{
  * tabla: object
  * resultados: object
  * }
  */
  textosTabla: PropTypes.object.isRequired,
}