import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DivisionCrud from "../globales/DivisionCrud";
const SkeletonInstitutoEditar = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={306} height={40} />
      <div className="contenido">
        <h1 className="titulos-principales mb-5">
          <Skeleton className="mb-2" width={176} height={36} />
        </h1>
        <DivisionCrud>
          <>
            <Skeleton width={97} height={24} />
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
            <Skeleton className="ml-5" width={91} height={24} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={97} height={24} />
            <p className="w-full xl:w-60">
              <Skeleton width={'100%'} height={24} />
            </p>
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
            <Skeleton className="ml-5" width={91} height={24} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={97} height={24} />
            <p className="w-full xl:w-60">
              <Skeleton width={'100%'} height={24} />
            </p>
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
            <Skeleton className="ml-5" width={91} height={24} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <div>
            <h3 className="w-60 lg:w-full xl:w-60">
              <Skeleton width={'100%'} height={24} />
            </h3>
            <p className="w-full xl:w-60">
              <Skeleton width={'100%'} height={24} />
            </p>
          </div>
          <div className="lg:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="w-60 lg:w-full xl:w-60">
              <Skeleton width={'100%'} height={24} />
            </h3>
            <p className=" w-full xl:w-60">
              <Skeleton width={'100%'} height={64} />
            </p>
          </>
          <div className="w-full xl:w-3/5 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-3 gap-4 xl:mb-5 ">
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
            <Skeleton width={'100%'} height={80} />
          </div>
        </DivisionCrud>

        <div className="flex justify-end my-5">
          <button className=" w-1/2 sm:w-40 mr-5">
            <Skeleton width={'100%'} height={36} />
          </button>
          <button className=" w-1/2 sm:w-40">
            <Skeleton width={'100%'} height={36} />
          </button>
        </div>
      </div>
    </SkeletonTheme>
  )
}

export default SkeletonInstitutoEditar;