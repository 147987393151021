import { useEffect, useState } from 'react';
//Store
import { useTrackedState } from "../../store";
//Funciones
import buscarElementos from '../../utilities/function/buscarElementos';
//Hooks y funciones personalizadas
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Componentes
import GeneralClubsComponent from './../../components/clubs/GeneralClubsComponent';
import SkeletonClubs from '../../components/clubs/Skeletons/SkeletonClubs';
import PropTypes from 'prop-types';

/**
 * Container que recibe el estado de isLoadingCursos. Este container retorna el componente GeneralClubsComponent al cual se le pasa una serie de propiedades para renderizar la pantalla principal de clubs. La función principal de este componente es buscar clubs.
 * @returns {JSX.Element} GeneralClubsComponent
 */
const GeneralClubs = (props) => {
  const { isLoadingClubs } = props;
  //Estados globales
  const state = useTrackedState();
  const { datosClubs } = state;


  //Estaados del componente
  const [resultadosBusqueda, setResultadosBusqueda] = useState({
    hayResultados: true,
    resultados: []
  });


  //Hooks personalizado para el llamado inicial de los datos en el container
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('clubs');


  useEffect(() => {
    !!datosClubs && setResultadosBusqueda({ ...resultadosBusqueda, resultados: datosClubs })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosClubs]);


  //Buscador de clubs
  const buscarClubs = ({ value }) => {
    const resultados = buscarElementos({ value, datos: datosClubs })

    const resultadoBusqueda = {
      hayResultados: resultados.hayResultados,
      resultados: resultados.resultados
    }

    setResultadosBusqueda(resultadoBusqueda);
  }


  //Buscador de clubs
  const borrarFiltros = ({ refInput }) => {
    refInput.value = '';

    buscarClubs({ value: '' });    
  }


  return (
    <>
      {
        isReady && !isLoadingClubs ?
          <GeneralClubsComponent
            borrarFiltros={borrarFiltros}
            buscarClubs={buscarClubs}
            resultadosBusqueda={resultadosBusqueda}
            textosInterfaz={textosInterfaz}
          />
          :
          <SkeletonClubs />
      }
    </>
  )
}

export default GeneralClubs;


GeneralClubs.propTypes = {
  /**
   *  Es un booleano que indica si la información de clubes ya cargo. 
   */
  isLoadingClubs: PropTypes.bool.isRequired
}