import { useEffect, useState } from 'react';
//Hooks personalizadas 
import useObtenerDatosExcel from '../helpers/hooks/obtenerDatosExcel/useObtenerDatosExcel';
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Funciones
import randomNumber from '../../utilities/function/randomNumber';
//Store
import { useTrackedState, useDispatch } from '../../store';
//Componentes
import CrearInstitutoComponent from "../../components/institutos/CrearInstituto";
import SkeletonInstitutoCrear from '../../components/institutos/SkeletonInstitutoCrear';
import VentanaAlerta from '../../components/globales/VentanaAlerta';
import { SeleccionLibrosInicialAPI } from '../../api/SeleccionLibrosInicialAPI';
import PropTypes from 'prop-types';

/**
  * Componente de tipo container que recibe la función de descartar cambios y el estado isCrearSede. Este container retorna el componente CrearInstitutoComponent o VentanaAlerta según el valor del estado, estos conllevan una serie de propiedades que les permiten renderizar el contenido.  La función principal de este contenedor es almacenar los datos temporalmente.
 * @returns {JSX.Element} CrearInstitutoComponent o VentanaAlerta
   */
const CrearInstituto = (props) => {
  const { isCrearSede, descartarCambios } = props;
  //Estados globales
  const state = useTrackedState();
  const { paquetes, temporal } = state;
  const dispatch = useDispatch();

  //Estado del componente
  const [archivoExcel, setArchivoExcel] = useState(null);
  const [datosTemporales, setDatosTemporales] = useState({ hayDatos: false })
  const [paqueteSeleccionado, setPaqueteSeleccionado] = useState({ id: '', nombre: '' });
  const [paquetesDisponibles, setPaquetesdisponibles] = useState([]);
  //  const [paquetesDisponibles,setX] = useState();
  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('instituto');
  const hookDatosExcel = useObtenerDatosExcel({ archivo: archivoExcel, nombreHoja: 'archivo_excel', seccionACrear: 'sede' })
  const { advertenciasExcel, datosExcel, errorSubirExcel, isLoadingExcel, isReadyTextosHoja, isReadyTextosTabla } = hookDatosExcel;


  useEffect(() => {
    const cargarPaquetesDisponibles = async () => {
      await SeleccionLibrosInicialAPI
        .paquetesDisponibles()
        .then(listaPaquetes => {
          setPaquetesdisponibles(listaPaquetes)
        })
        .catch(error => console.log(error))
    }
    cargarPaquetesDisponibles();
  }, [])

  useEffect(() => {
    if (!!temporal?.sede) {
      setDatosTemporales({
        hayDatos: true,
        datos: temporal.sede
      })
      setPaqueteSeleccionado({ id: temporal.sede.paquete.id, nombre: temporal.sede.paquete.nombre })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal])


  const cambiarPaquete = ({ paquete }) => setPaqueteSeleccionado(paquete);


  const obtenerDatosExcel = ({ archivo, e, nombre }) => {
    setArchivoExcel({ archivo, e, nombre })
  }

  /**
   * Guarda los datos del formulario en el store de manera permanente. 
   * @param {number} color - numero del color
   * @param {string} correo - string del correo
   * @param {string} nombre - string con el nombre de la institucion o colegio
   * @param {shape} paquete - Objeto que contiene string del nombre y number del id del paquete
   * @param {string} telefono - string con el numero de telefono
   */
  const guardarDatosTemporalmente = async ({ color, correo, nombre, paquete, telefono }) => {
    const coloresBotones = process.env.REACT_APP_COLORES_SEDE.split(', ');
    const randomColor = randomNumber({ minimo: 0, maximo: coloresBotones.length - 1 });
    const cursos = [];
    for (const key in datosExcel.cursos) {
      const element = datosExcel.cursos[key];
      cursos.push({
        nombre: key,
        ...element
      });
    };

    const dataSede = {
      color: !!color ? color : coloresBotones[randomColor],
      correo: !!correo && correo !== '' ? correo : null,
      cursos: cursos,
      nombre: nombre,
      nombreArchivo: datosExcel.nombreArchivo,
      paquete: paquete,
      profesores: datosExcel.profesores,
      telefono: !!telefono && telefono !== '' ? telefono : null
    };

    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "sede",
      value: dataSede
    });
  }


  return (
    <>
      {(() => {
        if (isCrearSede) {
          if (isReady && isReadyTextosTabla && isReadyTextosHoja) {
            return (
              <CrearInstitutoComponent
                advertenciasExcel={{
                  hayAdvertencias: advertenciasExcel.hayAdvertencias,
                  href: advertenciasExcel.href,
                  nombreArchivo: advertenciasExcel.nombreArchivo
                }}
                cambiarPaquete={cambiarPaquete}
                datosTemporales={datosTemporales}
                descartarCambios={descartarCambios}
                errorExcel={errorSubirExcel}
                guardarDatosTemporalmente={guardarDatosTemporalmente}
                isLoadingExcel={isLoadingExcel}
                obtenerDatosExcel={obtenerDatosExcel}
                paqueteSeleccionado={paqueteSeleccionado}
                paquetes={paquetesDisponibles}
                textosInterfaz={{
                  advertencias: textosInterfaz.advertencias,
                  botones: textosInterfaz.botones,
                  crear: textosInterfaz.crear,
                  descartar_cambios: textosInterfaz.descartar_cambios,
                  error_excel: textosInterfaz.error_excel,
                  miga_de_pan: textosInterfaz.miga_de_pan,
                }}
              />
            )
          } else {
            return <SkeletonInstitutoCrear />
          }
        } else {
          if (isReady) {
            return (
              <VentanaAlerta
                textosInterfaz={{
                  titulo: textosInterfaz.restriccionInstituto.titulo,
                  descripcion: textosInterfaz.restriccionInstituto.descripcion,
                  textoBoton: textosInterfaz.botones.volver
                }}
                link='/institutos'
                imagen={{
                  alt: 'blop con un icono de alerta',
                  img: 'alertas/blop-alerta.png',
                }}
              />
            )
          }
        }
      })()}
    </>
  )
}

export default CrearInstituto;

CrearInstituto.propTypes = {
  /**
   * Estado de tipo booleano que valida si se puede seguir creando institutos/colegios.
   */
  isCrearSede: PropTypes.bool,

  /**
   * funcion callback que borra los datos temporales.
   */
   descartarCambios: PropTypes.func.isRequired
}