import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';

/*componentes */
import DivisionCrud from "../../globales/DivisionCrud";
import Modal from "../../globales/modales/Modal";
import ModalConfirmacion from "../../globales/modales/ModalConfirmacion";
import { Miga, MigasDePan } from "../../globales/MigaDePan";

/**
 * Componente que recibe una serie de props necesarios para renderizar la pantalla de verificar los datos de modo individual. Este hace uso de los componentes globales DivisionCrud, Modal y ModalConfirmacion.
 * @returns VerificarProfesorIndividual
 */
const VerificarProfesorIndividual = (props) => {
  const { datosProfesoresNuevos, descartarCambios, crearProfesores, preloader, respuestaApiCrear, textosInterfaz } = props;
  const { miga_de_pan, verificar_datos, botones, descartar_cambios, profesor_creado } = textosInterfaz;
  const { nombre, correo, sede, cursos } = verificar_datos;

  const { isError, mensaje } = respuestaApiCrear;

  const [modalDescartar, setModalDescartar] = useState(false);
  const history = useHistory();

  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar]);


  /**Una vez que cambia el valor isError, al dar click en crear profesor, la ventana modal de creado aparece   */
  useEffect(() => {
    if (isError !== null) {
      if (!isError) {
        MicroModal.show('profesor-creado', {
          awaitCloseAnimation: true
        });
      } else {
        notificacion(textosInterfaz.notificacion.error, mensaje, "danger")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])


  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Profesores'}
          alSeleccionar={() => {
            if (modalDescartar) {
              MicroModal.show('cancelar-crear', {
                awaitCloseAnimation: true
              });
            } else {
              setModalDescartar(true)
            }
          }}>
        </Miga>
        <Miga
          texto={miga_de_pan?.crear || 'Crear profesores'}
          alSeleccionar={() => {
            history.push('/profesores/crear-profesores')
          }}>
        </Miga>
        <Miga
          texto={miga_de_pan?.verificar || 'Verificar profesores'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{verificar_datos.titulo}</h1>

        {/* nombre */}
        <DivisionCrud>
          <h2 className="raleway-700 onbackground-var1--color">{nombre.titulo} </h2>
          <div className=" alineado-verticalmente input-lectura">
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">{datosProfesoresNuevos.nombre} </p>
          </div>
        </DivisionCrud>

        {/* correo */}
        <DivisionCrud>
          <h3 className="raleway-700 onbackground-var1--color">{correo.titulo} </h3>
          <>
            <div className=" alineado-verticalmente input-lectura">
              {!!datosProfesoresNuevos.correo
                ?
                <>
                  <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                  <p className="raleway-700 primary-inv-var2--color text-13">{datosProfesoresNuevos.correo} </p>
                </>
                :
                <p className={`r7-13-rojo alineado-verticalmente`}>
                  <span className="icon-alerta text-18  mr-3"></span> {correo.sin_corrreo}
                </p>
              }
            </div>
          </>
        </DivisionCrud>

        {/* sede */}
        <DivisionCrud>
          <h3 className="raleway-700 onbackground-var1--color">{sede.titulo} </h3>
          <div className=" alineado-verticalmente input-lectura">
            {!!datosProfesoresNuevos.nombreSede
              ?
              <>
                <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                <p className="raleway-700 primary-inv-var2--color text-13">{datosProfesoresNuevos.nombreSede}</p>
              </>
              :
              <p className={`r7-13-rojo alineado-verticalmente`}>
                <span className="icon-alerta text-18  mr-3"></span> {sede.sin_sede}
              </p>
            }
          </div>
        </DivisionCrud>

        {/* cursos */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{datosProfesoresNuevos.datosCursos.length} {datosProfesoresNuevos.datosCursos.length !== 1 ? cursos.plural : cursos.singular} </h3>
            <p className="r4-14-gris6a">{cursos.descripcion}</p>
          </>
          <>
            <>
              <div className="input-lectura">
                {datosProfesoresNuevos.datosCursos.length !== 0
                  ?
                  <div>
                    {
                      datosProfesoresNuevos.datosCursos.map((curso, index) => {
                        return (
                          <p
                            key={curso.id}
                            className={`raleway-700 primary-inv-var2--color alineado-verticalmente text-13 ${index + 1 !== datosProfesoresNuevos.datosCursos.length && 'mb-2'}`}>

                            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span> {curso.nombre}
                          </p>
                        )
                      })
                    }
                  </div>
                  :
                  <p className={`r7-13-rojo alineado-verticalmente`}>
                    <span className="icon-alerta text-18  mr-3"></span> {cursos.sin_cursos}
                  </p>
                }
              </div>
            </>
          </>
        </DivisionCrud>


        {/* botones */}
        <div className="alineado-izquierda-center my-5">
          <Link
            to="/profesores/crear-profesores"
            className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center mr-5 focusable-primary"
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </Link>
          <button
            onClick={crearProfesores}
            className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center focusable-primary"
          >
            {
              preloader ?
                <div className="center">
                  <span className="icon-spinner7 animate-spin text-20"></span>
                </div>
                : botones.crear.individual
            }
          </button>
        </div>
      </div>

      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal profesor -creado */}
      {!isError &&
        <ModalConfirmacion nombreModal="profesor-creado">
          <Link className="icon-cerrar text-20  right-6 absolute focusable-primary onbackground-var1--color" to="/profesores"></Link>
          <img
            alt="eyex feliz"
            className="w-5/12 mx-auto bg-img"
            src='/images/eyex-creado.png'
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{profesor_creado.titulo}</h1>
          <p className="text-center r7-16-negro26 mb-4">
            {profesor_creado.individual.descripcion1} <span className="text-resaltado">{datosProfesoresNuevos.nombre} </span>, <span className="text-resaltado">{datosProfesoresNuevos.nombreSede} </span> {profesor_creado.individual.descripcion2}
          </p>
          <div className="center my-3">
            <Link
              to="/profesores"
              className="boton-justo boton-amarillo boton_hover_morado py-2 focusable-primary"
            >
              {botones.volver.principal}
            </Link>
          </div>
        </ModalConfirmacion>

      }
    </>
  )
}
export default VerificarProfesorIndividual;

VerificarProfesorIndividual.propTypes = {
  /**
   * Objeto que contiene la información de los profesores que se desean crear
   */
  datosProfesoresNuevos: PropTypes.object.isRequired, //Esto puede ser un objeto

  /**
   * función que se encarga de borrar los datos temporales que se guarden en el store.
   */
  descartarCambios: PropTypes.func.isRequired,

  /**
  * función que se encarga de crear los profesores creados temporalmente ya sea de forma masiva o individual. Para esto esta función se encarga de llamar el api de crear profesores.
  */
  crearProfesores: PropTypes.func.isRequired,

  /**
   *  Es un booleano que contiene el preloader para el llamado del API de crear profesores.
   */
  preloader: PropTypes.bool.isRequired,

  /**
  * objeto que contiene dos propiedades que va a devolver cuando el API crear profesores termine de responder.
  */
  respuestaApiCrear: PropTypes.shape({
    isError: PropTypes.string,
    mensaje: PropTypes.string
  }).isRequired,

  /**
     *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
     */
  textosInterfaz: PropTypes.object.isRequired,
}