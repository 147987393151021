import ModalConfirmacion from "../../components/globales/modales/ModalConfirmacion";
import PropTypes from 'prop-types';

/**
 * Container que recibe la función de cerrar modal y los textos de interfaz. Este container renderiza la ventana modal de formulario enviado. Este container utiliza el componente global ModalConfirmacion.
 * @returns ModalFormEnviado
 */
const ModalFormEnviado = (props) => {
  const { alCerrarModal, textosInterfaz } = props;
  return (
    <ModalConfirmacion nombreModal="formulario-enviado">
      <img
        alt="eyex feliz"
        className="w-5/12 mx-auto bg-img"
        src='/images/eyex-creado.png'
      />
      <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{textosInterfaz.titulo_modal}</h1>
      <p className="text-center r7-16-negro26 mb-4">
        {textosInterfaz.descripcion_modal}
      </p>
      <div className="center my-3">
        <button
          aria-label="Cerrar"
          onClick={() => { alCerrarModal(); }}
          className="boton-justo boton-amarillo boton_hover_morado py-1 px-10"
        >
          {textosInterfaz.boton_cerrar}
        </button>
      </div>
    </ModalConfirmacion>
  )
}
export default ModalFormEnviado;

ModalFormEnviado.propTypes = {
  /**
   * Función que cierra la ventana modal y restablece los valores del formulario.
   */
  alCerrarModal: PropTypes.func.isRequired,
   /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
    textosInterfaz: PropTypes.object.isRequired
}