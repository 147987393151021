import useObtenerSubsecciones from "../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Componente que renderiza la ventana de contenido no disponible. Esto se hace para limitar el acceso a la plataforma según tipo de usuario.
 */
const PaginaNoAutorizada = (props) => {

  const {textosSubSecciones:traducciones}= useObtenerSubsecciones('global');

  return (
    <div className="px-5 h-full center">
      <div className="text-center flex flex-col ">
        <div className="mt-5 lg:mt-0 lg:order-2 flex flex-col">
          <h1 className="font-bold onbackground-var2--color text-28">{traducciones?.advertencias?.no_autorizado?.titulo || 'Acceso no autorizado'}</h1>
          <p className="onbackground-var1--color text-26 lg:w-8/12 mx-auto mb-5">{traducciones?.advertencias?.no_autorizado?.descripcion ||  'El rol del usuario actual no tiene permisos para acceder a este sitio.'}</p>
        </div>

        <img
          alt={'Acceso no permitido/Unauthorized access'}
          className="sm:w-1/2 mx-auto mb-5 lg:order-1"
          src={`/images/alertas/blop-alerta.png`}
        />
      </div>
    </div>
  )
}
export default PaginaNoAutorizada;