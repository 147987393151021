import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const SkeletonCursosVerificarMasivo = () => {
  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
        <Skeleton className="ruta" width={447} height={40} />
        <div className="contenido">
          <h1 className="titulos-principales  ">
            <div className="md:w-96 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>

          <div className="grid md:grid-cols-2 gap-11 mt-10">
            <Skeleton className="h-72" width={'100%'} />
            <Skeleton className="h-72" width={'100%'} />
          </div>

          <div className="flex justify-end my-5">
            <button className=" w-1/2 sm:w-40 mr-5">
              <Skeleton width={'100%'} height={36} />
            </button>
            <button className=" w-1/2 sm:w-40">
              <Skeleton width={'100%'} height={36} />
            </button>
          </div>
        </div>
      </SkeletonTheme>
    </>
  )
}
export default SkeletonCursosVerificarMasivo;