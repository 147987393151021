import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import MicroModal from 'micromodal';
import { store } from 'react-notifications-component';

/*Componentes */
import Paginacion from "../../globales/Paginacion";
import VentanaAlerta from '../../globales/VentanaAlerta';
import Modal from '../../globales/modales/Modal';
import ModalConfirmacion from '../../globales/modales/ModalConfirmacion';

const VerificarMasivo = (props) => {
  const { crearCursos, datosCursosNuevos, descartarCambios, eliminarCurso, isDisableButtonCrear, preloaderCrearCursos, textosInterfaz, numeroCursos, respuestaApiCrearCursos } = props;
  const { isError, mensaje } = respuestaApiCrearCursos;
  const { verificar_datos, botones, miga_de_pan, no_hay_cursos, descartar_cambios, curso_creado } = textosInterfaz;

  const [datosDivididos, setDatosDivididos] = useState([]);
  const [modalDescartar, setModalDescartar] = useState(false);

  /**Una vez cambia el valor de modal descartar, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar])

  /**Una vez que cambia el valor isError, la ventana modal de curso creado aparece   */
  useEffect(() => {
    if (isError !== null) {
      if (!isError) {
        MicroModal.show('curso-creado', {
          awaitCloseAnimation: true
        });
      } else {
        notificacion(textosInterfaz.notificacion.error, mensaje, "danger");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  const actualizarDatosDivididos = (value) => setDatosDivididos(value)

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <button
            className="focusable-primary mr-2"
            onClick={() => {
              if (modalDescartar) {
                MicroModal.show('cancelar-crear', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalDescartar(true)
              }
            }}
          >{miga_de_pan.principal}</button>  &gt;
          <Link to="/cursos/crear-curso" className="focusable-primary mx-2">{miga_de_pan.crear}</Link>  &gt;
          <span className="ml-2 font-bold">{miga_de_pan.verificar}</span>
        </p>
      </div>

      <div className="contenido">
        <div className="h-full">
          <h1 className="titulos-principales">{verificar_datos.titulo}</h1>

          {datosCursosNuevos.length !== 0 ?
            <>
              <div className="md:grid md:grid-cols-2 md:gap-6 xl:gap-11 mt-10">

                {
                  datosDivididos.map((curso, index) => {
                    return (
                      <div key={`curso-${index}`} className="caja-creado mt-8 md:mt-0">
                        <div className="caja-creado-titulo surface-var1--bg alineado-verticalmente relative overflow-hidden ">
                          <img className="caja-creado-titulo-img img-80-center" src={`/images/badges/badge-${curso.icono}.png`} alt="icono del curso" />

                          <p className="q7-20-blanco ml-20 una-linea-texto">
                            {curso.nombre}
                            {
                              curso.isNombreCursoRepetido &&
                              <>
                                <span
                                  className="icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
                                  data-tip={true}
                                  data-scroll-hide={true}
                                  data-for='tool-nombre-curso'
                                ></span>
                                <ReactTooltip
                                  backgroundColor="#FF3548"
                                  className="quicksand-400"
                                  id="tool-nombre-curso"
                                  place="top"
                                  textColor="#FFFFFF"
                                  event="mouseover mouseenter click"
                                  eventOff="mouseleave mouseout scroll mousewheel blur"
                                  effect="solid"
                                >
                                  <p>{verificar_datos.masivo.tool_tips.curso_repetido}</p>
                                </ReactTooltip>
                              </>

                            }
                          </p>

                        </div>

                        <div className=" px-5 xl:px-10 pb-5 md:pb-0">
                          <div className="caja-verificar-fila alineado-verticalmente">
                            <span className="onsurface-var1--color icon-library caja-creado-icono"></span>
                            <p className='onbackground-var1--color'>{curso.nombreSede}</p>
                          </div>

                          <div className="caja-verificar-fila alineado-verticalmente">
                            <span className="onsurface-var1--color icon-carita-redonda caja-creado-icono"></span>
                            <p className='onbackground-var1--color'>
                              {curso.profesores.length} {curso.profesores.length !== 1 ? verificar_datos.masivo.profesores.plural : verificar_datos.masivo.profesores.singular}
                              {curso.profesores.length === 0 &&
                                <span
                                  className="icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
                                  data-tip={true}
                                  data-scroll-hide={true}
                                  data-for='tool-profesores'
                                ></span>
                              }
                            </p>
                            <ReactTooltip
                              backgroundColor="#FF3548"
                              className="quicksand-400"
                              id="tool-profesores"
                              place="top"
                              textColor="#FFFFFF"
                              event="mouseover mouseenter click"
                              eventOff="mouseleave mouseout scroll mousewheel blur"
                              effect="solid"
                            >
                              <p className='onbackground-var1--color'>{verificar_datos.masivo.tool_tips.sin_profesor}</p>
                            </ReactTooltip>
                          </div>

                          <div className="caja-verificar-fila alineado-verticalmente">
                            <span className="onsurface-var1--color icon-usuario caja-creado-icono"></span>
                            <p className='onbackground-var1--color'>{curso.estudiantes.length} {curso.estudiantes.length !== 1 ? verificar_datos.masivo.estudiantes.plural : verificar_datos.masivo.estudiantes.singular}</p>
                          </div>

                          <div className="center mt-5 mb-7" >

                            <button
                              className="focusable-primary boton-pequenio boton-rojo boton_hover_morado"
                              onClick={() => eliminarCurso({ key: curso.key })}
                            >
                              {botones.eliminar}
                            </button>

                            <Link
                              to={`/cursos/crear-curso/verificar-cursos/${curso.key}`}
                              className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja center ml-3">
                              {botones.editar.curso}
                            </Link>
                          </div>
                        </div>

                      </div>
                    )
                  })
                }
              </div>

              <div className={`alineado-izquierda mt-5`}>
                <Paginacion datos={datosCursosNuevos} datosDivididos={actualizarDatosDivididos} />
              </div>

              <div className="alineado-izquierda my-5">
                <Link
                  to="/cursos/crear-curso"
                  className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center mr-5"
                >
                  <span className="icon-volver pr-3"></span>
                  {botones.regresar}
                </Link>
                <button
                  onClick={crearCursos}
                  className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center"
                  disabled={isDisableButtonCrear}
                >
                  {preloaderCrearCursos ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    :
                    <>{botones.crear.crear}</>
                  }
                </button>
              </div>
            </>
            :

            <VentanaAlerta
              textosInterfaz={{
                titulo: no_hay_cursos.titulo,
                descripcion: no_hay_cursos.descripcion,
              }}
              imagen={{
                alt: 'blop triste porque no hay cursos',
                img: 'alertas/blop-alerta-cursos.png',
              }}
            >
              <Link
                className="focusable-primary boton-justo mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado"
                to='/cursos/crear-curso'
              >
                {no_hay_cursos.boton}
              </Link>
            </VentanaAlerta>
          }
        </div>
      </div>

      {/* Modal descartar */}
      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
            aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal curso-creado */}
      {!isError &&
        <ModalConfirmacion nombreModal="curso-creado">
          <Link className="focusable-primary icon-cerrar text-20 right-6 absolute" to="/cursos"></Link>
          <img
            alt="eyex feliz"
            className="w-5/12 mx-auto bg-img"
            src='/images/eyex-creado.png'
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{curso_creado.titulo}</h1>
          <p className="text-center r7-16-negro26 mb-4">
            {curso_creado.masivo.descripcion} <span className="text-resaltado">{numeroCursos}</span> {numeroCursos !== 1 ?curso_creado.masivo.curso.plural : curso_creado.masivo.curso.singular}
          </p>
          <div className="center my-3">
            <Link
              to="/cursos"
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-2"
            >
              {botones.volver}
            </Link>
          </div>
        </ModalConfirmacion>

      }
    </>
  )
}
export default VerificarMasivo;