import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonClubs = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={160} height={40} />

      <div className="contenido">
        <h1 className="titulos-principales  ">
          <div className="md:w-96 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <div className="w-7/12 md:w-5/12">
          <Skeleton width={'100%'} height={36} />
        </div>

        <div className="w-full md:alineado-izquierda-center  mt-3">
          <div className="md:w-6/12 xl:w-451p mt-6 md:mt-0 ml-">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>

        <div className="w-full alineado-izquierda my-6 md:mt-0 ">
          <button className="boton-pequenio">
            <Skeleton width={'100%'} height={36} />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-4">
          <Skeleton className="h-60" width={'100%'} />
          <Skeleton className="h-60" width={'100%'} />
          <Skeleton className="h-60" width={'100%'} />
        </div>
      </div>
    </SkeletonTheme>
  )
}
export default SkeletonClubs;