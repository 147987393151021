import { useEffect, useState } from "react";
import { useTrackedState } from "../../../../store";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import useInitialDataOfSection from "../../../../containers/helpers/useInitiailDataOfSection";
import { Header } from "../../components/header";
import { ClubsSkeleton } from "../../components/skeletons/clubs-skeleton";
import { ClubCard } from "../components/club-card";

export const ClubsHome = () => {
	const appState = useTrackedState();

	const [clubs, setClubs] = useState([]);
	//Hooks personalizados
	const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const { isLoading: isLoadingClubs } = useInitialDataOfSection({
		pathname: "/clubs/obtenerClubs",
		property: "datosClubs",
		section: "clubs",
	});

	useEffect(() => {
		if (Array.isArray(appState?.datosClubs)) {
			setClubs(appState?.datosClubs);
		} else {
			setClubs([]);
		}
	}, [appState]);

	return (
		<>
			{isReady && !isLoadingClubs ? (
				<>
					<Header />
					<div className="contenido">
						<section className="blue-var2--bg blue-inv-var2--bordered rounded-md shadow-4 mt-10 py-5 px-5 lg:px-10 ">
							<p className="q7-28 onbackground-var2--color mt-2">
								<span className="icon-corona"></span> {textos?.clubs?.titulo}
							</p>
							{clubs?.length > 0 ? (
								clubs?.map((club, i) => {
									return <ClubCard key={club?.id} club={club} />;
								})
							) : (
								<div className="center">
									<div>
										<img
											className={`blue-inv-var2--bg`}
											src="/images/perfil-estudiante/clubs.png"
											alt="ayex te dice no hay clubs"
										/>
										<p className="r7-20-negro26 mt-5 text-center">
											{textos?.clubs?.no_hay}
										</p>
									</div>
								</div>
							)}
						</section>
					</div>
				</>
			) : (
				<ClubsSkeleton />
			)}
		</>
	);
};
