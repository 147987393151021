import { useState } from "react";
import Paginacion from "../../globales/Paginacion";

const VerificarListaEstudiantes = (props) => {
  const { datosEstudiantes, textosTabla } = props;
  const { codigo, correo, nombres_apellidos, sin_datos, sin_correo, sin_codigo} = textosTabla;
  const [datosDivididos, setDatosDivididos] = useState([]);

  /*Esta función es para el componente de paginación y devuelve la cantidad de datos que se deben mostrar */
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  return (
    <>
      <div className="encabezadoTabla">
        <p className="col-span-1"></p>
        <p className="col-span-2">{codigo}</p>
        <p className="xl:col-span-5">{nombres_apellidos}</p>
        <p className=" xl:col-span-4">{correo}</p>
      </div>
      {datosDivididos.length === 0 ?
        <div className="filaTabla xl:grid-cols-12">
          <div className=" center col-span-12">
            <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
            <p className="raleway-700 red-inv-var2--color text-13"> {sin_datos}</p>
          </div>
        </div>
        :
        datosDivididos.map((dato, index) => {
          return (
            <div key={`campo${index}`} className="filaTabla">
              <div className="fila-2-sm1-lg12-xl1  row-span-3 sm:row-span-2  md:row-span-1">
                <div
                  className="  w-10 h-10 xl:mx-auto text-indent rounded-full img-cover-center"
                  style={{ backgroundImage: `url(/images/avatares/avatar${dato.avatar}.png) ` }}
                >
                  <p>{dato.avatar}</p>
                </div>
              </div>
              <div className="fila-10-sm5-md3-lg6 xl:col-span-2">
                <span className="q7-14-negro26  xl:hidden">{codigo}</span>
                <p className={`${!!dato.codigo ? 'raleway-700 text-13 primary-inv-var2--color' : 'r7-13-rojo'} `}>{!!dato.codigo ? dato.codigo : sin_codigo}</p>
              </div>
              <div className="fila-10-sm5-md4-lg6 xl:col-span-5">
                <span className="q7-14-negro26  xl:hidden">{nombres_apellidos}</span>
                <p className="r5-13-negro26 break-words">{dato.nombre}</p>
              </div>
              <div className="fila-10-sm5-md4-lg6 xl:col-span-4 overflow-auto">
                <span className="q7-14-negro26  xl:hidden">{correo}</span>
                <p className={`${!!dato.correo ? 'r5-13-negro26' : 'r7-13-rojo'} break-words`}>{!!dato.correo ? dato.correo : sin_correo}</p>
              </div>
            </div>
          )
        })
      }

      <div className={`alineado-izquierda xl:mt-3`}>
        <Paginacion datos={datosEstudiantes} datosDivididos={ActualizarDatosDivididos} />
      </div>
    </>
  )
}
export default VerificarListaEstudiantes;