import { useEffect, useState } from "react";
import AnalisisColeccion from "./AnalisisColeccion";
import SuscripcionActivaComponent from "../../components/libros/SuscripcionActivaComponent";
import useObtenerSubsecciones from "../helpers/hooks/useObtenerSubsecciones";
import PropTypes from 'prop-types';
/**
 Este componente de tipo Container se encarga de gestionar refrescar el estado de los libros y de pasar la lógica y propiedades relacionadas a los filtros de busqueda, libros, cursos y sedes hacia el componente 'SuscripcionActivaComponent' para que renderice los correspondientes datos.
 */
const SuscripcionActiva = (props) => {
  const { librosData, itemsActivos, cursosData } = props;
  const [schFilter, setSchFilter] = useState('');
  const [librosFiltrados, setLibrosFiltrados] = useState([]);
  const [modoFiltro, setModoFiltro] = useState({ activado: false });

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('libros');

  useEffect(() => {
         /**
       * 
       Si el modo filtro esta activado, solo carga y actualiza los 
       cambios hechos en los libros filtrados (si se hizo alguno) 
       y no carga de nuevo todos los libros, si no esta activado, carga,
        actualiza y renderiza de nuevo la totalidad de libros.
       */

   const refrescarLibros = ()=>{
    if (Array.isArray(librosData?.libros)) {
      if (modoFiltro.activado) {
        const librosIds= librosFiltrados.map(libro=> libro?.idLibro);
        const filtrados = [...librosData?.libros.reduce((acc,curr)=>{
          if(librosIds.includes(curr?.idLibro)){
            acc.push(curr)
          }
          return acc;
        },[])]
        setLibrosFiltrados(filtrados)

      } else {
        setLibrosFiltrados(JSON.parse(JSON.stringify(librosData.libros)))
      }
    }
   }

   refrescarLibros();
   
  }, [librosData, modoFiltro])

  const compileCursosData = () => {
    if (Object.keys(cursosData).length > 0) {
      let sedes = [];
      let cursos = cursosData[itemsActivos.sede].cursos
      for (let i in cursosData) {
        let item = cursosData[i];
        sedes.push({ nombre: item.nombre, id: i })
      }
      return { sedes, cursos }
    } else {
      return { sedes: [], cursos: [] }
    }
  }
  return (
    <>
      {
        isReady &&
        <SuscripcionActivaComponent
          {...props}
          modoFiltro={modoFiltro}
          setModoFiltro={setModoFiltro}
          compileCursosData={compileCursosData}
          setLibrosFiltrados={setLibrosFiltrados}
          librosFiltrados={librosFiltrados}
          setSchFilter={setSchFilter}
          schFilter={schFilter}
          AnalisisColeccion={AnalisisColeccion}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}

export default SuscripcionActiva;

SuscripcionActiva.propTypes = {
  /**
* Objeto que contiene las sedes y sus respectivos cursos
*/
  cursosData: PropTypes.object,
  /**
* Objeto que contiene una propiedad con los libros disponibles
*/
  librosData: PropTypes.shape({
    libros: PropTypes.arrayOf(PropTypes.object),
  }),
  /**
 * Objeto que contiene los IDs de los items activos cuando se está en modo de 'Plan Activo'.
 */
  itemsActivos: PropTypes.shape({
    sede: PropTypes.string,
    curso: PropTypes.string,
  }),
}