import { useEffect, useState } from "react";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { useHistory } from "react-router-dom";
import { AccionesLibroAPI } from "../../../../api/AccionesLibroAPI";
import { adaptarPortadaLibros } from "../../../../utilities/adaptadores";
import { LibrosAPI } from "../../../../api/LibrosAPI";
import { CancelRequestController } from "../../../../utilities/cancel-request-controller";
import { Filters } from "../components/filters";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NoHayResultados from "../../../../components/globales/NoHayResultados";
import PropTypes from "prop-types";
import { BooksList } from "../components/book-list";

const gridSecciones = `
my-5
grid 
grid-cols-2 
sm:grid-cols-3
md:grid-cols-5 
md:self-start  
xl:grid-cols-6
gap-8
lg:gap-6
`;

/**
 * Este componente recibe una serie de props que permiten renderizar la pantalla de libros en el perfil del estudiante. Además, hace uso de los componentes ExplorarLibros y SeccionLibros.
 * @returns LibrosEstudianteComponent
 */
export const BooksHome = () => {
	const { textosSubSecciones } = useObtenerSubsecciones("perfil_estudiante");
	const traducciones = textosSubSecciones;
	const [modoFiltro, setModoFiltro] = useState({ activado: false });
	const [filtros, setFiltros] = useState(undefined);
	const [libros, setLibros] = useState([]); // libros totales del estudiante
	const history = useHistory();
	const [cargando, setCargando] = useState({
		libros: false,
	});
	const [filterAbortController, setFilterAbortController] = useState(null);
	const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

	const mostrarLibro = (libro) => {
		history.push({
			pathname: `/visor/${libro.idLibro}`,
			state: { idLibro: libro.idLibro, pathAnterior: window.location.pathname },
		});
	};

	const agregarRemoverAFavoritos = async (libro, favorito) => {
		await AccionesLibroAPI.agregarRemoverAFavoritos([libro?.idLibro], favorito).catch((error) =>
			console.log(error)
		);
	};

	const cargarTodos = async ({ abortSignal, isUnMounted }) => {
		if (isUnMounted) {
			return;
		}
		setCargando({
			...cargando,
			libros: true,
		});
		await LibrosAPI.obtenerTodos({ abortSignal })
			.then((resultado) => {
				setLibros(
					adaptarPortadaLibros(resultado && Array.isArray(resultado) ? resultado : [])
				);
			})
			.catch((error) => {
				console.log(error);
			});
		setFirstLoadCompleted(true);
		setCargando({
			...cargando,
			libros: false,
		});
	};

	const manejarFiltros = async (estadoModoFiltro) => {
		window.scrollTo({ top: 0 });
		if (filterAbortController) {
			filterAbortController.abort();
		}

		const materias =
			Array.isArray(estadoModoFiltro?.filtros?.materias) &&
			estadoModoFiltro?.filtros?.materias?.length > 0
				? estadoModoFiltro?.filtros?.materias
						.map((materia) => materia?.propiedadAPI)
						.join(";")
				: null;
		const generos =
			Array.isArray(estadoModoFiltro?.filtros?.generos) &&
			estadoModoFiltro?.filtros?.generos?.length > 0
				? estadoModoFiltro?.filtros?.generos.map((genero) => genero?.propiedadAPI).join(";")
				: null;
		const sch = Boolean(estadoModoFiltro?.filtros?.sch) ? estadoModoFiltro?.filtros?.sch : null;
		const favoritos = Boolean(estadoModoFiltro?.filtros?.favorito) ? 1 : null;
		setModoFiltro({ activado: estadoModoFiltro?.activado });
		const _filtros = {
			materias: materias,
			genero: generos,
			sch: sch,
			favorito: favoritos,
		};

		if (JSON.stringify(_filtros) === JSON.stringify(filtros)) {
			return;
		}
		setFiltros(_filtros);

		const abortController = new CancelRequestController();
		setFilterAbortController(abortController);
		setCargando({
			...cargando,
			libros: true,
		});

		await LibrosAPI.multiplesFiltros(_filtros, abortController.signal)
			.then((libros) => {
				const adaptados = adaptarPortadaLibros(libros);
				setLibros(adaptados);
			})
			.catch((err) => {
				console.log(err);
			});
		setCargando({
			...cargando,
			libros: false,
		});
	};

	useEffect(() => {
		let abortController = new CancelRequestController();
		let isUnMounted = false;
		window.scrollTo({ top: 0 });
		cargarTodos({ abortSignal: abortController.signal, isUnMounted });
	
		return () => {
			isUnMounted = true;
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		return () => {
			if (filterAbortController) {
				filterAbortController.abort();
			}
		};
	}, [filterAbortController]);

	useEffect(() => {
		return () => {
			setFirstLoadCompleted(false);
		};
	}, []);

	return (
		<div className="relativ">
			{firstLoadCompleted === true ? (
				<>
					<div className="primary-var2--bg borde-explorar contenido">
						<h1 className="titulos-principales2 onprimary-var1--color mx-2 ">
							{traducciones?.libros?.titulo || "Explorar libros"}
						</h1>
					</div>
					<section
						className="lg:sticky block  left-0 w-full  z-20 rounded-br-2xl"
						style={{ top: 63 }}
					>
						<Filters
							alLimpiarFiltros={() => setModoFiltro({ activado: false })}
							alCambiarModoFiltro={(filtros) => manejarFiltros(filtros)}
							loadingData={cargando.libros}
						/>
					</section>
				</>
			) : (
				<SkeletonTheme color={"#e8e8e8"} highlightColor={"#f7f7f7"}>
					<Skeleton
						className="w-100 h-100"
						height={260}
						style={{ borderBottomRightRadius: "1rem" }}
					/>
				</SkeletonTheme>
			)}

			<section className="contenido">
				{modoFiltro.activado === false ? (
					<>
						<BooksList
							cargando={cargando.libros}
							clasesPersonalizadas={gridSecciones}
							libros={libros}
							nombre={`
                ${traducciones?.libros?.seccion_libros?.total_1 || "Hay un total de"} ${
								libros.length
							} 
                ${traducciones?.libros?.seccion_libros?.total_2 || "libros"}`}
							alSeleccionarLibro={(libroSeleccionado) =>
								mostrarLibro(libroSeleccionado)
							}
							alAgregarLibroAFav={(libro) => agregarRemoverAFavoritos(libro, true)}
							alRemoverLibroDeFav={(libro) => agregarRemoverAFavoritos(libro, false)}
						/>
					</>
				) : (
					<BooksList
						cargando={cargando.libros}
						clasesPersonalizadas={gridSecciones}
						libros={libros}
						nombre={`
            ${libros.length} ${
							traducciones?.libros?.buscador?.resultados?.informacion ||
							"Libros encontrados"
						} `}
						alSeleccionarLibro={(libroSeleccionado) => mostrarLibro(libroSeleccionado)}
						alAgregarLibroAFav={(libro) => agregarRemoverAFavoritos(libro, true)}
						alRemoverLibroDeFav={(libro) => agregarRemoverAFavoritos(libro, false)}
						seccionVaciaElementoPersonalizado={
							<NoHayResultados
								titulo={
									traducciones?.libros?.buscador?.sin_resultados?.titulo ||
									"Sin resultados"
								}
								descripcion={
									traducciones?.libros?.buscador?.sin_resultados?.descripcion ||
									"No se encontraron resultados para tu busqueda."
								}
							/>
						}
					/>
				)}
			</section>
		</div>
	);
};

BooksHome.propTypes = {
	/**
	 *  función que se encarga de buscar los libros que quiere ver el estudiante.
	 * @type{Function({ value: string, genero,: string materia: string }) :void}
	 */
	buscarLibros: PropTypes.func,
	/**
	 * array que almacena los libros según la búsqueda realizada.
	 */
	librosBusqueda: PropTypes.array,
	/**
	 * objeto que almacena las categorías de los libros que van aparecer en el home del estudiante.
	 */
	librosEstudiantes: PropTypes.shape({
		escogidosPorElProfesor: PropTypes.array,
		favoritos: PropTypes.array,
		leidosRecientemente: PropTypes.array,
		todosLosLibros: PropTypes.array,
	}),
	/**
	 *  booleano que indica si el API buscar libros termino de responder.
	 */
	preloader: PropTypes.bool,
	/**
	 * objeto que contiene dos propiedades que va a devolver cuando las API termine de responder.
	 */
	respuestaApi: PropTypes.shape({
		isError: PropTypes.bool,
		mensaje: PropTypes.string,
	}),
	/**
	 *  objeto que trae los textos de la interfaz dependiendo del idioma activo.
	 */
	textosInterfaz: PropTypes.object,
};
