
/**
 * Devuelve un arreglo de objetos que definen un id y un nombre, normalmente se usa para obtener el id y nombre de sedes.
 * @returns {{id:string, nombre:string}[]} -  Retorna un arreglo de objetos que definen un id y un nombre.
 */
const listarArray = ({array}) => {
    let lista = [];

    array.map((elemento) => {
      lista.push({
        id: elemento.id,
        nombre: elemento.nombre
      });
      return elemento;
    });
  
  return lista
}

export default listarArray;