import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

/**
 * Este componente hace referencia a la caja de "Agregar +", ubicada en las pantallas principales de Institutos/Colegios, cursos y clubs. Este recibe una serie de props que permiten retornar dicho elemento. 
 * @returns CajaCrear
 */
const CajaCrear = (props) => {
    const { link, textoCaja, altura } = props;
    const [altoCaja, setAltoCaja] = useState();

    const cajaCrear = useRef(null);

    useEffect(() => {
        if (!!cajaCrear.current) {
            actualizarAlto();
            window.addEventListener('resize', actualizarAlto);
            return () => {
                window.removeEventListener('resize', actualizarAlto);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [altoCaja, cajaCrear]);

    const actualizarAlto = () => {
        const nuevaAltura = !!altura
            ? altura
            : window.innerWidth < 1200
                ? cajaCrear.current.scrollWidth
                : 380;

        setAltoCaja(nuevaAltura);
    }
    return (
        <Link
            className="caja-agregar m-3 focusable-red"
            ref={cajaCrear}
            style={{ height: altoCaja }}
            to={link}
        >
            <div className="z-10">
                <span className="icon-mas text-38 block text-center  onbackground-var2--color"></span>
                <p className="r7-16-negro26 mt-3">{textoCaja}</p>
            </div>
        </Link>
    )
}
export default CajaCrear;

CajaCrear.propTypes = {
    /**
     * string que hace referencia a la pantalla a la cual debe dirigirse la caja al hacer clic.
     */
    link: PropTypes.string.isRequired,
    /**
     * string que hace referencia al texto de la caja.
     */
    textoCaja: PropTypes.string.isRequired,
    /**
     * número que corresponde a la altura de la caja. 
     */
    altura: PropTypes.number
}