//componentes
import VerificarProfesorIndividual from "./VerificarProfesor/Individual";
import VerificarProfesorMasivo from "./VerificarProfesor/Masivo";
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de props que le permiten hacer la logica para renderizar la pagina correspondiente a  verificar datos de Profesores.  Además, usa los componentes VerificarProfesorIndividual y VerificarProfesorMasivo que hacen referencia a los modos de verificar.
 * @returns ProfesoresVerificarComponent
 */
const ProfesoresVerificarComponent = (props) => {
  const { actualizarSedeAsignada, cambiarCurso, cambiarProfesoresElegidos, crearProfesores, cursosSede, cursoSeleccionada, datosProfesoresNuevos, descartarCambios, cancelarAccionMultiSelect, eliminarProfesorIndividual, eliminarProfesores, modoVista, moverProfesores, numeroProfesoresSeleccionados, preloader, primerMultiselect, respuestaApiCrear, sedeSeleccionada, sedesInstituto, textosInterfaz, sedesProfesores } = props;


  return (
    <>
      {modoVista === 'individual' ?
        <VerificarProfesorIndividual
          crearProfesores={crearProfesores}
          datosProfesoresNuevos={datosProfesoresNuevos}
          descartarCambios={descartarCambios}
          preloader={preloader}
          respuestaApiCrear={respuestaApiCrear}
          textosInterfaz={textosInterfaz}
        />
        :
        <VerificarProfesorMasivo
          actualizarSedeAsignada={actualizarSedeAsignada}
          cambiarCurso={cambiarCurso}
          cancelarAccionMultiSelect={cancelarAccionMultiSelect}
          crearProfesores={crearProfesores}
          cursosSede={cursosSede}
          cursoSeleccionada={cursoSeleccionada}
          datosProfesoresNuevos={datosProfesoresNuevos}
          descartarCambios={descartarCambios}
          cambiarProfesoresElegidos={cambiarProfesoresElegidos}
          eliminarProfesorIndividual={eliminarProfesorIndividual}
          eliminarProfesores={eliminarProfesores}
          preloader={preloader}
          respuestaApiCrear={respuestaApiCrear}
          mostrarMultiSelect={primerMultiselect}
          moverProfesores={moverProfesores}
          numeroProfesoresSeleccionados={numeroProfesoresSeleccionados}
          sedeSeleccionada={sedeSeleccionada}
          sedesInstituto={sedesInstituto}
          sedesProfesores={sedesProfesores}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}
export default ProfesoresVerificarComponent;


ProfesoresVerificarComponent.propTypes = {
  /**
   * función que se encarga de actualizar el id de la sede en la que se desea crear el profesor(es). 
   * @type{Function({ idSede:string }):void}- callback
  */
  actualizarSedeAsignada: PropTypes.func.isRequired,

  /**
   * funcion que cambia el curso por el que se desea filtrar
   * @type{Function({ idSede: string, idCurso: string }):void} -callback
   */
  cambiarCurso: PropTypes.func.isRequired,

  /**
   * función que se encarga de almacenar los profesores seleccionados con el check.
   * @type{Function ({ e: event, idSede: string, profesor: shape }):void } -callback
   */
  cambiarProfesoresElegidos: PropTypes.func.isRequired,

  /**
   * función que se encarga de crear los profesores creados temporalmente ya sea de forma masiva o individual. Para esto esta función se encarga de llamar el api de crear profesores.
   */
  crearProfesores: PropTypes.func.isRequired,

  /**
  * Objeto que contiene la información del curso
  */
  cursosSede: PropTypes.shape({
    hayCursos: PropTypes.bool,
    cursos: PropTypes.array
  }).isRequired,

  /**
   * Objeto que contiene la información del curso seleccionado
   */
  cursoSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
   * Objeto que contiene la información de los profesores que se desean crear
   */
  datosProfesoresNuevos: PropTypes.array.isRequired, //Esto puede ser un objeto

  /**
   * función que se encarga de borrar los datos temporales que se guarden en el store.
   */
  descartarCambios: PropTypes.func.isRequired,

  /**
   * función que se encarga de cancelar la acción del multi action. 
   */
  cancelarAccionMultiSelect: PropTypes.func.isRequired,

  /**
   *  función que se encarga de eliminar un profesor de forma individual.
   * @type{Function({ key: string }):void}-callback
   */
  eliminarProfesorIndividual: PropTypes.func.isRequired,

  /**
   * función que se encarga de eliminar varios profesores al tiempo. 
   *  @type{Function({ keys: string }):void} -callback
   */
  eliminarProfesores: PropTypes.func.isRequired,

  /**
   * string que indica cual es el modo en el que se están creando los profesores. Este string puede contar con un valor de "individual" o "masivo".
   */
  modoVista: PropTypes.string.isRequired,

  /**
   * función que se encarga de cambiar la sede en la que se desean crear los profesores que se encuentran almacenados en el prop datosProfesoresNuevos. 
   */
  moverProfesores: PropTypes.func.isRequired,

  /**
   * número almacena el número de profesores seleccionados con el multiaction.
   */
  numeroProfesoresSeleccionados: PropTypes.number.isRequired,

  /**
   *  Es un booleano que contiene el preloader para el llamado del API de crear profesores.
   */
  preloader: PropTypes.bool.isRequired,

  /**
   *  booleano que se encarga de controlar si el multi action debe aparecer o no en pantalla.
   */
  primerMultiselect: PropTypes.bool.isRequired,

  /**
   * objeto que contiene dos propiedades que va a devolver cuando el API crear profesores termine de responder.
   */
  respuestaApiCrear: PropTypes.shape({
    isError: PropTypes.string,
    mensaje: PropTypes.string
  }).isRequired,

  /**
   *  objeto que cuenta con los datos de la sede seleccionada para mover al o los profesores de sede en la que se desea crear. 
   */
  sedeSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
   *  array de objetos que contiene el listado de sedes que el instituto tiene.
   */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
     *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
     */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * objeto donde se almacenan los datos en los que se desean crear los profesores
   */
  sedesProfesores: PropTypes.shape({
    numeroSedes: PropTypes.number,
    sede: PropTypes.string
  }).isRequired
}