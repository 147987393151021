import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';

/**componentes */
import Modal from "../../globales/modales/Modal";
import ModalConfirmacion from "../../globales/modales/ModalConfirmacion";
import DivisionCrud from "../../globales/DivisionCrud";
import { Miga, MigasDePan } from "../../globales/MigaDePan";

const VerificarEstudianteIndividual = (props) => {
  const { textosInterfaz, descartarCambios, datosEstudiantesNuevos, crearEstudiantes, preloader, respuestaApiCrear } = props;
  const { botones, miga_de_pan, descartar_cambios, verificar_datos, estudiante_creado } = textosInterfaz;

  const { codigo, correo, curso, nombre, sede, titulo } = verificar_datos;

  const { isError, mensaje } = respuestaApiCrear;
  const history = useHistory();

  const [modalDescartar, setModalDescartar] = useState(false);

  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar]);

  /**Una vez que cambia el valor isError, al dar click en crear estudiantes, la ventana modal de creado aparece   */
  useEffect(() => {
    if(isError !== null){
      if (!isError) {
        MicroModal.show('estudiante-creado', {
          awaitCloseAnimation: true
        });
      }else{
        notificacion(textosInterfaz.notificacion.error, mensaje, "danger")
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Estudiantes'}
          alSeleccionar={() => {
            if (modalDescartar) {
              MicroModal.show('cancelar-crear', {
                awaitCloseAnimation: true
              });
            } else {
              setModalDescartar(true)
            }
          }}
        />
        <Miga
          texto={miga_de_pan?.crear || 'Crear estudiantes'}
          alSeleccionar={() => {
            history.push('/estudiantes/crear-estudiantes')
          }}
        />
        <Miga
          texto={miga_de_pan?.verificar || 'Verificar estudiantes'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{titulo}</h1>
        {/* codigo */}
        <DivisionCrud>
          <h3 className="raleway-700 onbackground-var1--color">{codigo.titulo} </h3>
          <>
            <div className=" alineado-verticalmente input-lectura">
              {!!datosEstudiantesNuevos.codigo
                ?
                <>
                  <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                  <p className="raleway-700 primary-inv-var2--color text-13">{datosEstudiantesNuevos.codigo} </p>
                </>
                :
                <>
                  <p className={`r7-13-rojo break-words alineado-verticalmente`}>
                    <span className="icon-alerta text-18  mr-3"></span> Sin codigo
                  </p>
                </>
              }
            </div>
          </>
        </DivisionCrud>

        {/* Nombre */}
        <DivisionCrud>
          <h3 className="raleway-700 onbackground-var1--color">{nombre.titulo} </h3>
          <>
            <div className=" alineado-verticalmente input-lectura">
              {!!datosEstudiantesNuevos.nombre
                ?
                <>
                  <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                  <p className="raleway-700 primary-inv-var2--color text-13">{datosEstudiantesNuevos.nombre} </p>
                </>
                :
                <>
                  <p className={`r7-13-rojo break-words alineado-verticalmente`}>
                    <span className="icon-alerta text-18  mr-3"></span> Sin nombre
                  </p>
                </>
              }
            </div>
          </>
        </DivisionCrud>

        {/* correo */}
        <DivisionCrud>
          <h3 className="raleway-700 onbackground-var1--color">{correo.titulo} </h3>
          <>
            <div className=" alineado-verticalmente input-lectura">
              {!!datosEstudiantesNuevos.correo
                ?
                <>
                  <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                  <p className="raleway-700 primary-inv-var2--color text-13">{datosEstudiantesNuevos.correo} </p>
                </>
                :
                <>
                  <p className={`r7-13-rojo break-words alineado-verticalmente`}>
                    <span className="icon-alerta text-18  mr-3"></span> {correo.sin_corrreo}
                  </p>
                </>
              }
            </div>
          </>
        </DivisionCrud>

        {/* sede */}
        <DivisionCrud>
          <h3 className="raleway-700 onbackground-var1--color">{sede.titulo} </h3>
          <div className=" alineado-verticalmente input-lectura">
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">{datosEstudiantesNuevos.nombreSede} </p>
          </div>
        </DivisionCrud>

        {/* curso*/}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{curso.titulo} </h3>
            <p className="r4-14-gris6a">{curso.descripcion}</p>
          </>
          <div className=" alineado-verticalmente input-lectura">
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">{datosEstudiantesNuevos.nombreCurso} </p>
          </div>
        </DivisionCrud>

        {/* botones */}
        <div className="alineado-izquierda-center my-5">
          <Link
            to="/estudiantes/crear-estudiantes"
            className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center mr-5 focusable-primary"
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </Link>
          <button
            onClick={crearEstudiantes}
            className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center focusable-primary"
          >
            {
              preloader ?
                <div className="center">
                  <span className="icon-spinner7 animate-spin text-20"></span>
                </div>
                : botones.crear.individual
            }
          </button>
        </div>
      </div>

      {/* modal descartar */}
      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal estudiante -creado */}
      {!isError &&
        <ModalConfirmacion nombreModal="estudiante-creado">
          <Link className="icon-cerrar text-20 right-6 absolute focusable-primary onbackground-var1--color" to="/estudiantes"></Link>
          <img
            alt="eyex feliz"
            className="w-5/12 mx-auto bg-img"
            src='/images/eyex-creado.png'
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{estudiante_creado.titulo}</h1>
          <p className="text-center r7-16-negro26 mb-4">
          {estudiante_creado.individual.descripcion1} <span className="text-resaltado">{datosEstudiantesNuevos.nombre} </span>, <span className="text-resaltado">{datosEstudiantesNuevos.nombreSede} </span> {estudiante_creado.individual.descripcion2}
          </p>
          <div className="center my-3">
            <Link
              to="/estudiantes"
              className="boton-justo boton-amarillo boton_hover_morado py-2 focusable-primary"
            >
              {botones.volver.principal}
            </Link>
          </div>
        </ModalConfirmacion>
      }
    </>
  )
}
export default VerificarEstudianteIndividual;