import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
/*Componente */
import DivisionCrud from "../../globales/DivisionCrud";

const SkeletonClubCrear = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={270} height={40} />

      <div className="contenido" >
        <h1 className="titulos-principales  ">
          <div className="md:w-44 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <DivisionCrud>
          <>
            <Skeleton width={150} height={24} />
          </>
          <div className="w-full xl:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={100} height={24} />
            <Skeleton className="mt-3" width={'100%'} height={60} />
          </>
          <div className="w-full xl:w-451p">
            <Skeleton width={'100%'} height={150} />
          </div>
        </DivisionCrud>


        <DivisionCrud>
          <>
            <Skeleton width={100} height={24} />
            <Skeleton className="mt-3" width={'100%'} height={60} />
          </>
          <div className="w-full xl:w-451p">
            <Skeleton width={'100%'} height={150} />
          </div>

        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={100} height={24} />
            <Skeleton className="mt-3" width={'100%'} height={60} />
          </>
          <div>
            <div className="w-full xl:w-451p">
              <div className="alineado-izquierda-center">
                <div className="w-40">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <Skeleton width={'100%'} height={36} />

            </div>
            <div className="w-32 h-44 mt-5">
              <Skeleton width={'100%'} height={'100%'} />
            </div>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={'90%'} height={24} />
            <Skeleton className="mt-3" width={'100%'} height={60} />
          </>
          <div>
            <Skeleton className="mx-auto lg:mx-0" width={128} height={128} style={{ borderRadius: '100%', display: 'block' }} />
            <Skeleton className="mx-auto lg:mx-0 mt-3" width={130} height={24} style={{ display: 'block' }} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={'90%'} height={24} />
            <Skeleton className="mt-3" width={'100%'} height={60} />
          </>
          <>
            <div className="flex gap-4">
              <div className="w-6/12">
                <Skeleton width={'100%'} height={36} />
              </div>

              <div className="w-6/12">
                <Skeleton width={'100%'} height={36} />
              </div>
            </div>

            <div className="w-full mt-3">
              <Skeleton width={'100%'} height={36} />


              <div className="encabezadoTabla mt-10">
                <div className="col-span-2">
                  <div className="md:w-10/12">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                </div>
                <div className="xl:col-span-3">
                  <div className="md:w-10/12">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                </div>
                <div className=" xl:col-span-3">
                  <div className="md:w-10/12">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                </div>
                <div className=" xl:col-span-3">
                  <div className="md:w-10/12">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                </div>
                <div className="col-span-1"></div>
              </div>

              <div className="filaTabla mt-5">

                <div className="col-span-12 ">
                  <div className="w-6/12 mx-auto">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                </div>

              </div>

            </div>
          </>
        </DivisionCrud>


        <div className="flex justify-end my-5">
          <button className=" w-1/2 sm:w-40 mr-5">
            <Skeleton width={'100%'} height={36} />
          </button>
          <button className=" w-1/2 sm:w-40">
            <Skeleton width={'100%'} height={36} />
          </button>
        </div>
      </div>
    </SkeletonTheme >
  )
}
export default SkeletonClubCrear;