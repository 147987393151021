import axios from "axios";

export class DetalleProfesorAPI {

 

  // DEFINICIÓN DE LOS TIPOS
  /**
   * @typedef {{
  username: string,
  nombreCompleto: string,
  numeroDeMedallasGanadas: number,
  numeroDeLibrosConsultados: number,
  numeroDehorasLeidas: number,
  sesionesDeLectura: [{
    fecha: 'yyy-mm-dd',
    tiempoPromedioLectura: number, //mins
    tiempoPromedioEnPlataforma: number, //mins
    librosLeidos: number,
    numeroSesiones:number
  }],  
  librosLeidos: [{
    titulo: string,
    autor: string,
    editorial:string,
    fechaLectura:string,
    tiempoPromedioDeLectura: number//mins,
    tiempoTotalLectura:number
  }]
}
} DetalleProfesor
**/

/**
 * @type {DetalleProfesor}
 */
static detalleProfesorPorDefecto = {
    username:'',
    nombreCompleto:'N/A',
    numeroDeLibrosConsultados:0,
    numeroDeMedallasGanadas:0,
    numeroDehorasLeidas:0,
    librosLeidos:[],
    sesionesDeLectura:[]
}



  //---------------------------------------------------------------------------------
  // EMPIEZAN LOS SERVICIOS

  /**
* devuelve las estadisticas de un profesor con base a el correo y rango de fechas 
* @param {String} correo - el correo del profesor que se quiere buscar
* @param {String} fechaInicio - la fecha de inicio de busqueda de información
* @param {String} fechaFin - la fecha de fin  de busqueda de información
* @returns {Promise<DetalleProfesor>} DetalleProfesor
*/
  static async obtenerDetalles({correo, fechaInicio, fechaFin, abortSignal=null}) {
    const body = {
        username:correo || '',
        fechaInicio:fechaInicio || '', 
        fechaFin:fechaFin || ''
    }
    try {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      axios.defaults.withCredentials = true;
      const response = await axios({
        method: 'POST',
        url: 'estadisticas/estadisticasDetalleProfesor',
        data: body,
        signal:abortSignal || null
      })
      if(response.data.status != 1 ){
          return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
      }
      /**
       * @type{DetalleProfesor}
       */
      const detalles = response.data?.data || DetalleProfesorAPI.detalleProfesorPorDefecto;
      return detalles;
      
    } catch (error) {
      return Promise.reject(error);
    }

  }

}