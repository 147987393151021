import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const SkeletonPortadasLibros = () => {
    return (
        <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
            <div className="w-full mt-5 lg:mt-0">
                <div className="lg:w-96">
                    <Skeleton width={'100%'} height={36} />
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 mt-5">
                    <Skeleton width={'100%'} height={271} />
                    <Skeleton width={'100%'} height={271} />
                    <Skeleton width={'100%'} height={271} />
                    <Skeleton width={'100%'} height={271} />
                    <Skeleton width={'100%'} height={271} />
                    <Skeleton width={'100%'} height={271} />
                    <Skeleton width={'100%'} height={271} />
                    <Skeleton width={'100%'} height={271} />
                </div>
            </div>
        </SkeletonTheme>
    )
}
export default SkeletonPortadasLibros;