


/**
 * Obtiene los datos RAW de los estudiantes traidos desde el API, y los transforma en un arreglo de objetos de sedes, con sus cursos y estudiantes correspondientes.
 * @param {{datos:object, sedes:object}} { datos, sedes}
 * @returns {Object} - Retorna objeto con las sedes, sus cursos y estudiantes.
 */
const transformarDatosEstudiantes = async ({ datos, sedes }) => {
  let idSedesInstituto = [];
  !!sedes && sedes.length !== 0 && sedes.map(sede => idSedesInstituto.push(sede.id));


  let infoEstudiantes = {}

  for (const key in datos) {
    const sede = datos[key];
    infoEstudiantes[key] = {
      nombre: sede.nombre,
      cursos: {}
    }

    const cursosSedes = sede.cursos;
    let cursosSede = {}

    for (const keyCurso in cursosSedes) {
      const curso = cursosSedes[keyCurso];

      let estudiantesCurso = [];

      for (const key in curso.estudiantes) {
        const estudiante = curso.estudiantes[key];

        estudiantesCurso.push({
          ...estudiante,
          nombreCurso: curso.nombre,
          nombreProfesor: curso.nombreProfesor.join(", "),
        })
      }

      cursosSede[keyCurso] = {
        nombre: curso.nombre,
        estudiantes: estudiantesCurso
      };
    }

    infoEstudiantes[key].cursos = cursosSede;

  }



  !!sedes && sedes.length !== 0 && sedes.map(sede => {
    if (infoEstudiantes[sede.id] === undefined) {
      infoEstudiantes[sede.id] = { nombre: sede.nombre, cursos: {} }
    }
    return sede;
  })


  return infoEstudiantes;
}

export default transformarDatosEstudiantes;