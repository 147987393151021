import { useEffect, useState } from "react";

import SelectorDeBusqueda from "../globales/elementosFormularios/SelectorDeBusqueda";
import PropTypes from 'prop-types';

/**
 * Componente que carga los paquetes de libros para una institución que está en modo de selección de libros, permite gestionar y actualizar el paquete seleccionado.
 */
const SeleccionPaquetes = (props) => {
  const { paquetesDisponibles, paqueteParaSeleccionar, setPaqueteActivo, select } = props;

  /**estados del componente */
  /* const [paquete, setPaquete] = useState(''); */

  /**Funciones */
  /**Actualiza el valor del paquete*/
  const cambiarOpcionPaquete = ({ opcion }) => {
    setPaqueteActivo(opcion?.nombre || '' ); 
  }

  useEffect(()=>{

  },[paqueteParaSeleccionar])

 
  
  return (
    <>
        <SelectorDeBusqueda
          cambiarElegido={cambiarOpcionPaquete}
          opciones={paquetesDisponibles}
          textoDefault={select.paquetes.default}
          textoElegido={paqueteParaSeleccionar.nombre}
          zIndex={61}
        />


      {/* <select value={paqueteParaSeleccionar.id} onChange={(e) => {
        setPaqueteActivo(e.target.value)
      }}>
        {paquetesDisponibles.map(item =>
          <option value={item.id} key={item.id}>{item.nombre}</option>
        )}
      </select> */}
    </>
  )
}

export default SeleccionPaquetes;

SeleccionPaquetes.propTypes = {
  /**
   * Lista de paquetes disponibles para seleccionar
   */
  paquetesDisponibles: PropTypes.arrayOf(PropTypes.shape({
    autorizado:PropTypes.bool,
    estadoSeleccion:PropTypes.bool,
    fechaLimiteParaEscogerLibros:PropTypes.string,
    libros:PropTypes.arrayOf(PropTypes.object),
    maxNumeroDeLibrosParaEscoger:PropTypes.number,
    nombre:PropTypes.string,
  })).isRequired,
  /**
   * Paquete seleccionado actualmente
   */
  paqueteParaSeleccionar: PropTypes.shape({
    autorizado:PropTypes.bool,
    estadoSeleccion:PropTypes.bool,
    fechaLimiteParaEscogerLibros:PropTypes.string,
    libros:PropTypes.arrayOf(PropTypes.object),
    maxNumeroDeLibrosParaEscoger:PropTypes.number,
    nombre:PropTypes.string,
  }).isRequired,
  /**
   * Función que actualiza el paquete seleccionado
   */
  setPaqueteActivo: PropTypes.func.isRequired,
  /**
   * Objeto con los textos de la interfaz del componente
   */
  select: PropTypes.object.isRequired
}