import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Header } from "../components/header";
import { BadgesSkeleton } from "../components/skeletons/badges-skeleton";
import { Badges } from "./components/badges";
import { useBooksReadStats } from "./hooks/use-books-read-stats";
import { useEffect, useState } from "react";
import { CancelRequestController } from "../../../utilities/cancel-request-controller";
import { BooksReadChart } from "./components/books-read-chart";
import { StatsTab } from "./components/stats-tab";
import { useBadges } from "./hooks/use-badges";

export const Stats = () => {
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");

	const statsCategories = [
		{
			id: "esta-semana",
			text: { default: "Esta semana", translation: textos?.logros?.estadisticas?.semana },
		},
		{
			id: "semana-pasada",
			text: {
				default: "Semana pasada",
				translation: textos?.logros?.estadisticas?.semana_pasada,
			},
		},
		{
			id: "este-mes",
			text: { default: "Este mes", translation: textos?.logros?.estadisticas?.mes },
		},
		{
			id: "todo-anio",
			text: { default: "Todo el año", translation: textos?.logros?.estadisticas?.anual },
		},
	];

	const badgesCategories = [
		{
			id: "materias",
			text: { default: "Materias", translation: textos?.logros?.medallas?.materias },
		},
		{
			id: "tiempo-de-lectura",
			text: {
				default: "Tiempo de lectura",
				translation: textos?.logros?.medallas?.tiempo_lectura,
			},
		},
		{
			id: "sesiones",
			text: {
				default: "Sesiones de lectura",
				translation: textos?.logros?.medallas?.sesiones,
			},
		},
		{
			id: "lecturas",
			text: { default: "Libros leídos", translation: textos?.logros?.medallas?.lecturas },
		},
	];

	const [abortController, setAbortController] = useState(new CancelRequestController());
	const [currentBookReadStatsTab, setCurrentBookReadStatsTab] = useState(statsCategories[0]);
	const [currentBadgeTab, setCurrentBadgeTab] = useState(badgesCategories[0]);
	const booksReadStats = useBooksReadStats({ abortController, setAbortController });
	const badges = useBadges({ badgeCategory: currentBadgeTab?.id });
	const finalBadges = badges?.badges;
	const [currentBooksReadStats, setCurrentBooksReadStats] = useState({
		rangoFechas: "",
		rangoX: [],
		rangoY: [],
	});
	const [loading, setLoading] = useState({
		booksReadStats: true,
		firstLoad: true,
	});

	const handleOnChangeBookReadStats = async (statsCategory) => {
		if (loading.booksReadStats || statsCategory?.id === currentBookReadStatsTab?.id) {
			return;
		}
		setLoading((value) => {
			return { ...value, booksReadStats: true };
		});
		setCurrentBookReadStatsTab(statsCategory);
		try {
			if (statsCategory?.id === "esta-semana") {
				const stats = await booksReadStats.getThisWeek({
					abortSignal: abortController?.signal,
				});
				setCurrentBooksReadStats(stats);
			} else if (statsCategory?.id === "semana-pasada") {
				const stats = await booksReadStats.getLastWeek({
					abortSignal: abortController?.signal,
				});
				setCurrentBooksReadStats(stats);
			} else if (statsCategory?.id === "este-mes") {
				const stats = await booksReadStats.getThisMonth({
					abortSignal: abortController?.signal,
				});
				setCurrentBooksReadStats(stats);
			} else if (statsCategory?.id === "todo-anio") {
				const stats = await booksReadStats.getThisYear({
					abortSignal: abortController?.signal,
				});
				setCurrentBooksReadStats(stats);
			}
			setLoading((value) => {
				return { ...value, booksReadStats: false };
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnChangeBadge = async (badge) => {
		setCurrentBadgeTab(badge);
		badges.changeCategory(badge?.id);
	};

	const loadFirstTime = async ({ isUnMounted }) => {
		if (isUnMounted) {
			return;
		}
		setLoading({
			booksReadStats: true,
			badges: true,
			firstLoad: true,
		});
		try {
			const stats = await booksReadStats.getThisWeek({
				abortSignal: abortController?.signal,
			});
			setCurrentBooksReadStats(stats);
			setLoading({
				booksReadStats: false,
				badges: false,
				firstLoad: false,
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (abortController?.signal?.aborted) {
			setAbortController(new CancelRequestController());
		}
	}, [abortController]);

	useEffect(() => {
		let isUnMounted = false;
		loadFirstTime({ isUnMounted });
		return () => {
			isUnMounted = true;
			abortController.abort();
		};
	}, []);

	return (
		<>
			{loading.firstLoad === false && badges?.loading === false ? (
				<>
					<Header />
					<div className="contenido">
						<div className=" p-5 background-var3--bg onprimary-var1--bordered shadow-4  rounded-md mt-10">
							<p className="q7-28 onbackground-var2--color">
								{textos?.logros?.estadisticas?.titulo}
							</p>
							<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-auto  mt-3">
								{statsCategories?.map((statsCategory, i) => {
									return (
										<StatsTab
											key={i}
											data={statsCategory}
											current={
												currentBookReadStatsTab?.id === statsCategory?.id
											}
											disabled={loading?.booksReadStats}
											onClick={() =>
												handleOnChangeBookReadStats(statsCategory)
											}
										/>
									);
								})}
								<BooksReadChart
									stats={currentBooksReadStats}
									loading={loading?.booksReadStats}
								/>
							</div>
						</div>
						<div className=" p-5 background-var3--bg onprimary-var1--bordered shadow-4  rounded-md mt-5">
							<p className="q7-28 onbackground-var2--color">
								{textos?.logros?.medallas?.titulo}
							</p>
							<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-auto  mt-3">
								{/* tabs */}
								{badgesCategories?.map((badge, i) => {
									return (
										<StatsTab
											key={i}
											data={badge}
											current={currentBadgeTab?.id === badge?.id}
											disabled={loading?.badges}
											onClick={() => handleOnChangeBadge(badge)}
										/>
									);
								})}
							</div>
							{/* badges */}
							<Badges badges={finalBadges} />
						</div>
					</div>
				</>
			) : (
				<BadgesSkeleton />
			)}
		</>
	);
};
