import { useState } from 'react';
import { useHistory } from 'react-router';
import MicroModal from "micromodal";
import PropTypes from 'prop-types';

//Componentes
import Modal from '../globales/modales/Modal';
import DivisionCrud from '../globales/DivisionCrud';
import InputSelectCheckbox from '../globales/elementosFormularios/InputSelectCheckbox';
import ModalCambiarIcono from '../globales/modales/ModalCambiarIcono';
import Selector from '../globales/elementosFormularios/Selector';
import EditarListaEstudiantes from './Listas/ListaEstudiantes';
import { FileSelector } from '../globales/elementosFormularios/FileSelector';
import { useTrackedState } from '../../store';
import { Miga, MigasDePan } from "../globales/MigaDePan";

/**
 * Este componente recibe una serie de props que son necesarios para renderizar la pantalla de crear club. Además, hace uso de los componentes Modal, DivisionCrud, InputSelectCheckbox, ModalCambiarIcono y Selector.
 * @returns CrearClubComponent
 */
const CrearClubComponent = (props) => {
  const { actualizarCurso, actualizarDescripcion, actualizarLibrosElegidos, actualizarEstudiantesElegidos, actualizarNombre, actualizarIcono, actualizarSede, archivoClub, borrarEstudiante, buscarEstudiantes, cursosSede, descartarCambios, descripcionClub, estudiantesElegidos, filtros, guardarDatosTemporalmente, hayDatosDescartar, iconoClub, librosElegidos, nombreClub, numeroLibrosElegidos, obtenerDatosInput, resultadoEstudiantes, resultadoLibros, sedesInstituto, textosInterfaz } = props;

  const { miga_de_pan, crear, descartar_cambios, botones, modal_icono } = textosInterfaz;

  const { archivo, descripcion, estudiantes, icono, libros, nombre } = crear;
  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';

  /**estados del componente */
  const [activadorExterno, setActivadorExterno] = useState(false);
  const [inputNombre, setInputNombre] = useState({ campo: nombreClub, valido: nombreClub !== '' });
  const [inputEstudiantes, setInputEstudiantes] = useState('');
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarIcono, setMostrarIcono] = useState(false);
  // eslint-disable-next-line no-unused-vars
  /*   const [nombreDocumento, setNombreDocumento] = useState(''); */
  const [textareaDescripcion, setTextareaDescripcion] = useState({ campo: descripcionClub, valido: descripcionClub !== '' });

  const history = useHistory();

  //Variables de entorno
  const numIconos = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_ICONOS_CURSO);

  /**funciones */
  const descartar = () => {
    if (!hayDatosDescartar) {
      history.push('/clubs');
    } else {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }

  /**Valida que el campo no este vacio y actualiza su estado a valido */
  const validarCampo = (estado, actualizarCampo) => {
    const valorCampo = estado.campo.length > 0 ? true : false;
    actualizarCampo({ ...estado, valido: valorCampo })
  }


  /**Actualiza el estado una vez se escriba en campo */
  const actualizarCampo = (e, estado, cambiarEstado) => cambiarEstado({ ...estado, campo: e.target.value });


  /**Envia los datos de los libros seleccionados */
  const actualizarLibros = ({ e, opcion }) => {
    actualizarLibrosElegidos({ e, libro: opcion });
  }


  /**Buscas los estudiantes por nombre */
  const searchEstudiantes = ({ e }) => {
    setInputEstudiantes(e.target.value);
    buscarEstudiantes({ value: e.target.value });
  }

  /**Actualiza los estudiantes elegidos */
  const actualizarEstudiantes = ({ e, opcion }) => {
    actualizarEstudiantesElegidos({ e, estudiante: opcion });
  }


  /** Actualiza el valor del estado activador externo que en este caso es el botón de agregar libros*/
  const activadorExternoLibros = (valor) => setActivadorExterno(valor);

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarSede = ({ opcion }) => {
    actualizarSede({ idSede: opcion.id, value: inputEstudiantes });
  };

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarCurso = ({ opcion }) => {
    actualizarCurso({ idSede: opcion.idSede, idCurso: opcion.id, value: inputEstudiantes });
  };

  /**Verifica que los campos esten bien diligenciados para pasar a la siguiente ventana */
  const verificarDatos = async () => {
    setMostrarAlerta(true);
    if (inputNombre.valido && textareaDescripcion.valido) {
      await guardarDatosTemporalmente();
      history.push('/clubs/crear-club/verificar-club');
    }
  }

  return (
    <>
      <MigasDePan>
        <Miga
          alSeleccionar={()=>{descartar()}}
          texto={miga_de_pan?.principal || 'Clubs'}>
        </Miga>
        <Miga
          texto={miga_de_pan.crear || 'Crear un club de lectura'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales mb-5">{crear.titulo}</h1>

        {/*Nombre  */}
        <DivisionCrud>
          <>
            <h2 className="raleway-700 onprimary-var2--color">{nombre.titulo} <span className="asterisco">*</span> </h2>
          </>
          <>
            <div className="w-full xl:w-451p">
              <input
                className={`w-full ${mostrarAlerta ? !inputNombre.valido && 'input_error' : null}`}
                onBlur={() => { validarCampo(inputNombre, setInputNombre) }}
                onChange={(e) => {
                  actualizarCampo(e, inputNombre, setInputNombre);
                  actualizarNombre({ nombre: e.target.value })
                }}
                onKeyUp={() => { validarCampo(inputNombre, setInputNombre) }}
                placeholder={nombre.placeholder}
                type="text"
                value={inputNombre.campo}
              />

              {mostrarAlerta &&
                <p className={`text-14 ${!inputNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {nombre.error}
                </p>
              }
            </div>
          </>
        </DivisionCrud>


        {/* Descripcion */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{descripcion.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{descripcion.descripcion} </p>
          </>
          <>
            <div className="w-full xl:w-451p">
              <textarea
                id="descripcion"
                className={`textarea focusable-primary ${mostrarAlerta ? !textareaDescripcion.valido && 'textarea-error' : null}`}
                onBlur={() => { validarCampo(textareaDescripcion, setTextareaDescripcion) }}
                onChange={(e) => {
                  actualizarCampo(e, textareaDescripcion, setTextareaDescripcion);
                  actualizarDescripcion({ descripcion: e.target.value })
                }}
                onKeyUp={() => { validarCampo(textareaDescripcion, setTextareaDescripcion) }}
                placeholder={descripcion.placeholder}
                value={textareaDescripcion.campo}
              ></textarea>

              {mostrarAlerta &&
                <p className={`text-14 ${!textareaDescripcion.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {descripcion.error}
                </p>
              }
            </div>
          </>
        </DivisionCrud>


        {/*archivo adjunto */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{archivo.titulo}</h3>
            <p className="r4-14-gris6a">{archivo.descripcion} </p>
          </>
          <>
            <FileSelector
              cantidadMaximaArchivos={1}
              extensionesPermitidas={'.pdf, application/pdf'}
              alCargarArchivo={(archivoCargado) => {
                if (archivoCargado)
                  obtenerDatosInput({ archivo: archivoCargado, nombre: archivoCargado.name });
                /* setNombreDocumento(archivoCargado.name); */
              }}
              alFallar={(error) => console.log(error)}
              informacion={!!archivoClub ? archivoClub.name : archivo.descripcion}
              tituloBotonAgregar={archivo.titulo}
              mensajeError={archivo.no_valido}
            />
          </>
        </DivisionCrud>


        {/*libros asignados */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{libros.titulo}</h3>
            <p className="r4-14-gris6a">{libros.descripcion} </p>
          </>
          <>
            <div className="w-full xl:w-451p alineado-izquierda-center">
              <p className="r7-morado">({numeroLibrosElegidos}/5 {numeroLibrosElegidos !== 1 ? libros.seleccionados.plural : libros.seleccionados.singular})</p>
            </div>
            <div className="w-full xl:w-451p">
              <InputSelectCheckbox
                actualizarElegidos={actualizarLibros}
                activadorExterno={activadorExterno}
                activadorExternoLibros={activadorExternoLibros}
                datosAlmacenados={librosElegidos.ids}
                listaOpciones={resultadoLibros.resultados}
                noEstaEnLista={libros.input.no_resultados}
                noHaylistaOpciones={libros.input.no_hay}
                notificacionTitulo={libros.alerta.titulo}
                notificacionDescripcion={libros.alerta.descripcion}
                numeroLibros={numeroLibrosElegidos}
                placeholder={libros.input.placeholder}
                propiedadId='idLibro'
                propiedadNombre='title'
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-3 2xl:grid-cols-5  gap-4 mt-5 ">
              {
                librosElegidos.datos.length !== 0 && librosElegidos.datos.map((libro) => {
                  return (
                    <div key={libro.idLibro}>
                      <div className="w-32 h-44 mx-auto">
                        <img
                          alt={libro.title}
                          className="w-32 h-44"
                          src={`${process.env.REACT_APP_ASSETS_URL}${libro.portada}`}
                        />
                      </div>
                      <div className="w-10/12 xl:w-11/12 mx-auto">
                        <p className="r7-morado text-center mt-1" aria-hidden={true}>{libro.title}</p>
                      </div>
                    </div>
                  )
                })
              }
              {librosElegidos.datos.length !== 5 &&
                <>
                  <div
                    aria-hidden={true}
                    className="w-32 h-44 caja-agregar mx-auto focusable-primary"
                    onClick={() => { setActivadorExterno(true)}}
                  >
                    <div className="z-10">
                      <span className="icon-mas text-20 block text-center  onbackground-var2--color"></span>
                      <p className="r7-16-negro26 mt-3 text-center"> {libros.agregar}</p>
                    </div>
                  </div>
                </>
              }
            </div>
          </>
        </DivisionCrud>


        {/* icono */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{icono.titulo}</h3>
            <p className="r4-14-gris6a">{icono.descripcion} </p>
          </>
          <div className="lg:w-32 mt-4 lg:mt-0">
            <div
              className={`avatar mx-auto cursor-pointer onsurface-var2--border-color ${iconoClub === null ? 'icono_null ' : 'icono text-indent'} `}
              style={{ backgroundImage: `url(/images/badges/badge-${iconoClub === null ? 'sombrero' : iconoClub}.png)` }}
              onClick={() => {
                if (mostrarIcono) {
                  MicroModal.show('cambiar-icono', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarIcono(true)
                }
              }}
            >
              <p className="px-5 text-center r7-13-negro26">{iconoClub === null ? crear.icono.no_hay : ''}</p>
            </div>
            <button
              className="evento_rojo mx-auto block mt-3 focusable-primary"
              onClick={() => {
                if (mostrarIcono) {
                  MicroModal.show('cambiar-icono', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarIcono(true)
                }
              }}
            >
              {botones.cambiar}
            </button>
          </div>
        </DivisionCrud>


        {/* estudiantes */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{estudiantes.titulo}</h3>
            <p className="r4-14-gris6a">{estudiantes.descripcion} </p>
          </>
          <>
            <div className="sm:flex gap-4">
              {
                rolUsuario === 'rector' &&
                <div className="sm:w-6/12">
                  <Selector
                    cambiarElegido={cambiarSede}
                    desactivado={false}
                    estadoEnviar={mostrarAlerta}
                    opciones={sedesInstituto}
                    superposicion={true}
                    textoDefault={estudiantes.sede.default}
                    textoElegido={filtros.sede.nombre}
                    textoNoHayOpciones={estudiantes.sede.no_resultados}
                    textoError="error"
                    valido={!!filtros.sede.id ? true : false}
                  />
                </div>
              }
              <div className="sm:w-6/12 mt-5 sm:mt-0">
                <Selector
                  cambiarElegido={cambiarCurso}
                  desactivado={false}
                  opciones={cursosSede.resultados}
                  textoDefault={estudiantes.cursos.default}
                  textoElegido={filtros.curso.nombre}
                  estadoEnviar={mostrarAlerta}
                  textoError="error"
                  textoNoHayOpciones={estudiantes.cursos.no_hay}
                  valido={!!filtros.curso.id}
                  subposicion={true}
                />
              </div>
            </div>
            <div className="w-full mt-3">
              <InputSelectCheckbox
                inputChange={searchEstudiantes}
                actualizarElegidos={actualizarEstudiantes}
                datosAlmacenados={estudiantesElegidos.ids}
                listaOpciones={resultadoEstudiantes.resultados}
                noEstaEnLista={estudiantes.input.no_resultados}
                noHaylistaOpciones={estudiantes.input.no_hay}
                placeholder={estudiantes.input.placeholder}
              />
            </div>
            <div className="mt-10">
              <EditarListaEstudiantes
                botonEliminar={botones.eliminar}
                datosEstudiantes={estudiantesElegidos.datos}
                eliminarEstudianteIndividual={borrarEstudiante}
                textosTabla={estudiantes.tabla}
              />
            </div>
          </>
        </DivisionCrud>

        {/* Botones */}
        <div className="flex justify-end my-5">
          <button
            to="/clubs"
            className="boton-pequenio boton-rojo boton_hover_morado text-16 text-center mr-5 "
            onClick={() => { descartar() }}
          >
            {botones.cancelar}
          </button>
          <button
            className="boton-justo boton-amarillo boton_hover_naranja text-16 center"
            onClick={() => { verificarDatos() }}
          >
            {botones.verificar.verificar}
          </button>
        </div>
      </div>

      {/*Modal de descartar cambios */}
      {hayDatosDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img "
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }


      {/* modal de cambiar icono */}
      {mostrarIcono &&
        <ModalCambiarIcono
          boton={botones.cambiar}
          cambiarAvatar={actualizarIcono}
          datoIcono={iconoClub === null ? 1 : iconoClub}
          descripcion={modal_icono.descripcion}
          nombreModal="cambiar-icono"
          minIconos={numIconos.minimo}
          maxIconos={numIconos.maximo}
          titulo={modal_icono.titulo}
          rutaCarpeta='badges/badge-'
        />
      }
    </>
  )
}

export default CrearClubComponent;

CrearClubComponent.propTypes = {
  /**
   * Es una función que se encarga de cambiar el curso por el que se desean filtrar los etudiantes. 
   */
  actualizarCurso: PropTypes.func,
  /**
   * Es una función que se recibe como parámetro llamado descripcion. Esta función de encarga de actualizar la propiedad descripcion del club a crear.
   */
  actualizarDescripcion: PropTypes.func,
  /**
   * Es una función que se encarga de actualizar el array de los libros asignados al club. 
   *@type{Function({ e, libro: object }):void}
   */
  actualizarLibrosElegidos: PropTypes.func,
  /**
   * Es una función que se encarga de actualizar el array de los estudiantes asignados al club.
   * @type{Function({ e,  estudiante: object }):void}
   */
  actualizarEstudiantesElegidos: PropTypes.func,

  /**
   * Esta función de encarga de actualizar la propiedad nombre del club.
   * @type{Function({ nombre: string }):void}
   */
  actualizarNombre: PropTypes.func,
  /**
   * Es una función que se encarga de cambiar el icono del club que se desea crear
   *  @type{Function({ icono: number }):void}
   */
  actualizarIcono: PropTypes.func,
  /**
   * Es una función que se encarga de cambiar la sede por la que se desean filtrar los estudiantes. 
   * @type{Function({ idSede: string, value: string }):void}
   */
  actualizarSede: PropTypes.func,
  /**
   *  Es un objeto de objetos que contiene el archivo que se desea subir al club.
   */
  archivoClub: PropTypes.object,
  /**
   * Es una función que se encarga de remover los estudiantes que se desean asignar al Club.
   * @type{Function({ idEstudiante: string }):void}
   */
  borrarEstudiante: PropTypes.func,
  /**
   *  Es una función que se encarga de buscar los estudiantes que se desean asignar al club.
   * @type{Function({ cursoObligatorio: bool, idCurso:string, idSede: string, value: string }):void}
   */
  buscarEstudiantes: PropTypes.func,
  /**
   * Es un objeto que almacena que cuenta con dos propiedades
   */
  cursosSede: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.array
  }),
  /**
   *  Es una función que se encarga de borrar los datos temporales que se guarden en el store.
   */
  descartarCambios: PropTypes.func,
  /**
   * Es un string que corresponde a la descripcion del club.
   */
  descripcionClub: PropTypes.string,
  /**
   * Es un objeto que almacena las propiedades de los estudiantes
   */
  estudiantesElegidos: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }),
  /**
   * Es un objeto que controla los filtros aplicados dentro de la sección de estudiantes. 
   */
  filtros: PropTypes.shape({
    curso: PropTypes.shape({
      id: PropTypes.string, //puede ser null
      nombre: PropTypes.string
    }),
    sede: PropTypes.shape({
      id: PropTypes.string, //puede ser null
      nombre: PropTypes.string
    })
  }),
  /**
   *  Esta función guarda los datos del club de forma temporal.
   */
  guardarDatosTemporalmente: PropTypes.func,
  /**
   * Es un booleano que se encarga de indicar si hay datos para descartar. En caso que este cuente con un valor de true y el usuario quiera ir a otra pantalla diferente a las del rango de crear, se debe mostrar el pop up de descartar cambios.
   */
  hayDatosDescartar: PropTypes.bool,
  /**
   * Esta propiedad puede contar con un valor de null o puede ser un number. Estos valores corresponden al icono selecciondo para el club.
   */
  iconoClub: PropTypes.number,
  /**
   * Es un objeto que almacena las propiedades del libro elegido
   */
  librosElegidos: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }),
  /**
   *  Es un string que corresponde al nombre del club.
   */
  nombreClub: PropTypes.string,
  /**
   * Es un numero que corresponde al los libros asignados al club.
   */
  numeroLibrosElegidos: PropTypes.number,
  /**
   *  Es una función que se encarga de guardar el archivo que se desea subir al club, para que posteriormente sea guardado y pueda ser descargado por los estudiantes.
   *  @type{Function({ archivo: object, nombre: string }):void}
   */
  obtenerDatosInput: PropTypes.func,
  /**
   *  Es un objeto que almacena que cuenta con dos propiedades
   */
  resultadoEstudiantes: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.array
  }),
  /**
   * Es un objeto que almacena que cuenta con dos propiedades
   */
  resultadoLibros: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.array
  }),
  /**
   *  Es un array de objetos que contiene el listado de sedes que el instituto tiene.
   */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })),
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object
}