import { useState } from "react";
import Paginacion from "../../globales/Paginacion";
import PropTypes from "prop-types";

/**
 * Este componente recibe los props datosProfesores y textosTabla que son necesarios para renderizar la tabla de profesores dentro de la pantalla de verificar instituto/colegio. Además, hace uso del componente global Paginacion.
 * @returns ListaProfesores
 */
const ListaProfesores = (props) => {
  const { datosProfesores, textosTabla } = props;
  const { correo, nombre, curso, sin_curso, sin_datos, sin_correo } =
    textosTabla;
  const [datosDivididos, setDatosDivididos] = useState([]);

  const ActualizarDatosDivididos = (value) => setDatosDivididos(value);
  return (
    <section className={`${datosProfesores.length > 10 ? "" : "mb-5"}`}>
      <ul className="encabezadoTabla  xl:grid grid-cols-12">
        <li className="col-span-1"></li>
        <li
          tabIndex={0}
          className="focusable-primary col-span-4 alineado-verticalmente"
        >
          {nombre}
        </li>
        <li
          tabIndex={0}
          className="focusable-primary col-span-4 alineado-verticalmente"
        >
          {correo}
        </li>
        <li
          tabIndex={0}
          className="focusable-primary col-span-3 alineado-verticalmente"
        >
          {curso}
        </li>
      </ul>

      {datosDivididos.length === 0 ? (
        <section className="filaTabla xl:grid-cols-12">
          <section className=" center col-span-12">
            <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
            <p className="raleway-700 red-inv-var2--color text-13">
              {" "}
              {sin_datos}
            </p>
          </section>
        </section>
      ) : (
        datosDivididos.map((dato, index) => {
          return (
            <section
              key={`campo${index}`}
              className="filaTabla xl:grid-cols-12"
            >
              <section className="fila-2-sm1-lg12-xl1  row-span-3  md:row-span-1">
                <section
                  className="  w-10 h-10 xl:mx-auto text-indent rounded-full img-cover-center"
                  style={{
                    backgroundImage: `url(/images/avatares/avatar${dato.avatar}.png) `,
                  }}
                >
                  <p>{dato.avatar}</p>
                </section>
              </section>
              <section className="fila-10-sm5-md3-lg6 xl:col-span-4">
                <span className="q7-14-negro26  xl:hidden">{nombre}</span>
                <p
                  tabIndex={0}
                  className="focusable-primary r5-13-negro26 break-words"
                >
                  {dato.nombre}
                </p>
              </section>
              <section className="fila-10-sm5-md4-lg6  xl:col-span-4 overflow-auto ">
                <span className="q7-14-negro26  xl:hidden">{correo}</span>
                <p
                  tabIndex={0}
                  className={`${
                    !!dato.correo
                      ? "focusable-primary r5-13-negro26"
                      : "r7-13-rojo"
                  } break-words`}
                >
                  {!!dato.correo ? dato.correo : sin_correo}
                </p>
              </section>
              <section className="fila-10-sm5-md3-lg6 xl:col-span-3 overflow-auto ">
                <span className="q7-14-negro26  xl:hidden">{curso}</span>
                <p
                  tabIndex={0}
                  className={`focusable-primary raleway-700 text-13 ${
                    !!dato.cursos ? "primary-inv-var2--color" : "red-inv-var2--color"
                  }`}
                >
                  {!!dato.cursos ? dato.cursos : sin_curso}
                </p>
              </section>
            </section>
          );
        })
      )}
      <section className={`alineado-izquierda xl:mt-3`}>
        <Paginacion
          datos={datosProfesores}
          datosDivididos={ActualizarDatosDivididos}
        />
      </section>
    </section>
  );
};
export default ListaProfesores;

ListaProfesores.propTypes = {
  /**
   * Es un array de objetos que contiene la información de los profesores extraídos del excel.
   */
  datosProfesores: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.number,
      correo: PropTypes.string,
      curso: PropTypes.array,
      nombres_apellidos: PropTypes.string,
    })
  ),

  /**
   * Es un objeto que trae los textos del encabezado de la tabla. Este cambia según el idioma activo.
   */
  textosTabla: PropTypes.object.isRequired,
};
