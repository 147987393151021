import SkeletonCursosVerificarMasivo from "./SkeletonCursosVerificarMasivo";
import SkeletonCursosVerificarIndividual from './SkeletonCursosVerificarIndividual';

const SkeletonCursosVerificar = (props) => {
  const { modoCreacion } = props;
  return (
    <>
      {
        modoCreacion === 'individual'
          ? <SkeletonCursosVerificarIndividual />
          : <SkeletonCursosVerificarMasivo />
      }
    </>
  )
}

export default SkeletonCursosVerificar;