import { useState, useRef, useEffect } from "react";
import { store } from 'react-notifications-component';
import { Link, useHistory } from "react-router-dom";
import MicroModal from "micromodal";
import { MigasDePan, Miga } from "../globales/MigaDePan";

/**componentes */
import DivisionCrud from "../globales/DivisionCrud";
import Selector from "../globales/elementosFormularios/Selector";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";

const EstudiantesEditarNuevoComponent = (props) => {
  const { actualizarCodigo, actualizarCorreo, actualizarCurso, actualizarNombre, actualizarSede, cursosSede, cursoSeleccionado, datosEstudianteNuevo, descartarCambios, editarEstudiante, eliminarEstudiante, hayCambios, nombreActual, sedeSeleccionada, sedesInstituto, textosInterfaz } = props;

  const { editar, botones, miga_de_pan, descartar_cambios, eliminar_estudiante, estudiante_eliminado } = textosInterfaz;

  const { codigo, correo, curso, cursos, nombre, sede, titulo } = editar;

  /**estados */
  const [modalDescartar, setModalDescartar] = useState(false);
  const [inputCodigo, setInputCodigo] = useState({ campo: datosEstudianteNuevo.codigo, valido: true, desactivado: false });
  const [inputNombre, setInputNombre] = useState({ campo: datosEstudianteNuevo.nombre, valido: true, desactivado: false });
  const [inputCorreo, setInputCorreo] = useState({ campo: datosEstudianteNuevo.correo, valido: true, desactivado: false });
  const [inputSede, setInputSede] = useState({ campo: datosEstudianteNuevo.nombreSede, desactivado: true });
  const [inputCurso, setInputCurso] = useState({ campo: datosEstudianteNuevo.nombreCurso, desactivado: true });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  /*expresion */
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  /**REF */
  const refInputCodigo = useRef(null);
  const refInputNombre = useRef(null);
  const refInputCorreo = useRef(null);
  const history = useHistory();

  /**UseEffect */
  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar]);

  /**Abre la ventana modal de estas seguro eliminar? cuando cambia el estado */
  useEffect(() => {
    if (modalEliminar) {
      MicroModal.show('estas-seguro-estudiante', {
        awaitCloseAnimation: true
      });
    }
  }, [modalEliminar])

  /**Sirve para desactivar el campo de curso en caso que se actualice el campo de sede */
  useEffect(() => {
    if (inputSede.desactivado === false) {
      setInputCurso({ ...inputCurso, desactivado: false });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSede.desactivado])

  useEffect(()=>{
    if(refInputCodigo && inputCodigo.desactivado === true){
      refInputCodigo.current.focus()
    }
  },[inputCodigo.desactivado])

  useEffect(()=>{
    if(refInputNombre && inputNombre.desactivado === true){
      refInputNombre.current.focus()
    }
  },[inputNombre.desactivado])

  useEffect(()=>{
    if(refInputCorreo && inputCorreo.desactivado === true){
      refInputCorreo.current.focus()
    }
  },[inputCorreo.desactivado])

  /*Funciones */
  /*Valida si el campo es valido o no. Esto se califica según el número de caracteres */
  const validarCampo = (estado, actualizarEstado) => {
    const valorCampo = estado.campo.length > 0 ? true : false;
    actualizarEstado({ ...estado, valido: valorCampo })
  }

  /*esta función actualiza el valor del input y lo agrega en el estado */
  const actualizarCampo = (e, estado, cambiarEstado) => cambiarEstado({ ...estado, campo: e.target.value });


  /**Esta función comprueba que el valor del campo correo cumple con la expresión y cambia su estado */
  const validarCorreo = (expresion, estado, cambiarEstado) => {
    const valor = estado.campo !== '' ? expresion.test(estado.campo) : false;
    cambiarEstado({ ...estado, valido: valor });
  }

  /*Cambia la sede en el select */
  const cambiarSede = ({ opcion }) => {
    setInputSede({ ...inputSede, campo: opcion.nombre, desactivado: false });
    actualizarSede({ idSede: opcion.id });
  }

  const cambiarCurso = ({ opcion }) => {
    setInputCurso({ ...inputCurso, campo: opcion.nombre, desactivado: false });
    actualizarCurso({ idSede: opcion.idSede, idCurso: opcion.id });
  }

  /**Crea la notificacion */
  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  /*actualia los cambios de todos los campos */
  const actualizarCambios = () => {
    setMostrarAlerta(true);
    if (inputNombre.valido && !!cursoSeleccionado.id && !!sedeSeleccionada.id && inputCorreo.valido) {
      editarEstudiante();
      notificacion(textosInterfaz.notificacion.exito, textosInterfaz.notificacion.actualizacion, "success");
      setInputCodigo({ ...inputCodigo, desactivado: false });
      setInputNombre({ ...inputNombre, desactivado: false });
      setInputCorreo({ ...inputCorreo, desactivado: false });
      setInputSede({ ...inputSede, desactivado: true });
      setInputCurso({ ...inputCurso, desactivado: true });
    }
  }


  /**Elimina el estudiante */
  const eliminarEstudianteNuevo = () => {
    eliminarEstudiante();
    MicroModal.close('estas-seguro-estudiante', {
      awaitCloseAnimation: true
    });
    MicroModal.show('estudiante-eliminado', {
      awaitCloseAnimation: true
    });
  }

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Estudiantes'}
          alSeleccionar={() => {
            if (modalDescartar) {
              MicroModal.show('cancelar-crear', {
                awaitCloseAnimation: true
              });
            } else {
              setModalDescartar(true)
            }
          }} />
        <Miga
          texto={miga_de_pan?.crear || 'Crear estudiantes'}
          alSeleccionar={() => {
            history.push('/estudiantes/crear-estudiantes')
          }} />
          <Miga
          texto={miga_de_pan?.verificar || 'Verificar profesores'}
          alSeleccionar={() => {
            history.push('/estudiantes/crear-estudiantes/verificar-estudiantes')
          }} />
        <Miga
          texto={nombreActual}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales mb-5">{titulo}</h1>

        {/* Codigo */}
        <DivisionCrud>
          <div>
            <h3 className="raleway-700 onprimary-var2--color">{codigo.titulo}</h3>
            <p className="r4-14-gris6a">{codigo.descripcion}</p>
          </div>
          <div className="alineado-verticalmente">
            <div className="w-full xl:w-451p">
              <input
                className={`w-full ${!inputCodigo.desactivado && 'input_disabled'}`}
                onChange={(e) => {
                  actualizarCampo(e, inputCodigo, setInputCodigo);
                  actualizarCodigo({ codigo: e.target.value });
                }}
                /* onClick={() => { setInputCodigo({ ...inputCodigo, desactivado: true }) }} */
                disabled={!inputCodigo.desactivado}
                placeholder={codigo.placeholder}
                ref={refInputCodigo}
                type="text"
                value={inputCodigo.campo}
              />

            </div>
            <button
              className="focusable-primary evento_rojo ml-5"
              onClick={() => { setInputCodigo({ ...inputCodigo, desactivado: true }) }}
            >
              {botones.editar}
            </button>
          </div>

          <div className="w-full xl:w-451p">
            <p className={`text-14 ${!inputCodigo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
              {codigo.error}
            </p>
          </div>
        </DivisionCrud>

        {/* Nombre */}
        <DivisionCrud>
          <div>
            <h3 className="raleway-700 onprimary-var2--color">{nombre.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{nombre.descripcion}</p>
          </div>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputNombre.desactivado && 'input_disabled'} ${mostrarAlerta ? !inputNombre.valido && 'input_error' : null}`}
                  onBlur={() => { validarCampo(inputNombre, setInputNombre) }}
                  onChange={(e) => {
                    actualizarCampo(e, inputNombre, setInputNombre);
                    actualizarNombre({ nombre: e.target.value });
                  }}
                  /* onClick={() => { setInputNombre({ ...inputNombre, desactivado: true }) }} */
                  disabled={!inputNombre.desactivado}
                  onKeyUp={() => { validarCampo(inputNombre, setInputNombre) }}
                  placeholder={nombre.placeholder}
                  ref={refInputNombre}
                  type="text"
                  value={inputNombre.campo}
                />

              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setInputNombre({ ...inputNombre, desactivado: true })}}
              >
                {botones.editar}
              </button>
            </div>

            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p className={`text-14 ${!inputNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {nombre.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/* Correo */}
        <DivisionCrud>
          <> 
            <h3 className="raleway-700 onprimary-var2--color">{correo.titulo} <span className="asterisco">*</span></h3>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputCorreo.desactivado && 'input_disabled'} ${mostrarAlerta ? !inputCorreo.valido && 'input_error' : null}`}
                  onChange={(e) => {
                    actualizarCampo(e, inputCorreo, setInputCorreo);
                    actualizarCorreo({ correo: e.target.value })
                  }}
                  onBlur={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                 /*  onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true }) }} */
                  disabled={!inputCorreo.desactivado}
                  onKeyUp={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                  placeholder={correo.placeholder}
                  ref={refInputCorreo}
                  type="email"
                  value={inputCorreo.campo}
                />
              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true }) }}
              >
                {botones.editar}
              </button>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p className={`text-14 
                  ${!inputCorreo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {correo.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/* Sedes */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{sede.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{sede.descripcion} </p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarSede}
              desactivado={inputSede.desactivado}
              estiloTipoInput={true}
              opciones={sedesInstituto}
              textoDefault={sede.placeholder}
              textoElegido={sedeSeleccionada.nombre}
              superposicion={true}
            />
          </div>
        </DivisionCrud>

        {/* curso */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{curso.titulo} <span className="asterisco">*</span> </h3>
            <p className="r4-14-gris6a">{curso.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarCurso}
              desactivado={inputCurso.desactivado}
              estiloTipoInput={true}
              estadoEnviar={mostrarAlerta}
              opciones={cursosSede.resultados}
              textoDefault={curso.placeholder}
              textoElegido={!!cursoSeleccionado.nombre ? cursoSeleccionado.nombre : curso.placeholder}
              textoError={curso.error}
              textoNoHayOpciones={cursos.no_hay}
              valido={!cursoSeleccionado.id ? false : true}
            />
            {/* 
            cambiarElegido={cambiarCurso}
              desactivado={inputSede.desactivado}
              estiloTipoInput={true}
              estadoEnviar={mostrarAlerta}
              opciones={cursosSede.resultados}
              textoDefault={curso.placeholder}
              textoElegido={!!cursoSeleccionado.nombre ? cursoSeleccionado.nombre : curso.placeholder}
              textoError={curso.error}
              textoNoHayOpciones={cursos.no_hay}
              valido={ !cursoSeleccionado.id ? false : true} */}
          </div>
        </DivisionCrud>

        <div className="sm:alineado-izquierda-center my-5">
          <button
            className="focusable-primary boton-justo sm:center mr-5 "
            onClick={() => {
              if (modalEliminar) {
                MicroModal.show('estas-seguro-estudiante', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalEliminar(true)
              }
            }}
          >
            <span className="icon-basura red-inv-var2--color text-20 pr-3 hidden sm:block"></span>
            <p className=" evento_rojo">{botones.eliminar.singular}</p>
          </button>

          <Link
            className="w-full sm:w-40 boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 sm:mt-0 text-center mr-5 block focusable-primary"
            to='/estudiantes/crear-estudiantes/verificar-estudiantes'
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </Link>

          <button
            disabled={hayCambios ? false : true}
            onClick={actualizarCambios}
            className="w-full sm:w-40 boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 sm:mt-0 text-center focusable-primary"
          >
            {botones.actualizar}
          </button>
        </div>

      </div>

      {/* Modal descartar*/}
      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {modalEliminar &&
        <>
          {/* Modal pregunta si desea eliminar estudiante */}
          <Modal nombreModal="estas-seguro-estudiante">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{eliminar_estudiante.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{eliminar_estudiante.descripcion1} <span className="text-resaltado">{datosEstudianteNuevo.nombre}</span> {eliminar_estudiante.descripcion2} <span className="text-resaltado">{datosEstudianteNuevo.nombreSede}</span> {eliminar_estudiante.descripcion3}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-estudiante`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary"
                onClick={eliminarEstudianteNuevo}
              >
                {botones.eliminar.singular}
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion estudiante eliminado */}
          <ModalConfirmacion nombreModal="estudiante-eliminado">
            <Link aria-label="Cerrar ventana" className="icon-cerrar text-20 o right-6 absolute focusable-primary onbackground-var1--color" to="/estudiantes/crear-estudiantes/verificar-estudiantes"></Link>
            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{estudiante_eliminado.titulo}</h1>
            <p className="text-center r7-16-negro26 mb-4">
              {estudiante_eliminado.descripcion1} <span className="text-resaltado">{datosEstudianteNuevo.nombre}</span> {estudiante_eliminado.descripcion2} <span className="text-resaltado">{datosEstudianteNuevo.nombreSede}</span>
            </p>
            <div className="center my-3">
              <Link
                to="/estudiantes/crear-estudiantes/verificar-estudiantes"
                className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
              >
                {botones.volver.volver}
              </Link>
            </div>
          </ModalConfirmacion>
        </>

      }

    </>
  )
}
export default EstudiantesEditarNuevoComponent;