import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Componente que recibe el texto de interfaz y la información de los clubes para renderizar la sección en el Home.
 * @returns ClubsEstudiantes
 */
export const Clubs = ({ clubs }) => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
    const textoHome = textos?.home || {};

    return (
        <>
            {
                clubs?.length !== 0 ?
                    <div>
                        {
                            clubs?.map((club, index) => {
                                const soloEstosClubs = []
                                if (index <= 1) {
                                    soloEstosClubs.push(
                                        <Link
                                            key={club?.id}
                                            to={`/estudiante/clubs/${club?.id}`}
                                            className="focusable-red md:alineado-izquierda-center boton_hover_escalar w-10/12 my-10 mx-auto">

                                            <div className="background-var3--bg border-3 onsurface-var2--bordered md:relative rounded-md  md:w-full p-3 lg:0  mt-8 xl:mt-0 ">
                                                <div
                                                    className="avatar2  icono onprimary-var1--bg surface-var3--bordered mx-auto text-indent md:absolute md:-top-3  md:-left-10"
                                                    style={{ backgroundImage: `url(/images/badges/badge-${club?.icono}.png)` }}

                                                >
                                                    icono-2
                                                </div>

                                                <div className="md:pl-24 lg:pl-24 pt-5 md:pt-0  xl:pr-5 text-center md:text-left">
                                                    <p className="q7-24 onprimary-var2--color una-linea-texto">{club?.nombre}</p>
                                                    <p className="q7-16-negro26">{club?.cantidadLibros || 0} {club?.cantidadLibros !== 1 ? textoHome?.clubs?.libros?.plural : textoHome?.clubs?.libros?.singular}</p>
                                                    <p className="q7-16-negro26">{club?.cantidadEstudiantes || 0}  {club?.cantidadEstudiantes !== 1 ? textoHome?.clubs?.estudiantes?.plural : textoHome?.clubs?.estudiantes?.singular}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }
                                return soloEstosClubs;
                            })
                        }
                    </div>

                    :
                    <div className="mt-5 center lg:alineado-verticalmente lg:items-end ">
                        <div className="lg:center">
                            <img
                                aria-hidden={true}
                                alt="libro abierto"
                                className="blue-inv-var1--bg lg:w-56"
                                src='/images/perfil-estudiante/clubs.png'
                            />
                            <p className="q7-20 onprimary-var1--color text-center mt-5 lg:ml-3 lg:mt-0 break-words">{textoHome?.clubs?.no_hay}</p>
                        </div>
                    </div>
            }

        </>
    )
}

Clubs.propTypes = {
    /**
   * array de objeto que almacena los datos de los clubes a los que pertecene el estudiante. 
   */
    datosClubs: PropTypes.arrayOf(PropTypes.shape({
        archivo: PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string
        }),
        descripcion: PropTypes.string,
        estudiantes: PropTypes.array,
        icono: PropTypes.string,
        id: PropTypes.string,
        libros: PropTypes.array,
        nombre: PropTypes.string
    })).isRequired,
    /**
     *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
     */
    textoHome: PropTypes.object.isRequired
}