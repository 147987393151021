import {SeleccionLibrosInicialAPI} from '../../api/SeleccionLibrosInicialAPI';
import {AccionesLibroAPI} from "../../api/AccionesLibroAPI";
import PropTypes from 'prop-types';

/**
  Componente que se encarga de realizar acciones de actualización de información de libros de manera individual y multiple
 * @returns 
 */
const FunctionsToSelect = (props) => {
  const {librosData, setLibrosData, setProcessingItems, proccesingItems, multiselectState, setMultiselectState, itemsActivos} = props;

  //Para acciones individuales suscripcion activa
  const selectItems = async ({libros, property, newValue, multiselect, action, notUpdateState}) =>{
    if(!proccesingItems){
      setProcessingItems(true);
      let idLibros = libros.map(item=>item.idLibro);
      let properties = {idLibros};
      if(property === 'favorito'){
        properties = {...properties, favorito: newValue}
      }else{
        properties = {...properties, [property]: newValue, accion: action ? action : returnLibroActionForMultiselect({libros})}
      }
      return await AccionesLibroAPI
      .multiplesAcciones(properties)
      .then(resultado=>{
        modificarLibrosFiltrados({libros, property, newValue, multiselect})
        setProcessingItems(false);
        return Promise.resolve(true);
      })
      .catch(error=>{
        console.log(error)
        setProcessingItems(false);
        return Promise.reject(error)
      })
    }
  }

  //Para acciones individuales escogiendo libros
  const seleccionLibros = async ({idPaquete,libros, estadoSeleccion}) =>{
    if(!proccesingItems){
      setProcessingItems(true);
      let idLibros = libros.map(libro=>libro.idLibro);
      await SeleccionLibrosInicialAPI
      .escogerLibros(idPaquete, idLibros || [])
      .then(result=>{
      })
      .catch(error=>{
        console.log('error',error)
        modificarLibrosSeleccionados ({libros, estadoSeleccion})
      })
      setProcessingItems(false);
    }
  }

  

  //La diferencia entre libros data y libros filtrados es que en en libros data mantienen los libros tal como vienen del API, en libros filtrados se modifica el array para mostrar u ocultar el arreglo de libros actual. Por tanto cualquier acción inmediata para el usuario debe hacerse para los libros filtrados pero para que quede en el objeto principal y que el usuario pueda volver a filtrar sin perder cambios debe aplicarse también al estado principal que es el de libros data.


  const modificarLibrosFiltrados = ({libros, property, newValue, multiselect}) => {
    let librosParaModificar = JSON.parse(JSON.stringify(librosData.libros)); 
    let librosIds = libros?.map(libro=>libro?.idLibro);
    //libros a los que se les actualizara los cursos, clubs y estado de favorito  
    const librosActualizados = [...librosParaModificar];
    const action = returnLibroActionForMultiselect({libros});
    librosParaModificar.forEach((libro,i)=>{
      if(librosIds.includes(libro?.idLibro)){
        if(property==='idCursos'){
          librosActualizados[i] = {
            ...librosActualizados[i], 
            cursos:action=='Mostrar'?newValue:[]
          }
        }
        if(property==='favorito'){
          librosActualizados[i] = {
            ...librosActualizados[i], 
            favorito:newValue
          }
        }
      }
    })
    setLibrosData({...librosData, libros: librosActualizados});
  }

  const modificarLibrosSeleccionados = ({libros, estadoSeleccion}) => {
    let librosParaModificar = JSON.parse(JSON.stringify(librosData.libros));    
    libros.forEach((libro) => {
      let libroIndex = librosParaModificar.findIndex(item=> item.idLibro === libro.idLibro);
      let libroItem = librosParaModificar.find(item=> item.idLibro=== libro.idLibro);
      libroItem = {...libroItem, seleccionado: estadoSeleccion};
      librosParaModificar[libroIndex] = libroItem;
    })
    setLibrosData({...librosData, libros: librosParaModificar});
  }

  //activar multiselect
  //Devolver libros seleccionados

  const selectLibroMultiselect = ({libro}) => {
    let multiselectStateToModify = {...multiselectState};
    const {idLibros, librosToSelect} = multiselectStateToModify;
    if(idLibros.includes(libro.idLibro)){
      let positionLibro = librosToSelect.findIndex(item => item.id === libro.idLibro);
      let positionLibroId = idLibros.indexOf(libro.idLibro);
      librosToSelect.splice(positionLibro, 1)
      idLibros.splice(positionLibroId, 1);
      multiselectStateToModify = {active: idLibros.length > 0, idLibros, librosToSelect}
    }else{
      multiselectStateToModify = {active: true, librosToSelect: [...librosToSelect, libro], idLibros: [...idLibros, libro.idLibro]}
    }
    setMultiselectState(multiselectStateToModify)
  }

  const deactivateMultiselect = () => {
    setMultiselectState({active: false, librosToSelect: [], idLibros: []})
  }

  const returnFavoritoActionForMultiselect = ({libros}) => {
    let option = 0;
    for(let i = 0; i < libros.length; i++){
      let libro = libros[i];
      if(Boolean(libro?.favorito)==false){
        option = 1;
        break;
      }
    }
    return option;
  }

  //función para mostrar en el multiselect
  const returnLibroActionForMultiselect = ({libros}) => {
    let option = 'Ocultar';
    for(let i = 0; i < libros.length; i++){
      let libro = libros[i];
      if(!libro?.cursos?.includes(itemsActivos?.curso)){
        option = 'Mostrar';
        break;
      }
    }
    return option;
  }

  //función para mostrar en el multiselect seleccionadoLibros
  const returnLibroSeleccionForMultiselect = ({libros}) => {
    let option = 'Quitar';
    for(let i = 0; i < libros.length; i++){
      let libro = libros[i];
      if(!libro.seleccionado){
        option = 'Seleccionar';
        break;
      }
    }
    return option;
  }

  return {
    selectItems, 
    selectLibroMultiselect, 
    deactivateMultiselect, 
    returnFavoritoActionForMultiselect, 
    returnLibroActionForMultiselect,
    returnLibroSeleccionForMultiselect,
    seleccionLibros
  }
}

export default FunctionsToSelect;

FunctionsToSelect.propTypes = {
  /**
    Función para actualizar el estado de librosData, recibe el siguiente parametro 
    {
      libros:Libro[]
    }
   */
  setLibrosData: PropTypes.func,
  /**
   * Función que actualiza el estado booleano de 'processingItems'.
   */
  setProcessingItems: PropTypes.func,
    /**
   *propiedad booleana que indica si se están procesando la información de los libros.
   */
  proccesingItems: PropTypes.object,
  /**
   * Objeto que contiene el estado de la multiselección de libros
   */
  multiselectState: PropTypes.shape({
    active: PropTypes.bool,
    librosToSelect:PropTypes.arrayOf(PropTypes.object), 
    idLibros: PropTypes.arrayOf(PropTypes.string)
  }),
  /**
   * Actualiza el estado de la multiselección de libros ('multiselectState')
   */
  setMultiselectState: PropTypes.object,
  /**
  * Objeto que contiene una propiedad con los libros disponibles
  */
  librosData: PropTypes.shape({
    libros: PropTypes.arrayOf(PropTypes.object),
  }),
  /**
 * Objeto que contiene los IDs de los items activos cuando se está en modo de 'Plan Activo'.
 */
  itemsActivos: PropTypes.shape({
    sede: PropTypes.string,
    curso: PropTypes.string,
  }),

}