/**
 * Las url de las imagenes no vienen con la direccion completa, este medtodo requiere un arreglo de libros y devuelve el mismo arreglo pero con la url completa de la portada teniendo en cuenta la variable de entorno REACT_APP_ASSETS_URL
 * @param {Libro[]} libros arreglo de libros
 * @returns {Libro[]} libros[]
 */
const adaptarPortadaLibros = (libros = [])=>{
    if(libros && Array.isArray(libros)){
        return libros.map(libro=>{
            const portada = `${process.env.REACT_APP_ASSETS_URL}${libro?.portada}`
            return {...libro, portada:portada}
          });
    }
    return []
}

export {
    adaptarPortadaLibros
}