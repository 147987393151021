import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { READER_ENV, READER_MESSAGE_EVENT_PREFIX, READER_MESSAGE_EVENTS_TO_RECEIVE, READER_MESSAGE_EVENTS_TO_SEND } from "../../config/constants";
import MicroModal from "micromodal";
import { ReaderMessageService } from "./services/reader-message-service";
import { useTrackedState } from "../../store";
import { useNetworkStatus } from "../../hooks/use-network-status";

export const Reader = () => {
	const state = useTrackedState();
	const lang = state?.idioma;
	const params = useParams();
	const bookId = params?.idLibro;
	const networkStatus = useNetworkStatus();
	const memoIsOnline = useMemo(() => networkStatus.online, [networkStatus.online]);

	const readerSrc = `/makemake-reader/index.html
	?bookId=${bookId}
	&lang=${lang}
	&platform=plan_lector
	&env=${READER_ENV}
	&appToken=null
	&downloaded_in_app=false
	`;

	useEffect(() => {
		window.addEventListener("message", handleReaderEvents);
		return () => {
			window.removeEventListener("message", handleReaderEvents);
		};
	}, [bookId]);

	const handleReaderEvents = (event) => {
		handleActivityClickEvent(event);
		handleShareClickEvent(event);
	};

	const handleActivityClickEvent = (e) => {
		const data = e.data;
		const eventId = data?.eventId;
		if (eventId === `${READER_MESSAGE_EVENT_PREFIX}${READER_MESSAGE_EVENTS_TO_RECEIVE.ACTIVITY_CLICK}`) {
			const url = data?.payload?.activityUrl;
			window.open(url, "_blank");
		}
	};

	const handleShareClickEvent = (e) => {
		const data = e.data;
		const eventId = data?.eventId;
		if (eventId === `${READER_MESSAGE_EVENT_PREFIX}${READER_MESSAGE_EVENTS_TO_RECEIVE.SHARE_CLICK}`) {
			openShareModal();
		}
	};

	const openShareModal = () => {
		// MicroModal.show("modal-compartir-visor", {
		//   awaitCloseAnimation: true,
		// });
	  };

	useEffect(()=>{
		const interval = setInterval(() => {
			ReaderMessageService.sendMessage({eventId:READER_MESSAGE_EVENTS_TO_SEND.NETWORK_STATUS_CHANGES, payload:{isOnline:memoIsOnline}})
		}, 3000);
		return ()=>{
			clearInterval(interval);
		}
	},[memoIsOnline])

	return (
		<>

			<div style={{ width: "100dvw", height: "100dvh", overflow: "hidden" }}>
				<iframe
					title="Reader"
					key={bookId}
					className="w-full h-full"
					src={readerSrc}
					style={{ border: "none", outline: "none" }}
				></iframe>
			</div>

		</>
	);
};
