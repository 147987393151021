import { useEffect, useState } from "react";
import useIsMobile from "../../containers/helpers/hooks/useIsMobile";
import PropTypes from 'prop-types';

/**
 * Componente que recibe los datos de una tabla y la función de datos divididos. Este componente renderiza los números de paginación de una tabla.
 */
const Paginacion = (props) => {
  const { datos, datosDivididos } = props;

  //Hooks personalizados
  const isMobile = useIsMobile();

  //Variables de entorno
  const datosPaginador = JSON.parse(process.env.REACT_APP_PAGINADOR);
  const datosPaginadorResponsive = JSON.parse(process.env.REACT_APP_PAGINADOR_RESPONSIVE);

  //Estados del componente
  const [paginaActual, setPaginaActual] = useState(1);
  const [itemsPorPagina, setItemsPorPagina] = useState(isMobile ? datosPaginadorResponsive.itemLista : datosPaginador.itemLista);
  //Cantidad de numeros que se van a mostrar en el paginador
  const [numeroBase, setNumeroBase] = useState(null);
  //Tope maximo
  const [maxNumeroLimite, setMaxNumeroLimite] = useState(null);
  //Tope minimo
  const [minNumeroLimite, setMinNumeroLimite] = useState(1);
  //Cantidad de paginas que se deben mostrar dependiendo de la cantidad de datos
  const [paginas, setPaginas] = useState([]);

  useEffect(() => {
    //Numero total de datos dependiendo del responsive
    const numItemsPorPagina = isMobile ? datosPaginadorResponsive.itemLista : datosPaginador.itemLista;
    setItemsPorPagina(numItemsPorPagina);

    //Numero de items que se vana a mostrar en el paginador
    const maxLimite = isMobile ? datosPaginadorResponsive.itemPaginador : datosPaginador.itemPaginador;
    setMaxNumeroLimite(maxLimite);

    //Genera el numero total de paginas dependiendo del numero total de datos. 
    let numPaginas = [];
    for (let i = 1; i <= Math.ceil(datos.length / numItemsPorPagina); i++) {
      numPaginas.push(i)
    }
    setPaginas(numPaginas);

    //Es la cantidad de numeros que se veran en el paginador. Es 5 o 2 pendiendo del responsive.
    setNumeroBase(maxLimite);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, datos])


  useEffect(() => {
    const final = paginaActual * itemsPorPagina;
    const inicio = final - itemsPorPagina;
    const dividirDatos = datos.slice(inicio, final);
    datosDivididos(dividirDatos);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, itemsPorPagina, datos])

  useEffect(() => {
    setPaginaActual(1);
  }, [datos])


  //Genera los numeros que aparecen en el paginador
  const cargarNumeros = () => {
    let itemPagina = [];
    paginas.forEach((numero) => {
      if (numero < maxNumeroLimite + 1 && numero >= minNumeroLimite) {
        itemPagina.push(
          <li
            key={numero}
            tabIndex={0}
            aria-current={paginaActual && 'page'}
            className={`paginaNumero focusable-red ${paginaActual === numero ? "paginaNumero-activo" : null}`}
            onClick={() => { setPaginaActual(numero) }}
          >
            <p className="mb-1">{numero}</p>
          </li>
        )
      }
    })
    return itemPagina
  }

  const paginaSiguiente = () => {
    const paginaNueva = paginaActual + 1;
    if (paginaNueva > maxNumeroLimite) {
      setMaxNumeroLimite(maxNumeroLimite + numeroBase);
      setMinNumeroLimite(minNumeroLimite + numeroBase);
    }
    setPaginaActual(paginaNueva);
  }

  const paginaAnterior = () => {
    const paginaNueva = paginaActual - 1;
    if (paginaNueva < minNumeroLimite) {
      setMaxNumeroLimite(minNumeroLimite - 1);
      setMinNumeroLimite(minNumeroLimite - numeroBase);
    }
    setPaginaActual(paginaNueva);
  }

  const ultimasPaginas = () => {
    setPaginaActual(paginas.length);

    const n = paginas.length % numeroBase !== 0 ? (paginas.length % numeroBase) - 1 : (numeroBase - 1);
    setMaxNumeroLimite(paginas.length);
    setMinNumeroLimite(paginas.length - n);
  }

  const primerasPaginas = () => {
    setPaginaActual(1);
    setMaxNumeroLimite(isMobile ? datosPaginadorResponsive.itemPaginador : datosPaginador.itemPaginador);
    setMinNumeroLimite(1);
  }


  //Elementos con punticos
  const botonDisminuir = () => {
    if (minNumeroLimite > 1) {
      return <li>
        <button
          className="paginaNumero hoverBackgroundAlfa focusable-red"
          aria-label='Ir a la primera página'
          onClick={primerasPaginas}
        > 1 &hellip;</button>
      </li>
    }
  }

  const botonIncrementar = () => {
    if (paginas.length > maxNumeroLimite) {
    return <li> 
        <button
          aria-label='Ir a la última página'
          className="paginaNumero hoverBackgroundAlfa focusable-red" 
          onClick={ultimasPaginas}
        >
          &hellip; {paginas.length}
        </button>
      </li>
    }
  }


  return (
    <>
      {
        datos.length >= itemsPorPagina &&
        <nav  aria-label="Páginas de resultados">
          <ul className="flex">
            <li>
              <button
                aria-label="Páginas previas"
                disabled={paginaActual === paginas[0] ? true : false}
                className="paginaNumero hoverBackgroundAlfa focusable-red"
                onClick={paginaAnterior}
              >
                <span className={`icon-anterior ${paginaActual === paginas[0] ? "background-var1--color" : "secondary-inv-var1--color"}`}></span>
              </button>
            </li>
            {botonDisminuir()}
            {cargarNumeros()}
            {botonIncrementar()}
            <li>
              <button
                aria-label="Páginas siguientes"
                disabled={paginaActual === paginas[paginas.length - 1] ? true : false}
                className="paginaNumero hoverBackgroundAlfa focusable-red"
                onClick={paginaSiguiente}
              >
                <span className={`icon-siguiente  ${paginaActual === paginas[paginas.length - 1] ? "background-var1--color" : "secondary-inv-var1--color"}`}></span>
              </button>
            </li>
          </ul>
        </nav>

      }
    </>
  )
}
export default Paginacion;


Paginacion.propTypes = {
  /**
   * array de objetos que contiene la información de los datos de una tabla
   */
  datos: PropTypes.array,
  /**
   * Funcion que sirve para dividir los datos de la tabla
   */
  datosDivididos: PropTypes.func
}