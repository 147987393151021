/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import { store } from 'react-notifications-component';
import { Link, useHistory } from "react-router-dom";
import MicroModal from "micromodal";
import PropTypes from 'prop-types';
import { MigasDePan, Miga } from "../globales/MigaDePan";


/**componentes */
import DivisionCrud from "../globales/DivisionCrud";
import Selector from "../globales/elementosFormularios/Selector";
import SelectorCheck from "../globales/elementosFormularios/SelectorCheck";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";

/**
 * Este componente recibe una serie de props que le permiten renderizar la pantalla editar profesor Nuevo. Además, hace uso de los componentes globales DivisionCrud, Selector, SelectorCheck, Modal y ModalConfirmacion.
 * @returns ProfesoresEditarNuevoComponent
 */
const ProfesoresEditarNuevoComponent = (props) => {
  const { actualizarCorreo, actualizarCursosAsignados, actualizarNombre, actualizarSedeAsignada, cursosAsignados, cursosSede, datosProfesorNuevo, descartarCambios, editarProfesor, eliminarProfesor, hayCambios, isProfesorEliminado, nombreProfesor, sedeSeleccionada, sedesInstituto, textosInterfaz, nombreActual } = props;
  const { miga_de_pan, editar, botones, eliminar_profesor, profesor_eliminado, descartar_cambios } = textosInterfaz;
  const { nombre, sede, cursos, correo } = editar;


  /*estados */
  const [inputCorreo, setInputCorreo] = useState({ campo: datosProfesorNuevo.correo, valido: true, desactivado: false });
  const [inputSede, setInputSede] = useState({ campo: datosProfesorNuevo.nombreSede, desactivado: true });
  const [modalEliminar, setModalEliminar] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [campoNombre, setCampoNombre] = useState({ campo: nombreProfesor, valido: true, desactivado: false });
  const [valorInicialCursos, setValorIniciarlCursos] = useState(false);
  const [modalDescartar, setModalDescartar] = useState(false);

  /*expresion */
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  /*REF */
  const refNombreProfesor = useRef(null);
  const refCorreo = useRef(null);
  const history = useHistory();

  /**UseEffect */
  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar]);

  /**Este useEffect Guarda el valor inicial de cursos asignados en un estado para activar y desactivar el SelectorCheck de cursos  */
  useEffect(() => {
    setValorIniciarlCursos(cursosAsignados.ids.length);
  }, [valorInicialCursos])


  /**Abre la ventana modal de ¡estas seguro eliminar? cuando cambia el estado */
  useEffect(() => {
    if (modalEliminar) {
      MicroModal.show('estas-seguro-profesor', {
        awaitCloseAnimation: true
      });
    }
  }, [modalEliminar])

  /**Esto cierra la venta modal de estas seguro y abre la confirmacion en el modal */
  useEffect(() => {
    if (isProfesorEliminado) {
      MicroModal.close('estas-seguro-profesor', {
        awaitCloseAnimation: true
      });
      MicroModal.show('profesor-eliminado', {
        awaitCloseAnimation: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfesorEliminado])

  useEffect(()=>{
    if(refNombreProfesor && campoNombre.desactivado === true){
      refNombreProfesor.current.focus()
    }
  },[campoNombre.desactivado])

  useEffect(()=>{
    if(refCorreo && inputCorreo.desactivado === true){
      refCorreo.current.focus()
    }
  },[inputCorreo.desactivado])

  /*Funciones */
  /*Valida si el nombre del profesor es valido o no. Esto se califica según el número de caracteres */
  const validarNombreProfesor = () => {
    const valorCampo = campoNombre.campo.length > 0 ? true : false;
    setCampoNombre({ ...campoNombre, valido: valorCampo })
  }

  /*esta función actualiza el valor del input y lo agrega en el estado */
  const actualizarCampo = (e, estado, cambiarEstado) => cambiarEstado({ ...estado, campo: e.target.value });

  /*Cambia la sede en el select */
  const cambiarSede = ({ opcion }) => {
    setInputSede({ ...inputSede, campo: opcion.nombre, desactivado: false });
    actualizarSedeAsignada({ idSede: opcion.id });
  }


  /*Cambia el valor de la opcion a check  */
  const actualizarCursos = ({ e, opcion }) => {
    actualizarCursosAsignados({ e, idSede: opcion.idSede, curso: opcion })
  }

  /**Esta función comprueba que el valor del campo correo cumple con la expresión y cambia su estado */
  const validarCorreo = (expresion, estado, cambiarEstado) => {
    const valor = estado.campo !== '' ? expresion.test(estado.campo) : false;
    cambiarEstado({ ...estado, valido: valor });
  }


  /**Crea la notificacion */
  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  /*actualia los cambios de todos los campos */
  const actualizarCambios = () => {
    setMostrarAlerta(true);
    if (campoNombre.valido && inputCorreo.valido) {
      editarProfesor();
      notificacion(textosInterfaz.notificacion.exito, textosInterfaz.notificacion.actualizacion, "success");
      setInputCorreo({ ...inputCorreo, desactivado: false });
      setCampoNombre({ ...campoNombre, desactivado: false });
      setInputSede({ ...inputSede, desactivado: true });
      setValorIniciarlCursos(cursosAsignados.ids.length);
    }
  }

  /**Elimina el profesor */
  const eliminarProfesorNuevo = () => {
    eliminarProfesor();
    MicroModal.close('estas-seguro-profesor', {
      awaitCloseAnimation: true
    });
    MicroModal.show('profesor-eliminado', {
      awaitCloseAnimation: true
    });
  }
  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Profesores'}
          alSeleccionar={() => {
            if (modalDescartar) {
              MicroModal.show('cancelar-crear', {
                awaitCloseAnimation: true
              });
            } else {
              setModalDescartar(true)
            }
          }} />
        <Miga
          texto={miga_de_pan?.crear || 'Crear profesores'}
          alSeleccionar={() => {
            history.push('/profesores/crear-profesores')
          }} />
          <Miga
          texto={miga_de_pan?.verificar || 'Verificar profesores'}
          alSeleccionar={() => {
            history.push('/profesores/crear-profesores/verificar-profesores')
          }} />
        <Miga
          texto={nombreActual}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales mb-5">{editar.titulo}</h1>

        {/* Nombre */}
        <DivisionCrud>
          <>
            <h2 className="raleway-700 onprimary-var2--color">{nombre.titulo} <span className="asterisco">*</span> </h2>
            <p className="r4-14-gris6a">{nombre.descripcion} </p>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!campoNombre.desactivado && 'input_disabled'} ${mostrarAlerta ? !campoNombre.valido && 'input_error' : null}`}
                  onBlur={() => { validarNombreProfesor() }}
                  onChange={(e) => {
                    actualizarCampo(e, campoNombre, setCampoNombre);
                    actualizarNombre({ nombre: e.target.value });
                  }}
                 /*  onClick={() => { setCampoNombre({ ...campoNombre, desactivado: true }) }} */
                  disabled={!campoNombre.desactivado}
                  onKeyUp={validarNombreProfesor}
                  placeholder={nombre.placeholder}
                  ref={refNombreProfesor}
                  type="text"
                  value={campoNombre.campo}
                />

              </div>
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setCampoNombre({ ...campoNombre, desactivado: true })}}
              >
                {botones.editar}
              </button>
            </div>

            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p role="alert" aria-live="assertive" className={`text-14 ${!campoNombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {nombre.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>


        {/* Correo */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{correo.titulo} <span className="asterisco">*</span></h3>
          </>
          <>
            <div className="alineado-verticalmente">
              <div className="w-full xl:w-451p">
                <input
                  className={`w-full ${!inputCorreo.desactivado && 'input_disabled'} ${mostrarAlerta ? !inputCorreo.valido && 'input_error' : null}`}
                  onChange={(e) => {
                    actualizarCampo(e, inputCorreo, setInputCorreo);
                    actualizarCorreo({ correo: e.target.value })
                  }}
                  onBlur={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                  disabled={!inputCorreo.desactivado}
                  /* onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true }) }} */
                  onKeyUp={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
                  placeholder={correo.placeholder}
                  ref={refCorreo}
                  type="email"
                  value={inputCorreo.campo}
                />
              </div>
              <button
                className="evento_rojo ml-5 focusable-primary "
                onClick={() => { setInputCorreo({ ...inputCorreo, desactivado: true }) }}
              >
                {botones.editar}
              </button>
            </div>
            {mostrarAlerta &&
              <div className="w-full xl:w-451p">
                <p role="alert" aria-live="assertive" className={`text-14 ${!inputCorreo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
                  {correo.error}
                </p>
              </div>
            }
          </>
        </DivisionCrud>

        {/* Sedes */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{sede.titulo} </h3>
            <p className="r4-14-gris6a">{sede.descripcion} </p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarSede}
              desactivado={inputSede.desactivado}
              estiloTipoInput={true}
              opciones={sedesInstituto}
              textoDefault={sede.placeholder}
              textoElegido={sedeSeleccionada.nombre}
              superposicion={true}
            />
          </div>
        </DivisionCrud>

        {/* Cursos */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700 onprimary-var2--color">{cursos.titulo} </h3>
            <p className="r4-14-gris6a">{cursos.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <SelectorCheck
              actualizarElegidos={actualizarCursos}
              datosAlmacenados={cursosAsignados.ids}
              desactivado={cursosAsignados.ids.length !== valorInicialCursos ? false : true}
              estiloTipoInput={true}
              hayElementos={cursosSede.hayCursos}
              opciones={cursosSede.cursos}
              textoDefault={`${cursosAsignados.datos.length} ${cursosAsignados.datos.length !== 1 ? cursos.asignados.plural : cursos.asignados.singular} `}
              textoNoHayDatos={cursos.no_hay}
            />
          </div>
        </DivisionCrud>

        <div className="sm:alineado-izquierda-center my-5">
          <button
            className="focusable-primary boton-justo sm:center mr-5 "
            onClick={() => {
              /* eliminarProfesor(); */
              if (modalEliminar) {
                MicroModal.show('estas-seguro-profesor', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalEliminar(true)
              }
            }}
          >
            <span className="icon-basura red-inv-var2--color  text-20 pr-3 hidden sm:block"></span>
            <p className=" evento_rojo">{botones.eliminar.singular}</p>
          </button>

          <Link
            className="w-full sm:w-40 boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 sm:mt-0 text-center mr-5 block focusable-primary"
            to='/profesores/crear-profesores/verificar-profesores'
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </Link>

          <button
            disabled={hayCambios ? false : true}
            onClick={actualizarCambios}
            className="w-full sm:w-40 boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 sm:mt-0 text-center focusable-primary"
          >
            {botones.actualizar}
          </button>
        </div>
      </div>

      {/* Modal descartar*/}
      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {modalEliminar &&
        <>
          {/* Modal pregunta si desea eliminar profesor */}
          <Modal nombreModal="estas-seguro-profesor">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{eliminar_profesor.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{eliminar_profesor.descripcion1} <span className="text-resaltado">{datosProfesorNuevo.nombre}</span> {eliminar_profesor.descripcion2} <span className="text-resaltado">{datosProfesorNuevo.nombreSede}</span> {eliminar_profesor.descripcion3}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-profesor`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary"
                onClick={eliminarProfesorNuevo}
              >
                {botones.eliminar.singular}
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion profesor eliminado */}
          <ModalConfirmacion nombreModal="profesor-eliminado">
            <Link aria-label="Cerrar ventana"  className="icon-cerrar text-20 right-6 absolute focusable-primary onbackground-var1--color" to="/profesores/crear-profesores/verificar-profesores"></Link>
            <img
              alt="eyex feliz"
              className="w-5/12 mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{profesor_eliminado.titulo}</h1>
            <p className="text-center r7-16-negro26 mb-4">
              {profesor_eliminado.descripcion1} <span className="text-resaltado">{datosProfesorNuevo.nombre}</span> {profesor_eliminado.descripcion2} <span className="text-resaltado">{datosProfesorNuevo.nombreSede}</span>
            </p>
            <div className="center my-3">
              <Link
                to="/profesores/crear-profesores/verificar-profesores"
                className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
              >
                {botones.volver.volver}
              </Link>
            </div>
          </ModalConfirmacion>
        </>

      }
    </>
  )
}
export default ProfesoresEditarNuevoComponent;

ProfesoresEditarNuevoComponent.propTypes = {
  /**
   * función que se encarga de actualizar el correo del profesor temporal. 
   * @type{Function({ correo: string }):void} - callback
   */
  actualizarCorreo: PropTypes.func.isRequired,

  /**
  * función que se encarga de actualizar los cursos asignados al profesor.
  * @type{Function({ e: event, curso: shape }):void} -callback
  */
  actualizarCursosAsignados: PropTypes.func.isRequired,

  /**
   * función que actualiza el nombre del profesor
   * @type{Function({ nombre: string }):void} - callback
   */
  actualizarNombre: PropTypes.func.isRequired,

  /**
  * Es una función que se encarga de actualizar el id de la sede en la que se desea crear el profesor temporal.
  * @type{Function({ idSede:string }):void}- callback
  */
  actualizarSedeAsignada: PropTypes.func.isRequired,

  /**
 * Objeto que contiene la información de los cursos asignados al profesor.
 */
  cursosAsignados: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }).isRequired,
  /**
     * Objeto que contiene el estado y los cursos que el instituto tiene.
     */
  cursosSede: PropTypes.shape({
    cursos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    hayCursos: PropTypes.bool
  }).isRequired,

  /**
   * objeto que almacena los datos que se encuentran guardados en el estado antes de ser modificados. 
   */
  datosProfesorNuevo: PropTypes.shape({
    avatar: PropTypes.number,
    correo: PropTypes.string,
    cursos: PropTypes.array,
    datosCursos: PropTypes.array,
    idSede: PropTypes.string,
    key: PropTypes.string,
    nombre: PropTypes.string,
    nombreSede: PropTypes.string
  }).isRequired,

  /**
   *  función que se encarga de borrar los datos temporales que se guarden en el store
   */
  descartarCambios: PropTypes.func.isRequired,

  /**
  * función que se encarga de editar los datos temporales del profesor.
  */
  editarProfesor: PropTypes.func.isRequired,

  /**
   * función que se encarga de eliminar el profesor. Esta función se encarga de llamar el api de borrar profesores.
   */
  eliminarProfesor: PropTypes.func.isRequired,

  /**
  * Objeto que guarda temporalmente los cambios del formulario
  */
  hayCambios: PropTypes.shape({
    cambios: PropTypes.bool,
    datos: PropTypes.object
  }),

  /**
   *  booleano que se encarga de indicar si el profesor que se esta viendo en pantalla fue eliminado por el usuario
   */
  isProfesorEliminado: PropTypes.bool.isRequired,

  /**
   * string que se encarga de almacenar el nombre del profesor antes de modificarse
   */
  nombreProfesor: PropTypes.string.isRequired,

  /**
*  Es un objeto que cuenta con los datos de la sede seleccionada donde se desea crear al profesor.
*/
  sedeSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
  * array de objetos que contiene el listado de sedes que el instituto tiene.
  */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,


  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * String del nombre actual
   */
  nombreActual: PropTypes.string.isRequired
}
