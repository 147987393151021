import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { store } from 'react-notifications-component';
import MicroModal from "micromodal";
import PropTypes from 'prop-types';

//Componentes
import DivisionCrud from "../globales/DivisionCrud";
import Modal from '../globales/modales/Modal';
import ModalConfirmacion from '../globales/modales/ModalConfirmacion';

/**
 * Este componente recibe una serie de props que le permiten renderizar la pantalla editar el instituto/colegio. Además, renderiza  los componentes DivisionCrud, MicroModal, Modal. 
 * @returns EditarInstitutoComponent
 */
const EditarInstitutoComponent = (props) => {
  const { editarSede, eliminarSede, textosInterfaz, respuestaApiEliminarSede, datosSede, preloaderApi, respuestaApiEditarSede } = props;
  const { miga_de_pan, editar, botones, eliminar_sede, sede_eliminada } = textosInterfaz;
  const { color, correo, nombre, paquete, telefono, titulo } = editar;

  const coloresBotones = process.env.REACT_APP_COLORES_SEDE.split(', ');

  const [colorInstituto, setColorInstituto] = useState(datosSede.color);
  const [correoSede, setCorreoSede] = useState({ campo: datosSede.correo, valido: true, desactivado: false });
  const [hayCambios, setHayCambios] = useState(true);
  const [modalSeguro, setModalSeguro] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [nombreSede, setNombreSede] = useState({ campo: datosSede.nombre, valido: true, desactivado: false });
  const [telefonoSede, setTelefonoSede] = useState({ campo: datosSede.telefono, valido: true, desactivado: false });

  // eslint-disable-next-line no-useless-escape
  const expresionTelefono = /^[\+]?(\d[\s-]?){7,14}$/;
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const refNombreSede = useRef(null);
  const refTelefono = useRef(null);
  const refCorreo = useRef(null);


  useEffect(() => {
    if (respuestaApiEliminarSede.isError === false) {
      MicroModal.close('estas-seguro', {
        awaitCloseAnimation: true
      });
      MicroModal.show('sede-eliminada', {
        awaitCloseAnimation: true
      });

    }

    if (respuestaApiEliminarSede.isError === true) {
      MicroModal.close('estas-seguro', {
        awaitCloseAnimation: true
      });

      notificacion(textosInterfaz.notificacion.error, respuestaApiEliminarSede.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEliminarSede.isError])


  useEffect(() => {
    if (respuestaApiEditarSede.isError !== null) {
      notificacion(
        respuestaApiEditarSede.isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        respuestaApiEditarSede.mensaje,
        respuestaApiEditarSede.isError ? "danger" : "success"
      );
      setNombreSede({ ...nombreSede, desactivado: false });
      setCorreoSede({ ...correoSede, desactivado: false });
      setTelefonoSede({ ...telefonoSede, desactivado: false });
    }

    if (respuestaApiEditarSede.isError === true) {
      setNombreSede({ ...nombreSede, campo: datosSede.nombre, desactivado: false });
      setCorreoSede({ ...correoSede, campo: datosSede.correo, desactivado: false });
      setTelefonoSede({ ...telefonoSede, campo: datosSede.telefono, desactivado: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEditarSede.isError])


  useEffect(() => {
    let existenCambios = true;
    if (nombreSede.campo !== datosSede.nombre || colorInstituto !== datosSede.color || telefonoSede.campo !== datosSede.telefono || correoSede.campo !== datosSede.correo) {
      existenCambios = false;
    }
    setHayCambios(existenCambios)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorInstituto, nombreSede.campo, telefonoSede.campo, correoSede.campo])

  useEffect(() => {
    if (modalSeguro) {
      MicroModal.show('estas-seguro', {
        awaitCloseAnimation: true
      });
    }
  }, [modalSeguro])


  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  const validarNombre = () => {
    const valorCampo = nombreSede.campo.length > 0 ? true : false;
    setNombreSede({ ...nombreSede, valido: valorCampo })
  }

  const validarExpresion = (estado, expresion, cambiarEstado) => {
    const valorCampo = estado.campo !== '' ? expresion.test(estado.campo) : true;
    cambiarEstado({ ...estado, valido: valorCampo })
  }

  const actualizarCampo = (e, estado, cambiarEstado) => cambiarEstado({ ...estado, campo: e.target.value });

  const actualizarCambios = () => {
    setMostrarAlerta(true);
    if (nombreSede.valido && correoSede.valido && telefonoSede.valido) {
      if (nombreSede.campo !== datosSede.nombre || colorInstituto !== datosSede.color || telefonoSede.campo !== datosSede.telefono || correoSede.campo !== datosSede.correo) {
        editarSede({
          color: colorInstituto,
          correo: correoSede.campo,
          nombre: nombreSede.campo,
          telefono: telefonoSede.campo,
        });
      }
    }
  }

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <Link to="/institutos" className="focusable-primary mr-2">{textosInterfaz.miga_de_pan.principal}</Link>  &gt;
          <span className="ml-2 font-bold ">{miga_de_pan.editar}</span>
        </p>
      </div>
      <div className="contenido">
        <h1 className="titulos-principales mb-5">{titulo}</h1>
        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700 onbackground-var1--color">{nombre.titulo} <span className="asterisco"></span></h3>
          </>
          <div className="flex">
            <div className="w-full xl:w-451p">
              <input
                className={`w-full ${!nombreSede.desactivado && 'input_disabled'} ${mostrarAlerta ? !nombreSede.valido && 'input_error' : null}`}
                // onBlur={validarNombre}
                // onChange={(e) => { actualizarCampo(e, nombreSede, setNombreSede) }}
                // onClick={() => { setNombreSede({ ...nombreSede, desactivado: true }) }}
                // onKeyUp={validarNombre}
                disabled
                placeholder={nombre.placeholder}
                ref={refNombreSede}
                type="text"
                value={nombreSede.campo}
              />
              {mostrarAlerta &&
                <p className={`text-14 
                  ${!nombreSede.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {nombre.error}
                </p>
              }
            </div>
            {/* <div className="h-9 alineado-verticalmente">
              <button
                className="evento_rojo ml-5"
                onClick={() => { setNombreSede({ ...nombreSede, desactivado: true }); refNombreSede.current.focus() }}
              >
                {botones.editar.campo}
              </button>
            </div> */}
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700 onbackground-var1--color">{telefono.titulo}</h3>
            <p className="r4-14-gris6a">{telefono.descripcion}</p>
          </>
          <div className="flex">
            <div className="w-full xl:w-451p">
              <input
                className={`w-full ${!telefonoSede.desactivado && 'input_disabled'} ${!telefonoSede.valido && 'input_error'}`}
                onChange={(e) => { actualizarCampo(e, telefonoSede, setTelefonoSede) }}
                onBlur={() => validarExpresion(telefonoSede, expresionTelefono, setTelefonoSede)}
                onClick={() => { setTelefonoSede({ ...telefonoSede, desactivado: true }) }}
                onKeyUp={() => validarExpresion(telefonoSede, expresionTelefono, setTelefonoSede)}
                placeholder={telefono.placeholder}
                ref={refTelefono}
                type="tel"
                value={telefonoSede.campo}
              />

              <p className={`text-14 
                  ${!telefonoSede.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
              }>
                {telefono.error}
              </p>
            </div>
            <div className="h-9 alineado-verticalmente">
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setTelefonoSede({ ...telefonoSede, desactivado: true }); refTelefono.current.focus() }}
              >
                {botones.editar.campo}
              </button>
            </div>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700 onbackground-var1--color">{correo.titulo}</h3>
            <p className="r4-14-gris6a">{correo.descripcion}</p>
          </>
          <div className="flex">
            <div className="w-full xl:w-451p">
              <input
                className={`w-full ${!correoSede.desactivado && 'input_disabled'} ${!correoSede.valido && 'input_error'}`}
                onChange={(e) => { actualizarCampo(e, correoSede, setCorreoSede) }}
                onBlur={() => validarExpresion(correoSede, expresionCorreo, setCorreoSede)}
                onClick={() => { setCorreoSede({ ...correoSede, desactivado: true }) }}
                onKeyUp={() => validarExpresion(correoSede, expresionCorreo, setCorreoSede)}
                placeholder={correo.placeholder}
                ref={refCorreo}
                type="email"
                value={correoSede.campo}
              />

              <p className={`text-14 
                  ${!correoSede.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
              }>
                {correo.error}
              </p>

            </div>
            <div className="h-9 alineado-verticalmente">
              <button
                className="focusable-primary evento_rojo ml-5"
                onClick={() => { setCorreoSede({ ...correoSede, desactivado: true }); refCorreo.current.focus() }}
              >
                {botones.editar.campo}
              </button>
            </div>
          </div>
        </DivisionCrud>


        <DivisionCrud>
          <div>
            <h3 className="onbackground-var1--color raleway-700 onbackground-var1--color">{paquete.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{paquete.descripcion}</p>
          </div>
          <div className="xl:w-451p">
            <input
              defaultValue={!!datosSede.paquete ? datosSede.paquete.nombre : paquete.sin_paquete}
              readOnly
              className="w-full xl:w-451p input_disabled  hover:cursor-not-allowed "
            />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700 onbackground-var1--color">{color.titulo}</h3>
            <p className="r4-14-gris6a">{color.descripcion}</p>
          </>
          <div className="xl:w-451p grid grid-cols-3 md:grid-cols-6 lg:grid-cols-3 xl:mb-5 ">
            {
              coloresBotones.map((item, index) => {
                return (
                  <div key={`boton-${index}`} className="p-2">
                    <button
                      aria-label={index}
                      className={`focusable-primary boton-color rounded-md h-20 flex items-start justify-end bg-${item}`}
                      onClick={() => { setColorInstituto(item) }}
                      style={{ backgroundColor: item, backgroundImage: `url(/images/backBoton.png)` }}
                    >
                      {
                        colorInstituto === item &&
                        <span className='icon-check-cuadro text-blanco-claro text-20 m-2' />
                      }
                    </button>
                  </div>
                )
              })
            }
          </div>
        </DivisionCrud>

        <div className="flex justify-end items-center my-5">
          <button
            className="focusable-primary boton-justo sm:flex justify-center items-center mr-5 "
            onClick={() => {
              if (modalSeguro) {
                MicroModal.show('estas-seguro', {
                  awaitCloseAnimation: true
                });
              } else {
                setModalSeguro(true)
              }
            }}
          >
            <span className="icon-basura text-rojo  text-20 pr-3 hidden sm:block"></span>
            <p className=" evento_rojo">{botones.eliminar}</p>
          </button>

          <button
            aria-label='Actualizar/Update'
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
            onClick={() => { actualizarCambios() }}
            disabled={hayCambios}
          >

            {preloaderApi ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              botones.actualizar
            }
          </button>
        </div>

        {/* Modal pregunta si desea eliminar sede */}
        {modalSeguro &&
          <>
            <Modal nombreModal="estas-seguro">
              <div className="pb-3">
                <img
                  alt="eyex frente a un bote de basura"
                  className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                  src='/images/eyex-eliminar.png'
                />
                <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{eliminar_sede.titulo}</h1>
              </div>
              <div className="text-center r7-16-negro26 mb-4">
                <p>{eliminar_sede.descripcion} <span className="text-resaltado">{datosSede.nombre} </span> {eliminar_sede.descripcion2} </p>
              </div>
              <div className="flex justify-center mb-4">
                <button
                  aria-label="Cancelar"
                  onClick={() => {
                    MicroModal.close(`estas-seguro`, {
                      awaitCloseAnimation: true
                    });
                  }}
                  className="focusable-primary boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5"
                >
                  {botones.cancelar}
                </button>
                <button aria-label="Eliminar" className="focusable-primary boton-justo sm:px-10 boton-rojo boton_hover_morado" onClick={eliminarSede}>
                  {preloaderApi ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    :
                    botones.eliminar
                  }
                </button>
              </div>
            </Modal>

            {/* Modal confirmacion sede eliminada */}
            <ModalConfirmacion nombreModal="sede-eliminada">
              <Link className="focusable-primary icon-cerrar text-20  right-6 absolute" to="/institutos"></Link>
              <img
                alt="eyex feliz"
                className="w-5/12  mx-auto bg-img"
                src='/images/eyex-creado.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{sede_eliminada.titulo}</h1>
              <p className="text-center r7-16-negro26 mb-4">
                {sede_eliminada.descripcion1} <span className="text-resaltado">{datosSede.nombre} </span> {sede_eliminada.descripcion2}
              </p>
              <div className="center my-3">
                <Link
                  to="/institutos"
                  className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
                >
                  {botones.volver}
                </Link>
              </div>
            </ModalConfirmacion>
          </>
        }



      </div>
    </>
  )
}

export default EditarInstitutoComponent;


EditarInstitutoComponent.propTypes = {

  /**
   * Función que se encarga de editar los datos de la sede.
   *  @type{Function({ nombre: string, color: number, correo: string,telefono: string }):void} - callback
   */
  editarSede: PropTypes.func.isRequired,

  /**
   *  Es una función que se encarga de eliminar la sede actual.
   */
  eliminarSede: PropTypes.func.isRequired,

  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * Objeto que contiene dos propiedades que va a devolver cuando el API eliminar instituto termine de responder.
   */
  respuestaApiEliminarSede: PropTypes.shape({
    isError: PropTypes.string,
    mensaje: PropTypes.string
  }).isRequired,

  /**
   * Objeto que cuenta con los datos que corresponden a la sede que deseamos editar
   */
  datosSede: PropTypes.shape({
    color: PropTypes.string,
    correo: PropTypes.string, //Esta propiedad puede tener un valor de null 
    nombre: PropTypes.string,
    paquete: PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    }),
    telefono: PropTypes.string, //Esta propiedad puede tener un valor de null 
  }).isRequired,

  /**
   *  Es un booleano que indica si el api de eliminar o editar sede termino de responder o sigue en proceso.
   */
   preloaderApi: PropTypes.bool.isRequired,

   /**
    *  Objeto que contiene dos propiedades que va a devolver cuando el API editar instituto termine de responder.
    */
    respuestaApiEditarSede: PropTypes.shape({
      isError: PropTypes.bool, //Si este cuenta con un valor de null quiere que decir que el api está ejecutando el api.
      mensaje: PropTypes.string      
    }).isRequired
}