import removeAccents from "../../../../utilities/function/removeAccents";

/**
 * Esta función se encarga de comprobar si el header de las tablas del archivo coinciden con los headers esperados de la tabla. El header esperado se extraet del Sheets en donde se encuentran los textos del excel, así que si se necesitan agregar mas campos obligatorios en un momento posterior, estos se deben agregar en el archivo.
 * 
 * @param headerEsperado: array of objects
 * @param headerTabla: array
 * 
 * headerEsperado: Corresponde a un array de objetos, donde cada objeto cuenta dos propiedades :
 *    - campo: Es un string que almacena el nombre del campo que se espera en el Excel subido.
 *    - obligatorio: Es un booleano que indica si el campo es o no obligatorio
 * 
 * headerTabla: Es un array que almacena los nombre de los campos que se encontraron en el Excel subido.
 * 
 * @returns {campos: object, comprobacion: boolean}
 * 
 * campos: Es un objeto donde se almacenan los nombres de los campos que vienen del excel en las propiedades que corresponden o coincidieron dentro de alguna de las propiedades del header esperado.
 * 
 * comprobacion: Es un booelano que indica si la hoja cumple con los campos requeridos para ser creada.
**/


const comprobacionCamposExcel = ({ headerEsperado, headerTabla }) => {
  //Comprobacion si los header de la tyabla coinciden con los establecidos para crear los elementos
  let campos = {};

  headerEsperado.forEach(headerCorrecto => {
    if (headerCorrecto.obligatorio) {
      const nombreHeaderCorrecto = removeAccents(headerCorrecto.campo)
      const headerExcel = headerTabla?.find((header) => removeAccents(header).search(nombreHeaderCorrecto) !== -1);
      campos = { ...campos, [headerCorrecto.campo]: headerExcel };
    }
  })

  let comprobacion;
  for (const key in campos) {
    if (!campos[key]) {
      comprobacion = false;
      break;
    };
    comprobacion = true;
  }

  return { campos, comprobacion };
};

export default comprobacionCamposExcel;