/**
 * Esta función se encarga de validar si los campos obligatorios se encuentran todos llenos, en caso contrario donde estos no se encuentren completos retorna que campos se encuentran incompletos.
 * 
 * @param camposObligatorios: object
 * @param fila: object
 * @param index: integer
 * 
 * camposObligatorios: Es un objeto que contiene los campos que son obligatorios en los datos de las tablas
 * fila: Es un objeto que contiene los datos diligenciados por cada fila
 * index: Es un integer que corresponde a la posicion en la que se encuentra alamacenado dentro del array.
 *  
 * 
 * @returns {hayError: boolean, campos: object}
 * 
 * hayError: Es un booleano que indica si los datos obligatorios del excel se encuentran diligenciados completamente. 
 * campos: Es un objeto donde se almacena en cada propiedad que lleva como nombre el numero de la fila que no cumple con las campos obligatorios. En estos objetos se guarda el nombre de los campos incompletos. Estos solo hacen referencia a los campos obligatorios
 * 
**/

const validacionCamposLlenos = ({ camposObligatorios, fila, index }) => {
  let comprobacionCampos = { campos: {} };
  let campos = [];

  //Validación si los campos obligatorios se encuentran llenos
  for (const key in camposObligatorios) {
    const element = camposObligatorios[key];
    if (fila[element] === undefined) {
      campos.push(element);
      comprobacionCampos.campos[index + 2] = campos;
    }
  }

  const hayError = campos.length !== 0 ? true : false;
  return { hayError, campos: comprobacionCampos.campos };
}


export default validacionCamposLlenos;