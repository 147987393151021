import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import MicroModal from "micromodal";

//Componentes
import Masivo from './CrearCurso/CrearMasivo';
import Individual from './CrearCurso/CrearIndividual';
import Modal from '../globales/modales/Modal';

const CrearCursoComponent = (props) => {
  const { actualizarIconoCurso, actualizarModoCrearCursos, actualizarNombreCursos, actualizarProfesoresAsignados, actualizarSedeAsignada, datosTemporalesExcel, guardarDatosTemporalmente, iconoSeleccionado, isCrearEstudiantes, isDisableInput, isNombreCursoRepetido, modoActivoCrear, nombreCursoNuevo, obtenerDatosExcel, permisoCrearEstudiantes, profesoresAsignados, profesoresInstituto, sedesInstituto, sedeSeleccionada, textosInterfaz, errorSubirExcel, advertenciasExcel, isHayDatosParaDescartar, descartarCambios } = props;

  const { miga_de_pan, crear, botones, modal_icono, error_excel, advertencias, descartar_cambios } = textosInterfaz;
  const { archivo_incorrecto, sin_estudiantes } = error_excel;

  const [nombreCurso, setNombreCurso] = useState({ campo: datosTemporalesExcel ? nombreCursoNuevo : '', valido: '' });
  const [excelValidoCurso, setExcelValidoCurso] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaArchivo, setMostrarAlertaArchivo] = useState(false);
  const [continuarConAdvertencias, setContinuarConAdvertencias] = useState(false);

  const history = useHistory()


  useEffect(() => {
    if (errorSubirExcel.hayError) {
      MicroModal.show('error-archivo', {
        awaitCloseAnimation: true
      });
    }
  }, [errorSubirExcel])

  useEffect(() => {
    setMostrarAlertaArchivo(false);
    setMostrarAlerta(false);

    if (!datosTemporalesExcel.hayDatos ) {
      setExcelValidoCurso(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modoActivoCrear])


  useEffect(() => {
    if (modoActivoCrear === 'masivo') {
      if (mostrarAlerta) {
        setMostrarAlertaArchivo(mostrarAlerta);
      }
    } else {
      if (mostrarAlerta) {
        if (!isNombreCursoRepetido) {
          setMostrarAlertaArchivo(isCrearEstudiantes);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostrarAlerta, isCrearEstudiantes])

  const actualizarProfesores = ({ e, opcion }) => {
    actualizarProfesoresAsignados({ e, idSede: opcion.idSede, profesor: opcion });
  }

  const verificarDatos = async () => {
    setMostrarAlerta(true);
    if (modoActivoCrear === 'masivo') {
      if (!!sedeSeleccionada.id && excelValidoCurso === true) {
        if (advertenciasExcel.hayAdvertencias) {
          MicroModal.show('advertencias-excel', {
            awaitCloseAnimation: true
          });
        } else {
          await guardarDatosTemporalmente();
          history.push('/cursos/crear-curso/verificar-cursos');
        }
      }
    } else {
      if (nombreCurso.valido && !!sedeSeleccionada.id) {
        if (isCrearEstudiantes) {
          if (excelValidoCurso === true) {
            if (advertenciasExcel.hayAdvertencias) {
              MicroModal.show('advertencias-excel', {
                awaitCloseAnimation: true
              });
            } else {
              await guardarDatosTemporalmente();
              history.push('/cursos/crear-curso/verificar-cursos');
            }
          }
        } else {
          await guardarDatosTemporalmente();
          history.push('/cursos/crear-curso/verificar-cursos');
        }
      }
    }

  }

  const continuar = async () => {
    if (continuarConAdvertencias) {
      await guardarDatosTemporalmente();
      history.push('/cursos/crear-curso/verificar-cursos');
    }
  }

  const descartar = () => {
    if (!isHayDatosParaDescartar) {
      history.push('/cursos');
    } else {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <button onClick={descartar} className="focusable-primary mr-2">{miga_de_pan.principal}</button>  &gt;
          <span className="ml-2 font-bold ">{miga_de_pan.crear}</span>
        </p>
      </div>
      <div className="contenido">
        <h1 className="titulos-principales mb-5">{crear.titulo}</h1>

        <div className="flex pb-5">
          <button
            className={`focusable-primary boton-justo  ${modoActivoCrear === 'individual' ? "boton-morado" : "boton-morado_desactivado"}`}
            onClick={() => actualizarModoCrearCursos({ modo: 'individual' })}>{botones.crear.individual}
          </button>
          <button
            className={`focusable-primary boton-justo ml-5 ${modoActivoCrear === 'masivo' ? "boton-morado" : "boton-morado_desactivado"}`}
            onClick={() => actualizarModoCrearCursos({ modo: 'masivo' })} >{botones.crear.masivo}
          </button>
        </div>

        {modoActivoCrear === 'masivo' ?
          <>
            <Masivo
              actualizarSedeAsignada={actualizarSedeAsignada}
              crear={crear}
              datosTemporalesExcel={datosTemporalesExcel}
              desactivar={isDisableInput}
              excelValido={excelValidoCurso}
              hayErrorExcel={errorSubirExcel.hayError}
              mostrarAlerta={mostrarAlerta}
              mostrarAlertaArchivo={mostrarAlertaArchivo}
              obtenerDatosExcel={obtenerDatosExcel}
              opcionesSelector={sedesInstituto}
              sedeSeleccionada={sedeSeleccionada}
              setExcelValido={setExcelValidoCurso}
              setMostrarAlerta={setMostrarAlerta}
            />
          </>
          :
          <>
            <Individual
              actualizarIconoCurso={actualizarIconoCurso}
              actualizarProfesores={actualizarProfesores}
              actualizarSedeAsignada={actualizarSedeAsignada}
              actualizarNombreCursos={actualizarNombreCursos}
              autorizar={isCrearEstudiantes}
              botones={botones}
              crear={crear}
              datosTemporalesExcel={datosTemporalesExcel}
              desactivar={isDisableInput}
              excelValido={excelValidoCurso}
              hayErrorExcel={errorSubirExcel.hayError}
              iconoSeleccionado={iconoSeleccionado}
              isNombreCursoRepetido={isNombreCursoRepetido}
              modal_icono={modal_icono}
              mostrarAlerta={mostrarAlerta}
              mostrarAlertaArchivo={mostrarAlertaArchivo}
              nombreCurso={nombreCurso}
              obtenerDatosExcel={obtenerDatosExcel}
              opcionesSelector={sedesInstituto}
              permisoCrearEstudiantes={permisoCrearEstudiantes}
              profesoresAsignados={profesoresAsignados}
              profesoresInstituto={profesoresInstituto}
              sedeSeleccionada={sedeSeleccionada}
              setExcelValido={setExcelValidoCurso}
              setMostrarAlerta={setMostrarAlerta}
              setNombreCurso={setNombreCurso}

            />
          </>
        }

        {/* Botones */}
        <div className="flex justify-end my-5">
          <button
            to="/cursos"
            className="focusable-primary boton-pequenio boton-rojo boton_hover_morado text-16 text-center mr-5 "
            onClick={() => { descartar() }}
          >
            {botones.cancelar}
          </button>
          <button
            disabled={errorSubirExcel.hayError}
            className="focusable-primary boton-justo boton-amarillo boton_hover_naranja text-16 center"
            onClick={() => { verificarDatos() }}
          >
            {botones.verificar.verificar}
          </button>
        </div>
      </div>


      {/* Modal error archivo*/}
      {errorSubirExcel.hayError &&
        <Modal nombreModal="error-archivo">
          <div className="border-b pb-3">
            <span className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="q7-28 onbackground-var2--color text-center">
              {errorSubirExcel.tipoError === "No hay estudiantes" ? sin_estudiantes.titulo : archivo_incorrecto.titulo}
            </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {errorSubirExcel.tipoError === "No hay estudiantes" ? sin_estudiantes.descripcion : archivo_incorrecto.descripcion}
          </p>

          <button
            aria-label="Regresar"
            onClick={() => {
              MicroModal.close(`error-archivo`, {
                awaitCloseAnimation: true
              })
            }}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_morado block mx-auto mb-6"
          >
            {botones.regresar}
          </button>
        </Modal>
      }

      {/* Modal advertencias Modal */}
      {advertenciasExcel.hayAdvertencias &&
        <Modal nombreModal="advertencias-excel">
          <div className="border-b pb-3">
            <span className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="onbackground-var2--color text-center">{advertencias.titulo} </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {advertencias.descripcion}
          </p>

          <a href={advertenciasExcel.href} download={advertenciasExcel.nombreArchivo} className="evento_rojo text-center r7-16-negro26 block"> {advertencias.archivo.archivo} </a>

          <div className="flex md:items-center md:w-3/4 justify-between  mx-auto my-6">
            <p className="r5-14-negro4A w-9/12  ">{advertencias.archivo.checkbox} </p>
            <label className="relative w-20" role="checkbox">
              <input type="checkbox" className="h-0" onChange={(e) => { setContinuarConAdvertencias(e.target.checked) }} checked={continuarConAdvertencias} />
              <i className="checkbox"><span className="no-check">{crear.opcion.no} </span></i>
            </label>
          </div>

          <div className="center my-5">
            <button
            aria-label="Cancelar"
              className="focusable-primary boton-pequenio boton-rojo boton_hover_morado sm:flex justify-center items-center mr-5 "
              onClick={() => {
                MicroModal.close('advertencias-excel', {
                  awaitCloseAnimation: true
                });
              }}
            >
              <p className=" texto-blanco">{botones.cancelar}</p>
            </button>

            <button
            aria-label="Continuar"
              className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
              onClick={continuar}
              disabled={continuarConAdvertencias ? false : true}
            >
              {botones.continuar}
            </button>
          </div>
        </Modal>
      }

      {/*Modal de descartar cambios */}
      {isHayDatosParaDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img "
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
            aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }
    </>
  )
}
export default CrearCursoComponent;