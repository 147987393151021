import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useClasses } from "../hooks/use-load-classes";
import SelectorDeBusqueda from "../../../components/globales/elementosFormularios/SelectorDeBusqueda";
import { useTrackedState } from "../../../store";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { useSearchClasses } from "../hooks/use-search-classes";
import { HomeSkeleton } from "../skeletons/home-skeleton";
import { ClassesList } from "./components/classes-list";
import { useQueryParams } from "../../../hooks/use-query-params";

export const Home = () => {
	const { textosSubSecciones: texts } = useObtenerSubsecciones("cursos");
	const { miga_de_pan, vista_general, nuevo } = texts || {};
	const { busqueda, rector, profesor, header, select } = vista_general || {};
	const params = useQueryParams();
	const buildingParam = params.get('sede');

	const state = useTrackedState();
	const rolUsuario = state?.planActivo?.tipoUsuario || "null";
	const isRector = rolUsuario?.toLowerCase()?.includes("rector");
	const classesLoader = useClasses();
	const classes = classesLoader.classes;
	const loading = classesLoader.loading;
	// const buildings = classesLoader.buildings;
	// const buildingSelectorOptions = buildings.map((building) => {
	// 	return {
	// 		id: building?.idSede,
	// 		nombre: building?.sedeNombre,
	// 	};
	// });

	const buildings = Array.isArray(state?.datosInstitucion?.sedes)?state?.datosInstitucion?.sedes:[];
	const buildingSelectorOptions = buildings.map(building=>{
	    return {
	        id:building?.id,
	        nombre:building?.nombre
	    }
	})

	const [currentBuilding, setCurrentBuilding] = useState(undefined);
	const [inputText, setInputText] = useState(undefined);
	const filteredClasses = useSearchClasses({
		buildingId: currentBuilding?.id,
		classes,
		query: inputText,
	});

	const filterByAllBuildings = () => {
		setCurrentBuilding(undefined);
	};

	const onChangeBuildingSelector = (event) => {
		const building = event?.opcion;
		if (building) {
			setCurrentBuilding(building);
		}
	};

	const handleInputOnChange = ({ text }) => {
		setInputText(text);
	};

	useEffect(()=>{
		const building = buildingSelectorOptions.find(building=>building?.id === buildingParam);
		setCurrentBuilding(building?building:undefined);
	},[buildingParam])

	if (loading) {
		return <HomeSkeleton />;
	}

	return (
		<>
			<div className="ruta">
				<p>{miga_de_pan?.principal}</p>
			</div>
			<div className="contenido" id="test">
				<h1 className="titulos-principales">{header?.titulo}</h1>

				<div className="md:flex justify-between  flex-wrap items-center  py-3">
					<div
						className={`${
							rolUsuario === "profesor" ? "w-full " : "md:w-7/12 md:order-1"
						}`}
					>
						<p className="informacion-principal">{`${
							rolUsuario === "rector"
								? `${
										rector?.header?.descripcion ||
										"Filtra por colegio (sede) para ver los cursos asociados"
								  }`
								: `${
										profesor?.header?.descripcion ||
										"Aquí encontraras todos los cursos que tienes asignados."
								  }`
						}`}</p>
					</div>

					<div className="w-full md:flex justify-between flex-wrap mt-3 gap-4 md:order-3">
						{rolUsuario === "rector" && (
							<div className="md:w-5/12 xl:w-3/12">
								<SelectorDeBusqueda
									cambiarElegido={onChangeBuildingSelector}
									filtro={""}
									filtroMensajeNoexite={select?.no_existe}
									opciones={buildingSelectorOptions}
									textoDefault={select?.placeholder}
									textoElegido={currentBuilding?.nombre}
									superposicion={true}
									verTodasLasOpciones={{
										textos: select?.ver_todo,
										alVertodasLasOpciones: filterByAllBuildings,
									}}
								/>
							</div>
						)}

						<div
							className={`${
								rolUsuario === "profesor"
									? "w-full"
									: "md:w-6/12 xl:w-451p mt-6 md:mt-0 mb-6"
							}`}
						>
							<div className="relative w-full h-full">
								<input
									className="w-full"
									value={inputText}
									onChange={(e) =>
										handleInputOnChange({ text: e?.currentTarget?.value })
									}
									placeholder={busqueda?.placeholder}
									type="text"
								/>

								<button className="focusable-secondary absolute right-0 top-0 w-10 h-full">
									<span className="icon-lupa onsurface-var1--color hover:text-morado"></span>
								</button>
							</div>
						</div>
					</div>
					{rolUsuario === "rector" && (
						<div className="md:flex justify-end items-center md:w-5/12 my-6 lg:my-0 md:order-2">
							<Link
								className="focusable-primary w-full lg:w-auto boton-justo boton-amarillo boton_hover_naranja center px-10"
								to="/cursos/crear-curso"
							>
								{nuevo?.titulo}
								<span className="icon-mas ml-3"></span>
							</Link>
						</div>
					)}
				</div>
				<ClassesList
					classes={filteredClasses}
					isRector={isRector}
					isFiltering={inputText?.length > 0}
				/>
			</div>
		</>
	);
};
