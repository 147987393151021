import axios from "axios";

export class VisorAPI{

    // DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
    /**
     * @typedef {{ 
    Actividad:{
        contenidoExtra1Actividad: string,
        contenidoExtra2Actividad:string,
        contenidoExtra3Actividad:string,
        contentURL:string,
        idActividad:string,
        tituloActividad: string,
    },
    Archivos: string[],
    Index:string,
    autor:string,
    contenidoExtra1: any,
    contenidoExtra2: any,
    formato:string,
    genero: string,
    gratis: boolean | 0 | 1,
    hrefRecomen1:string,
    hrefRecomen1Portada:string,
    hrefRecomen2: string,
    hrefRecomen2Portada: string,
    idLibro:string,
    identifier:string,
    isbn:string,
    link:string,
    link1:string,
    link2: string,
    link3:string,
    portada: string,
    puedeLeer: boolean | 0 | 1,
    recomendadoID1: string,
    recomendadoID2: string,
    textLink1:string,
    textLink2: string,
    textLink3:string,
    titulo:string
    }} LibroVisor
 */

    /**
     * Devuelve toda la información y assets de un libro
     * @param {string} idLibro Id del libro que se desea obtener
     * @returns {Promise<LibroVisor>} Promesa con el libro solicitado
     */
    static async visor(idLibro){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'POST',
                url:`/visor/visor?idLibro=${idLibro}`
             }) 
             return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Recopila las estadisticas de una sesión
     * @param {string} idLibro el id del libro que se desea obtener
     * @returns {Promise<string>} Mensaje indicando la recopilación de estadisticas
     */
    static async bpc(idLibro){
        try {
            if (window.location.pathname.startsWith('/visor'))//if(window.location.pathname=="/visor/")
            {
                window.location.pathname.split("/").forEach(async(subString) => {                   
                if (subString.startsWith("MM")) {
                    var idLibro = subString;
                    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                    axios.defaults.withCredentials = true;  
                    const response = await axios({
                        method:'POST',
                        url:`/sesion/bpc`,
                        data:JSON.stringify(
                            {
                                bookId: idLibro,
                                updateBookStats: 1
                            }
                        )
                    }) 
                    // console.log('bpc', response.data)
                    return response.data;
                }
                });
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Devuelve el manifest de un Epub
     * @param {string} idLibro Id del libro que se desea obtener
     * @returns {Promise<LibroVisor>} Promesa con el libro solicitado
     */
    static async getManifest(libro){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_STREAMER_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method:'GET',
                url:`/getManifest?url=${libro.Archivos[0]}`
             }) 
             return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }


    /**
     * 
     * @param {string} idLibro Id del libro que se desea obtener
     * @returns {Promise<LibroVisor>} Promesa con el libro solicitado
     */
    static async saveBookMarker({bookId, marker}){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const body = {
                idLibro:bookId,
                marcador:marker,
                accion:'crear'
            }
            const response = await axios({
                method:'POST',
                url:`/libros/marcador`,
                data:body
             }) 
             return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
    // /**
    //  * Devuelve el manifest de un Epub
    //  * @param {string} idLibro Id del libro que se desea obtener
    //  * @returns {Promise<LibroVisor>} Promesa con el libro solicitado
    //  */
    static async getBookMarker({bookId}){
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const body = {
                idLibro:bookId,
                accion:'obtener'
            }
            const response = await axios({
                method:'POST',
                url:`/libros/marcador`,
                data:body
             }) 
             return response.data?.marcador;
        } catch (error) {
            return Promise.reject(error);
        }
    }

}