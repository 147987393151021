import React, { useCallback, useState, useRef, useEffect } from "react";
import MicroModal from "micromodal";

import SwiperCore, { Navigation, Pagination, Keyboard, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { store } from 'react-notifications-component';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import PropTypes from 'prop-types';

SwiperCore.use([Navigation, Pagination, Keyboard, Lazy]);

/**
 * Este componente recibe una serie de props que son necesarios para renderizar el popUp o ventan modal de cambiar avatar. 
 * @returns CambiarAvatar
 */
const CambiarAvatar = (props) => {
  const { datosUsuario, cambiarAvatar, textosInterfaz, preloader, respuestaApiAvatar } = props;
  const { isError, mensaje } = respuestaApiAvatar;
  const [mostrarSwiper, setMostrarSwiper] = useState(false);
  const [avatar, setAvatar] = useState();

  const refCambiarAvatar = useRef(null);
  const avatarModalImagen = `${datosUsuario.avatar}`

  const microModal = useCallback((node) => {
    if (node !== null) {
      MicroModal.show(`${node.getAttribute('id')}`, {
        awaitCloseAnimation: true
      });
      setMostrarSwiper(true)
    }
  }, [])

  useEffect(() => {
    if (mensaje !== '') {
      notificacion(
        isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        mensaje,
        isError ? "danger" : "success"
      );
      MicroModal.close(`cambiar-avatar`, {
        awaitCloseAnimation: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const onKeyEnter = (event, value) => {
    if ((event.keyCode ? event.keyCode : event.which) == 13) {
      setAvatar(value)
      let image = event.target.src;
      document.getElementById('avatar-profile').src = image;
      refCambiarAvatar.current.focus();
    }
  }

  const createAvatars = () => {
    let items = [];
    for (let i = 0; i < 32; i++) {
      let value = i + 1;
      items.push(
        <SwiperSlide
          className={` w-full center`}
          key={`avatar${i}`}
        >
          <div className={`${value === avatar ? 'avatar-active' : ''}  swiper-avatar avatar center`}>
            <img
              alt={`avatar${i}`}
              tabIndex={0}
              className="focusable-red rounded-full"
              onKeyUp={(e) => onKeyEnter(e, value)}
              onClick={(e) => {
                setAvatar(value)
                let image = e.target.src;
                document.getElementById('avatar-profile').src = image;
                refCambiarAvatar.current.focus();
              }}
              src={`/images/avatares/avatar${value}.png`}
            />
          </div>

        </SwiperSlide>
      )
    }
    return items;
  }

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }
  return (
    <div className="micromodal-slide" id="cambiar-avatar" aria-hidden="true" ref={microModal}>
      <div className="modal__overlay" data-micromodal-close>
        <div className="modal__container_avatar max-h-screen-90 max-w-screen-90 lg:max-w-800" role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <img
            className="avatar mx-auto mb-3"
            id="avatar-profile"
            alt={datosUsuario.avatar}
            src={avatarModalImagen}
          />
          <h2 className="font-quicksand red-inv-var2--color text-center pb-2 text-30 font-semibold" id="modal-title">
            {textosInterfaz.avatar.titulo}
          </h2>
          <p className="text-center quicksand-700 text-20 text-negro-claro">
            {textosInterfaz.avatar.instruccion}
          </p>
          <section className="my-8">
            <div className="avatar-container py-3 my-4 relative">
              {
                mostrarSwiper &&
                <Swiper
                  className="w-4/6 sm:w-9/12 lg:w-5/6"
                  slidesPerView={1}
                  slidesPerGroup={1}
                  spaceBetween={20}
                  navigation
                  modules={[Pagination, Navigation]}
                  pagination={{
                    clickable: true,
                    type: "bullets",
                    dynamicBullets: true,
                  }}
                  breakpoints={{
                    453: {
                      slidesPerView: 2,
                    },
                    576: {
                      slidesPerView: 3,
                    },
                    992: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {createAvatars()}
                </Swiper>
              }
            </div>
            <button
              ref={refCambiarAvatar}
              className="boton-justo boton-amarillo boton_hover_morado block text-22 mx-auto mt-3 focusable-primary"
              onClick={() => { cambiarAvatar(avatar) }}
            >
              {preloader ?
                <div className="flex p-2">
                  <span className="icon-spinner7 animate-spin text-20"></span>
                </div>
                :
                textosInterfaz.botones.guardar.avatar
              }
            </button>
          </section>
        </div>
      </div>
    </div >
  )
}

export default CambiarAvatar;


CambiarAvatar.propTypes = {
  /**
   * objeto que trae la información del usuario.
   */
  datosUsuario: PropTypes.shape({
    avatar: PropTypes.string,
    contrasena: PropTypes.string,
    correo: PropTypes.string,
    instituto: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,
  /**
   * Función que se encarga de ejecutar el API de cambiar avatar.
   * @type{Function(value:number):void } - callback
   */
  cambiarAvatar: PropTypes.func.isRequired,
  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo. 
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Booleano que corresponde al preloader para el API de cambiarAvatar.
   */
  preloader: PropTypes.bool.isRequired,
  /**
   *  objeto que contiene dos propiedades que va a devolver cuando las API cambiarAvatar termine de responder.
   */
  respuestaApiAvatar: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }).isRequired
}