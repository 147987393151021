import { useState, useEffect } from 'react';
import { useTrackedState } from "../../../store";
import ObtenerTraducciones from '../obtenerTraducciones';

/**
 * Retorna un objeto con los textos de la interfaz gráfica de una sección específica
 * @param {string} seccion - Sección (pestaña) del archivo de excel  que se desea obtener los textos.
 * @param {string} subseccion - sección especifica dentro de la sección pasada por parámetro.
 * @returns {object} - Objeto con los textos de la sección especificada.
 */
const useObtenerTraducciones = (seccion, subseccion) => {
  const state = useTrackedState();
  const { idioma } = state;
  const [isReady, setIsReady] = useState(false);
  const [textosSeccion, setTextosSeccion] = useState({});
  useEffect(() => {
    //AE: Obtener los textos de la sección. Es necesaria la propiedad isReady ya que en el primer renderizado aún no se han procesado los textos dentro del useEffect, por eso apenas actualiza el estado de textos quitar el isReady y así el componente login nunca debería recibir textos vacios.
    let textos = ObtenerTraducciones(seccion, idioma, subseccion)
    setTextosSeccion(textos);
    setIsReady(true);
  }, [idioma, seccion, subseccion])
  return { isReady, textosSeccion }
}

export default useObtenerTraducciones;