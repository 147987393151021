import PropTypes from 'prop-types';


/**
 * Este componente renderiza la información suministrada sobre el análisis de la colección, teniendo en cuenta la clasificación que se ha seleccionado.
 */
const OpcionesDeClasificacion = (props) => {
    const { analisis, clasificacion, textosClasificacion, textoLibro } = props;

    const coloresBotones = process.env.REACT_APP_COLORES_ANALISIS_COLECCION.split(', ');

    return (
        analisis.map((item, index) => {
            return (
                <div
                    tabIndex={0}
                    role='region'
                    key={`clasificacion-${index}`}
                    className="focusable-primary xsm:flex xsm:justify-between mx-3 my-2 py-2 border-b onbackground-var1--borderb-color">
                    <div className="alineado-verticalmente">
                        <div className={`w-14 h-14 center rounded-lg`} style={{ backgroundColor: coloresBotones[index] }}>
                            {clasificacion !== 'Nivel de lectura' && clasificacion !== 'Edad' &&
                                <span className={`icon-${item.icon} text-blanco text-26`}></span>
                            }
                            {clasificacion === 'Nivel de lectura' &&
                                <img src={`/images/niveles/${item.icon}.png`} />
                            }
                            {clasificacion === 'Edad' &&
                                <p className="q7-20-blanco">{item.icon}</p>
                            }

                        </div>


                        <p className="q7-20-negro33 ml-5">{textosClasificacion[item.translate]}</p>
                    </div>
                    <div className="text-right w-4/12">
                        <p className="q7-24-grisB3 leading-8">{item.porcentaje}%</p>
                        <p className="q7-18-gris6A">{item.numeroDeLibros} {item.numeroDeLibros !== 1 ? textoLibro.plural : textoLibro.singular}</p>
                    </div>
                </div >
            )
        })
    )
}
export default OpcionesDeClasificacion;

OpcionesDeClasificacion.propTypes = {
    /**
     * arreglo de objetos que definen la categoria y el analisis de la libros correspondientes.
     */
    analisis: PropTypes.array.isRequired,
    /**
     * clasificacion del analisis seleccionada que se debe mostrar.
     */
    clasificacion: PropTypes.string.isRequired,
    /**
     * Textos de traducción de la clasificacion seleccionada.
     */
    textosClasificacion: PropTypes.object.isRequired,
      /**
     * objeto con Textos de la palabra 'libro' en singular y plural.
     */
    textoLibro: PropTypes.shape({
        singular: PropTypes.string.isRequired,
        plural: PropTypes.string.isRequired
    }).isRequired,
}