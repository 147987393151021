import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Componente que recibe la información necesaria para cada opción del acordeón en "Preguntas Frecuentes".
 * @returns PreguntasFrecuentes
 */
const PreguntasFrecuentes = (props) => {
  const { textosPreguntasFrecuentes } = props;

  const [acordeonActivo, setAcordionActivo] = useState();
  const [alturaAcordeon, setAlturaAcordeon] = useState();
  const [activador, setActivador] = useState(false);

  const contenedorAcordeon = useRef(null);

  useEffect(() => {
    datoAlturaAcordeon();
    window.addEventListener('resize', datoAlturaAcordeon)
    return () => {
      window.removeEventListener('resize', datoAlturaAcordeon)
    }
  }, [acordeonActivo, alturaAcordeon])


  const datoAlturaAcordeon = () => {
    if (!!contenedorAcordeon.current) return setAlturaAcordeon(contenedorAcordeon.current.scrollHeight);
    setAlturaAcordeon(0);
  }

  const activadorAcordeon = (index) => {
    var nuevoValor = acordeonActivo === index ? "" : index;
    setActivador(acordeonActivo === index ? false : true)
    setAcordionActivo(nuevoValor)
  }

  return (
    <>
      {textosPreguntasFrecuentes.map((textos, index) =>

        <div key={`preguta${index}`}>
          <button
            aria-expanded={activador && acordeonActivo === index}
            className={`focusable-primary  ${acordeonActivo === index ? "acordeon_active" : ""} acordeon`}
            onClick={() => { activadorAcordeon(index) }}
          >
            <h1 className="quicksand-700 text-18 text-left">{textos.titulo} </h1>
            <span className={`icon-select ${acordeonActivo === index ? "" : "transform rotate-180"} inline-block   text-20 transicion-500`}></span>
          </button>

          {activador && acordeonActivo === index &&
            <div
              tabIndex={0}
              ref={acordeonActivo === index ? contenedorAcordeon : null}
              style={{ maxHeight: acordeonActivo === index && activador ? alturaAcordeon : 0 }}
              className={`focusable-primary preguntasFrecuentes`}
            >
              {
                textos?.descripcion &&
                <div className=' m-5 relative h-350 '>
                  <iframe
                    src={textos?.descripcion}
                    frameBorder="0"
                    allowFullScreen
                    className='w-full h-full absolute'
                    title={`video - ${textos?.titulo || 'No se identifica el titulo'}`}>
                  </iframe>
                </div>
              }


              {/* <div className="raleway-500  pt-5 px-5" dangerouslySetInnerHTML={{ __html: textos.descripcion }} /> */}
            </div>
          }
        </div>
      )}
    </>
  )
}
export default PreguntasFrecuentes;

PreguntasFrecuentes.propTypes = {
  /**
   * Arreglo de objetos que contiene la información 
   */
  textosPreguntasFrecuentes: PropTypes.arrayOf(PropTypes.shape({
    descripcion: PropTypes.string,
    titulo: PropTypes.string,
    titulo_video: PropTypes.string
  }))
}