import { useEffect, useState, useMemo } from "react";
import { useTrackedState } from "../../../store"
import { FiltroFechas } from "../../../utilities/gestion_fechas/FiltrosFechas";


/**
 * Hook que es utilizado para devolver un rango de fechas con base en el inicio de suscripcion y la fecha actual.
 * @returns {{inicio:string, fin:string}} Retorna un objeto con una fecha de inicio y de fin.
 */
export const useFechaPorDefecto = () => {
    const state = useTrackedState();

    return useMemo(() => {
        return {
            inicio: state?.datosInstitucion?.fechaInicioSuscripcion,
            fin: FiltroFechas.hoy(state?.idioma, 'hoy').fechaInicio
        };
    }
        , [state])


}