import ModalConfirmacion from "../../globales/modales/ModalConfirmacion";
import PropTypes from "prop-types";

/**
 * Modal de confirmación generico  actualmente usado en el modo de selección de libros para indicar que hacen falta paquetes por escoger.
 */
const ModalVerificacion = (props) => {
  const { nombreDelModal, titulo, descripcion, tituloBotonConfirmacion, alConfirmar, imagen } = props;
  return (
    <ModalConfirmacion nombreModal={nombreDelModal}>
      <img
        alt="eyex feliz"
        className="w-5/12  mx-auto bg-img"
        src={imagen}
      />
      <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{titulo || 'N/A'}</h1>
      <p className="text-center r7-16-negro26 mb-4">
        {descripcion || 'N/A'}
      </p>
      <div className="center my-3">
        <button
        aria-label="Aceptar"
          className="boton-justo boton-amarillo boton_hover_morado py-1 px-10"
          onClick={() => alConfirmar && alConfirmar()}
        >
          {tituloBotonConfirmacion || 'Aceptar'}
        </button>
      </div>
    </ModalConfirmacion>
  )
}
export default ModalVerificacion;

ModalVerificacion.propTypes = {
  /**
   * el nombre requerido para abrir la ventana modal
   */
  nombreDelModal: PropTypes.string.isRequired,

  /**
   * texto o elemento personalizado
   */
  titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),


  /**
   * descripcion de la advertencia, puede ser text o un elemento  personalizado
   */
  descripcion: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * titulo del botón de confirmacion
   */
  tituloBotonConfirmacion: PropTypes.string,
  /**
   * funcion callback que es llamada cuando el botón de confirmar es presionado, no recibe parametros aún.
   * @type{Function():void}
   */
  alConfirmar: PropTypes.func,

  /**
   * string de la ruta de la imagen 
   */
  imagen: PropTypes.string.isRequired

}