import MicroModal from "micromodal";
import PropTypes from "prop-types";

/**
 * Componente que recibe los props necesarios para crear una ventana modal o emergente de tipo sencillo. Este componente recibe la propiedad children, la cual pasa elementos hijos directamente.
 * @returns Modal
 */
const Modal = (props) => {
  const { children, nombreModal, alCerrarModal } = props;

  const cerrarModal = () => {
    MicroModal.close(nombreModal, {
      awaitCloseAnimation: true
    });
    alCerrarModal && alCerrarModal();
  }
  return (
    <div className="micromodal-slide" id={nombreModal} aria-hidden="true">
      <div className="modal__overlay" data-micromodal-close>
        <div className="modal__container_error scroll max-h-screen" role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <button aria-label="Cerrar ventana de dialogo" className="icon-cerrar style-icon-crerrar text-20 right-6 absolute focusable-red" onClick={cerrarModal}></button>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;

Modal.prototypes = {
  /**
  * Elementos que se encuentran dentro de la ventana modal
  */
  children: PropTypes.element.isRequired,
  /**
   * string que se asigna al id de ventana modal
   */
  nombreModal: PropTypes.string,
  /**
   * función que se genera al cerrar la ventana modal
   */
  alCerrarModal: PropTypes.func
}