import { Icon } from '@iconify/react';
import MicroModal from 'micromodal';
import { useEffect } from 'react';
import useObtenerSubsecciones from '../../../containers/helpers/hooks/useObtenerSubsecciones';
import { LocalStorage } from '../../../services/local-storage/LocalStorage';
import { useDispatch } from '../../../store';

import PropTypes from "prop-types";
/**
 * Componente que recibe la propiedad visible que permite mostrar la ventana modal o emergente de tipo sesión cerrada, esta ventana modal aparece cuando caduca la sesión. 
 * @returns ModalSesionCerrada
 */
export const ModalSesionCerrada = (props) => {
    const { visible } = props;
    const idModal = 'modal-sesion-cerrada';
    const { textosSubSecciones: traducciones } = useObtenerSubsecciones('global');
    const dispatch = useDispatch();


    const cerrarModal = () => {
        try {
            MicroModal.close(idModal);
            LocalStorage.guardarEstadoSesion(LocalStorage.SESION_ESTADOS.noAutenticado)
            dispatch({
                type: "SET_DATA_SESION",
                property: "sesionActual",
                value: { estado: LocalStorage.obtenerEstadoSesion() }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        if (visible === true) {
            MicroModal.show(idModal, {
                awaitCloseAnimation: true
            });
        } else {
            try {
                MicroModal.close(idModal);
            } catch (error) {

            }
        }

    }, [visible])



    return (
        <div className="micromodal-slide" id={idModal}  >
            <div className="modal__overlay">
                <div className="modal__container_error scroll max-h-screen" role="alertdialog" >
                    <button aria-label="Cerrar ventana de dialogo" className="icon-cerrar style-icon-crerrar focusable-red text-20 right-6 absolute" onClick={cerrarModal}></button>
                    <div className='flex flex-col center text-center'>
                        <Icon icon="dashicons:warning" className='red-inv-var2--color' width={80} />
                        <h1 className='onprimary-var2--color text-28 font-bold'>
                            {`${traducciones?.advertencias?.sesion?.cerrada_por_inactividad?.titulo
                                || 'Sesión finalizada'}`}

                        </h1>
                        <p className='onprimary-var2--color text-18 font-raleway w-full text-center'>
                            {`${traducciones?.advertencias?.sesion?.cerrada_por_inactividad?.descripcion
                                || `La ultima sesión iniciada se ha finalizado debido a que ha estado inactiva por un largo periodo de tiempo.`}`}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}

ModalSesionCerrada.propTypes = {
    /**
     * Booleano que indica si es visible o no la ventana modal
     */
    visible: PropTypes.bool.isRequired,
}