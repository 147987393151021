import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonFichaLibro = () => {
  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
        <div className="xsm:w-96">
          <Skeleton className="ruta" width={'100%'} height={40} />
        </div>

        <div className="contenido">
          <h1 className="titulos-principales mb-5 ">
            <div className="md:w-40 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>

          <div className="w-28">
            <Skeleton width={'100%'} height={45} />
          </div>

          <div className="xl:flex gap-16 mt-5">
            <div className=" xl:w-3/12">
              <div className="h-96 w-8/12 sm:w-6/12 md:w-4/12  mx-auto xl:w-full mt-10 ">
                <Skeleton width={'100%'} height={'100%'} />
              </div>
              <div className="w-full center border-b border-gris-linea py-3">
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={36} />
                </div>
              </div>

              <div className="2xl:border-b border-gris-linea py-5">
                <div className="md:w-6/12 mb-3 ">
                  <Skeleton width={'100%'} height={30} />
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-2 gap-3  mb-3">
                  <Skeleton width={'100%'} height={30} />
                  <Skeleton width={'100%'} height={30} />
                </div>
                <div className="md:w-6/12 ">
                  <Skeleton width={'100%'} height={30} />
                </div>
              </div>
            </div>

            <div className="xl:w-9/12">
              <div className="flex gap-2 md:gap-4">
                <div className="w-6/12  md:w-56">
                  <Skeleton width={'100%'} height={36} />
                </div>
                <div className="w-6/12  md:w-56">
                  <Skeleton width={'100%'} height={36} />
                </div>
              </div>

              <div className="bg-blanco border-2 border-blanco-boton min-h-600 p-8 lg:p-10">
                <div className="sm:w-6/12 ">
                  <Skeleton width={'100%'} height={45} />
                </div>

                <div className="my-5">
                  <div className="sm:w-4/12 ">
                    <Skeleton width={'100%'} height={36} />
                    <Skeleton width={'50%'} height={25} />
                  </div>
                </div>

                <div className="my-5">
                  <div className="sm:w-4/12 ">
                    <Skeleton width={'100%'} height={36} />
                    <Skeleton width={'50%'} height={25} />
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-4">
                  <div className=" col-span-12 md:col-span-4 lg:col-span-12 2xl:col-span-4">
                    <Skeleton width={'100%'} height={60} />
                  </div>

                  <div className=" col-span-12 md:col-span-4 lg:col-span-12 2xl:col-span-4">
                    <Skeleton width={'100%'} height={60} />
                  </div>

                  <div className=" col-span-12 md:col-span-4 lg:col-span-12 2xl:col-span-4">
                    <Skeleton width={'100%'} height={60} />
                  </div>
                </div>

                <Skeleton className="mt-5" width={'100%'} height={80} />

                <div className="md:alineado-izquierda-center  my-5">
                  <button className="w-full md:w-40 mr-5">
                    <Skeleton width={'100%'} height={36} />
                  </button>
                  <button className="w-full md:w-40  mt-3 md:mt-0">
                    <Skeleton width={'100%'} height={36} />
                  </button>
                </div>

              </div>
            </div>
          </div>

        </div>
      </SkeletonTheme>
    </>
  )
}
export default SkeletonFichaLibro;