
/*Componentes */
import VerificarIndividual from "./VerificarCurso/VerificarIndividual";
import VerificarMasivo from "./VerificarCurso/VerificarMasivo";

const VerificarCursoComponente = (props) => {
  const { crearCursos, datosCursosNuevos, descartarCambios, eliminarCurso, isDisableButtonCrear, isLoading, isReady, modoCreacion, preloaderCrearCursos, respuestaApiCrearCursos, textosInterfaz, numeroCursos } = props;


  return (
    <>
      {modoCreacion === 'individual' ?
        // <SkeletonCursosVerificar />
        <VerificarIndividual
          crearCursos={crearCursos}
          descartarCambios={descartarCambios}
          icono={datosCursosNuevos.icono}
          isLoading={isLoading}
          isReady={isReady}
          listaEstudiantes={datosCursosNuevos.estudiantes}
          listaProfesoresAsignados={datosCursosNuevos.datosProfesores}
          nombre={datosCursosNuevos.nombre}
          nombreSede={datosCursosNuevos.nombreSede}
          preloaderCrearCursos={preloaderCrearCursos}
          respuestaApiCrearCursos={respuestaApiCrearCursos}
          textosInterfaz={textosInterfaz}
        />
        :
        <VerificarMasivo
          crearCursos={crearCursos}
          datosCursosNuevos={datosCursosNuevos}
          descartarCambios={descartarCambios}
          eliminarCurso={eliminarCurso}
          isDisableButtonCrear={isDisableButtonCrear}
          numeroCursos={numeroCursos}
          preloaderCrearCursos={preloaderCrearCursos}
          respuestaApiCrearCursos={respuestaApiCrearCursos}
          textosInterfaz={textosInterfaz}
        />
      }
    </>
  )
}
export default VerificarCursoComponente;