import react, {memo, useEffect, useState} from 'react';
import { useTrackedState } from '../../store';
import DatePicker, { DateObject } from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"
import moment from 'moment'
import { isArray, isString } from '@craco/craco/lib/utils';
import PropTypes from 'prop-types';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { FiltroFechas } from '../../utilities/gestion_fechas/FiltrosFechas';
// import "react-multi-date-picker/styles/colors/purple.css"

/**
 * Componente que renderiza el calendario usado en la sección de estadíticas
 */
export const Calendario = memo((props)=>{
    const {
        fechaPorDefecto,
        fechaMaxima, 
        fechaMinima, 
        formato, 
        alCambiar,
        zIndex,
        placeholder,
        modoMultiple,
        modoRango
    } = props;

    const [fecha, setFecha] = useState(fechaPorDefecto);


    useEffect(() => {
        setFecha(fechaPorDefecto);
    }, [fechaPorDefecto?.length])

    useEffect(() => {
        alCambiar && alCambiar(fecha)
    }, [fecha])

    const manejarFechas = (fechasSeleccionadas)=>{
        let fechas = '';
        if(isArray(fechasSeleccionadas)){
            fechas = fechasSeleccionadas.map(fecha=>{
                return fecha.format(formato)
            })
        }else{
                fechas = Boolean(fechasSeleccionadas)?fechasSeleccionadas.format(formato):FiltroFechas.hoy('',moment()).fechaInicio
        }
        setFecha(fechas);
    }

    return (<>
    <DatePicker
    className='purple bg-auto'
    zIndex={zIndex || 0}
    format={formato && isString(formato) && moment.isDate(formato)?formato: 'YYYY-MM-DD'}
    onChange={(x)=>manejarFechas(x)}
    minDate={fechaMinima}
    maxDate={fechaMaxima}
    range={modoRango}
    multiple={modoMultiple}
    value={fecha}
    showOtherDays
    plugins={[
        <DatePanel/>
    ]}
    render={
        <InputIcon className='rmdp-input focusable-secondary'  placeholder={placeholder}/>
    }

    />

    </>)
})




Calendario.propTypes = {
    placeholder:PropTypes.string,
    fechaPorDefecto:PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    fechaMaxima:PropTypes.string,
    fechaMinima:PropTypes.string,
    formato:PropTypes.string,
    modoMultiple:PropTypes.bool,
    modoRango:PropTypes.bool,
    zIndex:PropTypes.number,
    /**
     * funcion callback que devuelve un arreglo con fechas seleccionadas en el calendario.
     * EJEMPLO
     * @example
     <Calendario
     alCambiar={(fechas)=>console.log(fechas)}/>
     */
    alCambiar:PropTypes.func
}

