import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTrackedState } from "../../../store";
import { Header } from "../components/header";
import { HomeSkeleton } from "../components/skeletons/home-skeleton";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import useInitialDataOfSection from "../../../containers/helpers/useInitiailDataOfSection";
import { CancelRequestController } from "../../../utilities/cancel-request-controller";
import { LibrosAPI } from "../../../api/LibrosAPI";
import { adaptarPortadaLibros } from "../../../utilities/adaptadores";
import { SectionTitle } from "./components/section-title";
import { Badges } from "./components/badges";
import { RecentlyReadBooks } from "./components/recently-read-books";
import { FavoriteBooks } from "./components/favorite-books";
import { RecommendedBooks } from "./components/recommended-books";
import { Clubs } from "./components/clubs";

export const Home = () => {
	const history = useHistory();
	const state = useTrackedState();
	const { badgesEstudiantes } = state;
	const [cargando, setCargando] = useState(true);
	const [leidosRecientemente, setLeidosRecientemente] = useState([]);
	const [escogidosPorProfesor, setEscogidosPorProfesor] = useState([]);
	const [favoritos, setLibrosFavoritos] = useState([]);
	const [clubs, setClubs] = useState([]);
	const { isReady, textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const { isLoading: isLoadingBadges } = useInitialDataOfSection({
		pathname: "/perfilEstudiante/badgesActivos",
		property: "badgesEstudiantes",
	});
	const isMobile = window.innerWidth < 576 ? true : false;

	useEffect(() => {
		let abortController = new CancelRequestController();
		let isUnMounted = false;

		const cargarDatos = async () => {
			if (isUnMounted) {
				return;
			}
			setCargando(true);
			await LibrosAPI.obtenerLibrosEstudiante({
				abortSignal: abortController.signal,
				home: 1,
			})
				.then((resultados) => {
					setLeidosRecientemente(
						adaptarPortadaLibros(resultados?.leidosRecientemente || [])
					);
					setEscogidosPorProfesor(
						adaptarPortadaLibros(resultados?.escogidosPorElProfesor || [])
					);
					setLibrosFavoritos(adaptarPortadaLibros(resultados?.favoritos || []));
					setClubs(adaptarPortadaLibros(resultados?.clubs || []));
				})
				.catch((err) => {
					console.log(err);
				});

			setCargando(false);
		};
		cargarDatos();
		return () => {
			isUnMounted = true;
			abortController.abort();
		};
	}, []);

	return (
		<>
			{isReady && cargando === false && isLoadingBadges !== true ? (
				<>
					<Header />
					<div className="contenido">
						<div className="xl:grid xl:grid-cols-12  gap-4 mt-10">
							<article className="p-5 background-var3--bg onprimary-var1--bordered  col-span-12 rounded-md shadow-4 mb-5 xl:mb-0 sm:h-250">
								<SectionTitle
									icono="icon-medalla"
									titulo={textos?.home?.logros?.titulo}
									mostrarBoton={badgesEstudiantes?.length !== 0 ? true : false}
									textoBoton={textos?.botones?.seccion_libros?.ver_todos}
									alHacerClick={() => {
										history.push({
											pathname: "/estudiante/logros",
										});
									}}
								/>

								<Badges badgesEstudiantes={badgesEstudiantes} />
							</article>

							<article className="p-5 fondo-verde-claro col-span-7 rounded-md shadow-4 mb-5 xl:mb-0">
								<SectionTitle
									icono="icon-reloj"
									titulo={textos?.home?.seguir_leyendo?.titulo}
									mostrarBoton={leidosRecientemente?.length !== 0 ? true : false}
									textoBoton={textos?.botones?.seccion_libros?.ver_todos}
									alHacerClick={() => {
										history.push({
											pathname: "/estudiante/libros/leidos-recientemente",
											state: {
												nombreSeccion: {
													textoEstatico: null,
													traduccion: {
														seccionEspecifica:
															"home.seguir_leyendo.titulo",
														textoAlternativo:
															"Libros leidos recientemente",
													},
												},
												libros: leidosRecientemente,
											},
										});
									}}
								/>

								<RecentlyReadBooks leidosRecientemente={leidosRecientemente} />
							</article>

							<article className="p-5 fondo-rosado rounded-md shadow-4 col-span-5 mb-5 xl:mb-0">
								<SectionTitle
									icono="icon-corazon"
									mostrarBoton={favoritos?.length !== 0 ? true : false}
									textoBoton={textos?.botones?.seccion_libros?.ver_todos}
									titulo={textos?.home?.favoritos?.titulo}
									alHacerClick={async () => {
										history.push({
											pathname: "/estudiante/libros/favoritos",
											state: {
												nombreSeccion: {
													textoEstatico: null,
													traduccion: {
														seccionEspecifica: "home.favoritos.titulo",
														textoAlternativo: "Tus favoritos",
													},
												},
												libros: favoritos,
											},
										});
									}}
								/>

								<FavoriteBooks favoritos={favoritos} />
							</article>

							<article className="p-5 primary-var5--bg primary-inv-var5--bordered col-span-7 rounded-md shadow-4 mb-5 xl:mb-0">
								<SectionTitle
									icono="icon-star-full"
									mostrarBoton={escogidosPorProfesor?.length !== 0 ? true : false}
									textoBoton={textos?.botones?.seccion_libros?.ver_todos}
									titulo={textos?.home?.profesor?.titulo}
									alHacerClick={() => {
										history.push({
											pathname:
												"/estudiante/libros/escogidos-por-el-profesor",
											state: {
												nombreSeccion: {
													textoEstatico: null,
													traduccion: {
														seccionEspecifica:
															"libros.categorias.escogidos-por-el-profesor",
														textoAlternativo: "Tu profe te recomienda",
													},
												},
												libros: escogidosPorProfesor?.map((libro) => libro),
											},
										});
									}}
								/>

								<RecommendedBooks
									escogidosPorElProfesor={escogidosPorProfesor}
									isMobile={isMobile}
								/>
							</article>

							<article className="p-5 blue-var1--bg blue-inv-var1--bordered rounded-md shadow-4 col-span-5 mb-5 xl:mb-0">
								<SectionTitle
									icono="icon-corona"
									titulo={textos?.home?.clubs?.titulo}
									mostrarBoton={textos?.datosClubs?.length !== 0 ? true : false}
									textoBoton={textos?.botones?.seccion_libros?.ver_todos}
									alHacerClick={() => {
										history.push({
											pathname: "/estudiante/clubs",
										});
									}}
									cajaClubs
								/>

								<Clubs clubs={clubs} />
							</article>
						</div>
					</div>
				</>
			) : (
				<HomeSkeleton />
			)}
		</>
	);
};
