import { useEffect, useState } from "react";
import useInitialDataOfSection from "../../../../containers/helpers/useInitiailDataOfSection";
import { useTrackedState } from "../../../../store";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

export const useBadges = ({ badgeCategory }) => {
	const appState = useTrackedState();
	const studentBadges = appState?.badgesEstudiantes;
	const language = appState?.idioma;
	const [badges, setBadges] = useState([]);
	const [medallasAgrupadas, setMedallasAgrupadas] = useState({});
	const [categoriaMedallas, setCategoriaMedallas] = useState();
	const { isReady: isReadyMedallas, textosSubSecciones: textosMedallas } =
		useObtenerSubsecciones("medallas");

	const { isLoading: isLoadingBadges } = useInitialDataOfSection({
		pathname: "/perfilEstudiante/badgesActivos",
		property: "badgesEstudiantes",
	});

	const loadCurrentCategoryBadges = () => {
		if (!!studentBadges && isReadyMedallas) {
			const categorias = Object.keys(textosMedallas);
			let newBadges = {};
			let medallasEstudiante = [];

			studentBadges.length !== 0 &&
				studentBadges.map((medallaEstudiante) =>
					medallasEstudiante.push(medallaEstudiante.Categoria)
				);

			categorias.map((categoria) => {
				let subCategorias = Object.keys(textosMedallas[categoria]);
				newBadges[categoria] = [];
				subCategorias.length !== 0 &&
					subCategorias.map((subCategoria) => {
						let categoriaEstudiante = medallasEstudiante.findIndex(
							(categoriaEstudiante) => subCategoria === categoriaEstudiante
						);

						const nivel = !!studentBadges[categoriaEstudiante]
							? studentBadges[categoriaEstudiante].Nivel
							: 0;
						const numeroCategorias = Object.keys(
							textosMedallas[categoria][subCategoria]
						).length;

						for (let index = 1; index <= numeroCategorias; index++) {
							let activado = false;
							const element =
								textosMedallas[categoria][subCategoria][`nivel${index}`];
							if (index <= nivel) {
								activado = true;
							}
							newBadges[categoria].push({
								...element,
								nivel: index,
								subCategoria: subCategoria,
								activado: activado,
							});
						}
						return subCategoria;
					});
				return categoria;
			});
			setMedallasAgrupadas(newBadges);
			setBadges(newBadges[badgeCategory]);
		}
	};

	useEffect(() => {
		loadCurrentCategoryBadges();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [studentBadges, isReadyMedallas, language, badgeCategory, textosMedallas]);

	/**
	 * función que cambia la categoria de las medallas
	 * @param {string} categoria
	 */
	const changeCategory = (categoria) => {
		setBadges(medallasAgrupadas[categoriaMedallas]);
		setCategoriaMedallas(categoria);
	};

	return {
		loading: isLoadingBadges,
		changeCategory,
		badges,
	};
};
