import { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';
import { MigasDePan, Miga } from "../globales/MigaDePan";
import PropTypes from 'prop-types';

/**componentes */
import Modal from '../globales/modales/Modal';
import ModalConfirmacion from '../globales/modales/ModalConfirmacion';
import DivisionCrud from '../globales/DivisionCrud';
import VerificarListaEstudiantes from './Listas/ListaEstudiantesVerificar';

/**
 * Este componente recibe una serie de props que son necesarios para renderizar la pantalla de verificar club. Además, hace uso de los componentes Modal, ModalConfirmacion, DivisionCrud y VerificarListaEstudiantes.
 * @returns VerificarClubComponent
 */
const VerificarClubComponent = (props) => {
  const history = useHistory();
  const { datosClub, descartarCambios, crearClub, preloader, respuestaApiCrear, textosInterfaz } = props;
  const { miga_de_pan, descartar_cambios, botones, verificar_datos, club_creado } = textosInterfaz;


  /**estados del componente */
  const [modalDescartar, setModalDescartar] = useState(false);

  console.log(verificar_datos.descripcion.descripcion)
  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar])

  /**Una vez que cambia el valor isError, la ventana modal de curso creado aparece   */
  useEffect(() => {
    if (respuestaApiCrear.isError !== null) {
      if (!respuestaApiCrear.isError) {
        MicroModal.show('club-creado', {
          awaitCloseAnimation: true
        });
      } else {
        notificacion(textosInterfaz.notificacion.error, respuestaApiCrear.mensaje, "danger");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiCrear.isError])

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }
  return (
    <>
      <MigasDePan>
      <Miga
          texto={miga_de_pan?.principal || 'Clubs'}
          alSeleccionar={() => {
            if (modalDescartar) {
              MicroModal.show('cancelar-crear', {
                awaitCloseAnimation: true
              });
            } else {
              setModalDescartar(true)
            }

          }} />
      <Miga
          texto={miga_de_pan?.crear || 'Crear un club de lectura'}
          alSeleccionar={() => {
            history.push({
              pathname: '/clubs/crear-club'
            })
          }} />
        <Miga
          texto={miga_de_pan?.verificar || 'Verificar Club'}
          esRutaActual={true}
        />
      </MigasDePan>
      <section className="contenido">
        <h1 className="titulos-principales">{verificar_datos.titulo}</h1>

        <DivisionCrud>
          <h3 className="raleway-700 onsurface-var1--color">{verificar_datos.nombre.titulo} </h3>
          <div className=" alineado-verticalmente input-lectura">
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">{datosClub.nombre}</p>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <h3 className="raleway-700 onsurface-var1--color">{verificar_datos.descripcion.titulo} </h3>
          <div className=" flex input-lectura">
            <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
            <p className="raleway-700 primary-inv-var2--color text-13">{datosClub.descripcion}</p>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <h3 className="raleway-700 onsurface-var1--color">{verificar_datos.archivo.titulo} </h3>
          <div className=" alineado-verticalmente input-lectura">
            {!!datosClub.archivo ?
              <>
                <span className="icon-pushpin text-18 orange-inv-var2--color mr-3"></span>
                <p className="raleway-700 primary-inv-var2--color text-13">{datosClub.archivo.nombre}</p>
              </>
              :
              <p className={`raleway-700 red-inv-var2--color alineado-verticalmente text-13`}>
                <span className="icon-alerta text-18  mr-3"></span> {verificar_datos.archivo.no_hay}
              </p>
            }

          </div>
        </DivisionCrud>

        <DivisionCrud>
          <h3 className="raleway-700 onsurface-var1--color">{verificar_datos.libros.titulo} </h3>
          <>
            {
              datosClub.libros.datos.length !== 0 ?
                <>
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-3 2xl:grid-cols-5  gap-4 mt-5 ">
                    {datosClub.libros.datos.map((libro) => {
                      return (
                        <div key={libro.idLibro}>
                          <div className="w-32 h-44 mx-auto">
                            <img
                              alt={libro.title}
                              className="w-32 h-44"
                              src={`${process.env.REACT_APP_ASSETS_URL}${libro.portada}`}
                            />
                          </div>
                          <div className="w-10/12 xl:w-11/12 mx-auto">
                            <p aria-hidden="true" className=" r7-morado text-center mt-1">{libro.title}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
                :
                <div className='input-lectura'>
                  <p className={`raleway-700 red-inv-var2--color alineado-verticalmente text-13`}>
                    <span className="icon-alerta text-18  mr-3"></span> {verificar_datos.libros.no_hay}
                  </p>
                </div>
            }
          </>
        </DivisionCrud>


        <DivisionCrud>
          <>
            <h3 className="raleway-700 onsurface-var1--color">{verificar_datos.icono.titulo}</h3>
          </>
          <div className="lg:w-32">
            <div
              className="avatar icono onsurface-var2--bordered mx-auto  text-indent"
              style={{ backgroundImage: `url(/images/badges/badge-${datosClub.icono}.png)` }}
            >
              {verificar_datos.icono.titulo}
            </div>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onsurface-var1--color">{datosClub.estudiantes.datos.length !== 1 ? verificar_datos.estudiantes.titulo.plural : verificar_datos.estudiantes.titulo.singular}</h3>
            <p className="r4-14-gris6a">{verificar_datos.estudiantes.descripcion} </p>
          </>
          <VerificarListaEstudiantes
            datosEstudiantes={datosClub.estudiantes.datos}
            textosTabla={verificar_datos.estudiantes.tabla}
          />
        </DivisionCrud>

        {/* BOTONES */}
        <div className="flex justify-center md:justify-end my-5">
          <Link
            to="/clubs/crear-club"
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center mr-5"
          >
            <span className="icon-volver pr-3"></span>
            {botones.regresar}
          </Link>
          <button
            onClick={crearClub}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center"
          >
            {preloader ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              botones.crear
            }
          </button>
        </div>

      </section>


      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img "
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal creado */}
      {!respuestaApiCrear.isError &&
        <ModalConfirmacion nombreModal="club-creado">
          <Link className="icon-cerrar style-icon-crerrar text-20 right-6 absolute" to="/clubs"></Link>
          <img
            alt="eyex feliz"
            className="w-5/12 mx-auto bg-img "
            src='/images/eyex-creado.png'
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{club_creado.titulo}</h1>
          <p className="text-center r7-16-negro26 mb-4">
            {club_creado.descripcion1} <span className="text-resaltado">{datosClub.nombre} </span>, {club_creado.descripcion2}
          </p>
          <div className="center my-3">
            <Link
              to="/clubs"
              className="boton-justo boton-amarillo boton_hover_morado py-2"
            >
              {botones.volver}
            </Link>
          </div>
        </ModalConfirmacion>

      }
    </>
  )
}

export default VerificarClubComponent;

VerificarClubComponent.propTypes = {
  /**
   *  Es un objeto que almacena las propiedades del club
   */
  datosClub: PropTypes.shape({
    archivo: PropTypes.object, //puede ser null
    descripcion: PropTypes.string,
    estudiantes: PropTypes.shape({
      datos: PropTypes.array,
      ids: PropTypes.array
    }),
    icono: PropTypes.number,
    libros: PropTypes.shape({
      datos: PropTypes.array,
      ids: PropTypes.array
    }),
    nombre: PropTypes.string
  }),
  /**
    *  Es una función que se encarga de borrar los datos temporales que se guarden en el store.
    */
  descartarCambios: PropTypes.func,
  /**
   * Es una función que se encarga de crear el club. Para esto esta función se encarga de llamar el api de crear clubs.
   */
  crearClub: PropTypes.func,
  /**
   *  Es un booleano que contiene el preloader para el llamado de las API de crear club.
   */
  preloader: PropTypes.bool,
  /**
   *  Es un objeto que contiene dos propiedades que va a devolver cuando el API crear club. termine de responder.
   */
  respuestaApiCrear: PropTypes.shape({
    isError: PropTypes.string,
    mensaje: PropTypes.string
  }),
  /**
  *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
  */
  textosInterfaz: PropTypes.object
}