import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export const BookListSkeleton = () => {
	return (
		<SkeletonTheme color={"#e8e8e8"} highlightColor={"#f7f7f7"}>
			<div className="w-full">
				<div className="w-full md:w-1/2">
					<Skeleton width={"100%"} height={36} />
				</div>
				<div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 mt-5">
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
					<Skeleton width={"100%"} className="my-3" style={{ aspectRatio: "29/45" }} />
				</div>
			</div>
		</SkeletonTheme>
	);
};
