import { Switch, Route } from "react-router-dom";
import { ClubsHome } from "./pages/clubs-home";
import Error_404 from "../../../containers/Error_404";
import { Club } from "./pages/club";

export const Clubs = () => {
	return (
		<Switch>
			<Route exact path="/estudiante/clubs">
				<ClubsHome />
			</Route>

			<Route exact path="/estudiante/clubs/:club">
				<Club />
			</Route>
			<Route component={Error_404} />
		</Switch>
	);
};
