import axios from "axios";

export class DetalleLibroProfesorAPI {



    // DEFINICIÓN DE LOS TIPOS
    /**
     * @typedef {  
       {
        idLibro: string,
        tituloLibro: string,
        autor: string,
        editorial: string, //mins
        lecturasLibro:[
            {
            nombreCompleto: string,
            numeroDeVecesLeido: number,
            tiempoTotalLectura: number,
            tiempoPromedioDeLectura: number,
            nombreInstituto: string,
            fechaLectura: string
            }
        ]
        }


  } DetalleLibroProfesor
  **/

    /**
     * @type {DetalleLibroProfesor}
     */
    static DetalleLibroPorDefecto = {
      autor:'N/A',
      editorial:'N/A',
      idLibro:'N/A',
      lecturasLibro:[],
      tituloLibro:'N/A' 
    }



    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

    /**
  * devuelve un objeto con las estadisticas de los profesores que han leído el libro que coincida con el ID pasado como parametro. 
  * @param {String} idLibro - id del libro
  * @param {String} fechaInicio - la fecha de inicio de busqueda de información
  * @param {String} fechaFin - la fecha de fin  de busqueda de información
  * @returns {Promise<DetalleLibroProfesor>} DetalleLibro
  */
    static async obtenerDetalle({idLibro, fechaInicio, fechaFin, abortSignal=null}) {
        const body = {
            idLibro:idLibro || '',
            fechaInicio: fechaInicio || '',
            fechaFin: fechaFin || ''
        }
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const response = await axios({
                method: 'POST',
                url: 'estadisticas/estadisticasDetalleLibroProfesor',
                data: body,
                signal:abortSignal || null
            })
            if (response.data.status != 1) {
                return new Error(response.data?.data?.info || 'Hubo un error, intente más tarde.');
            }

            const detalleLibro = response.data?.data.find(libro=>libro?.idLibro === idLibro);
            const detalles = detalleLibro || DetalleLibroProfesorAPI.DetalleLibroPorDefecto;
            return detalles;

        } catch (error) {
            return Promise.reject(error);
        }

    }


}

