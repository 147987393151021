import SkeletonEstudiantesCrearIndividual from "./SkeletonEstudiantesCrearIndividual";
import SkeletonEstudiantesCrearMasivo from "./SkeletonEstudiantesCrearMasivo";

const SkeletonEstudiantesCrear = (props) => {
  const { modoActivoCrear } = props;
  return (
    <>
      {
        modoActivoCrear === 'individual'
          ? <SkeletonEstudiantesCrearIndividual />
          : <SkeletonEstudiantesCrearMasivo />
      }
    </>
  )
}
export default SkeletonEstudiantesCrear;