import * as XLSX from "xlsx";

const alphabet = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
];

/**
 * Permite transformar los datos pasados en un objeto a un archivo excel y descargarlo.
 * @param {{type:string, nombreArchivo:string, valores:object, headerTabla:string[]}} datos Datos a transformar en un archivo excel.
 */
function descargarTabla({type, nombreArchivo, valores, headerTabla }) {

  let worksheet = XLSX.utils.json_to_sheet(valores)

  if (!!headerTabla) {
    for (let i = 0; i < headerTabla.length; i++) {
      worksheet[`${alphabet[i]}1`].v = headerTabla[i]
    }
  }

  let workbook = XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'test');

  const worksheetNombre = nombreArchivo || "SheetJSTableExport."
  const extension = type || "xlsx";

  return XLSX.writeFile(workbook, `${worksheetNombre}.${extension}`);
}


export default descargarTabla;