import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LibrosAPI } from "../../api/LibrosAPI";
import BuscadorFiltros from "../../components/libros/BuscadorFiltros";
import { useDispatch, useTrackedState } from "../../store";
import { adaptarPortadaLibros } from "../../utilities/adaptadores";
import FiltrosData from "./FiltrosData";
import PropTypes  from "prop-types";

/**
 Este componente de tipo Container se encarga de gestionar los filtros de busqueda 
 de libros (actualizar filtros, borrar filtros, guardar filtros y manipular los filtros)
 este container y su componente son utilizados por 'SuscripcionActiva' y por 'EscogiendoLibros'
 * @returns {JSX.Element}  Retorna y renrendiza el componente 'BuscadorFiltros'
 */
const FiltrosContainer = (props) => { 
  const {alFiltrar, alLimpiarFiltros, setSchFilter, setLibrosFiltrados, librosData, textosFiltros, botonesFiltros, itemsActivos, setIsLoadingLibros, paqueteParaSeleccionar, eligiendoLibros} = props;
  const [filtros, setFiltros] = useState(JSON.parse(JSON.stringify(FiltrosData)));
  const [mostrarBorrarFiltro, setMostrarFiltro] = useState(false);
  const state = useTrackedState();
  const filtrosGuardados = state?.seccionLibros?.temporal?.modoFiltro;
  const dispatch = useDispatch();

  const cargarFiltrosPlanos = ()=>{
    const filtrosAcargar = (Boolean(filtrosGuardados) && Boolean(filtrosGuardados?.activado))?filtrosGuardados?.filtros:JSON.parse(JSON.stringify(FiltrosData));
    if(eligiendoLibros){
      const filtrosSesionActiva = filtrosAcargar.filter(filtro=>filtro?.visibilidad!= 'sesionActiva')
      setFiltros(filtrosSesionActiva)
    }else{
      const filtrosEscogiendoLibros = filtrosAcargar.filter(filtro=>filtro?.visibilidad!= 'escogiendoLibros')
      setFiltros(filtrosEscogiendoLibros)
    }
  }

  //FUNCIONES DE LOS FILTROS
  const modificarFiltros = ({property, newValue, option}) =>{
    let newFilters = [...filtros];
    const position = filtros.findIndex(item=>item.name === property);
    if(option){
      let mainCategory = newFilters[position]
      let options = mainCategory.options;
      let optionPosition = options.findIndex(opt => opt.name === option.name);
      let optionItem = options[optionPosition]
      optionItem.selected = !options[optionPosition].selected;
      mainCategory.numeroOpciones = options.filter(item=>item.selected).length;
    }else{
      newFilters[position].value = newValue;
    }
    setFiltros(newFilters);
  }

  const guardarFiltros = (filtros, activo)=>{
    dispatch({
      type: "SECCION_LIBROS_TEMPORAL",
      property: "modoFiltro",
      value: {
        filtros:filtros,
        activado:activo
      }
    })
  }

  
  const findFilter = (name, filtrosData) => {
    return filtrosData.find(filtro => filtro.name === name)
  }

  const enviarFiltros = (filtrosData) => {
    let busqueda = findFilter('search', filtrosData);
    let favorito = findFilter('favorito', filtrosData); 
    let actividad = findFilter('actividad', filtrosData);    
    let ocultos = findFilter('libros_ocultos', filtrosData);    
    let agregados = findFilter('libros_agregados', filtrosData);    
    let recomendacionAlgoritmo = findFilter('recomendadosPorAlgoritmo', filtrosData);    
    let filtrosParaEnviar = {};
    if(!!busqueda.value){filtrosParaEnviar.sch = busqueda.value};
    if(actividad.value){filtrosParaEnviar.actividad = true};
    if(favorito?.value){filtrosParaEnviar.favorito = true};
    if(ocultos?.value){filtrosParaEnviar['libros_ocultos'] = true};
    if(agregados?.value){filtrosParaEnviar['libros_agregados'] = true};
    if(recomendacionAlgoritmo?.value){filtrosParaEnviar['recomendadosPorAlgoritmo'] = true};
    let dropdowns = filtrosData.filter(item=>item.type === 'dropdown');
    dropdowns.forEach(item=>{
      const {options, apiProperty} = item;
      let selectedItems = [];
      options.forEach(option=>{
        if(option.selected){
          selectedItems.push(option.value ? option.value : option.name);
        }
      })
      if(selectedItems.length > 0){
        filtrosParaEnviar[transformarPropiedad(apiProperty)] = selectedItems.join(';')
        /* if(apiProperty == 'tiempoLectura'){
          filtrosParaEnviar[transformarPropiedad(apiProperty)] = transformarDatosTiempoLectura(selectedItems.join(';'))
        } */
      }
    })
    
  /*   console.log(filtrosParaEnviar) */
    alFiltrar&&alFiltrar();
    funcionFiltrar(filtrosParaEnviar);
    setMostrarFiltro(true);
    guardarFiltros(filtrosData, true);
  }

  /**
   * Transformar la api property realizada por Andrés E. 
   * en FiltrosData por las que solicita la API real, 
   * esto se hace para no generar confilctos 
   * cambiando directamente las propiedad en filtrosData 
   * debido a que podria afectar el comportamiento 
   * en otras partes donde se utilice FiltrosData
   */

  const transformarPropiedad = (propiedad)=>{
    switch (propiedad) {
      case 'Materia':return 'materias';
      case 'nivelUsuarioLector':return 'nivelUsuario';
      default:return propiedad;
    }
  }

  /**
   * Retorna el objeto que requiere el parametro tiempoLectura con el valor minimo y maximo de los rangos, esto se hace para que retorne los libros que estan en esos rangos.
   */
  const transformarDatosTiempoLectura = (rangos = "")=>{
    const split = rangos.replaceAll('-' ,' ').replaceAll(';', ' ').replaceAll('>',' ').split(' ');
    const max = split.reduce((acc,curr)=>{
      const num = parseInt(curr);
      if(Number.isInteger(num) && num > acc){
        acc = curr;
      }
     return acc;
    },0)
    const min = split.reduce((acc,curr)=>{
      const num = parseInt(curr);
      if(Number.isInteger(num) && num <= acc){
        acc = curr;
      }
     return acc;
    },max)

    let tiempoLectura = {min:min,max:max}
    if(rangos.includes('>20')){
      tiempoLectura = {min:min, max:999999 }
    }
    return tiempoLectura
  }

  const borrarFiltros = () => {
    guardarFiltros(null);
    setSchFilter('')
    if(librosData?.libros)
    setLibrosFiltrados(JSON.parse(JSON.stringify(librosData.libros)))
    setMostrarFiltro(false);
  }

  const funcionFiltrar = async (filtros) => {
    setIsLoadingLibros(true);
    let librosFiltrados;
    if(eligiendoLibros){
      await LibrosAPI
      .librosPorPaquete(paqueteParaSeleccionar?.nombre,{...filtros})
      .then(libros=>{
        librosFiltrados = adaptarPortadaLibros(libros);
      })
      .catch(error=>console.log(error));
      // librosFiltrados = await generalCallApi({ pathname: api_obtenerLibrosPorPaquete, properties: { idPaquete: paqueteParaSeleccionar.id, ...filtros } });
    }else{
      await LibrosAPI
      .multiplesFiltros({...filtros, idSede: itemsActivos.sede || ''})
      .then(libros=>{
        librosFiltrados = adaptarPortadaLibros(libros);
      })
      .catch(error=>console.log(error));
      // librosFiltrados = await generalCallApi({ pathname: api_obtenerLibros, properties: { idSede: itemsActivos.sede, ...filtros } });
    }
    const filtroOcultos = filtros['libros_ocultos'];
    if(Boolean(filtroOcultos)){
      librosFiltrados = librosFiltrados.filter(libro=>libro?.cursos.includes(itemsActivos?.curso)===false);
    }
    const filtroAgregados = filtros['libros_agregados'];
    if(Boolean(filtroAgregados)){
      librosFiltrados = librosFiltrados.filter(libro=>Boolean(libro?.elegido));
    }
    setLibrosFiltrados(librosFiltrados)
    setIsLoadingLibros(false);
  }


  useEffect(()=>{
    cargarFiltrosPlanos();
  },[filtrosGuardados])


  useEffect(()=>{
  if(Boolean(filtrosGuardados) && Boolean(filtrosGuardados?.activado)){
    enviarFiltros(filtrosGuardados?.filtros)
    
  }
  },[itemsActivos])


  return(    
    <BuscadorFiltros 
      {...props}
      filtros={filtros}
      mostrarBorrarFiltro={mostrarBorrarFiltro}
      modificarFiltros={modificarFiltros}
      borrarFiltros={borrarFiltros}
      enviarFiltros={enviarFiltros}
      textosFiltros={textosFiltros}
      botonesFiltros={botonesFiltros}
      alFiltrar = {alFiltrar}
      alLimpiarFiltros = {alLimpiarFiltros}
    />
  )
}

export default FiltrosContainer;

FiltrosContainer.propTypes = {
  /**
   * Funcion que se ejecuta cuando al menos un filtro se activa
   */
  alFiltrar: PropTypes.func,
   /**
   * Funcion que se ejecuta cuando se han borrado todos los filtros
   */
  alLimpiarFiltros: PropTypes.func,
     /**
   * Funcion que actualiza el filtro por busqueda recibe como parametro el texto de busqueda
   */
  setSchFilter: PropTypes.func,
       /**
   * Funcion que actualiza los libros cuando se han filtrado
   */
  setLibrosFiltrados: PropTypes.func,
   /**
   * Objeto que contiene una propiedad con los libros disponibles
   */
  librosData: PropTypes.shape({
    libros: PropTypes.arrayOf(PropTypes.object),
  }),
    /**
   * Objeto que contiene los textos de traducción para los filtros
   */
  textosFiltros: PropTypes.object,
   /**
   * Objeto que contiene los textos de traducción para los botones
   */
  botonesFiltros: PropTypes.object,
    /**
   * Objeto que contiene los IDs de los items activos cuando se está en modo de 'Plan Activo'.
   */
  itemsActivos: PropTypes.shape({
    sede: PropTypes.string,
    curso: PropTypes.string,
  }),
    /**
   * Función que actualiza e indica si se está cargando los libros
   */
  setIsLoadingLibros: PropTypes.func,
    /**
   * Objeto con la información del paquete para ser seleccionado, este objeto solo se pasa cuando se está en modo de selección de libros
   */
  paqueteParaSeleccionar: PropTypes.object,
  /**
   * Propiedad booleana que define si se está en modo de eligiendo libros o no.
   */
  eligiendoLibros: PropTypes.bool

} 