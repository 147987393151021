import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const BooksSkeleton = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={170} height={40} />
      <div className="contenido">
        <h1 className="titulos-principales  ">
          <div className="w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>
        <div className="h-36 mt-5 ">
          <Skeleton width={'100%'} height={'100%'} />
        </div>

        <div className="sm:flex flex-wrap justify-between items-center  border-b-2 border-gris-linea mt-5">
          <div className="w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>

          <div className="w-40 boton-pequenio">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8 lg:gap-6 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-6 mt-5">
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
        </div>

        <div className="sm:flex flex-wrap justify-between items-center  border-b-2 border-gris-linea mt-5">
          <div className="w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>

          <div className="w-40 boton-pequenio">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8 lg:gap-6 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-6 mt-5">
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
          <div className="h-44 sm:h-60 md:h-44 xl:h-60"> <Skeleton width={'100%'} height={'100%'} /></div>
        </div>

      </div>
    </SkeletonTheme>
  )
}
