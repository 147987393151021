import React, { useCallback, useState, useRef } from "react";
import MicroModal from "micromodal";

import SwiperCore, { Navigation, Pagination, Keyboard, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Navigation, Pagination, Keyboard, Lazy]);


/**
 * Componente que recibe una serie de propiedades necesarias para renderizar la ventana modal o emergente de cambiar icono o avatar.
 * @returns ModalCambiarIcono
 */
const ModalCambiarIcono = (props) => {
    const { datoIcono, cambiarAvatar, cambiarAvatarEstudiante, boton, titulo, descripcion, nombreModal, minIconos, maxIconos, rutaCarpeta, rutaEstudiante } = props;
    const [mostrarSwiper, setMostrarSwiper] = useState(false);
    const [avatar, setAvatar] = useState();

    const refCambiarAvatar = useRef(null);
    const avatarModalImagen = !!rutaEstudiante ? `${rutaEstudiante}` : `/images/${rutaCarpeta}${datoIcono}.png`;

    const microModal = useCallback((node) => {
        if (node !== null) {
            MicroModal.show(`${node.getAttribute('id')}`, {
                awaitCloseAnimation: true
            });
            setMostrarSwiper(true)
        }
    }, [])

    const createAvatars = () => {
        let items = [];
        for (let i = minIconos; i <= maxIconos; i++) {
            let value = i;
            let _image = !!rutaEstudiante ? `/images/avatares/avatar${value}.png` : `/images/${rutaCarpeta}${value}.png`;
            items.push(
                <SwiperSlide
                    className={` w-full center py-3`}
                    key={`avatar${i}`}
                >
                    <button 
                        aria-label={`Icono ${i+1}, Icon ${i+1}`}
                        className={`focusable-primary ${value === avatar ? 'avatar-active' : ''}  ${!!rutaEstudiante ? null : 'p-3'} swiper-avatar avatar center`}
                        onClick={(e) => {
                            setAvatar(value)
                            document.getElementById('avatar-profile').src = _image;
                        }}>
                        <img
                            alt={`avatar${i}`}
                            src={_image}
                        />
                    </button>

                </SwiperSlide>
            )
        }
        return items;
    }

    const cambiarIcono = () => {
        if (!!rutaEstudiante) {
            const valorInput = !avatar ? rutaEstudiante : avatar;
            cambiarAvatarEstudiante(valorInput);
        } else {
            const valorInput = !avatar ? datoIcono : avatar;
            cambiarAvatar({ icono: valorInput });
        }
        MicroModal.close(nombreModal, {
            awaitCloseAnimation: true
        });
    }
    return (
        <div role="alertdialog" aria-modal={"true"} className="micromodal-slide " id={nombreModal} aria-hidden="true" ref={microModal}>
            <div className="modal__overlay" data-micromodal-close>
                <div className="background-var3--bg modal__container_avatar max-h-screen-90 max-w-screen-90 lg:max-w-800 " role="dialog" aria-modal="true" aria-labelledby="modal-title">
                    <img
                        className={`avatar ${!!rutaEstudiante ? null : 'p-3'} img-80-center mx-auto mb-3 border-3 border-gris-linea `}
                        id="avatar-profile"
                        alt={datoIcono}
                        src={avatarModalImagen}
                    />
                    <h2 className="font-quicksand red-inv-var2--color text-center pb-2 text-30 font-semibold" id="modal-title">
                        {titulo}
                    </h2>
                    <p className="text-center quicksand-700 text-20 onbackground-var2--color w-11/12 mx-auto">
                        {descripcion}
                    </p>
                    <section className="my-8">
                        <div className="avatar-container p-3 my-4 relative background-var3--bg">
                            {
                                mostrarSwiper &&
                                <Swiper
                                    className="w-4/6 sm:w-9/12 lg:w-5/6"
                                    slidesPerView={1}
                                    slidesPerGroup={1}
                                    spaceBetween={20}
                                    navigation
                                    modules={[Pagination, Navigation]}
                                    pagination={{
                                        clickable: true,
                                        type: "bullets",
                                        dynamicBullets: true,
                                    }}
                                    breakpoints={{
                                        453: {
                                            slidesPerView: 2,
                                        },
                                        576: {
                                            slidesPerView: 3,
                                        },
                                        992: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {createAvatars()}
                                </Swiper>
                            }
                        </div>
                        <button
                            ref={refCambiarAvatar}
                            className="focusable-primary boton-justo boton-amarillo boton_hover_morado block text-22 mx-auto mt-3"
                            onClick={cambiarIcono}
                        >
                            {/* {preloader ? 
                                <div className="flex p-2">
                                    <span className="icon-spinner7 animate-spin text-20"></span>
                                </div>
                               : */}
                            {boton}
                        </button>
                    </section>
                </div>
            </div >
        </div >
    )
}

export default ModalCambiarIcono;

ModalCambiarIcono.propTypes = {
    /**
     * número que equivale al icono seleccionado
     */
    datoIcono: PropTypes.number,
    /**
     * Función que cambia el número del icono seleccionado
     */
    cambiarAvatar: PropTypes.func,
    /**
     * Función que cambia el número del icono seleccionado, en el perfil del estudiante
     */
    cambiarAvatarEstudiante: PropTypes.func,
    /**
     * strign que equivale al texto del botón 
     */
    boton: PropTypes.string,
    /**
     * string que corresponde al titulo de la ventana modal o emergete
     */
    titulo: PropTypes.string,
    /**
     * string que corresponde al texto descriptivo de la ventana modal
     */
    descripcion: PropTypes.string,
    /**
     * string que corresponde al nombre de la ventana modal
     */
    nombreModal: PropTypes.string,
    /**
     * Corresponde al número mínimo de iconos que se deben mostrar en el swiper
     */
    minIconos: PropTypes.number,
    /**
     * Corresponde al número máximo de iconos que se deben mostrar en el swiper
     */
    maxIconos: PropTypes.number,
    /**
     * string de la ruta de las imágenes del swiper
     */
    rutaCarpeta: PropTypes.string,
    /**
     * string de la ruta de las imágenes del swiper del perfil del estudiante. 
     */
    rutaEstudiante: PropTypes.string
}
