import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useElementoExterior from '../../../containers/helpers/hooks/useElementoExterior';

/**
 * Este componente devuelve el texto de cada seccion. Es usado en la pantallas de estadisticas
 * @param {*} props
 */
const SelectorCheck = (props) => {
  const { datosAlmacenados, opciones, textoDefault, estiloTipoInput, desactivado, actualizarElegidos, hayElementos, textoNoHayDatos, subposicion } = props

  const [alturaOpciones, setAlturaOpciones] = useState("0px");
  const [activadorSelect, setActivadorSelect] = useState(false);
  const contenedorOpciones = useRef(null);
  const selector = useRef(null);

  useEffect(() => {
    if (!!contenedorOpciones.current) {
      setAlturaOpciones(contenedorOpciones.current.scrollHeight);
    }
  }, [opciones])

  const cerrarOpcionesSelect = () => setActivadorSelect(false);

  useElementoExterior(cerrarOpcionesSelect, selector);

  const onKeyEnterOption = (e, opcion) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
      actualizarElegidos({ e, opcion })
    }
  }
  return (
    <>
      <div className={`selector ${subposicion ? 'z-40' : ''}`} ref={selector}>

        <button
          className={`${desactivado && 'input_disabled'} ${estiloTipoInput ? 'selector-elegido-input' : 'selector-elegido'} ${activadorSelect ? 'rounded-t-md' : 'rounded-md'} `}
          onClick={() => { setActivadorSelect(!activadorSelect) }}
        >
          <p className="una-linea-texto"> {textoDefault}</p>
          {<span className={`icon-select  ${activadorSelect ? "" : "transform rotate-180"} transicion-500 text-20`}></span>}
        </button>


        <ul
          ref={contenedorOpciones}
          role='listbox'
          aria-hidden={activadorSelect ? false : true}
          aria-expanded={activadorSelect ? true : false}
          className={`selector-opciones  max-h-80 scroll ${activadorSelect ? 'selector-opciones-contraste' : ''}`}
          style={{ height: activadorSelect ? `${alturaOpciones}px` : '0px' }}
        >

          {
            hayElementos ?
              <>
                {
                  opciones.lenght !== 0 && opciones.map((opcion, index) => {
                    const checked = datosAlmacenados?.find(dato => dato === opcion.id) ? true : false;

                    return (
                      <li
                        className="px-3 py-3 alineado-verticalmente cursor-pointer optionSelectorCheck "
                        key={`selectCheck-${index}`}
                      >
                        <label 
                          className=" w-full h-7 relative mr-3 cursor-pointer focusable-primary"
                          role='option'
                          aria-checked={checked}     
                          tabIndex={activadorSelect ? '0' : '-1'}         
                          aria-label={opcion.nombre}    
                          onKeyUp={(e) => { onKeyEnterOption(e, opcion) }}       
                        >
                          <input
                            checked={checked}
                            onChange={(e) => {
                              actualizarElegidos({ e, opcion })
                            }}
                            type="checkbox"
                            readOnly
                            tabIndex={-1} 
                          />
                          <i className="check"></i>
                          <p className="raleway-400 onsurface-var1--color absolute left-10 top-0.5">{opcion.nombre}</p>
                        </label>
                      </li>
                    )
                  })
                }
              </>
              : 
              <li
                tabIndex={activadorSelect ? '0' : undefined}
                aria-label={textoNoHayDatos} 
                className="px-3 py-3 optionSelectorCheck raleway-400 onsurface-var1--color">{textoNoHayDatos}</li>
          }
        </ul>

      </div>
    </>
  )
}
export default SelectorCheck;

SelectorCheck.propTypes = {
  /**
   * Arreglo de objetos. Aquí deben ir las opciones del select
   * @type[{
   *  id:string
   *  nombre:string
   * }]
   */
  opciones: PropTypes.array.isRequired,
  /**
   * callback utilizada para cuando se seleccione una opcion dentro de las diferentes opciones, esta actualiza la lista de opciones
   * @type{Function({ e, opcion }):void} - callback
   */
  actualizarElegidos: PropTypes.func.isRequired,
  /**
   * Arreglo que contiene los id de las opciones que se encuentran check. 
   * @type["id string"]
   */
  datosAlmacenados: PropTypes.array.isRequired,
  /**
   * String que indica la acción del select. Ej: elija un colegio
   */
  textoDefault: PropTypes.string.isRequired,
  /**
   * Booleano que cambia el estilo del select en caso de que se use en un formulario
   */
  estiloTipoInput: PropTypes.bool,
  /**
   * Booleano que cambia el estilo del select en caso de que se use en un formulario y este deba aparecer desactivado
   */
  desactivado: PropTypes.bool,
  /**
   * Booleano que indica si hay datos en la lista ed opciones
   */
  hayElementos: PropTypes.bool,
  /**
   * String. Este texto aparece cuando no hay elementos en el arreglo de opciones
   */
  textoNoHayDatos: PropTypes.string,
}
