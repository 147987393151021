import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
//Store
import { useDispatch } from '../../store';
//Hooks personalizados
import useInitialDataOfSection from './../helpers/useInitiailDataOfSection';
//Componentes
import EstudiantesGeneral from "./EstudiantesGeneral";
import EstudiantesEditarEliminar from "./EstudiantesEditarEliminar";
import EstudiantesCrear from "./EstudiantesCrear";
import EstudiantesVerificar from './EstudiantesVerificar';
import EstudiantesEditarNuevo from './EstudiantesEditarNuevo';
import Error_404 from '../Error_404';


/**
 * Container principal del módulo de estudiantes, se función es renderizar los diferentes containers con base a sus correspondientes rutas de navegación, por otro lado se encarga de la lógica para borrar datos temporales de la creación de estudiantes y también de descartar cambios de actualización o creación de datos.
 */
const Estudiantes = () => {
  //Estados globales
  const dispatch = useDispatch();

  const { isLoading: isLoadingCursos } = useInitialDataOfSection({ pathname: '/cursos/obtenerCursos', property: 'datosCursos', section: 'cursos', propiedadJson: 'profesores' });
  const { isLoading: isLoadingEstudiantes } = useInitialDataOfSection({ pathname: '/estudiantes/obtenerListadoEstudiantes', property: 'datosEstudiantes', section: 'estudiantes' })

  const history = useHistory();

  useEffect(() => {
    return () => borrarDatosTemporales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Descartar cambios
  const descartarCambios = async () => {
    borrarDatosTemporales();
    history.push('/estudiantes');
  }


  const borrarDatosTemporales = async () => {
    let dataTemporal = {};
    await dispatch({
      type: "SET_DATA",
      property: "temporal",
      value: dataTemporal
    });
  }


  return (
    <>
      <Switch>
        <Route exact path="/estudiantes">
          <EstudiantesGeneral
            isLoadingCursos={isLoadingCursos}
            isLoadingEstudiantes={isLoadingEstudiantes}
          />
        </Route>

        <Route exact path="/estudiantes/crear-estudiantes">
          <EstudiantesCrear
            isLoadingCursos={isLoadingCursos}
            descartarCambios={descartarCambios}
          />
        </Route>

        <Route exact path="/estudiantes/crear-estudiantes/verificar-estudiantes">
          <EstudiantesVerificar
            isLoadingCursos={isLoadingCursos}
            descartarCambios={descartarCambios}
          />
        </Route>

        <Route exact path="/estudiantes/crear-estudiantes/verificar-estudiantes/:estudiante_nuevo">
          <EstudiantesEditarNuevo
            isLoadingCursos={isLoadingCursos}
            descartarCambios={descartarCambios}
          />
        </Route>


        <Route exact path="/estudiantes/editar-estudiantes">
          <EstudiantesEditarEliminar
            isLoadingCursos={isLoadingCursos}
            isLoadingEstudiantes={isLoadingEstudiantes}
          />
        </Route>

        <Route exact path="/estudiantes/editar-estudiantes/:sede">
          <EstudiantesEditarEliminar
            isLoadingCursos={isLoadingCursos}
            isLoadingEstudiantes={isLoadingEstudiantes}
          />
        </Route>

        <Route exact path="/estudiantes/editar-estudiantes/:sede/:curso">
          <EstudiantesEditarEliminar
            isLoadingCursos={isLoadingCursos}
            isLoadingEstudiantes={isLoadingEstudiantes}
          />
        </Route>

        <Route exact path="/estudiantes/editar-estudiantes/:sede/:curso/:estudiante">
          <EstudiantesEditarEliminar
            isLoadingCursos={isLoadingCursos}
            isLoadingEstudiantes={isLoadingEstudiantes}
          />
        </Route>

        <Route component={Error_404} />
      </Switch>
    </>
  )
}
export default Estudiantes;