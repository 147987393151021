import { useRef, useState, useEffect, useContext } from "react";
import { store } from 'react-notifications-component';
import { Link } from "react-router-dom";
import MicroModal from "micromodal";

/**componentes */
import SelectorDeBusqueda from "../globales/elementosFormularios/SelectorDeBusqueda";
import ResultadosListaEstudiantes from "./ResultadosListaEstudiantes";
import NoHayResultados from "../globales/NoHayResultados";
import VentanaAlerta from "../globales/VentanaAlerta";
import Modal from "../globales/modales/Modal";
import ModalConfirmacion from "../globales/modales/ModalConfirmacion";
import { useTrackedState } from "../../store";
import { Miga, MigasDePan } from "../globales/MigaDePan";
import { AccesibilidadContext } from '../../contexts/AccesibilidadContext';

const EstudiantesGeneralComponent = (props) => {
  const { borrarFiltros, buscarEstudiantes, cambiarCurso, cambiarEstudiantesElegidos, cambiarSede, cancelarAccionMultiSelect, cursosSede, datosMultiSelect, descargarDatos, eliminarEstudiantes, filtros, isErrorSede, moverEstudiantes, numeroEstudiantesElegidos, preloader, primerMultiselect, resultadosBusqueda, respuestaApiEliminar, respuestaApiMover, sedesInstituto, textosInterfaz } = props;

  const { vista_general, miga_de_pan, botones, multi_accion, modal_mover, modal_exito, modal_eliminar, no_existe_sede } = textosInterfaz;
  const { rector, profesor,header, select, busqueda } = vista_general;

  const state = useTrackedState();
  const rolUsuario = state?.planActivo?.tipoUsuario || 'null';
  /**estados */
  const [modalMover, setModalMover] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const accesibilidad = useContext(AccesibilidadContext);

  //Referencias
  const inputBuscador = useRef(null)

  /**useEffect */

  /**estos useEfeect sirven para que cuando cambie el estado de cada ventana modal se abrna de una vez sin necesidad de dar 2 veces click sobre sus correspondientes botones */
  useEffect(() => {
    if (modalMover) {
      MicroModal.show('estas-seguro-mover-estudiantes', {
        awaitCloseAnimation: true
      });
    }
  }, [modalMover]);

  useEffect(() => {
    if (modalEliminar) {
      MicroModal.show('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
    }
  }, [modalEliminar]);

  useEffect(() => {
    if (respuestaApiMover.isError === false) {
      MicroModal.close('estas-seguro-mover-estudiantes', {
        awaitCloseAnimation: true
      });
      MicroModal.show('estudiante-movido', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaApiMover.isError === true) {
      MicroModal.close('estas-seguro-mover-estudiantes', {
        awaitCloseAnimation: true
      });

      notificacion(textosInterfaz.notificacion.error, respuestaApiMover.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiMover.isError])

  useEffect(() => {
    if (respuestaApiEliminar.isError === false) {
      MicroModal.close('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
      MicroModal.show('estudiante-eliminado', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaApiEliminar.isError === true) {
      MicroModal.close('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });

      notificacion(textosInterfaz.notificacion.error, respuestaApiEliminar.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiEliminar.isError])

  /**FUNCIONES */
  /**Cambia  el texto seleccionado del Selector de busqueda*/
  const cambiarOpcionInstituto = ({ opcion }) => {
    cambiarSede({ idSede: opcion.id, value: inputBuscador.current.value, tipoAccion: 'filtros' })
  };


  const cambiarOpcionCurso = ({ opcion }) => {
    cambiarCurso({ idCurso: opcion.id, idSede: opcion.idSede, value: inputBuscador.current.value, tipoAccion: 'filtros' })
  };


  const cambiarSedeMultiSelect = ({ opcion }) => {
    cambiarSede({ idSede: opcion.id, value: inputBuscador.current.value, tipoAccion: 'multiselect' });
  };


  const cambiarCursoMultiSelect = ({ opcion }) => {
    cambiarCurso({ idCurso: opcion.id, idSede: opcion.idSede, value: inputBuscador.current.value, tipoAccion: 'multiselect' })
  };

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  const vertodasLasSedes = () => cambiarSede({ idSede: null, tipoAccion: 'ver todo' })
  const vertodasLosCursos = () => cambiarSede({ idSede: filtros.sede.id, value: inputBuscador.current.value, tipoAccion: 'filtros' })

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Estudiantes'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales alineado-verticalmente">
          {!!filtros.sede.id || !!filtros.curso.id ? `${header.titulo.filtro} ` : header.titulo.sin_filtro}
          {!!filtros.curso.id && filtros.curso.nombre}
          {!!filtros.sede.id && !!filtros.curso.id && ' - '}
          {!!filtros.sede.id && filtros.sede.nombre}</h1>
        <div className="md:flex justify-between items-center gap-5 py-3">
        <div className={`${rolUsuario==='profesor'?'w-full ':'md:w-7/12'}`}>
            <p className="informacion-principal">{
              `${
                rolUsuario === 'rector'?`${rector?.header?.descripcion 
                  || 'Filtra por colegio (sede) y curso para ver los estudiantes asociados'}`
                :`${profesor?.header?.descripcion 
                  || 'Filtra por cursos para ver los estudiantes asociados'}`
              }`
            }</p>
          </div>
        </div>
        <div className="md:flex justify-between mt-3 gap-4">
          {
            rolUsuario === 'rector' &&
            <div className="md:w-4/12 xl:w-3/12">
              <SelectorDeBusqueda
                cambiarElegido={cambiarOpcionInstituto}
                filtroMensajeNoexite={select.sede.no_existe}
                opciones={sedesInstituto}
                superposicion={true}
                textoDefault={select.sede.default}
                textoElegido={!!filtros.sede.id ? filtros.sede.nombre : select.sede.ver_todo}
                verTodasLasOpciones={{
                  textos: select.sede.ver_todo,
                  alVertodasLasOpciones: vertodasLasSedes,
                }}
              />

            </div>
          }

          <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0 z-60">
            <SelectorDeBusqueda
              cambiarElegido={cambiarOpcionCurso}
              filtroMensajeNoexite={select.curso.no_existe}
              opciones={cursosSede.resultados}
              textoDefault={select.curso.default}
              textoElegido={
                cursosSede.hayResultados
                  ? !!filtros.curso.id ? filtros.curso.nombre : null
                  : null
              }
              textoNoHayOpciones={select.curso.no_hay}
              subposicion={true}
              verTodasLasOpciones={{
                textos: select.curso.ver_todo,
                alVertodasLasOpciones: vertodasLosCursos,
              }}
            />
          </div>

          <div className="md:w-4/12 xl:w-451p mt-6 md:mt-0">
            <div className="relative w-full h-full">
              <input
                className="w-full"
                placeholder={busqueda.placeholder}
                onChange={() => buscarEstudiantes({ value: !!inputBuscador.current ? inputBuscador.current.value : '' })}
                type="text"
                ref={inputBuscador}
              />

              <button className="absolute right-0 top-0 w-10 h-full focusable-primary">
                <span className="icon-lupa onsurface-var1--color"></span>
              </button>
            </div>
          </div>
        </div>

        <div className="md:flex justify-between  gap-4 py-5 mb-5 border-b-2 onsurface-var2--border-color">
          {/*borrar filtros */}
          <div className="md:w-4/12  xl:w-451p md:order-3 xl:alineado-izquierda">
            {/*  <button
              className="boton-pequenio boton-rojo boton_hover_morado justify-self-end  md:col-span-2 xl:col-auto "
              onClick={() => borrarFiltros({ refInput: inputBuscador.current })}
            >
              {botones.filtros.borrar}
            </button> */}
          </div>

          {/* agregar profesor */}
          {
            rolUsuario === 'rector'&&
            <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0 md:order-1">
            <Link to={'/estudiantes/crear-estudiantes'} className="w-full h-full boton-justo boton-amarillo boton_hover_morado center focusable-primary">
              {botones.agregar}
              <span className="icon-mas ml-3  lg:hidden xl:block"></span>
            </Link>
          </div>
          }

          {/*descargar datos */}
          <div className="md:w-4/12 xl:w-3/12 mt-6 md:mt-0 md:order-2 ">
            <button
              className="  w-full h-full boton-justo boton-amarillo boton_hover_morado center focusable-primary"
              disabled={resultadosBusqueda.resultados.length === 0}
              onClick={descargarDatos}
            >
              {botones.descargar.lista}
              <span className="icon-descargar ml-3 lg:hidden xl:block"></span>
            </button>
          </div>
        </div>

        {(() => {
          if (!isErrorSede) {
            if (resultadosBusqueda.hayResultados) {
              return (
                <ResultadosListaEstudiantes
                  cambiarEstudiantesElegidos={cambiarEstudiantesElegidos}
                  datosEstudiantes={resultadosBusqueda.resultados}
                  preloader={preloader}
                  textosTabla={vista_general.tabla}
                  textoBotonEditar={botones.editar}
                />
              )
            } else {
              return (
                <NoHayResultados
                  descripcion={vista_general.no_hay_resultados.descripcion}
                  titulo={vista_general.no_hay_resultados.titulo}
                />
              )

            }
          } else {
            return (
              <VentanaAlerta
                textosInterfaz={{
                  titulo: no_existe_sede.titulo,
                  descripcion: no_existe_sede.descripcion,
                }}
                imagen={{
                  alt: 'blop con un icono de alerta',
                  img: 'alertas/blop-alerta.png',
                }}
              >
                <button
                  className="boton-pequenio mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado"
                  onClick={() => borrarFiltros({ refInput: inputBuscador.current })}
                >
                  {botones.filtros.borrar}
                </button>
              </VentanaAlerta>
            )
          }
        })()}

        {/* barra de seleccionados */}
        {primerMultiselect &&
          <div
            role="region"
            aria-label={`acción de ${numeroEstudiantesElegidos !== 1 ? multi_accion.estudiantes.singular : multi_accion.estudiantes.plural}`}
            className={`${accesibilidad.temaActual !== "clasico" && 'border-b-2 onbackground-var1--border-color'} multiselector fixed left-0 primary-var2--bg w-full py-4 transicion-200 ${primerMultiselect ? `animate-slideIn -top-0` : ` animate-slideOut -top-72`} `}>
            <div className='lg:flex'>
              <div className="lg:w-4/12  alineado-verticalmente pl-5 xl:pl-10">
                <button aria-label="desactivar seleccionados" className='onprimary-var1--color text-20 mr-3 hover:text-amarillo focusable-red' onClick={cancelarAccionMultiSelect}>
                  <span className='icon-cerrar'></span>
                </button>
                <p className='q7-20-blanco'>
                  {numeroEstudiantesElegidos} {numeroEstudiantesElegidos !== 1 ? multi_accion.estudiantes.singular : multi_accion.estudiantes.plural}
                </p>
              </div>
              <div className="lg:w-8/12 md:alineado-verticalmente lg:alineado-izquierda-center gap-4 px-5 xl:px-0 mt-5 lg:mt-0">
                {
                  rolUsuario === 'rector' &&
                  <div className=' md:w-4/12 lg:w-60'>
                    <SelectorDeBusqueda
                      cambiarElegido={cambiarSedeMultiSelect}
                      estiloMultiAction={true}
                      opciones={sedesInstituto}
                      superposicion={true}
                      textoDefault={multi_accion.cambiar_sede}
                      textoElegido={!!datosMultiSelect.idSede ? datosMultiSelect.nombreSede : null}
                      verTodasLasOpciones={true}
                    />
                  </div>
                }

              <div className='md:w-4/12 lg:w-60 mt-3 md:mt-0 '>
                <SelectorDeBusqueda
                  cambiarElegido={cambiarCursoMultiSelect}
                  estiloMultiAction={true}
                  opciones={datosMultiSelect.cursos.resultados}
                  textoDefault={multi_accion.curso.titulo}
                  textoElegido={
                    datosMultiSelect.cursos.hayResultados
                      ? !!datosMultiSelect.idCurso ? datosMultiSelect.nombreCurso : null
                      : null
                  }
                  textoNoHayOpciones={select.curso.no_hay}
                />
              </div>
              <button
                className={`focusable-red md:w-4/12 lg:w-auto quicksand-700 text-17.6 center pt-3 md:pt-0 ${datosMultiSelect.idSede !== null && datosMultiSelect.idCurso !== null ? 'onprimary-var1--color hover:text-amarillo' :`bg-transparent cursor-default ${accesibilidad.temaActual === "clasico" ? 'text-gris-alfa' : 'ondisabled-var1--color'}` }`}
                onClick={() => {
                  if (datosMultiSelect.idSede !== null && datosMultiSelect.idCurso !== null) {
                    if (modalMover) {
                      MicroModal.show('estas-seguro-mover-estudiantes', {
                        awaitCloseAnimation: true
                      });
                    } else {
                      setModalMover(true)
                    }
                  }
                }}
              >
                <span className='icon-mover mr-1 lg:hidden xl:block'></span>
                {botones.mover}
              </button>

              <button
                  className="focusable-red md:w-4/12 lg:w-auto mr-10 onprimary-var1--color hover:text-amarillo quicksand-700 text-17.6 center pt-3 md:pt-0"
                  onClick={() => {
                    if (modalEliminar) {
                      MicroModal.show('estas-seguro-eliminar', {
                        awaitCloseAnimation: true
                      });
                    } else {
                      setModalEliminar(true)
                    }
                  }}
                >
                  <span className="icon-basura mr-1 lg:hidden xl:block"></span>
                  {numeroEstudiantesElegidos !== 1 ? botones.eliminar.plural : botones.eliminar.singular}
                </button>
              </div>
            </div>
          </div>
        }

      </div>

      {/* Modal pregunta si desea mover estudiantes */}
      {modalMover &&
        <>
          <Modal nombreModal="estas-seguro-mover-estudiantes">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-pregunta.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_mover.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{modal_mover.descripcion_1} <span className="text-resaltado">{numeroEstudiantesElegidos} {numeroEstudiantesElegidos !== 1 ? header.num_estudiantes.masivos : header.num_estudiantes.individual}</span> {modal_mover.descripcion_2} <span className="text-resaltado">{datosMultiSelect.nombreSede} - {datosMultiSelect.nombreCurso}</span> </p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-mover-estudiantes`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button 
                className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" onClick={moverEstudiantes}>
                {preloader ?
                  <div className="center">
                    <span className="icon-spinner7 animate-spin text-20"></span>
                  </div>
                  :
                  <p>{botones.mover}</p>
                }
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion de estudiantes movidos */}
          <ModalConfirmacion nombreModal="estudiante-movido">
            <button
              aria-label="Cerrar ventana"
              className="icon-cerrar text-20 right-6 absolute focusable-primary onbackground-var1--color"
              onClick={() => {
                MicroModal.close(`estudiante-movido`, {
                  awaitCloseAnimation: true
                });
              }}>
            </button>

            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center "> {modal_exito.mover.titulo}</h1>

            <p className="text-center r7-16-negro26 mb-4">
              {numeroEstudiantesElegidos !== 1 ? modal_exito.mover.descripcion.masivo : modal_exito.mover.descripcion.individual}
            </p>
          </ModalConfirmacion>
        </>
      }

      {/* Modal pregunta si desea eliminar estudiantes */}
      {modalEliminar &&
        <>
          <Modal nombreModal="estas-seguro-eliminar">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_eliminar.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{modal_eliminar.descripcion_1} <span className="text-resaltado">{numeroEstudiantesElegidos} {numeroEstudiantesElegidos !== 1 ? header.num_estudiantes.masivos : header.num_estudiantes.individual} </span> {modal_eliminar.descripcion_2}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                aria-label="Cancelar"
                onClick={() => {
                  MicroModal.close(`estas-seguro-eliminar`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button 
                className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" 
                onClick={eliminarEstudiantes}
              >
                {preloader ?
                  <div className="center">
                    <span className="icon-spinner7 animate-spin text-20"></span>
                  </div>
                  :
                  <p>{numeroEstudiantesElegidos !== 1 ? botones.eliminar.plural : botones.eliminar.singular}</p>
                }
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion de estudiantes eliminados*/}
          <ModalConfirmacion nombreModal="estudiante-eliminado">
            <button
              aria-label="Cerrar ventana"
              className="icon-cerrar text-20  right-6 absolute onbackground-var1--color focusable-primary"
              onClick={() => {
                MicroModal.close(`estudiante-eliminado`, {
                  awaitCloseAnimation: true
                });
              }}>
            </button>

            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center "> {modal_exito.eliminar.titulo} </h1>

            <p className="text-center r7-16-negro26 mb-4">
              {numeroEstudiantesElegidos !== 1 ? modal_exito.eliminar.descripcion.masivo : modal_exito.eliminar.descripcion.individual}
            </p>
          </ModalConfirmacion>
        </>
      }
    </>
  )
}
export default EstudiantesGeneralComponent;