import { useState, useEffect } from 'react';
//Data
import DataTextos from "../../../modules/dataTextos.json";

/**
 * Retorna un objeto con los textos que se encuentran en el archivo de excel
 * @param {string} seccion - Sección (pestaña) del archivo de excel  que se desea obtener los textos.
 * @param {*} isArray - Si la sección es un array.
 * @returns {object} - Objeto con los textos de la sección especificada.
 */
const useObtenerSubseccionesIdiomas = (seccion, isArray) => {
  //Estados del componente
  const [textosSubSecciones, setTextosSubSecciones] = useState(undefined);
  const [isReady, setIsReady] = useState(false);


  useEffect(() => {
    const obetenerTraducciones = () => {
      const seccionEspecifica = DataTextos[seccion];
      let subseccion = {
        seccion: false,
        subseccion: false
      };
      let infoSeccionIidioma = {};

      const idiomasDisponibles = process.env.REACT_APP_IDIOMAS_DISPONIBLES.split(',');

      idiomasDisponibles.forEach(idioma => {
        let infoSeccion = {};
        seccionEspecifica.forEach((item, index) => {
          //Para recursos que tienen secciones
          if (!!item[idioma]) {
            if (!!item.seccion) {
              infoSeccion[item.seccion] = { ...infoSeccion[item.seccion] };
              subseccion[seccion] = true;
              //Para recursos con subsecciones              const obligatorio = {obligatorio : item.obligatorio !== 'null' ? item.obligatorio === 'TRUE' ? true : false : 'null'};
              const obligatorio = { obligatorio: item.obligatorio !== 'null' ? item.obligatorio === 'TRUE' ? true : false : 'null' };

              if (item.subseccion !== 'null' && !!item.subseccion) {
                infoSeccion[item.seccion][item.subseccion] = { ...infoSeccion[item.seccion][item.subseccion] };
                item.item !== 'null'
                  ? infoSeccion[item.seccion][item.subseccion][item.item] = { ...infoSeccion[item.seccion][item.subseccion][item.item], [item.id]: { campo: item[idioma], ...obligatorio } }
                  : infoSeccion[item.seccion][item.subseccion] = { ...infoSeccion[item.seccion][item.subseccion], [item.id]: { campo: item[idioma], ...obligatorio } };

                subseccion[subseccion] = true;
              } else {

                item.item !== 'null'
                  ? infoSeccion[item.seccion][item.item] = { ...infoSeccion[item.seccion][item.item], [item.id]: { campo: item[idioma], ...obligatorio } }
                  : infoSeccion[item.seccion] = { ...infoSeccion[item.seccion], [item.id]: { campo: item[idioma], ...obligatorio } };
              }
            }
            //Para recursos que no tienen subsecciones
            else itemsRecurso(seccionEspecifica, infoSeccion, infoSeccionIidioma, idioma)
          }
        })
        
        if(Object.keys(infoSeccion).length !== 0) infoSeccionIidioma[idioma] = infoSeccion
      })

      setIsReady(true);
      setTextosSubSecciones(infoSeccionIidioma);
    }

    obetenerTraducciones()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seccion])


  //Recursos que no tienen subsecciones internas
  const itemsRecurso = (array, infoSeccion, infoSeccionIidioma, idioma) => {
    array.forEach(item => {
      if (infoSeccion[item.item]) return infoSeccion[item.item] = { ...infoSeccion[item.item], [item.id]: item[idioma] }
      infoSeccion[item.item] = { [item.id]: item[idioma] }
    })

    infoSeccionIidioma[idioma] = infoSeccion
  }

  return { isReady, textosSubSecciones }
}

export default useObtenerSubseccionesIdiomas;
