import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { store as notificationStore } from 'react-notifications-component';
import PropTypes from 'prop-types';

/**componentes */
import InputText from "../globales/elementosFormularios/InputText";
import { useTrackedState } from "../../store";
import useObtenerSubsecciones from "../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Este componente recibe una serie de props que le permiten renderizar el formulario de acceso para los docentes. Además, renderiza el componente InputText.
 * @returns InicioDocente
 */
const InicioDocente = (props) => {
  const {
    isError, textosDeInterfaz, actualizarPosicion, setMostrarError, fnIniciarSesion,
    usuario, setUsuario, mostrarAlertaDocente, setMostrarAlertaDocente,
    isLoading, contrasenaValido, actualizarValidez } = props;

  const { boton, tipo_usuario, otros_login } = textosDeInterfaz;
  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('login');
  const { docente } = tipo_usuario;

  const [valorCampo, setValorCampo] = useState(false);

  const contrasena = useRef(null);
  const [submitPresionado, setSubmitPresionado] = useState(false);
  const state = useTrackedState();
  const usuarioActual = state?.datosDeUsuario;

  useEffect(() => {
    actualizarValidez(valorCampo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorCampo])


  useEffect(() => {
    // muestra notificaciones y mensajes de error o exito.
    const mostrarMensajes = (errores) => {
      let opcionesNotificacion =
      {
        id: 'notificaciones',
        title: '...',
        message: '...',
        type: 'info',
        insert: "top-right",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000
        }
      }

      if (submitPresionado) {
        if (Boolean(isError?.hayError)) {
          setMostrarAlertaDocente(true);
          setMostrarError(true);
          if(isError.tipoError==='suscripcion-caducada') return
          if (isError?.tipoError !== 'campos_vacios') {
            opcionesNotificacion = {
              ...opcionesNotificacion,
              ...{
                message: traducciones?.notificacion?.error?.autenticacion?.descripcion
                  || 'No se ha podido iniciar sesión, el correo o la contraseña no son validos.',
                title: traducciones?.notificacion?.error?.autenticacion?.titulo
                  || 'Error de autenticación',
                type: 'danger',
              }
            }
          } else {
            opcionesNotificacion = {
              ...opcionesNotificacion,
              ...{
                message: traducciones?.notificacion?.error?.campos_vacios?.descripcion
                  || 'Los campos no pueden estar vacíos.',
                title: traducciones?.notificacion?.error?.campos_vacios?.titulo
                  || 'Campos vacios',
                type: 'danger',
              }
            }
          }

          notificationStore.addNotification(opcionesNotificacion);

        }
        else {
          if (usuario?.campo?.trim().length > 0) {
            setMostrarError(false);
            setMostrarAlertaDocente(false);
            opcionesNotificacion = {
              ...opcionesNotificacion,
              ...{
                message: `${traducciones?.notificacion?.exito?.autenticacion?.descripcion || 'Bienvenido'} ${usuarioActual?.correo || usuario?.campo || 'N/A'}`,
                title: traducciones?.notificacion?.exito?.autenticacion?.titulo || 'Autenticación exitosa',
                type: 'success',
              }
            }

            notificationStore.addNotification(opcionesNotificacion);
          }
        }

      }

    }
    mostrarMensajes();
  }, [submitPresionado, isError])

  /**Valida si el capo esta vacio. Si el campo es mayot a 4 caracteres es true, sino es false */
  const validarCampo = () => {
    const valorCampoActual = contrasena.current.value.length > 0 ? true : false;
    setValorCampo(valorCampoActual);
  }

  return (
    <>
      <div className={'background-var2--bg onbackground-var1--color'}>
        <button
          className='focusable-primary alineado-verticalmente cursor-pointer mb-5'
          onClick={() => { actualizarPosicion() }}
        >
          <span className='icon-atras text-20 mr-3'></span>
          <p className='q7-20'>{boton.volver}</p>
        </button>
        <p className='q7-28'>{docente.titulo}</p>

        {mostrarAlertaDocente && isError.tipoError !== "estudiante_incorrecto" &&
          <p className='q7-20-rojo'>{tipo_usuario.error[isError.tipoError]} </p>
        }
        <form onSubmit={async (e) => {
          setMostrarAlertaDocente(false);
          setMostrarError(false);
          await fnIniciarSesion({ e: e, correo: usuario.campo, contrasena: contrasena.current.value })
          setSubmitPresionado(true);
        }}>
          <label className='q7-20-morado block  my-3'>{docente.usuario.titulo}:</label>
          <InputText
            cambiarEstado={setUsuario}
            estado={usuario}
            estadoEnviar={mostrarAlertaDocente}
            identidicador="usuario"
            mensajeError={!usuario.valido && docente.usuario.error}
            placeholder={docente.usuario.placeholder}
            typeInput='email'
            validarExpresion={/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/}
          />

          <label className='q7-20-morado block my-3'>{docente.contrasena.titulo}:</label>
          <input
            type="password"
            name={'password'}
            ref={contrasena}
            className={`w-full input-password_focus ${mostrarAlertaDocente ? !contrasenaValido && 'input_error' : null}`}
            onBlur={() => { validarCampo() }}
            onKeyUp={() => { validarCampo() }}
            placeholder={docente.contrasena.placeholder}
            autoComplete="off"
          />

          {mostrarAlertaDocente &&

            <p className={`text-14 ${!contrasenaValido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`}>
              {docente.contrasena.error}
            </p>
          }

          <div className='sm:alineado-verticalmente justify-between py-8'>
            <div>
              <Link to='/login/recuperar-contrasena' className='focusable-primary onsurface-var1--color q7-18 underline'>{boton.olvidar_contrasena}</Link>
            </div>

            <button
              className='focusable-red boton-justo boton-amarillo  q7-20 px-8 mt-5 sm:mt-0'
            >
              {isLoading ?
                <div className="center text-center mx-">
                  <span className="text-center icon-spinner7 animate-spin text-20 p-1"></span>
                </div>
                :
                boton.iniciar_sesion
              }
            </button>
          </div>
        </form>

        {/* <div className='bg-verder border-t border-gris-linea py-10'>
          <button className='w-full md:w-10/12 mx-auto boton-justo boton-rojo-google  center mb-5 boton_hover_escalar'>
            <span className='icon-google mr-3'></span>
            {otros_login.google}
          </button>

          <button className='w-full md:w-10/12 mx-auto boton-justo boton-azul-microsoft center boton_hover_escalar'>
            <span className='icon-microsoft mr-3 text-20'></span>
            {otros_login.microsoft}
          </button>
        </div> */}
      </div>
    </>
  )


}
export default InicioDocente;

InicioDocente.propTypes = {
  /**
    * object que indica si el API de login respondio con un error.
    */
  isError: PropTypes.object.isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
   textosInterfaz: PropTypes.object,
  /**
   * Funcion que actualiza la posición del contenido a 0 para reubicarlos. 
   */
  actualizarPosicion: PropTypes.func.isRequired,
  /**
   * Función que actualiza el estado para mostrar el error al iniciar sesión
   */
  setMostrarError: PropTypes.func.isRequired,
  /**
   * Es una función que se encarga de llamar el API de login y actualizar los datos del profesor o rector en el storage.
   * @type{Function({ e : event, correo:string, contrasena:string }) : void} - callback
   */
  fnIniciarSesion: PropTypes.func.isRequired,
  /**
   * Objeto que contiene el valor y la validacion del input usuario 
   */
  usuario: PropTypes.shape({
    campo: PropTypes.string,
    valido: PropTypes.bool
  }),
  /**
   * Función que actualiza el estado de usuario
   */
  setUsuario: PropTypes.func.isRequired,
  /**
   * Booleano que indica si los campos de inicio de sesión estan mal diligenciados. 
   */
  mostrarAlertaDocente: PropTypes.bool.isRequired,
  /**
   * Función que actualiza el estado de mostrarAlertaDocente
   */
  setMostrarAlertaDocente: PropTypes.func.isRequired,
  /**
   * booleano que indica que el api no ha terminado de responder. 
   */
   isLoading: PropTypes.bool.isRequired,
  /**
   * Boleano que indica si la contraseña es valida. 
   */
  contrasenaValido: PropTypes.bool.isRequired,
  /**
   * esta funcion actualiza el estado contrasenaValido
   * @type{Function(valor : bool):void}
   */
  actualizarValidez: PropTypes.func.isRequired
}