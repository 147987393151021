//Hooks y funciones personalizadas
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';

import IngresosEstudiantesComponent from '../../components/estadisticas/IngresosIndividuales/IngresosEstudiantes/IngresosEstudiantes';
import SkeletonIngresosIndividuales from '../../components/estadisticas/SkeletonsEstadisticas/SkeletonIngresosIndividuales';
import { Redirect, Route, Switch } from 'react-router-dom';
import IngresosProfesoresComponent from '../../components/estadisticas/IngresosIndividuales/IngresosProfesores/IngresosProfesores';

const IngresosIndividuales = () => {

  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
    <>
      <Switch>
        <Redirect exact from='/estadisticas/ingresos-individuales' to="/estadisticas/ingresos-individuales/estudiantes" />

        <Route exact path="/estadisticas/ingresos-individuales/profesores">
          {
            isReady ?
              <IngresosProfesoresComponent traducciones={textosInterfaz}/>
              :
              <SkeletonIngresosIndividuales />
          }
        </Route>
        <Route exact path="/estadisticas/ingresos-individuales/estudiantes">
          {
            isReady ?
              <IngresosEstudiantesComponent traducciones={textosInterfaz}/>
              :
              <SkeletonIngresosIndividuales />
          }
        </Route>
      </Switch>
    </>
  )
}
export default IngresosIndividuales;