import { useState, useEffect } from "react";
import MicroModal from "micromodal";

/**componente */
import Modal from "../../../globales/modales/Modal";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import SelectorDeBusqueda from "../../../globales/elementosFormularios/SelectorDeBusqueda";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
/**
    Este componente muestra la lista de los cursos existentes en el instituto y a sus estudiantes, permitiendo recomendar un libro, una vez que el libro se recomienda a al menos un estudiante de un curso, el curso no aparecera en este modal y solo se podrá editar haciendo click en el curso en la sección de recomendación en laficha del libro.
 */
export const ModalCursos = (props) => {
	const { visible, setVisible,  idModal, recommendedManager } =
		props;
	const { isReady, textosSubSecciones: traducciones } = useObtenerSubsecciones("libros");
	const [students, setStudents] = useState([]);
	const [cursoSeleccionado, setCursoSeleccionado] = useState();
	const opcionTodos = {
		id: "todos",
		nombre: traducciones?.ficha?.recomendar?.opciones?.recomendar_todos || "Recomendar a todos",
	};
	const [cargando, setCargando] = useState({
		estudiantes: false,
		guardando: false,
	});

	const classes = recommendedManager?.allClasses;

	const sortedClasses = () => {
		let ordenados = [...classes].sort((a, b) => {
			if (a?.nombre < b?.nombre) {
				return -1;
			}
			if (a?.nombre > b?.nombre) {
				return 1;
			}
			return 0;
		});
		return ordenados;
	};


	const cerrarModal = () => {
		MicroModal.close(idModal);
	};

	const update = async () => {
		setCargando({ ...cargando, guardando: true });
		await recommendedManager.removeAllStudents({ classId: cursoSeleccionado?.id });
        const recommendedStudentsIds = students
            .filter((student) => student?.recommended)
            .map((student) => student?.codigo);
        await recommendedManager
			.addStudents({
				classId: cursoSeleccionado?.id,
				ids: recommendedStudentsIds,
			})
			.then((resultado) => {
				setVisible(false);
				cerrarModal();
				setCargando({ ...cargando, guardando: false });
			})
			.catch((error) => console.log(error));
	};

	const updateStudent = ({ studentId, recommended }) => {
		setStudents(
			students.map((student) => {
				if (studentId === "todos") {
					return {
						...student,
						recommended,
					};
				}
				if (studentId === student.id) {
					return {
						...student,
						recommended,
					};
				}
				return student;
			})
		);
	};
	const getStudentsByClass = ({ classId }) => {
		return recommendedManager.getClassStudents({ classId });
	};

	useEffect(() => {
		if (visible) {
			setCursoSeleccionado(undefined);
			setStudents([]);
		}
	}, [visible]);

	useEffect(() => {
		const cargarEstudiantes = async () => {
			const _students = getStudentsByClass({ classId: cursoSeleccionado?.id });
			setStudents(Array.isArray(_students) ? _students : []);
			setCargando({ ...cargando, estudiante: false });
		};
		cargarEstudiantes();
	}, [cursoSeleccionado]);

	return (
		<>
			<Modal
				nombreModal={idModal}
				alCerrarModal={() => {
					setVisible(false);
					cerrarModal();
				}}
			>
				<div className="pb-3">
					<h1 className="quicksand-700 onbackground-var2--color text-20 text-center ">
						{`${
							traducciones?.ficha?.recomendar?.modal?.titulo ||
							"Recomendar este libro a"
						}`}
					</h1>
					<SelectorDeBusqueda
						cambiarElegido={({ opcion }) => {
							setCursoSeleccionado(opcion);
						}}
						opciones={sortedClasses()}
						superposicion={true}
						textoDefault={`${
							traducciones?.ficha?.recomendar?.modal?.seleccion_curso ||
							"Selecciona un curso"
						}`}
						textoElegido={cursoSeleccionado?.nombre}
						alturaLista={{
							max: "180px",
						}}
					/>
					<div
						className="background-var3--bg scroll mt-5 h-52"
						style={{ minHeight: 110 }}
					>
						{cargando?.estudiantes == false ? (
							<>
								{students?.length > 0 ? (
									<ul>
										{[opcionTodos, ...students].map((estudiante) => {
											const { nombre, id } = estudiante;
											return (
												<li
													key={id}
													className="px-3 py-3 alineado-verticalmente cursor-pointer hover:primary-inv-var5--bg"
												>
													<label
														className=" w-full h-7 relative mr-3 cursor-pointer"
														role="checkbox"
													>
														<input
															type="checkbox"
															checked={estudiante?.recommended}
															onChange={(e) =>
																updateStudent({
																	studentId: estudiante.id,
																	recommended: e.target.checked,
																})
															}
														/>
														<i className="check"></i>
														<p className="raleway-400 onsurface-var1--color absolute left-10 top-0.5">
															{nombre}
														</p>
													</label>
												</li>
											);
										})}
									</ul>
								) : (
									<div className="min-w-full w-full h-full min-h-full flex center">
										{Boolean(cursoSeleccionado) == false ? (
											<h1 className=" font-bold font-quicksand text-center primary-inv-var2--color text-22">
												{`${
													traducciones?.ficha?.recomendar?.modal?.aviso ||
													"Selecciona un curso para ver los estudiantes"
												}`}
											</h1>
										) : (
											<h1 className="onbackground-var2--color font-bold font-quicksand text-center">
												{`${
													traducciones?.ficha?.recomendar?.modal
														?.sin_estudiantes ||
													"No hay estudiantes en este curso."
												}`}
											</h1>
										)}
									</div>
								)}
							</>
						) : (
							<div className="flex center min-w-full min-h-full">
								<Icon
									icon="fa:spinner"
									className="primary-inv-var2--color text-28 animate-spin"
								/>
							</div>
						)}
					</div>
				</div>
				<div className="flex center w-full">
					<button
						disabled={
							Boolean(cursoSeleccionado) == false || students.length <= 0
								? true
								: false
						}
						className="focusable-red boton-justo text-16 boton-amarillo boton_hover_morado alineado-verticalmente  flex  justify-between text-center"
						onClick={() => update()}
					>
						{cargando.guardando == true && (
							<Icon icon={`fa:spinner`} className={"text-22 animate-spin mx-1"} />
						)}
						{traducciones?.ficha?.recomendar.modal?.actualizar || "Actualizar"}
					</button>
				</div>
			</Modal>
		</>
	);
};

ModalCursos.propTypes = {
	/**
	 * propiedad booleana que defiene si el modal debe ser visible o no
	 */
	visible: PropTypes.bool,
	/**
	 * Función que modifica el estado de visibilidad del modal, recibe un parametro booleano.
	 */
	setVisible: PropTypes.func,
	/**
	 * instancia del hook del manejador de libros recomendados
	 */
	recommendedManager: PropTypes.object,
	/**
	 * id del modal
	 */
	idModal: PropTypes.string,
};
