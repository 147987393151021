

/**
 * retorna el texto sin acentos y en con todos los caracteres en minusculas.
 * @param {string} texto 
 * @returns {string} texto normalizado
 */
export const normalizeString = (texto = '')=>{
    return typeof texto === 'string'?
     texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
     :
     texto
}
