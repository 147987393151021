import { useTrackedState } from '../../store';
import PropTypes from 'prop-types';
import PaginaNoAutorizada from './PaginaNoAutorizada';


/**
 * Componente que recibe unos props necesarios para autorizar el contenido, teniendo encuenta el tipo de usuario. Este puede retornar el componente global PaginaNoAutorizada
 */
export const RutaProtegida = (props)=>{
    const { children, rolesPermitidos, validacionesAdicionales} = props;
    const state = useTrackedState();

    if((Array.isArray(rolesPermitidos) && rolesPermitidos.includes(state?.planActivo?.tipoUsuario)===false) || (validacionesAdicionales && validacionesAdicionales()===false)){
        return <PaginaNoAutorizada/>
    }else{
        return children;
    }

}

RutaProtegida.propTypes = {
    /**
     * Arreglo de string con los roles que permitidos para acceder a los componentes dentro del componente
     * @example
     *<RutaProtegida rolesPermitidos={['rector']}>
        <PerfilRector/> //solo tendra acceso a este componente el usuario que cumpla con los roles especificados, de no ser así se redirecciona a una pantalla de no autorizado.
     </RutaProtegida>
     */
    rolesPermitidos:PropTypes.arrayOf(PropTypes.string),
       /**
     * Funcion que retorna un booleano, si retorna true es valido, si retorna false no es valido y no tendra acceso a la ruta.
     */
     validacionesAdicionales:PropTypes.func
}