import { Route, Switch } from 'react-router-dom';
//Componentes
import CambiarContrasena from './CambiarContrasena';
import DatosUsuario from './DatosUsuario';

/**
 * Es el componente principal del módulo de miCuenta. Su función es renderizar el container correspondiente. Los containers que pueden ser renderizados son DatosUsuario y CambiarContrasena.
 */
const MiCuenta = () => {
  return (
    <Switch>
      <Route exact path="/mi-cuenta" component={DatosUsuario} />
      <Route exact path="/mi-cuenta/cambiar-contrasena" component={CambiarContrasena} />
    </Switch>
  )
}

export default MiCuenta;