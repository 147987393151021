import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MicroModal from "micromodal";
import PropTypes from "prop-types";

/*componentes */
import InputText from "../globales/elementosFormularios/InputText";
import Modal from "../globales/modales/Modal";

/**
 * Este componente recibe una serie de props que le permiten renderizar la pantalla de recuperar contraseña. Además, renderiza  los componentes InputText y Modal.
 * @returns RecuperarContrasenaComponent
 */
const RecuperarContrasenaComponent = (props) => {
  const { preloader, respuestaApi, recuperarContrasena, textosInterfaz } =
    props;
  const { advertencia, boton, correo, seccion, modal } = textosInterfaz;
  const { concurrencias, correo_incorrecto, exito, general } = modal;

  const [campoCorreo, setCampoCorreo] = useState({ campo: "", valido: null });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);

  useEffect(() => {
    if (respuestaApi.isError !== null) {
      MicroModal.show("respuesta-modal", {
        awaitCloseAnimation: true,
      });
    }
  }, [respuestaApi.isError]);

  return (
    <>
      <section
        className="py-12 w-full  pl-home"
        style={{ backgroundImage: `url(/images/background.png)` }}
      >
        <section className="container-login">
          <section className="lg:w-7/12 mx-auto px-5 lg:px-0 pt-5">
            <Link
              aria-label="volver a inicio"
              role="button"
              to="/login"
              className="boton-justo inline px-5 quicksand-700 focusable-primary center boton-amarillo boton_hover_morado text-20"
            >
              <span className="icon-anterior mr-3"></span>
              {boton.volver}
            </Link>

            <section className="onred-var1--bordered background-var2--bg rounded-md shadow-3 ">
              <section
                tabIndex={0}
                /* alt="Olvidé mi contraseña. Ingresa tu correo y te enviaremos instrucciones para recuperar tu contraseña"
                 */ className="focusable-primary mt-5 pt-16 px-5 lg:px-14"
              >
                <h1 className="q7-34 text-center onsecondary-var1--color ">
                  {seccion.titulo}
                </h1>
                <p className="q4-20 my-5 onsecondary-var1--color">
                  {seccion.descripcion}{" "}
                </p>

                <InputText
                  aria-label="escribe tu correo electrónico"
                  role="textbox"
                  cambiarEstado={setCampoCorreo}
                  estado={campoCorreo}
                  estadoEnviar={mostrarAlerta}
                  identidicador="correo"
                  mensajeError={!campoCorreo.valido && correo.error}
                  placeholder={correo.placeholder}
                  typeInput="email"
                  validarExpresion={
                    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
                  }
                />

                <button
                  tabIndex={0}
                  aria-label="enviar"
                  onClick={() => {
                    setMostrarAlerta(true);
                    if (campoCorreo.valido === true) {
                      recuperarContrasena({ correo: campoCorreo.campo });
                    }
                  }}
                  className="focusable-primary boton-justo boton-amarillo boton_hover_morado text-24 px-10 mt-5 mb-5 lg:mb-16 quicksand-700"
                >
                  {preloader ? (
                    <section className="center text-center mx-">
                      <span className="text-center icon-spinner7 animate-spin text-20 p-1"></span>
                    </section>
                  ) : (
                    boton.enviar
                  )}
                </button>
              </section>

              <section
                tabIndex={0}
                role="article"
                className="focusable-primary icono-null rounded-b-md py-10 px-14 lg:flex"
              >
                <span className="icon-advertencia text-44 onsurface-var1--color"></span>

                <p className="lg:ml-5 mt-5 lg:mt-0 q4-20 onsecondary-var1--color">
                  {advertencia.informacion}{" "}
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>

      {/* Modal error archivo*/}
      {respuestaApi.isError !== null && (
        <Modal nombreModal="respuesta-modal">
          <section className="border-b pb-3">
            {respuestaApi.tipoAdvertencia !== "error" ? (
              <section className="rounded-full w-12 h-12 center green-inv-var1--bg mx-auto">
                <span
                  className={`icon-carita-redonda onred-var1--color   text-40 text-center block mb-0`}
                ></span>
              </section>
            ) : (
              <span
                className={`icon-alerta red-inv-var2--color text-40 text-center block mb-0`}
              ></span>
            )}

            <h2
              className={`q7-28 text-center ${
                respuestaApi.tipoAdvertencia !== "error"
                  ? "green-inv-var1--color"
                  : "red-inv-var2--color"
              }`}
            >
              {respuestaApi.modal === "correo_incorrecto" &&
                `¡ ${correo_incorrecto.titulo} !`}
              {respuestaApi.modal === "exito" && `¡ ${exito.titulo} !`}
              {respuestaApi.modal === "general" && `¡ ${general.titulo} !`}
              {respuestaApi.modal === "concurrencias" &&
                `¡ ${concurrencias.titulo} !`}
            </h2>
          </section>

          <p className="text-center r7-16-negro26 my-6">
            {respuestaApi.modal === "correo_incorrecto" &&
              correo_incorrecto.descripcion}
            {respuestaApi.modal === "exito" && exito.descripcion}
            {respuestaApi.modal === "general" && `¡ ${general.descripcion} !`}
            {respuestaApi.modal === "concurrencias" &&
              `¡ ${concurrencias.descripcion} !`}
          </p>

          <button
            aria-label="Aceptar"
            onClick={() => {
              MicroModal.close(`respuesta-modal`, {
                awaitCloseAnimation: true,
              });
            }}
            className="boton-pequenio boton-amarillo boton_hover_morado block mx-auto mb-6"
          >
            {boton.aceptar}
          </button>
        </Modal>
      )}
    </>
  );
};

export default RecuperarContrasenaComponent;

RecuperarContrasenaComponent.propTypes = {
  /**
   * boleano que indica que el Api de recuperar contraseña termino de responder o sigue en proceso.
   */
  preloader: PropTypes.bool.isRequired,
  /**
   *  Objeto que contiene dos propiedades que va a devolver cuando el API editar instituto termine de responder.
   */
  respuestaApi: PropTypes.shape({
    isError: PropTypes.bool,
    modal: PropTypes.string,
  }).isRequired,
  /**
   * Función que envia un correo para actualizar la contraseña. Esta función utiliza el api reestablecerContraseña
   * @type{Function({ correo:string }):void}
   */
  recuperarContrasena: PropTypes.func.isRequired,
  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
};
