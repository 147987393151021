import removeAccents from './../../../../utilities/function/removeAccents';

/**
 * Esta función se encarga de almacenar el nombre que se encontraron en el Excel en propiedades que coinciden con el nombre del header esperado. Esto se hace para que sea mas fácil acceder a estos sin la necesidad de cambiar el nombre de los campos a como aparecer en el archivo.
 * 
 * @param headerCorrecto: array
 * @param headerTabla: array 
 * 
 * headerTabla: Es un array que almacena el nombre de los campos que se encontraron en las hojas del archivo subido.
 * headerCorrecto: Es un array de objetos que corresponde a los valores que retorno la función de obtenerHeaderTabla.
 * 
 * @returns header: object
 * 
 * header: Es un objeto que almacena los nombres de los campos encontrados en las hojas del Excel en propiedades que llevan el nombre del campo correcto con el que se encontro coincidencia. Esto se hace, ya que en las hojas los campos se pueden encontrar en plural
**/

//Relacion del header de la tabla con el header esperado para que sea mas facil la manipulación de los elementos


const nuevoHeader = ({ headerCorrecto, headerTabla }) => {
  let header = {};

  headerCorrecto?.forEach(campo => {
    headerTabla?.map(campoTabla => {
      const nombreCampoTabla = removeAccents(campoTabla)
      const nombreCampo = removeAccents(campo?.campo)

      const buscar = nombreCampoTabla.search(nombreCampo);
      if (buscar !== -1) return header = { ...header, [campo?.campo]: campoTabla }
      return buscar;
    })
  })

  return header;
}

export default nuevoHeader;