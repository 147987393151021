import React from "react";
import { Route, Switch } from "react-router-dom";
import Error_404 from "../../containers/Error_404";
import { Home } from "./home";
import { Stats } from "./stats";
import { Clubs } from "./clubs";
import { Help } from "./help";
import { Books } from "./books";

export const StudentProfileRouter = () => {
	return (
		<>
			<Switch>
				<Route exact path="/estudiante/home">
					<Home />
				</Route>

				<Route exact path="/estudiante/logros">
					<Stats />
				</Route>

				<Route path="/estudiante/clubs">
					<Clubs />
				</Route>
				<Route exact path="/estudiante/ayuda">
					<Help />
				</Route>

				<Route path="/estudiante/libros">
					<Books />
				</Route>
				<Route component={Error_404} />
			</Switch>
		</>
	);
};
