import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BooksPage } from "../../books/components/books-page";
import { adaptarPortadaLibros } from "../../../../utilities/adaptadores";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { ClubsAPI } from "../../../../api/ClubsAPI";
import { CancelRequestController } from "../../../../utilities/cancel-request-controller";

export const Club = () => {
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const history = useHistory();
	const params = useParams();
	const id = params?.club;
	const [club, setClub] = useState({});
	const [loading, setLoading] = useState(true);

	const fetchClubData = async ({ abortSignal, isUnMounted }) => {
		if (isUnMounted) return;
		setLoading(true);
		await ClubsAPI.obtenerTodos({ abortSignal })
			.then((res) => {
				const clubs = Array.isArray(res) ? res : [];
				const _club = clubs.find((c) => c?.id === id);
				if (_club) {
					const _books = Array.isArray(_club?.libros)
						? adaptarPortadaLibros(_club?.libros)
						: [];
					_club.libros = _books;
					setClub(_club);
				} else {
					history.push("/estudiante/clubs");
				}
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		let isUnMounted = false;
		let abortController = new CancelRequestController();
		fetchClubData({ isUnMounted, abortSignal: abortController.signal });
		return () => {
			isUnMounted = true;
			abortController.abort();
		};
	}, [id]);

	return (
		<BooksPage
			title={club?.nombre}
			books={Array.isArray(club?.libros) ? club?.libros : []}
			loading={loading}
			description={""}
			breadcrumb={{
				source: { name: textos?.miga_de_pan?.clubs || "Clubs", path: "/estudiante/clubs" },
			}}
			favoritesFeature={false}
		/>
	);
};
