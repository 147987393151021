import { useState } from "react";
import Paginacion from "../../globales/Paginacion";
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de propiedades que son necesarios para renderizar la tabla de estudiantes en la pantalla de verificar club. Además, hace uso del componente global Paginacion.
 * @returns VerificarListaEstudiantes
 */
const VerificarListaEstudiantes = (props) => {
  const { datosEstudiantes, textosTabla } = props;
  const { codigo, curso, nombres_apellidos, sede, sin_codigo, sin_estudiantes } = textosTabla;
  const [datosDivididos, setDatosDivididos] = useState([]);

  /*Esta función es para el componente de paginación y devuelve la cantidad de datos que se deben mostrar */
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  return (
    <div role="table" aria-label="Estudiantes seleccionados" aria-rowcount={datosDivididos.length}>
      <div className="encabezadoTabla" role="row">
        <p role="columnheader" className="col-span-2">{codigo}</p>
        <p role="columnheader" className="xl:col-span-4">{nombres_apellidos}</p>
        <p role="columnheader" className=" xl:col-span-3">{sede}</p>
        <p role="columnheader" className=" xl:col-span-3">{curso}</p>
      </div>
      {datosDivididos.length === 0 ?
        <div className="filaTabla xl:grid-cols-12" role="rowgroup">
          <div className=" center col-span-12" role="row">
            <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
            <p className="raleway-700 red-inv-var2--color text-16"> {sin_estudiantes}</p>
          </div>
        </div>
        :
        datosDivididos.map((dato, index) => {
          return (
            <div key={`campo${index}`} className="filaTabla" role="row" aria-rowindex={index + 1}>
              <div role="cell" className="fila-10-sm5-md3-lg6 xl:col-span-2">
                <span className="q7-14-negro26  xl:hidden">{codigo}</span>
                <p className={`${!!dato.codigo ? 'r7-morado text-13 ' : 'r7-13-rojo'} break-words`}>{!!dato.codigo ? dato.codigo : sin_codigo}</p>
              </div>
              <div role="cell" className="fila-10-sm5-md3-lg6 xl:col-span-4">
                <span className="q7-14-negro26  xl:hidden">{nombres_apellidos}</span>
                <p className="r5-13-negro26 break-words">{dato.nombre}</p>
              </div>
              <div role="cell" className="fila-10-sm5-md3-lg6 xl:col-span-3 overflow-auto">
                <span className="q7-14-negro26  xl:hidden">{sede}</span>
                <p className="r5-13-negro26 break-words">{dato.nombreSede}</p>
              </div>
              <div role="cell" className="fila-10-sm5-md3-lg6 xl:col-span-3 overflow-auto">
                <span className="q7-14-negro26  xl:hidden">{curso}</span>
                <p className="r5-13-negro26 break-words">{dato.nombreCurso}</p>
              </div>
            </div>
          )
        })
      }

      <div className={`alineado-izquierda xl:mt-3`}>
        <Paginacion datos={datosEstudiantes} datosDivididos={ActualizarDatosDivididos} />
      </div>
    </div>
  )
}
export default VerificarListaEstudiantes;

VerificarListaEstudiantes.propTypes = {
  /**
   *  Es un array que almacenan los datos de los estudiantes asignados al club.
   */
  datosEstudiantes: PropTypes.array,
  /**
  *  Es un objeto que trae los textos del encabezado de la tabla. Este cambia según el idioma activo.
  */
  textosTabla: PropTypes.object.isRequired,
}