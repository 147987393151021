import _ from "lodash";

const reducer = (state, action) => {
	switch (action.type) {
		case "CHANGE":
			const _newState = { ...state };
			_.set(_newState, action.path, action.value);
			return _newState;
		case "SECCION_LIBROS_TEMPORAL":
			return {
				...state,
				seccionLibros: {
					...state?.seccionLibros,
					temporal: {
						...state?.seccionLibros?.temporal,
						[action.property]: action.value,
					},
				},
			};
		case "SECCION_ESTADISTICAS_FILTROS":
			return {
				...state,
				estadisticas: {
					...state?.estadisticas,
					filtros: {
						...state?.estadisticas?.filtros,
						[action.property]: action.value,
					},
				},
			};
		case "SET_DATA_SESION":
			return {
				...state,
				[action.property]: action.value,
			};
		case "SET_DATA":
			return {
				...state,
				[action.property]: action.value,
			};

		case "SET_DATA_TEMPORAL":
			return {
				...state,
				temporal: {
					...state.temporal,
					[action.property]: action.value,
				},
			};

		case "SET_DATA_PLAN_ACTIVO":
			return {
				...state,
				planActivo: {
					...state.planActivo,
					[action.property]: action.value,
				},
			};

		case "LOG_OUT":
			return {
				idioma: 'es',
				isLoading: false,
				isPending: false,
				isSeccionIniciada: false,
				planActivo: {},
			};

		default:
			return state;
	}
};

export default reducer;
