import useIsMobile from '../../containers/helpers/hooks/useIsMobile';
import PropTypes from 'prop-types';

/**
 * Componente que recibe una serie de props necesarios para renderizar las opciones del header. Estas opciones  (Blog, idioma, cerrar sesión) se visualizan en el encabezado y en el menú de manera responsive.
 * @returns 
 */
const OpcionesHeaderComponent = (props) => {
  const { idiomaActivo, textosInterfaz, cerrarSesion, cambiarIdioma, idiomasDisponibles } = props;
  const isMobile = useIsMobile();
  return (
    <>
      <a
        href="https://makemake.com.co/blog"
        target='_blank'
        rel="noreferrer"
        className={`focusable-red ${isMobile ? "order-3 header-elementos  header-elementos_hover cursor-pointer" : " font-bold"}`}
      >Blog</a>
      <div className={isMobile ? "order-1 header-elementos" : "mx-6"}>
        {idiomasDisponibles.map((item) =>
          <button
            key={`idioma-${item}`}
            className={`focusable-red ${idiomaActivo === item ? "font-bold" : ""}`}
            onClick={() => { cambiarIdioma(item) }}
          // style={{color: i}}
          >
            {item === 'es' ?
              <p>{item.toUpperCase()}<span className="font-normal mx-1.5">/</span></p>
              :
              <p>{item.toUpperCase()}</p>}
          </button>
        )}
      </div>
      <button
        className={`focusable-red ${isMobile ? "order-2 header-elementos header-elementos_hover" : "boton-justo boton-gris-salir"}`}
        onClick={cerrarSesion}
      >
        {textosInterfaz.cerrar_sesion}
      </button>
    </>
  )
}

export default OpcionesHeaderComponent;

OpcionesHeaderComponent.propTypes = {
  /**
   * string que corresponde al idioma activo en el store.
   */
  idiomaActivo: PropTypes.string.isRequired,

  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * función que se encarga de finalizar la sesión del usuario.
   */
  cerrarSesion: PropTypes.func.isRequired,

  /**
   * Función que cambia el idioma de la plataforma
   * @type{Function(idioma:string) :void} -callback
   */
  cambiarIdioma: PropTypes.func.isRequired,

  /**
   * array de strings que corresponde a los idiomas disponibles dentro del proyecto.
   */
  idiomasDisponibles : PropTypes.array.isRequired

}