import axios from "axios";

export class SedesAPI{

    // DEFINICIÓN DE LA ESTRUCTURA DE UN LIBRO
    /**
     * @typedef {
            {
                "id": string,
                "nombre": string,
                "telefono": string,
                "correo": string,
                "fechaInicioSuscripcion": string,
                "fechaFinSuscripcion": string,
                "color": string,
                "numeroTotalDeEstudiantes": number,
                "numeroDeLibrosAsignados": number,
                "numeroTotalDeProfesores": number,
                "paquetes": [
                    {
                        nombre: string
                    },
                ],
                "cursos": string[],
            }
 } Sede

 */

     /**
     * @typedef {{
        "idInstituto": string,
        "nombre": string,
        "correo": string,
        "telefono":string,
        "direccion": string,
        "color": {
            "header":string,
            "letras":string
        },
        "logo": string,
        "pais": string,
        "fechaInicioSuscripcion": string,
        "fechaFinSuscripcion": string,
        "numeroMaximoDeSedes": string,
        "paquetes":Array<string>,
        "sedes": Array<Sede>
    }} Instituto

 */

//---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS
       /**
     * Devuelve todos las sedes de un instituto
     * @returns {Promise<Sede[]>} Sede[]
     */
        static async obtenerTodas(){
          try {
              axios.defaults.baseURL = process.env.REACT_APP_API_URL;
              axios.defaults.withCredentials = true;
              const response = await axios({
                  method:'POST',
                  url:'sedes/obtenerInstituto',
                  data:{}
               }) 
               if(response.data?.status ==1){
                   let instituto = response.data?.data;
                   let sedes = instituto?.sedes || [];
                   return sedes && Array.isArray(sedes)?sedes:[];
               }else{
                   throw new Error(response.data?.info || 'Hubo un error, intente de nuevo más tarde.');
               }

          } catch (error) {
              return Promise.reject(error);
          }
      }
}
