import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
//Store
import { useDispatch } from '../../store';
//Hooks personalizados
import useInitialDataOfSection from './../helpers/useInitiailDataOfSection';
//Componentes
import GeneralCursos from './GeneralCursos';
import CrearCursos from './CrearCursos';
import VerificarCursos from './VerificarCursos';
import EditarCursoNuevo from './EditarCursoNuevo';
import EditarEliminarCurso from './EditarEliminarCurso';
import Error_404 from './../Error_404/index';

const Cursos = () => {
  //Estados globales
  const dispatch = useDispatch();

  const { isLoading: isLoadingCursos } = useInitialDataOfSection({ pathname: '/cursos/obtenerCursos', property: 'datosCursos', section: 'cursos', propiedadJson: 'profesores' });
  const { isLoading: isLoadingProfesores } = useInitialDataOfSection({ pathname: '/profesores/obtenerListadoProfesores', property: 'datosProfesores', section: 'profesores', propiedadJson: 'cursos' });

  const history = useHistory();

  useEffect(() => {
    return () => {
      borrarDatosTemporales()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //Descartar cambios
  const descartarCambios = async () => {
    borrarDatosTemporales()

    history.push('/cursos')
  }


  const borrarDatosTemporales = async () => {
    let dataTemporal = {};
    await dispatch({
      type: "SET_DATA",
      property: "temporal",
      value: dataTemporal
    });
  }


  return (
    <>
      <Switch>
        {/* <Route exact path="/cursos">
          <GeneralCursos
            isLoadingCursos={isLoadingCursos}
          />
        </Route> */}
        <Route exact path='/cursos/crear-curso'>
          <CrearCursos
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
            isLoadingProfesores={isLoadingProfesores}
          />
        </Route>
        <Route exact path='/cursos/crear-curso/verificar-cursos'>
          <VerificarCursos
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
          />
        </Route>
        <Route exact path='/cursos/crear-curso/verificar-cursos/:curso_nuevo'>
          <EditarCursoNuevo
            descartarCambios={descartarCambios}
            isLoadingCursos={isLoadingCursos}
            isLoadingProfesores={isLoadingProfesores}
          />
        </Route>
        <Route exact path='/cursos/editar-curso'>
          <EditarEliminarCurso
            isLoadingCursos={isLoadingCursos}
            isLoadingProfesores={isLoadingProfesores}
          />
        </Route>
        <Route exact path='/cursos/editar-curso/:sede'>
          <EditarEliminarCurso
            isLoadingCursos={isLoadingCursos}
            isLoadingProfesores={isLoadingProfesores}
          />
        </Route>
        <Route exact path='/cursos/editar-curso/:sede/:curso'>
          <EditarEliminarCurso
            isLoadingCursos={isLoadingCursos}
            isLoadingProfesores={isLoadingProfesores}
          />
        </Route>
        <Route component={Error_404} />
      </Switch>
    </>
  )
}

export default Cursos;
