/**
 Es un arreglo de objetos que en su interior se definen los diferentes filtros actualmente disponibles para la búsqueda de libros y también el estado por defecto de cada uno. Algunos filtros tienen la propiedad 'visibilidad' la cual indica si el filtro debe mostrarse en modo de plan activo o en modo de selección de libros, si no se pasa la propiedad el filtro será visible para ambos modos. 
 */
const FiltrosData = [
  {
    name: 'search',
    type: 'text',
    value: '',
    placeholder: 'Búsqueda por título'
  },
  {
    name: 'Todas las materias',
    translate: 'materia',
    apiProperty: 'Materia',
    type: 'dropdown',
    options: [
      {
        name: 'Ciencias',
        translate:'ciencias',
        selected: false      
      },
      {
        name: 'Historia y ensayo',
        translate:'historia_ensayo',
        selected: false      
      },
      {
        name: 'Letras y números',
        translate:'letras_numeros',
        selected: false      
      },
      {
        name: 'Artes y manualidades',
        translate:'artes_manualidades',
        selected: false      
      },
      {
        name: 'Canción y poesía',
        translate:'cancion_poesia',
        selected: false      
      },
      {
        name: 'Narrativa',
        translate:'narrativa',
        selected: false      
      }
    ]
  },
  {
    name: 'Nivel de lectura',
    translate:'nivel',
    apiProperty: 'nivelUsuarioLector',
    type: 'dropdown',
    options: [
      {
        name: 'Primeros lectores',
        translate:'primeros',
        value: 'Primeros lectores',
        icon:'nivel1',
        selected: false      
      },
      {
        name: 'Lectores intermedios',
        translate:'intermedios',
        value: 'Lectores intermedios',
        icon:'nivel2',
        selected: false      
      },
      {
        name: 'Lectores avanzados',
        translate:'avanzados',
        value: 'Lectores avanzados',
        icon:'nivel3',
        selected: false      
      }
    ]
  },
  {
    name: 'Género',
    translate:'generos',
    apiProperty: 'genero',
    type: 'dropdown',
    options: [
      {
        name: 'Cómic',
        translate:'comic',
        value: 'Cómic',
        icon:'comic',
        selected: false      
      },
      {
        name: 'Libro álbum',
        translate:'libro_album',
        value: 'Libro álbum',
        icon:'libro-album',
        selected: false      
      },
      {
        name: 'Cuento ilustrado',
        translate:'cuento_ilustrado',
        value: 'Cuento ilustrado',
        icon:'cuento-ilustrado',
        selected: false      
      },
      {
        name: 'Poesía',
        translate:'poesia',
        value: 'poesia',
        icon:'poesia',
        selected: false      
      },
      {
        name: 'Crónica y ensayo',
        translate:'cronica',
        value: 'Crónica y ensayo',
        icon:'cronica-y-ensayo',
        selected: false      
      },
      {
        name: 'Interactivo',
        translate:'interactivo',
        value: 'interactivo',
        icon:'interactivo',
        selected: false      
      },
      {
        name: 'Didáctico',
        translate:'didactico',
        value: 'didactico',
        icon:'didactico',
        selected: false      
      },
      {
        name: 'Teatro',
        translate:'teatro',
        value: 'Teatro',
        icon:'teatro',
        selected: false      
      },
      {
        name: 'Cuento',
        translate:'cuento',
        value: 'cuento',
        icon:'cuento',
        selected: false      
      },
      {
        name: 'Novela',
        translate:'novela',
        value: 'Novela',
        icon:'novela',
        selected: false      
      },
      {
        name: 'Informativo',
        translate:'informativo',
        value: 'informativo',
        icon:'informativo',
        selected: false      
      }
    ]
  },
  {
    name: 'Edad',
    translate:'edad',
    apiProperty: 'edad',
    type: 'dropdown',
    options: [
      {
        name: '3',
        translate:'tres',
        value: '3',
        icon:'3+',
        selected: false      
      },
      {
        name: '5',
        translate:'cinco',
        value: '5',
        icon:'5+',
        selected: false      
      },
      {
        name: '7',
        translate:'siete',
        value: '7',
        icon:'7+',
        selected: false      
      },
      {
        name: '9',
        translate:'nueve',
        value: '9',
        icon:'9+',
        selected: false      
      },
      {
        name: '11',
        translate:'once',
        value: '11',
        icon:'11+',
        selected: false      
      },
      {
        name: '13',
        translate:'trece',
        value: '13',
        icon:'13+',
        selected: false      
      },
      {
        name: '15',
        translate:'quince',
        value: '15',
        icon:'15+',
        selected: false      
      }
    ]
  },
 /*  {
    name: 'Tiempo de lectura',
    translate:'tiempo',
    apiProperty: 'tiempoLectura',
    type: 'dropdown',
    options: [
      {
        name: '5-10',
        translate:'cinco_diez',
        value: '5-10',
        icon:'tiempo',
        selected: false      
      },
      {
        name: '10-20',
        translate:'diez-veinte',
        value: '10-20',
        icon:'tiempo',
        selected: false      
      },
      {
        name: '>20',
        translate:'mas_veinte',
        value: '>20',
        icon:'tiempo',
        selected: false      
      }
    ]
  }, */
  {
    name: 'Dificultad Lectora',
    translate:'gr',
    apiProperty: 'dificultadLectora',
    type: 'dropdown',
    options: [
      {
        name: 'A-B-C',
        translate:null,
        value: 'A;B;C',
        icon:'tiempo',
        selected: false      
      },
      {
        name: 'D-E-F-G-H-I-J',
        translate:null,
        value: 'D;E;F;G;H;I;J',
        icon:'tiempo',
        selected: false      
      },
      {
        name: 'K-L-M',
        translate:null,
        value: 'K;L;M',
        icon:'tiempo',
        selected: false      
      },
      {
        name: 'N-O-P',
        translate:null,
        value: 'N;O;P',
        icon:'tiempo',
        selected: false      
      },
      {
        name: 'Q-R-S',
        translate:null,
        value: 'Q;R;S',
        icon:'tiempo',
        selected: false      
      },
      {
        name: 'T-U-V',
        translate:null,
        value: 'T;U;V',
        icon:'tiempo',
        selected: false      
      },
      {
        name: 'W-X-Y-Z-Z+',
        translate:null,
        value: 'W;X;Y;Z;Z+',
        icon:'tiempo',
        selected: false      
      },
    ]
  },
  {
    name: 'actividad',
    translate:'actividad',
    type: 'checkbox',
    value: false
  },
  {
    name: 'libros_ocultos',
    translate:'libros_ocultos',
    type: 'checkbox',
    value: false,
    visibilidad:'sesionActiva',
  },
  {
    name: 'libros_agregados',
    translate:'libros_agregados',
    type: 'checkbox',
    value: false,
    visibilidad:'escogiendoLibros',
  },
  {
    name: 'recomendadosPorAlgoritmo',
    translate:'recomendados_algoritmo',
    type: 'checkbox',
    value: false,
    visibilidad:'escogiendoLibros',
  },
  {
    name: 'favorito',
    translate:'favoritos',
    notShowEligiendoLibros: true,
    type: 'checkbox',
    value: false,
  }
]

export default FiltrosData;