import { useState, useEffect } from 'react';
import MicroModal from 'micromodal';
import PropTypes from 'prop-types';

//Componentes
import InputText from '../../globales/elementosFormularios/InputText';
import RadioButton from '../../globales/elementosFormularios/RadioButton';
import ModalFormEnviado from '../../../containers/ayuda/modalFormEnviado';

/**
 * Este componente recibe una serie de props que le permiten renderizar el formulario de "Tuve problemas con un libro". Además, renderiza los componentes InputText, RadioButton y ModalFormEnviado. 
 * @returns ProblemaLibro
 */
const ProblemaLibro = (props) => {
  const { info, textosInterfaz, enviarASoporte, preloader, mostrarModal, ocultarModal } = props;
  const { dispositivo, libro, otros, tipo_problema } = info;
  const [campoLibro, setCampoLibro] = useState({ campo: '', valido: false });
  const [campoDispositivo, setDispositivo] = useState({ campo: '', valido: false });
  const [enviar, setEnviar] = useState(false);
  const [radioActivo, setRadioActivo] = useState(null);
  const [otrosCampo, setOtrosCampo] = useState('');

  const expresion = /^[a-zA-ZÀ-ÿ0-9\s]{1,40}$/;

  useEffect(() => {
    if (mostrarModal) {
      MicroModal.show('formulario-enviado', {
        awaitCloseAnimation: true
      });
    }
  }, [mostrarModal])

  const enviarFormulario = () => {
    setEnviar(true);
    if (campoLibro.valido, campoDispositivo.valido, radioActivo !== null) {

      const nombreLibro = campoLibro;
      const nombreDispositivo = campoDispositivo;
      const tipoProblema = { sonido: 0, imagen: 0, libro: 0, tipografia: 0, carga: 0, clasificacion: 0 };

      Object.keys(tipoProblema).map((item, index) => {
        if (radioActivo === index) {
          tipoProblema[item] = 1;
        }
      })
      const otrosDescripcion = otrosCampo !== "" ? otrosCampo : "";

      enviarASoporte(nombreLibro.campo, nombreDispositivo.campo, tipoProblema, otrosDescripcion)
    } else {
      //si no son validos envia el foco al inicio del formulario
      const startForm = document.querySelector("#libro");
      if (startForm) {
        startForm.tabIndex = -1;
        startForm.focus();
        setTimeout(() => startForm.removeAttribute("tabindex"), 1000);
      }
    }
  }

  const alCerrarModal = () => {
    MicroModal.close("formulario-enviado", {
      awaitCloseAnimation: true
    });
    setCampoLibro({ ...campoLibro, campo: '', valido: false })
    setDispositivo({ ...campoDispositivo, campo: '', valido: false })
    setRadioActivo(null)
    setOtrosCampo('');
    setEnviar(false)

    ocultarModal();
  }

  return (
    <>
      <div className="mb-3">
        <label htmlFor="libro" className="q7-18 onbackground-var1--color inline-block pb-3">{libro.titulo} <span className="asterisco">*</span></label>
        <InputText
          cambiarEstado={setCampoLibro}
          estado={campoLibro}
          estadoEnviar={enviar}
          identidicador="libro"
          mensajeError={!campoLibro.valido && libro.error}
          placeholder={libro.placeholder}
          validarExpresion={expresion}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="dispositivo" className="q7-18 onbackground-var1--color inline-block pb-3">{dispositivo.titulo} <span className="asterisco">*</span></label>
        <InputText
          cambiarEstado={setDispositivo}
          estado={campoDispositivo}
          estadoEnviar={enviar}
          identidicador="dispositivo"
          mensajeError={!campoDispositivo.valido && dispositivo.error}
          placeholder={dispositivo.placeholder}
          validarExpresion={expresion}
        />
      </div>

      < RadioButton
        arreglo={tipo_problema}
        estadoEnviar={enviar}
        mensajeError={tipo_problema.error}
        radioActivo={radioActivo}
        setRadioActivo={setRadioActivo}
        titulo={tipo_problema.titulo}
      />

      <label htmlFor="otros" className="q7-18 onbackground-var1--color my-3">{otros.titulo} </label>
      <textarea
        id="otros"
        className={`textarea mt-3 focusable-primary onsurface-var2--placeholder`}
        onChange={(e) => { setOtrosCampo(e.target.value); }}
        placeholder={otros.placeholder}
        value={otrosCampo}
        aria-label={otros?.placeholder || 'describe qué otros problemas tuviste'}
      ></textarea>

      <div className="flex justify-end my-5">
        <button
          className="boton-pequenio boton-amarillo boton_hover_morado focusable-primary"
          onClick={() => { enviarFormulario() }}
        >
          {
            preloader ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              textosInterfaz.boton_enviar
          }
        </button>
      </div>

      {enviar &&
        <ModalFormEnviado alCerrarModal={alCerrarModal} textosInterfaz={textosInterfaz} />
      }
    </>
  )
}

export default ProblemaLibro;

ProblemaLibro.propTypes = {
  /**
   * Objeto que contiene los textos de cada pregunta del formulario.
   */
  info: PropTypes.shape({
    dispositivo: PropTypes.shape({
      error:PropTypes.string,
      placeholder:PropTypes.string,
      titulo:PropTypes.string
    }),
    libro: PropTypes.shape({
      error:PropTypes.string,
      placeholder:PropTypes.string,
      titulo:PropTypes.string
    }),
    otros:PropTypes.shape({
      placeholder:PropTypes.string,
      titulo:PropTypes.string
    }),
    tipo_problema:PropTypes.shape({
      error:PropTypes.string,
      opciones:PropTypes.array,
      titulo:PropTypes.string
    })
  }).isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Función que envia la información del formulario al correo. 
   * @type{Function(nombreLibro: string, nombreDispositivo: string, tipoProblema: object, otrosDescripcion: string):void} -callback
   */
  enviarASoporte: PropTypes.func.isRequired,
  /**
 * booleano que contiene el preloader para el llamado de la API EmailAPI
 */
  preloader: PropTypes.bool.isRequired,
  /**
   * Booleano que indica si debe aparecer la ventana modal de enviado
   */
  mostrarModal: PropTypes.bool.isRequired,
  /**
    * Función que oculta la ventana modal
    */
  ocultarModal: PropTypes.func.isRequired,
}