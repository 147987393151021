import { useState } from 'react';
/*Componentes */
import DivisionCrud from "../../globales/DivisionCrud";
import Selector from "../../globales/elementosFormularios/Selector";
import InputFile from "../../globales/elementosFormularios/InputFile";

const CrearEstudianteMasivo = (props) => {
  const { textosInterfaz, mostrarAlerta, sedesInstituto, sedeSeleccionada, cambiarCurso, cambiarSede, cursosSede, cursoSeleccionado, datosTemporalesExcel, excelValido, hayErrorExcel, mostrarAlertaArchivo, obtenerDatosExcel, setExcelValido } = props;
  const { curso, sede, subir_excel } = textosInterfaz;

  const [nombreDocumento, setNombreDocumento] = useState('');

  return (
    <>
      {/* sede */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{sede.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{sede.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarSede}
            desactivado={false}
            estadoEnviar={mostrarAlerta}
            estiloTipoInput={true}
            opciones={sedesInstituto}
            textoDefault={sede.placeholder}
            textoElegido={sedeSeleccionada.nombre}
            textoError={sede.error}
            valido={!!sedeSeleccionada.id}
            zIndex={60}
          />
        </div>
      </DivisionCrud>

      {/* curso */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{curso.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{curso.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarCurso}
            desactivado={false}
            estadoEnviar={mostrarAlerta}
            estiloTipoInput={true}
            opciones={cursosSede.resultados}
            textoDefault={curso.placeholder}
            textoElegido={cursoSeleccionado.nombre}
            textoError={curso.error}
            textoNoHayOpciones={curso.no_hay}
            valido={!!cursoSeleccionado.id}
            zIndex={50}
          />
        </div>
      </DivisionCrud>

      {/* Subir excel */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{subir_excel.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{subir_excel.descripcion}</p>
          <a href='/documents/plantilla_estudiantes.xlsx' download="plantilla_estudiante.xlsx" className="focusable-primary evento_rojo block mt-3">{subir_excel.descargar_plantilla}</a>
        </>
        <>
          <InputFile
            datoGuardado={datosTemporalesExcel}
            descripcion={
              !!datosTemporalesExcel?.datos?.nombreArchivo ? datosTemporalesExcel.datos.nombreArchivo : subir_excel.descripcion_input
            }
            error={subir_excel.input.excel_no_valido}
            excelValido={excelValido}
            hayErrorExcel={hayErrorExcel}
            instruccion={subir_excel.input.titulo}
            mostrarAlerta={mostrarAlertaArchivo}
            nombreDocumento={nombreDocumento}
            obtenerDatosExcel={obtenerDatosExcel}
            setExcelValido={setExcelValido}
            setNombreDocumento={setNombreDocumento}
            textoError={subir_excel.input.excel_no_valido}
          />
        </>
      </DivisionCrud>
    </>
  )
}
export default CrearEstudianteMasivo;