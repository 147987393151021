import { useState } from "react";
import PropTypes from 'prop-types';
import Paginacion from "../../../globales/Paginacion";
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import { useTrackedState } from "../../../../store";

/**
 * Renderizara una tabla con las estadísticas de todos los profesores que leyeron un libro en particular, estas estadísticas son pasadas de padre a hijo por props. 
 */
const TablaDetalleLibro = (props) => {
  const { datos, textosTabla } = props;
  const { tabla, resultados } = textosTabla;
  const { no_hay, nombres_apellidos, fecha, tiempo_total_lectura, tiempo_promedio, colegio } = tabla;
  const { hay, resultado } = resultados;

  const state = useTrackedState();

  const [datosDivididos, setDatosDivididos] = useState([]);
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)


  return (
    <>
      <div role="table" aria-rowcount={datos.length} className={`${datos.length > 10 ? null : 'mb-5'}`}>
        <div role="rowgroup">
          <div role="row" className="encabezadoTabla  xl:grid grid-cols-12 ">
            <p role="columnheader" className="col-span-3 alineado-verticalmente">{nombres_apellidos || 'Nombres y apellidos'}</p>
            <p role="columnheader" className="col-span-2 alineado-verticalmente ">{fecha || 'Fecha de lectura'}</p>
            <p role="columnheader" className="col-span-2 alineado-verticalmente text-center">{tiempo_total_lectura || 'Tiempo de lectura'}</p>
            <p role="columnheader" className="col-span-2 alineado-verticalmente text-center">{tiempo_promedio || 'Tiempo promedio de lectura'}</p>
            <p role="columnheader" className="col-span-2 alineado-verticalmente">{colegio || 'Sede'}</p>
          </div>
        </div>

        <div role="rowgroup">
          {datosDivididos.length === 0 ?
            <div role="row" className="filaTabla xl:grid-cols-12">
              <div role="cell" className=" center col-span-12">
                <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                <p className="raleway-700 red-inv-var2--color text-13">{no_hay}</p>
              </div>
            </div>
            :
            datosDivididos.map((dato) => {
              return (
                <div role="row" key={`campo-${dato.idEstudiante}`} className="filaTabla xl:grid-cols-12">
                  <div role="cell" className="fila-12-sm6-md4-lg6 xl:col-span-3 overflow-auto">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{nombres_apellidos || 'Nombres y apellidos'}</span>
                    <p className={`r5-13-negro26 una-linea-texto `} title={dato.nombreCompleto || 'N/A'}>{dato.nombreCompleto || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm6-md4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{fecha || 'Fecha de lectura'}</span>
                    <p className={`r5-13-negro26  una-linea-texto `} title={FiltroFechas.formatoUsuario(state?.idioma, dato.fechaLectura) || 'N/A'}>{FiltroFechas.formatoUsuario(state?.idioma, dato.fechaLectura) || 'N/A'}</p>
                  </div>
                  <div role="cell" className="fila-12-sm6-md4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{tiempo_total_lectura || 'Tiempo de lectura'}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center`} title={dato.tiempoTotalLectura || 0}>{dato.tiempoTotalLectura || 0} min</p>
                  </div>
                  <div role="cell" className="fila-12-sm6-md4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{tiempo_promedio || 'Tiempo promedio de lectura'}</span>
                    <p className={`r7-13-rojo una-linea-texto xl:text-center`} title={dato.tiempoPromedioDeLectura || 0}>{dato.tiempoPromedioDeLectura || 0} min</p>
                  </div>
                  <div role="cell" className="fila-12-sm6-md4-lg6 xl:col-span-2">
                    <span role="columnheader" className="q7-14-negro26  xl:hidden">{colegio || 'Sede'}</span>
                    <p className={`r5-13-negro26 una-linea-texto`} title={dato?.nombreInstituto || 'N/A'}>{dato?.nombreInstituto || 'N/A'}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="md:alineado-verticalmente xl:mt-3">
          <div className={`w-full md:w-6/12 `}>
            <p className="r4-16-negro4A">{hay} {datos.length} {datos.length !== 1 ? resultado.plural : resultado.singular} </p>
          </div>

          <div className={`w-full md:w-6/12 alineado-izquierda mt-5 md:mt-0`}>
            <Paginacion datos={datos} datosDivididos={ActualizarDatosDivididos} />
          </div>
        </div>

      </div>
    </>
  )
}
export default TablaDetalleLibro;

TablaDetalleLibro.prop3ypes = {
  /**
  * Arreglo de objetos con los datos de la tabla
  */
  datos: PropTypes.arrayOf(PropTypes.shape({
    nombreInstituto: PropTypes.string,
    nombreCompleto: PropTypes.string,
    numeroDeVecesLeido: PropTypes.number,
    tiempoPromedioDeLectura: PropTypes.number,
    tiempoTotalLectura: PropTypes.number,
    fechaLectura: PropTypes.string,

  })).isRequired,
  /**
   * Objeto con las textos de la interfaz del componente
   */
  textosTabla: PropTypes.object.isRequired,
}