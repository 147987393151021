import { Route, Switch } from 'react-router-dom';

//containers

import GeneralEstadisticas from './GeneralEstadisticas';
import ReporteIndividuo from './ReporteIndividuo';
import IngresosIndividuales from './IngresosIndividuales';
import DetalleLibro from './DetalleLibro';
import LibrosLeidos from './LibrosLeidos';
import TotalSesiones from './TotalSesiones';
import Error_404 from '../Error_404';
import DetallePorDia from './DetallePorDia';
import { Comparativo } from './Comparativo';

const Estadisticas = () => {
  return (
    <>
      <Switch>
        <Route exact path="/estadisticas">
          <GeneralEstadisticas />
        </Route>

        <Route exact path="/estadisticas/estadisticas-globales">
          <Comparativo />
        </Route>

        <Route exact path="/estadisticas/sesiones-de-lectura">
          <TotalSesiones />
        </Route>
        <Route exact path="/estadisticas/sesiones-de-lectura/detalle-dia">
          <DetallePorDia />
        </Route>

        <Route exact path="/estadisticas/libros-leidos">
          <LibrosLeidos />
        </Route>

        <Route exact path="/estadisticas/estudiantes/libros-leidos">
          <LibrosLeidos />
        </Route>

        <Route exact path="/estadisticas/profesores/libros-leidos">
          <LibrosLeidos />
        </Route>

        <Route exact path="/estadisticas/estudiantes/libros-leidos/:idLibro">
          <DetalleLibro />
        </Route>

        <Route exact path="/estadisticas/profesores/libros-leidos/:idLibro">
          <DetalleLibro />
        </Route>

        <Route exact path="/estadisticas/ingresos-individuales">
          <IngresosIndividuales />
        </Route>
        <Route exact path="/estadisticas/ingresos-individuales/profesores">
          <IngresosIndividuales />
        </Route>
        <Route exact path="/estadisticas/ingresos-individuales/estudiantes">
          <IngresosIndividuales />
        </Route>
        <Route exact path="/estadisticas/ingresos-individuales/profesores/:idProfesor">
          <ReporteIndividuo />
        </Route>
        <Route exact path="/estadisticas/ingresos-individuales/estudiantes/:idEstudiante">
          <ReporteIndividuo />
        </Route>

        <Route component={Error_404} />
      </Switch>
    </>
  )
}
export default Estadisticas;