import { useEffect, useRef, useState, useContext } from 'react';
import { store } from 'react-notifications-component';
import MicroModal from "micromodal";
import { Link } from 'react-router-dom';
import { Miga, MigasDePan } from "../globales/MigaDePan";
import { AccesibilidadContext } from '../../contexts/AccesibilidadContext';

//Componentes
import SelectorDeBusqueda from './../globales/elementosFormularios/SelectorDeBusqueda';
import ResultadosListaProfesores from './ListaProfesores';
import Modal from '../globales/modales/Modal';
import ModalConfirmacion from '../globales/modales/ModalConfirmacion';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de propiedades que permiten renderizar la pantalla principal de Profesores. Además, renderiza los componentes SelectorDeBusqueda, ResultadosListaProfesores,  Modal y ModalConfirmacion.
 * @returns ProfesoresGeneralComponent
 */
const ProfesoresGeneralComponent = (props) => {
  const { borrarFiltros, buscarProfesores, cambiarProfesoresElegidos, cambiarCurso, cambiarSede, cancelarAccionMultiSelect, cursosSede, datosMultiSelect, descargarDatos, eliminarProfesores, respuestaEliminarProfesores, filtros, moverProfesores, respuestaApiMoverProfesores, preloader, resultadosBusqueda, sedes, sedesSelect, textosInterfaz } = props;

  const { miga_de_pan, vista_general, botones, no_existe_sede, multi_accion, modal_mover, modal_exito, modal_eliminar } = textosInterfaz;

  const { busqueda, header, select, no_hay_resultados } = vista_general;

  //Estados del componente
  const [texto, setTexto] = useState(null);
  const [textoMultiselect, setTextoMultiselect] = useState({
    sede: null,
    cursos: null,
  });
  const [modalMover, setModalMover] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [numeroProfesoresSeleccionados, setNumeroProfesoresSeleccionados] = useState(0);
  const accesibilidad = useContext(AccesibilidadContext);

  //Referencias
  const inputBuscador = useRef(null)

  const cambiarOpcion = ({ opcion }) => {
    setTexto(opcion.nombre)
    cambiarSede({ idSede: opcion.id, value: inputBuscador.current.value, tipoAccion: 'filtros' });
  };



  const cambiarOpcionCurso = ({ opcion }) => {
    cambiarCurso({ idCurso: opcion.id, idSede: opcion.idSede })
  };


  const cambiarOpcionMultiSelect = ({ opcion }) => {
    setTextoMultiselect({
      ...textoMultiselect,
      sede: opcion.nombre
    })
    cambiarSede({ idSede: opcion.id, value: inputBuscador.current.value, tipoAccion: 'multiselect' });
  };

  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  useEffect(() => {
    if (filtros.sede === null) setTexto(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros])



  useEffect(() => {
    if (modalMover) {
      MicroModal.show('estas-seguro-mover-profesores', {
        awaitCloseAnimation: true
      });
    }
  }, [modalMover])

  useEffect(() => {
    if (modalEliminar) {
      MicroModal.show('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
    }
  }, [modalEliminar])


  useEffect(() => {
    if (respuestaApiMoverProfesores.isError === false) {
      MicroModal.close('estas-seguro-mover-profesores', {
        awaitCloseAnimation: true
      });
      MicroModal.show('profesor-movido', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaApiMoverProfesores.isError === true) {
      MicroModal.close('estas-seguro-mover-profesores', {
        awaitCloseAnimation: true
      });

      notificacion(textosInterfaz.notificacion.error, respuestaApiMoverProfesores.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiMoverProfesores.isError])


  useEffect(() => {
    if (respuestaEliminarProfesores.isError === false) {
      MicroModal.close('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
      MicroModal.show('profesor-eliminado', {
        awaitCloseAnimation: true
      });
    }

    if (respuestaEliminarProfesores.isError === true) {
      MicroModal.close('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });

      notificacion(textosInterfaz.notificacion.error, respuestaEliminarProfesores.mensaje, "danger");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaEliminarProfesores.isError])

  const vertodasLasSedes = () => cambiarSede({ idSede: null, tipoAccion: 'ver todo' })


  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan.principal || 'Profesores'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales alineado-verticalmente">{header.titulo} <span className='ml-3 text-12 primary-inv-var2--color'>({resultadosBusqueda.resultados.length} {resultadosBusqueda.resultados.length !== 1 ? header.num_profesores.masivos : header.num_profesores.individual}) </span></h1>

        <div className="md:flex justify-between items-center py-3">
          <div className="md:w-7/12 mt-3 ">
            <p className="informacion-principal ">{header.descripcion}</p>
          </div>
        </div>
        <div className="md:flex justify-between mt-3">
          <div className="md:w-6/12 xl:w-3/12">
            <SelectorDeBusqueda
              cambiarElegido={cambiarOpcion}
              filtro={filtros.sede}
              filtroMensajeNoexite={select.sede.no_existe}
              opciones={sedes}
              textoDefault={select.sede.default}
              textoElegido={texto}
              superposicion={true}
              verTodasLasOpciones={{
                textos: select.sede.ver_todo,
                alVertodasLasOpciones: vertodasLasSedes,
              }}
            />
          </div>

          <div className="md:w-6/12 xl:w-451p mt-6 md:mt-0 md:pl-5">
            <div className="relative w-full h-full">
              <input
                className="w-full"
                onChange={() => {
                  buscarProfesores({ value: !!inputBuscador.current ? inputBuscador.current.value : '' })
                }}
                placeholder={busqueda.placeholder}
                ref={inputBuscador}
                type="text"
              />

              <button
                aria-label='Buscar'
                className="absolute right-0 top-0 w-10 h-full focusable-primary">
                <span className="icon-lupa onsurface-var1--color"></span>
              </button>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 xl:grid-cols-4 py-5 mb-5 border-b-2 onsurface-var2--border-color">
          {/*borrar filtros */}
          {/* <button
            className="boton-pequenio boton-rojo boton_hover_morado justify-self-end xl:order-3 md:col-span-2 xl:col-auto "
            onClick={() => borrarFiltros({ refInput: inputBuscador.current })}
          >
            {botones.filtros.borrar}
          </button> */}

          {/* agregar profesor */}
          <Link to={'/profesores/crear-profesores'} className="boton-justo center boton-amarillo boton_hover_morado xl:order-1 focusable-primary">
            {botones.agregar}
            <span className="icon-mas ml-3"></span>
          </Link>

          {/*descargar datos */}
          <button
            className="boton-justo px-5 xl:col-span-2 xl:justify-self-start center boton-amarillo boton_hover_morado xl:order-2 mt-6 md:mt-0 md:ml-5 focusable-primary"
            disabled={resultadosBusqueda?.resultados?.length > 0 ? false : true}
            onClick={descargarDatos}
          >
            {botones.descargar.lista}
            <span className="icon-descargar ml-3"></span>
          </button>

        </div>


        <ResultadosListaProfesores
          cambiarProfesoresElegidos={cambiarProfesoresElegidos}
          datosProfesores={resultadosBusqueda.resultados}
          preloader={preloader}
          textosTabla={textosInterfaz.vista_general.tabla}
          textoBotonEditar={textosInterfaz.botones.editar}
        />

        {/* barra de seleccionados */}
        {datosMultiSelect.numeroProfesores !== 0 &&
          <div
            role="region"
            aria-label={`acción de ${datosMultiSelect.numeroProfesores !== 1 ? multi_accion.profesores.singular : multi_accion.profesores.plural}`}
            className={`${accesibilidad.temaActual !== "clasico" && 'border-b-2 onbackground-var1--border-color'} multiselector fixed left-0 primary-var2--bg w-full py-4 transicion-200  ${datosMultiSelect.numeroProfesores !== 0 ? `animate-slideIn -top-0` : ` animate-slideOut -top-72`}`}
          >
            <div className='lg:flex'>
              <div className="lg:w-4/12  alineado-verticalmente pl-5 xl:pl-10">
                <button aria-label="desactivar seleccionados" className='onprimary-var1--color text-20 mr-3 hover:text-amarillo focusable-red' onClick={cancelarAccionMultiSelect}>
                  <span className='icon-cerrar'></span>
                </button>
                <p className='q7-20-blanco'>
                  {datosMultiSelect.numeroProfesores} {datosMultiSelect.numeroProfesores !== 1 ? multi_accion.profesores.singular : multi_accion.profesores.plural}
                </p>
              </div>
              <div className="lg:w-8/12  md:alineado-verticalmente lg:alineado-izquierda-center gap-4 px-5 xl:px-0 mt-5 lg:mt-0">
                <div className='md:w-4/12 lg:w-60'>
                  <SelectorDeBusqueda
                    cambiarElegido={cambiarOpcionMultiSelect}
                    estiloMultiAction={true}
                    filtro={filtros.sede}
                    filtroMensajeNoexite={select.sede.no_existe}
                    opciones={sedesSelect}
                    superposicion={true}
                    textoDefault={multi_accion.cambiar_sede}
                    textoElegido={datosMultiSelect.idSede !== null ? datosMultiSelect.nombreSede : multi_accion.cambiar_sede}
                  />
                </div>
                <div className="md:w-4/12 lg:w-60 mt-3 md:mt-0">
                  <SelectorDeBusqueda
                    cambiarElegido={cambiarOpcionCurso}
                    estiloMultiAction={true}
                    filtroMensajeNoexite={select.curso.no_existe}
                    opciones={cursosSede.resultados}
                    textoDefault={select.curso.default}
                    textoElegido={datosMultiSelect?.nombreCurso}
                    textoNoHayOpciones={multi_accion.sin_curso}
                  />
                </div>

                <button
                  className={`focusable-red md:w-4/12 lg:w-auto quicksand-700 text-17.6 center pt-3 md:pt-0 ${datosMultiSelect.idCurso !== null ? 'onprimary-var1--color hover:text-amarillo' : ` bg-transparent cursor-default ${accesibilidad.temaActual === "clasico" ? 'text-gris-alfa' : 'ondisabled-var1--color'}`}`}
                  onClick={() => {
                    if (datosMultiSelect.idCurso !== null) {
                      if (modalMover) {
                        MicroModal.show('estas-seguro-mover-profesores', {
                          awaitCloseAnimation: true
                        });
                      } else {
                        setModalMover(true)
                      }
                    }
                  }}
                >
                  <span className='icon-mover mr-3 lg:hidden xl:block'></span>
                  {botones.mover}
                </button>
                <button
                  className="focusable-red md:w-4/12 lg:w-auto mr-10 onprimary-var1--color hover:text-amarillo quicksand-700 text-17.6 center pt-3 md:pt-0"
                  onClick={() => {
                    if (modalEliminar) {
                      MicroModal.show('estas-seguro-eliminar', {
                        awaitCloseAnimation: true
                      });
                    } else {
                      setModalEliminar(true)
                    }
                  }}
                >
                  <span className="icon-basura mr-3 lg:hidden xl:block"></span>
                  {datosMultiSelect.numeroProfesores !== 1 ? botones.eliminar.plural : botones.eliminar.singular}
                </button>
              </div>
            </div>

          </div>
        }


        {/* Modal pregunta si desea mover profesores */}
        {modalMover &&
          <>
            <Modal nombreModal="estas-seguro-mover-profesores">
              <div className="pb-3">
                <img
                  alt="eyex frente a un bote de basura"
                  className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                  src='/images/eyex-pregunta.png'
                />
                <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_mover.titulo}</h1>
              </div>
              <div className="text-center r7-16-negro26 mb-4">
                <p>{modal_mover.descripcion_1} <span className="text-resaltado">{datosMultiSelect.numeroProfesores} {datosMultiSelect.numeroProfesores !== 1 ? multi_accion.profesores.singular : multi_accion.profesores.plural} </span> {modal_mover.descripcion_2} <span className="text-resaltado">{datosMultiSelect.nombreSede} </span></p>
              </div>
              <div className="flex justify-center mb-4">
                <button
                  onClick={() => {
                    MicroModal.close(`estas-seguro-mover-profesores`, {
                      awaitCloseAnimation: true
                    });
                  }}
                  className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
                >
                  {botones.cancelar}
                </button>
                <button
                  className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary"
                  onClick={() => {
                    moverProfesores();
                    setNumeroProfesoresSeleccionados(datosMultiSelect.numeroProfesores)
                  }}>
                  {preloader ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    :
                    <p>{botones.mover}</p>
                  }
                </button>
              </div>
            </Modal>

            {/* Modal confirmacion de profesores movidos */}
            <ModalConfirmacion nombreModal="profesor-movido">
              <button
                aria-label="Cerrar vetana"
                className="icon-cerrar text-20  right-6 absolute focusable-primary onbackground-var1--color"
                onClick={() => {
                  MicroModal.close(`profesor-movido`, {
                    awaitCloseAnimation: true
                  });
                }}>
              </button>

              <img
                alt="eyex feliz"
                className="w-5/12  mx-auto bg-img"
                src='/images/eyex-creado.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center "> {modal_exito.mover.titulo} </h1>

              <p className="text-center r7-16-negro26 mb-4">
                {numeroProfesoresSeleccionados !== 1 ? modal_exito.mover.descripcion.masivo : modal_exito.mover.descripcion.individual}
              </p>
            </ModalConfirmacion>
          </>
        }

        {/* Modal pregunta si desea eliminar profesores */}
        {modalEliminar &&
          <>
            <Modal nombreModal="estas-seguro-eliminar">
              <div className="pb-3">
                <img
                  alt="eyex frente a un bote de basura"
                  className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                  src='/images/eyex-eliminar.png'
                />
                <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_eliminar.titulo}</h1>
              </div>
              <div className="text-center r7-16-negro26 mb-4">
                <p>{modal_eliminar.descripcion_1} <span className="text-resaltado">{datosMultiSelect.numeroProfesores} {datosMultiSelect.numeroProfesores !== 1 ? multi_accion.profesores.singular : multi_accion.profesores.plural} </span> {modal_eliminar.descripcion_2} </p>
              </div>
              <div className="flex justify-center mb-4">
                <button
                  aria-label="Cancelar"
                  onClick={() => {
                    MicroModal.close(`estas-seguro-eliminar`, {
                      awaitCloseAnimation: true
                    });
                  }}
                  className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
                >
                  {botones.cancelar}
                </button>
                <button
                  className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary"
                  onClick={() => {
                    eliminarProfesores();
                    setNumeroProfesoresSeleccionados(datosMultiSelect.numeroProfesores)
                  }}>
                  {preloader ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    :
                    <p>{datosMultiSelect.numeroProfesores !== 1 ? botones.eliminar.plural : botones.eliminar.singular}</p>
                  }
                </button>
              </div>
            </Modal>

            {/* Modal confirmacion de profesores movidos */}
            <ModalConfirmacion nombreModal="profesor-eliminado">
              <button
                aria-label="Cerrar ventana"
                className="icon-cerrar text-20 right-6 absolute onbackground-var1--color focusable-primary"
                onClick={() => {
                  MicroModal.close(`profesor-eliminado`, {
                    awaitCloseAnimation: true
                  });
                }}>
              </button>

              <img
                alt="eyex feliz"
                className="w-5/12  mx-auto bg-img"
                src='/images/eyex-creado.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center "> {modal_exito.eliminar.titulo}</h1>

              <p className="text-center r7-16-negro26 mb-4">
                {numeroProfesoresSeleccionados !== 1 ? modal_exito.eliminar.descripcion.masivo : modal_exito.eliminar.descripcion.individual}
              </p>
            </ModalConfirmacion>
          </>
        }

      </div>
    </>
  )
}

export default ProfesoresGeneralComponent;

ProfesoresGeneralComponent.propTypes = {
  /**
   * función que se encarga de borrar los filtros que se encuentran activos ahora, estos incluyen al valor del input y del select. 
   * @type{Function({ refInput : string }):void} - callback
   */
  borrarFiltros: PropTypes.func.isRequired,

  /**
   * Función que busca profesores
   * @type{Function({ idSede : string , value: string }):void} - callback
   */
  buscarProfesores: PropTypes.func.isRequired,

  /**
   *  función que se encarga de almacenar los profesores seleccionados con el check.
   * @type{Function({ e : bool , idSede: string, profesor:string }):void} - callback
   */
  cambiarProfesoresElegidos: PropTypes.func.isRequired,

  /**
   * Función callback que cambia el curso por el que se desea filtrar
   * @type{Function({ idSede: string, idCurso :string }):void} - callback
   */
  cambiarCurso: PropTypes.func.isRequired,

  /**
   * Función callback que cambia la sede
   *  @type{Function({ idSede: string, tipoAccion :string , value: string}):void} - callback
   */
  cambiarSede: PropTypes.func.isRequired,

  /**
   *  función que se encarga de cancelar la acción del multi action. 
   */
  cancelarAccionMultiSelect: PropTypes.func.isRequired,

  /**
   * Objeto que contiene la información del curso
   */
  cursosSede: PropTypes.shape({
    hayCursos: PropTypes.bool,
    cursos: PropTypes.array
  }).isRequired,

  /**
   * objeto que almacena los datos relacionados a la selección multiple
   */
  datosMultiSelect: PropTypes.shape({
    cursos: PropTypes.shape({
      hayResultados: PropTypes.bool,
      resultados: PropTypes.array
    }),
    idCurso: PropTypes.string,
    idSede: PropTypes.string,
    nombreCurso: PropTypes.string,
    nombreSede: PropTypes.string,
    numeroProfesores: PropTypes.number,
    profesores: PropTypes.array,
  }).isRequired,

  /**
   *  función que se encarga de descargar la lista de profesores
   */
  descargarDatos: PropTypes.func.isRequired,

  /**
   * Función que se encarga de eliminar los profesores que se encuentran almacenados en el prop
   */
  eliminarProfesores: PropTypes.func.isRequired,

  /**
   * objeto que contiene dos propiedades que va a devolver cuando el API eliminar profesores. termine de responder.
   */
  respuestaEliminarProfesores: PropTypes.shape({
    isError: PropTypes.string,
    mensaje: PropTypes.string
  }).isRequired,

  /**
   * objeto que controla los filtros aplicados dentro de la sección de profesores.
   */
  filtros: PropTypes.shape({
    sede: PropTypes.string, //puede ser null
    curso: PropTypes.string
  }).isRequired,

  /**
   * función que se encarga de mover los profesores que se encuentran almacenados en el prop 
   */
  moverProfesores: PropTypes.func.isRequired,

  /**
   * objeto que contiene dos propiedades que va a devolver cuando el API mover profesores. termine de responder.
   */
  respuestaApiMoverProfesores: PropTypes.shape({
    isError: PropTypes.string,
    mensaje: PropTypes.string
  }).isRequired,

  /**
   *  Es un booleano que contiene el preloader para el llamado de las API de eliminar y mover profesores de sede. 
   */
  preloader: PropTypes.bool.isRequired,

  /**
   * Objeto con los resultados de la busqueda
   */
  resultadosBusqueda: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.arrayOf(PropTypes.shape({
      checked: PropTypes.bool,
      clubes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        nombre: PropTypes.string
      })),
      correo: PropTypes.string,
      id: PropTypes.string,
      idSede: PropTypes.string,
      nombre: PropTypes.string,
      nombreCursos: PropTypes.string,
      nombreSede: PropTypes.string
    }))
  }).isRequired,

  /**
   * un array de objetos que corresponden a las sedes creadas por la institución y que pueden ser consultadas por el usuario
   */
  sedes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,


  /**
   * Arreglo con la información de cada colegio
   */
  sedesSelect: PropTypes.array.isRequired,

  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired

}