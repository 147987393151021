import { useState, useEffect } from 'react';
//Store
import { useTrackedState } from "../../../store";
//Data
import DataTextos from "../../../modules/dataTextos.json";

/**
 * Retorna un objeto con los textos de los formularios de soporte.
 * @param {string} seccion - Seccion del formulario de soporte.
 * @returns {object} Objeto con los textos de los formularios de soporte.
 */
const useObtenerFormulariosSoporte = (seccion) => {
  //Estados globales
  const state = useTrackedState();
  const { idioma } = state;
  //Estados del componente
  const [textosSubSecciones, setTextosSubSecciones] = useState(undefined);
  const [isReady, setIsReady] = useState(false);


  useEffect(() => {
    const obetenerTraducciones = () => {
      const seccionEspecifica = DataTextos[seccion];
      let infoSeccion = {};

      seccionEspecifica.forEach(item => {
        const { estudiante, id, item: grupo, profesor, rector, seccion, subseccion } = item;
        infoSeccion[seccion] = { ...infoSeccion[seccion] };
        infoSeccion[seccion][subseccion] = { ...infoSeccion[seccion][subseccion] }

        if (infoSeccion[item.seccion][item.subseccion]) {
          if (subseccion !== 'titulo' && subseccion !== 'usuarios') {
            let info = id !== 'opciones' ? item[idioma] : get_opciones(item[idioma]);
            infoSeccion[seccion][subseccion][grupo] = { ...infoSeccion[seccion][subseccion][grupo], [id]: info }
            return
          }
          else {
            infoSeccion[seccion][subseccion] = subseccion === 'titulo'
              ? item[idioma]
              : {
                estudiante: convert_string_bolean(estudiante),
                profesor: convert_string_bolean(profesor),
                rector: convert_string_bolean(rector)
              }
            return
          }
        }
      })

      setIsReady(true);
      setTextosSubSecciones(infoSeccion);
    }
    obetenerTraducciones();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idioma, seccion])

  const convert_string_bolean = (value) => value === true ? true : false;

  const get_opciones = (value) => value.split(';')

  return { isReady, textosSubSecciones }
}

export default useObtenerFormulariosSoporte;