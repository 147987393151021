import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Miga, MigasDePan } from "../globales/MigaDePan";
import PropTypes from 'prop-types';

/**
 * Componente que recibe los textos necesarios para renderizar los recursos del salón y de pedagógicos.
 * @returns RecursosComponent
 */
const RecursosComponent = (props) => {

  const [recursoActivo, setRecursoActivo] = useState('salon')
  const cambiarRecursos = (recurso) => setRecursoActivo(recurso)

  const [pedagogicoLoad, setPedagogicoLoad] = useState(false);
  const [salonLoad, setSalonLoad] = useState(false);

  const { textosInterfaz, textosRecursos } = props;
  const { boton_para_el_salon, boton_pedagogicos, descripcion, titulo, boton_leer, boton_descargar, palabras_claves } = textosInterfaz;
  const { pedagogicos, salon } = textosRecursos;

  return (
    <>
      <MigasDePan>
        <Miga
        texto={titulo || 'Recursos'}
        esRutaActual={true}>
        </Miga>
      </MigasDePan>
      <div className="contenido">
        <h1 className="titulos-principales">{titulo}</h1>
        <p className="informacion-principal">
          {descripcion}
        </p>
        <div className="flex mt-5 mb-10">
          <button 
            aria-current= {recursoActivo === 'salon' ? 'page' : ''}
            className={`focusable-red ${recursoActivo === 'salon' ? "boton-justo boton-morado" : "boton-justo boton-morado_desactivado"}`} onClick={() => cambiarRecursos('salon')}>
            {boton_para_el_salon}
          </button>
          <button 
            aria-current= {recursoActivo === 'pedagogicos' ? 'page' : ''}
            className={`focusable-red ${recursoActivo === 'pedagogicos' ? "boton-justo boton-morado ml-5" : "boton-justo boton-morado_desactivado ml-5"}`} onClick={() => cambiarRecursos('pedagogicos')}>
            {boton_pedagogicos}
          </button>
        </div>
        {recursoActivo === 'pedagogicos' ?
          <>
            {pedagogicos.map((pedagogicos, index) =>
              <section key={`pedagogicos${index}`} className="flex flex-col md:flex-row justify-between border-t onsurface-var2--border-color py-5"  >
                <div className="md:w-3/12 xl:w-2/12 relative">
                  <img
                    src={pedagogicos.img}
                    alt={`Portada ${pedagogicos.titulo}`}
                    className={`img-libro ${!pedagogicoLoad ? " " : "shadow-2"}`}
                    style={{ height: !pedagogicoLoad && 272 }}
                    onLoad={() => setPedagogicoLoad(true)}
                  />
                  {
                    !pedagogicoLoad && (
                      <Skeleton aria-label={`Portada ${pedagogicos.titulo}`} width={175} height={272} className="absolute left-0 top-0" />
                    )
                  }
                </div>
                <div className="md:w-9/12 xl:w-10/12 flex flex-col justify-between md:pl-5 lg:pl-10 ">
                  <div className="mt-5 md:mt-0">
                    <h2 className="quicksand-700 text-20 pb-3 onbackground-var2--color">{pedagogicos.titulo}</h2>
                    <p className="q7-18 onsurface-var2--color">{pedagogicos.subtitulo}</p>
                    <p className="raleway-400  text-16 onbackground-var1--color italic">{pedagogicos.pais !== 'null' ? pedagogicos.pais : ''}</p>
                    <p className="raleway-400  text-16 onbackground-var1--color mb-5">{pedagogicos.descripcion !== 'null' ? pedagogicos.descripcion : ''}</p>
                    <p className="raleway-400  text-16 onbackground-var1--color mb-5"><strong className='mr-3'>{pedagogicos.palabras_clave !== 'null' && palabras_claves}:</strong>{pedagogicos.palabras_clave !== 'null' ? pedagogicos.palabras_clave : ''}</p>
                  </div>
                  <div className="flex justify-center md:justify-end">
                    <a
                      href={pedagogicos.link}
                      className="focusable-primary boton-justo boton-amarillo boton_hover_naranja text-22 px-9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {boton_leer}
                    </a>
                  </div>
                </div>
              </section>
            )}
          </>
          :
          <>
            <div className="xl:grid xl:grid-cols-2  xl:border-t xl: onsurface-var2--border-color">
              {salon.map((salon, index) =>
                <section key={`salon${index}`} className="flex flex-col  md:flex-row justify-between border-t onsurface-var2--border-color xl:border-0 xl:border-trasnparent py-5">
                  <div className="md:w-3/12 xl:w-4/12 relative">
                    <img
                      src={salon.img}
                      alt={`Portada ${salon.titulo}`}
                      className={`img-libro ${!salonLoad ? " " : "shadow-2"}`}
                      style={{ height: !salonLoad && 272 }}
                      onLoad={() => setSalonLoad(true)}
                    />
                    {
                      !salonLoad && (
                        <Skeleton className="absolute left-0 top-0 w-full h-full" />
                      )
                    }
                  </div>
                  <div className="p-4 md:w-9/12 xl:w-8/12 flex flex-col justify-between xl:justify-start md:pl-5 lg:pl-10 ">
                    <div className="mt-5 md:mt-0">
                      <h2 className="quicksand-700 text-20 pb-3 onbackground-var2--color">{salon.titulo}</h2>
                      <p className="q7-18 onsurface-var2--color mb-5">{salon.descripcion}</p>
                    </div>

                    <div className="flex justify-center md:justify-end xl:justify-start">
                      <a
                        href={salon.link}
                        target="_blank"
                        className="focusable-primary boton-justo boton-amarillo boton_hover_naranja text-17 px-9 xl:text-16 "
                      >
                        {boton_descargar}<span className="icon-descargar ml-2"></span>
                      </a>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </>
        }
      </div>
    </>
  )
}
export default RecursosComponent;

RecursosComponent.propTypes = {
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Es un objeto que trae 2 objetos; uno con la propiedad pedagogicos y otro con la propiedad salon. Dentro de cada uno de estos objetos se encuentran los textos de los recursos que se deben renderizar dependiendo del idioma activo.
   */
  textosRecursos: PropTypes.shape({
    pedagogicos: PropTypes.array.isRequired,
    salon: PropTypes.array.isRequired
  }).isRequired
}