import { useState, useEffect } from 'react';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Store
import { useTrackedState } from "../../store";
//Componentes
import InstitutosComponent from './../../components/institutos/index';
import SkeletonInstitutos from '../../components/institutos/SkeletonInstitutos';
import buscarElementos from '../../utilities/function/buscarElementos';
import PropTypes from 'prop-types';

/**
 * Container que recibe los datos iniciales y el estado isCrearSede. Este container retorna el componente InstitutosComponent al cual se le pasa una serie de propiedades para renderizar la pantalla principal de institutos/colegios. La función principal de este container es buscar y mostrar Institutos/colegios.
 * @returns {JSX.Element} EditarInstitutoComponent
 */
const GeneralInstituto = (props) => {
  const { isCrearSede, isLoading } = props;
  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('instituto')
  //Estados globales
  const state = useTrackedState();
  const { datosInstitucion, planActivo } = state;
  //Estados del componente 
  const [resultadosBusqueda, setResultadosBusqueda] = useState({
    hayResultados: true,
    resultados: []
  });


  /**
   * Buscador de institutos
   * @param {string} value
   */
  const buscarInstitutos = ({ value }) => {
    const resultados = buscarElementos({ value, datos: datosInstitucion.sedes })

    const resultadoBusqueda = {
      hayResultados: resultados.hayResultados,
      resultados: resultados.resultados
    }

    setResultadosBusqueda(resultadoBusqueda);
  }

  useEffect(() => {
    !!datosInstitucion && setResultadosBusqueda({ ...resultadosBusqueda, resultados: datosInstitucion.sedes })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosInstitucion])


  return (

    <>
      {!isLoading && isReady && !!planActivo && !!datosInstitucion ?
        <InstitutosComponent
          buscarInstitutos={buscarInstitutos}
          infoInstituto={{
            fechaFinSuscripcion: planActivo.fechaFinSuscripcion,
            numeroMaximoDeSedes: planActivo.numeroMaximoDeSedes,
            numeroSedesCreadas: datosInstitucion.sedes.length,
            sedes: datosInstitucion.sedes
          }}
          isCrearSede={isCrearSede}
          resultadosBusqueda={resultadosBusqueda}
          textosInterfaz={{
            botones: textosInterfaz.botones,
            info_sede: textosInterfaz.info_sede,
            miga_de_pan: textosInterfaz.miga_de_pan,
            nuevo: textosInterfaz.nuevo,
            vista_general: textosInterfaz.vista_general,
          }}
        />
        :
        <SkeletonInstitutos />
      }
    </>
  )
}

export default GeneralInstituto;

GeneralInstituto.propTypes = {
  /**
  * Estado de tipo booleano que valida si se puede seguir creando institutos/colegios.
  */
  isCrearSede: PropTypes.bool,

  /**
   * Estado de tipo booleano que valida si el instituto/colegio seleccionado existe y ha cargado.
   */
  isLoading: PropTypes.bool.isRequired,
}