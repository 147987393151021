import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

/**
 * 
 * Permite seleccionar o arrastrar un archivo y lo devuelve
 */
export const FileSelector = (props) => {
    const {
        extensionesPermitidas,
        cantidadMaximaArchivos,
        mensajeError,
        informacion,
        tituloBotonAgregar,
        alFallar,
        alCargarArchivo } = props;

    const [archivoValido, setArchivoValido] = useState(true);
    const [archivoCargado, setArchivoCargado] = useState(false);
    const [archivoNombre, setArchivoNombre] = useState('');

    const onDropAccepted = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                setArchivoNombre(file.name);
                setArchivoValido(true);
                setArchivoCargado(true);
                if(alCargarArchivo)alCargarArchivo(file);
            }
            reader.readAsArrayBuffer(file)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const onDropRejected = useCallback((fileRejections) => {
        fileRejections.forEach(() => {
            setArchivoValido(false);
            setArchivoCargado(false);
            if(alFallar)alFallar("Error loading file")
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDropAccepted: onDropAccepted,
        onDropRejected: onDropRejected,
        maxFiles: cantidadMaximaArchivos || 1,
        /* disabled: desactivar, */
        accept: extensionesPermitidas
    })

    useEffect(()=>{
        if(isDragActive){
            if(isDragAccept)setArchivoValido(true);
            if(isDragReject){
                setArchivoValido(false);
                if(alFallar)alFallar("Error loading file")
            }
        }else{
            setArchivoValido(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isDragAccept, isDragReject])



    return <div {...getRootProps({
        className: `inputFile h-36 cursor-pointer transicion-300  focusable-primary
        ${archivoValido === false && 'inputFile-incorrecto'}
        ${isDragActive && isDragAccept && 'inputFile-correcto'}
        ${isDragActive && isDragReject  && 'inputFile-incorrecto'}`
    })}>
        <input {...getInputProps()} />
        <div className="text-center raleway-500 text-14 onsurface-var1--color">
            <input {...getInputProps()} />
            <div className="text-center raleway-500 text-14 onsurface-var1--color">
                {archivoValido ?
                    <p className='pb-3'>{archivoCargado?archivoNombre:informacion}</p> :
                    <p className='pb-3 r7-rojo-alerta'>{mensajeError}</p>
                }
            </div>
            <div className='flex flex-row items-center justify-center cursor-pointer'>
                <p className={`cursor-pointer evento_rojo m-1`}>{tituloBotonAgregar}</p>
                <span className={`icon-subir ml-2 red-inv-var2--color`}></span>
            </div>
        </div>
    </div>
}


FileSelector.propTypes = {
    extensionesPermitidas: PropTypes.string.isRequired,
    cantidadMaximaArchivos: PropTypes.number.isRequired,
    /**
     * Información como el nombre del archivo actual o información adicional sobre el tipo de archivos validos.
     */
    informacion: PropTypes.string.isRequired,
        /**
     * define un mensaje para mostrar en caso tal de que el archivo no se pueda cargar
     */
    mensajeError: PropTypes.string.isRequired,
    /**
   * define el titulo para el botón de agregar archivo, si no se pasa esta propiedad el nombre por defecto es 'Adjuntar archivo'
   */
    tituloBotonAgregar: PropTypes.string,
    archivoPorDefecto: PropTypes.instanceOf(File),
    /** callback para cuando se seleccione y cargue correctamente un archivo
     * @returns Function(archivoCargado:File):void;
     */
    alCargarArchivo: PropTypes.func,
    /** callback para cuando el archivo no se cargue por algun motivo.
    * @returns Function(error:string):void;
    */
    alFallar: PropTypes.func
}

