import { useState, useEffect } from 'react';

/**
 * Este hook detecta si se esta navegando en un dispositivo movil, con base a las dimensiones de la pantalla.
 @returns {boolean} true si se esta navegando en un dispositivo movil, false en caso contrario.
*/
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992 ? true:false);

  //SET MOBILE
  useEffect(()=>{
    const setMobile = () => {
      let validacion;
      if(window.innerWidth < 992){
        validacion = true;
      }else{
        validacion = false;
      }
      setIsMobile(validacion)
    }
    setMobile();
    window.addEventListener('resize', setMobile)
    return () => {
      window.removeEventListener('resize', setMobile)
    }
  }, [])

  return isMobile;
}

export default useIsMobile;