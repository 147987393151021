import { useEffect, useState } from "react";
import { useTrackedState } from "../../store";
/**componentes */
import SelectorDeBusqueda from "../globales/elementosFormularios/SelectorDeBusqueda";
import PropTypes from 'prop-types';

/**
 * Componente que permite cargar libros con base en sedes y cursos.
 */
const SedesSelection = (props) => {
  const { cursosData, itemsActivos, sedesFilters, textosInterfaz } = props;
  const { setCursoActivo, setSedeActiva } = sedesFilters;
  const { sedes, cursos } = cursosData;
  const state = useTrackedState();

  /**estados del componente */
  const [elegidoSede, setElegidoSede] = useState('');
  const [elegidoCurso, setElegidoCurso] = useState('');

  /**useEffect */
  /**Actualiza el estado de sede elegida para que aparezca en el selector de Busqueda */
  useEffect(() => {
    let actualizarEstadoSede;
    sedes.map(item => {
      if (itemsActivos.sede === item.id) {
        actualizarEstadoSede = setElegidoSede(item.nombre)
      }
      return actualizarEstadoSede;
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsActivos.sede])


  /**Actualiza el estado de curso elegido para que aparezca en el selector de Busqueda correspondiente*/
  useEffect(() => {
    let actualizarEstadoCurso;
    cursos.map(item => {
      if (itemsActivos.curso === item.id) {
        actualizarEstadoCurso = setElegidoCurso(item.nombre)
      }
      return actualizarEstadoCurso;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsActivos.curso])

  /**Funciones */
  /**Actualiza el valor de la sede activa */
  const cambiarOpcionInstituto = ({ opcion }) => setSedeActiva(opcion.id)

  /**Actualiza el valor del curso activo */
  const cambiarOpcionCurso = ({ opcion }) => setCursoActivo(opcion.id)

  return (
    <>
      <div className="md:flex mt-5 lg:mt-3">
        {state?.planActivo?.tipoUsuario === 'rector' &&
          <>
            <div className={`${state?.planActivo?.tipoUsuario === 'rector' ? 'md:w-4/12 xl:w-3/12 md:mr-5' : ''}`}>
              <>
                <SelectorDeBusqueda
                  cambiarElegido={cambiarOpcionInstituto}
                  opciones={sedes}
                  superposicion={true}
                  textoDefault={textosInterfaz.sede.default}
                  textoElegido={elegidoSede}
                  zIndex={62}
                />
              </>


            </div>
            <div className="alineado-verticalmente mr-5">
              <span className="icon-atras transform rotate-180 hidden md:block"></span>
            </div>

          </>
        }


        <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0">
          <SelectorDeBusqueda
            cambiarElegido={cambiarOpcionCurso}
            filtroMensajeNoexite={textosInterfaz.curso.no_existen}
            opciones={cursos}
            textoDefault={textosInterfaz.curso.default}
            textoElegido={elegidoCurso}
            textoNoHayOpciones={textosInterfaz.curso.no_hay}
            zIndex={61}
          />
        </div>

      </div>
    </>
  )
}

export default SedesSelection;

SedesSelection.propTypes = {
  /**
   * Objeto con las sedes y cursos disponibles en la institución
   */
  cursosData: PropTypes.shape({
    sedes: PropTypes.arrayOf(PropTypes.object),
    cursos: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  /**
   * Objeto que devuelve el curso y sede actual.
   */
  itemsActivos: PropTypes.shape({
    curso: PropTypes.string.isRequired,
    sede: PropTypes.string.isRequired
  }).isRequired,
  /**
   * Objeto que permite actualiza la sede y el curso actual.
   */
  sedesFilters: PropTypes.shape({
    setCursoActivo: PropTypes.func.isRequired,
    setSedeActiva: PropTypes.func.isRequired
  }).isRequired,

  /**
   * Objeto con los textos de la interfaz del componente
   */
  textosInterfaz: PropTypes.object.isRequired
}