import { useContext } from "react";
import { Link } from "react-router-dom";
import { AccesibilidadContext, TemasDisponibles } from "../../contexts/AccesibilidadContext";


const EstadisticasComponent = (props) => {
  const { traducciones } = props;
  const { principal, botones } = traducciones;
  const { comparativa, estadisticas_libros, estadisticas_sesiones, estadisticas_individuales } = principal;
  const acc = useContext(AccesibilidadContext);

  const cajaContenedora = `shadow-4 background-var3--bg ${acc.temaActual===TemasDisponibles.altoContraste.id&&'onbackground-var1--bordered'} rounded-md w-full`;
  const tituloCaja = `rounded-t-md p-5 img-15-95 ${acc.temaActual===TemasDisponibles.altoContraste.id&&'border-b-2 onbackground-var1--borderb-color'}`;
  const descripcionCaja = `q5-18-negro26 `;
  const botonCaja = `focusable-red boton-justo boton-amarillo boton_hover_morado mt-5`;

  return (
    <>
      <div className="contenido">
        <h1 className="titulos-principales">{principal.titulo}</h1>
        <p className="informacion-principal">{principal.descripcion}</p>

        <div className=" grid md:grid-cols-2  gap-4 mt-5">
          {/* estadisticas comparativas  */}
          <div className={cajaContenedora}>
            <div
              className={`flex justify-between items-center ${acc.temaActual===TemasDisponibles.altoContraste.id?"primary-var1--bg":"bg-verde"} ${tituloCaja}`}
              
            >

              <p className="q7-20-negro26 w-10/12 ">{comparativa?.titulo || 'Reportes gráficos de estudiantes'}</p>
              <img style={{width:50, height:50}} className={`${acc.temaActual===TemasDisponibles.altoContraste.id&&'primary-inv-var1--bg'}`} src="/images/estadisticas/globales.svg" alt="globales"></img>
            </div>
            <div className="p-5">
              <p className={descripcionCaja}>{comparativa?.descripcion || 'Revisa estadísticas de lecturas y sesiones en la plataforma de los alumnos. También, puedes generar reportes comparativos entre (DOS) (TRES) sedes o cursos. '}</p>
              <div className="alineado-izquierda-center">
                <Link to='/estadisticas/estadisticas-globales' className={botonCaja}>{botones.ver_estadisticas}</Link>
              </div>
            </div>
          </div>

          {/* estadisticas de libros */}
          <div className={cajaContenedora}>
            <div
              className={`flex justify-between items-center ${acc.temaActual===TemasDisponibles.altoContraste.id?"primary-var1--bg":"bg-azul"} ${tituloCaja}`}
            >
              <p className="q7-20-blanco w-10/12 ">{estadisticas_libros.titulo}</p>
              <img style={{width:50, height:50}} className={`${acc.temaActual===TemasDisponibles.altoContraste.id&&'primary-inv-var1--bg'}`} src="/images/estadisticas/libros.svg" alt="libros"></img>
            </div>
            <div className="p-5">
              <p className={descripcionCaja}>{estadisticas_libros.descripcion}</p>
              <div className="alineado-izquierda-center">
                <Link to='/estadisticas/libros-leidos' className={botonCaja}>{botones.ver_estadisticas}</Link>
              </div>
            </div>
          </div>

          {/*  estadisticas de sesiones */}
          <div className={cajaContenedora}>
            <div
              className={`flex justify-between items-center ${acc.temaActual===TemasDisponibles.altoContraste.id?"primary-var1--bg":"bg-naranja-estadisticas"}  overflow-hidden ${tituloCaja}`}
            >
              <p className="q7-20-negro26 w-10/12 sm:una-linea-texto">{estadisticas_sesiones.titulo}</p>
              <img style={{width:50, height:50}} className={`${acc.temaActual===TemasDisponibles.altoContraste.id&&'primary-inv-var1--bg'}`} src="/images/estadisticas/sesiones.svg" alt="sesiones"></img>
            </div>
            <div className="p-5">
              <p className={descripcionCaja}>{estadisticas_sesiones.descripcion}</p>
              <div className="alineado-izquierda-center">
                <Link to='/estadisticas/sesiones-de-lectura' className={botonCaja}>{botones.ver_estadisticas}</Link>
              </div>
            </div>
          </div>

          {/* estadisticas individuales */}
          <div className={cajaContenedora}>
            <div
              className={`flex justify-between items-center ${acc.temaActual===TemasDisponibles.altoContraste.id?"primary-var2--bg":"bg-morado-estadisticas"}  ${tituloCaja}`}
            >
              <p className="q7-20-blanco w-10/12 ">{estadisticas_individuales.titulo}</p>
              <img style={{width:50, height:50}} className={`${acc.temaActual===TemasDisponibles.altoContraste.id&&'primary-inv-var1--bg'}`} src="/images/estadisticas/individuales.svg" alt="individuales"></img>
            </div>
            <div className="p-5">
              <p className={descripcionCaja}>{estadisticas_individuales.descripcion}</p>
              <div className="alineado-izquierda-center">
                <Link to='/estadisticas/ingresos-individuales' className={botonCaja}>{botones.ver_estadisticas}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default EstadisticasComponent;