import { useState } from 'react';
//Hooks y funciones personalizadas
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
import generalCallApi from '../helpers/generalCallApi';
//Store
import { useDispatch, useTrackedState } from "../../store";
//Componentes
import MiCuentaComponent from '../../components/miCuenta';
import SketonMiCuenta from '../../components/miCuenta/SkeletonMiCuenta';

/**
 * Container que no recibe props. Este container retorna el componente  MiCuentaComponent al cual se le pasa una serie de propiedades que le permiten renderizar la pantalla de mi cuenta. Las funciones principales de este container son cambiar información del usuario y actualizar avatar.
 * @returns {JSX.Element} MiCuentaComponent
 */
const DatosUsuario = () => {
  //Estados globales
  const state = useTrackedState();
  const { datosDeUsuario } = state;
  const dispatch = useDispatch()
  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('mi_cuenta');

  const [preloader, setPreloader] = useState({
    cambiarAvatar: false,
    cambiarDatosUsuario: false
  })

  const [respuestaApiAvatar, setRespuestaApiAvatar] = useState({
    isError: false,
    mensaje: ''
  })

  const [respuestaApiDatosUsuario, setRespuestaApiDatosUsuario] = useState({
    isError: false,
    mensaje: ''
  })

  /**
   * Función que cambia la información del usuario
   * @param {shape} data 
   */
  const cambiarInfoUsuario = async (data) => {
    if (!preloader.cambiarDatosUsuario) {
      setRespuestaApiDatosUsuario({
        isError: null,
        mensaje: ""
      });

      setPreloader({ ...preloader, cambiarDatosUsuario: true });
      const pathname = '/datosUsuario/actualizarDatosUsuario';
      const properties = data;
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        const newData = {
          ...datosDeUsuario,
          nombre: data.nombre,
          correo: data.correo,
        };

        dispatch({
          property: 'datosDeUsuario',
          type: 'SET_DATA',
          value: newData
        });
      }

      setRespuestaApiDatosUsuario({
        isError: error,
        mensaje: result.info
      });
      setPreloader({ ...preloader, cambiarDatosUsuario: false });
    }
  }

  /**
   * Función que cambia el avatar. Se encarga de ejecutar el API de cambiar avatar.
   * @param {number} value 
   */
  const cambiarAvatarUsuario = async (value) => {
    if (!preloader.cambiarAvatar) {
      setRespuestaApiAvatar({
        isError: null,
        mensaje: ""
      });
      setPreloader({ ...preloader, cambiarAvatar: true });
      const pathname = "/datosUsuario/cambiarAvatar";
      const properties = { nuevoAvatar: value };
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        const newData = {
          ...datosDeUsuario,
          avatar: `/images/avatares/avatar${value}.png`
        };

        dispatch({
          property: 'datosDeUsuario',
          type: 'SET_DATA',
          value: newData
        });
      }

      setRespuestaApiAvatar({
        isError: error,
        mensaje: result.info
      });
      setPreloader({ ...preloader, cambiarAvatar: false });
    }
  }

  return (
    <>
      {isReady && !!datosDeUsuario ?
        <MiCuentaComponent
          cambiarAvatar={cambiarAvatarUsuario}
          cambiarInfoUsuario={cambiarInfoUsuario}
          datosUsuario={datosDeUsuario}
          preloader={preloader}
          respuestaApiAvatar={respuestaApiAvatar}
          respuestaApiDatosUsuario={respuestaApiDatosUsuario}
          textosInterfaz={textosInterfaz}
        />
        :
        <SketonMiCuenta />
      }
    </>
  )
}

export default DatosUsuario;