import PropTypes from 'prop-types';

/**
 * Componente que recibe una serie de propiedades para rederizar una lista de radio button de los formularios 
 * @returns RadioButton
 */
const RadioButton = (props) => {
    const { arreglo, titulo, estadoEnviar, radioActivo, setRadioActivo, mensajeError, identificador } = props;

    const onKeyEnter = (event, index) => {
        if ((event.keyCode ? event.keyCode : event.which) == 13) {
            setRadioActivo(index);
        }
    }
    return (
        <>
            <h2 tabIndex={0} className="q7-18 onbackground-var1--color focus-visible:border-0 focus:outline-none">{titulo} <span className="asterisco">*</span></h2>
            {estadoEnviar && radioActivo === null &&
                <span
                    role={"alert"}
                    aria-live="assertive"
                    className={`text-14 alert_error`}
                >
                    {mensajeError}
                </span>
            }

            <div
                role="radiogroup" aria-labelledby={titulo}>
                {arreglo.opciones.map((tipo, index) =>
                    <label
                        id={identificador? identificador : 'radio'}
                        tabIndex={0}
                        role="radio"
                        aria-checked={radioActivo === index ? true : false}
                        key={`radio${index}`}
                        className={`radio-boton focusable-primary ${arreglo.opciones[index] !== arreglo.opciones[arreglo.opciones.length - 1] ? 'border-b onsurface-var2--border-color ' : ''}`}
                        onClick={() => { setRadioActivo(index) }}
                        onKeyUp={(e) => onKeyEnter(e, index)}
                    >
                        <input
                            type="radio"
                            value={tipo}
                            checked={radioActivo === index ? true : false}
                            readOnly
                            aria-hidden={true}
                        />
                        <i className="radio" ></i>
                        {tipo}
                    </label>
                )}
            </div>

        </>
    )
}

export default RadioButton;

RadioButton.propTypes = {
    /**
     * Objeto que contiene la información de la lista de radio buttons
     */
    arreglo: PropTypes.shape({
        error: PropTypes.string,
        opciones: PropTypes.arrayOf(PropTypes.string),
        titulo: PropTypes.string
    }),
    /**
     * Corresponde al titulo de la lista de radio buttons
     */
    titulo: PropTypes.string,
    /**
     * Booleano que indica si se muestra algún mensaje de error
     */
    estadoEnviar: PropTypes.bool,
    /**
     * Es el número que indica cuales de las opciones ha sido seleccionada.
     */
    radioActivo: PropTypes.number,
    /**
     * Función que sirve para cambiar el radioActivo
     * @type{Function( number):void}
     */
    setRadioActivo: PropTypes.func,
    /**
     * string que corresponde al texto de error o advertencia
     */
    mensajeError: PropTypes.string,
    /** 
     * Strign que identifica el grupo de radiosbuttons
     */
    identificador: PropTypes.string
}