import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonInstitutos = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={188} height={40} />
      <div className="contenido"  >
        <div className="relative">
          <h1 className="titulos-principales  ">
            <div className="md:w-96 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>

          <div className="md:absolute top-0 right-0 h-full flex items-center">
            <Skeleton className="mb-2" width={199} height={30} />
          </div>
        </div>
        <div className="relative">
          <div className="xl:w-40 h-12 xl:h-24 xl:absolute top-0 right-0 my-5 md:my-0 xl:rounded-b-md" >
            <Skeleton width={'100%'} height={'100%'} />
          </div>

          <Skeleton className="mt-3" width={457} height={28} />

          <div className=" w-full lg:w-451p mt-3">
            <Skeleton width={457} height={36} />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-11 mt-10">
          <Skeleton className="h-96" width={'100%'} />
          <Skeleton className="h-96" width={'100%'} />
        
        </div>
      </div>
    </SkeletonTheme>
  )
}
export default SkeletonInstitutos;