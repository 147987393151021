import { useRef } from "react";
import { Link } from "react-router-dom";
import { Miga, MigasDePan } from "../globales/MigaDePan";

/**componentes */
import ClubCreado from "./ClubCreado";
import CajaCrear from "../globales/cajasCrear/CajaCrear";
import NoHayResultados from "../globales/NoHayResultados";
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de propiedades que permiten renderizar la pantalla principal de clubs. Además, renderiza los componentes ClubCreado, CajaCrear y NoHayResultados.
 * @returns GeneralClubsComponent
 */
const GeneralClubsComponent = (props) => {
  const { buscarClubs, resultadosBusqueda, textosInterfaz } = props;
  const { miga_de_pan, vista_general, botones, info_club, nuevo } = textosInterfaz;
  const { busqueda, header, tool_tips } = vista_general;


  //Referencias
  const inputBuscador = useRef(null);


  return (
    <>
      <MigasDePan>
        <Miga
        texto={miga_de_pan.principal || 'Clubs'}
        esRutaActual={true}>
        </Miga>
      </MigasDePan>
      <div className="contenido">
        <h1 className="titulos-principales">{header.titulo}</h1>
        <p className="informacion-principal ">{header.descripcion}</p>

        <div className="w-full md:alineado-verticalmente justify-between my-6">
          <div className="md:w-6/12 my-6 md:my-0">
            <Link to='/clubs/crear-club' className='focusable-primary boton-justo boton-amarillo boton_hover_morado py-2'>{nuevo.titulo}  <span className="icon-mas ml-3"></span></Link>
          </div>

          <div className="md:w-6/12 xl:w-451p ">
            <div className="relative w-full h-full">
              <input
                className="w-full"
                onChange={() => { buscarClubs({ value: !!inputBuscador.current ? inputBuscador.current.value : '' }) }}
                placeholder={busqueda.placeholder}
                ref={inputBuscador}
                type="text"
              />

              <button aria-label="Botón buscar" className="focusable-primary absolute right-0 top-0 w-10 h-full">
                <span className="icon-lupa onsurface-var1--color"></span>
              </button>
            </div>
          </div>

          {/* <div className="w-full alineado-izquierda my-6 mt-3">
            <button
              className="boton-pequenio boton-rojo boton_hover_morado"
              onClick={() => borrarFiltros({ refInput: inputBuscador.current })}
            >
              {botones.filtros.borrar}
            </button>
          </div> */}
        </div>


        {
          resultadosBusqueda.hayResultados
            ? (
              <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                <ClubCreado
                  resultados={resultadosBusqueda.resultados}
                  textos={info_club}
                  textoBotonEditar={botones.editar.club}
                  tooltips={tool_tips}
                />
                <CajaCrear
                  textoCaja={nuevo.titulo} link='/clubs/crear-club' altura={302}
                />
              </section>
            )
            : <NoHayResultados descripcion={busqueda.descripcion} titulo={busqueda.titulo} />
        }
      </div>
    </>
  )
}

export default GeneralClubsComponent;

GeneralClubsComponent.propTypes = {
  /**Función que busca los clubs */
  buscarClubs: PropTypes.func,
  /**
   * objeto que cuenta con 2 propiedades
   */
  resultadosBusqueda: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.array
  }),
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
   */
  textosInterfaz: PropTypes.object
}