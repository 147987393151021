import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonGeneralEstadisticas = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={188} height={40} />
      <div className="contenido">

        <h1 className="titulos-principales  ">
          <div className="md:w-40 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <div className="py-3 md:w-490p"  >
          <Skeleton width={'100%'} height={30} />
        </div>

        <div className="md:alineado-verticalmente mt-3 mb-5 gap-4">
          <div className="md:w-4/12 xl:w-3/12">
            <Skeleton width={'100%'} height={36} />
          </div>
          <span className="icon-atras transform rotate-180 hidden md:block"></span>
          <div className="md:w-4/12 xl:w-3/12 mt-5 md:mt-0">
            <Skeleton width={'100%'} height={36} />
          </div>
          <span className="icon-atras transform rotate-180 hidden md:block"></span>
          <div className="md:w-4/12  xl:w-3/12 mt-5 md:mt-0">
            <Skeleton width={'100%'} height={36} />
          </div>
        </div>

        <hr />

      </div>

    </SkeletonTheme>
  )
}
export default SkeletonGeneralEstadisticas;