import { useState, useEffect } from 'react';
import Selector from "../../globales/elementosFormularios/Selector";
import ProblemaLibro from "./ProblemaLibro";
import DudaVentas from "./DudaVentas";
import SugerenciasYComentarios from "./SugerenciasYComentarios";
import ProblemaPlataforma from './ProblemaPlataforma';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de propiedades que le permiten renderizar el formulario correspondiente a la opción seleccionada.Además, hace uso de los componentes Selector, ProblemaLibro, DudaVentas, SugerenciasYComentarios y ProblemaPlataforma.
 * @returns {JSX.Element} ProblemaLibro, DudaVentas, SugerenciasYComentarios o ProblemaPlataforma.
 */
const Formularios = (props) => {
  const { textosInterfaz, tiposDeProblema, textosFormulariosSoporte, enviarFormulario, mostrarModal,
    ocultarModal, preloader } = props;
  const { duda_ventas, problema_libro, sugerencias, problema_plataforma } = textosFormulariosSoporte;
  const { enviarProblemaLibro, enviarProblemaPlataforma, enviarDudasVentas, enviarSugerenciasYComentarios } = enviarFormulario;
  const [textProblema, setTextProblema] = useState('');
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
  const [opcionSelecciodaIndex, setOpcionSelecciodaIndex] = useState(null);

  const cambiarProblemaElegido = ({ opcion, index }) => {
    setOpcionSeleccionada(opcion.id);
    setOpcionSelecciodaIndex(index);
    setTextProblema(opcion.nombre);
  };

  useEffect(() => {
    opcionSelecciodaIndex !== null && setTextProblema(tiposDeProblema[opcionSelecciodaIndex].nombre);
    ocultarModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiposDeProblema])

  return (
    <>
      <div className="bg-blanco background-var3--bg border onprimary-var1--border-color shadow-2 px-5 py-3">
        <h1 className="quicksand-700 onbackground-var1--color text-20 mt-3 mb-5">{textosInterfaz.titulo_formulario}</h1>

        <Selector
          cambiarElegido={cambiarProblemaElegido}
          opciones={tiposDeProblema}
          textoDefault={textosInterfaz.placeholder_area_problema}
          textoElegido={textProblema}
          superposicion={true}
        />

        <div className="mt-5">
          {opcionSeleccionada === 'problema_libro' &&
            <>
              <ProblemaLibro
                info={problema_libro.info}
                textosInterfaz={textosInterfaz}
                enviarASoporte={enviarProblemaLibro}
                preloader={preloader}
                mostrarModal={mostrarModal}
                ocultarModal={ocultarModal} />
            </>
          }

          {opcionSeleccionada === 'problema_plataforma' &&
            <>
              <ProblemaPlataforma
                info={problema_plataforma.info}
                textosInterfaz={textosInterfaz}
                enviarASoporte={enviarProblemaPlataforma}
                preloader={preloader}
                mostrarModal={mostrarModal}
                ocultarModal={ocultarModal}
              />
            </>
          }

          {opcionSeleccionada === 'duda_ventas' &&
            <>
              <DudaVentas
                info={duda_ventas.info}
                textosInterfaz={textosInterfaz}
                enviarASoporte={enviarDudasVentas}
                preloader={preloader}
                mostrarModal={mostrarModal}
                ocultarModal={ocultarModal}
              />
            </>
          }

          {opcionSeleccionada === 'sugerencias' &&
            <>
              <SugerenciasYComentarios
                info={sugerencias.info}
                textosInterfaz={textosInterfaz}
                enviarASoporte={enviarSugerenciasYComentarios}
                preloader={preloader}
                mostrarModal={mostrarModal}
                ocultarModal={ocultarModal}
              />
            </>
          }
        </div>
      </div>
    </>
  )
}
export default Formularios;

Formularios.propTypes = {
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
  * array que trae los títulos de los formularios de soporte a los que tiene acceso nuestro usuario loggeado.
  */
  tiposDeProblema: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,
  /**
   * objeto que trae la información de los formularios de soporte que tiene acceso el usuario que está loggeado en el idioma activo.
   */
  textosFormulariosSoporte: PropTypes.shape({
    titulo: PropTypes.string,
    info: PropTypes.object
  }).isRequired,
  /**
   * Es un objeto que contiene las funciones de enviar formulario. 
   */
  enviarFormulario: PropTypes.shape({
    enviarProblemaLibro: PropTypes.func,
    enviarProblemaPlataforma: PropTypes.func,
    enviarDudasVentas: PropTypes.func,
    enviarSugerenciasYComentarios: PropTypes.func
  }).isRequired,
  /**
   * Booleano que indica si debe aparecer la ventana modal de enviado
   */
  mostrarModal: PropTypes.bool.isRequired,
  /**
    * Función que oculta la ventana modal
    */
  ocultarModal: PropTypes.func.isRequired,
  /**
  * booleano que contiene el preloader para el llamado de la API EmailAPI
  */
  preloader: PropTypes.bool.isRequired
}