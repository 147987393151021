
/**
 * Esta clase se realizó con el fin de poder utilizar AbortController en navegadores que lo soporten 
 * y no romper la app en los que no soportan AbortController.
 * Devuelve propiedades, objetos y metodos similares a AbortController
 * Verifica que exista Abortcontroller y si existe, devuelve su instancia
 * En caso tal de que no exista, devuelve las propiedades, metodos, definidos acá pero que realmente no tienen un efecto para cancelar peticiones.
 */
export class CancelRequestController{

    signal = {
        aborted:false,
        reason:"",
        addEventListener:()=>{},
        removeEventListener:()=>{},
    };
    abortController = {};

    constructor(){
        try{
            if(Boolean(AbortController)){
                this.abortController = new AbortController();
                this.signal = this.abortController.signal;
            }
        }
        catch(error){
            console.log("This browser doesn't support AbortController", error)
        }
       
    }

    abort(){
        try{
            if(Boolean(AbortController) && 
            Boolean(this.abortController)
            && this.abortController?.abort){
                this.abortController.abort();
            } 
        }
        catch(error){
            console.log("This browser doesn't support AbortController", error)
        }
       
    }
}