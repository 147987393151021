import { useRef } from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

/**
 * Componente que recibe una serie de propiedades que sirven para renderizar la caja de club creado.
 * @returns ClubCreado
 */
const ClubCreado = (props) => {
  const { resultados, textos, textoBotonEditar, tooltips } = props;
  /*   const { estadisticas, estudiante, libro } = textos; */
  const { estadisticas, estudiante, libro } = textos;

  const cajaClubCreado = useRef(null);

  return (
    <>
      {
        resultados.map((club) => {
          return (
            <article key={club.id} className="caja-creado m-3" ref={cajaClubCreado}>
              <div className="caja-creado-titulo surface-var1--bg alineado-verticalmente relative overflow-hidden ">
                <img className="caja-creado-titulo-img-2 img-80-center" src={`/images/badges/badge-${parseInt(club.icono)}.png`} alt="icono del club" />
                <p className="q7-20-blanco mr-40 una-linea-texto">{club.nombre}</p>
              </div>

              <div className=" px-5 xl:px-8 ">
                <div className="caja-creado-contenido-fila gap-4">
                  <div className="alineado-verticalmente w-8/12">
                    <span className="icon-libro caja-creado-icono onsurface-var1--color"></span>

                    <div className="w-full alineado-verticalmente">
                      <p className={!club.numeroTotalDeLibros ? "q7-16-negro26" : "q4-16-negro26"}>
                        {club.numeroTotalDeLibros} {club.numeroTotalDeLibros !== 1 ? libro.plural : libro.singular}

                        {!club.numeroTotalDeLibros &&
                          <span
                            tabIndex={0}
                            className="focusable-primary icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
                            data-tip={true}
                            data-scroll-hide={true}
                            data-for='tool-libro'
                          >
                            <span className='descripcion-tool'>
                              {tooltips.estudiantes}
                            </span>
                          </span>
                        }
                      </p>
                      {!club.numeroTotalDeLibros &&
                        <ReactTooltip
                          backgroundColor="red-inv-var2--bg"
                          className="quicksand-400 red-inv-var2--bg background-var3--color"
                          id="tool-libro"
                          place="top"
                          textColor="#FFFFFF"
                          event="mouseover mouseenter click"
                          eventOff="mouseleave mouseout scroll mousewheel blur"
                          effect="solid"
                        >
                          <p>{tooltips.libros}</p>
                        </ReactTooltip>
                      }
                    </div>
                  </div>
                </div>


                <div className="caja-creado-contenido-fila gap-4">
                  <div className="alineado-verticalmente w-8/12">
                    <span className="icon-usuario caja-creado-icono onsurface-var1--color"></span>

                    <div className="w-full alineado-verticalmente">
                      <p className={!club.numeroTotalDeEstudiantes ? "q7-16-negro26" : "q4-16-negro26"}>
                        {club.numeroTotalDeEstudiantes} {club.numeroTotalDeEstudiantes !== 1 ? estudiante.plural : estudiante.singular}

                        {!club.numeroTotalDeEstudiantes &&
                          <span
                            tabIndex={0}
                            className="focusable-primary icon-alerta red-inv-var2--color text-18 cursor-pointer ml-3"
                            data-tip={tooltips.estudiantes}
                            data-scroll-hide={true}
                            data-for='tool-estudiantes'
                          >
                            <span className='descripcion-tool'>
                              {tooltips.estudiantes}
                            </span>
                          </span>
                        }
                      </p>
                      {!club.numeroTotalDeEstudiantes &&
                        <ReactTooltip
                          backgroundColor="red-inv-var2--bg"
                          className="quicksand-400 red-inv-var2--bg background-var3--color"
                          id="tool-estudiantes"
                          place="top"
                          textColor="#FFFFFF"
                          event="mouseover mouseenter click"
                          eventOff="mouseleave mouseout scroll mousewheel blur"
                          effect="solid"
                        >
                          <p>{tooltips.estudiantes}</p>
                        </ReactTooltip>
                      }
                    </div>
                  </div>
                </div>


                <div className="center flex-col mt-5 mb-7" >
                  {/* <Link
                    to={{
                      pathname: "./estadisticas",
                      search: `?club=${club.idClub}`
                    }}
                    className="alineado-verticalmente"
                  >
                    <span className="icon-estadisticas-barra mr-5 text-rojo text-22 mt-1"></span>
                    <span className="evento_rojo">{estadisticas}</span>
                  </Link> */}

                  <Link
                    to={`/clubs/editar-club/${club.id}`}
                    className="focusable-primary boton-amarillo boton_hover_morado boton-justo mt-6"
                  >
                    {textoBotonEditar}
                  </Link>
                </div>
              </div>

            </article>
          )
        })
      }
    </>
  )
}
export default ClubCreado;

ClubCreado.propTypes = {
  /**
   * arreglo de objetos que contiene la información de los clubes creados
   */
  resultados: PropTypes.array,
  /**
   * objeto que contiene los textos de interfaz de la caja creado
   */
  textos: PropTypes.shape({
    estadisticas: PropTypes.string,
    estudiante: PropTypes.shape({
      plural: PropTypes.string,
      singular: PropTypes.string
    }),
    libro: PropTypes.shape({
      plural: PropTypes.string,
      sin_libro: PropTypes.string,
      singular: PropTypes.string
    })
  }),
  /**
   * string que corresponde al texto del botón
   */
  textoBotonEditar: PropTypes.string,
  /**
   * objeto que corresponde a los textos de los tooltips
   */
  tooltips: PropTypes.shape({
    estudiantes: PropTypes.string,
    libros: PropTypes.string
  })
}