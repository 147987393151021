import { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import CajaCrear from "../globales/cajasCrear/CajaCrear";
import InstitutoCreado from './InstitutoCreado';
import NoHayResultados from '../globales/NoHayResultados';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de props que le permiten renderizar la pantalla principal de institutos/colegios. Además, renderiza  los componentes CajaCrear, NoHayResultados y el componente InstitutoCreado.
 * @returns InstitutoComponent
 */

const InstitutoComponent = (props) => {
  const { buscarInstitutos, isCrearSede, resultadosBusqueda, textosInterfaz, infoInstituto } = props;

  const { miga_de_pan, vista_general, nuevo, botones, info_sede } = textosInterfaz;
  const { header, sede_asignada, suscripcion, busqueda } = vista_general;
  const { numeroSedesCreadas, numeroMaximoDeSedes, fechaFinSuscripcion } = infoInstituto;
  const [estaBuscando, setEstaBuscando] = useState(false);
  const [alturaCajaCreado, setAlturaCajaCreado] = useState();

  const refInput = useRef(null);

  /**
   * Función que actualiza la altura de las cajas creadas y el botón de crear.
   * @param {number} altura 
   */
  const actualizarAlturaCajaCreado = (altura) => {
    setAlturaCajaCreado(altura);
  }

  return (
    <>
      <div className="ruta">
        <p>{miga_de_pan.principal}</p>
      </div>

      <div className="contenido h-full">
        <div className="relative">
          <h1 className="titulos-principales">{header.titulo}</h1>
          <div className="hidden md:flex h-full items-center absolute top-0 right-0">
            <p className="q7-morado text-20"> {`${numeroSedesCreadas}/${numeroMaximoDeSedes} ${sede_asignada} `}</p>
          </div>
        </div>
        <div className="relative">
          <div className="xl:absolute top-0 right-0 red-inv-var2--bg xl:w-40 xl:rounded-b-md p-3 text-center">
            <p className="onred-inv-var1--color raleway-700">{`${suscripcion}`}</p>
            <p className="onred-inv-var1--color raleway-700">{`${fechaFinSuscripcion}`}</p>
          </div>
          <p className="informacion-principal">{header.descripcion}</p>
          <p className="q7-morado text-20 md:hidden"> {`${numeroSedesCreadas}/${numeroMaximoDeSedes} ${sede_asignada} `}</p>

          <div className="relative w-full lg:w-451p mt-3 mb-5">
            <input type="text" className="w-full" placeholder={busqueda.placeholder}
              onChange={e => { buscarInstitutos({ value: e.target.value }); setEstaBuscando(e.target.value !== '' ? true : false) }}
              ref={refInput}
            />

            <button className="focusable-secondary absolute right-0 top-0 w-10 h-full">
              <span className="icon-lupa onsurface-var1--color"></span>
            </button>
          </div>

          <Link to='/institutos/crear-instituto' className='focusable-primary boton-justo boton-amarillo boton_hover_morado py-2'>{botones.crear}  <span className="icon-mas ml-3"></span></Link>
        </div>

        {resultadosBusqueda.hayResultados ?
          <div className="grid md:grid-cols-2 gap-6 xl:gap-11 mt-10">

            <InstitutoCreado
              botones={botones}
              cambiarAlturaCajaCreado={actualizarAlturaCajaCreado}
              estadoAltura={alturaCajaCreado}
              infoSede={info_sede}
              resultados={resultadosBusqueda.resultados}
            />

            {isCrearSede && !estaBuscando &&
              <CajaCrear
                altura={alturaCajaCreado}
                textoCaja={nuevo.titulo} link='/institutos/crear-instituto'
              />
            }
          </div>
          :
          <div className='mt-20'>
            <NoHayResultados descripcion={busqueda.descripcion} titulo={busqueda.titulo} />
          </div>
        }
      </div>
    </>
  )
}
export default InstitutoComponent;

InstitutoComponent.propTypes = {
  /**
   * Función callback que recibe un parámetro llamado value que hace referencia al valor del input de búsqueda.
   * @type{Function({ value : string }):void} - callback
   */
  buscarInstitutos: PropTypes.func.isRequired,

  /**
   * Es un booleano que indica si el usuario puede seguir creando sedes para su instituto.
   */
  isCrearSede: PropTypes.bool.isRequired,

  /**
   *  Es un objeto que cuenta con 2 propiedades:
   * 1.hayResultados:  Es un booleano que indica si hay resultados para la búsqueda hecha por el usuario.
   * 2.resultados:Es un array de objetos que contiene la información de los institutos filtrados por la búsqueda
   */
  resultadosBusqueda: PropTypes.shape({
    hayResultados: PropTypes.bool,
    resultados: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      cursos: PropTypes.array,
      fechaFinSuscripcion: PropTypes.string, //yyyy-mm-dd
      id: PropTypes.string.isRequired,
      nombre: PropTypes.string,
      numeroDeLibrosAsignados: PropTypes.number,
      numeroTotalDeEstudiantes: PropTypes.number,
      numeroTotalDeProfesores: PropTypes.number,
      paquete: PropTypes.shape({
        id: PropTypes.string.isRequired,
        nombre: PropTypes.string
      })
    })).isRequired
  }).isRequired,

  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * objeto que contiene la información del instituto
   */
  infoInstituto: PropTypes.shape({
    fechaFinSuscripcion: PropTypes.string, 
    numeroMaximoDeSedes: PropTypes.string, 
    numeroSedesCreadas: PropTypes.number,
    sedes: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      cursos: PropTypes.array,
      fechaFinSuscripcion: PropTypes.string, //yyyy-mm-dd
      id: PropTypes.string.isRequired,
      nombre: PropTypes.string,
      numeroDeLibrosAsignados: PropTypes.number,
      numeroTotalDeEstudiantes: PropTypes.number,
      numeroTotalDeProfesores: PropTypes.number,
      paquete: PropTypes.shape({
        id: PropTypes.string.isRequired,
        nombre: PropTypes.string
      })
    }))
  }).isRequired,
}