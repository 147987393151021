import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

// Register font
Font.register({
  family: 'Raleway', fonts: [
    { src: require('../../../fonts/raleway/raleway-regular.ttf').default },
    { src: require('../../../fonts/raleway/raleway-black.ttf').default, fontStyle: 'black' },
    { src: require('../../../fonts/raleway/raleway-bold.ttf').default, fontStyle: 'bold' },
  ]
});
Font.register({
  family: 'Quicksand', fonts: [
    { src: require('../../../fonts/quicksand/quicksand-regular.ttf').default },
    { src: require('../../../fonts/quicksand/quicksand-bold.ttf').default, fontStyle: 'bold' },
  ]
});

const stylesGeneral = StyleSheet.create({
  bulletPoint: {
    color: '#FF3548',
    fontSize: 20,
    width: 10,
  },
  contenedor: {
    paddingBottom: 10
  },
  descripcion: {
    fontFamily: 'Quicksand',
  },
  item: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 10,
    width: '100%'
  },
  itemContent: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
  subtitulo: {
    fontFamily: 'Quicksand',
    fontStyle: 'bold',
    marginBottom: 5,
    width: 'auto'
  },
  textoBold: {
    fontStyle: 'bold',
  },
  tituloSeccion: {
    color: '#FFD100',
    fontFamily: 'Raleway',
    fontSize: 20,
    fontStyle: 'black',
    marginBottom: 10
  }
});

const stylesHeader = StyleSheet.create({
  header: {
    alignItems: 'center',
    borderBottom: '1px solid #7F4098',
    flexDirection: 'row',
    marginBottom: 10,
    paddingBottom: 20,
    width: '100%'
  },
  image: {
    marginBottom: 10,
    marginRight: 50,
    width: "15%",
    flexGrow: 1
  },
  titulo: {
    color: '#6666CC',
    fontFamily: 'Raleway',
    fontSize: 30,
    fontStyle: 'black',
    flexGrow: 1,
    width: "60%"
  },
});


const HeaderPDF = (props) => {
  const { advertencias } = props

  return (
    <View style={stylesHeader.header}>
      <Image style={stylesHeader.image} src='/images/logo-makemake.png' />
      <Text style={stylesHeader.titulo}>{advertencias.datos.titulo}</Text>
    </View>
  )
}

const stylesTabla = StyleSheet.create({
  contenedorFilas: {
    flexDirection: 'column',
    flexGrow: 2
  },
  curso: {
    flexDirection: 'row',
    width: '100%',
  },
  fila: {
    flexDirection: 'row',
    width: '100%'
  },
  header: {
    alignItems: 'center',
    backgroundColor: '#9999FF',
    flexDirection: 'row',
    width: '100%',
  },
  itemFila: {
    border: "0.8px solid #A3A1A8",
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: '50%'
  },
  itemHeader: {
    border: "0.8px solid white",
    color: 'white',
    fontStyle: 'black',
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: '33.33%'
  },
  itemHeaderProfesor: {
    border: "0.8px solid white",
    color: 'white',
    fontStyle: 'black',
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: '50%'
  },
  nombreCurso: {
    border: "0.8px solid #A3A1A8",
    paddingHorizontal: 10,
    paddingTop: 5,
    width: '33.33%'
  },
  tabla: {
    flexDirection: 'column',
    marginTop: 10,
    width: '100%'
  },
});

//Cursos
const DatosIncompletosEstudiantes = (props) => {
  const { titulo, descripcion, datos, header } = props;

  const cursos = () => {
    let hojaIncompletas = []
    for (const key in datos) {
      const cursos = datos[key]
      let lineasHoja = []
      hojaIncompletas.push(
        <View style={stylesTabla.curso} key={`estudianteIncompleto_${key}`}>
          <Text style={stylesTabla.nombreCurso}>{key}</Text>

          <View style={stylesTabla.contenedorFilas}>
            {(() => {
              for (const key in cursos) {
                lineasHoja.push(
                  <View style={stylesTabla.fila} key={`fila_estudianteIncompleto_${key}`}>
                    <Text style={stylesTabla.itemFila}>{key}</Text>
                    <Text style={stylesTabla.itemFila}>{cursos[key].join(', ')}</Text>
                  </View>
                )
              }
              return lineasHoja;
            })()
            }
          </View>
        </View>
      )
    }
    return hojaIncompletas
  }

  return (
    <View style={stylesGeneral.contenedor}>
      <View>
        <Text style={stylesGeneral.subtitulo}>{titulo}</Text>
        <Text style={stylesGeneral.descripcion}>{descripcion}</Text>
      </View>
      <View style={stylesTabla.tabla}>
        <View style={stylesTabla.header} >
          <Text style={stylesTabla.itemHeader}>{header.hoja}</Text>
          <Text style={stylesTabla.itemHeader}>{header.filas}</Text>
          <Text style={stylesTabla.itemHeader}>{header.campos}</Text>
        </View>
        {cursos()}
      </View>
    </View>
  )
}

const Cursos = (props) => {
  const { advertencias } = props;

  const sinEstudiantes = () => {
    return (
      <View style={stylesGeneral.contenedor}>
        <View>
          <Text style={stylesGeneral.subtitulo}>{advertencias.datos.cursos.sin_estudiantes.titulo}</Text>
          <Text style={stylesGeneral.descripcion}>{advertencias.datos.cursos.sin_estudiantes.mensaje}</Text>
        </View>
        <View>
          {
            advertencias.datos.cursos.sin_estudiantes.data.map(curso => {
              return (
                <View style={stylesGeneral.item} key={`sinEstudiantes_${curso}`}>
                  <Text style={stylesGeneral.bulletPoint}>•</Text>
                  <Text>{curso}</Text>
                </View>
              )
            })
          }
        </View>
      </View>
    )
  }


  const sinCamposObligatorios = () => {
    return (
      <View style={stylesGeneral.contenedor}>
        <View>
          <Text style={stylesGeneral.subtitulo}>{advertencias.datos.cursos.header.titulo}</Text>
          <Text style={stylesGeneral.descripcion}>{advertencias.datos.cursos.header.mensaje}</Text>
        </View>
        <View>
          {(() => {
            let hojasIncompletas = []
            for (const key in advertencias.datos.cursos.header.campos) {
              const cursos = advertencias.datos.cursos.header.campos[key]
              hojasIncompletas.push(
                <View style={stylesGeneral.item} key={`sinCampoEstudiantes_${key}`} >
                  <Text style={stylesGeneral.bulletPoint}>•</Text>
                  <Text style={stylesGeneral.textoBold}>{key}: </Text>
                  <Text>{cursos.join(', ')}</Text>
                </View>
              )
            }
            return hojasIncompletas
          })()}
        </View>
      </View>
    )
  }



  return (
    <>
      <Text style={stylesGeneral.tituloSeccion}>{advertencias.datos.cursos.titulo}</Text>
      {!!advertencias.datos.cursos.header && sinCamposObligatorios()}
      {!!advertencias.datos.cursos.sin_estudiantes && sinEstudiantes()}
      {!!advertencias.datos.cursos.incompletos && (
        <DatosIncompletosEstudiantes
          datos={advertencias.datos.cursos.incompletos.data}
          descripcion={advertencias.datos.cursos.incompletos.mensaje}
          header={advertencias.datos.cursos.incompletos.header}
          titulo={advertencias.datos.cursos.incompletos.titulo}
        />
      )}
    </>
  )
}

//Profesores
const DatosIncompletosProfesores = (props) => {
  const { titulo, descripcion, datos, header } = props;

  const profesores = () => {
    let hojaIncompletas = []
    for (const key in datos) {
      hojaIncompletas.push(
        <View style={stylesTabla.fila} key={`datosIncompletos_profesor${key}`}>
          <Text style={stylesTabla.itemFila}>{key}</Text>
          <Text style={stylesTabla.itemFila}>{datos[key].join(', ')}</Text>
        </View>
      )
    }
    return hojaIncompletas
  }

  return (
    <View style={stylesGeneral.contenedor}>
      <View>
        <Text style={stylesGeneral.subtitulo}>{titulo}</Text>
        <Text style={stylesGeneral.descripcion}>{descripcion}</Text>
      </View>
      <View style={stylesTabla.tabla}>
        <View style={stylesTabla.header} >
          <Text style={stylesTabla.itemHeaderProfesor}>{header.filas}</Text>
          <Text style={stylesTabla.itemHeaderProfesor}>{header.campos}</Text>
        </View>
        {profesores()}
      </View>
    </View>
  )
}

const Profesores = (props) => {
  const { advertencias } = props;


  const sinCursos = () => {
    return (
      <View style={stylesGeneral.contenedor}>
        <View>
          <Text style={stylesGeneral.subtitulo}>{advertencias.datos.profesor.sin_curso.titulo}</Text>
          <Text style={stylesGeneral.descripcion}>{advertencias.datos.profesor.sin_curso.mensaje}</Text>
        </View>
        <View>
          {
            advertencias.datos.profesor.sin_curso.data.map(curso => {
              return (
                <View style={stylesGeneral.item} key={`sinCurso_${curso}`} >
                  <Text style={stylesGeneral.bulletPoint}>•</Text>
                  <Text>{curso}</Text>
                </View>
              )
            })
          }
        </View>
      </View>
    )
  }

  const sinHoja = () => {
    return (
      <View style={stylesGeneral.contenedor}>
        <View>
          <Text style={stylesGeneral.subtitulo}>{advertencias.datos.profesor.hoja.titulo}</Text>
          <Text style={stylesGeneral.descripcion}>{advertencias.datos.profesor.hoja.mensaje}</Text>
        </View>
      </View>
    )
  }

  const sinCamposObligatorios = () => {
    return (
      <View style={stylesGeneral.contenedor}>
        <View>
          <Text style={stylesGeneral.subtitulo}>{advertencias.datos.profesor.header.titulo}</Text>
          <Text style={stylesGeneral.descripcion}>{advertencias.datos.profesor.header.mensaje}</Text>
        </View>
        <View>
          {
            advertencias.datos.profesor.header.campos.map(curso => {
              return (
                <View style={stylesGeneral.item} key={`sinCamposObligatoriosPeofesor_${curso}`}>
                  <Text style={stylesGeneral.bulletPoint}>•</Text>
                  <Text>{curso}</Text>
                </View>
              )
            })
          }
        </View>
      </View>
    )
  }


  return (
    <>
      <Text style={stylesGeneral.tituloSeccion}>{advertencias.datos.profesor.titulo}</Text>
      {!!advertencias.datos.profesor.header && sinCamposObligatorios()}
      {!!advertencias.datos.profesor.hoja && sinHoja()}
      {!!advertencias.datos.profesor.sin_curso && sinCursos()}
      {!!advertencias.datos.profesor.incompletos && (
        <DatosIncompletosProfesores
          datos={advertencias.datos.profesor.incompletos.data}
          descripcion={advertencias.datos.profesor.incompletos.mensaje}
          header={advertencias.datos.profesor.incompletos.header}
          titulo={advertencias.datos.profesor.incompletos.titulo}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: '42px 42px',
    fontFamily: 'Raleway',
    fontSize: 12
  },
});

// Create Document Component
/**
 * Componente que recibe las advertencias del archivo excel subido para generar un PDF con los campos faltantes. 
 * @returns {JSX.Element} Document
 */
const ReportePDF = (props) => {
  const { advertencias } = props;
  return (
    <>
      {
        !!advertencias.datos
          ? (<Document>
            <Page size="A4" style={styles.page}>
              <HeaderPDF {...props} />
              {!!advertencias.datos.cursos && <Cursos {...props} />}
              {!!advertencias.datos.profesor && <Profesores {...props} />}
            </Page>
          </Document>)
          :
          <></>
      }
    </>
  )
}

export default ReportePDF;

ReportePDF.propTypes = {
  /**objeto que contiene la información del archivo subido */
  advertencias: PropTypes.object
}