import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DivisionCrud from "../../globales/DivisionCrud";
const SkeletonCursosEditarNuevo = () => {
  return (
    <>
      <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
        <Skeleton className="ruta" width={447} height={40} />

        <div className="contenido" >
          <h1 className="titulos-principales  ">
            <div className="md:w-64 mb-2">
              <Skeleton width={'100%'} height={36} />
            </div>
          </h1>

          <DivisionCrud>
            <>
              <Skeleton width={100} height={24} />
            </>
            <div className="alineado-verticalmente h-full">
              <div className="w-full xl:w-451p">
                <Skeleton width={'100%'} height={36} />
              </div>
              <Skeleton className="ml-5" width={54} height={24} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={80} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={120} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={'90%'} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <div>
              <Skeleton className="mx-auto lg:mx-0" width={128} height={128} style={{ borderRadius: '100%', display: 'block' }} />
              <Skeleton className="mx-auto lg:mx-0 mt-3" width={130} height={24} style={{ display: 'block' }} />
            </div>
          </DivisionCrud>

          <DivisionCrud>
            <>
              <Skeleton width={'90%'} height={24} />
              <Skeleton className="mt-3" width={'100%'} height={60} />
            </>
            <>
              <div className="encabezadoTabla">
                <p className="col-span-1"></p>
                <p className="xl:col-span-6">
                  <Skeleton width={'100%'} height={24} />
                </p>
                <p className=" xl:col-span-5">
                  <Skeleton width={'100%'} height={24} />
                </p>
              </div>
              <div className="filaTabla">
                <div className="fila-2-sm1-lg12-xl1  row-span-2  md:row-span-1">
                  <div className="  w-10 h-10 xl:mx-auto" >
                    <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: '100%', display: 'block' }} />
                  </div>
                </div>

                <div className="fila-10-sm5-lg6 xl:col-span-6">
                  <div className="xl:hidden w-6/12">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                  <Skeleton width={'100%'} height={24} />
                </div>
                <div className="fila-10-sm5-lg6  xl:col-span-5 ">
                  <div className=" w-4/12  xl:hidden">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <div className="filaTabla">
                <div className="fila-2-sm1-lg12-xl1  row-span-2  md:row-span-1">
                  <div className="  w-10 h-10 xl:mx-auto" >
                    <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: '100%', display: 'block' }} />
                  </div>
                </div>

                <div className="fila-10-sm5-lg6 xl:col-span-6">
                  <div className="xl:hidden w-6/12">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                  <Skeleton width={'100%'} height={24} />
                </div>
                <div className="fila-10-sm5-lg6  xl:col-span-5 ">
                  <div className=" w-4/12  xl:hidden">
                    <Skeleton width={'100%'} height={24} />
                  </div>
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
            </>
          </DivisionCrud>

          <div className="flex justify-end my-5">
            <button className=" w-1/2 sm:w-40 mr-5">
              <Skeleton width={'100%'} height={36} />
            </button>
            <button className=" w-1/2 sm:w-40">
              <Skeleton width={'100%'} height={36} />
            </button>
          </div>
        </div>
      </SkeletonTheme>

    </>
  )
}
export default SkeletonCursosEditarNuevo;