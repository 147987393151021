import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Funciones
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
import listarArray from '../../utilities/function/listarElementos/listarArray';
import listarObject from './../../utilities/function/listarElementos/listarObject';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Componentes
import ProfesoresEditarNuevoComponent from '../../components/profesores/ProfesoresEditarNuevoComponent';
import PropTypes from 'prop-types';

/**
 * Este container recibe la función de descartar cambios y los props necesarios para renderizar la pantalla de editar profesor nuevo. Las funciones principales de este container son actualizar y confirmar los datos, eliminar el profesor seleccionado, listar cursos y varificar cambios. 
 * @returns {JSX.Element} ProfesoresEditarNuevoComponent
 */
const ProfesoresEditarNuevo = (props) => {
  const { isLoading, isLoadingProfesores, descartarCambios } = props;
  //Estados globales
  const state = useTrackedState();
  const { datosCursos, datosInstitucion, temporal } = state;
  const dispatch = useDispatch();

  const history = useHistory();
  const params = useParams();
  const { profesor_nuevo } = params;

  //Estados del componente
  const [correo, setCorreo] = useState(null);
  const [cursosAsignados, setCursosAsignados] = useState({
    ids: [],
    datos: []
  })
  const [cursosSede, setCursosSede] = useState(null);
  const [datosProfesorNuevo, setDatosProfesorNuevo] = useState([]);
  const [hayCambios, setHayCambios] = useState({ cambios: false, datos: {} });
  const [isProfesorEliminado, setIsProfesorEliminado] = useState(false);
  const [nombre, setNombre] = useState([]);
  const [nombreActual, setNombreActual] = useState([]);
  const [sedesInstituto, setSedesInstituto] = useState(null);
  const [sedeSeleccionada, setSedeSeleccionada] = useState({ id: null, nombre: '' })


  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('profesores');


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes })
    setSedesInstituto(listaSedes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!temporal?.profesores) {
      if (!isProfesorEliminado) {
        const datosTemporales = { ...temporal.profesores.datos };
        let datosProfesor = {};

        for (const key in datosTemporales) {
          const profesor = datosTemporales[key];
          if (profesor.key === profesor_nuevo) {
            datosProfesor = profesor;
          }
        }

        setCorreo(datosProfesor.correo)
        setCursosAsignados({
          ids: datosProfesor.cursos,
          datos: datosProfesor.datosCursos
        })
        setDatosProfesorNuevo(datosProfesor);

        setNombre(datosProfesor.nombre);
        setNombreActual(datosProfesor.nombre);

        setSedeSeleccionada({ id: datosProfesor.idSede, nombre: datosProfesor.nombreSede })

        setHayCambios({
          cambios: false,
          datos: {
            correo: false,
            cursos: false,
            nombre: false,
            sede: false,
          }
        })
      }
    } else {
      history.push('/profesores');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal]);


  useEffect(() => {
    !!datosCursos && listarCursosSede();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, sedeSeleccionada.id])


  //Actualiza el nombre del profesor
  const actualizarCorreo = ({ correo }) => {
    const propiedades = { correo: correo !== datosProfesorNuevo.correo }
    verificarCambios({ propiedades });

    setCorreo(correo);
  }


  //Actualiza el nombre del profesor
  const actualizarNombre = ({ nombre }) => {
    const propiedades = { nombre: nombre !== datosProfesorNuevo.nombre }
    verificarCambios({ propiedades });
    setNombre(nombre)
  };


  //Actualizar cursos asignados
  const actualizarCursosAsignados = ({ e, curso }) => {
    const datosCursosAsignados = [...cursosAsignados.datos];
    const resultados = elegirElementosCheck({ e, elemento: curso, datosActuales: datosCursosAsignados });
    const { ids, datos } = resultados;

    const propiedades = { cursos: cursosAsignados.ids.length !== datos.length }
    verificarCambios({ propiedades });
    setCursosAsignados({ ids, datos });

    setSedeSeleccionada({ id: curso.idSede, nombre: datosCursos[curso.idSede].nombre })
  }


  //Actualiza la sede asignada
  const actualizarSedeAsignada = ({ idSede }) => {
    if (idSede !== sedeSeleccionada.id) {
      const nombreSede = datosCursos[idSede].nombre;
      setSedeSeleccionada({
        id: idSede,
        nombre: nombreSede
      });

      const propiedades = { sede: datosProfesorNuevo.idSede !== idSede }
      verificarCambios({ propiedades });
      setCursosAsignados({ ids: [], datos: [] });
    };
  };


  //Editar curso  
  const editarProfesor = () => {
    let profesoresNuevos = { ...temporal.profesores.datos };

    //Se actualizan los datos del curso nuevo
    profesoresNuevos[profesor_nuevo] = {
      ...profesoresNuevos[profesor_nuevo],
      correo, nombre,
      cursos: cursosAsignados.ids,
      datosCursos: cursosAsignados.datos,
      idSede: sedeSeleccionada.id,
      nombreSede: sedeSeleccionada.nombre
    }

    //Se crean los nuevos datos de los cursos nuevos
    const nuevosDatosTemporales = {
      ...temporal.profesores,
      datos: profesoresNuevos
    }


    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "profesores",
      value: nuevosDatosTemporales
    });
  }


  //Eliminar profesor
  const eliminarProfesor = () => {
    const profesoresTemporal = { ...temporal.profesores.datos };
    let profesoresNuevos = profesoresTemporal;
    delete profesoresNuevos[datosProfesorNuevo.key];

    const nuevosDatosTemporales = {
      ...temporal.profesores,
      datos: profesoresNuevos
    }

    setIsProfesorEliminado(true)
    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "profesores",
      value: nuevosDatosTemporales
    });
  };


  //Genera el listado de cursos que el usurio puede seleccionar para asignar al profesor
  const listarCursosSede = () => {
    const cursosFiltrados = listarObject({
      object: datosCursos,
      idSede: sedeSeleccionada.id,
      propiedad: 'cursos'
    })

    const resultados = {
      hayCursos: cursosFiltrados.resultados.hayResultados,
      cursos: cursosFiltrados.resultados.resultados,
    }

    setCursosSede(resultados)
  }


  //Verificar cambios
  const verificarCambios = ({ propiedades }) => {
    const datos = { ...hayCambios.datos, ...propiedades };
    let cambios = false;

    for (const key in datos) {
      const element = datos[key];
      if (element) cambios = true;
    }

    setHayCambios({
      hayCambios: cambios,
      datos: { ...hayCambios.datos, ...propiedades }
    });
  }


  return (
    <>
      {isReady && !isLoading && !isLoadingProfesores && cursosAsignados.hayCursos !== null && !!cursosSede &&
        <>
          <ProfesoresEditarNuevoComponent
            actualizarCorreo={actualizarCorreo}
            actualizarCursosAsignados={actualizarCursosAsignados}
            actualizarNombre={actualizarNombre}
            actualizarSedeAsignada={actualizarSedeAsignada}
            correoProfesor={correo}
            cursosAsignados={cursosAsignados}
            cursosSede={cursosSede}
            datosProfesorNuevo={datosProfesorNuevo}
            descartarCambios={descartarCambios}
            editarProfesor={editarProfesor}
            eliminarProfesor={eliminarProfesor}
            hayCambios={hayCambios.hayCambios}
            isProfesorEliminado={isProfesorEliminado}
            nombreActual={nombreActual}
            nombreProfesor={nombre}
            sedeSeleccionada={sedeSeleccionada}
            sedesInstituto={sedesInstituto}
            textosInterfaz={{
              botones: textosInterfaz.botones,
              descartar_cambios: textosInterfaz.descartar_cambios,
              editar: textosInterfaz.editar,
              eliminar_profesor: textosInterfaz.eliminar_profesor,
              miga_de_pan: textosInterfaz.miga_de_pan,
              profesor_eliminado: textosInterfaz.profesor_eliminado,
              notificacion: textosInterfaz.notificacion,
            }}
          />
        </>
      }
    </>
  )
}
export default ProfesoresEditarNuevo;


ProfesoresEditarNuevo.propTypes = {
  /**
   *  Booleano que valida si ha cargado la información.
   */
  isLoading: PropTypes.bool,
  /**
  *  Booleano que valida si ha cargado la información de profesores.
  */
  isLoadingProfesores: PropTypes.bool,
  /**
   * funcion callback que borra los datos temporales.
   */
  descartarCambios: PropTypes.func.isRequired,
}