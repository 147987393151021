import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import CambiarAvatar from "./CambiarAvatar";
import DivisionCrud from "../globales/DivisionCrud";
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';
import { Miga, MigasDePan } from "../globales/MigaDePan";

/**
 * Este componente recibe una serie de props que le permiten renderizar la pantalla de mi cuenta. Además, renderiza  los componentes CambiarAvatar y DivisionCrud.
 * @returns MiCuentaComponent
 */
const MiCuentaComponent = (props) => {
  const { cambiarAvatar, cambiarInfoUsuario, datosUsuario, textosInterfaz, respuestaApiDatosUsuario, preloader, respuestaApiAvatar } = props;
  const { botones, vista_general } = textosInterfaz;
  const { isError, mensaje } = respuestaApiDatosUsuario;

  const [mostrarAvatar, setMostrarAvatar] = useState(false);
  const [btnGuardarCambios, setBtnGuardarCambios] = useState(true);
  const [nombre, setNombre] = useState({ campo: datosUsuario.nombre, valido: true, desactivado: false });
  const [correo, setCorreo] = useState({ campo: datosUsuario.correo, valido: true, desactivado: false });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);

  const refNombre = useRef(null);
  const refCorreo = useRef(null);

  useEffect(() => {
    if (mensaje !== '') {
      notificacion({
        titulo: isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        mensaje: mensaje,
        tipo: isError ? "danger" : "success"
      });
      if (!isError) {
        setNombre({ ...nombre, desactivado: false });
        setCorreo({ ...correo, desactivado: false });
        setBtnGuardarCambios(true)
        setMostrarAlerta(false);
      }
      setMostrarAlerta(!isError ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const notificacion = ({ titulo, mensaje, tipo }) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }
  const validarNombre = () => {
    const valorCampo = nombre.campo.length > 0 ? true : false;
    setNombre({ ...nombre, valido: valorCampo })
  }

  const validarCorreo = () => {
    let expresion = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const valorCampo = expresion.test(correo.campo) ? true : false;
    setCorreo({ ...correo, valido: valorCampo });
  }

  const actualizarCampo = (e, estado, cambiarEstado) => {
    cambiarEstado({ ...estado, campo: e.target.value });
    setBtnGuardarCambios(false);
  }

  const enviarDatos = () => {
    const infoUsuario = nombre.valido && correo.valido ? { nombre: nombre.campo, correo: correo.campo } : undefined
    cambiarInfoUsuario(infoUsuario);
  }

  const preventPaste = (e) => e.preventDefault();

  useEffect(()=>{
    if(refNombre && nombre.desactivado === true){
      refNombre.current.focus()
    }
  },[nombre.desactivado])

  useEffect(()=>{
    if(refCorreo && correo.desactivado === true){
      refCorreo.current.focus()
    }
  },[correo.desactivado])
  return (
    <>
      <MigasDePan>
        <Miga
          texto={botones?.mi_cuenta || 'Mi cuenta'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>
      <div className="contenido onbackground-var1--color">
        <h1 className="titulos-principales">{vista_general.titulo}</h1>
        <div className="crud-seccion">
          <div>
            <div
              className="avatar mx-auto img-cover-center text-indent"
              style={{ backgroundImage: `url(${datosUsuario.avatar})` }}
              aria-hidden={true}
            >
              {datosUsuario.avatar}
            </div>
            <button
              className="evento_rojo mx-auto lg:mx-0 block mt-3 focusable-primary"
              onClick={() => {
                if (mostrarAvatar) {
                  MicroModal.show('cambiar-avatar', {
                    awaitCloseAnimation: true
                  });
                } else {
                  setMostrarAvatar(true)
                }
              }}
            >
              {vista_general.avatar.titulo}
            </button>
          </div>
          <div className="lg:px-10 flex flex-col justify-center text-center lg:text-left">
            <h2 className="quicksand_500 onbackground-var2--color text-26">{datosUsuario.nombre}</h2>
            <p className="raleway-500 text-18">{datosUsuario.instituto}</p>
          </div>
        </div>

        {/*NOMBRE-------------------------------- */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700">{vista_general.nombre.titulo}</h3>
            <p className="r4-14-gris6a">{vista_general.nombre.descripcion}</p>
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p">
              <input
                className={`w-full  ${!nombre.desactivado && 'input_disabled'} ${mostrarAlerta ? !nombre.valido && 'input_error' : null}`}
                onBlur={validarNombre}
                onChange={(e) => { actualizarCampo(e, nombre, setNombre) }}
                onCopy={preventPaste}
                onKeyUp={validarNombre}
                onPaste={preventPaste}
                placeholder={vista_general.nombre.placeholder}
                ref={refNombre}
                type="text"
                value={nombre.campo}
                disabled={!nombre.desactivado}
              />
              {mostrarAlerta &&
                <p className={`text-14 
                  ${!nombre.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {vista_general.nombre.error}
                </p>
              }
            </div>
            <button
              className="evento_rojo ml-5 focusable-primary"
              onClick={() => {setNombre({ ...nombre, desactivado: true })}}
            >
              {botones.boton_editar}
            </button>
          </div>
        </DivisionCrud>

        {/*CORREO-------------------------------- */}
        <DivisionCrud>
          <>
            <h3 className="raleway-700">{vista_general.correo.titulo}</h3>
            <p className="r4-14-gris6a">{vista_general.correo.descripcion}</p>
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p">
              <input
                className={`w-full ${!correo.desactivado && 'input_disabled'} ${mostrarAlerta ? !correo.valido && 'input_error' : null}`}
                onBlur={validarCorreo}
                onChange={(e) => { actualizarCampo(e, correo, setCorreo) }}
                /* onClick={() => { setCorreo({ ...correo, desactivado: true }) }} */
                onCopy={preventPaste}
                onKeyUp={validarCorreo}
                onPaste={preventPaste}
                placeholder={vista_general.correo.placeholder}
                ref={refCorreo}
                type="text"
                value={correo.campo}
                disabled={!correo.desactivado}
              />
              {mostrarAlerta &&
                <p className={`text-14 
                  ${!correo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {vista_general.correo.error}
                </p>
              }
            </div>
            <button
              className="evento_rojo ml-5 focusable-primary"
              onClick={() => { setCorreo({ ...correo, desactivado: true }) }}
            >
              {botones.boton_editar}
            </button>
          </div>
        </DivisionCrud>

        <div className="crud-seccion justify-between">
          <div className="lg:w-3/12">
            <h3 className="raleway-700">{vista_general.contrasena.titulo}</h3>
            <p className="r4-14-gris6a">{vista_general.contrasena.descripcion}</p>
          </div>
          <div className="lg:w-8/12 crud-contenido flex flex-col xl:flex-row xl:items-center">
            <Link to="/mi-cuenta/cambiar-contrasena" aria-hidden={true} tabIndex={-1}>
              <input
                type='password'
                aria-hidden={true}
                tabIndex={-1}
                defaultValue={datosUsuario.contrasena}
                readOnly
                className="w-full xl:w-451p input_disabled"
              />
            </Link>
            <Link to="/mi-cuenta/cambiar-contrasena" className="evento_rojo xl:ml-5 text-left mt-3 xl:mt-0 focusable-primary">
              {botones.cambiar_contrasena}
            </Link>
          </div>
        </div>
        <div className="alineado-izquierda my-5">
          <button
            disabled={btnGuardarCambios}
            className="boton-justo boton-amarillo boton_hover_naranja text-16 center w-60 h-9 focusable-primary"
            onClick={() => { enviarDatos() }}
          >
            {
              preloader.cambiarDatosUsuario ?
                <span className="icon-spinner7 animate-spin text-20"></span>
                :
                <>
                  <p className="block">{botones.guardar.datos_usuario}</p>
                  <span className="icon-check pl-3 block"></span>
                </>
            }
          </button>
        </div>
      </div>

      {mostrarAvatar &&
        <CambiarAvatar
          cambiarAvatar={cambiarAvatar}
          datosUsuario={datosUsuario}
          preloader={preloader.cambiarAvatar}
          textosInterfaz={textosInterfaz}
          respuestaApiAvatar={respuestaApiAvatar}
        />
      }
    </>
  )
}

export default MiCuentaComponent;

MiCuentaComponent.propTypes = {
  /**
   * Función que se encarga de ejecutar el API de cambiar avatar.
   * @type{Function(value:number):void } - callback
   */
  cambiarAvatar: PropTypes.func.isRequired,
  /**
   * función que se encarga de ejecutar el API que actualiza la información del usuario.
   * @type{Function(data:shape):void} - Callback
   */
  cambiarInfoUsuario: PropTypes.func.isRequired,
  /**
   * objeto que trae la información del usuario.
   */
  datosUsuario: PropTypes.shape({
    avatar: PropTypes.string,
    contrasena: PropTypes.string,
    correo: PropTypes.string,
    instituto: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,
  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo. 
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * objeto que contiene dos propiedades que va a devolver cuando las API actualizarDatosUsuario termine de responder.
   */
  respuestaApiDatosUsuario: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }),
  /**
   * objeto que contiene los preloaders para los llamados de las API's. 
   */
  preloader: PropTypes.shape({
    cambiarAvatar: PropTypes.bool,
    cambiarDatosUsuario: PropTypes.bool
  }).isRequired,
  /**
   *  objeto que contiene dos propiedades que va a devolver cuando las API cambiarAvatar termine de responder.
   */
  respuestaApiAvatar: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }).isRequired
}