
/**
 * Genera un numero aleatorio entre un rango de numeros
 * @param {{minimo:number, maximo:number}} numeros - Objeto con el minimo y maximo de los numeros a generar
 * @returns {number} - Numero aleatorio entre el minimo y el maximo
 */
const randomNumber = ({ minimo, maximo }) => {
  return Math.floor(Math.random() * (maximo-minimo+1)) + minimo;
};

export default randomNumber;