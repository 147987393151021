import SelectorDeFiltros from "../globales/elementosFormularios/SelectorDeFiltros";
import PropTypes from 'prop-types';

/**
 * Componente que renderiza los filtros de busqueda de libros y gestiona los resultados de la busqueda.
 */
const BuscadorFiltros = (props) => {
  const { filtros, mostrarBorrarFiltro,  modificarFiltros, borrarFiltros, enviarFiltros, eligiendoLibros, textosFiltros, botonesFiltros, alFiltrar, alLimpiarFiltros } = props;

  return (
    <>
      {filtros.map((item, index) => {
        const { type, name, translate, value, options, numeroOpciones, notShowEligiendoLibros } = item;
  
        return (
          <div key={`item-checkbox-${index}`}>
            {/*Input: Busqueda por titulo */}
            {
              type === 'text' &&
              <>
                <div className="relative w-full h-full">
                  <input
                    className="w-full"
                    onKeyPress={(e) => { if (e.key === "Enter"){
                      enviarFiltros(filtros); 
                      alFiltrar();
                    }}}
                    onChange={(e) => {
                      let property = name;
                      let newValue = e.target.value;
                      modificarFiltros({ property, newValue })
                    }}
                    placeholder={textosFiltros.filtro.busqueda}
                    type={type}
                    value={value}
                  />
                  <button
                    className="focusable-primary absolute right-0 top-0 w-10 h-full"
                    onClick={() => {
                      enviarFiltros(filtros)
                      if (alFiltrar)
                        alFiltrar();
                    }}
                  >
                    <span className="icon-lupa onsurface-var1--color hover:text-morado"></span>
                  </button>
                </div>


                <button
                  className={`focusable-primary w-full boton-justo boton-amarillo boton_hover_morado ${mostrarBorrarFiltro ? 'mt-5' : 'my-5'}`}
                  onClick={() => {
                    enviarFiltros(filtros)
                    if (alFiltrar)
                      alFiltrar();
                  }}
                > {botonesFiltros.aplicar_filtros}</button>

                {mostrarBorrarFiltro &&
                  <button
                    className="focusable-primary w-full evento_rojo my-5 text-center"
                    onClick={() => {
                      borrarFiltros();
                      if (alLimpiarFiltros)
                        alLimpiarFiltros();
                    }}
                  >
                    {botonesFiltros.borrar_filtros}  <span className="icon-cerrar"></span>
                  </button> 
                }

              </>

            }


            {/* Selectores de filtro */}
            {type !== 'checkbox' &&
              name !== 'search' &&
              <div className="mb-5">
                <SelectorDeFiltros
                  opciones={type === 'dropdown' && options}
                  textoDefault={`${!!numeroOpciones ? `(${numeroOpciones})` : ''} ${textosFiltros.filtro[translate]}`}
                  modificarFiltros={modificarFiltros}
                  nombre={name}
                  traduccionesTextos={textosFiltros[translate]}
                />
              </div>
            }

            {/*  {name === 'GR Level' && <hr className="mt-5 mb-5" />} */}

            {
              type === 'checkbox' && (!eligiendoLibros || (eligiendoLibros && !notShowEligiendoLibros))  &&
              <>
                <label className="focusable-by-children-primary block h-7 relative mr-3 cursor-pointer mb-3 ">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      let property = name;
                      let newValue = !value;
                      modificarFiltros({ property, newValue })
                    }}
                    checked={value}
                  />
                  <i className="check"></i>
                  <p className="raleway-700 text-18 onsurface-var1--color absolute left-10 top-0.5">{textosFiltros.filtro[translate]}</p>
                </label>
              </>
            }
            {/*  {name === 'favorito' && <hr className="my-5" />} */}
          </div>
        )
      })}


    </>
  )
}

export default BuscadorFiltros;


BuscadorFiltros.propTypes = {
  /**
   * Arreglo con los filtros de busqueda que se deben renderizar.
   */
  filtros: PropTypes.array.isRequired,
  /**
   * Propiedad booleana que defiene si debe mostrarse el boton de borrar filtros.
   */
  mostrarBorrarFiltro: PropTypes.bool.isRequired,
  /**
   * Funcion que modifica el estado de los filtros de busqueda.
   */
  modificarFiltros: PropTypes.func.isRequired,
  /**
   * Función que borrar todos los filtros apitados.
   */
  borrarFiltros: PropTypes.func.isRequired,
  /**
   * Funcion que realiza la petición de filtrado y actualiza los libros filtrados.
   */
  enviarFiltros: PropTypes.func.isRequired,
  /**
   * Propiedad que indica si se esta eligiendo libros.
   */
  eligiendoLibros: PropTypes.bool.isRequired,
    /**
   * Funcion que se ejecuta cuando al menos un filtro se activa
   */
     alFiltrar: PropTypes.func,
     /**
     * Funcion que se ejecuta cuando se han borrado todos los filtros
     */
    alLimpiarFiltros: PropTypes.func,

      /**
     * Objeto que contiene los textos de traducción para los filtros
     */
    textosFiltros: PropTypes.object,
     /**
     * Objeto que contiene los textos de traducción para los botones
     */
    botonesFiltros: PropTypes.object,


}