import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { MigasDePan, Miga } from "../globales/MigaDePan";
import MicroModal from "micromodal";

/**componentes */
import CrearEstudianteIndividual from './CrearEstudiante/Individual';
import CrearEstudianteMasivo from './CrearEstudiante/Masivo';
import Modal from '../globales/modales/Modal';

const EstudiantesCrearComponent = (props) => {
  const { actualizarCodigo, actualizarCorreo, actualizarCursoAsignado, actualizarNombre, actualizarModoCrear, actualizarSedeAsignada, advertenciasExcel, codigoEstudiante, correoEstudiante, cursoSeleccionado, cursosSede, datosTemporalesExcel, descartarCambios, errorSubirExcel, guardarDatosTemporales, hayArchivo, isLoadingExcel, nombreEstudiante, modoActivoCrear, obtenerDatosExcel, sedeSeleccionada, sedesInstituto, textosInterfaz } = props;

  const { botones, crear, miga_de_pan, descartar_cambios, advertencias, error_excel } = textosInterfaz;


  /**estados del componente */
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [continuarConAdvertencias, setContinuarConAdvertencias] = useState(false);
  const [excelValido, setExcelValido] = useState(true);
  const [mostrarAlertaArchivo, setMostrarAlertaArchivo] = useState(false);
  const [inputNombre, setInputNombre] = useState({ campo: nombreEstudiante, valido: nombreEstudiante !== '' });
  const [inputCorreo, setInputCorreo] = useState({ campo: correoEstudiante, valido: false });

  const history = useHistory();

  /**useEffect */
  useEffect(() => {
    if (errorSubirExcel.hayError) {
      MicroModal.show('error-archivo', {
        awaitCloseAnimation: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSubirExcel])

  useEffect(() => {
    setMostrarAlertaArchivo(false);
    setMostrarAlerta(false);

    if (!datosTemporalesExcel.hayDatos) {
      setExcelValido(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modoActivoCrear])


  useEffect(() => {
    if (modoActivoCrear === 'masivo') {
      if (mostrarAlerta) {
        setMostrarAlertaArchivo(true);
      }
    } else {
      setMostrarAlertaArchivo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostrarAlerta])


  /**Funciones */
  /**Esta función sirve para verificar los campos antes de que pase a la pantalla de verificar */
  const verificarDatos = async () => {
    setMostrarAlerta(true);
    if (modoActivoCrear === 'masivo') {
      /*!errorSubirExcel.hayError  */
      if (!!sedeSeleccionada.id && !!cursoSeleccionado.id && errorSubirExcel.hayError !== null) {
        if (advertenciasExcel.hayAdvertencias) {
          MicroModal.show('advertencias-excel', {
            awaitCloseAnimation: true
          });
        } else {
          await guardarDatos();
        }
      }
    } else {
      if (inputNombre.valido && !!cursoSeleccionado.id && !!sedeSeleccionada.id && inputCorreo.valido) {
        await guardarDatos();
      }
    }
  }

  /**Esta función es del botón de la ventana modal de advertencias.  */
  const continuar = async () => {
    if (continuarConAdvertencias) {
      await guardarDatos()
    }
  }

  const guardarDatos = async () => {
    await guardarDatosTemporales();
    history.push('/estudiantes/crear-estudiantes/verificar-estudiantes');
  }

  /**función del botón descartar */
  const descartar = () => {
    if (datosTemporalesExcel.hayDatos === false) {
      history.push('/estudiantes');
    } else {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }


  /*funcion que cambia la sede asignada en el selector*/
  const cambiarSede = ({ opcion }) => actualizarSedeAsignada({ idSede: opcion.id });

  /*funcion que cambia el curso asignado en el selector*/
  const cambiarCurso = ({ opcion }) => actualizarCursoAsignado({ idCurso: opcion.id, idSede: opcion.idSede });

  const onKeyEnter = (e) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
       e.target.checked = !e.target.checked;
       setContinuarConAdvertencias(e.target.checked)
    }
  }

  return (
    <>
      <MigasDePan>
      <Miga
          texto={miga_de_pan?.principal || 'Estudiantes'}
          alSeleccionar={() => {
            descartar()
          }} />
        <Miga
          texto={miga_de_pan?.crear || 'Crear estudiantes'}
          esRutaActual={true}
        />
      </MigasDePan>
      <div className="contenido">
        <h1 className="titulos-principales mb-5">{crear.titulo}</h1>
        <div className="flex pb-5">
          <button
            aria-current= {modoActivoCrear === 'individual' ? 'page' : ''}
            className={`focusable-red boton-justo  ${modoActivoCrear === 'individual' ? "boton-morado" : "boton-morado_desactivado"}`}
            onClick={() => actualizarModoCrear({ modo: 'individual' })}
          >
            {botones.modo_crear.individual}
          </button>
          <button
            aria-current= {modoActivoCrear === 'masivo' ? 'page' : ''}
            className={`focusable-red boton-justo ml-5 ${modoActivoCrear === 'masivo' ? "boton-morado" : "boton-morado_desactivado"}`}
            onClick={() => actualizarModoCrear({ modo: 'masivo' })}
          >
            {botones.modo_crear.masivo}
          </button>
        </div>

        {modoActivoCrear === 'masivo' ?
          <CrearEstudianteMasivo
            cambiarCurso={cambiarCurso}
            cambiarSede={cambiarSede}
            cursosSede={cursosSede}
            cursoSeleccionado={cursoSeleccionado}
            datosTemporalesExcel={datosTemporalesExcel}
            excelValido={excelValido}
            hayArchivo={hayArchivo}
            hayErrorExcel={errorSubirExcel.hayError}
            isLoadingExcel={isLoadingExcel}
            mostrarAlerta={mostrarAlerta}
            mostrarAlertaArchivo={mostrarAlertaArchivo}
            obtenerDatosExcel={obtenerDatosExcel}
            sedeSeleccionada={sedeSeleccionada}
            sedesInstituto={sedesInstituto}
            setExcelValido={setExcelValido}
            textosInterfaz={crear}
          />
          :
          <CrearEstudianteIndividual
            actualizarCodigo={actualizarCodigo}
            actualizarCorreo={actualizarCorreo}
            cambiarCurso={cambiarCurso}
            actualizarNombre={actualizarNombre}
            cambiarSede={cambiarSede}
            codigoEstudiante={codigoEstudiante}
            correoEstudiante={correoEstudiante}
            cursosSede={cursosSede}
            cursoSeleccionado={cursoSeleccionado}
            mostrarAlerta={mostrarAlerta}
            nombreEstudiante={nombreEstudiante}
            sedesInstituto={sedesInstituto}
            sedeSeleccionada={sedeSeleccionada}
            textosInterfaz={crear}
            inputNombre={inputNombre}
            setInputNombre={setInputNombre}
            inputCorreo={inputCorreo}
            setInputCorreo={setInputCorreo}
          />
        }


        {/* Botones */}
        <div className="flex justify-end my-5">
          <Link
            to="/estudiantes"
            className="focusable-primary boton-pequenio boton-rojo boton_hover_morado text-16 text-center mr-5 "
          >
            {botones.cancelar}
          </Link>
          <button
            className="focusable-primary boton-justo boton-amarillo boton_hover_naranja text-16 center"
            onClick={verificarDatos}
          >
            {botones.verificar}
          </button>
        </div>
      </div>

      {/* Modal error archivo*/}
      {errorSubirExcel.hayError &&
        <Modal nombreModal="error-archivo">
          <div className="border-b pb-3">
            <span aria-label="Icono alerta" className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="q7-28 onbackground-var2--color text-center">
              {errorSubirExcel.tipoError === "No hay estudiantes" ? error_excel.sin_estudiantes.titulo : error_excel.archivo_incorrecto.titulo}
            </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {errorSubirExcel.tipoError === "No hay estudiantes" ? error_excel.sin_estudiantes.descripcion : error_excel.archivo_incorrecto.descripcion}
          </p>

          <button
          aria-label="Regresar"
            onClick={() => {
              MicroModal.close(`error-archivo`, {
                awaitCloseAnimation: true
              })
            }}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_morado block mx-auto mb-6"
          >
            {botones.regresar}
          </button>
        </Modal>
      }

      {/* Modal advertencias  */}
      {advertenciasExcel.hayAdvertencias &&
        <Modal nombreModal="advertencias-excel">
          <div className="border-b onsurface-var1--color pb-3">
            <span aria-label="Icono alerta" className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="q7-28 onbackground-var2--color text-center">{advertencias.titulo} </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {advertencias.descripcion}
          </p>

          <a href={advertenciasExcel.href} download={advertenciasExcel.nombreArchivo} className="focusable-primary evento_rojo text-center r7-16-negro26 block"> {advertencias.archivo.archivo} </a>

          <div className="flex md:items-center md:w-3/4 justify-between  mx-auto my-6">
            <p className="r5-14-negro4A w-9/12  ">{advertencias.archivo.checkbox} </p>
            <label className="relative w-20 focusable-by-children-primary"  role="checkbox">
              <input 
                tabIndex={0} 
                type="checkbox" 
                className="focusable-red h-0 m-3" 
                onChange={(e) => { setContinuarConAdvertencias(e.target.checked) }} 
                onKeyUp={(e) => { onKeyEnter(e) }}
                checked={continuarConAdvertencias} 
              />
              <i aria-hidden={true} className="focusable-red checkbox"><span className="no-check">No </span></i>
            </label>
          </div>

          <div className="center my-5">
            <button
            aria-label="Cerrar ventana"
              className="focusable-primary boton-pequenio boton-rojo boton_hover_morado sm:flex justify-center items-center mr-5 "
              onClick={() => {
                MicroModal.close('advertencias-excel', {
                  awaitCloseAnimation: true
                });
              }}
            >
            <p className="texto-blanco">{botones.cancelar}</p>
            </button>

            <button
            aria-label="Continuar"
              className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
              onClick={continuar}
              disabled={continuarConAdvertencias ? false : true}
            >
              {botones.continuar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal descartar cambios */}
      {datosTemporalesExcel.hayDatos &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img "
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
            aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }
    </>
  )
}
export default EstudiantesCrearComponent;