import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useObtenerSubsecciones from "../../containers/helpers/hooks/useObtenerSubsecciones";
import { FILTROS_ORDENAMIENTO_TABLA } from "../../shared/constants/Filtros";
import SelectorDeBusqueda from "../globales/elementosFormularios/SelectorDeBusqueda";
import Paginacion from "../globales/Paginacion";
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de propiedades que son necesarios para renderizar la tabla de resultados en la pantalla principal de Profesores. Además, hace uso de los componentes globales SelectorDeBusqueda y Paginacion.
 * @returns ResultadosListaProfesores
 */
const ResultadosListaProfesores = (props) => {
  const { datosProfesores, textosTabla, textoBotonEditar, cambiarProfesoresElegidos, preloader } = props;
  const { nombres_apellidos, correo, sede, curso } = textosTabla;
  const [datosDivididos, setDatosDivididos] = useState([]);

  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('global');
  const [filtroActualTabla, setFiltroActualTabla] = useState(FILTROS_ORDENAMIENTO_TABLA.aZ)
  const [profesores, setProfesores] = useState([]);

  /**
   * dependiendo de el filtro seleccionado ordenada de una manera u otra los profesores y los setea en el state de profesores
   */
  const manejarFiltroOrdenamiento = () => {
    let estudiantesOrdenados = [];
    switch (filtroActualTabla.id) {
      case FILTROS_ORDENAMIENTO_TABLA.aZ.id:
        estudiantesOrdenados = [...datosProfesores].sort((a, b) => {
          const nombreA = a?.nombre.toLowerCase().split(' ')[0];
          const nombreB = b?.nombre.toLowerCase().split(' ')[0];
          if (nombreA < nombreB)
            return -1;
          if (nombreA > nombreB)
            return 1;
          return 0;
        })
        break;
      case FILTROS_ORDENAMIENTO_TABLA.zA.id:
        estudiantesOrdenados = [...datosProfesores].sort((a, b) => {
          const nombreA = a?.nombre.toLowerCase().split(' ')[0];;
          const nombreB = b?.nombre.toLowerCase().split(' ')[0];;
          if (nombreA > nombreB)
            return -1;
          if (nombreA < nombreB)
            return 1;
          return 0;
        })
        break;
      default:
        estudiantesOrdenados = datosProfesores
        break;
    }
    setProfesores(estudiantesOrdenados);
  }

  useEffect(() => {
    manejarFiltroOrdenamiento();
  }, [datosProfesores, filtroActualTabla])

  const onKeyEnter = (e, idSede, dato) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
      e.target.checked = !e.target.checked;
      cambiarProfesoresElegidos({ e, idSede: idSede, profesor: dato });
    }
  }

  return (
    <>
      <div className={`${datosProfesores.length > 10 ? '' : 'mb-5'}`}>
        <div className="w-fit  m-1" style={{ minWidth: 160 }}>
          <SelectorDeBusqueda
            cambiarElegido={({ opcion }) => { setFiltroActualTabla(opcion) }}
            filtroMensajeNoexite={`${traducciones?.filtros?.tablas?.ordenamiento?.no_existe || 'No se encontraron filtros.'}`}
            opciones={Object.values(FILTROS_ORDENAMIENTO_TABLA)}
            subposicion={true}
            textoDefault={`${traducciones?.filtros?.tablas?.ordenamiento?.titulo || 'Ordenar por'}`}
            textoElegido={filtroActualTabla?.nombre}
          />
        </div>
        <div role="table" aria-rowcount={datosDivididos.length} aria-label="Lista de profesores">
          <div role="rowgroup">
            <div role="row" className="encabezadoTabla  xl:grid grid-cols-12 mt-5 ">
              <p role="columnheader" className="col-span-1"></p>
              <p role="columnheader" className="col-span-2">{nombres_apellidos.titulo}</p>
              <p role="columnheader" className="col-span-3">{correo.titulo}</p>
              <p role="columnheader" className="col-span-3">{sede.titulo}</p>
              <p role="columnheader" className="col-span-2">{curso.titulo}</p>
              <p role="columnheader" className="col-span-1"></p>
            </div>
          </div>

          {!preloader ?
            <div role="rowgroup">
              {datosDivididos.length === 0 ?
                <div className="filaTabla xl:grid-cols-12" role="row">
                  <div className=" center col-span-12">
                    <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                    <p className="raleway-700 red-inv-var2--color text-13">{textosTabla.sin_profesores}</p>
                  </div>
                </div>
                :
                datosDivididos.map((dato, index) => {
                  return (
                    <div key={`campo${dato.id}`} role="row" aria-rowindex={index} className="filaTabla xl:grid-cols-12">


                      <label
                        role="cell"
                        className="fila-2-sm1-lg12-xl1 row-span-5 sm:row-span-3 lg:row-span-1 cursor-pointer h-7 relative mr-3 focusable-by-children-primary"
                      >
                        <input
                          checked={dato.checked}
                          tabIndex={0}
                          type="checkbox"
                          onChange={(e) => { cambiarProfesoresElegidos({ e, idSede: dato.idSede, profesor: dato }); }}
                          onKeyUp={(e) => { onKeyEnter(e, dato.idSede, dato) }}
                        />
                        <i aria-hidden={true} className="check"></i>
                      </label>

                      <div role="cell" className="fila-10-sm5-lg6 xl:col-span-2">
                        <span className="q7-14-negro26  xl:hidden">{nombres_apellidos.titulo}</span>
                        <p className="r5-13-negro26 una-linea-texto">{dato.nombre}</p>
                      </div>
                      <div role="cell" className="fila-10-sm5-lg6 xl:col-span-3 overflow-auto">
                        <span className="q7-14-negro26  xl:hidden">{correo.titulo}</span>
                        <p className={`${!!dato.correo ? 'r5-13-negro26' : 'r7-13-rojo'} una-linea-texto`} >{!dato.correo ? correo.sin_corrreo : dato.correo}</p>
                      </div>
                      <div role="cell" className="fila-10-sm5-lg6 xl:col-span-3">
                        <span className="q7-14-negro26  xl:hidden">{sede.titulo}</span>
                        <p className={`${!!dato.nombreSede ? 'r5-13-negro26' : 'r7-13-rojo'} una-linea-texto`} >{!dato.nombreSede ? sede.sin_sede : dato.nombreSede}</p>
                      </div>
                      <div role="cell" className="fila-10-sm5-lg6 xl:col-span-2">
                        <span className="q7-14-negro26  xl:hidden">{curso.titulo}</span>
                        <p className={`${!!dato.nombreCursos ? 'r5-13-negro26' : 'r7-13-rojo'} una-linea-texto`} >{!dato.nombreCursos ? curso.sin_cursos : dato.nombreCursos}</p>
                      </div>
                      <div role="cell" className="fila-10-sm11-lg12 xl:col-span-1">
                        <div className="alineado-izquierda-center">
                          <Link aria-label="editar fila" to={`profesores/editar-profesor/${dato.idSede}/${dato.id}`} className="boton-justo boton-amarillo boton_hover_morado center w-full md:w-auto md:px-10 xl:p-2 focusable-primary">
                            <p className="xl:hidden">{textoBotonEditar}</p><span className="pl-3 xl:pl-0 icon-lapiz"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            :
            <>
              <div className="center pt-5">
                <span className="ml-10 icon-spinner9 animate-spin text-40 primary-inv-var2--color"></span>
              </div>
            </>
          }
        </div>
        <div className={`alineado-izquierda xl:mt-3`}>
          <Paginacion datos={profesores} datosDivididos={ActualizarDatosDivididos} />
        </div>

      </div>
    </>
  )
}

export default ResultadosListaProfesores;

ResultadosListaProfesores.propTypes = {
  /**
   *  Es un array de objetos que hace referencia a los profesores que se deben renderizarse en pantalla. 
   */
  datosProfesores: PropTypes.arrayOf(PropTypes.shape({
    checked: PropTypes.bool,
    clubes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    correo: PropTypes.string,
    id: PropTypes.string,
    idSede: PropTypes.string,
    nombre: PropTypes.string,
    nombreCursos: PropTypes.string,
    nombreSede: PropTypes.string
  })).isRequired,

  /**
   *  Es un objeto que trae los textos del encabezado de la tabla. Este cambia según el idioma activo.
   */
  textosTabla: PropTypes.object.isRequired,

  /**
   * Es un string del texto del boton de editar
   */
  textoBotonEditar: PropTypes.string.isRequired,

  /**
 *  función que se encarga de almacenar los profesores seleccionados con el check.
 * @type{Function({ e : bool , idSede: string, profesor:string }):void} - callback
 */
  cambiarProfesoresElegidos: PropTypes.func.isRequired,

  /**
   *  Es un booleano que contiene el preloader para el llamado de las API de eliminar y mover profesores de sede. 
   */
  preloader: PropTypes.bool.isRequired,
}