import { useHistory } from "react-router-dom";

//componentes
import { MigasDePan, Miga } from "../../../globales/MigaDePan";
import Filtro from "../../../globales/estadisticas/Filtro";
import TituloSeccion from "../../../globales/estadisticas/TituloSeccion";
import TablaIngresos from "./TablaIngresos";
import { useTrackedState } from "../../../../store";
import { useQueryParams } from "../../../../containers/helpers/hooks/locationHooks";
import { useEffect, useState } from "react";
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import moment from 'moment';
import { ESTADISTICAS_COLUMNAS_TABLAS } from "../../../../containers/estadisticas/tablas-reportes/ColumnasTablas";
import { CargandoInformacion } from "../../../globales/estadisticas/CargandoInformacion";
import { DescargarReporte } from "../../Reportes/DescargarReporte";
import { DetalleEstudiantesAPI } from "../../../../api/estadisticas/DetalleEstudiantesAPI";
import { useFechaPorDefecto } from "../../hooks/Fechas";
import { SelectorRol } from "../../../globales/estadisticas/SelectorRol";
import { normalizeString } from "../../../../utilities/StringUtilities";
import PropTypes from "prop-types";
import { CancelRequestController } from "../../../../utilities/cancel-request-controller";

/**
 * Este componente tiene la función de cargar todas las estadísticas de los ingresos y lecturas de los estudiantes con base a un rango de fechas, sede y/o curso.Por otro lado, también se encarga de transformar los datos estadísticos a formato excel para que puedan ser descargados por el usuario.
 */
const IngresosEstudiantesComponent = (props) => {
  const { traducciones } = props;
  const { miga_de_pan, ingresos_individuales, botones } = traducciones;
  const { tabla, titulo, descripcion, detalle_de, resultados, buscador, resumen } = ingresos_individuales;

  const history = useHistory();
  const state = useTrackedState();
  const queryParams = useQueryParams();
  const fechaPorDefecto = useFechaPorDefecto();
  const [filtros, setFiltros] = useState({
    sede: undefined,
    curso: undefined,
    fechas: {
      inicio: fechaPorDefecto.fechaInicio,
      fin: fechaPorDefecto.fechaFin
    }
  })

  const [nombreSedeActual, setnombreSedeActual] = useState(filtros?.sede?.nombre || 'N/A');
  const [rangoFechas, setRangoFechas] = useState({
    inicio: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaInicio),
    fin: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaFin),
  })

  const [estadisticas, setEstadisticas] = useState({
    ingresos: [],
    ingresosFiltrados: []
  });

  const [cargando, setCargando] = useState({
    estadisticas: false
  })



  const actualizarFiltros = (filtrosActualizados) => {
    setFiltros({
      ...filtros,
      curso: filtrosActualizados?.curso,
      sede: filtrosActualizados?.sede,
      fechas: {
        inicio: filtrosActualizados?.fechas[0],
        fin: filtrosActualizados?.fechas[1]
      },
      fechasUsuario: {
        inicio: FiltroFechas.formatoUsuario(state?.idioma, filtrosActualizados?.fechas[0]),
        fin: FiltroFechas.formatoUsuario(state?.idioma, filtrosActualizados?.fechas[1])
      }
    })
  }


  const filtrarPorInput = ({ value }) => {
    if (value && value.trim().length > 0) {
      const coincidencias = estadisticas?.ingresos.filter(ingreso => normalizeString(ingreso?.nombreCompleto)?.includes(normalizeString(value)));
      setEstadisticas({
        ...estadisticas,
        ingresosFiltrados: coincidencias
      })
    } else {
      setEstadisticas({
        ...estadisticas,
        ingresosFiltrados: estadisticas?.ingresos
      })
    }

  }

  /**
   * transforma la información suministrada por las APIs a una tabla que se puede descargar como excel.
   * @returns tabla para reporte
   */
  const generarInformacionTabla = () => {
    const columnas = ESTADISTICAS_COLUMNAS_TABLAS.ingresosIndividuales.map(col => {
      const traduccion = traducciones?.ingresos_individuales?.tabla[`${col?.traduccion}`]
      return { nombreColumna: traduccion || col.textoPorDefecto }
    })

    const filas = [];
    estadisticas?.ingresos.forEach(ingreso => {
      let fila = [];
      ESTADISTICAS_COLUMNAS_TABLAS.ingresosIndividuales.forEach(col => {
        let valor = ingreso[`${col?.propiedad}`];
        if (Array.isArray(ingreso[`${col?.propiedad}`])) {
          valor = ingreso[`${col?.propiedad}`].join(',')
        }
        else if (col?.propiedad === 'sede') {
          valor = valor?.nombreSede;
        }
        else if (col?.propiedad === 'curso') {
          valor = valor?.nombreCurso;
        }
        fila.push({ valor: valor })
      })
      filas.push(fila);
    })

    const tabla = {
      columnas: columnas,
      filas: filas
    }
    return tabla;
  }


  useEffect(() => {
    setRangoFechas({
      inicio: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.inicio),
      fin: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.fin),
    })
  }, [filtros, state?.idioma])

  useEffect(() => {
    if (filtros?.sede?.id !== 'todo' || Boolean(filtros?.sede) === false) {
      setnombreSedeActual(filtros?.sede?.nombre);
    } else {
      setnombreSedeActual(state?.datosInstitucion?.nombre)
    }
  }, [filtros?.sede])


  useEffect(() => {
    const abortController = new CancelRequestController();
    setCargando({
      ...cargando,
      estadisticas: true
    })
    const actualizarEstadisticas = async () => {
      const sede = filtros?.sede;
      const curso = filtros?.curso;
      const rangoFechas = {
        inicio: filtros?.fechas.inicio || FiltroFechas.formatoAPI(state?.idioma, moment()),
        fin: filtros?.fechas.fin || FiltroFechas.formatoAPI(state?.idioma, moment())
      };

      if (sede?.id === 'todo' || curso?.id === 'todo') {
        await DetalleEstudiantesAPI
          .obtenerDetallesPorSede({idSede:sede?.id === 'todo' ? undefined : sede?.id, fechaInicio:rangoFechas?.inicio, fechaFin:rangoFechas?.fin, abortSignal: abortController.signal})
          .then(resultado => {

            setEstadisticas({
              ingresos: resultado,
              ingresosFiltrados: resultado,
            })
            setCargando({
              ...cargando,
              estadisticas: false
            })
          })
          .catch(error => console.log(error))
      }
      else if (curso?.id !== 'todo') {
        await DetalleEstudiantesAPI
          .obtenerDetallesPorCurso({idCurso:curso?.id, fechaInicio:rangoFechas?.inicio, fechaFin:rangoFechas?.fin, abortSignal: abortController.signal})
          .then(resultado => {
            setEstadisticas({
              ingresos: resultado || [],
              ingresosFiltrados: resultado || [],
            })
            setCargando({
              ...cargando,
              estadisticas: false
            })
          })
          .catch(error => console.log(error))
      }
    }
    if (filtros?.fechas?.inicio && filtros?.fechas?.fin) {
      actualizarEstadisticas();
    }
    return ()=>{
      abortController.abort();
      setCargando({
        ...cargando,
        estadisticas: true
      })
    }
  }, [JSON.stringify(filtros)])



  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.estadisticas || 'Estadisticas'}
          alSeleccionar={() => {
            history.push('/estadisticas')
          }} />
        <Miga
          texto={miga_de_pan?.ingresos_individuales || 'Ingresos individuales'}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{`${ingresos_individuales?.seccion?.estudiante?.titulo || 'Ingresos individuales de estudiantes'}`}</h1>
        <p className="informacion-principal">{descripcion}</p>

        <Filtro
          alActualizarFiltros={actualizarFiltros}
          calendarioProps={{
            fechaMaxima: moment().format('YYYY-MM-DD'),
            fechaMinima: state?.datosInstitucion?.fechaInicioSuscripcion || '',
            modoRango: true,
            fechaPorDefecto: [
              state?.estadisticas?.filtros?.fechas?.inicio || queryParams.get('fechaInicio') || fechaPorDefecto?.inicio, 
              state?.estadisticas?.filtros?.fechas?.fin || queryParams.get('fechaFin') || fechaPorDefecto.fin
            ],
            zIndex: 9999,
            modoMultiple: false,
            placeholder: ''
          }}
          sedePorDefecto={
             state?.estadisticas?.filtros?.sede || 
             queryParams.get('sede')
            }
          cursoPorDefecto={   
            state?.estadisticas?.filtros?.curso || 
            queryParams.get('curso')
          }
        />

        <SelectorRol
          alSeleccionarRol={(rol) => {
            if (rol === 'profesor') {
              history.push({
                pathname: '/estadisticas/ingresos-individuales/profesores',
                search: `?fechaInicio=${filtros?.fechas?.inicio}&fechaFin=${filtros?.fechas?.fin}`
              })
            }
          }
          }
          rolPorDefecto={'estudiante'}
          textos={{
            estudiante: ingresos_individuales?.selector_rol?.estudiante || 'Ingresos individuales de estudiantes',
            profesor: ingresos_individuales?.selector_rol?.profesor || 'Ingresos individuales de profesores'
          }}
        />
        <hr className="my-8" />



        {Boolean(cargando?.estadisticas) === true ?
          <CargandoInformacion />

          : <>
            <div className="mb-8">
              <div tabIndex={0} className="focusable-red text-center lg:text-left  mt-5 sm:mt-0 ">
                <p className='q5-32 red-inv-var2--color'>
                  <span className='icon-star-full text-30 mr-3'></span>
                  {estadisticas?.ingresos?.length}  {estadisticas?.ingresos?.length !== 1 ? resumen.plural : resumen.singular}
                </p>
                <p className="r5-14-negro4A">{resumen.descripcion}</p>
              </div>
            </div>

            <TituloSeccion
              titulo={detalle_de}
              fecha={`${rangoFechas.inicio || 'N/A'} - ${rangoFechas.fin || 'N/A'}`}
              input={{
                placeHolder: buscador?.placeholder || 'Encuentra un estudiante',
                textoBorrarFiltro: 'Borrar'
              }}
              alEscribir={(value) => {
                filtrarPorInput(value)
              }}
              elementosPersonalizados={
                <div className="my-2 w-full flex  justify-center sm:justify-end">
                  <DescargarReporte
                    nombreArchivo={`${ingresos_individuales?.titulo || 'Ingresos individuales'} (${rangoFechas?.inicio} - ${rangoFechas?.fin}) (${filtros?.sede?.nombre} - ${filtros?.curso?.nombre})`}
                    tablaInformacion={generarInformacionTabla()}
                  />
                </div>
              }
            />

            <div className="background-var3--bg  mt-5 xl:p-5 rounded-lg">
              <TablaIngresos
                filtros={filtros}
                datos={estadisticas?.ingresosFiltrados}
                textosTabla={{ tabla: tabla, boton: botones.ver_detalle, resultados: resultados }}
              />
            </div>

          </>
        }
      </div>
    </>
  )
}
export default IngresosEstudiantesComponent;

IngresosEstudiantesComponent.propTypes = {
  /**
   * Objeto con los textos que se deben renderizar en la sección
   */
  traducciones: PropTypes.object.isRequired,
}