import { Icon } from "@iconify/react";
import React, { Children, isValidElement, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

/**
 *  Este componente es el contenedor para migas de pan, solo renderiza en su interior componentes tipo Miga.
 * @param {*} props 
 * @type {Miga[]} props.children  -
 * @returns 
 */
const MigasDePan = (props) => {
	const { children } = props;

	const cargarMigas = () => {
		return Children.map(children, (child, i) => {
			if (isValidElement(child) && child.type === Miga) {
				return (
					<>
						{child}
						{i + 1 < Children.count(children) &&
							<Icon className="transform rotate-90 m-1" icon={'dashicons:arrow-up-alt2'} />
						}
					</>
				);
			}
		});
	};
	const [migas, setMigas] = useState(cargarMigas());
	const containerRef = useRef();

	useEffect(() => {
		setMigas(cargarMigas())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children])


	return <nav className="ruta" aria-label="Miga de pan" ref={containerRef}>{migas}</nav>;
};


const Miga = (props) => {
	const { alSeleccionar, texto, esRutaActual } = props;
	return (
		<>
			{esRutaActual ?
				<p
					aria-current="location"
					tabIndex={0}
					className={`onbackground-var1--color font-bold focusable-primary`}
				>
					{texto || 'N/A'}
				</p>
				:
				<button
					className={`onbackground-var1--color raleway-500 rutaAnterior transicion-150 focusable-primary`}
					onClick={() => alSeleccionar && alSeleccionar()}
				>
					{texto || 'N/A'}
				</button>

			}

		</>

	);
};

Miga.propTypes = {
	/**
 * esta propiedad booleana define si la miga referencia la ruta actual y se usa para diferenciar el estilo de los links con el de la ruta actual
 * No modifica funcionamiento ni se comporta diferente, solo crea diferenciador de estilos
 */
	esRutaActual: PropTypes.bool,
	/**
	 * texto de la miga
	 */
	texto: PropTypes.string,
	/**
	 * callback que se ejecuta al momento de seleccionar la miga actual
	 * Por el momento no tiene parametros
	 * @type{Function():void} - callback
	 */
	alSeleccionar: PropTypes.func
}

export { MigasDePan, Miga };
