import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Funciones
import comprobarNombreRepetido from './../../utilities/function/comprobarNombreRepetido';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Apis
import generalCallApi from './../helpers/generalCallApi';
//Componentes
import VerificarCursoComponente from '../../components/cursos/VerificarCurso';
import SkeletonCursosVerificar from '../../components/cursos/SkeletonsCursos/Verificar';


const VerificarCurso = (props) => {
  const { descartarCambios, isLoadingCursos } = props;
  //Estados globales
  const state = useTrackedState();
  const { datosCursos, temporal } = state;
  const dispatch = useDispatch();


  const history = useHistory();


  //Estados
  const [datosCursosNuevos, setDatosCursosNuevos] = useState([]);
  const [hayCursos, setHayCursos] = useState(true)
  const [isDisableButtonCrear, setIsDisableButtonCrear] = useState(false)
  const [modoCreacion, setModoCreacion] = useState(null);
  const [numeroCursos, setNumeroCursos] = useState(null);
  const [preloaderCrearCursos, setpreloaderCrearCursos] = useState(false)
  const [respuestaApiCrearCursos, setrespuestaApiCrearCursos] = useState({
    isError: null,
    mensaje: ''
  })



  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('cursos');


  useEffect(() => {
    if (!!temporal?.cursos) {
      let cursos = [];
      let estudiantes = [];

      const datosCursosTemporal = { ...temporal.cursos.datos };
      const modoActivoCrear = temporal.cursos.modoActivoCrear;
      let crearCursos = [];

      for (const key in datosCursosTemporal) {
        const curso = datosCursosTemporal[key];
        !!curso.estudiantes && curso.estudiantes.map(estudiante => {
          estudiantes.push({
            ...estudiante,
            curso: curso.nombre,
          });
          return estudiante;
        });

        let profesores = [];
        curso.datosProfesores.length !== 0 && curso.datosProfesores.map(profesor => profesores.push(profesor.nombre));

        let cursoNuevo = {
          ...curso,
          nombreProfesor: profesores.join(', '),
          numEstudiantes: !!curso.estudiantes ? curso.estudiantes.length : 0,
          isNombreCursoRepetido: false,
          estudiantes:Array.isArray(curso?.estudiantes)?curso?.estudiantes:[]
        };

        if (temporal.cursos.modoActivoCrear === 'masivo') {
          let nombresCursosNuevos = []
          temporal.cursos.nombreCursos[curso.idSede].forEach(nombreCurso => nombreCurso.key !== curso.key && nombresCursosNuevos.push(nombreCurso))

          const array = [...datosCursos[curso.idSede].cursos, ...nombresCursosNuevos];
          const comprobacionNombre = comprobarNombreRepetido({
            array: array,
            nombreCurso: curso.nombre,
          });

          cursoNuevo = {
            ...cursoNuevo,
            isNombreCursoRepetido: comprobacionNombre.estaRepetida
          };

          crearCursos.push(comprobacionNombre.estaRepetida)
        };

        cursos.push(cursoNuevo);
      }

      const isDisableButtonCrear = crearCursos.find(curso => curso === true);
      setIsDisableButtonCrear(isDisableButtonCrear);

      setModoCreacion(modoActivoCrear);
      const datos = modoActivoCrear === 'masivo' ? cursos : cursos[0];

      setNumeroCursos(datos.length);
      setDatosCursosNuevos(datos);
    } else {
      if (respuestaApiCrearCursos.isError === null) {
        history.push('/cursos');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporal]);


  useEffect(() => {
    if (respuestaApiCrearCursos.isError === false) {
      dispatch({
        type: "SET_DATA",
        property: "temporal",
        value: {}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respuestaApiCrearCursos.isError]);


  useEffect(() => {
    const validacionCursos = datosCursosNuevos.length !== 0 ? true : false;
    setHayCursos(validacionCursos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursosNuevos])


  const crearCursos = async () => {
    if (!preloaderCrearCursos && respuestaApiCrearCursos.isError !== false) {
      setpreloaderCrearCursos(true);
      setrespuestaApiCrearCursos({
        isError: null,
        mensaje: ''
      });

      const datos = modoCreacion === 'individual' ? [datosCursosNuevos] : datosCursosNuevos;


      const pathname = "/cursos/crearCursos";
      const properties = datos;

      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        let cursosInstituto = { ...datosCursos };
        await generalCallApi({pathname:'/cursos/obtenerCursos'})
        .then(resultado=>{
          const cursos = resultado?.data;
          cursosInstituto = {...cursos?cursos:datosCursos}
        })
        .catch(error=>console.log(error))

        dispatch({
          property: 'datosCursos',
          type: 'SET_DATA',
          value: cursosInstituto
        });
      };

      setpreloaderCrearCursos(false);
      setrespuestaApiCrearCursos({
        isError: error,
        mensaje: result.info
      });
    };
  };


  //Eliminar curso
  const eliminarCurso = ({ key }) => {
    const cursosNuevos = { ...temporal.cursos.datos };
    let cursosActualizados = cursosNuevos;

    delete cursosActualizados[key]

    const datosCursosTemporal = temporal.cursos.datos;
    let nombreCursosNuevos = {};

    for (const key in cursosActualizados) {
      const curso = datosCursosTemporal[key];
      nombreCursosNuevos[curso.idSede] = !!nombreCursosNuevos[curso.idSede]
        ? nombreCursosNuevos[curso.idSede] = [...nombreCursosNuevos[curso.idSede], { key: curso.key, nombre: curso.nombre }]
        : [{ key: curso.key, nombre: curso.nombre }];
    }

    const nuevosDatosTemporales = {
      ...temporal.cursos,
      datos: cursosActualizados,
      nombreCursos: nombreCursosNuevos,
    }


    dispatch({
      type: "SET_DATA_TEMPORAL",
      property: "cursos",
      value: nuevosDatosTemporales
    });
  };


  return (
    <>
      {
        isReady && !isLoadingCursos ? (
          <>
            <VerificarCursoComponente
              crearCursos={crearCursos}
              datosCursosNuevos={datosCursosNuevos}
              descartarCambios={descartarCambios}
              eliminarCurso={eliminarCurso}
              hayCursos={hayCursos}
              isLoading={isLoadingCursos}
              isDisableButtonCrear={isDisableButtonCrear}
              isReady={isReady}
              modoCreacion={modoCreacion}
              numeroCursos={numeroCursos}
              preloaderCrearCursos={preloaderCrearCursos}
              respuestaApiCrearCursos={respuestaApiCrearCursos}
              textosInterfaz={{
                botones: textosInterfaz.botones,
                curso_creado: textosInterfaz.curso_creado,
                descartar_cambios: textosInterfaz.descartar_cambios,
                miga_de_pan: textosInterfaz.miga_de_pan,
                no_hay_cursos: textosInterfaz.no_hay_cursos,
                verificar_datos: textosInterfaz.verificar_datos,
                notificacion:textosInterfaz.notificacion,
              }}
            />
          </>
        )
          : <SkeletonCursosVerificar modoCreacion={modoCreacion} />
      }
    </>
  )
}

export default VerificarCurso;