//Hooks y funciones personalizadas
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';

//component
import LibrosLeidosEstudianteComponent from '../../components/estadisticas/LibrosLeidos/LibrosLeidosEstudiantes/LibrosLeidosEstudianteComponent';
import SkeletonLibrosLeidos from '../../components/estadisticas/SkeletonsEstadisticas/SkeletonLibrosLeidos';
import { Redirect, Route, Switch } from 'react-router-dom';
import LibrosLeidosProfesorComponent from '../../components/estadisticas/LibrosLeidos/LibrosLeidosProfesores/LibrosLeidosProfesorComponent';

const LibrosLeidos = () => {

  //Hooks personalizado para los textos de interfaz
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('estadisticas')

  return (
    <>
      <Switch>
        <Redirect exact from='/estadisticas/libros-leidos' to="/estadisticas/estudiantes/libros-leidos" />

        <Route exact path="/estadisticas/profesores/libros-leidos">
          {
            isReady ?
              <LibrosLeidosProfesorComponent traducciones={textosInterfaz} />
              :
              <SkeletonLibrosLeidos />
          }
        </Route>
        <Route exact path="/estadisticas/estudiantes/libros-leidos">
          {
            isReady ?
              <LibrosLeidosEstudianteComponent traducciones={textosInterfaz} />
              :
              <SkeletonLibrosLeidos />
          }
        </Route>
      </Switch>
    </>
  )
}
export default LibrosLeidos;