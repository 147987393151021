import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//componentes
import { MigasDePan, Miga } from "../../globales/MigaDePan";
import Filtro from "../../globales/estadisticas/Filtro";
import TituloSeccion from "../../globales/estadisticas/TituloSeccion";
import TablaSesiones from "./TablaSesiones";
import moment from 'moment';
import 'moment/locale/es';
import { useTrackedState } from "../../../store";
import { FiltroFechas } from "../../../utilities/gestion_fechas/FiltrosFechas";
import { DetallesSesionesAPI } from "../../../api/estadisticas/DetallesSesionesAPI";
import { useQueryParams } from "../../../containers/helpers/hooks/locationHooks";
import { CargandoInformacion } from "../../globales/estadisticas/CargandoInformacion";
import { DescargarReporte } from "../Reportes/DescargarReporte";
import { ESTADISTICAS_COLUMNAS_TABLAS } from "../../../containers/estadisticas/tablas-reportes/ColumnasTablas";
import { useFechaPorDefecto } from "../hooks/Fechas";
import { TarjetaInfoSesion } from "./TarjetaInfoSesion";
import PropTypes from 'prop-types';
import { CancelRequestController } from "../../../utilities/cancel-request-controller";

/**
 * Este componente tiene la función de cargar todas las estadísticas de las sesiones en plataforma de los estudiantes y profesores con base a un rango de fechas, sede y/o curso **(Se debe tener en cuenta que las estadísticas de los profesores solo responden al filtro de fechas, el filtro de sede y curso solo responderá a las estadísticas de los estudiantes)**.Por otro lado, también se encarga de transformar los datos estadísticos a formato excel para que puedan ser descargados por el usuario.
 */
const TotalSesionesComponent = (props) => {
  const { traducciones } = props;
  const { miga_de_pan, total_sesiones, botones } = traducciones;
  const { resumen, titulo, descripcion, detalle_de, tabla, resultados } = total_sesiones;

  const history = useHistory();
  const state = useTrackedState();
  const queryParams = useQueryParams();
  const fechaPorDefecto = useFechaPorDefecto();
  const [filtros, setFiltros] = useState({
    sede: undefined,
    curso: undefined,
    fechas: {
      inicio: fechaPorDefecto.fechaInicio,
      fin: fechaPorDefecto.fechaFin
    }
  })
  const [rangoFechas, setRangoFechas] = useState({
    inicio: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaInicio),
    fin: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaFin),
  })

  const [estadisticas, setEstadisticas] = useState({
    totalSesionesEnPlataforma: 0,
    tiempoPromedioPorSesion: 0,
    totalSesionesEnPlataformaProfesores: 0,
    tiempoPromedioPorSesionProfesores: 0,
    sesiones: [],
    sesionesFiltradas: []
  });

  const [cargando, setCargando] = useState({
    estadisticas: false
  })
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const actualizarFiltros = (filtrosActualizados) => {
    setFiltersLoaded(true);
    setFiltros({
      ...filtros,
      curso: filtrosActualizados?.curso,
      sede: filtrosActualizados?.sede,
      fechas: {
        inicio: filtrosActualizados?.fechas[0],
        fin: filtrosActualizados?.fechas[1]
      },
      fechasUsuario: {
        inicio: FiltroFechas.formatoUsuario(state?.idioma, filtrosActualizados?.fechas[0]),
        fin: FiltroFechas.formatoUsuario(state?.idioma, filtrosActualizados?.fechas[1])
      }
    })
  }

  const filtrarPorInput = ({value})=>{
    if(value && value.trim().length > 0){
     const coincidencias = estadisticas?.sesiones.filter(sesion=>sesion?.fecha.includes(value));
     setEstadisticas({
      ...estadisticas,
      sesionesFiltradas:coincidencias
    })
   }else{
    setEstadisticas({
      ...estadisticas,
      sesionesFiltradas:estadisticas?.sesiones
    })
   }

  }

  /**
   * transforma la información suministrada por las APIs a una tabla que se puede descargar como excel.
   * @returns tabla para reporte
   */
  const generarInformacionTabla = ()=>{
    const columnas = ESTADISTICAS_COLUMNAS_TABLAS.totalSesiones.map(col => {
      const traduccion = traducciones?.total_sesiones?.tabla[`${col?.traduccion}`]
      return { nombreColumna: traduccion || col.textoPorDefecto }
    })

    const filas = [];
    estadisticas?.sesiones.forEach(ingreso => {
      let fila = [];
      ESTADISTICAS_COLUMNAS_TABLAS.totalSesiones.forEach(col => {
        let valor = ingreso[`${col?.propiedad}`];
        fila.push({ valor: valor })
      })
      filas.push(fila);
    })

    const tabla = {
      columnas: columnas,
      filas: filas
    }
    return tabla;
  }

  useEffect(() => {
    setRangoFechas({
      inicio: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.inicio),
      fin: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.fin),
    })
  }, [filtros, state?.idioma])


  
  useEffect(() => {
    const abortController = new CancelRequestController();

    setCargando({
      ...cargando,
      estadisticas: true
    })

    const actualizarEstadisticas = async () => {
      const sede = filtros?.sede;
      const curso = filtros?.curso;
      const rangoFechas = {
        inicio: filtros?.fechas.inicio || FiltroFechas.formatoAPI(state?.idioma, moment()),
        fin: filtros?.fechas.fin || FiltroFechas.formatoAPI(state?.idioma, moment())
      };

      if (sede?.id === 'todo' || curso?.id === 'todo') {
        await DetallesSesionesAPI
          .obtenerPorSede({idSede:sede?.id === 'todo' ? undefined : sede?.id, fechaInicio:rangoFechas?.inicio, fechaFin:rangoFechas?.fin, abortSignal: abortController.signal})
          .then(resultado => {
            setEstadisticas({
              sesiones: resultado.datos || [],
              sesionesFiltradas: resultado.datos || [],
              tiempoPromedioPorSesion: resultado?.tiempoPromedioPorSesion | 'N/A',
              totalSesionesEnPlataforma: resultado?.totalSesionesEnPlataforma | 'N/A',
              tiempoPromedioPorSesionProfesores:resultado?.tiempoPromedioPorSesionProfesores | 'N/A',
              totalSesionesEnPlataformaProfesores:resultado?.totalSesionesEnPlataformaProfesores | 'N/A'
            })
            setCargando({
              ...cargando,
              estadisticas: false
            })
          })
          .catch(error=>console.log(error))
      }
      else if (curso?.id !== 'todo') {
        await DetallesSesionesAPI
          .obtenerPorCurso({idCurso:curso?.id, fechaInicio:rangoFechas?.inicio, fechaFin:rangoFechas?.fin, abortSignal: abortController.signal})
          .then(resultado => {
            setEstadisticas({
              sesiones: resultado.datos || [],
              sesionesFiltradas: resultado?.datos || [],
              tiempoPromedioPorSesion: resultado?.tiempoPromedioPorSesion | 'N/A',
              totalSesionesEnPlataforma: resultado?.totalSesionesEnPlataforma | 'N/A',
              tiempoPromedioPorSesionProfesores:resultado?.tiempoPromedioPorSesionProfesores | 'N/A',
              totalSesionesEnPlataformaProfesores:resultado?.totalSesionesEnPlataformaProfesores | 'N/A'
            })
            setCargando({
              ...cargando,
              estadisticas: false
            })
          })
          .catch(error=>console.log(error))

      }
      
    }
    if (filtros?.fechas?.inicio && filtros?.fechas?.fin && filtersLoaded) {
      actualizarEstadisticas();
    }

    return ()=>{
      abortController.abort();
      setCargando({
        ...cargando,
        estadisticas: true
      })
    }
  }, [filtersLoaded, filtros.fechas.inicio,filtros.fechas.fin, filtros.sede, filtros.curso, state?.idioma])

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.estadisticas || 'Estadisticas'}
          alSeleccionar={() => {
            history.push('/estadisticas')
          }} />
        <Miga
          texto={miga_de_pan?.sesiones || 'Sesiones de lectura diaria'}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{titulo || 'Sesiones de lectura diaria'}</h1>
        <p className="informacion-principal">{descripcion}</p>

        <Filtro
          alActualizarFiltros={actualizarFiltros}
          calendarioProps={{
            fechaMaxima: moment().format('YYYY-MM-DD'),
            fechaMinima: state?.datosInstitucion?.fechaInicioSuscripcion || '',
            modoRango: true,
            fechaPorDefecto: [
              state?.estadisticas?.filtros?.fechas?.inicio || queryParams.get('fechaInicio') || fechaPorDefecto?.inicio, 
              state?.estadisticas?.filtros?.fechas?.fin || queryParams.get('fechaFin') || fechaPorDefecto.fin
            ],
            zIndex: 9999,
            modoMultiple: false,
            placeholder: ''
          }}
          sedePorDefecto={
             state?.estadisticas?.filtros?.sede || 
             queryParams.get('sede')
            }
          cursoPorDefecto={   
            state?.estadisticas?.filtros?.curso || 
            queryParams.get('curso')
          }
        />
        {Boolean(cargando?.estadisticas) === true ?
          <CargandoInformacion />
          :
          <>
            <div className="w-full flex flex-row flex-wrap center bg-mor">
              <div className="m-2 md:mr-5">
                <TarjetaInfoSesion
                  titulo={resumen?.individuos?.estudiantes || 'Estudiantes'}
                  totalSesionesLectura={estadisticas?.totalSesionesEnPlataforma}
                  tiempoPromedioSesion={estadisticas?.tiempoPromedioPorSesion}
                  estiloPersonalizado={{
                    titulo:{
                      class:'bg-morado text-blanco'
                    }
                  }}
                />
              </div>
              <TarjetaInfoSesion
                titulo={resumen?.individuos?.profesores ||'Profesores'}
                totalSesionesLectura={estadisticas?.totalSesionesEnPlataformaProfesores}
                tiempoPromedioSesion={estadisticas?.tiempoPromedioPorSesionProfesores}
                estiloPersonalizado={{
                  titulo:{
                    class:'bg-azul text-blanco'
                  }
                }}
              />
            </div>
            <hr className="my-8"/>

            <TituloSeccion
              titulo={detalle_de}
              fecha={`${rangoFechas.inicio || 'N/A'} - ${rangoFechas.fin || 'N/A'}`}
              input={{ 
                placeHolder: traducciones?.global?.filtros?.fecha ||  'Buscar por fecha (YYYY-MM-DD)',
                 textoBorrarFiltro: 'Borrar' 
              }}
              alEscribir={(value) => {
                filtrarPorInput(value)
              }}
              elementosPersonalizados={
               <div className="my-2 w-full flex  justify-center sm:justify-end">
                 <DescargarReporte
                  nombreArchivo={`${titulo || 'Sesiones de lectura'} (${rangoFechas?.inicio} - ${rangoFechas?.fin}) (${filtros?.sede?.nombre} - ${filtros?.curso?.nombre})`}
                  tablaInformacion={generarInformacionTabla()}
                 />
               </div>
              }
            />

            <div className=" background-var3--bg mt-5 xl:px-10 xl:py-5 rounded-lg">
              <TablaSesiones
                datos={estadisticas?.sesionesFiltradas}
                textosTabla={{ tabla: tabla, resultados: resultados, boton: botones.ver_detalle }}
              />
            </div>
          </>
        }

      </div>
    </>
  )
}
export default TotalSesionesComponent;

TotalSesionesComponent.propTypes = {
  /**
   * Objeto con los textos que se deben renderizar en la sección
   */
  traducciones: PropTypes.object.isRequired,
}