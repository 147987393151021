import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import SelectorDeBusqueda from "../../globales/elementosFormularios/SelectorDeBusqueda";
import MicroModal from "micromodal";
import { store } from 'react-notifications-component';
import { AccesibilidadContext } from '../../../contexts/AccesibilidadContext';

/**componentes */
import Modal from "../../globales/modales/Modal";
import ModalConfirmacion from "../../globales/modales/ModalConfirmacion";
import VentanaAlerta from "../../globales/VentanaAlerta";
import ListaEstudiantesNuevos from "./ListaEstudiantesNuevos";
import { Miga, MigasDePan } from "../../globales/MigaDePan";

const VerificarEstudianteMasivo = (props) => {
  const { actualizarCurso, actualizarSede, cambiarEstudiantesElegidos, cancelarAccionMultiSelect, crearEstudiantes, cursoSeleccionado, cursosSede, datosEstudiantesNuevos, descartarCambios, eliminarEstudiantes, eliminarEstudianteIndividual, estudiantesElegidos, mostrarMultiSelect, moverEstudiantes, preloader, respuestaApiCrear, sedeSeleccionada, sedesInstituto, textosInterfaz, cursosEstudiantes } = props;

  const { botones, miga_de_pan, descartar_cambios, verificar_datos, estudiante_creado, no_hay_estudiantes, multi_accion, modal_mover, modal_exito, modal_eliminar } = textosInterfaz;

  const { isError, mensaje } = respuestaApiCrear;
  const history = useHistory();

  /**estados */
  const [modalDescartar, setModalDescartar] = useState(false);
  const [modalMover, setModalMover] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalConfirmarMover, setModalConfirmarMover] = useState(false);
  const accesibilidad = useContext(AccesibilidadContext);

  /**Una vez cambia el valor de modal, al dar click en la miga de pan, esta aparece sin necesidad de dar click dos veces  */
  useEffect(() => {
    if (modalDescartar) {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }, [modalDescartar]);

  useEffect(() => {
    if (modalMover) {
      MicroModal.show('estas-seguro-mover', {
        awaitCloseAnimation: true
      });
    }
  }, [modalMover]);

  useEffect(() => {
    if (modalConfirmarMover === true) {
      MicroModal.close('estas-seguro-mover', {
        awaitCloseAnimation: true
      });
      MicroModal.show('estudiante-movido', {
        awaitCloseAnimation: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfirmarMover]);

  useEffect(() => {
    if (modalEliminar) {
      MicroModal.show('estas-seguro-eliminar', {
        awaitCloseAnimation: true
      });
    }
  }, [modalEliminar]);

  /**Una vez que cambia el valor isError, al dar click en crear estudiante, la ventana modal de creado aparece   */
  useEffect(() => {
    if (isError !== null) {
      if (!isError) {
        MicroModal.show('estudiante-creado', {
          awaitCloseAnimation: true
        });
      } else {
        notificacion(textosInterfaz.notificacion.error, mensaje, "danger")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  /**funciones */
  const actualizarSedeMultiSelect = ({ opcion }) => {
    actualizarSede({ idSede: opcion.id, value: 'valor', tipoAccion: 'multiselect' });
  };

  const actualizarCursoMultiSelect = ({ opcion }) => {
    actualizarCurso({ idCurso: opcion.id, idSede: opcion.idSede, value: 'valor', tipoAccion: 'multiselect' })
  };


  const eliminarEstudiantesNuevos = () => {
    eliminarEstudiantes({});
    MicroModal.close('estas-seguro-eliminar', {
      awaitCloseAnimation: true
    });
    MicroModal.show('estudiante-eliminado', {
      awaitCloseAnimation: true
    });
  }


  const notificacion = (titulo, mensaje, tipo) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.principal || 'Estudiantes'}
          alSeleccionar={() => {
            if (modalDescartar) {
              MicroModal.show('cancelar-crear', {
                awaitCloseAnimation: true
              });
            } else {
              setModalDescartar(true)
            }
          }}>
        </Miga>
        <Miga
          texto={miga_de_pan?.crear || 'Crear estudiantes'}
          alSeleccionar={() => {
            history.push('/estudiantes/crear-estudiantes')
          }}>
        </Miga>
        <Miga
          texto={miga_de_pan?.verificar || 'Verificar estudiantes'}
          esRutaActual={true}>
        </Miga>
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales">{verificar_datos.titulo}</h1>

        {datosEstudiantesNuevos.length !== 0 ?
          <>
            <ListaEstudiantesNuevos
              datosEstudiantes={datosEstudiantesNuevos}
              eliminarEstudianteIndividual={eliminarEstudianteIndividual}
              textosTabla={verificar_datos}
              botones={botones}
              cambiarEstudiantesElegidos={cambiarEstudiantesElegidos}
            />

            {/* barra de seleccionados */}
            <div className={`${accesibilidad.temaActual !== "clasico" && 'border-b-2 onbackground-var1--border-color'} multiselector  fixed left-0 primary-var2--bg w-full py-4 transicion-200  ${mostrarMultiSelect ? `animate-slideIn -top-0` : ` animate-slideOut -top-72`}`}>
              <div className='lg:flex'>
                <div className="lg:w-4/12  alineado-verticalmente pl-5 xl:pl-10">
                  <button aria-label="desactivar seleccionados"  onClick={cancelarAccionMultiSelect} className='onprimary-var1--color text-20 mr-3 hover:text-amarillo focusable-red'>
                    <span className='icon-cerrar'></span>
                  </button>
                  <p className='q7-20-blanco'>
                    {estudiantesElegidos.total} {estudiantesElegidos.total !== 1 ? multi_accion.estudiantes.singular : multi_accion.estudiantes.plural}
                  </p>
                </div>
                <div className="lg:w-8/12  md:alineado-verticalmente lg:alineado-izquierda-center gap-4 px-5 xl:px-0 mt-5 lg:mt-0">
                  <div className=' md:w-4/12 lg:w-60'>
                    <SelectorDeBusqueda
                      cambiarElegido={actualizarSedeMultiSelect}
                      estiloMultiAction={true}
                      opciones={sedesInstituto}
                      superposicion={true}
                      textoDefault={multi_accion.cambiar_sede}
                      textoElegido={sedeSeleccionada.nombre}
                    />
                  </div>

                  <div className='md:w-4/12 lg:w-60 mt-3 md:mt-0 '>
                    <SelectorDeBusqueda
                      cambiarElegido={actualizarCursoMultiSelect}
                      estiloMultiAction={true}
                      opciones={cursosSede.resultados}
                      textoDefault={multi_accion.curso.titulo}
                      textoElegido={cursoSeleccionado.nombre}
                      textoNoHayOpciones={multi_accion.curso.no_hay}
                    />
                  </div>

                  <button
                    className={`focusable-red md:w-4/12 lg:w-auto quicksand-700 text-17.6 center pt-3 md:pt-0 ${sedeSeleccionada.id !== null && cursoSeleccionado.id !== null ? 'onprimary-var1--color hover:text-amarillo' : `bg-transparent cursor-default ${accesibilidad.temaActual === "clasico" ? 'text-gris-alfa' : 'ondisabled-var1--color'}`}`}
                    onClick={() => {
                      if (sedeSeleccionada.id !== null && cursoSeleccionado.id !== null) {
                        if (modalMover) {
                          MicroModal.show('estas-seguro-mover', {
                            awaitCloseAnimation: true
                          });
                        } else {
                          setModalMover(true)
                        }
                      }
                    }}
                  >
                    <span className='icon-mover mr-1 lg:hidden xl:block'></span>
                    {botones.mover}
                  </button>
                  <button
                    className="focusable-red md:w-4/12 lg:w-auto mr-10 onprimary-var1--color hover:text-amarillo quicksand-700 text-17.6 center pt-3 md:pt-0"
                    onClick={() => {
                      if (modalEliminar) {
                        MicroModal.show('estas-seguro-eliminar', {
                          awaitCloseAnimation: true
                        });
                      } else {
                        setModalEliminar(true)
                      }
                    }}
                  >
                    <span className="icon-basura mr-1 lg:hidden xl:block"></span>
                    {estudiantesElegidos.total !== 1 ? botones.eliminar.plural : botones.eliminar.singular}
                  </button>
                </div>
              </div>
            </div>

            <div className="alineado-izquierda-center my-5">
              <Link
                to="/estudiantes/crear-estudiantes"
                className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center mr-5 focusable-primary"
              >
                <span className="icon-volver pr-3"></span>
                {botones.regresar}
              </Link>
              <button
                onClick={crearEstudiantes}
                className="boton-pequenio boton-amarillo boton_hover_naranja text-16 mt-5 md:mt-0 text-center focusable-primary"
              >
                {
                  preloader ?
                    <div className="center">
                      <span className="icon-spinner7 animate-spin text-20"></span>
                    </div>
                    : botones.crear.masivo
                }
              </button>
            </div>
          </>
          :
          <VentanaAlerta
            textosInterfaz={{
              titulo: no_hay_estudiantes.titulo,
              descripcion: no_hay_estudiantes.descripcion,
            }}
            imagen={{
              alt: 'blop triste porque no hay cursos',
              img: 'alertas/blop-alerta-cursos.png',
            }}
          >
            <Link
              className="boton-justo mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado"
              to='/estudiantes/crear-estudiantes'
            >
              {botones.volver.crear_estudiantes}
            </Link>
          </VentanaAlerta>
        }
      </div>



      {/* Modal pregunta si desea mover */}
      {modalMover &&
        <>
          <Modal nombreModal="estas-seguro-mover">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-pregunta.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_mover.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{modal_mover.descripcion_1} <span className="text-resaltado"> {estudiantesElegidos.total} {estudiantesElegidos.total !== 1 ? multi_accion.estudiantes.singular : multi_accion.estudiantes.plural} </span> {modal_mover.descripcion_2} <span className="text-resaltado">{sedeSeleccionada.nombre} - {cursoSeleccionado.nombre}</span> {modal_mover.descripcion_3}</p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-mover`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" onClick={() => {
                moverEstudiantes();
                setModalConfirmarMover(true)
              }}>
                <p>{botones.mover}</p>
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion  movidos */}
          <ModalConfirmacion nombreModal="estudiante-movido">
            <button
              aria-label="Cerrar ventana"
              className="icon-cerrar text-20 right-6 absolute focusable-primary onbackground-var1--color"
              onClick={() => {
                setModalConfirmarMover(false);
                MicroModal.close(`estudiante-movido`, {
                  awaitCloseAnimation: true
                });
              }}>
            </button>

            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center "> {modal_exito.mover.titulo}</h1>

            <p className="text-center r7-16-negro26 mb-4">
              {estudiantesElegidos.total !== 1 ? modal_exito.mover.descripcion.masivo : modal_exito.mover.descripcion.individual}
            </p>
          </ModalConfirmacion>
        </>
      }

      {/* Modal pregunta si desea eliminar */}
      {modalEliminar &&
        <>
          <Modal nombreModal="estas-seguro-eliminar">
            <div className="pb-3">
              <img
                alt="eyex frente a un bote de basura"
                className="w-44 lg:w-52 mx-auto mb-3 bg-img"
                src='/images/eyex-eliminar.png'
              />
              <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_eliminar.titulo}</h1>
            </div>
            <div className="text-center r7-16-negro26 mb-4">
              <p>{modal_eliminar.descripcion_1} <span className="text-resaltado">{estudiantesElegidos.total} {estudiantesElegidos.total !== 1 ? multi_accion.estudiantes.singular : multi_accion.estudiantes.plural} </span>{modal_eliminar.descripcion_2} </p>
            </div>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => {
                  MicroModal.close(`estas-seguro-eliminar`, {
                    awaitCloseAnimation: true
                  });
                }}
                className="boton-justo py-1 sm:px-10 boton-amarillo boton_hover_morado mr-5 focusable-primary"
              >
                {botones.cancelar}
              </button>
              <button className="boton-justo sm:px-10 boton-rojo boton_hover_morado focusable-primary" onClick={eliminarEstudiantesNuevos}>
                <p>{estudiantesElegidos.total !== 1 ? botones.eliminar.plural : botones.eliminar.singular}</p>
              </button>
            </div>
          </Modal>

          {/* Modal confirmacion de eliminados */}
          <ModalConfirmacion nombreModal="estudiante-eliminado">
            <button
              aria-label="Cerrar ventana"
              className="icon-cerrar text-20  right-6 absolute focusable-primary onbackground-var1--color"
              onClick={() => {
                MicroModal.close(`estudiante-eliminado`, {
                  awaitCloseAnimation: true
                });

              }}>
            </button>

            <img
              alt="eyex feliz"
              className="w-5/12  mx-auto bg-img"
              src='/images/eyex-creado.png'
            />
            <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{modal_exito.eliminar.titulo} </h1>

            <p className="text-center r7-16-negro26 mb-4">
              {estudiantesElegidos.total !== 1 ? modal_exito.eliminar.descripcion.masivo : modal_exito.eliminar.descripcion.individual}
            </p>
          </ModalConfirmacion>
        </>
      }


      {/* Modal descartar*/}
      {modalDescartar &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              onClick={descartarCambios}
              className="boton-justo boton-amarillo boton_hover_morado py-1 px-10 focusable-primary"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal estudiante -creado */}
      {!isError &&
        <ModalConfirmacion nombreModal="estudiante-creado">
          <Link className="icon-cerrar text-20  right-6 absolute focusable-primary onbackground-var1--color" to="/estudiantes"></Link>
          <img
            alt="eyex feliz"
            className="w-5/12 mx-auto bg-img"
            src='/images/eyex-creado.png'
          />
          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{estudiante_creado.titulo}</h1>
          <p className="text-center r7-16-negro26 mb-4">

            {estudiante_creado.masivo.descripcion} <span className="text-resaltado">{datosEstudiantesNuevos.length}</span>  {datosEstudiantesNuevos.length !== 1 ? estudiante_creado.masivo.estudiantes.plural : estudiante_creado.masivo.estudiantes.singular}

            {cursosEstudiantes.numeroCursos === 1 ?
              <> {estudiante_creado.masivo.cursos.singular} <span className="text-resaltado">{cursosEstudiantes.cursos} </span></>
              :
              <> {estudiante_creado.masivo.en} <span className="text-resaltado">{cursosEstudiantes.numeroCursos} </span>  {estudiante_creado.masivo.cursos.plural}</>
            }

            {cursosEstudiantes.numeroSedes === 1 ?
              <> {estudiante_creado.masivo.en} {estudiante_creado.masivo.sede.singular} <span className="text-resaltado">{cursosEstudiantes.sedes}</span></>
              :
              <> {estudiante_creado.masivo.en} <span className="text-resaltado">{cursosEstudiantes.numeroSedes}</span>  {estudiante_creado.masivo.sede.plural} </>
            }
          </p>
          <div className="center my-3">
            <Link
              to="/estudiantes"
              className="boton-justo boton-amarillo boton_hover_morado py-2 focusable-primary"
            >
              {botones.volver.principal}
            </Link>
          </div>
        </ModalConfirmacion>
      }
    </>
  )
}
export default VerificarEstudianteMasivo;