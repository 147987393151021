import { Route, Switch } from "react-router-dom";
import Error_404 from "../../../containers/Error_404";
import { BooksHome } from "./pages/books-home";
import { RecentlyReadBooks } from "./pages/recently-read-books";
import { RecommendedBooks } from "./pages/recommended-books";
import { FavoriteBooks } from "./pages/favorite-books";

export const Books = () => {
	return (
		<Switch>
			<Route exact path="/estudiante/libros">
				<BooksHome />
			</Route>

			<Route exact path="/estudiante/libros/leidos-recientemente">
				<RecentlyReadBooks />
			</Route>

			<Route exact path="/estudiante/libros/escogidos-por-el-profesor">
				<RecommendedBooks />
			</Route>

			<Route exact path="/estudiante/libros/favoritos">
				<FavoriteBooks />
			</Route>
			<Route component={Error_404} />
		</Switch>
	);
};
