import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

/**Componentes */
import InputText from "../globales/elementosFormularios/InputText";

/**
 * Este componente recibe una serie de props que le permiten renderizar el formulario de acceso para los estudiantes. Además, renderiza el componente InputText.
 * @returns InicioEstudiante
 */
const InicioEstudiante = (props) => {
  const { actualizarPosicion, textosDeInterfaz, setCodigo, codigo, iniciarSesionEstudiante, isLoading, mostrarAlertaEstudiante, isError } = props;
  const { boton, tipo_usuario } = textosDeInterfaz;
  const { estudiante } = tipo_usuario;

  return (
    <>
      <div className={'background-var2--bg onbackground-var1--color'}>
        <button
          className='focusable-primary alineado-verticalmente cursor-pointer mb-5'
          onClick={() => { actualizarPosicion() }}
        >
          <span className='icon-atras text-20 mr-3 '></span>
          <p className='q7-20'>{boton.volver}</p>
        </button>
        <p className='q7-28 pb-5'>{estudiante.titulo}</p>

        {mostrarAlertaEstudiante && isError.tipoError === "estudiante_incorrecto" &&
          <p className='q7-20-rojo'>{tipo_usuario.error[isError.tipoError]} </p>
        }

        <p className={`q7-20-morado pb-5`}>{estudiante.input.titulo} </p>
        <InputText
          cambiarEstado={setCodigo}
          estado={codigo}
          estadoEnviar={mostrarAlertaEstudiante}
          identidicador="codigo"
          mensajeError={!codigo.valido && estudiante.input.error}
          placeholder={estudiante.input.placeholder}
          validarExpresion={/^[a-zA-Z0-9_-]{4,30}$/}
          required={true}
        />

        <div className='alineado-verticalmente mt-5'>
          <div
            className='red-inv-var2--bg  onred-inv-var1--color w-7 h-7 rounded-full center cursor-pointer'
            data-for='tool-ayuda'
            data-tip
          >
            <p className='q7-24'>?</p>
          </div>
          <p className='ml-5 q7-20'>{estudiante.ayuda.titulo} </p>
          <ReactTooltip
            backgroundColor="#000"
            className="quicksand-400 w-44"
            effect="solid"
            id="tool-ayuda"
            place="top"
            textColor="var(--onprimary-var1)"
          >
            <p>{estudiante.ayuda.tool_tip}</p>
          </ReactTooltip>
        </div>
        <div className='alineado-izquierda-center py-8'>
          <button
            className='focusable-red boton-justo boton-amarillo boton_hover_morado q7-20 px-8'
            onClick={(e) => {
              iniciarSesionEstudiante({ e, codigo: codigo.campo });
            }}
          >
            {
              isLoading ?
                <div className="center text-center mx-">
                  <span className="text-center icon-spinner7 animate-spin text-20 p-1"></span>
                </div>
                :
                boton.iniciar_sesion
            }
          </button>
        </div>

        {/* <div className='bg-verder border-t border-gris-linea py-10'>
          <button className='w-full md:w-10/12 mx-auto boton-justo boton-rojo-google center mb-5 boton_hover_escalar'>
            <span className='icon-google mr-3'></span>
            {otros_login.google}
          </button>

          <button className='w-full md:w-10/12 mx-auto boton-justo boton-azul-microsoft center boton_hover_escalar'>
            <span className='icon-microsoft mr-3 text-20'></span>
            {otros_login.microsoft}
          </button>
        </div> */}
      </div>
    </>
  )
}
export default InicioEstudiante;

InicioEstudiante.propTypes = {
  /**
   * Funcion que actualiza la posición del contenido a 0 para reubicarlos. 
   */
  actualizarPosicion: PropTypes.func.isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object,
  /**
   * Función que actualiza el objeto codigo 
   */
  setCodigo: PropTypes.func.isRequired,
  /**
   * Objeto que contiene el valor y la validacion del input usuario
   */
  codigo: PropTypes.shape({
    campo: PropTypes.string,
    valido: PropTypes.bool
  }).isRequired,
  /**
   * función que valida e inicia la sesión del estudiante
   * @type{Function({ e: event, codigo: string }):void} - callback
   */
  iniciarSesionEstudiante: PropTypes.func.isRequired,
  /**
   * booleano que indica que el api no ha terminado de responder. 
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * boleano que indica si el campo de codigo esta diligeniado correctamente 
   */
  mostrarAlertaEstudiante: PropTypes.bool.isRequired,
  /**
   * object que indica si el API de login respondio con un error.
   */
  isError: PropTypes.object.isRequired,
}