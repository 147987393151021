import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { AccionesLibroAPI } from "../../../../api/AccionesLibroAPI";
import { Libro } from "../../../../components/globales/libro/LibroComponent";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

/**
 * Componente que recibe el texto de interfaz y la información de los libros favoritos, para renderizar la sección en el Home. Además, hace uso del componente global "libro".
 * @returns FavoritosEstudiantes
 */
export const FavoriteBooks = ({ favoritos }) => {
	const [favoritosAMostrar, setFavoritosAMostrar] = useState([]);
	const [sobreBoton, setSobreBoton] = useState(false);
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const noResults = textos?.home?.favoritos?.no_hay || 'Nada por aquí aún...';

	const history = useHistory();

	const agregarRemoverAFavoritos = async (libro, favorito) => {
		await AccionesLibroAPI.agregarRemoverAFavoritos([libro?.idLibro], favorito).catch((error) =>
			console.log(error)
		);
	};

	useEffect(() => {
		if (Array.isArray(favoritos)) {
			if (favoritos.length > 1) {
				setFavoritosAMostrar(favoritos.slice(0, 2));
			} else {
				setFavoritosAMostrar(favoritos);
			}
		} else {
			setFavoritosAMostrar([]);
		}
	}, [favoritos]);

	const mostrarLibro = (idLibro) => {
		history.push({
			pathname: `/visor/${idLibro}`,
			state: { idLibro: idLibro, pathAnterior: window.location.pathname },
		});
	};

	return (
		<>
			{Array.isArray(favoritos) && favoritos.length !== 0 ? (
				<>
					<div className=" grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-2 gap-8 mt-5">
						{favoritosAMostrar.map((libro, i) => {
							return (
								<div key={`librofavorito-${i}`} className="my-5">
									<Libro
										key={`libro-${i}`}
										libro={libro}
										clasesPersonalizadas={{
											contenedor: "min-w-full",
											portada: "min-w-full",
											titulo: "",
										}}
										ocultarOpciones={{
											seleccionado: true,
											botonMultiSeleccion: true,
											botonFavoritos: true,
										}}
										estadoInicial={{
											esFavorito: Boolean(libro?.favorito),
										}}
										alSeleccionar={(libro) => mostrarLibro(libro?.idLibro)}
										alAgregarFav={(libro) =>
											agregarRemoverAFavoritos(libro, true)
										}
										alRemoverFav={(libro) =>
											agregarRemoverAFavoritos(libro, false)
										}
										esVisible
									/>
								</div>
							);
						})}
					</div>
				</>
			) : (
				<>
					<div
						className="fondo-rosado-imagen mt-5 h-250 xl:h-350 center img-contain-center xl:img-80-center"
						style={{
							backgroundImage: `${
								sobreBoton
									? "url(/images/perfil-estudiante/favoritos2.png)"
									: "url(/images/perfil-estudiante/favoritos.png)"
							}`,
						}}
					>
						<button
							onMouseOver={() => setSobreBoton(true)}
							onMouseLeave={() => setSobreBoton(false)}
							onClick={() => {
								history.push("/estudiante/libros");
							}}
							className="px-10 focusable-red primary-var2--bordered boton-justo boton-morado boton_hover_morado_oscuro mt-32"
						>
							{noResults}
						</button>
					</div>
				</>
			)}
		</>
	);
};

FavoriteBooks.propTypes = {
	/**
	 * array de objeto que almacena los datos de los libros elegidos como favoritos por el estudiante.
	 */
	favoritos: PropTypes.array.isRequired,
	/**
	 *  Es un string que trae los textos de la interfaz dependiendo del idioma activo.
	 */
	textoHome: PropTypes.string,
};
