import PropTypes from "prop-types";
/**
 * Componente que recibe los props necesarios para crear una ventana modal o emergente de tipo confirmación, esta ventana modal no se puede cerrar dando clic fuera de ella. Este componente recibe la propiedad children, la cual pasa elementos hijos directamente y el nombre de la ventana.
 * @returns ModalConfirmacion
 */
const ModalConfirmacion = (props) => {
  const { children, nombreModal } = props;
  return (
    <div className="micromodal-slide" id={nombreModal} aria-hidden="true">
      <div className="modal__overlay">
        <div className="modal__container_error scroll max-h-screen " role="dialog" aria-modal="true" aria-labelledby="modal-title">
          {children}
        </div>
      </div>
    </div>
  )
}
export default ModalConfirmacion;

ModalConfirmacion.prototypes = {
  /**
    * Elementos que se encuentran dentro de la ventana modal
    */
  children: PropTypes.element.isRequired,
  /**
  * string que se asigna al id de ventana modal
  */
  nombreModal: PropTypes.string,
}