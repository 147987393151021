import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTrackedState } from '../../store';
//Componentes
import DivisionCrud from '../globales/DivisionCrud';
import InputText from '../globales/elementosFormularios/InputText';
import InputFile from '../globales/elementosFormularios/InputFile';
import MicroModal from "micromodal";
import Modal from '../globales/modales/Modal';
import Selector from './../globales/elementosFormularios/Selector';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de props que le permiten renderizar la pantalla crear instituto/colegio. Además, renderiza  los componentes DivisionCrud, InputText, InputFile, MicroModal, Modal  y Selector. 
 * @returns CrearInstitutosComponent
 */
const CrearInstitutosComponent = (props) => {
  const { advertenciasExcel, cambiarPaquete, datosTemporales, descartarCambios, errorExcel, guardarDatosTemporalmente, isLoadingExcel, obtenerDatosExcel, paquetes, paqueteSeleccionado, textosInterfaz } = props;
  const { miga_de_pan, crear, botones, error_excel, descartar_cambios, advertencias } = textosInterfaz;
  const { nombre, paquete, color_instituto, subir_excel, telefono: textoTelefono, correo: textoCorreo } = crear;
  const coloresBotones = process.env.REACT_APP_COLORES_SEDE.split(', ');

  const [autorizar, setAutorizar] = useState(false);
  const [campoPaquete, setCampoPaquete] = useState({ campo: '', valido: false, id: '' });
  const [colorInstituto, setColorInstituto] = useState('');
  const [correo, setCorreo] = useState({ campo: '', valido: true });
  const [excelValido, setExcelValido] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [nombreDocumento, setNombreDocumento] = useState('');
  const [nombreSede, setNombreSede] = useState({ campo: '', valido: '' });
  const [telefono, setTelefono] = useState({ campo: '', valido: true });

  const history = useHistory();
  const state = useTrackedState();
  const { idioma } = state;

  // eslint-disable-next-line no-useless-escape
  const expresionTelefono = /^[\+]?(\d[\s-]?){7,14}$/;
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const handleCheckbox = e => {
    setAutorizar(e.target.checked);
  };

  useEffect(() => {
    if (datosTemporales.hayDatos) {
      setNombreSede({ ...nombreSede, campo: datosTemporales.datos.nombre, valido: true });
      setCampoPaquete({ ...campoPaquete, campo: datosTemporales.datos.paquete.nombre, id: datosTemporales.datos.paquete.id, valido: true });
      setColorInstituto(datosTemporales.datos.color);
      setCorreo({ campo: datosTemporales.datos.correo, valido: true })
      setTelefono({ campo: datosTemporales.datos.telefono, valido: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (errorExcel.hayError) {
      MicroModal.show('error-archivo', {
        awaitCloseAnimation: true
      });
    }
  }, [errorExcel])


  useEffect(() => {
    if (autorizar) {
      setAutorizar(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nombreDocumento])


  const verificarDatos = async () => {
    setMostrarAlerta(true);
    if (correo.valido && telefono.valido) {
      if (nombreSede.valido && campoPaquete.valido && excelValido === true) {
        if (autorizar) {
          await guardarDatosTemporalmente({
            nombre: nombreSede.campo,
            paquete: {
              id: campoPaquete.id,
              nombre: campoPaquete.campo
            },
            color: colorInstituto,
            telefono: telefono.campo,
            correo: correo.campo
          });
          datosTemporales && history.push('/institutos/crear-instituto/verificar-instituto');
        } else {
          if (advertenciasExcel.hayAdvertencias) {
            MicroModal.show('advertencias-excel', {
              awaitCloseAnimation: true
            });
          }
        }

        if (!advertenciasExcel.hayAdvertencias && !errorExcel.hayError) {
          await guardarDatosTemporalmente({
            nombre: nombreSede.campo,
            paquete: {
              nombre: campoPaquete.campo
            },
            color: colorInstituto,
            telefono: telefono.campo,
            correo: correo.campo
          });
          datosTemporales && history.push('/institutos/crear-instituto/verificar-instituto');
        }
      }

    }
  }

  //Ejecuta la funcion guardar datos temporalmente y pasa a la ventana de verificar
  const continuar = async () => {
    if (autorizar) {
      await guardarDatosTemporalmente({
        nombre: nombreSede.campo,
        paquete: {
          id: campoPaquete.id,
          nombre: campoPaquete.campo
        },
        color: colorInstituto,
        telefono: telefono.campo,
        correo: correo.campo
      });
      datosTemporales && history.push('/institutos/crear-instituto/verificar-instituto');
    }
  }

  //muestra la ventana modal si hay campos verificados
  const descartar = () => {
    if (datosTemporales.hayDatos === false) {
      history.push('/institutos');
    } else {
      MicroModal.show('cancelar-crear', {
        awaitCloseAnimation: true
      });
    }
  }

  //cambia el valor del select de paquetes
  const cambiarPaqueteSede = ({ opcion }) => {
    cambiarPaquete({ paquete: opcion });
    setCampoPaquete({ ...campoPaquete, campo: opcion.nombre, id: opcion.id, valido: true });
  }

  //Valida los campos de tipo input
  const validarCampo = (expresion, estado, cambiarEstado) => {
    const valor = estado.campo !== '' ? expresion.test(estado.campo) : true;
    cambiarEstado({ ...estado, valido: valor });
  }

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <button onClick={descartar} className="focusable-primary mr-2">{miga_de_pan.principal}</button>  &gt;
          <span className="ml-2 font-bold ">{miga_de_pan.crear}</span>
        </p>
      </div>
      <div className="contenido">
        <h1 className="titulos-principales mb-5">{crear.titulo}</h1>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{nombre.titulo} <span className="asterisco">*</span></h3>
          </>
          <div className="xl:w-451p">
            <InputText
              cambiarEstado={setNombreSede}
              estado={nombreSede}
              estadoEnviar={mostrarAlerta}
              mensajeError={!nombreSede.valido && nombre.error}
              placeholder={nombre.placeholder_nombre}
              validarExpresion={/^[a-zA-ZÀ-ÿ0-9_\-\s]{1,40}/}
            />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{textoTelefono.titulo}</h3>
            <p className="r4-14-gris6a">{textoTelefono.descripcion}</p>
          </>
          <div className="xl:w-451p">

            <input
              type="tel"
              placeholder={textoTelefono.placeholder}
              className={`w-full ${telefono.valido ? null : 'input_error'}`}
              value={telefono.campo}
              onChange={(e) => { setTelefono({ ...telefono, campo: e.target.value }) }}
              onKeyUp={() => validarCampo(expresionTelefono, telefono, setTelefono)}
              onBlur={() => validarCampo(expresionTelefono, telefono, setTelefono)}
            />

            <p className={`text-14 ${telefono.valido ? 'hidden' : 'alert_error'}`}>{crear.telefono.error}</p>
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{textoCorreo.titulo}</h3>
            <p className="r4-14-gris6a">{textoCorreo.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <input
              type="email"
              placeholder={textoCorreo.placeholder}
              className={`w-full ${correo.valido ? null : 'input_error'}`}
              value={correo.campo}
              onChange={(e) => { setCorreo({ ...correo, campo: e.target.value }) }}
              onKeyUp={() => validarCampo(expresionCorreo, correo, setCorreo)}
              onBlur={() => validarCampo(expresionCorreo, correo, setCorreo)}
            />

            <p className={`text-14 ${correo.valido ? 'hidden' : 'alert_error'}`}>{textoCorreo.error}</p>

          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{paquete.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{paquete.descripcion}</p>
          </>
          <div className="xl:w-451p">
            <Selector
              cambiarElegido={cambiarPaqueteSede}
              estadoEnviar={mostrarAlerta}
              estiloTipoInput={true}
              opciones={paquetes}
              textoDefault={paquete.select}
              textoElegido={paqueteSeleccionado.nombre}
              textoError={paquete.error}
              valido={paqueteSeleccionado.nombre !== '' ? true : false}
            />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{color_instituto.titulo}</h3>
            <p className="r4-14-gris6a">{color_instituto.descripcion}</p>
          </>
          <div className="xl:w-451p grid grid-cols-3 md:grid-cols-6 lg:grid-cols-3">
            {
              coloresBotones.map((item, index) => {
                return (
                  <div key={`boton-${index}`} className="p-2">
                    <button
                      aria-label={index}
                      className={`focusable-primary boton-color rounded-md h-20 flex items-start justify-end`}
                      onClick={() => { setColorInstituto(item); }}
                      style={{ backgroundColor: item, backgroundImage: `url(/images/backBoton.png)` }}
                    >
                      {
                        colorInstituto === item &&
                        <span className='icon-check-cuadro text-blanco-claro text-20 m-2' />
                      }
                    </button>
                  </div>

                )
              })
            }
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <h3 className="raleway-700 onbackground-var1--color">{subir_excel.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{subir_excel.cursos_estudiantes_descripcion}</p>
            <a href='/documents/plantilla_sede.xlsx' download='plantilla_sede.xlsx' className="focusable-primary evento_rojo block mt-3">{subir_excel.descargar_plantilla}</a>
            {/* <a href={idioma === 'es' ? '/documents/plantilla_sede_ES.xlsx' : '/documents/plantilla_sede_EN.xlsx'} download={idioma === 'es' ? "plantilla_sede.xlsx" : "school_template.xlsx"} className="evento_rojo block mt-3">{subir_excel.descargar_plantilla}</a> */}
            <p className="raleway-700">{subir_excel.columnas_plantilla}</p>
          </>
          <InputFile
            datoGuardado={datosTemporales}
            descripcion={subir_excel.cursos_estudiantes_descripcion}
            excelValido={excelValido}
            hayErrorExcel={errorExcel.hayError}
            instruccion={subir_excel.cursos_estudiantes_instruccion}
            mostrarAlerta={mostrarAlerta}
            nombreDocumento={nombreDocumento}
            obtenerDatosExcel={obtenerDatosExcel}
            setExcelValido={setExcelValido}
            setNombreDocumento={setNombreDocumento}
            textoError={subir_excel.excel_no_valido}
            desactivar={isLoadingExcel}
          />
        </DivisionCrud>

        <div className="flex justify-end my-5">
          <button
            onClick={() => {
              descartar();
            }}
            className="focusable-primary boton-pequenio boton-rojo boton_hover_morado text-16 text-center mr-5 "
          >
            {botones.cancelar}
          </button>
          <button
            disabled={errorExcel.hayError ? true : false}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
            onClick={() => { verificarDatos() }}
          >
            {botones.verificar}
          </button>
        </div>
      </div >

      {/* Modal error archivo*/}
      {
        errorExcel.hayError &&
        <Modal nombreModal="error-archivo">
          <div className="border-b pb-3">
            <span className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="q7-28 text-center">{error_excel.titulo} </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {error_excel.descripcion}
          </p>

          <button
            aria-label="Regresar"
            onClick={() => {
              MicroModal.close(`error-archivo`, {
                awaitCloseAnimation: true
              })
            }}
            className="focusable-primary boton-pequenio boton-amarillo boton_hover_morado block mx-auto mb-6"
          >
            {error_excel.boton_regresar}
          </button>
        </Modal>
      }

      {/* Modal descartar cambios */}
      {
        datosTemporales.hayDatos &&
        <Modal nombreModal="cancelar-crear">
          <img
            alt="eyex se pregunta si debe descartar los cambios"
            className="w-5/12 md:w-3/12 mx-auto bg-img"
            src='/images/eyex-pregunta.png'
          />

          <h1 className="quicksand-700 red-inv-var2--color text-24 text-center ">{descartar_cambios.titulo}</h1>

          <p className="text-center r7-16-negro26 ">
            {descartar_cambios.descripcion}
          </p>

          <div className="center my-5">
            <button
              aria-label="Descartar cambios"
              onClick={descartarCambios}
              className="focusable-primary boton-justo boton-amarillo boton_hover_morado py-1 px-10"
            >
              {botones.descartar}
            </button>
          </div>
        </Modal>
      }

      {/* Modal advertencias Modal */}
      {
        advertenciasExcel.hayAdvertencias &&
        <Modal nombreModal="advertencias-excel">
          <div className="border-b pb-3">
            <span className="icon-alerta text-40 red-inv-var2--color text-center block mb-3"></span>
            <h2 className="q7-28 onbackground-var2--color text-center">{advertencias.titulo} </h2>
          </div>

          <p className="text-center r7-16-negro26 my-6">
            {advertencias.descripcion}
          </p>

          <a href={advertenciasExcel.href} download={advertenciasExcel.nombreArchivo} className="focusable-primary evento_rojo text-center r7-16-negro26 block"> {advertencias.archivo.archivo} </a>

          <div className="flex md:items-center md:w-3/4 justify-between  mx-auto my-6">
            <p className="r5-14-negro4A w-9/12  ">{advertencias.archivo.checkbox} </p>
            <label className="focusable-by-children-primary relative w-20" role="checkbox" aria-checked={autorizar}>
              <input type="checkbox" className="h-0" onChange={handleCheckbox} checked={autorizar} />
              <i className="checkbox"><span className="no-check">NO</span></i>
            </label>
          </div>

          <div className="center my-5">
            <button
              aria-label="Cancelar"
              className="focusable-primary boton-pequenio boton-rojo boton_hover_morado sm:flex justify-center items-center mr-5 "
              onClick={() => {
                MicroModal.close('advertencias-excel', {
                  awaitCloseAnimation: true
                });
              }}
            >
              <p className=" texto-blanco">{botones.cancelar}</p>
            </button>

            <button
              aria-label="Continuar"
              className="focusable-primary boton-pequenio boton-amarillo boton_hover_naranja text-16 text-center"
              onClick={continuar}
              disabled={autorizar ? false : true}
            >
              {botones.continuar}
            </button>
          </div>
        </Modal>
      }

    </>
  )
}
export default CrearInstitutosComponent;

CrearInstitutosComponent.propTypes = {

  /**
   * Es un objeto de objetos que contienen ciertas propiedades relacionadas con las advertencias que se encontraron en el archivo subido por el usuario.
   */
  advertenciasExcel: PropTypes.shape(PropTypes.shape({
    hayAdvertencias: PropTypes.bool,
    href: PropTypes.string,
    nombreArchivo: PropTypes.string
  })).isRequired,

  /**
   * Función callback que recibe como parámetro una variable llamada paquete que corresponde a un objeto.
   * @type{Function({ id:string, nombre:string }):void} - callback
   */
  cambiarPaquete: PropTypes.func.isRequired,

  /**
   *  Es un objeto que cuenta con 2 propiedades:
   *  1. datos: Corresponde a un objeto con los datos de la institución que se han diligenciado previamente.
   *  2. hayDatos: Es un booleano que indica si hay datos que se guardaron de forma temporal. 
   */
  datosTemporales: PropTypes.shape({
    datos: PropTypes.shape({
      color: PropTypes.number,
      correo: PropTypes.string,
      cursos: PropTypes.arrayOf(PropTypes.shape({
        estudiantes: PropTypes.arrayOf(PropTypes.shape({
          avatar: PropTypes.number,
          correo: PropTypes.string,
          nombres: PropTypes.string
        })),
        nombre: PropTypes.string,
        profesores: PropTypes.arrayOf(PropTypes.shape({
          avatar: PropTypes.number,
          correo: PropTypes.string,
          cursos: PropTypes.array,
          nombres: PropTypes.string
        })),
      })),
      nombre: PropTypes.string,
      paquete: PropTypes.shape({
        id: PropTypes.string,
        nombre: PropTypes.string
      }),
      profesores: PropTypes.arrayOf({
        avatar: PropTypes.number,
        correo: PropTypes.string,
        cursos: PropTypes.array,
        nombres: PropTypes.string
      }),
      telefono: PropTypes.string
    }),
    hayDatos: PropTypes.bool
  }).isRequired,

  /**
   * Función que se encarga de borrar los datos temporales que se guarden en el store. 
   */
  descartarCambios: PropTypes.func.isRequired,

  /**
   * Objeto que indica si el archivo subido es erróneo y que tipo de error es.
   */
  errorExcel: PropTypes.shape({
    hayError: PropTypes.bool,
    tipoError: PropTypes.string
  }).isRequired,

  /**
   * Función que debe ejecutarse cuando el usuario haga clic en el botón de Verificar Datos, pero solo debe ejecutarse cuando la validación de todos los campos sea correcta.
   * @type{Function({ color:number, correo:string, nombre: string, paquete: shape, telefono:string }):void} - callback
   */
  guardarDatosTemporalmente: PropTypes.func.isRequired,

  /**
   * Booleano que indica si el archivo subido termino de ser validado.
   */
  isLoadingExcel: PropTypes.bool.isRequired,

  /**
   *  Función que debe ejecutarse en un evento onChange del input en el que se carga el excel.
   * @type{Function({ e, archivo: file, nombre:string }):void} - callback
   */
  obtenerDatosExcel: PropTypes.func.isRequired,

  /**
   * Array de objetos que contiene la información de los paquetes que tiene autorizado el instituto
   */
  paquetes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
   * objetos que contiene la información del paquete
   */
  paqueteSeleccionado: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo
   */
   textosInterfaz: PropTypes.object.isRequired,
}
