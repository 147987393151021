
/**
 * Es un objeto en donde están cada una de las secciónes de estadísticas y dentro de cada sección se especifica todas las columnas que los reportes descargables en formato excel deben llevar, este objeto lo lee cada componente y se encargaran de realizar la lógica necesaria para descargar los datos con base a las columnas especificadas para su correspondiente sección, el objeto se caracteriza por tener el objeto con el nombre de la sección y dentro del mismo tener un arreglo de objetos, cada objeto representa una columna
 */
export const ESTADISTICAS_COLUMNAS_TABLAS = {
    totalSesiones: [
        {
            propiedad: 'fecha',
            traduccion: 'fecha',
            textoPorDefecto: 'Fecha'
        },
        {
            propiedad: 'numeroDeSesiones',
            traduccion: 'sesiones',
            textoPorDefecto: 'Sesiones'
        },
        {
            propiedad: 'tiempoPromedioDeLectura',
            traduccion: 'promedio_lectura',
            textoPorDefecto: 'Tiempo promedio de lectura (min)'
        },
        {
            propiedad: 'tiempoPromedioEnPlataforma',
            traduccion: 'promedio_plataforma',
            textoPorDefecto: 'Tiempo promedio en plataforma (min)'
        },
        {
            propiedad: 'librosLeidos',
            traduccion: 'libros_leidos',
            textoPorDefecto: 'Libros leidos'
        },
        {
            propiedad: 'numeroDeSesionesProfesores',
            traduccion: 'sesiones_profesores',
            textoPorDefecto: 'Sesiones (Profesores)'
        },
        {
            propiedad: 'tiempoPromedioDeLecturaProfesores',
            traduccion: 'promedio_lectura_profesores',
            textoPorDefecto: 'Tiempo promedio de lectura (Profesores)(min)'
        },
        {
            propiedad: 'tiempoPromedioEnPlataformaProfesores',
            traduccion: 'promedio_plataforma_profesores',
            textoPorDefecto: 'Tiempo promedio en plataforma (Profesores)(min)'
        },
        {
            propiedad: 'librosLeidosProfesores',
            traduccion: 'libros_leidos_profesores',
            textoPorDefecto: 'Libros leidos (Profesores)'
        },
    ],
    sesionesDia: [
        {
            propiedad: 'fecha',
            traduccion: 'hora',
            textoPorDefecto: 'Hora'
        },
        {
            propiedad: 'nombreUsuario',
            traduccion: 'nombres_apellidos',
            textoPorDefecto: 'Nombres y apellidos'
        },
        {
            propiedad: 'rol',
            traduccion: 'rol',
            textoPorDefecto: 'Rol'
        },
        {
            propiedad: 'sede',
            traduccion: 'colegio',
            textoPorDefecto: 'Sede'
        },
        {
            propiedad: 'curso',
            traduccion: 'curso',
            textoPorDefecto: 'Curso'
        },
    ],
    ingresosIndividuales: [
        {
            propiedad: 'codigo',
            traduccion: 'codigo_acceso',
            textoPorDefecto: 'Código de acceso'
        },
        {
            propiedad: 'codigoAsignadoInstitucion',
            traduccion: 'codigo',
            textoPorDefecto: 'Código'
        },
        {
            propiedad: 'fechaLectura',
            traduccion: 'fecha',
            textoPorDefecto: 'Fecha'
        },
        {
            propiedad: 'nombreCompleto',
            traduccion: 'nombres_apellidos',
            textoPorDefecto: 'Nombres y apellidos'
        },
        {
            propiedad: 'profesor',
            traduccion: 'profesor',
            textoPorDefecto: 'Profesor'
        },
        {
            propiedad: 'sede',
            traduccion: 'colegio',
            textoPorDefecto: 'Colegio'
        },
        {
            propiedad: 'curso',
            traduccion: 'curso',
            textoPorDefecto: 'Curso'
        },
        {
            propiedad: 'tiempoPromedioDeLectura',
            traduccion: 'tiempoPromedioDeLectura',
            textoPorDefecto: 'Tiempo promedio de lectura'
        },
    ],
    ingresosIndividualesProfesores: [
        {
            propiedad: 'username',
            traduccion: 'correo',
            textoPorDefecto: 'Correo'
        },
        {
            propiedad: 'nombreCompleto',
            traduccion: 'nombres_apellidos',
            textoPorDefecto: 'Nombres y apellidos'
        },
        {
            propiedad: 'fechaLectura',
            traduccion: 'fecha',
            textoPorDefecto: 'Fecha'
        },
        {
            propiedad: 'instituto',
            traduccion: 'colegio',
            textoPorDefecto: 'Colegio'
        },
        {
            propiedad: 'tiempoPromedioDeSesion',
            traduccion: 'tiempo_promedio',
            textoPorDefecto: 'Tiempo promedio de sesión'
        },
        {
            propiedad: 'tiempoPromedioDeLectura',
            traduccion: 'tiempo_promedio_lectura',
            textoPorDefecto: 'Tiempo promedio de lectura'
        },
    ],
    ingresoIndividual:{
        librosLeidos: [
            {
                propiedad: 'idLibro',
                traduccion: 'ID',
                textoPorDefecto: 'ID'
            },
            {
                propiedad: 'fechaLectura',
                traduccion: 'fecha',
                textoPorDefecto: 'Fecha'
            },
            {
                propiedad: 'titulo',
                traduccion: 'titulo_libro',
                textoPorDefecto: 'Título'
            },
            {
                propiedad: 'autor',
                traduccion: 'autor',
                textoPorDefecto: 'Autor'
            },
            {
                propiedad: 'editorial',
                traduccion: 'editorial',
                textoPorDefecto: 'Editorial'
            },
            {
                propiedad: 'tiempoPromedioDeLectura',
                traduccion: 'tiempo_promedio',
                textoPorDefecto: 'Tiempo promedio de lectura'
            },
            {
                propiedad: 'tiempoTotalLectura',
                traduccion: 'tiempo_total',
                textoPorDefecto: 'Timepo total de lectura'
            },
        ],
        sesiones: [
            {
                propiedad: 'fecha',
                traduccion: 'fecha',
                textoPorDefecto: 'Fecha'
            },
            {
                propiedad: 'numeroSesiones',
                traduccion: 'sesiones',
                textoPorDefecto: 'Sesiones'
            },
            {
                propiedad: 'librosLeidos',
                traduccion: 'libros_leidos',
                textoPorDefecto: 'Libros leidos'
            },
            {
                propiedad: 'tiempoPromedioEnPlataforma',
                traduccion: 'tiempo_promedio',
                textoPorDefecto: 'Tiempo promedio en plataforma'
            },
        ]
    },
    librosLeidos:[
        {
            propiedad: 'idLibro',
            traduccion: 'ID',
            textoPorDefecto: 'ID'
        },
        {
            propiedad: 'tituloLibro',
            traduccion: 'titulo',
            textoPorDefecto: 'Título del libro'
        },
        {
            propiedad: 'autor',
            traduccion: 'autor',
            textoPorDefecto: 'Autor'
        },
        {
            propiedad: 'editorial',
            traduccion: 'editorial',
            textoPorDefecto: 'Editorial'
        },
        {
            propiedad: 'cantidadLecturas',
            traduccion: 'numero_veces',
            textoPorDefecto: 'N° veces leído'
        },
        {
            propiedad: 'tiempoPromedioDeLectura',
            traduccion: 'tiempo_promedio',
            textoPorDefecto: 'tiempo promedio de lectura'
        },
        {
            propiedad: 'totalTiempoLectura',
            traduccion: 'tiempo_acumulado',
            textoPorDefecto: 'Tiempo acumulado de lectura'
        },
    ],
    detalleLibro:[
        {
            propiedad: 'codigo',
            traduccion: 'codigo_acceso',
            textoPorDefecto: 'Código de acceso'
        },
        {
            propiedad: 'codigoAsignadoInstitucion',
            traduccion: 'codigo',
            textoPorDefecto: 'Código'
        },
        {
            propiedad: 'nombreCompleto',
            traduccion: 'nombres_apellidos',
            textoPorDefecto: 'Nombres y apellidos'
        },
        {
            propiedad: 'fechaLectura',
            traduccion: 'fecha',
            textoPorDefecto: 'Fecha de lectura'
        },
        {
            propiedad: 'tiempoTotalLectura',
            traduccion: 'tiempo_total_lectura',
            textoPorDefecto: 'Tiempo de lectura'
        },
        {
            propiedad: 'sede',
            traduccion: 'colegio',
            textoPorDefecto: 'Sede'
        },
        {
            propiedad: 'curso',
            traduccion: 'curso',
            textoPorDefecto: 'Curso'
        },
    ],
    detalleLibroProfesor:[
        {
            propiedad: 'nombreCompleto',
            traduccion: 'nombres_apellidos',
            textoPorDefecto: 'Nombres y apellidos'
        },
        {
            propiedad: 'fechaLectura',
            traduccion: 'fecha',
            textoPorDefecto: 'Fecha de lectura'
        },
        {
            propiedad: 'tiempoTotalLectura',
            traduccion: 'tiempo_total_lectura',
            textoPorDefecto: 'Tiempo de lectura'
        },
        {
            propiedad: 'tiempoPromedioDeLectura',
            traduccion: 'tiempo_promedio',
            textoPorDefecto: 'Tiempo promedio de lectura'
        },
        {
            propiedad: 'nombreInstituto',
            traduccion: 'colegio',
            textoPorDefecto: 'Sede'
        },
    ]
}