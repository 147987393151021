import SkeletonCursosCrearIndividual from "./SkeletonCursosCrearIndividual";
import SkeletonCursosCrearMasivo from './SkeletonCursosCrearMasivo';

const SkeletonCursosCrear = (props) => {
  const { modoActivoCrear } = props;
  return (
    <>
      {
        modoActivoCrear === 'individual'
          ? <SkeletonCursosCrearIndividual />
          : <SkeletonCursosCrearMasivo />
      }
    </>
  )
}
export default SkeletonCursosCrear;