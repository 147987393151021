
import DivisionCrud from "../globales/DivisionCrud";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const SketonMiCuenta = () => {

  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={188} height={40} />
      <div className="contenido  text-negro-claro">

        <h1 className="titulos-principales">
          <Skeleton className="mb-2" width={176} height={36} />
        </h1>
        <div className="crud-seccion">
          <div>
            <Skeleton className="mx-auto" width={128} height={128} style={{ borderRadius: '100%', display: 'block' }} />
            <Skeleton className="mx-auto mt-3" width={130} height={24} style={{ display: 'block' }} />
          </div>
          <div className="lg:px-10 flex flex-col justify-center">
            <Skeleton className="mx-auto mt-3 lg:mx-0" width={200} height={29} />
            <Skeleton className="mx-auto mt-3 lg:mx-0" width={280} height={29} />
          </div>
        </div>

        <DivisionCrud>
          <>
            <Skeleton className="mt-3 mb-1" width={130} height={24} />
            <Skeleton className="" width={'100%'} height={15} count={2} />
          </>
          <div className="alineado-verticalmente h-full">

            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
            <Skeleton className="ml-5" width={100} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton className="mt-3 mb-1" width={130} height={24} />
            <Skeleton className="" width={'100%'} height={15} count={2} />
          </>
          <div className="alineado-verticalmente h-full">

            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
            <Skeleton className="ml-5" width={100} height={36} />
          </div>
        </DivisionCrud>

        <div className="crud-seccion">
          <div className="lg:w-1/4">
            <Skeleton className="mt-3 mb-1" width={130} height={24} />
            <Skeleton className="" width={'100%'} height={15} count={2} />
          </div>
          <div className="lg:w-3/4 lg:px-5 pt-5 lg:pt-0 flex flex-col xl:flex-row xl:items-center">
            <div className="w-full xl:w-451p">
              <Skeleton width={'100%'} height={36} />
            </div>
            <Skeleton className="xl:ml-5 mt-3 xl:mt-0" width={150} height={36} />
          </div>
        </div>
        <div className="alineado-izquierda my-5">
          <Skeleton className="xl:ml-5 mt-3 xl:mt-0" width={240} height={36} />
        </div>
      </div>

    </SkeletonTheme>
  )
}

export default SketonMiCuenta;