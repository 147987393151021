import { useState, useEffect } from "react";

import OpcionesDeClasificacion from "./OpcionesDeClasificacion";
import PropTypes from 'prop-types';
/**
Este componente renderiza un listado de categorias de libros y muestra el análisis estadístico de los libros pertenecientes a cada categoria.
 */
const AnalisisDeLaColeccion = (props) => {
    const { activador, analisisColeccion, textosInterfaz } = props;
    const { filtros, libros } = textosInterfaz;
    const [botonActivo, setBotonActivo] = useState('Nivel de lectura');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200 ? true:false);

    useEffect(()=>{
        const setMobile = () => {
          let validacion;
          if(window.innerWidth < 1200){
            validacion = true;
          }else{
            validacion = false;
          }
          setIsMobile(validacion)
        }
        setMobile();
        window.addEventListener('resize', setMobile)
        return () => {
          window.removeEventListener('resize', setMobile)
        }
      }, [])

    return (
        <>
            <div className={`background-var3--bg w-full  secondary-inv-var1--border-color rounded-b-lg transicion-150 ${!activador ? "overflow-hidden h-0 border-0" : ` block p-5 border-3`}`}>
                <div className='xl:alineado-verticalmente justify-between' >
                    <p className=" xl:w-4/12 q7-gris6A text-18 una-linea-texto ">{libros.distribucion_coleccion}</p>
                    <div className=" mt-5 xl:mt-0 grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-2 xl:w-auto xl:flex ">
                        {
                            analisisColeccion.map((item, index) => {
                                const { name, translate } = item;
                                return (
                                    name !== 'Todas las materias' && name !== 'Dificultad Lectora' &&
                                    <button
                                        key={`boton-analisis-${index}`}
                                        className={`focusable-primary boton-justo center ${botonActivo === name ? `boton-linea-morado` : 'boton-gris boton_hover_gris'}`}
                                        onClick={() => setBotonActivo(name)}
                                    >
                                        {filtros.filtro[translate]}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2   mt-5">
                    {
                        analisisColeccion.map((item) => {
                            if (botonActivo === item.name) {
                                return (
                                    <OpcionesDeClasificacion
                                        key={item.name}
                                        analisis={item.analisis}
                                        clasificacion={item.name}
                                        textosClasificacion={filtros[item.translate]}
                                        textoLibro={libros.libro}
                                    />
                                )
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default AnalisisDeLaColeccion;

AnalisisDeLaColeccion.propTypes = {
    /**
     * activador es un boolean que indica si el componente debe mostrarse o no
     */
    activador: PropTypes.bool,
    /**
     * arreglo de objetos que definen la categoria y el analisis de la libros correspondientes.
     */
    analisisColeccion: PropTypes.array,
    /**
     * Objeto con los textos traducidos correspondientes a la interfaz grafica del componente
     */
    textosInterfaz: PropTypes.object
}