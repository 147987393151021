import { useState } from "react";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { useDispatch, useTrackedState } from "../../../store";
import MicroModal from "micromodal";
import ModalCambiarIcono from "../../../components/globales/modales/ModalCambiarIcono";
import generalCallApi from "../../../containers/helpers/generalCallApi";

export const Header = () => {
	const { textosSubSecciones: textos } = useObtenerSubsecciones("perfil_estudiante");
	const home = textos?.home || {};
	const botones = textos?.botones || {};

	const dispatch = useDispatch();
	const appState = useTrackedState();
	const student = appState?.perfilEstudiante;
	const stats = {
		totalHorasLeidas: student?.totalHorasLeidas || 0,
		totalLibrosLeidos: student?.totalLibrosLeidos || 0,
		totalLogros: student?.totalLogros || 0,
	};
	const institutionData = student?.instituto;
	const [showAvatarModal, setShowAvatarModal] = useState(false);
	const numIcons = JSON.parse(process.env.REACT_APP_RANGO_NUMERO_AVATARES);

	const handleShowAvatarModal = () => {
		if (showAvatarModal) {
			MicroModal.show("cambiar-avatar", {
				awaitCloseAnimation: true,
			});
		} else {
			setShowAvatarModal(true);
		}
	};

	const changeAvatar = async (avatar) => {
		try {
			const pathname = "/datosUsuario/cambiarAvatar";
			const properties = { nuevoAvatar: avatar };
			const result = await generalCallApi({ pathname, properties });
			const error = result.status === 0;
			if (!error) {
				dispatch({
					type: "CHANGE",
					path: "perfilEstudiante.avatar",
					value: `/images/avatares/avatar${avatar}.png`,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<section className="primary-var2--bg border-perfil pt-1 pb-5 rounded-br-3xl">
			<div className="py-5">
				<div className="grid xl:grid-cols-12">
					<div className="xl:col-span-7 md:alineado-verticalmente gap-4 xl:pl-10">
						<div className="md:w-3/12">
							<div
								aria-label={"imagen del avatar"}
								className="avatar mx-auto cursor-pointer img-cover-center text-indent"
								style={{ backgroundImage: `url(${student?.avatar})` }}
								onClick={() => handleShowAvatarModal()}
							>
								<span aria-hidden={true}>{student?.avatar}</span>
							</div>
							<button
								className="focusable-red evento_blanco mx-auto block"
								onClick={() => handleShowAvatarModal()}
							>
								{botones?.avatar}
							</button>
						</div>

						<div className="md:w-9/12">
							<div className="my-5 md:my-0">
								<p className="q7-28 onprimary-var1--color text-center md:text-left">
									¡{home?.perfil?.titulo} {student?.nombre}!
								</p>

								<p className="r7-16-blanco text-center md:text-left my-2">
									<span className="onprimary-var1--color uppercase tracking-widest">
										{home?.perfil?.perfil}
									</span>{" "}
									<span className="r5-16-blanco ml-2">
										{institutionData?.nombre}
									</span>
								</p>

								<p className="r7-16-blanco onprimary-var1--color text-center md:text-left my-2">
									{home?.perfil?.codigo_acceso || "Código de acceso"}{" "}
									<span className="r5-16-blanco ml-2">
										{student?.codigo || "N/A"}
									</span>
								</p>
								<p className="r7-16-blanco onprimary-var1--color text-center md:text-left my-2">
									{home?.perfil?.codigo || "Código"}{" "}
									<span className="r5-16-blanco  ml-2">
										{student?.codigoColegio || "N/A"}
									</span>
								</p>
								<p className="r7-16-blanco onprimary-var1--color text-center md:text-left my-2">
									{home?.perfil?.curso || "Curso"}{" "}
									<span className="r5-16-blanco ml-2">
										{student?.nombreCurso || "N/A"}
									</span>
								</p>
							</div>
						</div>
					</div>
					<div className="xl:col-span-5 alineado-izquierda-center pt-3 xl:pt-0 ">
						<div className="w-full xl:w-10/12 onprimary-var1--bg border-2 onsurface-var2--bordered xl:rounded-l-md py-3 px-2 sm:px-0 grid grid-cols-3 gap-4">
							<div className=" center">
								<div>
									<div className="alineado-verticalmente ">
										<img
											aria-hidden={true}
											alt="medalla"
											className="w-8 mr-2"
											src="/images/perfil-estudiante/medallas.svg"
										/>
										<p className="q7-34">{stats?.totalLogros || "0"}</p>
									</div>
									<p className="q7-16-gris97 text-center">
										{stats.totalLogros !== 1
											? home?.perfil?.medallas?.plural
											: home?.perfil?.medallas?.singular}
									</p>
								</div>
							</div>

							<div className=" center">
								<div>
									<div className="alineado-verticalmente">
										<img
											aria-hidden={true}
											alt="reloj de arena"
											className="w-7 mr-2"
											src="/images/perfil-estudiante/horas.svg"
										/>
										<p className="q7-34">{stats?.totalHorasLeidas || "0"} </p>
									</div>
									<p className="q7-16-gris97  text-center">
										{stats?.totalHorasLeidas !== 1
											? home?.perfil?.horas?.plural
											: home?.perfil?.horas?.singular}
									</p>
								</div>
							</div>

							<div className=" center">
								<div>
									<div className="alineado-verticalmente">
										<img
											aria-hidden={true}
											alt="libros"
											className="w-8 mr-2"
											src="/images/perfil-estudiante/libros.svg"
										/>
										<p className="q7-34">{stats?.totalLibrosLeidos || "0"} </p>
									</div>
									<p className="q7-16-gris97 text-center">
										{stats.totalLibrosLeidos !== 1
											? home?.perfil?.libros?.plural
											: home?.perfil?.libros?.singular}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showAvatarModal && (
				<ModalCambiarIcono
					boton={botones.avatar}
					cambiarAvatarEstudiante={changeAvatar}
					rutaEstudiante={student.avatar}
					descripcion={textos?.modal_avatar.descripcion}
					maxIconos={numIcons.maximo}
					minIconos={numIcons.minimo}
					nombreModal="cambiar-avatar"
					titulo={textos?.modal_avatar.titulo}
				/>
			)}
		</section>
	);
};
