
/**
 *   Permite concatenar en un objeto los datos pertenecientes a una propiedad dentro de otra.
 * @param {{datos:object, section:string, propiedadJson:string}} objeto con los parametros necesarios para realizar la concatenación 
 * @returns {Promise<object>} Retorna el objeto con los datos concatenados con base a los parametros 
 */
const concatenarDatos = async ({ datos, section, propiedadJson }) => {
  let resultadosModificados = {}
  
  for (const key in datos) {
    let arreglo = [];
    const datosSeccion = datos[key][section];
    datosSeccion.forEach(elemento => {
      const elementoAConsultar = elemento[propiedadJson];
      const numeroElementos = elementoAConsultar.length;
      let nombreConcatenado = [];

      numeroElementos !== 0 && elementoAConsultar.map(dato => nombreConcatenado.push(dato.nombre))
      const nombrePropiedad = `nombre${propiedadJson.charAt(0).toUpperCase()}${propiedadJson.slice(1)}`
      arreglo.push({
        ...elemento,
        [nombrePropiedad]: nombreConcatenado.join(', ')
      })
    })

    resultadosModificados[key] = {
      ...datos[key],
      [section]: arreglo
    }
  }

  return resultadosModificados;
}

export default concatenarDatos