import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';
//Componentes
import DivisionCrud from '../globales/DivisionCrud';
import PropTypes from 'prop-types';

/**
 * Este componente recibe una serie de props que son necesarios para renderizar la pantalla de cambiar contraseña. Además, hace uso del componente DivisionCrud.
 * @returns CambiarContrasenaComponent
 */
const CambiarContrasenaComponent = (props) => {
  const { cambiarContrasena, textosInterfaz, respuestaCambioContrasena, preloader } = props;
  const { isError, mensaje } = respuestaCambioContrasena;

  const [contrasenaAnterior, setContrasenaAnterior] = useState({ campo: '', valido: null, verContrasena: false });
  const [nuevaContrasena, setNuevaContrasena] = useState({ campo: '', valido: null, verContrasena: false });
  const [confirmarContrasena, setConfirmarContrasena] = useState({ campo: '', valido: null, verContrasena: false });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [activarDesactivarBoton, setActivarDesactivarBoton] = useState(true);

  useEffect(() => {
    if (mensaje !== '') {
      notificacion({
        titulo: isError ? textosInterfaz.notificacion.error : textosInterfaz.notificacion.exito,
        mensaje,
        tipo: isError ? "danger" : "success"
      });

      if (!isError) {
        setActivarDesactivarBoton(true)
        setContrasenaAnterior({ ...contrasenaAnterior, campo: "" });
        setNuevaContrasena({ ...nuevaContrasena, campo: "" });
        setConfirmarContrasena({ ...confirmarContrasena, campo: "" });
        setMostrarAlerta(false);
      } else {
        setContrasenaAnterior({ ...contrasenaAnterior, valido: false });
        setMostrarAlerta(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const notificacion = ({ titulo, mensaje, tipo }) => {
    store.addNotification({
      title: titulo,
      message: mensaje,
      type: tipo,
      insert: "bottom",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
      },
    })
  }

  const validarCampo = (estado, cambiarEstado) => {
    const valorCampo = estado.campo.length >= 8 ? true : false;
    cambiarEstado({ ...estado, valido: valorCampo })
  }

  const actualizarCampo = (e, estado, cambiarEstado) => {
    cambiarEstado({ ...estado, campo: e.target.value });
    setActivarDesactivarBoton(false)
  }

  const enviarContrasena = () => {
    setMostrarAlerta(true);
    if (contrasenaAnterior.valido && nuevaContrasena.valido && confirmarContrasena.valido) {
      if (nuevaContrasena.campo === confirmarContrasena.campo) {
        cambiarContrasena(contrasenaAnterior.campo, nuevaContrasena.campo);
      } else {
        setNuevaContrasena({ ...nuevaContrasena, valido: false })
        setConfirmarContrasena({ ...confirmarContrasena, valido: false })
      }
    }
  }

  const mostrarContraseña = (estado, cambiarEstado) => {
    cambiarEstado({ ...estado, verContrasena: !estado.verContrasena })
  }

  const preventPaste = (e) => e.preventDefault();

  return (
    <>
      <div className="ruta">
        <p className="font-medium">
          <Link to="/mi-cuenta" className="focusable-primary mr-2">{textosInterfaz.botones.mi_cuenta}</Link>  &gt;
          <span className="ml-2 font-bold">{textosInterfaz.botones.cambiar_contrasena}</span>
        </p>
      </div>

      <div className="contenido">
        <h1 className="titulos-principales">{textosInterfaz.contrasena.titulo}</h1>

        {/* ANTERIOR */}
        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{textosInterfaz.contrasena.actual.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{textosInterfaz.contrasena.actual.descripcion}</p>
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p">
              <div className="relative">
                <input
                  className={`focusable-primary w-full input-password_focus ${mostrarAlerta ? !contrasenaAnterior.valido && 'input_error' : null}`}
                  onBlur={() => { validarCampo(contrasenaAnterior, setContrasenaAnterior) }}
                  onChange={(e) => { actualizarCampo(e, contrasenaAnterior, setContrasenaAnterior) }}
                  onCopy={preventPaste}
                  onKeyUp={() => { validarCampo(contrasenaAnterior, setContrasenaAnterior) }}
                  onPaste={preventPaste}
                  placeholder={textosInterfaz.contrasena.actual.placeholder}
                  type={contrasenaAnterior.verContrasena ? "text" : "password"}
                  value={contrasenaAnterior.campo}
                />
                <div className="absolute inset-y-0 w-10 right-0 cursor-pointer center">
                  <span
                    className={`${contrasenaAnterior.verContrasena ? 'icon-no-ver primary-inv-var2--color' : 'icon-ver onsurface-var2--color'} text-24 `}
                    onClick={() => { mostrarContraseña(contrasenaAnterior, setContrasenaAnterior) }}
                  ></span>
                </div>
              </div>


              {
                mostrarAlerta &&

                <p className={`text-14 
                  ${!contrasenaAnterior.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {textosInterfaz.contrasena.actual.error}
                </p>
              }

            </div>
          </div>
        </DivisionCrud>

        {/* NUEVA */}
        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{textosInterfaz.contrasena.nueva.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{textosInterfaz.contrasena.nueva.descripcion}</p>
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p">
              <div className="relative">
                <input
                  className={`focusable-primary w-full input-password_focus ${mostrarAlerta ? !nuevaContrasena.valido && 'input_error' : null}`}
                  onBlur={() => { validarCampo(nuevaContrasena, setNuevaContrasena) }}
                  onChange={(e) => { actualizarCampo(e, nuevaContrasena, setNuevaContrasena) }}
                  onCopy={preventPaste}
                  onKeyUp={() => { validarCampo(nuevaContrasena, setNuevaContrasena) }}
                  onPaste={preventPaste}
                  placeholder={textosInterfaz.contrasena.nueva.placeholder}
                  type={nuevaContrasena.verContrasena ? "text" : "password"}
                  value={nuevaContrasena.campo}
                />
                <div className="absolute inset-y-0 w-10 right-0 cursor-pointer center">
                  <span
                    className={`${nuevaContrasena.verContrasena ? 'icon-no-ver' : 'icon-ver'} text-24 onsurface-var2--color `}
                    onClick={() => { mostrarContraseña(nuevaContrasena, setNuevaContrasena) }}
                  ></span>
                </div>
              </div>

              {mostrarAlerta &&
                <p className={`text-14 
                  ${!nuevaContrasena.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {textosInterfaz.contrasena.nueva.error}
                </p>
              }

            </div>
          </div>
        </DivisionCrud>

        {/* CONFIRMACION */}
        <DivisionCrud>
          <>
            <h3 className="onbackground-var1--color raleway-700">{textosInterfaz.contrasena.confirmar.titulo} <span className="asterisco">*</span></h3>
            <p className="r4-14-gris6a">{textosInterfaz.contrasena.confirmar.descripcion}</p>
          </>
          <div className="alineado-verticalmente h-full">
            <div className="w-full xl:w-451p relative">
              <div className="relative">
                <input
                  className={`focusable-primary w-full input-password_focus ${mostrarAlerta ? !confirmarContrasena.valido && 'input_error' : null}`}
                  onBlur={() => { validarCampo(confirmarContrasena, setConfirmarContrasena) }}
                  onChange={(e) => { actualizarCampo(e, confirmarContrasena, setConfirmarContrasena) }}
                  onCopy={preventPaste}
                  onKeyUp={() => { validarCampo(confirmarContrasena, setConfirmarContrasena) }}
                  onPaste={preventPaste}
                  placeholder={textosInterfaz?.contrasena.confirmar.placeholder}
                  value={confirmarContrasena.campo}
                  type={confirmarContrasena.verContrasena ? "text" : "password"}
                />
                <div className="absolute inset-y-0 w-10 right-0 cursor-pointer center">
                  <span
                    className={`${confirmarContrasena.verContrasena ? 'icon-no-ver' : 'icon-ver'} text-24 onsurface-var2--color `}
                    onClick={() => { mostrarContraseña(confirmarContrasena, setConfirmarContrasena) }}
                  ></span>
                </div>
              </div>

              {mostrarAlerta &&
                <p className={`text-14 
                  ${!confirmarContrasena.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
                }>
                  {textosInterfaz.contrasena.confirmar.error}
                </p>
              }
            </div>
          </div>
        </DivisionCrud>

        <div className="alineado-izquierda my-5">
          <Link to="/mi-cuenta" className="focusable-primary boton-justo boton-amarillo boton_hover_naranja alineado-verticalmente text-16 mr-5">
            <span className="icon-volver pr-3"></span>
            {textosInterfaz.botones.boton_volver}
          </Link>
          <button
            className="focusable-red boton-justo boton-amarillo boton_hover_naranja text-16 w-48"
            disabled={activarDesactivarBoton}
            onClick={() => { enviarContrasena() }}
          >
            {preloader.contrasena ?
              <div className="center">
                <span className="icon-spinner7 animate-spin text-20"></span>
              </div>
              :
              textosInterfaz.botones.cambiar_contrasena
            }
          </button>
        </div>
      </div>
    </>
  )
}

export default CambiarContrasenaComponent;

CambiarContrasenaComponent.propTypes = {
  /**
   * Función que se encarga de ejecutar el API de cambiar contraseña.
   * @type{Funcion(anterior:string, nueva:string):void} - callback
   */
  cambiarContrasena: PropTypes.func.isRequired,
  /**
   *  Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,
  /**
   * Objeto que corresponde al mensaje de confirmación cuando se ejecuta el API de cambiar contraseña. 
   */
  respuestaCambioContrasena: PropTypes.shape({
    isError: PropTypes.bool,
    mensaje: PropTypes.string
  }).isRequired,
  /**
   * objeto que contiene los preloaders para los llamados de las API's. 
   */
  preloader: PropTypes.shape({
    contrasena: PropTypes.bool //preloader para el API de cambiarContrasena.
  }).isRequired
}