import { useHistory, useParams } from "react-router-dom";
//componentes
import { MigasDePan, Miga } from "../../../globales/MigaDePan";
import Filtro from "../../../globales/estadisticas/Filtro";
import TituloSeccion from "../../../globales/estadisticas/TituloSeccion";
import TablaDetalleLibro from "./TablaDetalleLibro";
import { useDispatch, useTrackedState } from "../../../../store";
import { useQueryParams } from "../../../../containers/helpers/hooks/locationHooks";
import { useEffect, useState } from "react";
import { FiltroFechas } from "../../../../utilities/gestion_fechas/FiltrosFechas";
import { ESTADISTICAS_COLUMNAS_TABLAS } from "../../../../containers/estadisticas/tablas-reportes/ColumnasTablas";
import { DetalleLibroAPI } from "../../../../api/estadisticas/DetalleLibroAPI";
import moment from "moment";
import { CargandoInformacion } from "../../../globales/estadisticas/CargandoInformacion";
import { DescargarReporte } from "../../Reportes/DescargarReporte";
import { LibrosAPI } from "../../../../api/LibrosAPI";
import { useFechaPorDefecto } from "../../hooks/Fechas";
import { normalizeString } from "../../../../utilities/StringUtilities";
import PropTypes from "prop-types";
import { CancelRequestController } from "../../../../utilities/cancel-request-controller";

/**
 * Este componente tiene la función de cargar todas las estadísticas de los estudiantes que leyeron un libro en particular con base a un rango de fechas, sede y/o curso.Por otro lado, también se encarga de transformar los datos estadísticos a formato excel para que puedan ser descargados por el usuario.
 */
const DetalleLibroEstudianteComponent = (props) => {
  const { traducciones } = props;
  const { miga_de_pan, detalle_libro } = traducciones;
  const { tabla, descripcion, detalle_de, resultados } = detalle_libro;


  const history = useHistory();
  const parametros = useParams();
  const state = useTrackedState();
  const queryParams = useQueryParams();
  const fechaPorDefecto = useFechaPorDefecto();

  const [filtros, setFiltros] = useState({
    sede: undefined,
    curso: undefined,
    fechas: {
      inicio: fechaPorDefecto.fechaInicio,
      fin: fechaPorDefecto.fechaFin
    }
  })
  const [rangoFechas, setRangoFechas] = useState({
    inicio: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaInicio),
    fin: FiltroFechas.formatoUsuario(state?.idioma, fechaPorDefecto?.fechaFin),
  })
  const [informacionLibro, setInformacionLibro] = useState({ nombre: '' })
  const [estadisticas, setEstadisticas] = useState({
    lecturas: [],
    lecturasFiltradas: []
  });
  const [cargando, setCargando] = useState({
    estadisticas: false
  })



  const actualizarFiltros = (filtrosActualizados) => {
    setFiltros({
      ...filtros,
      curso: filtrosActualizados?.curso,
      sede: filtrosActualizados?.sede,
      fechas: {
        inicio: filtrosActualizados?.fechas[0],
        fin: filtrosActualizados?.fechas[1]
      },
      fechasUsuario: {
        inicio: FiltroFechas.formatoUsuario(state?.idioma, filtrosActualizados?.fechas[0]),
        fin: FiltroFechas.formatoUsuario(state?.idioma, filtrosActualizados?.fechas[1])
      }
    })
  }


  const filtrarPorInput = ({ value }) => {
    if (value && value.trim().length > 0) {
      const coincidencias = estadisticas?.lecturas.filter(lectura => normalizeString(lectura?.nombreCompleto)?.includes(normalizeString(value)));
      setEstadisticas({
        ...estadisticas,
        lecturasFiltradas: coincidencias
      })
    } else {
      setEstadisticas({
        ...estadisticas,
        lecturasFiltradas: estadisticas?.lecturas
      })
    }

  }

  /**
   * transforma la información suministrada por las APIs a una tabla que se puede descargar como excel.
   * @returns tabla para reporte
   */
  const generarInformacionTabla = () => {
    const columnas = ESTADISTICAS_COLUMNAS_TABLAS.detalleLibro.map(col => {
      const traduccion = traducciones?.detalle_libro?.tabla[`${col?.traduccion}`]
      return { nombreColumna: traduccion || col.textoPorDefecto }
    })

    const filas = [];
    estadisticas?.lecturas.forEach(ingreso => {
      let fila = [];
      ESTADISTICAS_COLUMNAS_TABLAS.detalleLibro.forEach(col => {
        let valor = ingreso[`${col?.propiedad}`];
        if (Array.isArray(ingreso[`${col?.propiedad}`])) {
          valor = ingreso[`${col?.propiedad}`].join(',')
        }
        else if (col?.propiedad === 'sede') {
          valor = valor?.nombre;
        }
        else if (col?.propiedad === 'curso') {
          valor = valor?.nombre;
        }
        fila.push({ valor: valor })
      })
      filas.push(fila);
    })

    const tabla = {
      columnas: columnas,
      filas: filas
    }
    return tabla;
  }


  useEffect(() => {
    setRangoFechas({
      inicio: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.inicio),
      fin: FiltroFechas.formatoUsuario(state?.idioma, filtros?.fechas?.fin),
    })
  }, [filtros, state?.idioma])

  useEffect(() => {
    const abortController = new CancelRequestController();
    setCargando({
      ...cargando,
      estadisticas: true
    })
    const actualizarEstadisticas = async () => {
      const idLibro = parametros?.idLibro;
      const sede = filtros?.sede;
      const curso = filtros?.curso;
      const rangoFechas = {
        inicio: filtros?.fechas.inicio || FiltroFechas.formatoAPI(state?.idioma, moment()),
        fin: filtros?.fechas.fin || FiltroFechas.formatoAPI(state?.idioma, moment())
      };

      if (sede?.id === 'todo' || curso?.id === 'todo') {
        await DetalleLibroAPI
          .obtenerPorSede({idLibro, idSede:sede?.id === 'todo' ? undefined : sede?.id, fechaInicio:rangoFechas?.inicio, fechaFin:rangoFechas?.fin, abortSignal: abortController.signal})
          .then(resultado => {
            setEstadisticas({
              ...estadisticas,
              lecturas: resultado?.lecturasLibro,
              lecturasFiltradas: resultado?.lecturasLibro
            })
            setCargando({
              ...cargando, estadisticas:false})
          })
          .catch(error => console.log(error))
      }
      else if (curso?.id !== 'todo') {
        await DetalleLibroAPI
          .obtenerPorCurso({idLibro, idCurso:curso?.id, fechaInicio:rangoFechas?.inicio, fechaFin:rangoFechas?.fin, abortSignal: abortController.signal})
          .then(resultado => {
            setEstadisticas({
              ...estadisticas,
              lecturas: resultado?.lecturasLibro,
              lecturasFiltradas: resultado?.lecturasLibro
            })
            setCargando({
              ...cargando, estadisticas:false})
          })
          .catch(error => console.log(error))
      }
    }
    if (filtros?.fechas?.inicio && filtros?.fechas?.fin) {
      actualizarEstadisticas();
    }

    return ()=>{
      abortController.abort();
      setCargando({
        ...cargando, estadisticas:true})
    }

  }, [parametros?.idLibro, JSON.stringify(filtros)])

  useEffect(() => {
    const cargarInformacionLibro = async () => {
      await LibrosAPI.multiplesFiltros({ idLibro: parametros?.idLibro })
        .then(resultado => {
          if (Boolean(resultado) && resultado.length > 0) {
            setInformacionLibro({
              ...informacionLibro,
              nombre: resultado[0]?.title || 'N/A'
            })
          } else {
            history.push('/estadisticas/libros-leidos');
          }
        })
    }
    cargarInformacionLibro();
  }, [parametros?.idLibro])


  return (
    <>
      <MigasDePan>
        <Miga
          texto={miga_de_pan?.estadisticas || 'Estadisticas'}
          alSeleccionar={() => {
            history.push('/estadisticas')
          }} />
        <Miga
          texto={detalle_libro?.miga_pan?.libros_leidos || 'Libros leidos(Estudiantes)'}
          alSeleccionar={() => {
            history.push('/estadisticas/estudiantes/libros-leidos')
          }} />
        <Miga
          texto={`${miga_de_pan?.detalle_de || 'Detalle de'}  ${informacionLibro?.nombre || ''}`}
          esRutaActual={true}
        />
      </MigasDePan>

      <div className="contenido">
        <h1 className="titulos-principales"> {informacionLibro?.nombre || ''}</h1>
        <p className="informacion-principal">{descripcion}</p>

        <Filtro
          alActualizarFiltros={actualizarFiltros}
          calendarioProps={{
            fechaMaxima: moment().format('YYYY-MM-DD'),
            fechaMinima: state?.datosInstitucion?.fechaInicioSuscripcion || '',
            modoRango: true,
            fechaPorDefecto: [
              state?.estadisticas?.filtros?.fechas?.inicio || queryParams.get('fechaInicio') || fechaPorDefecto?.inicio, 
              state?.estadisticas?.filtros?.fechas?.fin || queryParams.get('fechaFin') || fechaPorDefecto.fin
            ],
            zIndex: 9999,
            modoMultiple: false,
            placeholder: ''
          }}
          sedePorDefecto={
             state?.estadisticas?.filtros?.sede || 
             queryParams.get('sede')
            }
          cursoPorDefecto={   
            state?.estadisticas?.filtros?.curso || 
            queryParams.get('curso')
          }
        />


        {Boolean(cargando?.estadisticas) === true ?
          <CargandoInformacion />

          :
          <>
            <TituloSeccion
              titulo={detalle_de}
              fecha={`${rangoFechas.inicio || 'N/A'} - ${rangoFechas.fin || 'N/A'}`}
              input={{
                placeHolder: traducciones?.detalle_libro?.input_busqueda?.placeholder || 'Buscar por nombre de usuario',
              }}
              alEscribir={(value) => {
                filtrarPorInput(value)
              }}
              elementosPersonalizados={
                <div className="my-2 w-full flex  justify-center sm:justify-end">
                  <DescargarReporte
                    nombreArchivo={`${miga_de_pan?.detalle_de || 'Detalle de'} ${informacionLibro?.nombre || 'N/A'} (${rangoFechas?.inicio} - ${rangoFechas?.fin}) (${filtros?.sede?.nombre} - ${filtros?.curso?.nombre})`}
                    tablaInformacion={generarInformacionTabla()}
                  />
                </div>
              }
            />

            <div className="fondoTabla mt-5 xl:px-10 xl:py-5 rounded-lg">
              <TablaDetalleLibro
                datos={estadisticas?.lecturasFiltradas}
                textosTabla={{ tabla: tabla, resultados: resultados }}
              />
            </div>
          </>
        }

      </div>
    </>
  )
}
export default DetalleLibroEstudianteComponent;

DetalleLibroEstudianteComponent.propTypes = {
  /**
   * Objeto con los textos que se deben renderizar en la sección
   */
  traducciones: PropTypes.object.isRequired,
}