import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Funciones
import generalCallApi from './../helpers/generalCallApi';
//Componentes
import EditarInstitutoComponent from "../../components/institutos/EditarInstituto";
import SkeletonInstitutoEditar from '../../components/institutos/SkeletonInstitutoEditar';
import VentanaAlerta from '../../components/globales/VentanaAlerta';
import PropTypes from 'prop-types';

/**
 * Este Container recibe los datos iniciales y retorna el componente EditarInstitutoComponent, al cual se le pasará una serie de propiedades para renderizar la pantalla de editar instituto/colegio. Las funciones principales de este Container son editar la información o eliminar la sede seleccionada. 
 * @returns {JSX.Element} EditarInstitutoComponent
 */

const EditarInstituto = (props) => {
  const { isLoading } = props;

  //Estados globales
  const state = useTrackedState();
  const { datosInstitucion } = state;
  const dispatch = useDispatch();

  //Estados del componente
  const [datosSede, setDatosSede] = useState(null);
  const [existeSede, setExisteSede] = useState(null);
  const [preloader, setPreloader] = useState(false);
  const [respuestaApiEditarSede, setRespuestaApiEditarSede] = useState({
    isError: null,
    mensaje: ''
  });
  const [respuestaApiEliminarSede, setRespuestaApiEliminarSede] = useState({
    isError: null,
    mensaje: ''
  });

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('instituto', false, 'editar');

  //Parámetros de url
  const history = useHistory();
  const params = useParams();
  const { sede } = params;


  useEffect(() => {
    //Verificar si el parámetro de sede existe
    if (!!sede && !!datosInstitucion) {
      //Buscar la sede a editar
      const sedeActual = datosInstitucion.sedes.find(sedeInstituto => sedeInstituto.id === sede);
      //Verificar si la sede existe
      const existeSedeParam = !!sedeActual ? true : false;
      setExisteSede(existeSedeParam);

      //Si el api de eliminar ya se llamo y esta respondio de forma afirmativa, no se ejecuta lo que se encuentra en el siguiente condicional
      if (respuestaApiEliminarSede.isError !== false) {
        //Si exite la sede se guardan los datos de la sede en el estado del componente
        if (existeSedeParam) {
          //Se el estado datosInstitucion existe en el store se obtienen los datos de ese estado
          let paquete = []
          Boolean(Array.isArray(sedeActual?.paquetes)) && sedeActual?.paquetes.map(paqueteSede => paquete.push(paqueteSede.nombre))

          if (!!datosInstitucion) {
            const infoSede = {
              color: sedeActual.color,
              correo: sedeActual.correo,
              nombre: sedeActual.nombre,
              paquete: paquete.length !== 0 ? {nombre: paquete.join(', ')} : null,
              telefono: sedeActual.telefono,
            };
            setDatosSede(infoSede)
          }
        };
      };
      return;
    }

    //Si el parámetro no existe se redirecciona a la vista en general
    history.push('/institutos');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sede, datosInstitucion])


  //Función editar sede
  const editarSede = async ({ color, correo, nombre, telefono }) => {
    if (!preloader) {
      setRespuestaApiEditarSede({
        isError: null,
        mensaje: ''
      })

      setPreloader(true);

      //Parámetros para el llamado del api
      const pathname = '/sedes/editarSede';
      const properties = {
        color: color,
        correo: correo,
        id: sede,
        nombre: nombre,
        telefono: telefono
      }

      //Llamar api de editar instituto
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;


      if (!error) {
        let sedesDatosInstitucion = [...datosInstitucion.sedes];
        //Actualizar datos de la sede
        sedesDatosInstitucion.forEach((sedeInstituto, index) => {
          if (sedeInstituto.id === sede) {
            sedesDatosInstitucion[index] = {
              ...sedesDatosInstitucion[index],
              ...properties
            };
          };
        });

        sedesDatosInstitucion = {
          ...datosInstitucion,
          sedes: sedesDatosInstitucion
        };

        //Actualizar datos en el store 
        dispatch({
          property: 'datosInstitucion',
          type: 'SET_DATA',
          value: sedesDatosInstitucion
        });

        //Actualizar datos de la sede en el estado del componente
        setDatosSede({ ...datosSede, ...properties });
      }

      //Actualizar datos de la respuesta del api de editar
      setRespuestaApiEditarSede({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    }
  }


  //Función eliminar sede
  const eliminarSede = async () => {
    if (!preloader && respuestaApiEliminarSede.isError !== false) {
      setRespuestaApiEliminarSede({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);
      //Parámetros para el llamado del api
      const pathname = '/sedes/eliminarSede';
      const properties = { id: sede };
      // const properties = { id: "SEE000" };

      //Llamar api de editar instituto
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        setDatosSede({ ...datosSede, ...properties });
        let sedesDatosInstitucion = [...datosInstitucion.sedes];
        sedesDatosInstitucion.forEach((sedeInstituto, index) => sedeInstituto.id === sede && sedesDatosInstitucion.splice(index, 1));

        const newDatosInstitucion = {
          ...datosInstitucion,
          sedes: sedesDatosInstitucion
        };

        //Actualizar datos en el store 
        dispatch({
          property: 'datosInstitucion',
          type: 'SET_DATA',
          value: newDatosInstitucion
        });
      };

      setRespuestaApiEliminarSede({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    };
  };


  const componenteRender = () => {
    if (!isLoading && isReady && !!datosSede) {
      return (
        <EditarInstitutoComponent
          datosSede={datosSede}
          editarSede={editarSede}
          eliminarSede={eliminarSede}
          preloaderApi={preloader}
          respuestaApiEditarSede={respuestaApiEditarSede}
          respuestaApiEliminarSede={respuestaApiEliminarSede}
          textosInterfaz={{
            botones: textosInterfaz.botones,
            editar: textosInterfaz.editar,
            eliminar_sede: textosInterfaz.eliminar_sede,
            miga_de_pan: textosInterfaz.miga_de_pan,
            sede_eliminada: textosInterfaz.sede_eliminada,
            notificacion: textosInterfaz.notificacion
          }}
        />
      );
    }
    if (!isLoading && isReady && !existeSede && respuestaApiEliminarSede.isError !== false) {
      return (
        <VentanaAlerta
          textosInterfaz={{
            titulo: textosInterfaz.no_existe_sede.titulo,
            descripcion: textosInterfaz.no_existe_sede.descripcion,
          }}
          link=''
          imagen={{
            alt: 'blop con un icono de alerta',
            img: 'alertas/blop-alerta.png',
          }}
        >
          <Link
            to='/institutos'
            className="boton-pequenio mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado">
            {textosInterfaz.botones.volver}
          </Link>
        </VentanaAlerta>
      )
    }
    return <SkeletonInstitutoEditar />;
  };

  return (
    <>
      {componenteRender()}
    </>
  );
};


export default EditarInstituto;

EditarInstituto.propTypes = {
  /**
   * Estado de tipo booleano que valida si el instituto/colegio seleccionado existe.
   */
   isLoading: PropTypes.bool,
}