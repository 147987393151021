import { useState, useRef, useEffect } from 'react';
import normalizeString from '../../../utilities/function/normalizeString';
import useElementoExterior from '../../../containers/helpers/hooks/useElementoExterior';
import PropTypes from 'prop-types';

/**
 * componente que recibe una serie de propiedades que sirven para renderizar el selector de filtro en la sección de libros.
 * @returns 
 */
const SelectorDeFiltros = (props) => {
  const { opciones, textoDefault, modificarFiltros, nombre, traduccionesTextos } = props

  const [alturaOpciones, setAlturaOpciones] = useState("0px");
  const [activadorSelect, setActivadorSelect] = useState(false);
  const contenedorOpciones = useRef(null);
  const selector = useRef(null);

  useEffect(() => {
    if (!!contenedorOpciones.current) {
      setAlturaOpciones(contenedorOpciones.current.scrollHeight);
    }

  }, [opciones])

  const cerrarOpcionesSelect = () => setActivadorSelect(false);
  useElementoExterior(cerrarOpcionesSelect, selector);

  const onKeyEnterOption = (e, property, newValue, option) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
      modificarFiltros({ property, newValue, option })
    }
  }

  return (
    <>
      <div className="selector" ref={selector}>

        <button
          className={`selector-elegido ${activadorSelect ? 'rounded-t-md' : 'rounded-md'} focusable-primary`}
          onClick={() => { setActivadorSelect(!activadorSelect) }}
        >
          <p className="una-linea-texto"> {textoDefault}</p>
          {<span className={`icon-select  ${activadorSelect ? "" : "transform rotate-180"} transicion-500 text-20`}></span>}
        </button>

        <ul
          className={`max-h-80 relative scroll`}
          ref={contenedorOpciones}
          style={{ height: activadorSelect ? `${alturaOpciones}px` : '0px' }}
          role='listbox'
          aria-hidden={activadorSelect ? false : true}
          aria-expanded={activadorSelect ? true : false}
        >
          {opciones.map((option) =>
            <li key={normalizeString(option.name)} className='my-3'>
              <label 
                className="block h-7 relative mr-3 cursor-pointer focusable-primary"
                role='option'
                aria-checked={option.selected} 
                tabIndex={activadorSelect ? '0' : '-1'}
                aria-label={Boolean(option?.translate) ? traduccionesTextos[option.translate] : option.name} 
                onKeyUp={(e) => { 
                  let property = nombre;
                  let newValue = '';
                  onKeyEnterOption(e, property, newValue, option) }}  
              >
                <input
                  type="checkbox"
                  onChange={(e) => {
                    let property = nombre;
                    let newValue = '';
                    modificarFiltros({ property, newValue, option })
                  }}
                  checked={option.selected}
                  tabIndex={-1}
                />
                <i className="check"></i>
                <p className="raleway-400 onsurface-var1--color absolute left-10 top-0.5">{Boolean(option?.translate) ? traduccionesTextos[option.translate] : option.name}</p>
              </label>
            </li>
          )}
        </ul>

      </div>
    </>
  )
}
export default SelectorDeFiltros;

SelectorDeFiltros.propTypes = {
  /**
   * arreglo de objetos que contiene la información de cada opción
   */
  opciones: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    selected: PropTypes.bool,
    translate: PropTypes.string
  })),
  /**
   * string del texto por default del campo
   */
  textoDefault: PropTypes.string,
  /**
   * Función que modifica los filtros
   */
  modificarFiltros: PropTypes.func,
  /**
   * String que equivale al nombre del campo
   */
  nombre: PropTypes.string,
  /**
   *  objeto que trae los textos de la interfaz según el idioma activo.
   */
  traduccionesTextos: PropTypes.object
}