import axios from "axios";

export class EmailAPI {

    //---------------------------------------------------------------------------------
    // EMPIEZAN LOS SERVICIOS

    /**
     * Envia un email que informa que un paquete ya esta disponible para ser autorizado
     * @param {string} emailDestino - a quien se enviara el email 
     * @param {string} usuario - rector que confirmo el paquete
     * @param {string} nombrePaquete - nombre del paquete que esta listo para autorizar
     * @returns {Promise<boolean>} 
     */
    static async autorizarPaquete(emailDestino, usuario, nombrePaquete) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = 'mails/autorizarPaquete'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: {
                    user: usuario,
                    paquete: nombrePaquete,
                    recipient: emailDestino
                }
            })
            if (response?.data?.status === 1) {
                return true;
            }
            else {
                return false;
            }

        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
         * Envia un email con la información diligenciada por el usuario en el formulario "tuve problemas con un libro"
         * @param {string} emailDestino - a quien se enviara el email 
         * @param {string} nombreLibro - nombre del libro 
         * @param {string} dispositivo - nombre del dispositivo con el que se tiene problemas
         * @param {number} sonidoDefectuoso - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
         * @param {number} imagenDefectuosa - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
         * @param {number} libroIncompleto - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
         * @param {number} tipografiaConfusa - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
         * @param {number} nuncaCargo - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
         * @param {number} noClasificado - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
         * @param {string} nombreLibro - texto descriptivo de otro tipo de problema
         * @returns {Promise<boolean>} 
         */
    static async enviarProblemaLibro(emailDestino, sonidoDefectuoso, imagenDefectuosa, libroIncompleto, tipografiaConfusa, nuncaCargo, noClasificado, nombreLibro, dispositivo, otros) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = '/soporte'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: {
                    "correo-entrada": emailDestino,
                    "form-soporte": "Tuve problemas con un libro",
                    "sonido-defectuoso": sonidoDefectuoso,
                    "imagen-defectuosa": imagenDefectuosa,
                    "libro-incompleto": libroIncompleto,
                    "tipografia-confusa": tipografiaConfusa,
                    "nunca-cargo": nuncaCargo,
                    "no-clasificado": noClasificado,
                    "flibro": nombreLibro,
                    "fdispositivo": dispositivo,
                    "exampleFormControlTextarea1": otros
                }
            })
            if (response?.data?.status === 1) {
                return true;
            }
            else {
                return false;
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }


    /**
     * Envia un email con la información diligenciada por el usuario en el formulario "Tuve problemas con la plataforma"
     * @param {string} emailDestino - a quien se enviara el email 
     * @param {string} dispositivo - texto del nombre del dispositivo con el que se tiene problemas
     * @param {string} problema - texto descriptivo acerca del problema
     * @returns {Promise<boolean>} 
     */
    static async enviarProblemaPlataforma(emailDestino, dispositivo, problema) {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = '/soporte'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: {
                    "correo-entrada": emailDestino,
                    "form-soporte": "Tuve problemas con la plataforma",
                    "fdispositivo-plataforma": dispositivo,
                    "fproblema-pataforma": problema
                }
            })
            if (response?.data?.status === 1) {
                return true;
            }
            else {
                return false;
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Envia un email con la información diligenciada por el usuario en el formulario "Tengo dudas sobre ventas"
     * @param {string} emailDestino - a quien se enviara el email 
     * @param {number} suscripcion - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
     * @param {number} renovar - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
     * @param {number} otrosPlanes - valor 1 en caso de SER el problema o valor 0 en caso de NO ser el problema
     * @param {string} descripcionProblema - texto descriptivo sobre el problema
     * @returns {Promise<boolean>} 
     */
    static async enviarDudasSobreVentas(emailDestino, suscripcion, renovar, otrosPlanes, descripcionProblema){
        try{
            axios.defaults.baseURL = process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = '/soporte'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: {
                    "correo-entrada": emailDestino,
                    "form-soporte": "Tengo dudas sobre ventas",
                    "suscripcion": suscripcion,
                    "renovar-suscripcion": renovar,
                    "otros-planes": otrosPlanes,
                    "fduda-cuentanos": descripcionProblema
                }
            })
            if (response?.data?.status === 1) {
                return true;
            }
            else {
                return false;
            }
        }catch (error){
            return Promise.reject(error);
        }
    }

    
    /**
     * Envia un email con la información diligenciada por el usuario en el formulario "Sugerencias y comentarios"
     * @param {string} emailDestino - a quien se enviara el email 
     * @param {string} sugerenciaComentarios - texto con las sugerencias o comentarios
     */
    static async enviarSugerenciasYComentarios(emailDestino, sugerenciaComentarios ){
        try{
            axios.defaults.baseURL= process.env.REACT_APP_API_URL;
            axios.defaults.withCredentials = true;
            const URL = '/soporte'
            const response = await axios({
                method: 'POST',
                url: URL,
                data: {
                    "correo-entrada": emailDestino,
                    "form-soporte": "Sugerencias y comentarios",
                    "fsugerencias-comentario": sugerenciaComentarios
                }
            })
            if (response?.data?.status === 1) {
                return true;
            }
            else {
                return false;
            }
        }catch (error){
            return Promise.reject(error);
        }
    }
}
