import { useEffect, useState, useRef, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";

/**componente */
import AsignarAClub from "./AsignarAClub";
import InformacionBasica from "./InformacionBasica";
import Detalles from "./Detalles";
import Modal from "../../globales/modales/Modal";
import MicroModal from "micromodal";
import { AccionesLibroAPI } from "../../../api/AccionesLibroAPI";
import { Recomendar } from "./Recomendados";
import { SeleccionLibrosInicialAPI } from "../../../api/SeleccionLibrosInicialAPI";
import ModalAdvertencia from "../ventanasModales/ModalAdvertencia";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import { CancelRequestController } from "../../../utilities/cancel-request-controller";
import { useParams } from "react-router-dom";
import SkeletonFichaLibro from "../Skeletons/SkeletonFichaLibro";
import { adaptarPortadaLibros } from "../../../utilities/adaptadores";
import { LibrosAPI } from "../../../api/LibrosAPI";
import { useTrackedState } from "../../../store";
import { useLocation } from "react-router-dom";

const URL_IMG_POR_DEFECTO = "/images/no-imagen.png";

/**
 * Componente que se encarga de renderizar la información de un libro,
 * dentro de este componente se encuentran los componentes para asignar libros a clubs y recomendar a estudiantes.
 */
export const BookInfo = () => {
	const state = useTrackedState();
	const location = useLocation();
	const { planActivo } = state;
	const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones("libros");
	const { miga_de_pan, ficha, botones, modal_compartir, modal_limite_libros } =
		textosInterfaz || {};
	const { info_basica, mas_detalles } = ficha || {};
	const [book, setBook] = useState(undefined);

	const params = useParams();
	const history = useHistory();
	const [modoInfo, setModoInfo] = useState("basica");
	const [libroAgregado, setLibroAgregado] = useState(false);
	const [modalCompartir, setModalCompartir] = useState(false);
	const [esFavorito, setEsFavorito] = useState(false);
	const [totalAgregados, setTotalAgregados] = useState(0);
	const [loading, setLoading] = useState(true);
	const isSelectingBooks = !!planActivo && planActivo.eligiendoLibros;
	const currentSelectingBooksPackage = useMemo(() => {
		const _package = location?.state?.currentSelectingBooksPackage;
		const books = Array.isArray(_package?.libros) ? _package?.libros : [];
		const ids = books?.map((libro) => libro?.idLibro);
		setLibroAgregado(ids.includes(book?.idLibro));
		return _package || { nombre: "" };
	}, [location?.state?.currentSelectingBooksPackage, book]);

	const urlDireccion = useRef(null);

	const idModales = {
		limiteMaximo: "advertencia-limite-maximo-libros-ficha",
		modalCompartir: "modal-compartir-ficha-libro",
	};

	const hasActivity = ()=>{
		if(Array.isArray(book?.actividades) && book?.actividades?.length > 0){
			return true;
		}
		else if(Array.isArray(book?.idActividad) && book?.actividades?.length > 0){
			return true;
		}
		else if(book?.idActividad){
			return true;
		}
		return false;
	}

	const agregarRemoverAFavoritos = async (libro, favorito) => {
		setEsFavorito(favorito);
		await AccionesLibroAPI.agregarRemoverAFavoritos([libro?.idLibro], favorito).catch((error) =>
			console.log(error)
		);
	};

	const mostrarLibro = (idLibro) => {
		history.push({
			pathname: `/visor/${idLibro}`,
			state: { idLibro: idLibro, pathAnterior: window.location.pathname },
		});
	};

	/**
	 * Actualiza el paquete y su estado en el API
	 */
	const actualizarLibrosAgregados = async (librosIds = [], confirmarSeleccion) => {
		return await SeleccionLibrosInicialAPI.escogerLibros(
			currentSelectingBooksPackage?.nombre || "",
			librosIds || [],
			confirmarSeleccion
		).catch((error) => {
			console.log(error);
		});
	};

	const EscogiendoLibros = {
		agregarLibro: async () => {
			if (
				currentSelectingBooksPackage?.libros?.length <
				currentSelectingBooksPackage?.maxNumeroDeLibrosParaEscoger
			) {
				const ids = currentSelectingBooksPackage?.libros?.map((libro) => libro?.idLibro);
				const actualizados = [...ids, book?.idLibro];
				await actualizarLibrosAgregados(actualizados, false)
					.then((resultado) => {
						setLibroAgregado(true);
						setTotalAgregados(totalAgregados + 1);
					})
					.catch((error) => console.log(error));
			} else {
				MicroModal.show(idModales.limiteMaximo, {
					awaitCloseAnimation: true,
				});
			}
		},
		removerLibro: async () => {
			const ids = currentSelectingBooksPackage?.libros?.map((libro) => libro?.idLibro);
			const actualizados = [...ids].filter((id) => id !== book?.idLibro);
			await actualizarLibrosAgregados(actualizados, false)
				.then((resultado) => {
					setLibroAgregado(false);
					setTotalAgregados(totalAgregados - 1);
				})
				.catch((error) => console.log(error));
		},
	};

	useEffect(() => {
		setLoading(true);
		const abortController = new CancelRequestController();
		const cargarInfoLibro = async () => {
			await LibrosAPI.buscarPorId({
				bookId: params?.idLibro,
				abortSignal: abortController.signal,
			})
				.then((resultado) => {
					if (resultado) {
						const adapted = adaptarPortadaLibros([resultado])[0];
						setBook(adapted);
					} else {
						history.push("/libros");
					}
					setLoading(false);
				})
				.catch((error) => console.log(error));
		};
		cargarInfoLibro();
		return () => {
			setLoading(true);
			abortController.abort();
		};
	}, [params?.idLibro]);

	// useEffect(() => {
	// 	setLibroAgregado(Boolean(book?.elegido));
	// }, [book]);

	useEffect(() => {
		const abortController = new CancelRequestController();
		console.log("package", currentSelectingBooksPackage);
		const actualizarTotal = async () => {
			if (isSelectingBooks && currentSelectingBooksPackage.nombre) {
				await SeleccionLibrosInicialAPI.getPackage({
					packageId: currentSelectingBooksPackage?.nombre,
					abortSignal: abortController.signal,
				})
					.then((resultado) => {
						setTotalAgregados(resultado?.libros?.length);
					})
					.catch((error) => console.log(error));
			}
		};
		actualizarTotal();
		return () => {
			abortController.abort();
		};
	}, [currentSelectingBooksPackage, isSelectingBooks]);

	useEffect(() => {
		if (modalCompartir) {
			MicroModal.show(idModales.modalCompartir, {
				awaitCloseAnimation: true,
			});
		}
	}, [modalCompartir]);

	useEffect(() => {
		setEsFavorito(book?.favorito);
	}, [book]);

	return (
		<>
			{isReady && loading === false && book ? (
				<>
					<div className="ruta">
						<p className="font-medium">
							<Link to="/libros" className="focusable-primary mr-2">
								{miga_de_pan.libros}
							</Link>
							;
							<span className="ml-2 font-bold ">
								{miga_de_pan.detalle_de} {book?.title}
							</span>
						</p>
					</div>

					<div className="contenido relative">
						<h1 className="titulos-principales mb-5">{ficha.titulo}</h1>
						<Link
							to="/libros"
							className="focusable-primary w-28 lg:center boton-justo boton-amarillo boton_hover_morado py-2 px-5"
						>
							<span className="icon-atras mr-3"></span>
							{botones.volver}
						</Link>
						{isSelectingBooks &&
							currentSelectingBooksPackage?.nombre &&
							Boolean(currentSelectingBooksPackage?.estadoSeleccion) === false && (
								<div
									className="w-full z-60 "
									style={{ position: "sticky", top: 62, left: 0 }}
								>
									<div className="rounded-md w-full shadow-1 flex flex-col sm:flex-row center sm:justify-between items-center p-2 my-3 primary-var5--bg primary-inv-var5--bordered">
										<p className="font-bold font-raleway onbackground-var1--color">
											{currentSelectingBooksPackage?.nombre || "N/A"}
											<span className="primary-inv-var2--color">
												{` (${totalAgregados}/${currentSelectingBooksPackage?.maxNumeroDeLibrosParaEscoger})`}
											</span>
										</p>
										<div className="flex center">
											<button
												disabled={libroAgregado}
												className="focusable-red boton-morado boton-justo hover:bg-morado-oscuro transicion-150 mr-2"
												onClick={() => EscogiendoLibros.agregarLibro()}
											>
												{libroAgregado
													? `${
															ficha?.escogiendoLibros?.agregado ||
															"Añadido"
													  }`
													: `${
															ficha?.escogiendoLibros?.agregar ||
															"Añadir"
													  }`}
											</button>
											<button
												disabled={libroAgregado ? false : true}
												className="focusable-red boton-morado boton-justo hover:bg-morado-oscuro transicion-150"
												onClick={() => EscogiendoLibros.removerLibro()}
											>
												{`${ficha?.escogiendoLibros?.quitar || "Quitar"}`}
											</button>
										</div>
									</div>
								</div>
							)}
						<div className="xl:flex gap-16 mt-5">
							<div className=" xl:w-3/12">
								<img
									src={book?.portada || URL_IMG_POR_DEFECTO}
									alt={`Portada del libro`}
									className={`w-8/12  sm:w-6/12 md:w-4/12  mx-auto xl:w-full mt-10 shadow-1 ${
										isSelectingBooks && "mb-6"
									} `}
								/>

								{!isSelectingBooks && (
									<>
										<button
											className="focusable-red w-full center py-7 lg:py-4 q7-16-negro26 border-b border-gris-linea"
											onClick={() => {
												agregarRemoverAFavoritos(book, !esFavorito);
											}}
										>
											<span
												className={`icon-corazon text-26 mr-2 ${
													esFavorito ? "red-inv-var2--color" : " favorito"
												}`}
											></span>
											{ficha.favorito}
										</button>

										<Recomendar.General idLibro={book?.idLibro || ""} />

										<AsignarAClub
											idLibro={book?.idLibro || ""}
											textosInterfaz={textosInterfaz}
										/>
									</>
								)}
							</div>

							<div className="xl:w-9/12">
								<div className="flex gap-2 md:gap-4">
									<button
										className={`focusable-red ${
											modoInfo === "basica"
												? "boton-morado"
												: "boton-blanco-ficha"
										} w-6/12  md:w-auto rounded-t-lg quicksand-700  md:px-8 py-1 text-20`}
										onClick={() => {
											setModoInfo("basica");
										}}
									>
										{info_basica.titulo}
									</button>
									<button
										className={`focusable-red ${
											modoInfo === "detalles"
												? "boton-morado"
												: "boton-blanco-ficha"
										} w-6/12 md:w-auto  rounded-t-lg quicksand-700 md:px-8 py-1 text-20`}
										onClick={() => {
											setModoInfo("detalles");
										}}
									>
										{mas_detalles.titulo}
									</button>
								</div>

								<div className="background-var3--bg border-2 onsurface-var2--border-color min-h-600  relative">
									{modoInfo === "basica" ? (
										<InformacionBasica
											title={book?.title}
											author={book?.author}
											publisher={book?.editorial}
											nivelUsuarioLector={book?.nivelUsuarioLector}
											subject={book?.subject}
											genero={book?.genero}
											description={book?.description}
											descriptionENG={book?.descriptionENG}
											contributor={book?.contributor}
											idLibro={book?.idLibro}
											actividad={hasActivity()}
											textosInterfaz={textosInterfaz}
										/>
									) : (
										<>
											<div className="background-var3--bg grid grid-cols-12 gap-4">
												<div className="col-span-12 xl:col-span-9 p-10">
													<Detalles
														book={book}
														textosInterfaz={textosInterfaz}
													/>
												</div>

												<div className="col-span-12 xl:col-span-3  gap-4 xl:block mx-10 xl:m-0 xl:my-10 xl:mr-10">
													<div className="md:w-6/12 xl:w-full orange-inv-var1--bg rounded-xl p-3 center mb-5">
														<div className="text-center r7-18-blanco leading-5">
															<p>{mas_detalles.edad.titulo}</p>
															<p>
																{mas_detalles.edad.desde}{" "}
																{book?.edad}{" "}
																{mas_detalles.edad.anios}
															</p>
														</div>
													</div>

													<div className="md:w-6/12 xl:w-full  boton-ficha center text-center lg:block leading-5 mb-5">
														<span className="icon-tiempo primary-inv-var2--color text-40 mr-3 lg:mr-0"></span>
														<div>
															<p>{mas_detalles.tiempo_lectura}</p>
															<p className="primary-inv-var2--color">
																{book?.tiempoLectura} min
															</p>
														</div>
													</div>

													<div className="md:w-6/12 xl:w-full boton-ficha text-center mb-5 center">
														<div className="text-center leading-5">
															<p>{mas_detalles.curso}</p>
															<p className="primary-inv-var2--color">
																{book?.curso}
															</p>
														</div>
													</div>
													{!!book?.dificultadLectora && (
														<div className="md:w-6/12 xl:w-full boton-ficha center text-center lg:block mb-5">
															<p className="q7-morado text-40 leading-7 mb-2 lg:text-80 lg:leading-10 lg:mb-5 mr-3 lg:mr-0">
																{book?.dificultadLectora}
															</p>
															<p>{mas_detalles.gr_level}</p>
														</div>
													)}
												</div>
											</div>
										</>
									)}

									<div className="md:alineado-izquierda-center m-10">
										{!isSelectingBooks && (
											<button
												className="focusable-red w-full md:w-auto boton-justo py-2 px-8 boton-morado boton_hover_amarillo text-18 mt-3 md:mt-0 md:ml-5"
												onClick={() => {
													if (modalCompartir) {
														MicroModal.show(idModales.modalCompartir, {
															awaitCloseAnimation: true,
														});
													} else {
														setModalCompartir(true);
													}
												}}
											>
												{botones.compartir}
												<span className="icon-compartir-small ml-3"></span>
											</button>
										)}

										<button
											className="focusable-red w-full md:w-auto boton-justo py-2 px-8 boton-amarillo boton_hover_morado text-18 mt-3 md:mt-0 md:ml-5"
											onClick={() => {
												mostrarLibro(book?.idLibro);
											}}
										>
											{botones.leer}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ModalAdvertencia
						nombreDelModal={idModales.limiteMaximo}
						titulo={modal_limite_libros?.titulo || "¡Limite de libros!"}
						descripcion={`
      ${modal_limite_libros?.descripcion1 || "Tienes permitido añádir hasta"} 
      ${currentSelectingBooksPackage?.maxNumeroDeLibrosParaEscoger} 
      ${modal_limite_libros?.descripcion2 || "libros al paquete"} '${
							currentSelectingBooksPackage?.nombre
						}'
      ${
			modal_limite_libros?.descripcion3 ||
			". Al parecer haz alcanzado el limite o estas intentado agregar más libros de lo permitido."
		}`}
					/>

					{/* Modal de compartir */}
					{modalCompartir && (
						<>
							<Modal nombreModal={idModales.modalCompartir}>
								<div className="pb-3">
									<h1 className="quicksand-700 onbackground-var2--color text-30 text-center ">
										{modal_compartir.titulo}
									</h1>

									<p className="text-center r7-16-negro26 mt-5 ">
										{modal_compartir.descripcion}
									</p>

									<div className="mt-5 relative">
										<input
											className="w-full shadow-1"
											disabled={true}
											readOnly
											ref={urlDireccion}
											value={`${window.location.host}/visor/${book?.idLibro}`}
										/>
										<span
											className="icon-copiar cursor-pointer absolute top-2  right-2 surface-var2--color hover:blue-var1--color"
											onClick={() => {
												navigator.clipboard.writeText(
													urlDireccion.current.value
												);
											}}
										></span>
									</div>
								</div>
							</Modal>
						</>
					)}
				</>
			) : (
				<SkeletonFichaLibro />
			)}
		</>
	);
};
