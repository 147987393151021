import react, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import useObtenerSubsecciones from '../../../containers/helpers/hooks/useObtenerSubsecciones';
import { Icon } from '@iconify/react';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/**
 * Es un botón que al ser presionado permite la descarga de un archivo excel que contendra cualquier tipo de información pero que deberá ser pasada en una estructura de datos especifica. Este componente es muy util para toda la aplicación, por el momento su uso más frecuente es dentro del componente de estadísticas, pero puede en el futuro pasar a ser un componente global.
 */
export const DescargarReporte = (props) => {
  const {
    nombreArchivo,
    tablaInformacion,
    anchoDeCelda,
    elementoPersonalizado,
    deshabilitado } = props;

  const [informacionFinal, setInformacionFinal] = useState([]);
  const { textosSubSecciones: traducciones } = useObtenerSubsecciones('estadisticas');


 // ESTILOS ----------------------------------------------------------------------------------
  const colorFondoCabecera = 'FF6666CC';
  const colorFuenteCabecera = 'FFFFFFFF';

  const estiloColumna = {
    width: { wpx: anchoDeCelda && Number.isInteger(anchoDeCelda) ? anchoDeCelda : 150 },
    style: {
      fill: { patternType: "solid", fgColor: { rgb: colorFondoCabecera } },
      font: { bold: true, color: { rgb: colorFuenteCabecera } },
      alignment: { vertical: 'center', horizontal: 'center', wrapText: true }
    }
  }

  const borde = 'thin';
  const colorBorde = 'FF6666CC';
  const colorFondoFila = 'FFF1F1FF';
  const colorFuente = 'FF000000';

  const estiloFila = {
    style: {
      fill: { patternType: "solid", fgColor: { rgb: colorFondoFila } },
      font: { bold: false, color: { rgb: colorFuente } },
      alignment: { vertical: 'center', horizontal: 'center', wrapText: true },
      border: {
        top: { style: borde, color: { rgb: colorBorde } },
        bottom: { style: borde, color: { rgb: colorBorde } },
        left: { style: borde, color: { rgb: colorBorde } },
        right: { style: borde, color: { rgb: colorBorde } }
      }
    }
  }
 // FINAL DE ESTILOS ----------------------------------------------------------------------------------

  /**
   * 
   * @param {Array<{nombreColumna:string}>} columnas un arreglo unidimensional con objetos que especifiquen el nombre de las columnas.
   * @param {Array<Array<{valor:string}>>} filas - arreglo bidimensional de objetos que especifiquen el valor de la celda. 
   */
  const transformarDatos = (columnas, filas) => {
    const informacionTransformada = [];
    const columnasTransformadas = [];
    const filasTransformadas = [];
    // poner celdas vacias en relación con las columnas que se pasen, esto se debe a que si no se pasan filas las columnas no se muestran, hay que pasar almenos una fila con strings vacios para que muestre todas las columnas.
    if (filas.length <= 0 && filas){
      filasTransformadas.push(generarCeldasVacias(columnas.length || 0));
    }
      // transformar columnas
      Object.values(columnas).forEach(col => {
        const colTransformada = { ...estiloColumna, title: col?.nombreColumna || 'N/A' }
        columnasTransformadas.push(colTransformada);
      })
    // transformar filas
    Object.values(filas).forEach(fila => {
      let celdas = [];
      for (let i = 0; i < columnas.length; i++) {
        const celda = { ...estiloFila,  value: (fila[i]?.valor !== undefined && fila[i]?.valor !== null)?
          !isNaN(fila[i]?.valor)? 
            parseInt(fila[i]?.valor) 
            :
            fila[i]?.valor.toString()
          : 
          'N/A' }
        celdas.push(celda);
      }
      filasTransformadas.push(celdas);
    })

    //integrar partes informqación transformada
    informacionTransformada.push({
      columns: columnasTransformadas,
      data: filasTransformadas
    })
    // ponerla disponible para descargar
    setInformacionFinal(informacionTransformada);
  }

  const generarCeldasVacias = (cantidad) => {
    const celdas = [];
    for (let i = 0; i < cantidad; i++) {
      const celda = { ...estiloFila, value: 'N/A' }
      celdas.push(celda);
    }

    return celdas;
  }


  useEffect(() => {
    transformarDatos(tablaInformacion?.columnas || [], tablaInformacion?.filas || []);
  }, [tablaInformacion])

  // useEffect(() => {
  //   console.log(informacionFinal);
  // }, [informacionFinal])

  return (<>
    <ExcelFile
      filename={nombreArchivo ? nombreArchivo : 'Reporte'}
      element={
        elementoPersonalizado ?
          elementoPersonalizado
          : <button
            className='boton-amarillo boton-justo flex center text-center boton_hover_morado transicion-150 focusable-red'
            disabled={deshabilitado}>
            {traducciones?.botones?.descargar || 'Descargar reporte'}
            <Icon icon="charm:download" className='mx-2' />
          </button>}
    >
      <ExcelSheet dataSet={informacionFinal} name={nombreArchivo ? nombreArchivo : 'Reporte'} />
    </ExcelFile>
  </>)
}

DescargarReporte.propTypes = {
  /**
   * Elemento de react o HTML que sirva como botón para descargar el archivo.
   */
  elementoPersonalizado: PropTypes.element,
  /**
   * Nombre con el que se guardara el archivo.
   */
  nombreArchivo: PropTypes.string,
  /**
   * Objeto con las columnas y filas que se desean mostrar
    @example 
    ej:
    {
      columnas:[
        {nombreColumna:'Col 1'},
        {nombreColumna:'Col 2'}
      ],

      filas:[
        [
          {valor:'Valor 1 col 1'},
          {valor:'Valor 1 col 2'}
       ],
         [
          {valor:'Valor 2 col 1'},
          {valor:'Valor 2 col 2'}
       ]
      ]
    }
   * @param {Array<{nombreColumna:string}>} columnas un arreglo unidimensional con objetos que especifiquen el nombre de las columnas.
   * @param {Array<Array<{valor:string}>>} filas - arreglo bidimensional de objetos que especifiquen el valor de la celda. 
   */
  tablaInformacion: PropTypes.arrayOf(PropTypes.array).isRequired,
  /**
   * Ancho de la celda deseado en pixeles
   */
  anchoDeCelda: PropTypes.number,
  /**
   * Booleano que permite deshabilitar el botón por defecto de descarga, si se pasa la propiedad 'elementoPersonalizado' esta propiedad no tendra efecto en ese elemento.
   */
  deshabilitado: PropTypes.bool
}

