import MicroModal from "micromodal";
import { useState, useEffect } from "react";
import { AccionesLibroAPI } from "../../../api/AccionesLibroAPI";
import { EstudiantesAPI } from "../../../api/EstudiantesAPI";
import useObtenerSubsecciones from "../../../containers/helpers/hooks/useObtenerSubsecciones";
import Modal from "../../globales/modales/Modal";
import PropTypes from 'prop-types';

/**
 * Este comopnente permite recomendar uno o varios libros a todos los estudiantes pertenecientes a un curso especifico.
 */
const ModalRecomendar = (props) => {
    const {
        curso,
        libros,
        alActualizarRecomendaciones, itemsActivos, compileCursosData } = props;

    const { textosSubSecciones: traducciones } = useObtenerSubsecciones('libros');
    const [cursoActivo, setCursoActivo] = useState('');
    const [estudiantes, setEstudiantes] = useState([]);
    const [recomendacionGlobal, setRecomendacionGlobal] =useState(false);

    const cerrarModal = () => {
        MicroModal.close('modal-recomendar');
    }

    const nombreCursoActivo = () => {
        const sedesCursos = compileCursosData();
        let nombreCurso = '';
        sedesCursos.cursos.map(item => {
            if (itemsActivos.curso === item.id && itemsActivos.sede === item.idSede) {
                nombreCurso = item.nombre;
            }
            return nombreCurso;
        })

        setCursoActivo(nombreCurso);
    }

  
    const gestionarRecomendaciones = async () => {
        let accion = 'Ocultar';
            if(recomendacionGlobal){
                accion = 'Mostrar'
            }
            await AccionesLibroAPI.multiplesAcciones({
                accion: accion,
                codigoEstudiantes: estudiantes.map(est => est?.codigo),
                idLibros: Array.isArray(libros) ? libros.map(libro => libro?.idLibro) : []
            })
            .then(resultado => {
                cerrarModal();
                alActualizarRecomendaciones && alActualizarRecomendaciones();
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        const cargarEstudiantes = async () => {
            await EstudiantesAPI.obtenerPorCurso(curso || '')
                .then(resultado => {
                    setEstudiantes(resultado)
                })
                .catch(error => console.log(error))
        }
        compileCursosData && nombreCursoActivo();
        cargarEstudiantes();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsActivos])

/* 
    useEffect(() => {
        console.log('estudiantes', estudiantes)
    }, [estudiantes])
 */

    return (
        <Modal nombreModal="modal-recomendar">
            <div className="pb-3">
                <h1 className="quicksand-700  text-20 text-center ">{`${traducciones?.modal_asignar?.titulo || 'Recomendar este libro a'}`}</h1>
                <p className="blue-inv-var1--bg mt-5 py-2 center background-var3--color q7-20-blanco">
                    {cursoActivo}
                </p>
                <div className="background-var3--bg max-h-48 scroll mt-5">
                    <ul>
                        <li
                            className="px-3 py-3 alineado-verticalmente cursor-pointer hover:primary-inv-var5--bg"
                          
                        >
                            <label className=" w-full h-7 relative mr-3 cursor-pointer" role="checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        setRecomendacionGlobal(e?.target?.checked)
                                    }}
                                />
                                <i className="check" ></i>
                                <p className="raleway-400 onbackground-var2--color  absolute left-10 top-0.5">{`${traducciones?.modal_asignar?.opciones?.todos || 'Recomendar a todo el curso'}`}</p>
                            </label>
                        </li>




                    </ul>
                </div>
            </div>
            <div className="w-full flex center">
                <button
                    aria-label="Actualizar"
                    className="focusable-primary boton-justo sm:px-10 boton-amarillo boton_hover_morado flex center"
                    onClick={() => gestionarRecomendaciones()}
                >
                    {traducciones?.botones.actualizar}
                </button>
            </div>

        </Modal>
    )
}
export default ModalRecomendar;

ModalRecomendar.propTypes = {
    /**
     * id del cursoque se va a recomendar el/los libros.
     */
    curso: PropTypes.string,
    /**
     * Arreglo de los libros que se desea recomendar al curso
     */
    libros: PropTypes.arrayOf(PropTypes.object),
    /**
     * Función que se actualiza la recomendación de los libros.
     */
    alActualizarRecomendaciones: PropTypes.func,
    /**
     * Objeto con el id del curso y sede actualmente seleccionados en la sección de libros.
     */
    itemsActivos: PropTypes.shape({
        curso:PropTypes.string,
        sede:PropTypes.string
    }),
    /**
     * Función que compila los datos de los cursos desde el container de SuscripcionActiva
     */
    compileCursosData: PropTypes.func

}