import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from './../helpers/hooks/useObtenerSubsecciones';
//Funciones
import agregarPropiedadChecked from './../../utilities/function/agregarPropiedadChecked';
import descargarTabla from '../../utilities/function/descargarTabla';
import generalCallApi from './../helpers/generalCallApi';
import getUrlParam from './../../utilities/function/getUrl';
import listarArray from '../../utilities/function/listarElementos/listarArray';
import listarObject from '../../utilities/function/listarElementos/listarObject';
import transformarDatosProfesores from './../../utilities/function/profesores/transformarDatosProfesores';
//Componentes
import ProfesoresGeneralComponent from './../../components/profesores/ProfesoresGeneralComponent';
import SkeletonProfesoresGeneral from '../../components/profesores/SkeletonsProfesores/SkeletonProfesoresGeneral';
import { normalizeString } from '../../utilities/StringUtilities';
import PropTypes from 'prop-types';

/**
 * Container que recibe los estados de isLoadingCursos y isLoadingProfesores. Este container retorna el componente ProfesoresGeneralComponent al cual se le pasa una serie de propiedades para renderizar la pantalla principal de profesores. Las funciones principales de este container son obtener profesores, buscar profesores , borrar filtros, acciones del multiselect (asignar curso y eliminar profesor) y descargar datos.
 * @returns {JSX.Element} ProfesoresGeneralComponent
 */
const ProfesoresGeneral = (props) => {
  const { isLoadingCursos, isLoadingProfesores } = props;
  //Estados globales
  const state = useTrackedState();
  const { datosCursos, datosInstitucion, datosProfesores } = state;
  const dispatch = useDispatch();


  //Estados del componentes  
  const [cursosSede, setCursosSede] = useState({ hayCursos: false, cursos: [] });
  const [datosMultiSelect, setDatosMultiSelect] = useState({
    cursos: { hayResultados: false, resultados: [] },
    idCurso: null,
    idSede: null,
    nombreCurso: '',
    nombreSede: '',
    numeroProfesores: 0,
    profesores: [],
  });
  const [instanciaDatosProfesores, setInstanciaDatosProfesores] = useState(datosProfesores?.resultados);
  const [filtros, setFiltros] = useState({
    sede: null,
    curso: null
  });
  const [preloader, setPreloader] = useState(false);
  const [profesoresSeleccionados, setProfesoresSeleccionados] = useState({
    idProfesores: [],
    profesores: [],
  });
  const [respuestaApiMoverProfesores, setRespuestaApiMoverProfesores] = useState({
    isError: null,
    mensaje: ''
  });
  const [respuestaEliminarProfesores, setRespuestaEliminarProfesores] = useState({
    isError: null,
    mensaje: ''
  });
  const [resultadosBusqueda, setResultadosBusqueda] = useState({
    hayResultados: true,
    resultados: []
  });
  const [sedes, setSedes] = useState([]);
  const [sedesSelect, setSedesSelect] = useState([]);
  const [todosLosProfesores, setTodosLosProfesores] = useState([]);

  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('profesores');


  const history = useHistory()


  useEffect(() => {
    const listaSedes = listarArray({ array: datosInstitucion.sedes });
    setSedesSelect(listaSedes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!!datosCursos) {
      const cursos = listarCursos({ idSede: datosMultiSelect.idSede })
      setCursosSede(cursos.resultados)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCursos, datosMultiSelect.idSede])


  //Actualiza todos los resultados
  useEffect(() => {
    if (!!datosProfesores?.resultados) {
      let listaSedes = listarArray({ array: datosInstitucion.sedes });
      if (datosProfesores['sin-asignar'] === true) listaSedes.push({ id: 'sin-asignar', nombre: 'Sin asignar' })

      setSedes(listaSedes);

      const datosPropiedadCheck = { ...datosProfesores.resultados };
      const dataActualizada = inicializarCheckedProfesores({ data: datosPropiedadCheck });

      setSedes(listaSedes);
      setInstanciaDatosProfesores(dataActualizada);
      resultadosProfesores({ data: dataActualizada });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosProfesores])


  //Actualiza todos los resultados
  useEffect(() => {
    setProfesoresSeleccionados({
      idProfesores: [],
      numeroProfesores: 0,
      profesores: [],
    })

    if (filtros.sede === null) {
      setResultadosBusqueda({
        hayResultados: todosLosProfesores.length > 0,
        resultados: todosLosProfesores
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros.sede, todosLosProfesores])


  //Borrar filtros
  const borrarFiltros = ({ refInput }) => {
    refInput.value = '';

    cancelarAccionMultiSelect();

    const filtrosLimpios = {};
    for (const key in filtros) {
      filtrosLimpios[key] = null;
    }

    history.push('/profesores');
    setFiltros(filtrosLimpios);
    setResultadosBusqueda({
      hayResultados: todosLosProfesores.length > 0,
      resultados: todosLosProfesores
    })
  }


  /**
   * Buscar profesores
   * @param {string} idSede - id de la sede por la que se desea filtrar. Este parámetro no es obligatorio.
   * @param {string} value - valor que tiene el input de búsqueda.
   */
  const buscarProfesores = ({ idSede, value }) => {
    let coincidencias = todosLosProfesores?.filter(profe =>
      normalizeString(profe?.correo)?.includes(normalizeString(value)) ||
      normalizeString(profe?.nombre)?.includes(normalizeString(value))
    );
    const sede = idSede || filtros?.sede;
    if (sede) {
      coincidencias = coincidencias?.filter(profe => profe?.idSede === sede);
    }

    setResultadosBusqueda({
      hayResultados: coincidencias?.length > 0,
      resultados: coincidencias
    })
  }

/**
 * Se cambia el curso por el que se desea filtrar
 * @param {string} idSede 
 * @param {string} idCurso  
 */
  const cambiarCurso = ({ idSede, idCurso }) => {
    const sede = !!idSede ? idSede : null;
    const curso = !!idCurso ? idCurso : null;

    const sedeDB = datosInstitucion.sedes.find(sede => sede.id === idSede);
    const cursoDB = datosCursos[sede].cursos.find(curso => curso.id === idCurso);

    setDatosMultiSelect({
      ...datosMultiSelect,
      cursos: listarCursos({ idSede: sede }).resultados,
      idCurso: curso,
      idSede: sede,
      nombreSede: sedeDB.nombre,
      nombreCurso: cursoDB.nombre
    });
  }

  /**
   * Actualizar lista de profesores seleccionados
   * @param {bool} e -  Corresponde al evento para rectificar el evento checked del profesor.
   * @param {string} idSede -  Hace referencia al id de la sede a la que pertenece el profesor
   * @param {shape} profesor -Hace referencia a un objeto con la información del profesor (id y nombre).
   */
  const cambiarProfesoresElegidos = ({ e, idSede, profesor }) => {
    let profesores = profesoresSeleccionados.profesores;
    let datosProfesoresElegidos = profesoresSeleccionados.profesores;

    const checked = e.target.checked;
    const datosViejos = profesoresSeleccionados.profesores;

    datosProfesoresElegidos = [];
    if (checked) {
      datosProfesoresElegidos = [...datosViejos, { id: profesor.id, idSede: profesor.idSede, cursos: profesor.cursos }]
    } else {
      datosViejos.forEach(profesorAsignado => {
        if (profesorAsignado.id !== profesor.id) {
          datosProfesoresElegidos.push(profesorAsignado)
        }
      })
    }

    profesores = [];
    let idSedes = []
    if (datosProfesoresElegidos.length !== 0) {
      datosProfesoresElegidos.map(profesorActualizado => {
        profesores.push(profesorActualizado.id)
        idSedes.push(profesorActualizado.idSede)
        return profesorActualizado
      })
    }

    idSedes = [...new Set(idSedes)];

    const datos = {
      idSede: idSedes.length !== 1 ? null : idSede,
      idProfesores: profesores,
      profesores: datosProfesoresElegidos,
    }


    const sedeMultiSelect = datosMultiSelect.idSede;
    let datosNuevosMultiSelect = {
      ...datosMultiSelect,
      idSede: sedeMultiSelect,
      nombreSede: !!datosMultiSelect.idSede ? datosProfesores.resultados[sedeMultiSelect].nombre : null,
      profesores: datosProfesoresElegidos,
      numeroProfesores: profesores.length,
    }

    const instanceResultados = [...resultadosBusqueda.resultados];

    const indexProfesorArray = instanceResultados.findIndex(profesorArray => profesorArray.id === profesor.id);

    instanceResultados[indexProfesorArray] = {
      ...instanceResultados[indexProfesorArray],
      checked: checked
    }

    setResultadosBusqueda({ ...resultadosBusqueda, resultados: instanceResultados })
    setDatosMultiSelect(datosNuevosMultiSelect)
    setProfesoresSeleccionados(datos);
  }


  /**
   * Cambiar sede
   * @param {string} idSede -  id de la sede por la que se desea filtrar. Esta corresponde al id de la sede que se desea consultar. Este parámetro no es obligatorio.
   * @param {string} value - indica si el cambio debe hacerse para el estado de los filtros o para la sede del multi action.
   * @param {string} tipoAccion -valor que tiene el input de búsqueda.
   */
  const cambiarSede = ({ idSede, value, tipoAccion }) => {
    const sede = !!idSede ? idSede : null;
    if (tipoAccion === 'filtros') {
      buscarProfesores({ idSede, value })
      setFiltros({ ...filtros, sede });
    } else if (tipoAccion === 'ver todo') {
      const filtrosLimpios = {};
      for (const key in filtros) {
        filtrosLimpios[key] = null;
      }

      setFiltros(filtrosLimpios);
      setResultadosBusqueda({
        hayResultados: todosLosProfesores.length !== 0,
        resultados: todosLosProfesores
      })
    } else if (tipoAccion === 'multiselect') {
      const sedeDB = datosInstitucion.sedes.find(sede => sede.id === idSede);

      setDatosMultiSelect({
        ...datosMultiSelect,
        idSede: sede,
        nombreSede: sedeDB.nombre,
        idCurso: null,
        nombreCurso: null
      });
    }
  }


  //Cancelar la función del multi-action
  const cancelarAccionMultiSelect = () => {
    const instanceResultados = agregarPropiedadChecked({ data: resultadosBusqueda.resultados });

    setResultadosBusqueda({
      ...resultadosBusqueda,
      resultados: instanceResultados
    })

    setDatosMultiSelect({
      cursos: listarCursos({ idSede: null }).resultados,
      idCurso: null,
      idSede: null,
      nombreCurso: '',
      nombreSede: '',
      numeroProfesores: 0,
      profesores: []
    })

    setProfesoresSeleccionados({
      idProfesores: [],
      numeroProfesores: 0,
      profesores: [],
    })
  }


  //Descargar reporte
  const descargarDatos = () => {
    let resultadosExcel = []
    resultadosBusqueda.resultados.map(resultado => {
      return resultadosExcel.push({
        nombre: resultado.nombre,
        correo: resultado.correo,
        nombreSede: resultado.nombreSede,
        nombreCursos: resultado.nombreCursos,
      })
    })

    descargarTabla({
      type: 'xlsx',
      nombreArchivo: `listadoProfesores_sede`,
      valores: resultadosExcel,
      headerTabla: ['Nombres y apellidos', 'Correo', 'Sede', 'Cursos asignados']
    })
  }


  //Eliminar profesores
  const eliminarProfesores = async () => {
    if (!preloader) {
      setRespuestaEliminarProfesores({
        isError: null,
        mensaje: ''
      })

      setPreloader(true);

      let profesores = [];
      datosMultiSelect.profesores.map(profesor => profesores.push(profesor.id))

      const pathname = "/profesores/borrarProfesor";
      const properties = { idProfesores: profesores };

      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        await llamadaApiObtenerProfesores();
      };

      cancelarAccionMultiSelect();
      //Actualizar datos de la respuesta del api de editar
      setDatosMultiSelect({
        idSede: null,
        nombreSede: '',
        profesores: [],
        numeroProfesores: 0,
      })

      setPreloader(false);

      setProfesoresSeleccionados({
        idProfesores: [],
        numeroProfesores: 0,
        profesores: [],
      })

      setRespuestaEliminarProfesores({
        isError: error,
        mensaje: result.info
      })
    }
  }


  //Inicializar la propiedad checked de los elementos
  const inicializarCheckedProfesores = ({ data }) => {
    const newData = { ...data }
    for (const key in data) {
      let element = newData[key];
      const nuevoDatoProfesor = agregarPropiedadChecked({ data: element.profesores });
      newData[key] = { ...element, profesores: nuevoDatoProfesor }
    }

    return newData
  }


  const listarCursos = ({ idSede }) => {
    let listaCursos = listarObject({ object: datosCursos, propiedad: 'cursos', idSede })
    return listaCursos;
  }


  //función para obtener los profesores despues de los cambios de eliminar y mover profesores
  const llamadaApiObtenerProfesores = async () => {
    const result = await generalCallApi({ pathname: '/profesores/obtenerListadoProfesores' });
    const error = result.status === 0;

    if (!error) {
      // Actualizar datos en el store 
      let value = await transformarDatosProfesores({ datos: result.data, sedes: datosInstitucion.sedes });
      value = {
        resultados: value.resultadosModificados,
        'sin-asignar': value.profesoresSinAsignar
      }

      dispatch({
        property: 'datosProfesores',
        type: 'SET_DATA',
        value: value
      });
    }
  }


  //Mover profesores de sede
  const moverProfesores = async () => {
    if (!preloader) {
      setRespuestaApiMoverProfesores({
        isError: null,
        mensaje: ''
      })

      setPreloader(true);

      let profesores = [];
      datosMultiSelect.profesores.map(profesor => {
        let cursos = [];
        profesor.cursos.map(curso => cursos.push(curso.id));

        profesores.push({
          cursos: profesor.idSede !== datosMultiSelect.idSede ? [datosMultiSelect.idCurso] : [...cursos, datosMultiSelect.idCurso],
          id: profesor.id
        });

        return profesor;
      });


      const pathname = '/profesores/moverProfesores';
      const properties = {
        idSede: datosMultiSelect.idSede,
        profesores: profesores
      }


      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        await llamadaApiObtenerProfesores();
      };

      //Actualizar datos de la respuesta del api de editar
      setDatosMultiSelect({
        idSede: null,
        nombreSede: '',
        numeroProfesores: 0,
        profesores: [],
      })

      setPreloader(false);

      setProfesoresSeleccionados({
        idProfesores: [],
        numeroProfesores: 0,
        profesores: [],
      })

      setRespuestaApiMoverProfesores({
        isError: error,
        mensaje: result.info
      });
    };
  };


  //Listar resultados
  const resultadosProfesores = ({ data }) => {
    if (!!data) {
      const paramSede = getUrlParam('sede');
      const datos = listarObject({ object: data, idSede: paramSede, propiedad: 'profesores' });
      const { errorSede, resultados, sedeBuscar, todosLosElementos } = datos;

      setFiltros({ ...filtros, sede: sedeBuscar });
      setResultadosBusqueda(resultados);
      setTodosLosProfesores(todosLosElementos);
    }
  }

  return (
    <>
      {
        isReady && !isLoadingProfesores && !isLoadingCursos ?
          <>
            <ProfesoresGeneralComponent
              borrarFiltros={borrarFiltros}
              buscarProfesores={buscarProfesores}
              cambiarProfesoresElegidos={cambiarProfesoresElegidos}
              cambiarCurso={cambiarCurso}
              cambiarSede={cambiarSede}
              cancelarAccionMultiSelect={cancelarAccionMultiSelect}
              cursosSede={cursosSede}
              datosMultiSelect={datosMultiSelect}
              descargarDatos={descargarDatos}
              eliminarProfesores={eliminarProfesores}
              filtros={filtros}
              moverProfesores={moverProfesores}
              preloader={preloader}
              respuestaEliminarProfesores={respuestaEliminarProfesores}
              respuestaApiMoverProfesores={respuestaApiMoverProfesores}
              resultadosBusqueda={resultadosBusqueda}
              sedes={sedes}
              sedesSelect={sedesSelect}
              textosInterfaz={{
                miga_de_pan: textosInterfaz.miga_de_pan,
                vista_general: textosInterfaz.vista_general,
                botones: textosInterfaz.botones,
                no_existe_sede: textosInterfaz.no_existe_sede,
                multi_accion: textosInterfaz.multi_accion,
                modal_mover: textosInterfaz.modal_mover,
                modal_exito: textosInterfaz.modal_exito,
                modal_eliminar: textosInterfaz.modal_eliminar,
                notificacion: textosInterfaz.notificacion,
              }}
            />
          </>
          :
          < SkeletonProfesoresGeneral />

      }
    </>
  )
}

export default ProfesoresGeneral;

ProfesoresGeneral.propTypes = {
  /**
  *  Booleano que valida si ha cargado la información de profesores.
  */
  isLoadingProfesores: PropTypes.bool.isRequired,

  /**
  * Es un booleano que indica si la información del curso ya cargo. 
  */
  isLoadingCursos: PropTypes.bool.isRequired
}