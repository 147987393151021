import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store';
//Hooks personalizados
import useObtenerSubsecciones from '../helpers/hooks/useObtenerSubsecciones';
//Funciones
import agregarDatosCurso from './../../utilities/function/agregarDatosCurso';
import comprobarNombreRepetido from './../../utilities/function/comprobarNombreRepetido';
import elegirElementosCheck from './../../utilities/function/elegirElementosCheck';
import generalCallApi from '../helpers/generalCallApi';
import listarObject from './../../utilities/function/listarElementos/listarObject';
//Componentes
import EditarCurso from './../../components/cursos/EditarCurso';
import SkeletonCursosEditar from '../../components/cursos/SkeletonsCursos/SkeletonCursosEditar';
import VentanaAlerta from '../../components/globales/VentanaAlerta';


const EditarEliminarCurso = (props) => {
  const { isLoadingCursos, isLoadingProfesores } = props

  //Estados globales
  const state = useTrackedState();
  const { datosCursos, datosProfesores, datosInstitucion } = state;
  const dispatch = useDispatch();

  //Estados del componente
  const [datosCurso, setDatosCurso] = useState(null);
  const [datosNuevosCurso, setDatosNuevosCurso] = useState(null);
  const [camposModificados, setCamposModificados] = useState({});
  const [comprobarDatosNuevos, setComprobarDatosNuevos] = useState(false)
  const [existeCurso, setExisteCurso] = useState(null);
  const [isNombreCursoRepetido, setIsNombreCursoRepetido] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [profesoresInstituto, setProfesoresInstituto] = useState({ hayProfesores: null });
  const [respuestaApiEditarCurso, setRespuestaApiEditarCurso] = useState({
    isError: null,
    mensaje: ''
  });
  const [respuestaApiEliminarCurso, setRespuestaApiEliminar] = useState({
    isError: null,
    mensaje: ''
  });
  const [sedeAsignada, setSedeAsignada] = useState(null);
  const [sedesInstituto, setSedesInstituto] = useState(null);


  //Hooks personalizados
  const { isReady, textosSubSecciones: textosInterfaz } = useObtenerSubsecciones('cursos');

  //Parámetros de url
  const history = useHistory();
  const params = useParams();
  const { sede, curso } = params;


  useEffect(() => {
    listarSedesInstituto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    comprobarCambios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCurso, datosNuevosCurso])


  useEffect(() => {
    comprobarNombreCurso({ nombreCurso: !!datosNuevosCurso ? datosNuevosCurso.nombre : '' });
    listarProfesoresInstituto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sedeAsignada])


  useEffect(() => {
    listarProfesoresInstituto()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosProfesores])


  useEffect(() => {
    //Verificar si el parámetro de curso y sede existen
    if (!!curso && !!sede) {
      if (!!datosCursos && !!datosProfesores?.resultados) {
        //Verificar si la sede existe
        const sedeActual = { ...datosCursos[sede] };
        let existeParam = !!sedeActual ? true : false;

        if (!!sedeActual) {
          //Buscar la sede del curso
          const cursoActual = sedeActual.cursos.find(cursoSede => cursoSede.id === curso);
          existeParam = !!cursoActual ? true : false;

          //Si el api de eliminar ya se llamo y esta respondio de forma afirmativa, no se ejecuta lo que se encuentra en el siguiente condicional
          if (respuestaApiEliminarCurso.isError !== false) {
            //Si exite el curso se guardan los datos en el estado del componente
            if (existeParam) {
              //Se el estado datosInstitucion existe en el store se obtienen los datos de ese estado
              let profesores = [];
              cursoActual.profesores.map(profesor => profesores.push(profesor.id.toString()));

              const infoCurso = {
                datosProfesores: cursoActual.profesores,
                icono: cursoActual.icono,
                id: cursoActual.id,
                idSede: cursoActual.idSede,
                nombre: cursoActual.nombre,
                nombreSede: datosCursos[cursoActual.idSede].nombre,
                numeroProfesores: profesores.length,
                profesores: profesores,
              };

              setDatosCurso(infoCurso)
              setDatosNuevosCurso(infoCurso)
              setSedeAsignada(cursoActual.idSede)
            };
          };
        }
        setExisteCurso(existeParam);
      }
      return;
    }

    //Si el parámetro no existe se redirecciona a la vista en general
    history.push('/cursos');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curso, datosCursos, datosProfesores, sede])


  //Actualiza icono del curso
  const actualizarIconoCurso = ({ icono }) => {
    setDatosNuevosCurso({ ...datosNuevosCurso, icono: icono })
  };


  //Actualiza el profesor asignado
  const actualizarProfesoresAsignados = ({ e, idSede, profesor }) => {
    let profesores = idSede !== datosNuevosCurso.idSede ? [] : datosNuevosCurso.profesores;
    let datosProfesoresNuevos = idSede !== datosNuevosCurso.idSede ? [] : datosNuevosCurso.datosProfesores;

    if (idSede === datosNuevosCurso.idSede) {
      const datosViejos = datosNuevosCurso.datosProfesores;

      const result = elegirElementosCheck({ e, elemento: profesor, datosActuales: datosViejos })
      const { ids, datos } = result;

      profesores = ids;
      datosProfesoresNuevos = datos;
    }

    const datos = {
      ...datosNuevosCurso,
      datosProfesores: datosProfesoresNuevos,
      idSede: idSede,
      numeroProfesores: profesores.length,
      profesores: profesores,
    }
    setDatosNuevosCurso(datos);
  };


  //Actualiza la sede asignada
  const actualizarSedeAsignada = ({ idSede }) => {
    const profesores = [];
    const datosProfesoresNuevos = [];

    const datos = {
      ...datosNuevosCurso,
      datosProfesores: datosProfesoresNuevos,
      idSede: idSede,
      numeroProfesores: 0,
      profesores: profesores,
    }

    setDatosNuevosCurso(datos);
    setSedeAsignada(idSede);
  }


  const comprobarCambios = () => {
    let datosNuevos = false;
    let campos = {}

    for (const key in datosCurso) {
      const datoViejo = datosCurso[key];
      const datoNuevo = datosNuevosCurso[key];
      let cambioCampo = false;

      if (key === 'profesores') {
        if (datoViejo.length !== datoNuevo.length) {
          datosNuevos = true;
          cambioCampo = true;
        } else {
          // eslint-disable-next-line no-loop-func
          datoNuevo.forEach(profesor => {
            const busquedaElemento = datoViejo.filter(profesorNuevo => profesorNuevo === profesor);
            if (busquedaElemento === 0) {
              datosNuevos = true;
              cambioCampo = true;
            }
          })
        }
      } else if (datoNuevo !== datoViejo) {
        datosNuevos = true;
        cambioCampo = true;
      };
      campos[key] = cambioCampo
    }

    campos = { ...camposModificados, ...campos }
    delete campos.id;
    delete campos.nombreSede;
    delete campos.numeroProfesores;

    setCamposModificados(campos)
    setComprobarDatosNuevos(datosNuevos);
  }


  //Comprueba si el nombre ingresado corresponde a algun curso ya creado dentro de la sede
  const comprobarNombreCurso = ({ nombreCurso }) => {
    if (!!sedeAsignada) {
      const array = !!sedeAsignada
        ? [...datosCursos[sedeAsignada].cursos] :
        [];
      const comprobacion = comprobarNombreRepetido({
        array,
        nombreCurso,
        propiedad: sedeAsignada
      })

      setDatosNuevosCurso({
        ...datosNuevosCurso,
        nombre: nombreCurso
      })

      const comprobacionRepetido = datosCurso.nombre !== nombreCurso ? comprobacion.estaRepetida : false;
      setIsNombreCursoRepetido(comprobacionRepetido);
    }
  }


  //Función editar sede
  const editarCurso = async () => {
    if (!preloader) {
      setRespuestaApiEditarCurso({
        isError: null,
        mensaje: ''
      })

      setPreloader(true);

      //Parámetros para el llamado del api
      const pathname = "/cursos/editarCurso";
      const properties = datosNuevosCurso;


      //Llamar api de editar instituto
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        await obtenerDatosDB();

        if (properties.sedeVieja !== properties.idSede) {
          history.push(`/cursos/editar-curso/${properties.idSede}/${properties.id}`)
        }
        // Actualizar datos de la sede en el estado del componente
        setDatosCurso(datosNuevosCurso);
      }

      //Actualizar datos de la respuesta del api de editar
      setRespuestaApiEditarCurso({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    }
  }


  //Función eliminar sede
  const eliminarCurso = async () => {
    if (!preloader && respuestaApiEliminarCurso.isError !== false) {
      setRespuestaApiEliminar({
        isError: null,
        mensaje: ''
      });

      setPreloader(true);
      //Parámetros parap el llamado del api
      const pathname = "/cursos/borrarCurso";
      const properties = { idCurso: curso };

      //Llamar api de eliminar curso
      const result = await generalCallApi({ pathname, properties });
      const error = result.status === 0;

      if (!error) {
        setDatosCurso({ ...datosCurso, ...properties });
        await obtenerDatosDB();
      };

      setRespuestaApiEliminar({
        isError: error,
        mensaje: result.info
      });

      setPreloader(false);
    };
  };


  //Genera el listado de profesores que el profesor puede seleccionar para asignar al curso
  const listarProfesoresInstituto = () => {
    if (!!datosProfesores?.resultados) {
      const profesoresFiltrados = listarObject({
        object: datosProfesores.resultados,
        idSede: sedeAsignada,
        propiedad: 'profesores'
      })

      const resultados = {
        hayProfesores: profesoresFiltrados.resultados.hayResultados,
        profesores: profesoresFiltrados.resultados.resultados,
      }
      setProfesoresInstituto(resultados);
    }
  }


  //Guarda los datos del id y el nombre de las sedes de la sede en un estado
  const listarSedesInstituto = () => {
    const sedes = []
    datosInstitucion.sedes.map(sede => {
      return sedes.push({ id: sede.id, nombre: sede.nombre })
    })

    setSedesInstituto(sedes)
  }


  //Llamada al api para obtener los datos de la base de datos
  const obtenerDatosDB = async () => {
    const result = await generalCallApi({
      pathname: "/cursos/obtenerCursos", property: 'datosCursos'
    });
    let datos;

    const error = result.status === 0;
    if (!error) {
      datos = await agregarDatosCurso({ datos: result.data, section: 'cursos', propiedadJson: 'profesores' });

      // Actualizar datos en el store 
      dispatch({
        property: 'datosCursos',
        type: 'SET_DATA',
        value: datos
      });
    }

    return { datos, error };
  }


  //Componente que se va a renderizar 
  const componenteRender = () => {
    if (isReady && !!datosCurso && !isLoadingCursos && !isLoadingProfesores && profesoresInstituto.hayProfesores !== null) {
      return (
        <>
          <EditarCurso
            actualizarIconoCurso={actualizarIconoCurso}
            actualizarProfesoresAsignados={actualizarProfesoresAsignados}
            actualizarSedeAsignada={actualizarSedeAsignada}
            camposModificados={{
              icono: camposModificados.icono,
              idSede: camposModificados.idSede,
              nombre: camposModificados.nombre,
              profesor: camposModificados.profesores
            }}
            comprobarDatosNuevos={comprobarDatosNuevos}
            comprobarNombreCurso={comprobarNombreCurso}
            datosAntiguos={datosCurso}
            datosCurso={datosNuevosCurso}
            editarCurso={editarCurso}
            eliminarCurso={eliminarCurso}
            isNombreCursoRepetido={isNombreCursoRepetido}
            preloaderApi={preloader}
            profesoresInstituto={profesoresInstituto}
            respuestaApiEditarCurso={respuestaApiEditarCurso}
            respuestaApiEliminarCurso={respuestaApiEliminarCurso}
            sedesInstituto={sedesInstituto}
            textosInterfaz={{
              botones: textosInterfaz.botones,
              curso_eliminado: textosInterfaz.curso_eliminado,
              editar: textosInterfaz.editar,
              eliminar_curso: textosInterfaz.eliminar_curso,
              miga_de_pan: textosInterfaz.miga_de_pan,
              modal_icono: textosInterfaz.modal_icono,
              notificacion: textosInterfaz.notificacion,
            }}
          />
        </>
      );
    }
    if (isReady && existeCurso === false && respuestaApiEliminarCurso.isError !== false) {
      return (
        <VentanaAlerta
          imagen={{
            alt: 'blop con un icono de alerta',
            img: 'alertas/blop-alerta.png',
          }}
          link='/institutos'
          textosInterfaz={{
            descripcion: textosInterfaz.no_existe_curso.descripcion,
            textoBoton: textosInterfaz.botones.volver,
            titulo: textosInterfaz.no_existe_curso.titulo,
          }}
        >
          <Link
            className="boton-justo mx-auto py-2  lg:order-3 boton-amarillo boton_hover_morado"
            to='/cursos'
          >
            {textosInterfaz.botones.volver}
          </Link>
        </VentanaAlerta>
      )
    }
    return <SkeletonCursosEditar />;
  };


  return (
    <>
      {componenteRender()}
    </>
  );
};


export default EditarEliminarCurso;
