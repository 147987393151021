import { useEffect, useState } from "react";
import { CancelRequestController } from "../../../../../utilities/cancel-request-controller";
import { EstudiantesAPI } from "../../../../../api/EstudiantesAPI";
import { CursosAPI } from "../../../../../api/CursosAPI";
import { ProfesoresAPI } from "../../../../../api/ProfesoresAPI";
import { useTrackedState } from "../../../../../store";
import { AccionesLibroAPI } from "../../../../../api/AccionesLibroAPI";

/**
 * Hook para manejar la logica de recomendaciones de un libro
 *
 * @param {Object} props - Propiedades del hook
 * @param {string} props.bookId - Id del libro
 *
 * @returns {Object} - Un objeto con las siguientes propiedades:
 *  - `recommendedClasses`: Un array de objetos con las clases recomendadas y los estudiantes que la tienen recomendada
 *  - `allStudents`: Un array de objetos con todos los estudiantes
 *  - `allClasses`: Un array de objetos con todas las clases
 *  - `getClassStudents`: Funcion que devuelve los estudiantes de una clase
 *  - `addStudents`: Funcion que agrega estudiantes a una clase
 *  - `removeStudents`: Funcion que elimina estudiantes de una clase
 *  - `removeAllStudents`: Funcion que elimina todos los estudiantes de una clase
 *  - `addAllStudents`: Funcion que agrega todos los estudiantes de una clase
 */

export const useRecommendedManager = ({ bookId }) => {
	const state = useTrackedState();
	const [recommendedClasses, setRecommendedClasses] = useState([]);
	const [allStudents, setAllStudents] = useState([]);
	const [allClasses, setAllClasses] = useState([]);

	const getStudentsByClass = ({ classId, allStudents }) => {
		return allStudents.filter((student) => student?.idCurso === classId);
	};

	const getRecommendedStudents = ({ students, bookId }) => {
		let recommendedStudents = [];
		if (Array.isArray(students)) {
			students.forEach((student) => {
				const recomendados = student?.escogidosPorElProfesor;
				if (Array.isArray(recomendados)) {
					const recomendadosIDs = recomendados.map((libro) => libro?.idLibro);
					if (recomendadosIDs.includes(bookId)) {
						recommendedStudents.push(student);
					}
				}
			});
		}
		return recommendedStudents;
	};

	const loadRecommended = async ({ classes, allStudents }) => {
		let allRecommended = [];
		return new Promise(async (resolve, reject) => {
			if (Array.isArray(classes)) {
				classes.forEach(async (c, i) => {
					let students = getStudentsByClass({ classId: c?.id, allStudents });
					const recommendedStudents = getRecommendedStudents({
						students,
						bookId: bookId,
					});
					const selectedStudentsIds = recommendedStudents.map(
						(estudiante) => estudiante?.id
					);
					students = students.map((student) => {
						return {
							...student,
							recommended: selectedStudentsIds.includes(student?.id),
						};
					});

					if (recommendedStudents.length > 0) {
						allRecommended.push({
							class: c,
							students,
							recommendedStudentsLength: recommendedStudents.length,
							recommendedStudents,
						});
					}
				});
			}
			setRecommendedClasses(allRecommended);
			resolve(true);
		});
	};

	useEffect(() => {
		const abortController = new CancelRequestController();

		const fetchStudents = async () => {
			await EstudiantesAPI.getAll({ abortSignal: abortController.signal })
				.then((students) => {
					setAllStudents(Array.isArray(students) ? students : []);
				})
				.catch((error) => console.log(error));
		};

		const fetchData = async () => {
			await fetchStudents();
			if (state?.planActivo?.tipoUsuario === "rector") {
				await CursosAPI.obtenerTodos({ abortSignal: abortController.signal })
					.then((resultado) => {
						const _classes = resultado.map((_class) => {
							return {
								..._class,
								nombre: `${_class?.sedeNombre} - ${_class?.nombre}`,
							};
						});
						setAllClasses(_classes);
					})
					.catch((error) => console.log(error));
			}
			if (state?.planActivo?.tipoUsuario === "profesor") {
				await ProfesoresAPI.getByEmail({
					email: state?.datosDeUsuario?.correo,
					abortSignal: abortController.signal,
				})
					.then((resultado) => {
						const teacherBooks = Array.isArray(resultado?.cursos)
							? resultado?.cursos
							: [];
						const _classes = teacherBooks.map((_class) => {
							return {
								..._class,
								nombre: `${_class?.nombreSede} - ${_class?.nombre}`,
							};
						});
						setAllClasses(_classes);
					})
					.catch((error) => console.log(error));
			}
		};
		fetchData();
		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		loadRecommended({ classes: allClasses, allStudents });
	}, [allClasses, allStudents, bookId]);

	const addStudents = async ({ classId, ids }) => {
		return await AccionesLibroAPI.multiplesAcciones({
			accion: "Mostrar",
			codigoEstudiantes: ids || [],
			idLibros: [bookId],
		})
			.then((resultado) => {
				setAllStudents((students) =>
					students.map((student) => {
						const recommendedBooks = student?.escogidosPorElProfesor || [];
						return {
							...student,
							escogidosPorElProfesor:
								student.idCurso === classId && ids.includes(student?.codigo)
									? [
											...recommendedBooks.filter(
												(book) => book?.idLibro !== bookId
											),
											{ idLibro: bookId },
									  ]
									: recommendedBooks,
						};
					})
				);
			})
			.catch((error) => console.log(error));
	};

	const addAllStudents = async ({ classId }) => {
		const ids = allStudents
			.filter((student) => student?.idCurso === classId)
			.map((student) => student?.codigo);
		return await AccionesLibroAPI.multiplesAcciones({
			accion: "Mostrar",
			codigoEstudiantes: ids || [],
			idLibros: [bookId],
		})
			.then((resultado) => {
				setAllStudents((students) =>
					students.map((student) => {
						const recommendedBooks = student?.escogidosPorElProfesor || [];
						return {
							...student,
							escogidosPorElProfesor:
								student.idCurso === classId
									? [
											...recommendedBooks.filter(
												(book) => book?.idLibro !== bookId
											),
											{ idLibro: bookId },
									  ]
									: recommendedBooks,
						};
					})
				);
			})
			.catch((error) => console.log(error));
	};
	const removeStudents = async ({ classId, ids }) => {
		return await AccionesLibroAPI.multiplesAcciones({
			accion: "Ocultar",
			codigoEstudiantes: ids || [],
			idLibros: [bookId],
		})
			.then((resultado) => {
				setAllStudents((students) =>
					students.map((student) => {
						const recommendedBooks = student?.escogidosPorElProfesor || [];
						return {
							...student,
							escogidosPorElProfesor:
								student.idCurso === classId && ids.includes(student?.codigo)
									? [
											...recommendedBooks.filter(
												(book) => book?.idLibro !== bookId
											),
									  ]
									: recommendedBooks,
						};
					})
				);
			})
			.catch((error) => console.log(error));
	};

	const removeAllStudents = async ({ classId }) => {
		const ids = allStudents
			.filter((student) => student?.idCurso === classId)
			.map((student) => student?.codigo);
		await AccionesLibroAPI.multiplesAcciones({
			accion: "Ocultar",
			codigoEstudiantes: ids || [],
			idLibros: [bookId],
		})
			.then((resultado) => {
				setAllStudents((students) =>
					students.map((student) => {
						const recommendedBooks = student?.escogidosPorElProfesor || [];
						return {
							...student,
							escogidosPorElProfesor:
								student.idCurso === classId
									? [
											...recommendedBooks.filter(
												(book) => book?.idLibro !== bookId
											),
									  ]
									: recommendedBooks,
						};
					})
				);
			})
			.catch((error) => console.log(error));
	};

	const getClassStudents = ({ classId }) => {
		let students = getStudentsByClass({ classId, allStudents });
		const recommendedStudents = getRecommendedStudents({
			students,
			bookId: bookId,
		});
		const selectedStudentsIds = recommendedStudents.map((estudiante) => estudiante?.id);
		students = students.map((student) => {
			return {
				...student,
				recommended: selectedStudentsIds.includes(student?.id),
			};
		});
		return students;
	};

	return {
		recommendedClasses,
		allStudents,
		allClasses,
		getClassStudents,
		addStudents,
		removeStudents,
		removeAllStudents,
		addAllStudents,
	};
};
