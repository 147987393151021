import PropTypes from 'prop-types';

/**
 * componente que recibe una serie de propiedades necesarios para crear un input de texto
 * @returns InputText
 */
const InputText = (props) => {
  const { mensajeError, placeholder, identidicador, validarExpresion, estado, cambiarEstado, estadoEnviar, metodoOnChange, typeInput, required = false } = props;

  const valorInput = (e) => {
    cambiarEstado({ ...estado, campo: e.target.value })
    !!metodoOnChange && metodoOnChange({ nombreCurso: e.target.value })
  }

  const validarCampo = () => {
    if (validarExpresion) {
      if (validarExpresion.test(estado.campo)) {
        cambiarEstado({ ...estado, valido: true })
      } else {
        cambiarEstado({ ...estado, valido: false })
      }
    }
  }

  return (
    <>
      <input
        id={identidicador}
        type={!!typeInput ? typeInput : 'text'}
        placeholder={placeholder}
        className={`w-full ${estadoEnviar ? estado.valido ? null : 'input_error' : ''}`}
        value={estado.campo}
        onChange={valorInput}
        onKeyUp={validarCampo}
        onBlur={validarCampo}
        required={required}
        autoComplete='off'
      />
      <span 
        role={"alert"}
        aria-live="assertive"
        className={`text-14 ${estadoEnviar ? estado.valido ? null : 'alert_error' : 'hidden'}`}
      >{mensajeError}</span>
    </>
  )
}
export default InputText;

InputText.propTypes = {
  /**
   * String que corresponde al mensaje de error cuando el campo esta mal diligenciado
   */
  mensajeError: PropTypes.string,
  /**
   * string que corresponde al placeholder del campo
   */
  placeholder: PropTypes.string,
  /**
   * string que se asigna a la propiedad id del input
   */
  identidicador: PropTypes.string,
  /**
   * string que contiene la expresión para hacer la validación del campo
   */
  validarExpresion: PropTypes.any,
  /**
   * string del estado del campo. Esto se usa para obtener el valor del input. 
   */
  estado: PropTypes.object,
  /**
   * función de estado que cambia el valor del campo.
   */
  cambiarEstado: PropTypes.func,
  /**
   * booleano que indica si se debe mostrar alertas del campo
   */
  estadoEnviar: PropTypes.bool,
  /**
   * función que cambia el valor del campo.
   */
  metodoOnChange: PropTypes.func,
  /**
   * string que cambia la propiedad type del input
   */
  typeInput: PropTypes.string,
  /**
   * booleano que indica si el campo es requerido o no
   */
  required: PropTypes.bool
}