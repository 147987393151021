/**
 * Devuelve una cadena de texto sin ningún tipo de acentos
 * @param {string} str - Cadena de texto a la que se le quiere quitar los acentos
 * @returns {string} - Cadena de texto sin acentos
 */
const removeAccents = (str) => {
  let string = str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[.*+\-?^${}()|[\]\\]/g, " ");

  return string;
}

export default removeAccents;