/**
 * Devuelve un arreglo con los datos pasados como parametro, pero agregando la propiedad 'checked' con valor 'false' por defecto, esta función es util para definir el estado de los checkboxs de la interfaz grafica
 * @param {{data:object}} {data} 
 * @returns {object[]} Arreglo de objetos con la propiedad 'checked' con valor 'false'
 */
const agregarPropiedadChecked = ({ data }) => {
  const dataNueva = [...data];

  dataNueva.length !== 0 && dataNueva.map((profesor, index) =>
    dataNueva[index] = { ...profesor, checked: false }
  )

  return dataNueva;
}

export default agregarPropiedadChecked;