import React from "react";
import { useSyncPagination } from "../../../../hooks/use-pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClassCard } from "../../components/class-card";
import NoHayResultados from "../../../../components/globales/NoHayResultados";
import { BotonVolverArriba } from "../../../../components/globales/BotonVolverArriba";
import CajaCrear from "../../../../components/globales/cajasCrear/CajaCrear";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";

export const ClassesList = ({ classes, isRector, isFiltering }) => {
	const { textosSubSecciones: texts } = useObtenerSubsecciones("cursos");
	const {nuevo, vista_general} = texts || {};
	const _classes = Array.isArray(classes) ? classes : [];
	const totalLength = _classes?.length;

	const pagination = useSyncPagination({
		itemsPerPage: 10,
		data: _classes,
		nextPageDelay: 200,
	});

	const paginatedData = pagination.paginatedData;

	return (
		<div>
			<p className="titulos-principales text-22 mb-5">{totalLength} {vista_general?.resultados || 'resultados'}</p>
			{totalLength > 0 ? (
				<>
					<InfiniteScroll
						style={{ overflow: "visible" }}
						className="w-full min-h-full grid md:grid-cols-2"
						dataLength={paginatedData.length}
						hasMore={pagination.hasMore}
						next={pagination.nextPage}
						scrollThreshold={0.86}
						loader={<></>}
					>
						{paginatedData?.map((_class, i) => {
							return <ClassCard key={i} data={_class} />;
						})}
						{!isFiltering && isRector && (
							<CajaCrear
								altura={"90%"}
								textoCaja={nuevo?.titulo}
								link="/cursos/crear-curso"
							/>
						)}
					</InfiniteScroll>
					{pagination.hasMore ? (
						<div className="w-full center pt-5 col-span-full">
							<span className="ml-10 icon-spinner9 animate-spin text-40 primary-inv-var2--color"></span>
						</div>
					) : null}
					<BotonVolverArriba />
				</>
			) : (
				<NoHayResultados titulo={vista_general?.busqueda?.titulo} descripcion={vista_general?.busqueda?.descripcion}/>
			)}
		</div>
	);
};
