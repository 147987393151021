import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Paginacion from "../globales/Paginacion";
import SelectorDeBusqueda from "../globales/elementosFormularios/SelectorDeBusqueda";
import { FILTROS_ORDENAMIENTO_TABLA } from "../../shared/constants/Filtros";
import useObtenerSubsecciones from "../../containers/helpers/hooks/useObtenerSubsecciones";

const ResultadosListaEstudiantes = (props) => {

  const { cambiarEstudiantesElegidos, preloader, datosEstudiantes, textosTabla, textoBotonEditar } = props;
  const { codigo_acceso,codigo, correo, curso, libros_leidos, nombres_apellidos, profesor, sede, sesiones, sin_resultados, tiempo_promedio } = textosTabla;

  const [datosDivididos, setDatosDivididos] = useState([]);
  const ActualizarDatosDivididos = (value) => setDatosDivididos(value)

  const {textosSubSecciones:traducciones} = useObtenerSubsecciones('global');
  const [filtroActualTabla, setFiltroActualTabla] = useState(FILTROS_ORDENAMIENTO_TABLA.aZ)
  const [estudiantes, setEstudiantes] = useState([]);


  /**
   * dependiendo de el filtro seleccionado ordenada de una manera u otra los estudiantes y los setea en el state de estudiante
   */
  const manejarFiltroOrdenamiento = () => {
    let estudiantesOrdenados = [];
    switch (filtroActualTabla.id) {
      case FILTROS_ORDENAMIENTO_TABLA.aZ.id:
          estudiantesOrdenados = [...datosEstudiantes].sort((a, b) => {
          const nombreA = a?.nombre.toLowerCase().split(' ')[0];
          const nombreB = b?.nombre.toLowerCase().split(' ')[0];
          if (nombreA < nombreB)
            return -1;
          if (nombreA > nombreB)
            return 1;
          return 0;
        })
        break;
      case FILTROS_ORDENAMIENTO_TABLA.zA.id:
        estudiantesOrdenados = [...datosEstudiantes].sort((a, b) => {
          const nombreA = a?.nombre.toLowerCase().split(' ')[0];;
          const nombreB = b?.nombre.toLowerCase().split(' ')[0];;
          if (nombreA > nombreB)
            return -1;
          if (nombreA < nombreB)
            return 1;
          return 0;
        })
        break;
      default: 
      estudiantesOrdenados = datosEstudiantes 
      break; 
    }
    setEstudiantes(estudiantesOrdenados);
  }

  useEffect(()=>{
    manejarFiltroOrdenamiento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[datosEstudiantes, filtroActualTabla])

  const onKeyEnter = (e, idSede, dato) => {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
      e.target.checked = !e.target.checked;
      cambiarEstudiantesElegidos({ e, idSede: idSede, estudiante: dato });
    }
  }

  return (
    <>
      <div className={`${datosEstudiantes.length > 10 ? '' : 'mb-5'}`}>
        <div className="w-fit  m-1 mb-5" style={{ minWidth: 160 }}>
          <SelectorDeBusqueda
            cambiarElegido={({ opcion }) => { setFiltroActualTabla(opcion) }}
            filtroMensajeNoexite={`${traducciones?.filtros?.tablas?.ordenamiento?.no_existe || 'No se encontraron filtros.'}`}
            opciones={Object.values(FILTROS_ORDENAMIENTO_TABLA)}
            subposicion={true}
            textoDefault={`${traducciones?.filtros?.tablas?.ordenamiento?.titulo || 'Ordenar por'}`}
            textoElegido={filtroActualTabla.nombre}
          />
        </div>

        <div role="table" aria-rowcount={datosDivididos.length} aria-label="Lista de estudiantes">

          {!preloader ?
            <div role="rowgroup">
              {
                datosDivididos.length === 0 ?
                  <div className="filaTabla xl:grid-cols-12" role="row">
                    <div className=" center col-span-12">
                      <span className="icon-alerta text-18 red-inv-var2--color mr-3"></span>
                      <p className="raleway-700 red-inv-var2--color text-16">{sin_resultados}</p>
                    </div>
                  </div>
                  :
                  datosDivididos.map((dato) => {
                    return (
                      <div key={`campo${dato.id}`} className="cajaTabla" role="row">

                        <label
                          role="cell" 
                          className="fila-12-sm1 xl:col-span-6  sm:row-span-6 md:row-span-4 lg:row-span-6 xl:row-span-1 h-7 relative cursor-pointer xl:order-1 focusable-by-children-primary"
                        >
                          <input 
                            checked={dato.checked} 
                            tabIndex={0}
                            type="checkbox"
                            onChange={(e) => {
                              cambiarEstudiantesElegidos({ e, idSede: dato.idSede, estudiante: dato });
                            }} 
                            onKeyUp={(e) => { onKeyEnter(e, dato.idSede, dato) }}
                            />
                          <i aria-hidden={true} className="check" ></i>
                        </label>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md4-lg5 xl:col-span-4 overflow-hidden xl:order-3"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26"
                          >
                            {codigo_acceso?.titulo || 'Codigo de acceso'}
                          </span>
                          <p className={`raleway-700 ${!dato.codigo ? 'red-inv-var2--color' : 'primary-inv-var2--color'}  text-16 una-linea-texto`}>{!dato.codigo ? codigo.sin_codigo : dato.codigo}</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md3-lg5 xl:col-span-4 overflow-hidden xl:order-3"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26"
                          >
                            {codigo.titulo}
                          </span>
                          <p className={`raleway-700 ${!dato.codigoAsignadoInstitucion ? 'red-inv-var2--color' : 'primary-inv-var2--color'}  text-16 una-linea-texto`}>{!dato.codigoAsignadoInstitucion ? codigo.sin_codigo : dato.codigoAsignadoInstitucion}</p>
                        </div>

                        <div
                          role="cell" 
                          className="fila-12-sm5-md4-lg5 xl:col-span-4 xl:order-4"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26"
                          >
                            {nombres_apellidos.titulo}
                          </span>
                          <p className="r5-13-negro26 una-linea-texto">{dato.nombre}</p>
                        </div>

                        <div 
                          role="cell" 
                          className="fila-12-sm5-md4-lg5 xl:col-span-4 xl:order-5"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26 "
                          >
                            {correo.titulo}
                          </span>
                          <p className={`${dato.correo === '' ? 'r7-13-rojo' : 'r5-13-negro26'} una-linea-texto`} >{dato.correo === '' ? correo.sin_corrreo : dato.correo}</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md3-lg5 xl:col-span-4 xl:order-6"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26 "
                          >
                            {sede.titulo}
                          </span>
                          <p className='r5-13-negro26 una-linea-texto' >{dato.nombreSede}</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md4-lg5 xl:col-span-4 xl:order-7"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26"
                          >
                            {curso.titulo}
                          </span>
                          <p className='r5-13-negro26 una-linea-texto'>{dato.nombreCurso}</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md4-lg5 xl:col-span-4 xl:order-8"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26 "
                          >
                            {profesor.titulo}
                          </span>
                          <p className={`${dato.nombreProfesor === '' ? 'r7-13-rojo' : 'r5-13-negro26'}  una-linea-texto `} >{dato.nombreProfesor === '' ? profesor.no_hay : dato.nombreProfesor}</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md3-lg5 xl:col-span-4 xl:order-9"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26 "
                          >
                            {libros_leidos}
                          </span>
                          <p className='r5-13-negro26 ' >{dato.numeroDeLibrosLeidos}</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md4-lg5 xl:col-span-4 xl:order-10"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26"
                          >
                            {tiempo_promedio}
                          </span>
                          <p className='r5-13-negro26' >{dato.tiempoPromedioDeLectura} min</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm5-md4-lg5 xl:col-span-4 xl:order-11"
                        >
                          <span 
                            role="columnheader"
                            className="q7-14-negro26 "
                          >
                            {sesiones}
                          </span>
                          <p className='r5-13-negro26' >{dato.numeroTotalDeSesiones}</p>
                        </div>

                        <div 
                          role="cell"
                          className="fila-12-sm11 xl:col-span-6 xl:order-2"
                        >
                          <div className="alineado-izquierda-center">
                            <Link to={`estudiantes/editar-estudiantes/${dato.idSede}/${dato.idCurso}/${dato.id}`} className="boton-justo boton-amarillo boton_hover_morado center w-full md:w-auto md:px-10 md:py-1 focusable-primary">
                              <p>{textoBotonEditar} </p><span className="pl-3 icon-lapiz"></span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })
              }
            </div>
            :
            <>
              <div className="center pt-5">
                <span className="ml-10 icon-spinner9 animate-spin text-40 primary-inv-var2--color"></span>
              </div>
            </>
          }

        </div>

        <div className={`alineado-izquierda xl:mt-3`}>
          <Paginacion datos={estudiantes} datosDivididos={ActualizarDatosDivididos} />
        </div>


      </div>
    </>
  )
}
export default ResultadosListaEstudiantes;