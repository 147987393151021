import { FALSE } from "sass";
import DivisionCrud from "../../globales/DivisionCrud";
import Selector from '../../globales/elementosFormularios/Selector';
import SelectorCheck from '../../globales/elementosFormularios/SelectorCheck';
import PropTypes from 'prop-types';

/**
 * Componente que recibe una serie de props necesarios apra renderizar el formulario de crear individual. Este hace uso de los componentes globales DivisionCrud, Selector y SelectorCheck
 * @returns CrearProfesorIndividual
 */
const CrearProfesorIndividual = (props) => {
  const { actualizarCorreo, actualizarCursosAsignados, actualizarNombre, actualizarSedeAsignada, cursosAsignados, cursosSede, nombreProfesor, mostrarAlerta, sedesInstituto, sedeSeleccionada, setNombreProfesor, textosInterfaz, inputCorreo, setInputCorreo } = props;

  const { nombre, sede, cursos, correo } = textosInterfaz;

  /*expresion */
  const expresionCorreo = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;


  /*funciones */
  /*Esto valida si el campo de texto es valido. Si no esta vacio es true y si lo esta es false */
  const validarNombreProfesor = () => {
    const valorCampo = nombreProfesor.campo.length > 0 ? true : false;
    setNombreProfesor({ ...nombreProfesor, valido: valorCampo })
  }

  /*Esta función actualiza en campo de input nombre */
  const actualizarCampoNombre = (e, estado, cambiarEstado) => {
    const valorCampo = e.target.value;
    actualizarNombre({ nombre: valorCampo });
    cambiarEstado({ ...estado, campo: valorCampo });
  }

  /*esta función actualiza el valor del input y lo agrega en el estado */
  const actualizarCampoCorreo = (e, estado, cambiarEstado) => {
    const valorCampo = e.target.value;
    cambiarEstado({ ...estado, campo: valorCampo });
    actualizarCorreo({ correo: valorCampo })
  }

  /*funcion que cambia la sede asignada en el selector*/
  const cambiarSede = ({ opcion }) => actualizarSedeAsignada({ idSede: opcion.id });

  /*funcion que cambia la sede asignada en el selector*/
  const actualizarCursos = ({ e, opcion }) => actualizarCursosAsignados({ e, idSede: opcion.idSede, curso: opcion });

  /**Esta función comprueba que el valor del campo correo cumple con la expresión y cambia su estado */
  const validarCorreo = (expresion, estado, cambiarEstado) => {
    const valor = estado.campo !== '' ? expresion.test(estado.campo) : false;
    cambiarEstado({ ...estado, valido: valor });
  }

  return (
    <>
      {/* nombre */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{nombre.titulo} <span className="asterisco">*</span></h3>
          <p className="r4-14-gris6a">{nombre.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <input
            className={`w-full ${mostrarAlerta ? !nombreProfesor.valido && 'input_error' : null}`}
            onBlur={() => { validarNombreProfesor() }}
            onChange={(e) => {
              actualizarCampoNombre(e, nombreProfesor, setNombreProfesor)
            }}
            onKeyUp={validarNombreProfesor}
            value={nombreProfesor.campo}
            placeholder={nombre.placeholder}
            type="text"
          />
          {mostrarAlerta &&
            <p role="alert" aria-live="assertive"  className={`text-14 
                  ${!nombreProfesor.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
            }>
              {nombre.error}
            </p>
          }
        </div>
      </DivisionCrud>

      {/* Correo */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{correo.titulo} <span className="asterisco">*</span></h3>
        </>
        <div className="alineado-verticalmente h-full">
          <div className="w-full xl:w-451p">
            <input
              type="email"
              className={`w-full ${mostrarAlerta ? !inputCorreo.valido && 'input_error' : null}`}
              onChange={(e) => {
                actualizarCampoCorreo(e, inputCorreo, setInputCorreo);
              }}
              onKeyUp={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
              onBlur={() => validarCorreo(expresionCorreo, inputCorreo, setInputCorreo)}
              placeholder={correo.placeholder}
              value={inputCorreo.campo}
            />
            {mostrarAlerta &&
              <p role="alert" aria-live="assertive" className={`text-14 
                  ${!inputCorreo.valido ? 'animate-fadeIn alert_error' : 'hidden  animate-fadeOut'}`
              }>
                {correo.error}
              </p>
            }
          </div>
        </div>
      </DivisionCrud>

      {/* sede */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{sede.titulo}</h3>
          <p className="r4-14-gris6a">{sede.descripcion}</p>
        </>
        <div className="xl:w-451p">
          <Selector
            cambiarElegido={cambiarSede}
            desactivado={false}
            estiloTipoInput={true}
            opciones={sedesInstituto}
            textoDefault={sede.placeholder}
            textoElegido={sedeSeleccionada.nombre}
            superposicion={true}
          />
        </div>
      </DivisionCrud>

      {/* Cursos */}
      <DivisionCrud>
        <>
          <h3 className="raleway-700 onbackground-var1--color">{cursos.titulo}</h3>
          <p className="r4-14-gris6a">{cursos.descripcion} </p>
        </>
        <div className="xl:w-451p">
          <SelectorCheck
            actualizarElegidos={actualizarCursos}
            datosAlmacenados={cursosAsignados.ids}
            estiloTipoInput={true}
            hayElementos={cursosSede.hayCursos}
            opciones={cursosSede.cursos}
            textoDefault={`${cursosAsignados.datos.length} ${cursosAsignados.datos.length !== 1 ? cursos.asignados.plural : cursos.asignados.singular} `}
            textoNoHayDatos={cursos.no_hay}
          />
        </div>
      </DivisionCrud>
    </>
  )
}
export default CrearProfesorIndividual;

CrearProfesorIndividual.propTypes = {

  /**
  * función que se encarga de actualizar el correo del profesor cuando se crea de forma individual.
  * @type{Function({ correo: string}):void} - callback
  */
  actualizarCorreo: PropTypes.func.isRequired,
  /**
   * función que se encarga de actualizar los cursos asignados al profesor. 
   * @type{Function({ e : shape, curso: shape, idSede: string }):void} - callback
   */
  actualizarCursosAsignados: PropTypes.func.isRequired,
  /**
  * función de encarga de actualizar el estado de nombre cuando se está creando el profesor de manera individual.
  * @type{Function({ nombre: string }):void} - callback
  */
  actualizarNombre: PropTypes.func.isRequired,
  /**
   * función que se encarga de actualizar el id de la sede en la que se desea crear el profesor(es)
   * @type{Function({ idSede : string }):void} - callback
   */
  actualizarSedeAsignada: PropTypes.func.isRequired,
  /**
  * Objeto que contiene la información de los cursos asignados al profesor.
  */
  cursosAsignados: PropTypes.shape({
    datos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    ids: PropTypes.array
  }).isRequired,
  /**
   * Objeto que contiene el estado y los cursos que el instituto tiene.
   */
  cursosSede: PropTypes.shape({
    cursos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      nombre: PropTypes.string
    })),
    hayCursos: PropTypes.bool
  }).isRequired,

  /**
   * Objeto que contiene el texto y la validacion del campo nombre
   */
  nombreProfesor: PropTypes.shape({
    campo: PropTypes.string,
    valido: PropTypes.bool
  }).isRequired,

  /**
   * Booleano que indica si hay un error en el formulario.
   */
  mostrarAlerta: PropTypes.bool.isRequired,

  /**
 * array de objetos que contiene el listado de sedes que el instituto tiene. 
 */
  sedesInstituto: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  })).isRequired,

  /**
   *  objeto que cuenta con los datos de la sede seleccionada para crear el o los profesores.
   */
  sedeSeleccionada: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }).isRequired,

  /**
   * función que actualiza el campo de nombre
   */
  setNombreProfesor: PropTypes.func.isRequired,

  /**
   * Es un objeto que trae los textos de la interfaz dependiendo del idioma activo.
   */
  textosInterfaz: PropTypes.object.isRequired,

  /**
   * Objeto que contiene el texto y la validacion del campo correo
   */
  inputCorreo: PropTypes.shape({
    campo: PropTypes.string,
    valido: PropTypes.bool
  }).isRequired,

  /**
   * función que actualiza el campo de correo
   */
  setInputCorreo: PropTypes.func.isRequired
}