import { useState} from "react";
import useObtenerSubsecciones from "../../../../containers/helpers/hooks/useObtenerSubsecciones";
import { Recomendar } from ".";
import MicroModal from "micromodal";
import PropTypes from "prop-types";
import { useRecommendedManager } from "./hooks/use-recommended-manager";

/**
 Este componente tiene la lógica principal para recomendar un libro a uno o varios estudiantes de los cursos existentes en el instituto, se encarga de mostrar los cursos existentes,  los cursos ya recomendados, los estudiantes a quienes se recomendó y permite editar cada curso de manera independiente.
 */
export const General = (props) => {
	const { idLibro } = props;
	const { textosSubSecciones: traducciones } = useObtenerSubsecciones("libros");
	const [modalCursosVisible, setModalCursosVisible] = useState(false);
	const [modalCursoEspecificoVisible, setModalCursoEspecificoVisible] = useState(false);
  const [currentClass, setCurrentClass] = useState(undefined);

	// const [asignadoActual, setAsignadoActual] = useState(undefined);
	const idModalCursos = "modal-recomendar-cursos-ficha-libro";
	const idModalCursoEspecifico = "modal-recomendar-curso-especifico-ficha-libro";
	const recommendedManager = useRecommendedManager({ bookId:idLibro });
	const recommendedClasses = recommendedManager.recommendedClasses;

	return (
		<>
			<div className="2xl:border-b border-gris-linea py-5">
				<h3 className="alineado-verticalmente pb-3 r7-16-negro26">
					<span className="icon-marcador text-20 mr-3"></span>
					{`${traducciones?.ficha?.recomendar?.titulo || "Recomendar"}`}
				</h3>

				<div className="max-h-40 scroll">
					{recommendedClasses.map((recommendedClass, i) => {
            const title = `(${recommendedClass?.recommendedStudentsLength}) ${recommendedClass?.class?.nombre}`
						return (
							<>
								<div className="grid grid-cols-2 primary-var2--bg  primary-inv-var2--bordered py-1 px-2 my-3  rounded-sm">
									<button
										className="focusable-red w-full  q7-18 onprimary-var1--color  una-linea-texto hover:cursor-pointer hover:text-morado-disabled"
										key={`asignado-${i}`}
                    title={title}
										onClick={() => {
                      setCurrentClass(recommendedClass?.class);
											setModalCursoEspecificoVisible(true);
											MicroModal.show(idModalCursoEspecifico);
										}}
									>
										{title}
									</button>
									<button
										aria-label="Quitar/Remove"
										className="focusable-red place-self-end self-center"
										onClick={() =>recommendedManager.removeAllStudents({classId:recommendedClass?.class?.id})}
									>
										<span className="icon-cerrar onprimary-var1--color text-14 hover:text-rojo cursor-pointer"></span>
									</button>
								</div>
							</>
						);
					})}
				</div>

				<button
					className="focusable-red w-full md:w-60 xl:w-auto boton-justo mt-5 boton-blanco boton_hover_amarillo"
					onClick={() => {
						setModalCursosVisible(true);
						MicroModal.show(idModalCursos);
					}}
				>
					{`${traducciones?.ficha?.recomendar?.boton || "Recomendar"}`}
					<span className="icon-mas ml-3"></span>
				</button>
			</div>

			<>
				 <Recomendar.ModalCursos
          idModal={idModalCursos}
          recommendedManager={recommendedManager}
          visible={modalCursosVisible}
          setVisible={setModalCursosVisible}
        />
        
        <Recomendar.ModalCursoEspecifico
          idModal={idModalCursoEspecifico}
          recommendedManager={recommendedManager}
          currentClass={currentClass}
          visible={modalCursoEspecificoVisible}
          setVisible={setModalCursoEspecificoVisible}
        />
			</>
		</>
	);
};

General.propTypes = {
	/**
	 * El id del libro que se desea recomendar
	 */
	idLibro: PropTypes.string.isRequired,
};
