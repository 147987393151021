import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
/*Componente */
import DivisionCrud from "../../globales/DivisionCrud";

const SkeletonClubVerificar = () => {
  return (
    <SkeletonTheme color={'#e8e8e8'} highlightColor={'#f7f7f7'}>
      <Skeleton className="ruta" width={270} height={40} />

      <div className="contenido" >
        <h1 className="titulos-principales  ">
          <div className="md:w-44 mb-2">
            <Skeleton width={'100%'} height={36} />
          </div>
        </h1>

        <DivisionCrud>
          <>
            <Skeleton width={150} height={24} />
          </>
          <div className="w-full xl:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={100} height={24} />
          </>
          <div className="w-full xl:w-451p">
            <Skeleton width={'100%'} height={150} />
          </div>
        </DivisionCrud>


        <DivisionCrud>
          <>
            <Skeleton width={150} height={24} />
          </>
          <div className="w-full xl:w-451p">
            <Skeleton width={'100%'} height={36} />
          </div>
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={150} height={24} />
          </>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-3 2xl:grid-cols-5  gap-4 ">
            <div className="w-32 h-44">
              <Skeleton width={'100%'} height={'100%'} />
            </div>
            <div className="w-32 h-44">
              <Skeleton width={'100%'} height={'100%'} />
            </div>
          </div>
        </DivisionCrud>



        <DivisionCrud>
          <>
            <Skeleton width={'90%'} height={24} />
          </>
          <Skeleton className="mx-auto lg:mx-0" width={128} height={128} style={{ borderRadius: '100%', display: 'block' }} />
        </DivisionCrud>

        <DivisionCrud>
          <>
            <Skeleton width={'90%'} height={24} />
            <Skeleton className="mt-3" width={'100%'} height={60} />
          </>
          <>

            <div className="encabezadoTabla">
              <div className="col-span-2">
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <div className="xl:col-span-4">
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <div className=" xl:col-span-3">
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <div className=" xl:col-span-3">
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <div className="col-span-1"></div>
            </div>

            <div className="filaTabla mt-5">

              <div className="fila-10-sm5-md3-lg6 xl:col-span-2">
                <div className="w-6/12 xl:w-10/12  xl:hidden">
                  <Skeleton width={'100%'} height={24} />
                </div>
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>

              <div className="fila-10-sm5-md3-lg6 xl:col-span-4">
                <div className="w-6/12 xl:w-10/12  xl:hidden">
                  <Skeleton width={'100%'} height={24} />
                </div>
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <div className="fila-10-sm5-md3-lg6 xl:col-span-3 overflow-auto">
                <div className="w-6/12 xl:w-10/12  xl:hidden">
                  <Skeleton width={'100%'} height={24} />
                </div>
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
              <div className="fila-10-sm5-md3-lg6 xl:col-span-3 overflow-auto">
                <div className="w-6/12 xl:w-10/12  xl:hidden">
                  <Skeleton width={'100%'} height={24} />
                </div>
                <div className="md:w-10/12">
                  <Skeleton width={'100%'} height={24} />
                </div>
              </div>
            </div>

          </>
        </DivisionCrud>


        <div className="flex justify-end my-5">
          <button className=" w-1/2 sm:w-40 mr-5">
            <Skeleton width={'100%'} height={36} />
          </button>
          <button className=" w-1/2 sm:w-40">
            <Skeleton width={'100%'} height={36} />
          </button>
        </div>
      </div>
    </SkeletonTheme>
  )
}
export default SkeletonClubVerificar;