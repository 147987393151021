import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

/**
 * Input que permite optener la información de un archivo. Este componente se usa cuando el input se puede deshabilitar.
 * @returns InputFile
 */
const InputFile = (props) => {
  const { descripcion, instruccion, textoError, obtenerDatosExcel, setExcelValido, excelValido, mostrarAlerta, desactivar, datoGuardado, setNombreDocumento, nombreDocumento, hayErrorExcel } = props;
  const [archivoIncorrecto, setArchivoIncorrecto] = useState(false);

  const onDropAccepted = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        obtenerDatosExcel({ archivo: file, e: e, nombre: file.name });
        setNombreDocumento(file.name);
        setArchivoIncorrecto(false);
      }
      reader.readAsArrayBuffer(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const onDropRejected = useCallback((fileRejections) => {
    fileRejections.forEach(() => {
      setArchivoIncorrecto(true);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxFiles: 1,
    disabled: desactivar,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  })

  useEffect(() => {
    const validar = acceptedFiles.length !== 0 ? true : false;
    setExcelValido(validar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  useEffect(() => {
    if (datoGuardado.hayDatos) {
      setNombreDocumento(datoGuardado.datos.nombreArchivo);
      setExcelValido(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datoGuardado.hayDatos])

  const validarDrag = () => {
    if (isDragActive) {
      return (
        <>
          {isDragAccept ?
            <p className='text-16 green-inv-var1--color raleway-700 transicion-300'>{instruccion} </p>
            :
            <p className='text-16 r7-rojo-alerta transicion-300'>{textoError}</p>
          }
        </>
      )
    }
  }
  return (
    <>
      <div {...getRootProps({
        className: `inputFile h-36  transicion-300 focusable-primary
                        ${hayErrorExcel && 'inputFile-incorrecto'}
                        ${!excelValido && mostrarAlerta && 'inputFile-incorrecto'}
                        ${archivoIncorrecto && 'inputFile-incorrecto'} 
                        ${isDragActive && 'inputFile-incorrecto'} 
                        ${isDragAccept && 'inputFile-correcto'}`
      })}>
        <input {...getInputProps()} />
        <div className="text-center raleway-500 text-14  onsurface-var1--color">
          {!isDragActive &&
            <>
              {!!nombreDocumento ?
                <p className={`pb-3 ${hayErrorExcel && 'r7-rojo-alerta'} `}>{nombreDocumento}</p>
                :
                <>
                  {archivoIncorrecto ?
                    <p className='text-16 r7-rojo-alerta transicion-300 pb-3'>{textoError}</p>
                    :
                    <p className={`pb-3 ${!excelValido && mostrarAlerta && 'r7-rojo-alerta'}`}>{descripcion}</p>
                  }
                </>
              }

              <p className={`${desactivar ? 'evento_desactivado' : 'evento_rojo'} cursor-pointer inline-block`}>{instruccion}</p>
              <span className={`icon-subir ${desactivar ? 'onsurface-var1--color' : 'red-inv-var2--color'}  ml-2`}></span>
            </>
          }
          {validarDrag()}
        </div>

      </div>
    </>

  )
}
export default InputFile;

InputFile.propTypes = {
  /**
   * string que corresponde a la descripción del input
   */
  descripcion: PropTypes.string,
  /**
   * string de la instrucción que aparece al sobreponer un archivo
   */
  instruccion: PropTypes.string,
  /**
   * string del texto de error
   */
  textoError: PropTypes.string,
  /**
   * función que obtiene la información del archivo
   * @type{Function({ archivo: object, e : event, nombre: string }):void}
   */
  obtenerDatosExcel: PropTypes.func,
  /**
   * Función de estado que actualiza el booleano que indica si el archivo es valido.
   */
  setExcelValido: PropTypes.func,
  /**
   * booleano que indica si el archivo es valido.
   */
  excelValido: PropTypes.bool,
  /**
   * booleano que indica si se debe mostrar la alerta del campo
   */
  mostrarAlerta: PropTypes.bool,
  /**
   * Booleano que indica si el campo esta habilitado o no
   */
  desactivar: PropTypes.bool,
  /**
   * string que contiene el nombre del archivo guardado
   */
  datoGuardado: PropTypes.object,
  /**
   * función de estado que actualiza el texto del nombre del archivo
   */
  setNombreDocumento: PropTypes.func,
  /**
   * string que corresponde al nombre del archivo
   */
  nombreDocumento: PropTypes.string,
  /**
   * booleano que indica si hay errores con el archivo
   */
  hayErrorExcel: PropTypes.bool
}